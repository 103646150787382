import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import { setHeader } from '../../../services/actions/headerActions';
import { useDispatch, useSelector } from 'react-redux';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getArrayParam, isValidParam } from '../../../services/helper/parameterVerifier';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel'
import { getSFFormFieldsPromise } from '../../../services/actions/sfFormActions';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { alpha, styled } from '@mui/material/styles';

const ToggleSwitch  = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#424242",
      '&:hover': {
        backgroundColor: '#2c2c2c1f',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#424242",
    },
  }));

const DuplicateRules = ({ object }) => {
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);

    const [objectList, setObjectList] = useState([]);
    const [objectSelected, setObjectSelected] = useState('');
    const [fieldList, setFieldList] = useState([]);
    const [fieldSelected, setFieldSelected] = useState('');
    const [totalRuleCount] = useState(3);
    const [ruleData, setRuleData] = useState([]);
    const [isRefresh, setIsRefresh] = useState(false);

    // object list (oninit)
    useEffect(() => {
        dispatch(setHeader(object, getLocalizedStrings().label.DUPLICATE_RULE.DUPLICATE_RULE, null, false, true));
        let count = 0;
        let dropDownList = [];
        let selectedItem = "";
        try {
            let objectListsData = app.objectList;
            let objectListsDataArray = objectListsData.sort((a, b) => (a.name > b.name) ? 1 : -1);
            if (objectListsDataArray.length > 0) {
                objectListsDataArray.forEach((items, Index) => {
                    if ((items.name === constants.ACCOUNTS_OBJECT || items.name === constants.CONTACTS_OBJECT || items.name ===constants.OPPORTUNITIES_OBJECT)
                       || (items.name === constants.CUSTOM_TABLE1_OBJECT && items.homeId >0) || (items.name === constants.CUSTOM_TABLE2_OBJECT && items.homeId >0) || (items.name === constants.CUSTOM_TABLE3_OBJECT && items.homeId >0)
                       || (items.name === constants.CUSTOM_TABLE4_OBJECT&& items.homeId >0) || (items.name === constants.PARTNERS_OBJECT && items.homeId >0))
                     {
                        let objectList = <MenuItem value={items.name} key={Index}>{items.label}</MenuItem>;
                        if (count === 0) {
                            selectedItem = items.name;
                        }
                        dropDownList.push(objectList);
                        count++;
                    }
                });
                setObjectList(dropDownList);
                setObjectSelected(selectedItem);
            }
        } catch (error) {
            console.error('Error in duplicateRules.js -> getObjectsForDuplicateRule() : ' + error);
        }
    }, [app.objectList, dispatch, object]);

    // fields selected
    useEffect(() => {
        try {
            let optionsList = sfForm.data[objectSelected];
            if (!isValidParam(optionsList)) {
                let promise = getSFFormFieldsPromise(objectSelected);
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            sfForm.data[objectSelected] = response;
                            processFields(response.fields);
                            setFieldSelected("-999");
                        }
                    });
                }
            } else {
                processFields(optionsList.fields);
            }

        } catch (error) {
            console.error('Error in duplicateRules.js -> getFieldsByObject() : ' + error);
        }
    }, [objectSelected, sfForm.data]);

    // rules data - api
    useEffect(() => {
        try {
            if (objectSelected !== '') {
                let tableId = OBJECT_TABLEID_MAP[objectSelected];
                if (tableId > 0) {
                    let params = { table_id: tableId };
                    let url = endPoints.DUPLICATE_RULE.GET;
                    let promise = Promise.resolve(HTTPClient.get(url, params));
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let rulesData = [];
                            for (let i = 0; i < totalRuleCount; i++) {
                                if (response.some(e => e.rule_no === i + 1)) {
                                    let tempdata = response.filter(e => e.rule_no === i + 1);
                                    const {field_names} = tempdata[0];
                                    if(field_names?.length > 0) {
                                        let data = fieldList.filter((item)=>item.name === field_names[0].field_name)
                                        if(data?.length === 0 &&( objectSelected === constants.OPPORTUNITIES_OBJECT || objectSelected === 'customobjectone'
                                            || objectSelected === 'customobjecttwo'|| objectSelected === 'customobjectthree'|| objectSelected === 'customobjectfour'
                                        )){
                                            rulesData.push(generateRuleObject(objectSelected, i + 1, 0, 0));
                                        }else{
                                            rulesData.push(tempdata[0]);                                            
                                        }   
                                    } else {
                                        rulesData.push(tempdata[0]);
                                    }
                                } else {
                                    rulesData.push(generateRuleObject(objectSelected, i + 1, 0, 0));
                                }
                            }
                            setRuleData(rulesData);
                        }
                    });
                }
            }
        } catch (error) {
            console.error('Error in duplicateRules.js -> getRuleData() : ' + error);
        }
    }, [fieldList, objectSelected, totalRuleCount]);

    const processFields = (optionsListItem) => {
        let selectObjetFields = [];
        if (optionsListItem.length > 0) {
            optionsListItem.forEach((items) => {
                if (items.t_tablefldid !== -1 && items.name !== "workflow_id" && items.is_hidden_field === false && items.field_type !== "memo") {
                    let objectList = {
                        label: items.label,
                        value: items.name,
                        title: items.label,
                        name: items.name,
                    };
                    selectObjetFields.push(objectList);
                }
            }
            )
        }
        sortArrayObjectByProperty(selectObjetFields, 'label');
        setFieldList(selectObjetFields);
    }

    const generateViewForRules = () => {
        let rulesHtml = [];
        try {
            if (ruleData.length > 0) {
                for (let i = 0; i < totalRuleCount; i++) {
                    let isactive = false;
                    let isdefault = false;
                    let ruleName = '';
                    let fieldsData = [];
                    if (ruleData.length >= i + 1 && ruleData[i].hasOwnProperty('rule_no')) {
                        isactive = ruleData[i].hasOwnProperty('is_active') && ruleData[i].is_active === 1;
                        isdefault = ruleData[i].hasOwnProperty('is_default') && ruleData[i].is_default === 1;
                        fieldsData = ruleData[i].field_names;
                    }
                    if (i === 0) {
                        ruleName = getLocalizedStrings().label.DUPLICATE_RULE.ONE_FIELD;
                    } else if (i === 1) {
                        ruleName = getLocalizedStrings().label.DUPLICATE_RULE.TWO_FIELD;
                    } else if (i === 2) {
                        ruleName = getLocalizedStrings().label.DUPLICATE_RULE.THREE_FIELD;
                    }
                    let paddingButtom = 2 + (i * 1) + i + "%";
                    let padding = "2% 2% " + paddingButtom + " 2%";
                    rulesHtml.push(
                        <div className='container'>
                            <div className='section'>
                                <div className='row' style={{ border: '1px solid #d3d3d3', padding: padding, margin: '3% 3% 0% 3%' }}>
                                    <div className='col-sm-6'>
                                        <div key={"div4" + i} className="col-sm-6">
                                            <div key={"div5" + i}> <span key={"span1" + i} style={{ fontSize: '15px', fontWeight: 'bold' }}>{ruleName}</span></div>
                                        </div>
                                    </div>
                                    <div className='col-sm-3'>
                                        <div key={"div6" + i} className="col-sm-3" style={{ width: '13%' }}>
                                            <div key={"div7" + i} name={"custom_field_div_active"} className="row" style={{ marginBottom: '10px', marginLeft: '270%', width: '100%' }} id={"custom_field_div_active"} >
                                                <div className="row" style={{ marginBottom: '10px', marginLeft: '270%', width: '100%' }} key={"custom_field_div_active" + i} id={"custom_field_div_active" + i} >
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<ToggleSwitch key={"tog1" + i} checked={isactive} value={isactive} onChange={(e) => handleActiveToggle(i, e, e.target.checked)} color="default" />}
                                                            label={getLocalizedStrings().label.DUPLICATE_RULE.ACTIVE}
                                                            key={"span2" + i} style={{ float: 'left', paddingLeft: '10px' }}

                                                        />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-3'>
                                        <div key={"div8" + i} className="col-sm-3" style={{ width: '13%' }}>
                                            <div key={"div9" + i} name={"custom_field_div_default"} className="row" style={{ marginBottom: '10px', marginLeft: '300%', width: '100%' }} id={"custom_field_div_default"} >
                                                <div className="row" style={{ marginBottom: '10px', marginLeft: '300%', width: '100%' }} key={"custom_field_div_default" + i} id={"custom_field_div_default" + i} >
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<ToggleSwitch key={"tog2" + i} checked={isdefault} value={isdefault} onChange={(e) => handleDefaultToggle(i, e, e.target.checked)} color="default" />}
                                                            label={getLocalizedStrings().label.DUPLICATE_RULE.DEFAULT}
                                                            key={"span3" + i} style={{ float: 'left', paddingLeft: '10px' }}
                                                        />
                                                    </FormGroup>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {generateViewForFields(fieldsData, i)}
                                </div>
                            </div>
                        </div>
                    );
                }
            }
        } catch (error) {
            console.error('Error in duplicateRules.js -> generateViewForRules() : ' + error);
        }
        return rulesHtml;
    }

    const generateViewForFields = (fieldsData, index) => {
        let htmlData = []
        try {
            if (fieldsData.length > 0) {
                for (let j = 0; j < index + 1; j++) {
                    let selectedField = fieldsData[j].field_name;
                    let title = '';
                    fieldList.forEach((items) => {
                        if (items.name === fieldsData[j].field_name) {
                            title = items.label;
                        }
                    });
                    htmlData.push(
                        <div key={"div10" + j} className="col-sm-12">
                            <FormControl style={{ width: '24%' }} variant="outlined">
                                <InputLabel id="sf-duplicate-rule-result-from-duplicaterule-simple-select-outlined-label" className="sf-duplicate-rule-result-from-duplicaterule">{getLocalizedStrings().label.DUPLICATE_RULE.SELECT_FIELD_LABEL}</InputLabel>
                                <Select
                                    labelId='sf-label-select1'
                                    key={"select1" + index + j}
                                    label={getLocalizedStrings().label.DUPLICATE_RULE.SELECT_FIELD_LABEL}
                                    name="fieldsList"
                                    id={"fieldsList" + index + j}
                                    value={selectedField}
                                    title={title}
                                    style={{ height: "40px", marginBottom: '4%' }}
                                    className={"sf-fields-bg"}
                                >
                                    {generateFieldsInMenu(index, j)}
                                </Select>
                            </FormControl>
                        </div>
                    );
                }
            } else {
                for (let j = 0; j < index + 1; j++) {
                    let title = '';
                    if (j === 0) {
                        title = getLocalizedStrings().label.DUPLICATE_RULE.FIELD_ONE;
                    } else if (j === 1) {
                        title = getLocalizedStrings().label.DUPLICATE_RULE.FIELD_TWO;
                    } else if (j === 2) {
                        title = getLocalizedStrings().label.DUPLICATE_RULE.FIELD_THREE;
                    }
                    htmlData.push(
                        <div key={"div10" + j} className="col-sm-12">
                            <FormControl style={{ width: '100%' }} variant="outlined">
                                <InputLabel id="sf-duplicaterule-from-duplicaterules-simple-select-outlined-label" className="sf-duplicaterule-from-duplicaterules">{getLocalizedStrings().label.DUPLICATE_RULE.SELECT_FIELD_LABEL}</InputLabel>
                                <Select
                                    labelId='sf-label-fieldsList'
                                    key={"select1" + index + j}
                                    label={getLocalizedStrings().label.DUPLICATE_RULE.SELECT_FIELD_LABEL}
                                    name="fieldsList"
                                    id={"fieldsList" + index + j}
                                    value={fieldSelected}
                                    title={title}
                                    className={"sf-fields-bg"}
                                >
                                    {generateFieldsInMenu(index, j)}
                                </Select>
                            </FormControl>
                        </div>
                    );
                }
            }
        } catch (error) {
            console.error('Error in duplicateRules.js -> generateViewForFields() : ' + error);
        }
        return htmlData;
    }

    const generateFieldsInMenu = (indexI, indexJ) => {
        let dropDownList = [];

        try {
            let objectsFieldsArray = fieldList;
            if (objectsFieldsArray.length > 0) {
                let label = '';
                if (indexJ === 0) {
                    label = getLocalizedStrings().label.DUPLICATE_RULE.FIELD_ONE;
                } else if (indexJ === 1) {
                    label = getLocalizedStrings().label.DUPLICATE_RULE.FIELD_TWO;
                } else if (indexJ === 2) {
                    label = getLocalizedStrings().label.DUPLICATE_RULE.FIELD_THREE;
                }
                dropDownList.push(
                    <MenuItem
                        value={"-999"}
                        key={"fldList" + indexI + indexJ + 0}
                        onClick={(e) => handleFieldMenuChange(indexI, indexJ, "-999", "", e)}
                    >{label}</MenuItem>
                );
                dropDownList.push(
                    <MenuItem
                        value={"-990"}
                        key={"fldList" + indexI + indexJ + 1}
                        onClick={(e) => handleFieldMenuChange(indexI, indexJ, "-999", "", e)}
                    >------------------------------</MenuItem>
                );
                objectsFieldsArray.forEach((items, Index) => {
                    let count = Index + 2;
                    let objectList = <MenuItem
                        value={items.name}
                        key={"fldList" + indexI + indexJ + count}

                        onClick={(e) => handleFieldMenuChange(indexI, indexJ, items.name, items.label, e)}
                    >  {items.label}</MenuItem>;
                    dropDownList.push(objectList);
                }
                )
            }
        } catch (error) {
            console.error('Error in duplicateRules.js -> generateFieldsInMenu() : ' + error);
        }
        return dropDownList;
    }

    const handleOnClickObjectDropDown = (event) => {
        let value = event.target.value;
        setObjectSelected(value);
        setFieldList([]);
        setRuleData([]);
    }

    const generateRuleObject = (object, ruleNo, isDefault, isActive) => {
        let newRuleObject = {};
        newRuleObject.is_active = isActive;
        newRuleObject.is_default = isDefault;
        newRuleObject.rule_no = ruleNo;
        newRuleObject.table_id = OBJECT_TABLEID_MAP[object];
        if (ruleNo === 2) {
            newRuleObject.rule_name = 'Two Fields';
        } else if (ruleNo === 3) {
            newRuleObject.rule_name = 'Three Fields';
        }else if (ruleNo === 1) {
            newRuleObject.rule_name = 'One Field';
        }
        let fields = [];
        for (let i = 0; i < ruleNo; i++) {
            let fieldsObject = {};
            fieldsObject.id = 0;
            fieldsObject.ordinal = i + 1;
            fieldsObject.field_name = "-999";
            fields.push(fieldsObject);
        }
        newRuleObject.field_names = fields;
        return newRuleObject;
    }

    const handleDefaultToggle = (indexI, event, isInputChecked) => {
        let rules = [...ruleData];
        try {
            let ruleNo = indexI + 1;
            if (isInputChecked) {
                if (rules.some(e => e.rule_no === ruleNo)) {
                    rules.forEach((items, Index) => {
                        if (items.is_default === 1 && Index !== indexI && ruleNo !== items.rule_no) {
                            items.is_default = 0;
                        }
                        if (items.is_default === 0 && Index === indexI && ruleNo === items.rule_no) {
                            items.is_default = 1;
                            if (items.is_active === 0) {
                                items.is_active = 1;
                            }
                        }
                    })
                } else {
                    rules.forEach((items, Index) => {
                        if (items.is_default === 1 && Index !== indexI && ruleNo !== items.rule_no) {
                            items.is_default = 0;
                        }
                    });
                    rules.push(generateRuleObject(objectSelected, ruleNo, 1, 1));
                }
            } else if (!isInputChecked) {
                if (rules.some(e => e.rule_no === ruleNo)) {
                    rules.forEach((items, Index) => {
                        if (items.is_active === 1 && items.is_default === 0 && Index === indexI && ruleNo === items.rule_no) {
                            items.is_active = 0;
                        }
                    })
                    let errorMessage = getLocalizedStrings().message.DUPLICATE_RULE.ERROR_MSG_DEFAULT;
                    dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            }

            setRuleData(rules);
        } catch (error) {
            console.error('Error in duplicateRules.js -> handleDefaultToggle() : ' + error);
        }
    }

    const handleActiveToggle = (indexI, event, isInputChecked) => {
        let rules = [...ruleData];
        try {
            let ruleNo = indexI + 1;
            if (isInputChecked) {
                if (rules.some(e => e.rule_no === ruleNo)) {
                    rules.forEach((items, Index) => {
                        if (items.is_active === 0 && Index === indexI && ruleNo === items.rule_no) {
                            items.is_active = 1;
                        }
                    })
                } else {
                    rules.push(generateRuleObject(objectSelected, ruleNo, 0, 1));
                }
            } else if (!isInputChecked) {
                if (rules.some(e => e.rule_no === ruleNo)) {
                    let flag = false;
                    rules.forEach((items, Index) => {
                        if (items.is_active === 1 && items.is_default === 0 && Index === indexI && ruleNo === items.rule_no) {
                            items.is_active = 0;
                            flag = true;
                        }
                    })
                    if (!flag) {
                        let errorMessage = getLocalizedStrings().message.DUPLICATE_RULE.ERROR_MSG_Active;
                        dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                } else {

                }
            }

            setRuleData(rules);
        } catch (error) {
            console.error('Error in duplicateRules.js -> handleActiveToggle() : ' + error);
        }
    }

    const handleFieldMenuChange = (indexI, indexJ, value, label) => {
        let rules = [...ruleData];
        let rulesData = [];
        try {
            let ruleNo = indexI + 1;
            let dataCheck = rules.filter(e => e.rule_no === ruleNo);
            if (dataCheck.length === 0 || !(dataCheck[0].field_names.some(j => j.field_name === value)) || value === "-999") {
                if (rules.some(e => e.rule_no === ruleNo)) {
                    rules.forEach((items) => {
                        if (ruleNo === items.rule_no && items.field_names.some(e => e.field_name !== value)) {
                            if (items.is_active === 0) {
                                items.is_active = 1;
                            }
                            for (let i = 0; i < items.field_names.length; i++) {
                                if (i === indexJ) {
                                    items.field_names[i].field_name = value;
                                }
                            }
                        }
                        rulesData.push(items);
                    })
                } else {
                    rulesData = rules;
                    let newRuleObject = {};
                    newRuleObject.is_default = 0;
                    newRuleObject.is_active = 1;
                    newRuleObject.rule_no = ruleNo;
                    newRuleObject.table_id = OBJECT_TABLEID_MAP[objectSelected];
                    if (ruleNo === 2) {
                        newRuleObject.rule_name = 'Two Fields';
                    } else if (ruleNo === 3) {
                        newRuleObject.rule_name = 'Three Fields';
                    }else if (ruleNo === 1) {
                        newRuleObject.rule_name = 'One Field';
                    }
                    let fields = [];
                    for (let i = 0; i < ruleNo; i++) {
                        let fieldsObject = {};
                        fieldsObject.id = 0;
                        fieldsObject.ordinal = i + 1;
                        if (i === indexJ) {
                            fieldsObject.field_name = value;
                        } else {
                            fieldsObject.field_name = "-999";
                        }
                        fields.push(fieldsObject);
                    }
                    newRuleObject.field_names = fields;
                    rulesData.push(newRuleObject);
                }
                setRuleData(rulesData);
            console.log("4444",rulesData)

            } else {
                let errorMessage = label + getLocalizedStrings().message.DUPLICATE_RULE.ERROR_MSG_SAME_FIELD;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } catch (error) {
            console.error('Error in duplicateRules.js -> handleFieldMenuChange() : ' + error);
        }
    }

    const verifyRulesDataParamsForSave = () => {
        try {
            let rules = getArrayParam([...ruleData]);
            let rulesData = [];
            if (rules.length > 0) {
                for (let i = 0; i < rules.length; i++) {
                    let checkData = false;
                    let checkData1 = false;
                    let fieldsData = rules[i].field_names;
                    for (let j = 0; j < fieldsData.length; j++) {
                        if (rules[i].is_active === 1 && fieldsData[j].field_name === "-999") {
                            let errorMessage = getLocalizedStrings().message.DUPLICATE_RULE.ERROR_MSG_FOR_FIELDS;
                            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            return false;
                        } else if (rules[i].is_active === 0 && fieldsData[j].field_name !== "-999") {
                            checkData = true;
                        } else if (rules[i].is_active === 0 && fieldsData[j].field_name === "-999") {
                            checkData1 = true;
                        }
                    }
                    if (checkData && !checkData1) {
                        rulesData.push(rules[i]);
                    } else if (!checkData && !checkData1) {
                        rulesData.push(rules[i]);
                    }
                }
                let params = {};
                params.rules = rulesData;
                saveDuplicateRules(params);
            } else {
                let errorMessage = getLocalizedStrings().message.DUPLICATE_RULE.ERROR_MSG_1;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        } catch (error) {
            console.error('Error in duplicateRules.js -> verifyRulesDataParamsForSave() : ' + error);
        }
    }

    const saveDuplicateRules = (params) => {
        try {
            if (isValidParam(params)) {
                setIsRefresh(true);
                const promise = Promise.resolve(HTTPClient.post(endPoints.DUPLICATE_RULE.SAVE, params));
                promise.then((response) => {
                    if (response.status !== -1) {
                        let ruleData = [];
                        for (let i = 0; i < totalRuleCount; i++) {
                            if (response.data.some(e => e.rule_no === i + 1)) {
                                let tempdata = response.data.filter(e => e.rule_no === i + 1);
                                ruleData.push(tempdata[0]);
                            } else {
                                ruleData.push(generateRuleObject(objectSelected, i + 1, 0, 0));
                            }
                        }
                        setIsRefresh(false);
                        setRuleData(ruleData);
                        
                        let successMessage = getLocalizedStrings().message.DUPLICATE_RULE.SUCCESS_MSG_1;
                        dispatch(showCustomSnackBar(successMessage, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    } else if (response.status === -1) {
                        let errorMessage = getLocalizedStrings().message.DUPLICATE_RULE.ERROR_MSG_1;
                        dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                });
            } else {
                let errorMessage = getLocalizedStrings().message.DUPLICATE_RULE.ERROR_MSG_1;
                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }

        } catch (error) {
            console.error('Error in duplicateRules.js -> saveDuplicateRules() : ' + error);
        }
    }

    if (!isRefresh) {
        return (
            <div className='container'>
                <div className="row" style={{ paddingRight: '13px' }}>
                    <div className="col-sm-9" style={{ paddingTop: '15px' }}>
                        <span style={{ marginLeft: '20px', fontSize: '20px' }}>{getLocalizedStrings().label.DUPLICATE_RULE.DUPLICATE_RULE_HEAD_LABEL}</span>
                    </div>
                    <div className="col-sm-3">
                        <div style={{ paddingTop: '15px', paddingBottom: '12px', overflow: 'hidden', float: 'right' }}>
                            <Button
                                key='save'
                                onClick={() => verifyRulesDataParamsForSave()}
                                style={{ ...styles.primaryButton, marginRight: '12px', verticalAlign: 'top' }}
                            > {getLocalizedStrings().label.COMMON.SAVE}</Button>
                        </div>
                    </div>
                </div>
                   
                <div className="col-sm-12">
                    <FormControl style={{ width: '24%', marginLeft: '20px' }} variant="outlined">
                        <InputLabel id="sf-dupilcates-simple-select-outlined-label" className="sf-dupilcaterules">{getLocalizedStrings().label.DUPLICATE_RULE.SELECT_OBJECT_LABEL}</InputLabel>
                        <Select
                            labelId='sf-label-objectList'
                            id='sf-objectList'
                            label={getLocalizedStrings().label.DUPLICATE_RULE.SELECT_OBJECT_LABEL}
                            name="objectList"
                            value={objectSelected}
                            onChange={(e) => handleOnClickObjectDropDown(e)}
                            style={{ height: '42px' }}
                            className={"sf-fields-bg"}>
                            {objectList}
                        </Select>
                    </FormControl>
                </div>

                { generateViewForRules() }

                <div className="col-sm-12">
                    <div className="col-sm-6" style={{ paddingTop: '15px' }}>
                    </div>
                    <div style={{ paddingTop: '15px', paddingBottom: '12px', overflow: 'hidden', float: 'right', marginRight: '10px' }}>
                        <div className="col-sm-6" style={{ width: 'auto', padding: '0px' }}>
                            <Button
                                key='save1'
                                onClick={() => verifyRulesDataParamsForSave()}
                                label={getLocalizedStrings().label.COMMON.SAVE}
                                style={{ ...styles.primaryButton, marginRight: '12px', verticalAlign: 'top' }}
                            >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                        </div>
                    </div>
                </div>
            </div>
            
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2;
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
}

export default DuplicateRules;