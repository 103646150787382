import React from 'react';
import { Menu, MenuItem, Button, Icon, Popover, ListItem, ListItemButton, ListItemText, Box, Typography, backdropClasses } from '@mui/material';
import { isValidParam, getStringParam, getBooleanParam, getArrayParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { connect } from 'react-redux';
import 'flatpickr/dist/flatpickr.min.css';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { dateTimeFormat, dateFormat } from '../../../services/helper/utils';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { styled } from '@mui/material/styles';
import { Margin } from '@mui/icons-material';

const mapStateToProps = (state) => {
    return {
        app: state.app,
        detailViewTaskAppintmentReducer: state.detailViewTaskAppintmentReducer

    };
};

const mapDispatchToProps = (dispatch) => {
    return {

    }
};


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    padding: `0px`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
    
    style={{margin:0}}
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem',padding:'2px' }} />}
        {...props}
    />
))(() => ({
    backgroundColor: '#fff',
    minHeight:'0px',
    flexDirection: 'row-reverse',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
        transform: 'rotate(90deg)',
    },
    [`& .${accordionSummaryClasses.content}`]: {
       margin:'2px'
    },
    [`&:hover`]: {
   backgroundColor:'#f0f0f0'
     },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: 0,
}));

class PopOver extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            openAccordion: false,
            openPopOverList: false,
            anchorEl: null,
            value: this.props.value,
            pos: {},
            datePkrOpen: false,
            datePkrAnchor: null,
            btnText: null,
            btnLabel: null,
            showValue: ""
        }
    }
    componentDidMount() {
        let props = this.props;
        let _state = this.state;
        let value = isValidParam(props.value) ? props.value : null;
        let showValue = isValidParam(props.value) ? Date.parse(props.value) : null;
        _state.btnLabel = value;
        _state.value = value;
        _state.btnText = value;
        _state.showValue = showValue;
        this.setState(_state);
    }

    UNSAFE_componentWillReceiveProps(props) {
        let _state = this.state;
        let value = isValidParam(props.value) ? props.value : null;
        let showValue = isValidParam(props.value) ? Date.parse(props.value) : null;
        if (_state.value !== value) {
            _state.value = value;
            _state.btnText = value;
            _state.btnLabel = value;
            _state.showValue = showValue;
            this.setState(_state);
        }
    }

    openPopOver = (event) => {
        let optionType = this.props.optionType ? getStringParam(this.props.optionType) : 'list ';
        if (optionType === 'accordion') {
            this.openAccordionPopOver(event);
        } else {
            this.openListPopOver(event);
        }
    }

    openListPopOver = (event) => {
        event.preventDefault();
        let isDisabled = isValidParam(this.props.disable) ? getBooleanParam(this.props.disable) : false;
        let buttonLineOrigin = isValidParam(this.props.buttonLineOrigin) ? this.props.buttonLineOrigin : [28, 28];
        let height = isValidParam(this.props.height) ? { height: this.props.height } : { height: 30 };
        let rect = event.currentTarget.getBoundingClientRect();
        let x = rect.x;
        let y = rect.y;
        let pos = {};
        pos.top = y + parseInt(height.height);
        pos.left = x - buttonLineOrigin[1];

        if (!isDisabled) {
            this.setState({
                openPopOverList: true,
                anchorEl: event.currentTarget,
                pos: pos
            });
        } else {
            this.setState({
                openPopOverList: false,
                anchorEl: null,
                pos: {},
            });
        }
    }
    openAccordionPopOver = (event) => {
        event.preventDefault();
        let isDisabled = isValidParam(this.props.disable) ? getBooleanParam(this.props.disable) : false;
        let buttonLineOrigin = isValidParam(this.props.buttonLineOrigin) ? this.props.buttonLineOrigin : [28, 28];
        let height = isValidParam(this.props.height) ? { height: this.props.height } : { height: 30 };
        let rect = event.currentTarget.getBoundingClientRect();
        let x = rect.x;
        let y = rect.y;
        let pos = {};
        pos.top = y + parseInt(height.height);
        pos.left = x - buttonLineOrigin[1];

        if (!isDisabled) {
            this.setState({
                openAccordion: true,
                anchorEl: event.currentTarget,
                pos: pos
            });
        } else {
            this.setState({
                openAccordion: false,
                anchorEl: null,
                pos: {},
            });
        }
    }

    closePopOver = () => {
        let _state = this.state;
        _state.datePkrOpen = false;
        _state.openAccordion = false;
        _state.openPopOverList = false;
        _state.anchorEl = null
        _state.pos = {};
        this.setState(_state);
    }

    generateMenuItems = () => {
        let actions = [];
        let arrActions = [];
        let btnType = isValidParam(this.props.btnType) ? this.props.btnType : "button"
        let viewSelectedMark = isValidParam(this.props.viewSelectedMark) ? this.props.viewSelectedMark : false;
        if (btnType !== 'date' && btnType !== 'dateTime') {
            arrActions = this.props.options;
        }
        let value = isValidParam(this.props.value) ? this.props.value : null;
        if (isValidParam(arrActions)) {
            arrActions.forEach((actionName, i) => {
                let labelstyle = isValidParam(actionName.labelStyle) ? { color: actionName.labelStyle.color, fontWeight: actionName.labelStyle.fontWeight } : { color: '#666666' };
                let menuItemStyle = isValidParam(this.props.menuItemStyle) ? { ...this.props.menuItemStyle } : {};
                let isDisabled = actionName.hasOwnProperty("disable") ? actionName.disable : false;
                actions.push(<MenuItem
                    key={actionName.value + i}
                    value={actionName.value}
                    selected={actionName.value === value}
                    style={{ ...styles.popoverMenuItem }}
                    fullwidth={true}
                    autowidth={true}
                    disabled={isDisabled}
                    onClick={this.onclickAction.bind(this, actionName.value)}>
                    <div style={{ ...menuItemStyle }}>
                        <span >
                            {actionName.leftIcon}
                        </span>
                        <span style={{ marginLeft: '4px', marginRight: '10px', ...labelstyle, color: this.props?.callFrom == "tasksFilter" && (actionName.label == this.props.detailViewTaskAppintmentReducer?.taskAppValue || actionName.label == this.props?.title) ? this.props.app.me.background : labelstyle }}>{actionName.label}</span>
                        {viewSelectedMark && actionName.value === value && <span style={{ right: 0, position: 'absolute', marginRight: '5px' }}><i class="fa fa-check" aria-hidden="true"></i></span>}
                    </div> </MenuItem>);
            });
        }
        return actions;
    }

    onclickAction = (value, event) => {
        console.log("onClickAction", value, event);
        let _state = this.state;
        _state.datePkrOpen = false;
        _state.openAccordion = false;
        _state.openPopOverList = false;
        _state.anchorEl = null;
        let optionType = this.props.optionType ? getStringParam(this.props.optionType) : 'list';
        let valueKey = this.props.valueKey ? getStringParam(this.props.valueKey) : '';
        if(optionType == 'list'){
            _state.value = value;
        }else{
            _state.value = value[valueKey];
        }
        _state.pos = {};
        this.setState(_state);
        if (isValidParam(value) && getStringParam(value) !== "") {
            this.props.onclickAction(value);
        }
    }
    openDatePiker = (event, isDefaultOpenDatePicker) => {
        let isOpenDatePiker = isValidParam(this.props.disable) ? getBooleanParam(this.props.disable) : false;
        let hasPermission = isValidParam(this.props.hasPermission) ? getBooleanParam(this.props.hasPermission) : true;
        let height = isValidParam(this.props.height) ? { height: this.props.height } : { height: 30 };
        let rect = event.currentTarget.getBoundingClientRect();
        let x = rect.x;
        let y = rect.y;
        let pos = {};
        pos.top = y + parseInt(height.height);
        pos.left = x;
        let isDisabled = isValidParam(this.props.disable) ? getBooleanParam(this.props.disable) : false;
        console.log(this.props)
        if (hasPermission) {
            if (!isOpenDatePiker && !isDefaultOpenDatePicker && !isDisabled) {
                this.setState({
                    datePkrOpen: true,
                    datePkrAnchor: event.currentTarget,
                    pos: pos
                });
            }
        }
        else {
            this.props.hasPermissionError();
        }
    }

    closeDatePiker = () => {
        setTimeout(() => {
            let _state = this.state
            let _value = _state.value;
            _state.datePkrOpen = false;
            _state.datePkrAnchor = null;
            this.setState({ ..._state });
            if (isValidParam(this.props.onClose)) {
                this.props.onClose(_value)
            }
        }, 200);
    }

    handleDateChange = (data) => {
        let btnType = isValidParam(this.props.btnType) ? this.props.btnType : "button"
        let _state = this.state;
        let name = isValidParam(this.props.name) ? this.props.name : 'date-pop-over';
        let newDta = getStringParam(data);
        let tempDate = null;
        if (btnType === 'date' && newDta !== null) {
            tempDate = moment(getStringParam(newDta)).format(dateFormat[this.props.app.me.date_format]);
        } else {
            tempDate = moment(getStringParam(newDta)).format(dateTimeFormat[this.props.app.me.date_format]);
        }
        let showValue = Date.parse(newDta);
        _state.btnLabel = tempDate;
        _state.value = tempDate;
        _state.showValue = showValue;
        this.state = _state;
        let onChangeAction = isValidParam(this.props.onChange) ? true : false;
        if (onChangeAction) {
            this.props.onChange(tempDate, name);
        }
        let closeOnChange = isValidParam(this.props.closeOnChange) ? this.props.closeOnChange : true;
        if (closeOnChange && btnType === 'date') {
            this.closeDatePiker();
        }
    }
    getButtonLabelBYValue = (value, options, buttonLabel) => {
        let label = null;
        if (value !== undefined && options.length > 0) {
            let selectValue = options.find(v => v.value === value);
            label = selectValue !== undefined && selectValue !== null && isValidParam(selectValue.label) ? selectValue.label : value;
        } else {
            label = buttonLabel;
        }
        return label
    }
    changeLink = (key, name) => {
        var idName = key + name;
        if (document.getElementById(idName).style.textDecoration === 'underline') {
            document.getElementById(idName).style.textDecoration = 'none';
        }
        else {
            document.getElementById(idName).style.textDecoration = 'underline'
        }
    }
    handleChange = (panel) => {
        let _state = this.state;
        _state.expanded = _state.expanded == panel ? null : panel;
        this.setState({ ..._state });
    };

    render() {
        let tempArr = [];
        let isShowIcon = isValidParam(this.props.isShowIcon) ? getBooleanParam(this.props.isShowIcon) : false;
        let isShowLabelIcon = isValidParam(this.props.isShowLabelIcon) ? getBooleanParam(this.props.isShowLabelIcon) : false;
        let buttonLabelPostion = isValidParam(this.props.labelPosition) && getStringParam(this.props.labelPosition) !== "" ? getStringParam(this.props.labelPosition) : "before";
        let id = isValidParam(this.props.id) && getStringParam(this.props.id) !== "" ? getStringParam(this.props.id) : Math.floor(Math.random() * 10);
        let key = isValidParam(this.props.key) && getStringParam(this.props.key) !== "" ? getStringParam(this.props.key) : Math.floor(Math.random() * 100);
        let className = isValidParam(this.props.className) && getStringParam(this.props.className) !== "" ? getStringParam(this.props.className) : 'overflow-y-initial';
        let title = isValidParam(this.props.title) && getStringParam(this.props.title) !== "" ? getStringParam(this.props.title) : "";
        let style = isValidParam(this.props.buttonStyle) ? this.props.buttonStyle : { fontWeight: 'bold', paddingLeft: '2px', paddingTop: '5px' };
        let value = isValidParam(this.state.value) ? this.state.value : "";
        let buttonLabel = isValidParam(this.props.buttonLabel) ? this.props.buttonLabel : "";
        let placeholder = isValidParam(this.props.placeholder) ? this.props.placeholder : "";
        let name = isValidParam(this.state.name) ? this.state.name : 'pop-over';
        let containerStyle = isValidParam(this.props.containerStyle) ? this.props.containerStyle : { width: '100%' };
        let buttonEndIcon = isValidParam(this.props.buttonEndIcon) && isShowIcon ? this.props.buttonEndIcon.split(",") : this.props.buttonEndIcon;
        let isDisabled = isValidParam(this.props.disable) ? getBooleanParam(this.props.disable) : false;
        if (isShowIcon) {
            buttonEndIcon.forEach((icon, i) => {
                tempArr.push(<Icon title={title} name="popOverIcon" key={"icon" + key + '#' + i} id={"icon" + id + '#' + i} style={style} onClick={this.openPopOver.bind(this)} >{icon}</Icon>);

            });
        }
        let btnType = isValidParam(this.props.btnType) ? this.props.btnType : "button";
        let btnLabelStyle = isValidParam(this.props.btnLabelStyle) ? getObjectParam(this.props.btnLabelStyle) : { fontSize: 14 };
        let btnLabelClass = isValidParam(this.props.btnLabelClass) ? getStringParam(this.props.btnLabelClass) : 'btn-label';
        let btnIconSpanStyle = isValidParam(this.props.btnIconSpanStyle) ? getObjectParam(this.props.btnIconSpanStyle) : { fontSize: 14 };
        let btnIconSpanClass = isValidParam(this.props.btnIconSpanClass) ? getStringParam(this.props.btnIconSpanClass) : 'btn-span';
        let btnIconClass = isValidParam(this.props.btnIconClass) ? getStringParam(this.props.btnIconClass) : null;
        let btnIconStyle = isValidParam(this.props.btnIconStyle) ? getObjectParam(this.props.btnIconStyle) : { fontSize: 14 };
        let btnIconTitle = isValidParam(this.props.btnIconTitle) ? getStringParam(this.props.btnIconTitle) : null;
        let btnIconText = isValidParam(this.props.btnIconText) ? getStringParam(this.props.btnIconText) : null;
        let defaultButtonLabelstyle = { backgroundColor: 'rgb(255, 255, 255, 0.81)', marginTop: '-2px', padding: '-1px', paddingLeft: 5, paddingRight: 4, fontSize: 11, fontWeight: 500, color: '#717171' };
        if (buttonLabel != "") {
            defaultButtonLabelstyle = { backgroundColor: 'rgb(255, 255, 255)', lineHeight: '0.1', marginTop: '-2px', padding: '-1px', paddingLeft: 5, paddingRight: 4, fontSize: 12, fontWeight: 500, color: '#717171', position: 'absolute' }
        }
        let buttonOuterLabelStyle = isValidParam(this.props.buttonOuterLabelStyle) ? getObjectParam(this.props.buttonOuterLabelStyle) : defaultButtonLabelstyle;
        let downArrowStyle = isValidParam(this.props.downArrowStyle) ? this.props.downArrowStyle : { marginTop: '2px' };
        let width = isValidParam(this.props.width) ? { width: this.props.width } : { width: "100%" };
        let height = isValidParam(this.props.height) ? { height: this.props.height } : { height: 30 };
        let valueHeight = isValidParam(this.props.height) ? { height: this.props.height - 5 } : { height: 25 };
        let divStyle = isValidParam(this.props.divStyle) ? (this.props.divStyle) : { color: 'rgb(97, 97, 97)', border: '1px solid #c4c4c4', borderRadius: '4px', background: '#fff', padding: '1px 10px' };

        if (isDisabled) {
            divStyle.color = 'rgba(0, 0, 0, 0.38)';
        }
        let iconWidthStyle = null;
        let innerIcon = isValidParam(this.props.innerIcon) ? (this.props.innerIcon) : "right"
        if (isShowIcon && innerIcon === 'right') {
            iconWidthStyle = { ...width, display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }
        } else {
            iconWidthStyle = { display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }

        }
        let innerLabelStyle = isValidParam(this.props.innerLabelStyle) ? this.props.innerLabelStyle : { fontSize: '15px' };
        let tempdatePickerOptions = {
            enableTime: false,
            minuteIncrement: 1,
            static: true,
            time_24hr: false
        };
        let propDatePickerOptions = isValidParam(this.props.options) ? this.props.options : tempdatePickerOptions;
        let datePickerOptions = { ...propDatePickerOptions, inline: true }

        let btnViewType = isValidParam(this.props.btnViewType) ? this.props.btnViewType : 'div';
        let optionsArr = [];
        optionsArr = getArrayParam(this.props.options) ? getArrayParam(this.props.options) : [];
        let isDefaultOpenDatePicker = isValidParam(this.props.openedPickr) ? this.props.openedPickr : false;
        let valueLabel = this.props.value !== null && this.props.value !== "" ? this.getButtonLabelBYValue(value, optionsArr, buttonLabel) : buttonLabel;
        let optionType = this.props.optionType ? getStringParam(this.props.optionType) : 'list';
        let nestKey = this.props.nestKey ? getStringParam(this.props.nestKey) : '';
        let nestValue = this.props.nestValue ? getStringParam(this.props.nestValue) : '';
        return (
            <div style={containerStyle}>
                {!isShowIcon && btnType === "button" &&
                    <Button
                        aria-controls={"menu" + id}
                        aria-haspopup={true}
                        name="popOverButton"
                        key={"button" + key}
                        id={"button" + id}
                        endIcon={<Icon style={{ fontWeight: 'bold' }} >{buttonEndIcon}</Icon>}
                        onClick={this.openPopOver.bind(this)}
                        labelposition={buttonLabelPostion}
                        style={this.props.buttonStyle}
                        className={className}
                        title={title}
                    >
                        {buttonLabel}
                    </Button>
                }
                {isShowIcon && btnType === "button" &&
                    <span>{tempArr}</span>
                }
                {btnType === "div" &&
                    <div
                        name={name}
                        key={"button" + key}
                        id={"button" + id}
                        onClick={(e) => this.openPopOver(e)}
                        title={this.props.value}
                        style={{ ...divStyle, ...width, ...height, ...{ cursor: this.props.disable ? 'not-allowed' : 'pointer' } }}
                    >
                        <div style={{ marginTop: '0px', position: 'relative' }}>
                            <label style={{ ...buttonOuterLabelStyle }}>{buttonLabel}</label>
                            <span style={{ ...innerLabelStyle, ...valueHeight, ...{ display: 'flex' } }}>
                                <div style={iconWidthStyle} >
                                    <span id='sf-popOver-select-label'  >{valueLabel} </span>
                                </div>
                                {isShowIcon && <div style={downArrowStyle}>
                                    <i className="fas fa-sort-down"></i>
                                </div>}
                            </span>
                            <div >
                            </div>
                        </div>
                    </div>}
                {btnType === "icon-div" &&
                    <div
                        name={name}
                        key={"button" + key}
                        id={"button" + id}
                        onClick={this.openPopOver.bind(this)}
                        title={this.props.value}
                        style={{ ...divStyle, ...width, ...height, ...{ cursor: this.props.disable ? 'not-allowed' : 'pointer' } }}
                    >
                        <div>
                            <span style={{ ...innerLabelStyle, ...valueHeight, ...{ display: 'flex' } }}>
                                <div style={iconWidthStyle} >
                                    <span id='sf-popOver-select-label'  >{buttonLabel}</span>
                                </div>
                                {isShowIcon && <div style={downArrowStyle}>
                                    <i className="fas fa-sort-down"></i>
                                </div>}
                            </span>
                            <div >
                            </div>
                        </div>
                    </div>
                }
                {(btnType === "date" || btnType === "dateTime") && <div>
                    {btnViewType === 'div' && <div style={{ marginTop: '0px', position: 'relative' }}>
                        {!isDefaultOpenDatePicker && <div
                            name={name}
                            key={"button" + key}
                            id={"button" + id}
                            onClick={(e) => this.openDatePiker(e, isDefaultOpenDatePicker)}
                            title={this.state.value}
                            style={{ ...divStyle, ...width, ...height, ...{ cursor: this.props.disable ? 'not-allowed' : 'pointer' } }}
                        >
                            <div>
                                {buttonLabel && buttonLabel !== '' && value !== '' ? <label style={{ ...buttonOuterLabelStyle }}>{buttonLabel}</label> : null}
                                <span style={{ ...{ display: 'flex' }, ...valueHeight, ...innerLabelStyle }}>
                                    <div style={iconWidthStyle} >
                                        <span id='sf-popOver-select-label'  >{this.state.btnLabel !== null && this.state.btnLabel !== "" ? <span>{this.state.btnLabel}</span> : <span>{buttonLabel !== '' && buttonLabel != null ? buttonLabel : placeholder}</span>}</span>
                                    </div>
                                </span>
                                <div >
                                </div>
                            </div>
                        </div>}
                        {isDefaultOpenDatePicker && <div key={"button" + key} style={{ ...width, background: 'white', borderRadius: '4px', border: '1px solid rgb(196, 196, 196)' }}>
                            <div>
                                <div
                                    title={this.state.value}
                                    style={{ ...divStyle, ...width, ...height, border: '0px' }} >
                                    <div>
                                        {this.state.btnLabel !== null ? <label style={{ ...buttonOuterLabelStyle }}>Due by</label> : null}
                                        <span style={{ ...innerLabelStyle, ...valueHeight, ...{ display: 'flex' } }}>
                                            <div style={iconWidthStyle} >
                                                <span id='sf-popOver-select-label'  >{this.state.btnLabel !== null ? <span>{this.state.btnLabel}</span> : <span>{buttonLabel}</span>}</span>
                                            </div>
                                        </span>
                                        <div >
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ borderBottom: '1px solid rgb(196, 196, 196)' }}></div>
                            <Flatpickr
                                onChange={this.handleDateChange.bind(this)}
                                value={this.state.showValue}
                                options={datePickerOptions}
                                className='flatpickr-on-a-popover'
                            />
                        </div>}
                    </div>}
                    {btnViewType === 'text' && <div>
                        <div name={name}
                            key={"button" + key}
                            id={"button" + id}
                            title={buttonLabel}
                            onClick={(e) => this.openDatePiker(e, isDefaultOpenDatePicker)} style={{ cursor:this.props.disable ? 'not-allowed' : 'pointer' }}>
                            {isShowLabelIcon && <span style={btnIconSpanStyle} title={btnIconTitle} className={btnIconSpanClass}>
                                <i className={btnIconClass} aria-hidden="true" style={btnIconStyle}>{btnIconText}</i>
                            </span>}
                            <div className={btnLabelClass} style={btnLabelStyle}><span id={key + 'date'} onMouseOver={() => this.changeLink(key, 'date')} onMouseLeave={() => this.changeLink(key, 'date')} >{buttonLabel}</span></div>

                        </div>
                    </div>}
                </div>}
                {btnType === "text" &&
                    <div
                        name={name}
                        key={"button" + key}
                        id={"button" + id}
                        onClick={(e) => this.openPopOver(e)}
                        title={this.state.value}
                        style={{ ...width, ...{ cursor: this.props.disable ? 'not-allowed' : 'pointer', fontWeight: 'bold' } }}
                    >
                        <div>
                            <span style={{ ...innerLabelStyle, ...{ display: 'flex' } }}>
                                <div style={{ ...iconWidthStyle, ...{ fontWeight: 'bold' } }} >
                                    <span id='sf-popOver-select-label' style={{ fontWeight: 'bold' }} >{this.state.value}</span>
                                </div>
                                <div style={{ downArrowStyle, ...{ paddingLeft: 5 } }}>
                                    <i className="fas fa-sort-down"></i>
                                </div>
                            </span>
                            <div >
                            </div>
                        </div>
                    </div>
                }
                <Popover
                    className="c-date-picker"
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: this.state.pos.top, left: this.state.pos.left }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={this.state.datePkrOpen}
                    anchorEl={this.state.datePkrAnchor}
                    onClose={() => this.closeDatePiker()}>
                    <div className="dtpkr-tabs">
                        <Flatpickr
                            onChange={this.handleDateChange.bind(this)}
                            value={this.state.showValue}
                            options={datePickerOptions}
                            className='flatpickr-on-a-popover'
                        />
                    </div>
                </Popover>
                {optionType == 'accordion' && <Popover
                    id={id}
                    open={this.state.openAccordion}
                    anchorEl={this.state.anchorEl}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: this.state.pos.top, left: this.state.pos.left }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={() => this.closePopOver()}
                >
                    <div >
                        {optionsArr.map((_item, index) => {
                            if (_item[nestKey]) {
                                return (
                                    <Accordion
                                        expanded={this.state.expanded === `accordion-${index}`}
                                        onChange={() => this.handleChange(`accordion-${index}`)}
                                    >
                                        <AccordionSummary
                                            aria-controls="panel1d-content"
                                            id="panel1d-header"
                                            style={{padding : 5, backgroundColor:_item.value == value ?'#f0f0f0':'#fff'}}
                                        >
                                                {_item.label || ''}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div style={{ width: '100%', maxHeight: 250, maxWidth: 360 }}>
                                                {_item[nestKey].map((__item, index) => {
                                                    return (
                                                         <MenuItem key={index} style={{marginLeft:'20px', marginTop:'2px', padding:'5px', backgroundColor:(__item.value == nestValue && _item.value == value ) ?'#f0f0f0':'#fff' }} onClick={this.onclickAction.bind(this, __item)}> {__item.label || ''}</MenuItem>
                                                )
                                                })}
                                            </div> 
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            } else {
                                return <MenuItem onClick={this.onclickAction.bind(this, _item)} style={{margin:0}}> {_item.label || ''}</MenuItem>
                            }
                        })
                        }
                    </div>
                </Popover>}
                {optionType == 'list' && <Menu
                    name={"menu" + id + 'name'}
                    key={"menu" + id + 'key'}
                    id={"menu" + id + 'id'}
                    className="c-popOverButton"
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: this.state.pos.top, left: this.state.pos.left }}
                    open={this.state.openPopOverList}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.closePopOver()}
                >
                    {this.generateMenuItems()}
                </Menu>}
            </div >
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PopOver);