import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector } from "react-redux";
import { styles } from '../../../services/constants/styles';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { isValidParam, getArrayParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { Button } from '@mui/material';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import * as sfDialogs from './sfDialogs';
import ShowCircularProgress from '../components/circularProgress';
import { saveUnitPlanNames } from '../../../services/actions/treeViewAcions';
import TextField from '@mui/material/TextField';
import { refreshListView } from '../../../services/actions/listViewAcions';
import { constants } from '../../../services/constants/constants';
import Autocomplete from '@mui/material/Autocomplete';
import { getUniqueId } from '../../../services/helper/utils';
import PopOver from './PopOver';
import { dateFormat } from '../../../services/helper/utils';
import moment from 'moment';
import { isEmpty,getStringParam } from '../../../services/helper/parameterVerifier';


const UnitPlanName = ({ data }) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);

    const [allRoyaltyPlans, setAllRoyaltyPlans] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [isAvailablePlan, setIsAvailablePlan] = useState(true);
    const [isSavedDisabled, setIsSavedDisabled] = useState(false);
    const [selectedPlanNames, setSelectedPlanNames] = useState([]);
    const [previousPlanNames, setPreviousPlanNames] = useState([]);
    const [subscriptionDate,setSubscriptionDate] = useState(null);
    const [currentSelectedPlan,setCurrentSelectedPlan] = useState([]);
    
    useEffect(() => {
        getDetails();
    }, []);


    const getDetails = () => {
        try {
            let id = data.recordId;
            let url = endPoints.UNITS.GET_PLAN_NAMES;
            let params = { recordId: id };
            let promise = Promise.resolve(HTTPClient.get(url, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (getArrayParam(response.allRoyaltyPlans).length > 0) {
                            const selectedPlanName = response.exitingPlans.map(name => name.split(':')[0].trim());
                            const formattedSelectedPlanName = selectedPlanName.map(item=>({name: item, id: Number(getUniqueId())}))
                            const allRoyaltyPlans = [...formattedSelectedPlanName, ...response.allRoyaltyPlans]
                            const filteredPlans = allRoyaltyPlans.filter(plan => selectedPlanName.includes(plan.name));
                            setPreviousPlanNames(response.exitingPlans)
                            setAllRoyaltyPlans(allRoyaltyPlans);
                            setSelectedPlanNames(filteredPlans);
                            setMounted(true);
                        } else {
                            setIsAvailablePlan(false);
                            setMounted(false);
                        }
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'unitPlanName.js -> getDetails()':" + error);
        }
    }


    const addUnitPlanName = () => {
        let isValidRequest = true;
        let subscription_date = moment(subscriptionDate).format("YYYY-MM-DD");
        if (selectedPlanNames.length === 0) {
            isValidRequest = false;
            dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_PLAN_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }

        if (currentSelectedPlan.length > 0 && (getArrayParam(currentSelectedPlan) && isEmpty(getStringParam(subscriptionDate)))) {
            isValidRequest = false;
            dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_SUBSCRIPTION_DATE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            return false;
        }
                    
        if(getStringParam(subscriptionDate) && isEmpty(getStringParam(currentSelectedPlan))){
            isValidRequest = false;
            dispatch(showCustomSnackBar(getLocalizedStrings().message.UNITS.BLANK_PLAN_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            setSubscriptionDate("");
            return false;
        }


        if (isValidRequest) {
            setIsSavedDisabled(true);
            let params = {};
            let selectedPlans = '';
            selectedPlanNames.forEach(item => {
                const found = previousPlanNames.find(prevItem => prevItem.split(":")[0] === item.name);
                if (found) {
                    selectedPlans += `${found},`;
                } else {
                    selectedPlans += `${item.name}:${subscription_date},`;
                }
            });
            
            let selectedPlansMessage = selectedPlanNames.map(item=>item.name).join(', ');
            const message = "<b>" + data.unitName + "</b> will be enrolled in Plan <b>" + selectedPlansMessage + "</b>. Are you sure?"
            params.recordId = data.recordId;
            params.plan_names = selectedPlans.slice(0, -1);
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, message, () => {
                let promise = Promise.resolve(saveUnitPlanNames(params));
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response)) {
                            if (response.status === 0) {
                                setIsSavedDisabled(false);
                                dispatch(getAppDrawer(false, null, null, null, null));
                                refreshListView(constants.UNITS_OBJECT);    
                                dispatch(showCustomSnackBar("Plan Name(s) updated successfully", styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            }
                        }
                    });
                }
            }, () => {
                setIsSavedDisabled(false);
            });
        }
    }

    const handleOnChange = (_, value) => {
        setSelectedPlanNames(value);
        // Filter plans that are newly selected (not in previousPlanNames)
        const filteredPlans = value.filter(plan => 
            !previousPlanNames.some(prevPlan => prevPlan.split(":")[0] === plan.name)
        );
        setCurrentSelectedPlan(filteredPlans);
    };

    function handleSubscriptionpkrDateChange(date) {
        try {
            if (isValidParam(date)) {
                let selectedDate = new Date(date);
                let dateValue = new Date(
                    selectedDate.getFullYear(),
                    selectedDate.getMonth(),
                    selectedDate.getDate()
                );
                const momentDate = moment(dateValue);

                setSubscriptionDate(momentDate.format(dateFormat[app.me.date_format]));

            } else {
                setSubscriptionDate("");
            }
        } catch (error) {
            console.error(
                "Error in 'addUnits.js -> handleSubscriptionpkrDateChange()':" + error
            );
        }
    }

    if (mounted) {
        return (
            <div style={{ paddingLeft: '30px', paddingRight: '30px', marginTop: '10px' }}>
                <div style={{ width: '100%' }}>
                    <Autocomplete
                        multiple
                        id={"autocomplete_royaltyplans"}
                        options={allRoyaltyPlans}
                        value={selectedPlanNames}
                        getOptionLabel={(option) => option.name}
                        getOptionDisabled={(option) => option.disabled === true}
                        disableClearable={false}
                        renderInput={(params) => (
                            <TextField {...params} margin='dense' size='small' label="Royalty Plans" placeholder="Select Royalty Plans" />
                        )}
                        onChange={handleOnChange}   
                        ListboxProps={
                            {
                                style: { maxHeight: selectedPlanNames.length >20 ? "8rem" :''} }
                            }             
                    />
                </div>
                <div style={{ margin: "15px 0", width: "100%" }} className={"sf-form-field dateTimeDiv-fa-expiration-date"} id="dateholder">
                    <PopOver
                        id={'field-ref-fa-expiration-date'}
                        key={"fa-subscription-date"}
                        name={"fa-subscription-date"}
                        btnType={"date"}
                        buttonOuterLabelStyle={{ backgroundColor: '#fff', lineHeight: '0.1', marginTop: '-2px', padding: '-1px', paddingLeft: 5, paddingRight: 4, fontSize: 11, fontWeight: 500, position: 'absolute' }}
                        buttonStyle={{ fontSize: "16px" }}
                        containerStyle={{ lineHeight: 1 }}
                        buttonLabel={getLocalizedStrings().label.UNITS.SUBSCRIPTION_DATE}
                        value={subscriptionDate}
                        onChange={(date) => handleSubscriptionpkrDateChange(date)}
                        innerLabelStyle={{
                            padding: "11 5 2 5",
                            fontSize: "15px",
                        }}
                        height={40}
                    
                    />
                </div>
                <Button
                    key='save'
                    onClick={() => addUnitPlanName()}
                    style={{ ...styles.primaryButton, marginRight: '-1%', marginTop: '15px', verticalAlign: 'top', float: 'Right', marginBottom: '10px' }}
                    disabled={isSavedDisabled}
                >{getLocalizedStrings().label.COMMON.SAVE}</Button>
            </div>
        );
    } else if (!isAvailablePlan) {
        return (<div style={{ marginLeft: '10px', marginTop: '10px' }}>
            {getLocalizedStrings().message.UNITS.NO_AVAILABLE_PLANS}
        </div>
        );
    } else {
        let contentHeight = window.innerHeight - 250;
        let top = (contentHeight - 10) / 2;
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    };
};

export default UnitPlanName;
