import React from 'react';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, FormControl, Select as SelectField, MenuItem, InputLabel} from '@mui/material';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { connect } from 'react-redux';
import { isValidParam, isFloat, isInteger, makeValidAppUrl, getArrayParam, getStringParam, getBooleanParam, getIntParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { getFieldListValuesPromise } from '../../../services/actions/sfFormActions';
import 'flatpickr/dist/flatpickr.min.css';
import Flatpickr from 'react-flatpickr';
import { dateTimeFormat, dateFormat, timeFormat, timeZone, dateFormatFlatPicker, dateTimeFormatFlatPicker, getDateObject, sortArrayObjectByProperty } from '../../../services/helper/utils';
import moment from 'moment';
import tz from 'moment-timezone';
import * as sfDialogs from '../components/sfDialogs';
import replace from 'lodash/replace';
import SFAutoComplete from '../components/sfAutoComplete';

class WebhookRuleCriteria extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: {
                id: 0,
                t_formid: this.props.object.charAt(0).toUpperCase() + this.props.object.slice(1),
                t_name: '',
                sql_array: []

            },
            error: {
                name: ''
            },
        }
    }

    componentDidMount() {
        if (this.props.criteria != undefined && Object.keys(this.props.criteria).length > 0) {
            this.setState({ filter: { ... this.props.criteria } })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.criteria != undefined && Object.keys(nextProps.criteria).length > 0 && JSON.stringify(this.props.criteria) != JSON.stringify(nextProps.criteria)) {
            this.setState({ filter: { ...nextProps.criteria } })
        }
        else if (nextProps.criteria != undefined && Object.keys(nextProps.criteria).length == 0 && JSON.stringify(this.props.criteria) != JSON.stringify(nextProps.criteria)) {
            this.setState({
                filter: {
                    id: 0,
                    t_formid: 0,
                    t_name: '',
                    sql_array: []

                },
            });
        }
        else if (isValidParam(nextProps.object)) {
            let filterObj = this.state.filter;
            filterObj.t_formid = this.props.object.charAt(0).toUpperCase() + this.props.object.slice(1);
            this.setState({ filter: filterObj });
        }
    }

    handleChangeFilterTextField = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
        this.state.filter.t_name = value;
        if (value.trim() !== '') {
            this.state.error.name = "";
        }

        if (this.props.setCriteriaInfo != undefined) {
            this.props.setCriteriaInfo(this.state.filter)
        }
        this.setState(this.state);
    }

    updateFields = (fields) => {
        this.state.filter.sql_array = fields;
        if (this.props.setCriteriaInfo != undefined) {
            this.props.setCriteriaInfo(this.state.filter)
        }
        this.setState(this.state);
    }

    showCustomSnackBar = (message, bodyStyle, style) => {
        this.props.showCustomSnackBar(message, bodyStyle, style);
    }

    render() {

        return (
            <div>
                <Fields
                    object={this.props.data.table}
                    sfForm={this.props.sfForm}
                    fields={this.state.filter.sql_array}
                    updateFields={(fields) => { this.updateFields(fields) }}
                    showCustomSnackBar={this.showCustomSnackBar}
                    app={this.props.app}
                    fromFieldData={this.props.fromFieldData}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sfForm: state.sfForm,
        app: state.app
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
    };
};

WebhookRuleCriteria = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(WebhookRuleCriteria);
export default WebhookRuleCriteria;


let defaultOption = {
    label: 'Click here to add a field to the filter',
    value: ''
}

let condition = {
    t_value: '',
    t_operator: '=',
    t_logicalop: 'OR'
}

class Fields extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            rows: this.props.fields,
            field_options: [],
            field: {
                t_fldid: '',
                t_logicalop: '',
                t_operator: '',
                t_value: '',
                is_start: false,
                is_end: false
            },
            field_id: '',
            field_name: '',
            selected_field: {}
        }
    }

    componentDidMount() {
        this.setState({ rows: this.props.fields });
        this.generateFieldOptions(this.props.fromFieldData);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.state.rows = nextProps.fields;
        this.setState(this.state);
        this.generateFieldOptions(nextProps.fromFieldData);
    }

    addField = () => {
        let rows = getArrayParam(this.state.rows);
        if (rows.length >= 20) {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.HOME.ASSIGNMENT_RULES_ON_MAX_FIELD, null);
        } else {
            if (this.state.selected_field == '') {
                this.props.showCustomSnackBar('Please select a Field  Name.', styles.snackbarBodyStyleError, styles.snackBarStyleTop);
                return;
            }

            let selected_field = this.state.selected_field;
            let condition = {
                t_logicalop: '',
                t_operator: '=',
                t_value: '',
                conditionFieldProps: {
                    isOpenPopover: false,
                    popoverTarget: null,
                    isShowConditionValue: true
                }
            };

            if (this.state.rows.length != 0) {
                condition.t_logicalop = 'AND';
            }

            let newField = { t_fldid: selected_field.value, t_fldname: selected_field.label, conditions: [condition] };
            let fieldProps = this.getFiledPropsByFieldId(selected_field.value);
            newField = { ...newField, ...fieldProps };
            newField = getObjectParam(newField);
            let fieldName = isValidParam(newField.filedProps) ? getStringParam(newField.filedProps.name) : '';

            if (fieldName == 't_status') {
                let params = new Object();
                params.object = this.props.object;
                params.name = fieldName;
                params.list_type = constants.LIST_FIELD_TYPE_REGULAR;

                let promise = getFieldListValuesPromise(params);
                promise.then((response) => {
                    response = getArrayParam(response);
                    if (response.length > 0) {
                        sortArrayObjectByProperty(response, 'value');
                        newField.filedProps.list_values = response;
                        let rows = [...this.state.rows, newField];
                        rows = JSON.parse(JSON.stringify(rows));
                        this.removeFieldFromFieldList();
                        this.state.rows = rows;
                        this.props.updateFields(this.state.rows);
                    }
                });
            } else {
                let rows = [...  this.state.rows, newField];
                rows = JSON.parse(JSON.stringify(rows));
                this.removeFieldFromFieldList();
                this.state.rows = rows;
                this.props.updateFields(this.state.rows);
            }
        }
    }


    getFiledPropsByFieldId = (fileldId) => {
        let selectFieldProps = {};
        this.props.fromFieldData.fields.map((obj) => {
            if (obj.t_tablefldid == fileldId) {
                selectFieldProps['filedProps'] = obj;
            }
        });
        return selectFieldProps;
    }

    deleteField = (idx) => {
        let rows = this.state.rows;
        let field = {
            label: rows[idx].t_fldname,
            value: rows[idx].t_fldid
        };

        if (rows.length > 1 && idx == 0) {
            let row1 = rows[idx];
            let row2 = rows[idx + 1];
            row2.conditions[0].t_logicalop = '';
            rows.splice(idx, 1);
        } else {
            rows.splice(idx, 1);
        }

        rows = rows;
        this.setState({ rows: rows });

        this.addFieldToFieldList(field);
    }

    addFieldToFieldList = (field) => {
        this.state.field_options.push(field);
        this.state.selected_field = '';
        this.sortFieldOptionsList();

    }

    removeFieldFromFieldList = () => {
        let value = this.state.selected_field.value;
        let options = this.state.field_options.filter(option => option.value != value);
        this.state.field_options = options;
        this.state.selected_field = '';
    }

    generateFieldOptions = (fromFieldData) => {
        let options = [];
        if (isValidParam(fromFieldData)) {
            fromFieldData.fields.map(function (obj) {
                if (obj.field_type != 'header' && obj.label != '' && obj.name != 'workflow_id') {
                    options.push({
                        label: obj.label,
                        value: obj.t_tablefldid
                    });
                }
            });

            let withOutSelectOption = [];
            options.map((option) => {
                let containOption = false;
                this.state.rows.map((row) => {
                    if (option['value'] == row.t_fldid) {
                        containOption = true;
                    }
                });
                if (!containOption) {
                    withOutSelectOption.push(option);
                }
            });
            options = withOutSelectOption;

            this.state.field_options = options;
            this.sortFieldOptionsList();
            this.setState(this.state);
        }
    }

    sortFieldOptionsList = () => {
        this.state.field_options.sort(function (option1, option2) {
            let opt1 = option1.label.toLowerCase();
            let opt2 = option2.label.toLowerCase();
            if (opt1 < opt2)
                return -1;
            if (opt1 > opt2)
                return 1;
            return 0;
        });
    }

    handleFieldSelect = (event, value) => {
        this.state.selected_field = value;
        this.addField();
        this.setState(this.state);
    }

    setField = (conditions, index) => {
        this.state.rows[index].conditions = conditions;
        this.props.updateFields(this.state.rows)
    }

    generateFieldSData = () => {
        return this.state.rows.map((row, index) => {
            return (
                <Field
                    field={row}
                    deleteField={() => { this.deleteField(index) }}
                    index={index}
                    updateField={(conditions, index) => { this.setField(conditions, index) }}
                    app={this.props.app}
                />
            )
        }
        )
    }

    render() {
        if (this.state.rows == null || this.state.rows == 'undefined') {
            this.state.rows = [];
        }
        let fieldsData = this.generateFieldSData();
        const unique = this.state.field_options.filter((obj, index) => {
            return index === this.state.field_options.findIndex(o => obj.label === o.label && obj.value === o.value);
          });
        return (
            <div>
                {fieldsData}
                <div className="row">
                    <div>
                        <div className="row">
                            <div>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <Autocomplete
                                        name="field"
                                        id="field-add_fld"
                                        key={"field-add_fld" + getArrayParam(fieldsData).length}
                                        ref="field-add_fld"
                                        options={unique}
                                        onChange={this.handleFieldSelect}
                                        size="small"
                                        getOptionLabel={(option) => option.label}
                                        disableClearable={true}
                                        selectOnFocus={false}
                                        value={unique.find(option => option.value === this.state.selected_field) || null}
                                        renderInput={(params) => <TextField {...params} key="text-fld1"
                                            label={getLocalizedStrings().label.WEBHOOKS_RULES.CONDITION}
                                            variant="outlined" margin="dense" className={"sf-fields-bg"}
                                        />
                                        }
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


class Field extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            field_value: '',
            operator: '=',
            rows: [],
            error: {
                field_value: ''
            },
            filedProps: {}
        }
    }

    addCondition = () => {
        let condition = {
        }

        let rows = [
            ...this.state.rows,
            {
                t_value: '',
                t_operator: '=',
                t_logicalop: 'OR',
                conditionFieldProps: {
                    isOpenPopover: false,
                    popoverTarget: null,
                    isShowConditionValue: true
                }
            }
        ];
        this.state.rows = rows;
        this.setState(this.state);
        this.props.updateField(this.state.rows, this.props.index)
    }

    deleteCondition = (fieldIndex, idx) => {
        let rows = getArrayParam(this.state.rows);

        if (rows.length == 2 && idx == 0) {
            let cond1 = rows[idx];
            let cond2 = rows[idx + 1];
            let logicalOperator = cond1.t_logicalop;
            cond2.t_logicalop = logicalOperator;
            rows.splice(idx, 1);

        } else {
            rows.splice(idx, 1);
        }

        rows.forEach((obj, index) => {
            if (index == 0 && fieldIndex == 0) {
                obj.t_logicalop = '';
            }
        });

        rows = rows;
        this.setState({ rows: rows });
        this.props.updateField(this.state.rows, this.props.index);
        if (this.state.rows.length == 0) {
            this.props.deleteField();
        }
    }


    setCondition = (condition, index) => {
        this.state.rows[index] = condition;
        this.setState(this.state);
        this.props.updateField(this.state.rows, this.props.index);
    }

    componentDidMount() {
        this.state.rows = this.props.field.conditions;
        this.state.filedProps = this.props.field.filedProps;

        this.setState(this.state);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.state.rows = nextProps.field.conditions;
        this.state.filedProps = nextProps.field.filedProps;

        this.setState(this.state);
    }

    getConditionsData = () => {
        let fieldIndex = getIntParam(this.props.index);
        return this.state.rows.map((row, index) => {
            return (
                <Condition
                    condition={row}
                    deleteConditionRow={() => { this.deleteCondition(fieldIndex, index) }}
                    index={index}
                    field_name={this.props.field.t_fldname}
                    updateCondition={(condition, index) => { this.setCondition(condition, index) }}
                    filedProps={this.state.filedProps}
                    app={this.props.app}
                />
            )
        }
        )
    }

    changeRowCondition = (value) => {
        let condition = this.state.rows[0];
        condition.t_logicalop = value;
        this.setCondition(condition, 0);
        this.setState(this.state);
    }

    render() {
        let conditions = this.getConditionsData();
        conditions = getArrayParam(conditions);
        return (
            <div>
                {(this.props.index > 0 && this.state.rows.length >= 1) && <div style={{ marginBottom: 20 }}>

                    <div className="radio-group">
                        <div className={this.state.rows[0].t_logicalop.trim() == 'AND' ? 'active-radio' : 'inactive_radio'} onClick={() => { this.changeRowCondition('AND') }}>
                            <span>{getLocalizedStrings().label.FILTER.AND}</span>
                        </div>
                        <div className={this.state.rows[0].t_logicalop.trim() == 'OR' ? 'active-radio' : 'inactive_radio'} onClick={() => { this.changeRowCondition('OR') }}>
                            <span>{getLocalizedStrings().label.FILTER.OR}</span>
                        </div>
                    </div>

                </div>}
                <div 
                    style={{
                        border: '1px solid #000', 
                        borderRadius: '4px', 
                        padding: '10px', 
                        margin: '10px 0',
                        position: 'relative'
                    }}
                >
                    <span style={{position: 'absolute', top: '-12px', left: '10px' ,background: 'white', padding: '0 5px',color: 'black'}}>
                        Condition
                    </span>
                    <div className="custom_field_row" style={{marginTop:'20px'}}>
                        <div className="heading_row">
                            {this.props.field.t_fldname}
                        </div>
                        <div className="body_panel">
                            <div style={{paddingBottom:'0.5px'}}>
                                {conditions}
                            </div>
                            {conditions.length < 20 &&
                                <div style={{ paddingBottom: '10px', paddingLeft: '5px', paddingTop: '10px', color: '#6495ED' , marginTop: '8px'}}>
                                    <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={this.addCondition} title={getLocalizedStrings().label.FILTER.ADD_CONDITION}><a>{getLocalizedStrings().label.FILTER.ADD_ANOTHER}</a></span>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class Condition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            field_value: this.props.condition.t_value,
            operator: this.props.condition.t_operator,
            error: {
                field_value: ''
            },
            condition: { ...this.props.condition, selectedListIds: '', selectedListValues: getStringParam(this.props.condition.t_value), },
            isShowDate: false,
            fromDate: null,
            toDate: null,
            operator_options_string: [
                { label: getLocalizedStrings().label.FILTER.CONTAINS, value: 'LIKE' },
                { label: getLocalizedStrings().label.FILTER.DOES_NOT_CONTAINS, value: 'NOT_LIKE' },
                { label: getLocalizedStrings().label.FILTER.IS_EQUAL_TO, value: '=' },
                { label: getLocalizedStrings().label.FILTER.IS_NOT_EQUAL_TO, value: 'NOT_EQUAL' },
                { label: getLocalizedStrings().label.FILTER.IS_EMPTY, value: 'IS_NULL' },
            ],
            operator_options_number: [
                { label: getLocalizedStrings().label.FILTER.IS_GREATER_THAN, value: '>' },
                { label: getLocalizedStrings().label.FILTER.IS_GREATER_THAN_OR_EQUAL_TO, value: '>=' },
                { label: getLocalizedStrings().label.FILTER.IS_LESS_THAN, value: '<' },
                { label: getLocalizedStrings().label.FILTER.IS_LESS_THAN_OR_EQUAL_TO, value: '<=' }
            ],
        }
    }

    handleConditionOperatorChange = (event, value) => {
        value = value.value;
        this.state.condition.t_operator = value;
        this.state.condition.t_value = '';
        this.state.fromDate = null;
        this.state.toDate = null;

        if (value == "IS_NULL") {
            this.state.condition.conditionFieldProps.isShowConditionValue = false;
            this.state.condition.t_value = "";
        } else {
            this.state.condition.conditionFieldProps.isShowConditionValue = true;
        }
        this.props.updateCondition(this.state.condition, this.props.index);

    }

    handleChangeConditionTextField = (event) => {

        let target = event.target;
        let value = target.value;
        this.state.condition.t_value = value;

        this.setState(this.state);
        this.props.updateCondition(this.state.condition, this.props.index);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.state.condition = nextProps.condition;
        this.state.condition.selectedListValues = getStringParam(this.state.condition.t_value);
        this.setState(this.state);
    }

    changeRadioButton = (event, value) => {
        this.state.condition.t_logicalop = value;
        this.setState(this.state);
        this.props.updateCondition(this.state.condition, this.props.index);
    }

    setSelectFieldValue = (fieldName, fieldType, event, value) => {
        this.state.condition.t_value = value;
        this.state.condition.conditionFieldProps.isOpenPopover = false;
        this.state.condition.conditionFieldProps.popoverTarget = null;
        if (fieldType == constants.FIELD_TYPE_DATE || fieldType == constants.FIELD_TYPE_DATE_TIME) {
            if (value == 'Pick a Date.') {
                this.state.isShowDate = true;
                this.state.condition.t_value = '';
            } else {
                this.state.isShowDate = false;
            }
        }
        this.setState(this.state);
        this.props.updateCondition(this.state.condition, this.props.index);

    }

    handleDateChange = (fieldName, date) => {

        if (date.length > 0) {
            let selectedDate = new Date(date[0]);
            let dateValue = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), selectedDate.getHours(), selectedDate.getMinutes())
            const momentDate = moment(dateValue);
            if (fieldName == 'fromDate' || fieldName == 'toDate') {
                this.state[fieldName] = momentDate.format(dateFormat[this.props.app.me.date_format]);
                if (isValidParam(this.state.fromDate) && isValidParam(this.state.toDate)) {
                    this.state.condition.t_value = this.state.fromDate + ' 00:00 AND ' + this.state.toDate + ' 23:59';
                }
            } else {
                this.state.condition.t_value = momentDate.format(dateFormat[this.props.app.me.date_format]);
                let operator = getStringParam(this.state.condition.t_operator);
                if (operator == '>' || operator == '<=') {
                    this.state.condition.t_value = this.state.condition.t_value + " 23:59";
                }
            }
        } else {
            this.state.condition.t_value = '';
        }

        this.setState(this.state);
        this.props.updateCondition(this.state.condition, this.props.index);
    }

    generateFieldOperatorOptions = (field) => {

        if (field.field_type == constants.FIELD_TYPE_INTEGER
            || field.field_type == constants.FIELD_TYPE_FLOAT
            || field.field_type == constants.FIELD_TYPE_DATE
            || field.field_type == constants.FIELD_TYPE_DATE_TIME
        ) {
            let list = [this.state.operator_options_number, this.state.operator_options_string];
            return [...this.state.operator_options_number, ...this.state.operator_options_string];
        } else {
            return this.state.operator_options_string;
        }
    }

    generateTypeWiseTextFields = (field) => {
        let value = this.state.condition.t_value;
        let isShowDate = getBooleanParam(this.state.isShowDate);
        let HHFormat = new RegExp("HH");
        let datePickerOptions = {
            enableTime: false,
            dateFormat: dateFormatFlatPicker[this.props.app.me.date_format],
            time_24hr: HHFormat.test(this.props.app.me.date_format) ? true : false
        };

        field = getObjectParam(field);
        field = { ...field };
        if (field.name == 'sales_rep' && field.field_type == constants.FIELD_TYPE_LIST) {
            let values = getArrayParam(field.list_values);
         let isMePresent = values.some((f)=>  f.value== 'me()')
     if(isMePresent ==  false){
        values.push({ id: 'me()', value: 'me()' });
     }

            field.list_values = values;
        } else if (field.name == 'state' && field.field_type == constants.FIELD_TYPE_LIST) {
            field.list_values = new Array();
            field.field_type = constants.FIELD_TYPE_TEXT;
        } else if (field.field_type == constants.FIELD_TYPE_DATE || field.field_type == constants.FIELD_TYPE_DATE_TIME) {
            if (this.state.condition.t_operator == 'LIKE' || this.state.condition.t_operator == 'NOT_LIKE') {
                let tempValue = getStringParam(value);
                tempValue = tempValue.split('AND');
                if (tempValue.length == 2) {
                    this.state.fromDate = tempValue[0].trim();
                    this.state.toDate = tempValue[1].trim();

                    this.state.fromDate = this.state.fromDate.replace('00:00', '');
                    this.state.fromDate = this.state.fromDate.replace('23:59', '');

                    this.state.fromDate = this.state.fromDate.trim();
                    this.state.toDate = this.state.toDate.trim();
                }
            } else {
                field.list_values = new Array();
                field.list_values.push({ id: 'CURDATE()', value: 'CURDATE()' });
                field.list_values.push({ id: 'CURWEEK()', value: 'CURWEEK()' });
                field.list_values.push({ id: 'CURMONTH()', value: 'CURMONTH()' });
                field.list_values.push({ id: 'Pick a Date.', value: 'Pick a Date.' });
                if (value == 'CURDATE()' || value == 'CURWEEK()' || value == 'CURMONTH()') {
                    isShowDate = false;
                    this.state.isShowDate = isShowDate;
                } else {
                    isShowDate = true;
                    this.state.isShowDate = isShowDate;
                    value = null;
                    value = 'Pick a Date.';
                }
            }
        }

        let component = null;

        if (this.state.condition.conditionFieldProps.isShowConditionValue == false) {
            return null;
        }

        if (field.field_type == constants.FIELD_TYPE_TEXT
            || field.field_type == constants.FIELD_TYPE_INTEGER
            || field.field_type == constants.FIELD_TYPE_FLOAT
            || field.field_type == constants.FIELD_TYPE_URL
            || field.field_type == constants.FIELD_TYPE_MEMO
            || field.name == 'workflow_name'
        ) {
            component = <FormControl style={{ width: '100%', float: 'left', height: '40px'}} className="test" noValidate autoComplete="off">
                <TextField
                    variant="outlined"
                    label={this.props.field_name}
                    InputLabelProps={{
                        style: {
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            width: '96%',
                        }
                    }}
                    size="small"
                    fullWidth={true}
                    name={field.name}
                    value={value}
                    onChange={this.handleChangeConditionTextField}
                    error={this.state.error.field_value!=''}
                    helperText={this.state.error.field_value} 
                    autoComplete="new-password"
                    className={"sf-fields-bg"}
                    margin='dense'
                    inputStyle={{ marginTop: '6px', color: '#333', fontSize: '15px', fontWeight: 'normal' }}

                />
            </FormControl>;
        } else if (field.field_type == constants.FIELD_TYPE_LIST
            || ((field.field_type == constants.FIELD_TYPE_DATE || field.field_type == constants.FIELD_TYPE_DATE_TIME)
                && this.state.condition.t_operator != 'LIKE' && this.state.condition.t_operator != 'NOT_LIKE')) {
            if (field.list_type == constants.LIST_FIELD_TYPE_REGULAR
                || field.list_type == constants.LIST_FIELD_TYPE_USER
                || field.list_type == constants.LIST_FIELD_TYPE_EXTERNAL
                || field.list_type == constants.LIST_FIELD_TYPE_MULTI_SELECT
                || field.field_type == constants.FIELD_TYPE_DATE
                || field.field_type == constants.FIELD_TYPE_DATE_TIME
            ) {
                component = new Array();
                if (field.list_type == constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                    if (this.state.condition.selectedListValues != '' && !Array.isArray(this.state.condition.selectedListValues)) {
                        this.state.condition.selectedListValues = this.state.condition.selectedListValues.split(',');
                    }else{
                        this.state.condition.selectedListValues = [];
                    }
                    component.push(<FormControl style={{ width: '85%',  float: 'left',marginTop:'8px' }} variant="outlined">
                        <InputLabel id="sf-WebhookRuleCriteria-fromsfassignment-simple-select-outlined-label" className="sf-WebhookRuleCriteria-fromsfassignments"  style={{ marginTop: '-7px' }}>{field.label}</InputLabel>
                        <SelectField
                            key={field.id}
                            multiple={true}
                            label={field.label}
                            className={"sf-fields-bg"}
                            value={this.state.condition.selectedListValues}
                            title={this.state.condition.selectedListValues}
                            onChange={this.setMultiSelectValue}
                            autowidth={"true"}
                            size="small"
                            menuStyle={{ marginTop: '0px' }}
                            style={{height: '40px'}}
                        >
                            {
                                field.list_values.map((listValue, listValueIndex) => {
                                    return <MenuItem value={listValue.value}
                                        id={listValue.id}
                                        title={listValue.value}
                                        key={listValue.id}
                                        insetChildren={true}
                                        checked={this.state.condition.selectedListValues && this.state.condition.selectedListValues.indexOf(listValue.value) > -1 ? true : false}
                                        style={styles.popoverMenuItem}
                                        className='menu-item-ellipsis' >{listValue.value}</MenuItem>
                                })
                            }
                        </SelectField>
                    </FormControl>);
                } else if (this.state.condition.conditionFieldProps.isShowConditionValue) {

                    let containerStyle = { width: '85%', float: 'left'  };
                    if (isShowDate) {
                        containerStyle.width = '42.5%';
                    }
                    let autoCompleteStyle = { ...styles.sfFormFieldContainer };
                    delete autoCompleteStyle.paddingLeft;

                    let uniqueId = field.id + '-' + field.name + '-' + this.props.index;
                    component.push(
                        <div style={containerStyle} id={uniqueId} key={uniqueId}>
                             <SFAutoComplete
                                key={'sf-auto-complete-' + uniqueId}
                                id={'sf-auto-complete-' + uniqueId}
                                name={'sf-auto-complete-' + uniqueId + field.name}

                                label={field.label}
                                itemValues={field.list_values.map((listValue, listValueIndex) => {
                                    return { id: listValue.id, label: listValue.value };
                                })}

                                onChange={this.setSelectFieldValue.bind(this, field.name, field.field_type)}
                                value={value}
                                maxLength={20}
                                underlineFocusStyle={styles.textField.underlineFocus}
                                floatingLabelStyle={{ ...styles.textField.floatingLabel, top: '10px', left: '7px' }}
                                inputStyle={{ ...styles.textField.input, paddingLeft: 7 }}
                                menuItemStyle={styles.popoverMenuItem}
                                style={autoCompleteStyle}
                            /> 
                        </div>);

                    if (isShowDate) {
                        component.push(<Flatpickr
                            onChange={this.handleDateChange.bind(this, field.name)}
                            value={Date.parse(this.state.condition.t_value)}
                            options={datePickerOptions}
                            className='flatpickr'
                            placeholder={field.label}
                            style={{ width: '42.5%', border: '1px solid #dddddd', height: '38px', float: 'left', marginLeft: '10px', marginTop:'8px' }}
                            ref={'field-ref-' + field.name}
                            key={'field-ref-' + field.name}
                        />);
                    }
                }
            }


        } else if ((field.field_type == constants.FIELD_TYPE_DATE || field.field_type == constants.FIELD_TYPE_DATE_TIME)
            && (this.state.condition.t_operator == 'LIKE' || this.state.condition.t_operator == 'NOT_LIKE')) {
            component = new Array();
            component.push(<Flatpickr
                key='fromDate'
                onChange={this.handleDateChange.bind(this, 'fromDate')}
                value={Date.parse(this.state.fromDate)}
                options={datePickerOptions}
                className='flatpickr'
                placeholder={getLocalizedStrings().label.FILTER.FROM_DATE}//'From Date'
                style={{ width: '42.5%', border: '1px solid #dddddd', height: '38px', float: 'left', marginLeft: '10px', marginTop:'8px' }}
            />);
            component.push(<Flatpickr
                key='toDate'
                onChange={this.handleDateChange.bind(this, 'toDate')}
                value={Date.parse(this.state.toDate)}
                options={datePickerOptions}
                className='flatpickr'
                placeholder={getLocalizedStrings().label.FILTER.TO_DATE}//'To Date'
                style={{ width: '42.5%', border: '1px solid #dddddd', height: '38px', float: 'left', marginLeft: '10px', marginTop:'8px' }}
            />);
        }
        return component;
    }

    switchLogicalOperator = (val) => {
        this.state.condition.t_logicalop = val;
        this.setState(this.state);
        this.props.updateCondition(this.state.condition, this.props.index);
    }

    getFormatedDate = (dateString) => {
        try {
            if (isValidParam(dateString) && dateString != '') {
                let momentObj = moment(dateString);
                dateString = momentObj.format(dateTimeFormat[this.props.app.me.date_format]);
            } else {
                dateString = null;
            }
        } catch (error) {
            console.error("Error in 'filter.js -> getFormatedDate()':" + error);
        }
        return dateString;
    }

    setMultiSelectValue = (event) => {
        let values = event.target.value;
        this.state.condition.t_value = getArrayParam(values).join(',');
        this.state.condition.selectedListIds = getStringParam(this.state.condition.selectedListIds);
        this.state.condition.selectedListValues = values;
        this.setState(this.state);
        this.props.updateCondition(this.state.condition, this.props.index);
    }

    render() {
        let typeWiseField = this.generateTypeWiseTextFields(this.props.filedProps);
        let isShowDate = getBooleanParam(this.state.isShowDate);

        let operator_options = this.generateFieldOperatorOptions(this.props.filedProps);
         return (
       
            <div className="row clearfix" style={{marginTop: '8px'}}>
                {this.props.index > 0 ?
                    <div className={isShowDate ? "col-sm-6" : "col-sm-6"} style={{ marginRight: '0px', height: '48px' }}>
                        <div className="row" style={{margintop: '8px'}}>
                        <div className="col-sm-4 sm-col-4" >
                                        <div className="radio-group" style={{float: 'right' , marginTop: '8px'}}>
                                            <div className={this.state.condition.t_logicalop.trim() == 'AND' ? 'active-radio' : 'inactive_radio'} onClick={() => { this.switchLogicalOperator('AND') }}>
                                                <span>{getLocalizedStrings().label.FILTER.AND}</span>
                                            </div>
                                            <div className={this.state.condition.t_logicalop.trim() == 'OR' ? 'active-radio' : 'inactive_radio'} onClick={() => { this.switchLogicalOperator('OR') }}>
                                                <span>{getLocalizedStrings().label.FILTER.OR}</span>
                                            </div>
                                        </div>
                                    </div>
                                       <div className="col-sm-8 main_form_div ">
                                       <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                            <Autocomplete
                                                name="operator"
                                                options={operator_options}
                                                onChange={this.handleConditionOperatorChange}
                                                value={operator_options.find(v => v.value == this.state.condition.t_operator)}
                                                getOptionLabel={(option) => option.label}
                                                size="small"
                                                disableClearable={true}
                                                getOptionSelected={(option) => option.value === this.state.condition.t_operator}
                                                renderInput={(params) => <TextField {...params}
                                                    variant="outlined"  margin="dense" className={"sf-fields-bg"} />
                                                }
                                            />
                                        </FormControl>
                                    </div>
                            
                        </div>
                    </div>
                    :
                    <div className={isShowDate ? "col-sm-6" : "col-sm-6"} style={{ marginRight: '0px', height: '48px' }}>
                        <div className="row">
                            <div className="col-sm-12 main_form_div">

                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <Autocomplete
                                    name="operator"
                                    value={operator_options.find(v => v.value == this.state.condition.t_operator)}
                                    options={operator_options}
                                    onChange={this.handleConditionOperatorChange}
                                    size="small"
                                    defaultValue={operator_options.find(v => v.value == this.state.condition.t_operator)}
                                    getOptionLabel={(option) => option.label}
                                    getOptionSelected={(option) => option.value === this.state.condition.t_operator}
                                    disableClearable={true}
                                    renderInput={(params) => <TextField {...params}
                                        variant="outlined"  margin="dense" className={"sf-fields-bg"}/>
                                    }
                                />
                            </FormControl>
                            
                            </div>
                        </div>
                    </div>
                }


                
                <div className={isShowDate ? "col-sm-5" : "col-sm-5"} style={{ padding: '0px', margin: '0px', marginTop: '0px', width: isShowDate ? '' : '41%' }}>
                    {typeWiseField}
                </div>
                <div className={isShowDate ? "col-sm-1" : "col-sm-1"} onClick={this.props.deleteConditionRow} style={{ cursor: 'pointer', marginTop: '5px', marginLeft: '0px', float: 'left', paddingBottom: '5px' }} title={getLocalizedStrings().label.FILTER.REMOVE_CONDITION}>
                    <i className="fa fa-minus add_icon" aria-hidden="true" style={{ cursor: 'pointer', marginLeft: '9%', marginTop: '18%' }}></i>
                </div>
            </div>
               );
    }
}