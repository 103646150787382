import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styles } from '../../../services/constants/styles';
import DetailViewChildContainer from '../containers/DetailViewChildContainer';
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { detailViewChildObjectSetupGet, detailViewChildObjectSetupSave } from '../../../services/actions/batchActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { refreshDetailViewData, refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import { updateWorkflowList } from '../../../services/actions/listViewAcions.js';
import { getActiveTab, getActiveTabInfo, updateTabInfo } from '../../../services/helper/sfTabManager';
import {
    getArrayParam,
    getBooleanParam,
    getIntParam,
    getObjectParam,
    getStringParam,
    isValidParam,
} from '../../../services/helper/parameterVerifier';
import { getLinkObjectInfoPromise, setLinkObjectInfo } from '../../../services/actions/appActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as sfDialogs from '../components/sfDialogs';
import { Button, Icon, IconButton, Popper } from '@mui/material';
import DraggableSelect from '../../sfPlugins/draggableSelect.tsx';

const _ = require('lodash');

const maxLimit = 15;
const DetailViewChildObjects = ({ childObjProps, isFranchiseRecord }) => {
    const dispatch = useDispatch();
    const reduxApp = useSelector((state) => state['app']);
    const detailView = useSelector((state) => state.detailView);

    const [isChildSetupPopupOpen, setIsChildSetupPopupOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [childSetupPopupAnchorEl, setChildSetupPopupAnchorEl] = useState(null);
    const [clildObjectList, setClildObjectList] = useState([]);
    const [avaliableChildObjectList, setAvaliableChildObjectList] = useState([]);
    const [stateChildObjProps, setStateChildObjProps] = useState([]);
    const [childTabs, setChildTabs] = useState([]);
    const [childObjectMap, setChildObjectMap] = useState({});
    const [childObjectName, setChildObjectName] = useState(null);

    useEffect(() => {
        if (!_.isEqual(childObjProps, stateChildObjProps)) {
            configureChildObject();
            setStateChildObjProps(childObjProps);
        }
    }, [childObjProps]);

    useEffect(() => {
        generateChildTabs();
    }, [stateChildObjProps, childObjectName]);

    function configureChildObject() {
        let objectName = '';
        try {
            let objects = getArrayParam(childObjProps.childObjects);
            if (objects.length > 0) {
                let info = getObjectParam(getActiveTabInfo());
                objectName = getStringParam(info.activeObjectName);

                let activeObject = objects.find((f) => {
                    return isValidParam(f) && f.child_object === objectName;
                });
                if (!isValidParam(activeObject)) {
                    objectName = isValidParam(objects[0]) ? getStringParam(objects[0].child_object) : '';

                    let taskObject = objects.find((f) => {
                        return isValidParam(f) && f.child_object === constants.TASKS;
                    });
                    if (isValidParam(taskObject) && isValidParam(objects[0]) && getStringParam(objects[0].child_object) === constants.TASKS) {
                        objectName = objects.length > 1 && isValidParam(objects[1]) ? getStringParam(objects[1].child_object) : '';
                    }
                }
                if ( reduxApp.me.is_franchisee && childObjProps.object === constants.PARTNERS_OBJECT) {
                    objectName = constants.CONTACTS_OBJECT ;
                }
            } else {
                objectName = 'details';
            }
            setChildObjectName(objectName);
        } catch (error) {
            console.error("Error in 'detailViewChildObjects.js -> configureChildObject()':" + error);
        }
    }

    function rearrangeSequence() {
        let parentElem = document.getElementById('selChildObject');
        if (parentElem != null) {
            let arrayFieldList = [...clildObjectList];
            let elements = parentElem.getElementsByClassName('css-12jo7m5');
            let arrayFields = [];
            if (elements != null) {
                for (let i = 0; i < elements.length; i++) {
                    const elem = arrayFieldList.filter(function (el) {
                        return el.label.trim().replace(/  +/g, ' ').toString() === elements[i].innerText.trim().replace(/  +/g, ' ').toString();
                    });

                    if (elem != null) {
                        arrayFields.push(elem[0]);
                    }
                }
            }
            if (arrayFields != null) {
                setClildObjectList(arrayFields);
            }
        }
    }

    function changeChildView(_object) {
        const _childObjects = detailView.childObjects;
        const _currentChildObj = _childObjects.find(_childObj => _childObj.child_object == _object)
        let activeTab = getActiveTab();
        activeTab = getObjectParam(activeTab);
        let info = getObjectParam(activeTab.info);
        let activeObjectName = info.activeObjectName;
        if(!info.UnitsAccountProjectId){
            info.UnitsAccountProjectId = reduxApp?.me?.projectId;
        }
        // delete info.filter;
        delete info.callParamsFromFilter;
        delete info.showExpand;
        delete info.filterConfig;
        delete info.getExtraFilter;
        // delete info.isUnitsAccount;
        if ((_object === constants.NOTES && _object === activeObjectName)) {
            dispatch(refreshDetailViewListViewData(true));
        } else {
            let _parentObject = getStringParam(childObjProps.object);
            if (info.sub_type !== 'AUDIT_DETAILS' && ((_object === constants.TASKS && _parentObject === constants.ACCOUNTS_OBJECT && isFranchiseRecord) || (_object === constants.TASKS && _parentObject === constants.AUDITS_OBJECT ))) {
                dispatch(updateWorkflowList([detailView.workflow.name], true));
                info.filter = {
                    parent_object: getStringParam(childObjProps.object),
                    parent_record_id: getIntParam(childObjProps.parent_record_id),
                    query_id: _currentChildObj?.query_id || 0,
                    query_name: "All Tasks",
                    query_type: "-1",
                    // search_text: `All,${detailView.workflow.name}`,
                    search_text: `All`,
                    search_type: "exact",
                    // search_field_name: 't_status, workflow_name'
                    search_field_name: 't_status',
                }
                info.getExtraFilter = true;
                info.filterConfig = { showTaskFor: true, showTaskStatus: true, showTaskWorkflow: false, showEditButton : false };
                info.showExpand = true;
            }
            info.activeObjectName = _object;
            delete info.pageSize;
            delete info.selectedPageNo;
            delete info.startIndex;
            delete info.startPageNo;
            updateTabInfo(info);
        }
        setChildObjectName(_object);
    }

    function generateChildTabs() {
        let themeColor = reduxApp.me.background;
        let borderBottomDesign = '2.5px solid' + themeColor;
        let childTabList = [];

        let tempChildtabsArray = getArrayParam(childObjProps.childObjects);
        let childtabsArray = tempChildtabsArray.filter((f) => f.child_object !== constants.MESSAGE_DETAILS_OBJECT);

        let selectedModuleName = reduxApp.selectedModuleName;
        if (selectedModuleName === 'Operations') {
            childtabsArray = childtabsArray.filter((f) => {
                return f.child_object !== constants.ISSUES_OBJECT && f.child_object !== constants.SOLUTIONS_OBJECT;
            });
        }
        if ( reduxApp.me.is_franchisee && childObjProps.object === constants.PARTNERS_OBJECT) {
            childtabsArray = childtabsArray.filter((f) => {
                return f.child_object == constants.CONTACTS_OBJECT 
            });
        }

        if (isValidParam(childtabsArray)) {
            childtabsArray = childtabsArray.filter(
                (childTab, index, arr) => index === arr.findIndex((t) => t.child_object === childTab.child_object),
            );

            childTabList = childtabsArray.slice(0, 16).map((childObject) => {
                let tabStyle;
                if (childObjectName === childObject.child_object) {
                    tabStyle = {
                        display: 'inline-block',
                        width: 'auto',
                        color: '#717171',
                        cursor: 'pointer',
                        borderBottom: borderBottomDesign,
                        paddingBottom: '5px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    };
                } else {
                    tabStyle = {
                        display: 'inline-block',
                        width: 'auto',
                        color: '#b5b5b5',
                        cursor: 'pointer',
                        paddingBottom: '5px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    };
                }
                return (
                    <div
                        key={childObject.child_object}
                        title={childObject.title}
                        style={tabStyle}
                        onClick={() => changeChildView(childObject.child_object)}>
                        <span style={{ margin: '0px 12px' }}>
                            {isValidParam(getLocalizedStrings().label.NO_RECORD[childObject.title])
                                ? getLocalizedStrings().label.NO_RECORD[childObject.title]
                                : childObject.child_object == 'refund'
                                    ? getLocalizedStrings().label.DETAIL_VIEW.REFUND_CHILD_LABEL
                                    : childObject.title}
                        </span>
                    </div>
                );
            });
        }
        setChildTabs([...childTabList]);
    }

    function openChildTabsList(event) {
        let tabInfo = getActiveTabInfo();
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        let isUnitsAccount = false;
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }
        if ((!isInXpress && (!isUnitsAccount || reduxApp.me.is_franchisee)) || (isUnitsAccount && tabInfo.callFrom === "mini_edit" && reduxApp.me.is_franchisee)) {
            detailViewChildObjectSetup();
            setChildSetupPopupAnchorEl(event.currentTarget);
            setIsChildSetupPopupOpen(true);
            event.preventDefault();
        } else {
            let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
        }
    }

    function closeHiddenChildTabList() {
        setIsChildSetupPopupOpen(false);
    }

    function handleSelectChange(options) {
        if (isValidParam(options) && options.length > 0) {
            options.forEach((objOption) => {
                objOption.title = objOption.label;
            });
        }

        if (clildObjectList.length > options.length) {
        } else if (options.length > maxLimit) {
            dispatch(
                showCustomSnackBar(
                    getLocalizedStrings().message.DETAIL_VIEW.MAX_SELECTED_CHILD_OBJECT_LIMIT_FOR_15_OBJECTS,
                    styles.snackbarBodyStyleError,
                    styles.snackBarStyleTop,
                ),
            );
            return;
        }

        let totalObjectList = { ...childObjectMap };
        let totalObjectNameList = Object.keys(totalObjectList);
        let avaliableObjectMap = {};
        for (let i = 0; i < totalObjectNameList.length; i++) {
            let contain = false;
            for (let j = 0; j < options.length; j++) {
                if (totalObjectList[totalObjectNameList[i]].name === options[j].label) {
                    contain = true;
                    break;
                }
            }

            if (!contain) {
                avaliableObjectMap[totalObjectNameList[i]] = {
                    value: totalObjectList[totalObjectNameList[i]].id,
                    label: totalObjectList[totalObjectNameList[i]].name,
                };
            }
        }

        let avaliableObjectList = Object.values(avaliableObjectMap);
        setClildObjectList(options);
        setAvaliableChildObjectList(avaliableObjectList);
    }

    function detailViewChildObjectSetup() {
        setIsLoading(true);
        let moduleAccess = reduxApp.me['module_access'];
        const response = detailViewChildObjectSetupGet(childObjProps.object, {});
        response.then((response) => {
            let selectedObjectMap = {};
            let avaliableObjectMap = {};
            let totalObjectMap = {};
            let remainObjectMap = {};
            let responseSelectedObjectList = response.selected_objects;

            let available_objects = response['available_objects'];
            if (childObjProps.object === constants.ACCOUNTS_OBJECT && !getBooleanParam(isFranchiseRecord)) {
                available_objects = available_objects.filter((f) => {
                    return f['t_child_tableid'] !== OBJECT_TABLEID_MAP[constants.ROYALTY_OBJECT];
                });
                responseSelectedObjectList = responseSelectedObjectList.filter((f) => {
                    return f['t_child_tableid'] !== OBJECT_TABLEID_MAP[constants.ROYALTY_OBJECT];
                });
            }

            // Remove Task  objects
            let childObjectArray = [];
            for (let i = 0; i < responseSelectedObjectList.length; i++) {
                childObjectArray.push(responseSelectedObjectList[i]);
            }
            // Remove Issues , Cases Solution objects for Projects under Operation Module.
            let selectedModuleName = reduxApp.selectedModuleName;
            if (selectedModuleName === 'Operations') {
                childObjectArray = childObjectArray.filter((f) => {
                    return f['t_child_tableid'] !== 13 && f['t_child_tableid'] !== 424 && f['t_child_tableid'] !== 315;
                });
            }

            responseSelectedObjectList = childObjectArray;
            responseSelectedObjectList.forEach((item, i) => {
                if (i > 14) {
                    remainObjectMap[item.name] = {
                        value: item.id,
                        label: item.name,
                    };
                } else {
                    selectedObjectMap[item.name] = {
                        value: item.id,
                        label: item.name,
                        title: item.name,
                    };
                }
                totalObjectMap[item.name] = item;
            });

            avaliableObjectMap = { ...remainObjectMap };
            if (selectedModuleName === 'Operations') {
                available_objects = available_objects.filter((f) => f['t_child_tableid'] !== 424 && f['t_child_tableid'] !== 315);
            }
            available_objects.forEach((item) => {
                if (item.name !== 'Sms' && item.name !== 'Message Details' && (moduleAccess.Pet === 1 || item.name !== 'Pets')) {
                    avaliableObjectMap[item.name] = {
                        value: item.id,
                        label: item.name,
                    };
                    totalObjectMap[item.name] = item;
                }
            });

            let selectedObjectList = Object.values(selectedObjectMap);
            let avaliableObjectList = Object.values(avaliableObjectMap);
            setClildObjectList(selectedObjectList);
            setAvaliableChildObjectList(avaliableObjectList);
            setIsLoading(false);
            setChildObjectMap(totalObjectMap);
        });
    }

    function childObjectSetupSave() {
        rearrangeSequence();
        let saveObject = {};
        let totalObjectList = Object.values(childObjectMap);
        let shownClildObjectList = [...clildObjectList];

        if (shownClildObjectList.length === 0) {
            dispatch(
                showCustomSnackBar(
                    getLocalizedStrings().message.DETAIL_VIEW.SELECT_ATLEAST_ONE_CHILD_OBJECT,
                    styles.snackbarBodyStyleError,
                    styles.snackBarStyleTop,
                ),
            );
            return;
        }

        let saveObjectArray = [];

        for (let j = 0; j < shownClildObjectList.length; j++) {
            totalObjectList.forEach((item) => {
                if (shownClildObjectList[j].label === item.name) {
                    saveObjectArray.push(item);
                }
            });
        }

        saveObject['selected_objects'] = saveObjectArray;

        const response = detailViewChildObjectSetupSave(childObjProps.object, saveObject);
        setIsChildSetupPopupOpen(false);
        response.then((response) => {
            if (response.status === 0) {
                let promise = Promise.resolve(getLinkObjectInfoPromise());
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            dispatch(setLinkObjectInfo(response.linkChildInfo));
                            dispatch(refreshDetailViewData(true));
                            dispatch(
                                showCustomSnackBar(
                                    getLocalizedStrings().message.COMMON.SAVE,
                                    styles.snackbarBodyStyleSuccess,
                                    styles.snackBarStyleTop,
                                ),
                            );
                        }
                    });
                }
            }
        });
    }

    function onSortEnd(selectedOptions) {
        setClildObjectList(selectedOptions);
    }

    // render()
    let childProps = null;
    let parentObject = getStringParam(childObjProps.object);
    let parentRecordId = getIntParam(childObjProps.parent_record_id);
    let childObject = getStringParam(childObjectName);
    if (parentObject !== '' && childObject !== '' && parentRecordId > 0) {
        childProps = {};
        childProps.parentObject = parentObject;
        childProps.parent_record_id = parentRecordId;
        childProps.childObject = childObject;
    }

    //let isHubCase = false;
    let showCustomizeChild = true;
    if (parentObject === constants.CASES_OBJECT && reduxApp.selectedModuleName === constants.MODULE_HUB) {
        showCustomizeChild = false;
    }
    if (parentObject === constants.PARTNERS_OBJECT && reduxApp.me.is_franchisee) {
        showCustomizeChild = false;
    }
    if (parentObject === constants.AUDITS_OBJECT) {
        showCustomizeChild = false;
    }
    if (parentObject === constants.ROYALTY_RUN_OBJECT) {
        showCustomizeChild = false;
    }

    let clildObjectListValue = clildObjectList.map((f) => {
        f.id = f.value;
        return f;
    });
    let avaliableChildObjectListOptions = avaliableChildObjectList.map((f) => {
        f.id = f.value;
        return f;
    });
    avaliableChildObjectListOptions = [...clildObjectListValue, ...avaliableChildObjectListOptions];
    return (
        <div style={{ paddingTop: '20px', marginBottom: '20px' }}>
            <div style={{ borderBottom: '1px solid #CCCCCC', width: '100%' }}>
                <div
                    id="childTabsLists"
                    style={{
                        display: 'inline-block',
                        width: '94%',
                        verticalAlign: 'top',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                    <div style={{ width: '100%', fontSize: '14px' }}>
                        <div className="overflow-x">{childTabs}</div>
                    </div>
                </div>

                {showCustomizeChild && (
                    <div
                        style={{
                            display: 'inline',
                            cursor: 'pointer',
                            verticalAlign: 'top',
                            marginTop: '-6px',
                            paddingLeft: '15px',
                        }}>
                        <IconButton onClick={openChildTabsList} style={{ color: '#949494', padding: 0, fontSize: '16px' }}>
                            <Icon
                                style={{ opacity: 0.7, fontSize: '16px', paddingLeft: '1px' }}
                                title={getLocalizedStrings().label.DETAIL_VIEW.CUSTOMIZE_CHILD}>
                                settings
                            </Icon>
                        </IconButton>
                        <Popper
                            open={isChildSetupPopupOpen}
                            anchorEl={childSetupPopupAnchorEl}
                            placement="left"
                            onClose={closeHiddenChildTabList}
                            sx={{ ...styles.popperStyle }}>
                            <div style={styles.customizedFieldsContainerDiv}>
                                <div style={styles.customizedFieldsHeaderDiv}>
                                    <span style={styles.customizedFieldsHeader}>{getLocalizedStrings().label.DETAIL_VIEW.SETUP_CHILD_TABS}</span>
                                    <span
                                        style={styles.customizedFieldsHeaderIcon}
                                        onClick={closeHiddenChildTabList}
                                        title={getLocalizedStrings().label.COMMON.CLOSE}>
                                        <i className="fa fa-times" aria-hidden="true" />
                                    </span>
                                </div>
                                <div style={styles.customizedFieldsNotify}>{getLocalizedStrings().label.DETAIL_VIEW.CLICK_TO_ADD_CHILD_OBJECTS}</div>
                                <div id="selChildObject">
                                    {avaliableChildObjectListOptions.length > 0 && (
                                        <DraggableSelect
                                            key={'select-draggable-' + childObjProps.object}
                                            id={'select-draggable-' + childObjProps.object}
                                            isClearable
                                            onSortEnd={(val) => onSortEnd(val)}
                                            closeMenuOnSelect={false}
                                            disabled={false}
                                            value={getArrayParam(clildObjectListValue)}
                                            placeholder={getLocalizedStrings().label.DETAIL_VIEW.SELECT_OPTIONS}
                                            selectLimit={maxLimit}
                                            limitMessage={getLocalizedStrings().message.DETAIL_VIEW.MAX_SELECTED_CHILD_OBJECT_LIMIT_FOR_15_OBJECTS}
                                            options={getArrayParam(avaliableChildObjectListOptions)}
                                            onChange={(value) => handleSelectChange(value)}
                                            clearAllText={getLocalizedStrings().label.COMMON.CLEAR_ALL}
                                            className="my-cool-select-top"
                                        />
                                    )}
                                </div>

                                <div style={{ paddingTop: '6px', float: 'right', paddingBottom: '10px' }}>
                                    <Button disabled={isLoading} onClick={() => childObjectSetupSave()} style={styles.primaryButton}>
                                        {getLocalizedStrings().label.COMMON.SAVE}
                                    </Button>
                                </div>
                            </div>
                        </Popper>
                    </div>
                )}
            </div>
            {childProps != null && <DetailViewChildContainer childProps={childProps} childTabs={childTabs} />}
        </div>
    );
};

export default DetailViewChildObjects;
