import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { styles } from '../../../services/constants/styles';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useDispatch, useSelector } from 'react-redux';
import { setHeader } from '../../../services/actions/headerActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { constants, OBJECT_TABLEID_MAP, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import {
    getArrayParam,
    getIntParam,
    getStringParam,
    isEmpty,
    isValidParam
} from '../../../services/helper/parameterVerifier';
import { getFilters } from '../../../services/actions/filterMenuAction';
import { getSFFormFieldsPromise } from '../../../services/actions/sfFormActions';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import ShowCircularProgress from '../components/circularProgress';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import _ from 'lodash';
import * as sfDialogs from '../components/sfDialogs';

const ExportSettings = ({ object }) => {
    const dispatch = useDispatch();

    const menuItemParentObject = useSelector((state) => state.app.objectList);
    const filters = useSelector((state) => state.filters);
    const sfForm = useSelector((state) => state.sfForm);
    const menuItemChildObject = useSelector((state) => state.app.linkChildInfo);
    const moduleAccess = useSelector((state) => state.app.me.module_access);


    const [parentObjectList, setParentObjectList] = useState([]);
    const [queryList, setQueryList] = useState([]);
    const [childObjectList, setChildObjectList] = useState([]);
    const [selected, setSelected] = useState('');
    const [selectedQuery, setSelectedQuery] = useState('');
    const [selectedFile, setSelectedFile] = useState('333');
    const [selectedOptionsListChild, setSelectedOptionsListChild] = useState([]);
    const [parentOptionList, setParentOptionList] = useState([]);
    const [childOptionList, setChildOptionList] = useState([]);
    const [selectedChildObject, setSelectedChildObject] = useState('');
    const [fileName, setFileName] = useState('');
    const [downloadFileData, setDownloadFileData] = useState('');
    const [downloadFileName, setDownloadFileName] = useState('');
    const [downloadFileType, setDownloadFileType] = useState('');
    const [isShowLoader, setIsShowLoader] = useState(false);
    const [downloadFile, setDownloadFile] = useState(false);
    const [exportFile, setExportFile] = useState(false);
    const [fileType, setFileType] = useState([]);
    const [selectedOptionsList, setSelectedOptionsList] = useState([]);


    useEffect(() => {
        dispatch(setHeader(object, getLocalizedStrings().label.EXPORT_SETTINGS.EXPORT_HEADING, null, false, true));
    }, [dispatch, object]);

    // updateObjectList when filters.queries or selected changes
    useEffect(() => {
        setIsShowLoader(true);
        let dropDownList = [];
        try {
            let parentItems = [...menuItemParentObject];
            if (!parentItems.some(e => e.name === constants.TASKS)) {
                const obj = { name: constants.TASKS, label: "Tasks", homeId: 0 };
                parentItems.push(obj);
            }
            parentItems.sort((a, b) => (a.name > b.name) ? 1 : -1);
            const availableObjects = [constants.ACCOUNTS_OBJECT, constants.CONTACTS_OBJECT, constants.OPPORTUNITIES_OBJECT, constants.TASKS];
            const hasServiceAccess = moduleAccess.Service;
            if (!!hasServiceAccess) {
                availableObjects.push(constants.ISSUES_OBJECT, constants.PROJECTS_OBJECT, constants.CASES_OBJECT);
            }
            dropDownList = parentItems.filter(item => availableObjects.includes(item.name));
        } catch (error) {
            console.error('Error in exportSettings.js -> getDropDownObjects() : ' + error);
        }
        setParentObjectList(dropDownList);
        if (dropDownList.length > 0) {
            setSelected(dropDownList[0].name);
        }
        setIsShowLoader(false);
    }, [menuItemParentObject, moduleAccess.Service]);

    // updateQueryList when filters.queries or selected changes
    useEffect(() => {
        if (selected && selected !== '') {
            let queries = filters.queries[selected];
            try {
                setIsShowLoader(true);
                if (!isValidParam(queries)) {
                    let promise = (Promise.resolve(getFilters(selected)));
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (isValidParam(response)) {
                                filters.queries[selected] = response.queries;
                                queries = response.queries;
                                processQueries(queries);
                            }
                            setIsShowLoader(false);
                        });
                    }
                } else {
                    processQueries(queries);
                    setIsShowLoader(false);
                }
            } catch (error) {
                console.error('Error in exportSettings.js -> useEffect() - updateQueryList : ' + error);
            }
        }
    }, [filters.queries, selected]);

    // updateChildObjectLists when selected and menuItemChildObject changes
    useEffect(() => {
        let childObjectItems = [];
        setIsShowLoader(true);
        try {
            if (isValidParam(menuItemChildObject) && isValidParam(selected) && selected !== '') {
                let accountId = OBJECT_TABLEID_MAP[selected];
                childObjectItems = _.get(menuItemChildObject, accountId);
                childObjectItems = childObjectItems
                    .filter(items => selected !== items.objectName && items.childTableId !== 320 && items.objectName !== constants.EMAIL_OBJECT && items.objectName !== constants.INVOICES_OBJECT && items.objectName !== constants.QUOTATION_OBJECT && items.objectName !== constants.PAYMENTS_OBJECT)
                    .sort((a, b) => (a.title > b.title) ? 1 : -1);
            }
        } catch (error) {
            console.error('Error in exportSettings.js -> getDropDownChildObject() : ' + error);
        }
        setChildObjectList(childObjectItems);
        if (childObjectItems?.length > 0) {
            setSelectedChildObject(childObjectItems[0].childTableId)
        }
        setIsShowLoader(false);
    }, [selected, menuItemChildObject]);

    // updateParentObjectFields when selected or  sfForm.data changes
    useEffect(() => {
        try {
            if (isValidParam(selected) && selected !== '') {
                setIsShowLoader(true);
                let optionsList = sfForm.data[selected];
                if (!isValidParam(optionsList)) {
                    let promise = getSFFormFieldsPromise(selected);
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (isValidParam(response)) {
                                sfForm.data[selected] = response;
                                const parentFields = processObjectFields(response.fields || []);
                                setParentOptionList(parentFields);
                                setSelectedOptionsList([]);
                            }
                            setIsShowLoader(false);
                        });
                    }
                } else {
                    const parentFields = processObjectFields(optionsList.fields || []);
                    setParentOptionList(parentFields);
                    setSelectedOptionsList([]);
                    setIsShowLoader(false);
                }
            } else {
                setParentOptionList([]);
                setSelectedOptionsList([]);
            }
        } catch (error) {
            console.error('Error in exportSettings.js -> updateParentObjectFields() : ' + error);
        }
    }, [selected, sfForm.data]);

    // updateChildObjectFields when selectedChildObject or sfForm.data changes
    useEffect(() => {
        try {
            if (isValidParam(selectedChildObject) && selectedChildObject !== '') {
                setIsShowLoader(true);
                let objectName = TABLEID_OBJECT_MAP[selectedChildObject];
                let optionsList = sfForm.data[objectName];
                if (!isValidParam(optionsList)) {
                    let promise = getSFFormFieldsPromise(objectName);
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (isValidParam(response)) {
                                sfForm.data[objectName] = response;
                                const childFields = processObjectFields(response.fields);
                                setChildOptionList(childFields)
                                setSelectedOptionsListChild([]);
                            }
                            setIsShowLoader(false);
                        });
                    }
                } else {
                    const childFields = processObjectFields(optionsList.fields);
                    setChildOptionList(childFields)
                    setSelectedOptionsListChild([]);
                    setIsShowLoader(false);
                }
            } else {
                setChildOptionList([]);
                setSelectedOptionsListChild([]);
            }
        } catch (error) {
            console.error('Error in exportSettings.js -> updateChildObjectFields() : ' + error);
        }
    }, [selectedChildObject, sfForm.data]);


    useEffect(() => {
        $('#parentFlds-move-right').prop('title', getLocalizedStrings().label.EXPORT_SETTINGS.ADD);
        $('#parentFlds-move-all-right').prop('title', getLocalizedStrings().label.EXPORT_SETTINGS.ADD_ALL);
        $('#parentFlds-move-left').prop('title', getLocalizedStrings().label.EXPORT_SETTINGS.REMOVE);
        $('#parentFlds-move-all-left').prop('title', getLocalizedStrings().label.EXPORT_SETTINGS.REMOVE_ALL);

        $('#childFlds-move-right').prop('title', getLocalizedStrings().label.EXPORT_SETTINGS.ADD);
        $('#childFlds-move-all-right').prop('title', getLocalizedStrings().label.EXPORT_SETTINGS.ADD_ALL);
        $('#childFlds-move-left').prop('title', getLocalizedStrings().label.EXPORT_SETTINGS.REMOVE);
        $('#childFlds-move-all-left').prop('title', getLocalizedStrings().label.EXPORT_SETTINGS.REMOVE_ALL);
    });

    const processQueries = (queries) => {
        const queryItems = queries
            .filter(item => item.type === constants.TYPE_DASHBOARD_STRING_QUERY || item.type === constants.SEARCH_QUERY_TYPE_ALL || item.type == 'filter')
            .sort((a, b) => (a.name > b.name) ? 1 : -1);
        setQueryList(queryItems);
        if (queryItems.length > 0) {
            setSelectedQuery(queryItems[0].id);
        }
    }


    const handleFileNameChange = (event) => {
        let target = event.target;
        let value = target.value;
        setFileName(value);
    }

    const getFileTypes = () => {
        return [
            <MenuItem key={1} value={333}>CSV[.csv]</MenuItem>,
            <MenuItem key={2} value={111}>EXCEL[.xlsx]</MenuItem>,
        ];
    }

    const handleOnClickFileDropDown = (event) => {
        let value = event.target.value;
        setSelectedFile(value);
    }

    const getParentObjectList = () => {
        if (isValidParam(parentObjectList) && parentObjectList.length > 0) {
            return parentObjectList.map((item, index) => (<MenuItem value={item.name} key={index}>{item.label}</MenuItem>));
        }
        return [];
    }

    const handleOnClickParentDropDown = (event) => {
        let value = event.target.value;
        setSelected(value);
    }

    const getQueryItems = () => {
        if (isValidParam(queryList) && queryList.length > 0) {
            return queryList.map((item, index) => (
                <MenuItem value={item.id} key={index} onClick={() => handleOnClickQueryDropDown(index, item.id)}>{item.name}</MenuItem>
            ));
        }
        return [];
    }

    const handleOnClickQueryDropDown = (index, value) => {
        setSelectedQuery(value);
    }

    const getDropDownChildObject = () => {
        if (isValidParam(childObjectList) && childObjectList.length > 0) {
            return childObjectList.map((item, index) => (
                <MenuItem
                    value={item.childTableId}
                    key={index}
                    name={item.objectName}
                    onClick={() => setSelectedChildObject(item.childTableId)}
                >{item.title}
                </MenuItem>))
        }
        return [];
    };

    const processObjectFields = (fields) => {
        let objectFields = [];
        if (fields.length > 0) {
            objectFields = fields
                .filter(item => item.t_tablefldid !== -1 && item.name !== "workflow_id" && item.is_hidden_field === false)
                .map(item => ({ label: item.label, value: item.name, title: item.label, name: item.name }));
        }
        sortArrayObjectByProperty(objectFields, 'label');
        return objectFields;
    }

    const onChangeDualListBoxParent = (selected) => {
        setSelectedOptionsList(selected);
    }

    const onChangeDualListBoxChild = (selected) => {
        setSelectedOptionsListChild(selected);
    };

    const validateExportDataAndDownload = () => {
        let expFileName = getStringParam(fileName).trim();
        let expParentObject = getStringParam(selected);
        let expFileType = getIntParam(selectedFile);
        let expQueryName = getIntParam(selectedQuery);
        let expChildObject = getStringParam(TABLEID_OBJECT_MAP[selectedChildObject]);
        let expParentObjectFieldName = getArrayParam(selectedOptionsList);
        let expParentOFName = '';
        let expParentOFLabel = '';
        let expChildObjectFieldName = getArrayParam(selectedOptionsListChild);
        let expChildOFName = '';
        let expChildOFLabel = '';
        let params = {};
        let errorMessage = '';
        let queryType = '';
        queryType = queryList.find((el)=>{
            if(el.id == selectedQuery){
                return el;
            }
        })

        if(expFileName.length > 128){
            let alertMsg =  'File name should not more then 128 character' 
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);
            setFileName("")
        }

     else if (!isEmpty(expFileName) && !isEmpty(expParentObject) && !isEmpty(expFileType) && !isEmpty(expQueryName) && !isEmpty(expParentObjectFieldName)) {
            setIsShowLoader(true);
            params.fileType = expFileType;
            params.fileName = expFileName;
            params.parentObjectName = expParentObject;
            params.queryName = expQueryName;
            params.queryType = queryType?.type;
            expParentOFName = expParentObjectFieldName.map(item => item.value).join();
            expParentOFLabel = expParentObjectFieldName.map(item => item.label).join();
            params.parentObjectFieldsName = expParentOFName;
            params.parentObjectFieldsLabel = expParentOFLabel;
            if (!isEmpty(expChildObject) && !isEmpty(expChildObjectFieldName)) {
                params.childObjectName = expChildObject;
                expChildOFName = expChildObjectFieldName.map(item => item.value).join();
                expChildOFLabel = expChildObjectFieldName.map(item => item.label).join();
                params.childObjectFieldsName = expChildOFName;
                params.childObjectFieldsLabels = expChildOFLabel;
            } else {
                params.childObjectName = "";
                params.childObjectFieldsName = "";
                params.childObjectFieldsLabels = "";
            }
            try {
                if (isValidParam(params) && !isEmpty(params)) {
                    let ext = null;
                    if (expFileType === 333) {
                        ext = expFileName + '.csv';
                        Promise.resolve(HTTPClient.post(endPoints.EXPORT.GENERATE_DATA_AND_DOWNLOAD, params)).then((response) => {
                            if (response.status !== -1) {
                                setDownloadFile(true);
                                setDownloadFileData(response.dataList)
                                setDownloadFileName(ext);
                                setDownloadFileType('333');
                                setIsShowLoader(false);
                            } else if (response.status === -1) {
                                setDownloadFile(false);
                                setIsShowLoader(false);
                                errorMessage = '';
                                errorMessage = getLocalizedStrings().message.EXPORT_SETTINGS.NO_RECORDS_FOUND;
                                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        });
                    } else {
                        ext = expFileName + '.xlsx';
                        Promise.resolve(HTTPClient.post(endPoints.EXPORT.GENERATE_DATA_AND_DOWNLOAD, params)).then((response) => {
                            if (response.status !== -1) {
                                setDownloadFile(true);
                                setDownloadFileData(response.dataStr);
                                setDownloadFileName(ext);
                                setDownloadFileType('111');
                                setIsShowLoader(false);
                            } else if (response.status === -1) {
                                setDownloadFile(false);
                                setIsShowLoader(false);
                                errorMessage = '';
                                errorMessage = getLocalizedStrings().message.EXPORT_SETTINGS.NO_RECORDS_FOUND;
                                dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        });
                    }
                }
            } catch (error) {
                console.error('Error in exportSettings.js -> validateExportDataAndDownload() : ' + error);
            }
        } else if (isEmpty(expFileName) || isEmpty(expParentObject) || isEmpty(expFileType) || isEmpty(expQueryName) || isEmpty(expParentObjectFieldName)) {
            errorMessage = '';
            if (isEmpty(expFileName)) {
                errorMessage = getLocalizedStrings().message.EXPORT_SETTINGS.FILE_NAME_IS_EMPTY;
            } else if (isEmpty(expFileType)) {
                errorMessage = getLocalizedStrings().message.EXPORT_SETTINGS.PLEASE_SELECT_THE_FILE_TYPE;
            } else if (isEmpty(expParentObject)) {
                errorMessage = getLocalizedStrings().message.EXPORT_SETTINGS.PLEASE_SELECT_THE_PARENT_OBJECT;
            } else if (isEmpty(expQueryName)) {
                errorMessage = getLocalizedStrings().message.EXPORT_SETTINGS.PLEASE_SELECT_THE_QUERY_NAME;
            } else if (isEmpty(expParentObjectFieldName)) {
                errorMessage = getLocalizedStrings().message.EXPORT_SETTINGS.PLEASE_SELECT_THE_PARENT_FIELDS;
            }
            dispatch(showCustomSnackBar(errorMessage, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
        }
    }

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        let byteCharacters = decodeBase64(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    const decodeBase64 = (base64Data) => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
        let str = base64Data;
        str = str.replace(/=+$/, '');
        let output = '';
        if (str.length % 4 === 1) {
            throw new Error("'decodeBase64' failed: The string to be decoded is not correctly encoded.");
        }
        for (let bc = 0, bs = 0, buffer, i = 0;
            buffer = str.charAt(i++);
            ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
                bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
        ) {
            buffer = chars.indexOf(buffer);
        }
        return output;
    }

    const getFilesDownloaded = () => {
        let fileDataDownload = downloadFileData;
        let fileNameDownload = downloadFileName;
        try {
            if (downloadFileType === '333') {
                let csvRows = [];
                for (let i = 0; i < fileDataDownload.length; i++) {
                    for (let j = 0; j < fileDataDownload[i].length; j++) {
                        if (fileDataDownload[i][j]!== null && fileDataDownload[i][j].includes('"')) {
                            fileDataDownload[i][j] = fileDataDownload[i][j].replace(/"/g, '""');
                        }
                        if (fileDataDownload[i][j]!== null &&  fileDataDownload[i][j].includes('\n')) {
                            // Handle elements that contain new lines
                            fileDataDownload[i][j] = fileDataDownload[i][j].replace(/\n/g, "");
                        }
                        if (fileDataDownload[i][j]!== null && fileDataDownload[i][j].includes(',')) {
                            // Handle elements that contain commas
                            fileDataDownload[i][j] = '"' + fileDataDownload[i][j] + '"';
                        }
                    }
                    csvRows.push(fileDataDownload[i].join(','));
                }
                let csvString = csvRows.join('\r\n');
                let blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileNameDownload;
                a.click();

            } else {
                const blob = b64toBlob(fileDataDownload, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileNameDownload;
                a.click();
            }
        } catch (error) {
            console.error('Error in exportSettings.js -> getFilesDownloaded() : ' + error);
        }

    }

    let mainContentTopMargin = '0px';
    let downloadViewCheck = downloadFile;
    let showLoaderStatus = isShowLoader;
    let height = window.innerHeight - 150;
    let top = (height - 10) / 2;
    if (showLoaderStatus) {
        return (
            <div style={{ width: '100%', height: height, overflow: 'hidden' }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }} >
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div >
        );
    } else if (downloadViewCheck) {
        return (
            <div style={{ paddingLeft: '26px', paddingRight: '30px' }}>
                <style> {".fr-desktop {z-index: 1000 !important; }"}</style>
                <div className="row" style={{ marginTop: mainContentTopMargin }}>
                    <div className="col-sm-12">
                    <div id="header" style={{ paddingLeft: '0px', width: '100%', fontSize: '24px', paddingBottom: '12px' }}></div>
                        <div className="row" style={{ paddingLeft: '15px' }}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <Button
                                        key='downloadFile'
                                        onClick={() => getFilesDownloaded()}
                                        style={{ ...styles.primaryButton, verticalAlign: 'top', width: 160, marginTop: 5, marginLeft: '340px',  marginTop: '70px'  }}
                                    >{getLocalizedStrings().label.EXPORT_SETTINGS.DOWNLOAD_BUTTON}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    } else {
        return (
            <div className="container" style={{ paddingLeft: '26px', paddingRight: '30px' }}>
                <div className="section">
                    <div className='row'>
                    <div id="header" style={{ paddingLeft: '10px', width: '100%', fontSize: '24px', paddingBottom: '12px' }}>{getLocalizedStrings().label.EXPORT_SETTINGS.EXPORT_HEADING}</div>
                         <div className='col-sm-6'>
                            <FormControl style={{ width: '100%', marginLeft: '0px' }} className="test" noValidate autoComplete="off">
                                <TextField variant="outlined"
                                    label={getLocalizedStrings().label.EXPORT_SETTINGS.FILE_NAME_REQUIRED}
                                    fullWidth={true}
                                    name="name"
                                    id="name"
                                    value={fileName}
                                    onChange={(event) => handleFileNameChange(event)}
                                    disabled={false}
                                    autoFocus
                                    inputprops={{ maxlength: '128' }}
                                    autoComplete="new-password"
                                    margin='dense'
                                    size='small'
                                    style={{ width: '96%', paddingBottom: '14,5px' }}
                                    className={"sf-fields-bg"}
                                />
                            </FormControl>
                        </div>
                        <div className='col-sm-6' style={{paddingBottom:'10px'}}>
                            <FormControl style={{ width: '100%',marginLeft:'20px', marginTop:'5px'}} variant="outlined">
                                <InputLabel id="sf-exportsettings-simple-select-outlined-label" className="sf-exportsettings-simple">{getLocalizedStrings().label.EXPORT_SETTINGS.FILE_TYPE_REQUIRED}</InputLabel>
                                <Select
                                    labelId='sf-label-fileType'
                                    label={getLocalizedStrings().label.EXPORT_SETTINGS.FILE_TYPE_REQUIRED}
                                    name="fileType"
                                    id="fileType"
                                    value={selectedFile}
                                    onChange={(event) => handleOnClickFileDropDown(event)}
                                    style={{ height: '42px', width: '96%' }}
                                    className={"sf-fields-bg"}
                                >
                                    {getFileTypes()}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <FormControl style={{ width: '100%', marginLeft: '0px', marginTop: '16px' }} variant="outlined">
                                <InputLabel id="sf-exportsetting-from-exportsetting-simple-select-outlined-label" className="sf-exportsetting-from-exportsetting">{getLocalizedStrings().label.EXPORT_SETTINGS.PARENT_OBJECT_REQUIRED}</InputLabel>
                                <Select
                                    labelId='sf-label-parentObject'
                                    label={getLocalizedStrings().label.EXPORT_SETTINGS.PARENT_OBJECT_REQUIRED}
                                    name="parentObject"
                                    id="parentObject"
                                    value={selected}
                                    onChange={(e) => handleOnClickParentDropDown(e)}
                                    style={{ height: '42px', width: '96%' }}
                                    className={"sf-fields-bg"}
                                >
                                    {getParentObjectList()}
                                </Select>
                            </FormControl>
                        </div>
                        <div className='col-sm-6'>
                            <FormControl style={{ width: '100%', marginLeft:'20px', marginTop:'15px'}} variant="outlined">
                                <InputLabel id="sf-export-settings-from-exportsettings-simple-select-outlined-label" className="sf-export-settings-from-exportsettings">{getLocalizedStrings().label.EXPORT_SETTINGS.QUERY_NAME_REQUIRED}</InputLabel>
                                <Select
                                    labelId='sf-label-queryName'
                                    label={getLocalizedStrings().label.EXPORT_SETTINGS.QUERY_NAME_REQUIRED}
                                    name="queryName"
                                    id="queryName"
                                    value={selectedQuery}
                                    style={{ height: '42px', width: '96%' }}
                                    className={"sf-fields-bg"}
                                >
                                    {getQueryItems()}
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className='row' style={{marginTop:'15px'}}>
                        <div className='col-sm-6' style={{fontWeight:'bold'}}>
                        <label>{getLocalizedStrings().label.EXPORT_SETTINGS.AVAILABLE_FIELDS_FOR_PARENT}</label>
                        </div>
                        <div className='col-sm-6' style={{fontWeight:'bold'}}>
                        <label style={{ marginLeft: '5%',paddingTop:'24px'}}>{getLocalizedStrings().label.EXPORT_SETTINGS.SELECTED_FIELDS_FOR_PARENT}</label>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12' style={{marginTop: '15px'}}>
                            <div >
                                <DualListBox
                                    id="parentFlds"
                                    options={parentOptionList}
                                    selected={selectedOptionsList}
                                    onChange={(selectedValues) => setSelectedOptionsList(selectedValues)}
                                    simpleValue={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6' style={{paddingTop:'25px',paddingBottom:'20px', width:'51%'}}>
                            <FormControl style={{ width: '100%'}} variant="outlined">
                                <InputLabel id="sf-exportsettings-from-exportsettings-simple-select-outlined-label" className="sf-exportsettings-from-exportsettings">{getLocalizedStrings().label.EXPORT_SETTINGS.CHILD_OBJECT_NAME}</InputLabel>
                                <Select
                                    labelId='sf-label-childObject'
                                    label={getLocalizedStrings().label.EXPORT_SETTINGS.CHILD_OBJECT_NAME}
                                    name="childObject"
                                    id="childObject"
                                    value={selectedChildObject}
                                    style={{ height: '42px', width: '96%' }}
                                    className={"sf-fields-bg"}
                                >
                                    {getDropDownChildObject()}
                                </Select>
                            </FormControl>
                        </div>

                    </div>
                </div>
                <div className="section">
                    <div className='row'>
                        <div className='col-sm-6' style={{fontWeight:'bold'}} >
                        <label>{getLocalizedStrings().label.EXPORT_SETTINGS.AVAILABLE_FIELDS_FOR_CHILD}</label>
                        </div>
                        <div className='col-sm-6' style={{fontWeight:'bold'}}>
                        <label style={{ marginLeft: '5%',paddingTop:'24px'}}>{getLocalizedStrings().label.EXPORT_SETTINGS.SELECTED_FIELDS_FOR_CHILD}</label>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-12' style={{marginTop: '15px'}}>
                            <div>
                                <DualListBox
                                    id="childFlds"
                                    options={childOptionList}
                                    selected={selectedOptionsListChild}
                                    onChange={(selectedValues) => setSelectedOptionsListChild(selectedValues)}
                                    simpleValue={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className='row'>
                        <div className='col-sm-12'>
                            <Button
                                key='download'
                                onClick={() => validateExportDataAndDownload()}
                                style={{ ...styles.primaryButton, marginRight: '12px', verticalAlign: 'top', marginTop: '23px', fontSize: '15px' }}
                            >{getLocalizedStrings().label.EXPORT_SETTINGS.EXPORT_BUTTON}</Button>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div style={{ marginTop: '35px' }}>
                                <b>{getLocalizedStrings().message.EXPORT_SETTINGS.CONSIDERATION}</b>
                                <ul>
                                    <li>{getLocalizedStrings().message.EXPORT_SETTINGS.CONSIDERATION_MSG1}</li>
                                    <li>{getLocalizedStrings().message.EXPORT_SETTINGS.CONSIDERATION_MSG2}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

}


export default ExportSettings;