
import React, { useState, useRef,useEffect } from 'react';
import { useDispatch,useSelector} from "react-redux";
import {Box,Card,CardContent,Typography,FormControl,Button,Checkbox,FormControlLabel,Link,CardActions,TextField,Snackbar,Slide,Alert} from "@mui/material";
import {getObjectParam, getStringParam, isValidParam, isEmpty} from "../../../services/helper/parameterVerifier";
import { LoadingButton } from '@mui/lab';
import { setCookie, deleteAllTabCookiesAfterlogin } from '../../../services/helper/sfCookies';
import * as HTTPClient from '../../../services/helper/httpClient';
import { constants } from "../../../services/constants/constants";
import { endPoints } from '../../../services/constants/endPoints';
import { hideSnackBar,showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';


const TwoFactorAuthentication = ({ userName, token}) => {
    const dispatch = useDispatch();

    const snackBar = useSelector((state) => state.snackBar);

    const [code, setCode] = useState("");
    const [rememberMe, setRememberMe] = useState(true);
    const [error, setError] = useState({})
    const [isInvalidCode, setIsInvalidCode] = useState(false);
    const [isCodeExpired, setIsCodeExpired] = useState(false);
    const [attempts, setAttempts] = useState();
    const [nextClicked,setNextClicked] = useState(false);
    const [snackBarOpenStatus, setSnackBarOpenStatus] = useState(false);
    const [snackBarOpenDuration, setSnackBarOpenDuration] = useState(2000); 
    const [isResendInProgress, setIsResendInProgress] = useState(false);
    
    const inputRef = useRef();

    useEffect(() => {
        if (inputRef.current) {
            focusOntextfield();
        }
    }, [error.code]); 

    useEffect(() => {
        let delay = 2000;
        if (snackBar?.snackbar?.duration !== null) {
            delay = parseInt(snackBar.snackbar.duration);
        }
        setSnackBarOpenDuration(delay);
        setSnackBarOpenStatus(snackBar.snackbar.open);
        if (snackBar.snackbar.open) {
            setTimeout(() => {
                setSnackBarOpenStatus(false);
                dispatch(hideSnackBar());
            }, delay);
        }
	}, [dispatch, snackBar])


    const focusOntextfield = (element) => {
        inputRef.current = element;
        inputRef.current?.focus();
    }

    const handleCodeChange = (e) => {
        const value = e.target.value.replace(/\D/g, ''); // Removes non-numeric characters
        setCode(value);
        setError({ ...error, code: false, codeMessage: "" }); 
    };

    const validateForm = () => {
        try {
            let valid = true;
            if (!isValidParam(code) || isEmpty(getStringParam(code))) {
                setError({ ...error, code: true, codeMessage: "Please enter the code." });
                valid = false;

            }else if(code.length > 7 || code.length < 7){
                setError({ ...error, code: true, codeMessage: "Please enter a valid 7-digit code." });
                valid = false;
            }
            return valid;
        }
        catch (error) {
            console.error("Error in 'TwoFactorAuthentication.js' -> validateForm()':" + error);
        }

    }

    const handleRememberMeChange = (e) => {
        setRememberMe(e.target.checked);
    };

    const handleSubmit = () => {
        let isValid = validateForm()
        if (isValid) {
            setNextClicked(true);
            let param = {
                code: code,
            }

            let url = endPoints.LOGIN.TWO_FA_VERIFIY;
            let promise = Promise.resolve(HTTPClient.postTwoFA(url,param,token));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === 0 && response.data === true) { // code verified
                        localStorage.setItem("token", token);
                        localStorage.setItem("global_franchisor_token", token);
                        localStorage.setItem("isLogin", true);
                        deleteAllTabCookiesAfterlogin();
                        let cookieName = "_sffa_" + userName;
                        if(rememberMe){
                            setCookie(cookieName,true,30);
                        }
                        if (constants.APP_URL === "") {
                            window.location.replace("/");
                        } else {
                            window.location.replace(constants.APP_URL);
                        }
                    } else if (response.status === -6) {
                        // Invalid code
                        setNextClicked(false);
                        setCode("");
                        setIsInvalidCode(true);
                        if (response?.data?.attempts > 0) {
                            let attempts = 5 - response.data.attempts;
                            setAttempts(attempts);
                        }
                    } else if (response.status === -7) {
                        // Code expired
                        setNextClicked(false);
                        setIsCodeExpired(true);
                        setIsInvalidCode(false);
                    }
                }
            })
        }
    };

    const handleResendCode = () => {
        if (isResendInProgress) return; // Prevent resend if in progress

        setIsResendInProgress(true); // Block further clicks
        focusOntextfield();
        setCode("");
        setIsCodeExpired(false);
        setIsInvalidCode(false);
        setError({ ...error, code: false, codeMessage: "" });
        let param = {}
        let url = endPoints.LOGIN.TWO_FA_RESEND_CODE;
        let promise = Promise.resolve(HTTPClient.postTwoFA(url,param,token));
        promise.then((response) => {
            if (isValidParam(response)) {
                setIsResendInProgress(false); // Unblock after response
                if (response.status === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.LOGIN.RESEND_CODE_SUCCESS, styles.snackbarBodyStyleSuccess,styles.snackBarStyleTop));
                }else{
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.LOGIN.RESEND_CODE_FAILURE, styles.snackbarBodyStyleError,styles.snackBarStyleTop));
                }
            }
        })
    };

    const userLogout = () => {
        localStorage.removeItem("token", token);
        window.location.replace("/");
    }

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "row",
                    padding: "20px",
                    boxSizing: "border-box",
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                }}
            >
                <div className="abc" style={{ width: "500px" }}>
                    <Box boxShadow={3} className="sf-login-box">
                        <Card style={{ height: "auto" }}>
                            <CardContent>
                                <Typography
                                    className="sf-login-Typography"
                                    color="textSecondary"
                                    gutterBottom
                                    bottommargin="10px">
                                    Two-Factor Authentication
                                </Typography>

                                {!isInvalidCode && !isCodeExpired &&
                                    <Typography variant="body2" style={{ marginBottom: "20px", wordBreak:"break-word" }}>
                                        {"We've sent an email with a code to "}<strong>{userName}</strong>.<br />
                                        Please enter the code below.
                                    </Typography>
                                }

                                <Typography variant="body2" color="error" style={{ marginBottom: "20px", whiteSpace: 'nowrap' }}>
                                {isInvalidCode && (
                                    attempts === 0 ? "Account Locked! Try again after one hour."
                                    : (
                                        <>
                                            Incorrect Code. You are left with {attempts} more attempt{attempts > 1 ? 's' : ''}.
                                            <br />
                                            Please enter the correct code below.
                                        </>
                                        ))
                                }

                                </Typography>

                                {isCodeExpired &&
                                    <Typography variant="body2" color="error" style={{ marginBottom: "20px", whiteSpace: 'nowrap' }}>
                                        Your code has expired. <br /> Please click "Resend Code" to request a new code.
                                    </Typography>
                                }

                                <div>
                                    <FormControl sx={{ width: "100%" }} variant="outlined">
                                        <TextField
                                            id="code"
                                            name="code"
                                            label="Code"
                                            size='small'
                                            value={code}
                                            onChange={(e) => handleCodeChange(e)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault(); // Prevent form submission default behavior
                                                    handleSubmit();
                                                }
                                            }}
                                            error={Boolean(error.code)}
                                            helperText={error.code ? error.codeMessage : ''}
                                            inputRef={focusOntextfield}
                                            FormHelperTextProps={{
                                                sx: {
                                                    marginLeft: 0, 
                                                }
                                            }}
                                        />
                                    </FormControl>
                                </div>

                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginTop: "10px" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={rememberMe}
                                                value={rememberMe}
                                                onChange={handleRememberMeChange}
                                                color="default"
                                            />
                                        }
                                        label="Remember me for 30 days"
                                    />
                                    <Link href="#" onClick={handleResendCode}  
                                        style={{ 
                                            textDecoration: "underline", 
                                            pointerEvents: attempts === 0 ? "none" : "auto", 
                                            opacity: attempts === 0 ? 0.5 : 1 
                                        }}>
                                        Resend code
                                    </Link>
                                </div>
                            </CardContent>
                            <CardActions style={{ float: "right" }}>
                                <div style={{ textAlign: "center", marginRight: "7px",marginBottom:"20px" }}>
                                    <LoadingButton
                                        key='next'
                                        style={{
                                            backgroundColor: "#3fc7f6",
                                            fontSize: 13,
                                            marginRight: "10px",
                                            paddingLeft: nextClicked ? 35 : 25
                                        }}
                                        color="primary"
                                        size="large"
                                        onClick={() => handleSubmit()}
                                        disabled={attempts === 0}
                                        loading={nextClicked}
                                        loadingPosition="start"
                                        variant="contained"
                                    >
                                        Next
                                    </LoadingButton>
                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: "#ffffff",
                                            fontSize: 13,
                                            color: "black",
                                        }}
                                        size="large"
                                        onClick={() => userLogout()}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </CardActions>
                        </Card>
                    </Box>
                </div>
            </div>
            {snackBarOpenStatus && <Snackbar
					open={snackBarOpenStatus}
					onClose={()=>{dispatch(hideSnackBar())}}
					anchorOrigin={{ vertical: "top", horizontal: "center" }}
					autoHideDuration={snackBarOpenDuration}
					TransitionComponent={Slide}
					children={
						<Alert
							icon={false}
							style={{
								overflow: "hidden",
								Width: "800px",
								...getObjectParam(snackBar.snackbar.bodyStyle),
								...getObjectParam(snackBar.snackbar.style),
							}}
						>
							{snackBar.snackbar.message}
						</Alert>
					}
				/>
            }
        </div>
    );
}
export default TwoFactorAuthentication;