import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { constants } from '../../../services/constants/constants';
import { isValidParam, getStringParam, getObjectParam, getArrayParam, getIntParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { getListData } from '../../../services/actions/listViewAcions';
import { getActiveTab, getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const getListViewInstanceFromRedux = (state,isDetailView) => {
    let tabInfo = getActiveTabInfo();
    let isReport = getBooleanParam(tabInfo.isReport);
    if (getBooleanParam(isDetailView)) {
        return state.childListView;
    } else if (getBooleanParam(isReport)) {
        return state.otherListView;
    } else {
        return state.listView;
    }
}

const getActiveTabLabelFromRedux = (state) => {
    let tabInfo = getActiveTabInfo();
    return tabInfo.reportLabel;
}


const ListViewExport =({isReport,isDetailView}) => {
   
    const dispatch = useDispatch();
    const reduxApp = useSelector((state) => state['app']);
    const detailViewTaskList = useSelector((state) => state.detailViewTaskList);
    
    const [exportto, setExportTo] = useState('LIST_VIEW_SELECTED_RECORDS'); 

    const listView = useSelector((state) => getListViewInstanceFromRedux(state, isDetailView));    
    const appContainer = useSelector((state) => state.appContainer);
    const activeTabLabel = useSelector((state) => getActiveTabLabelFromRedux(state)); 

    const exportTo = (event, value) => {
        setExportTo(value);
    }

    function filterByIds(array1, array2) {
        // Create a Set from array2 for efficient look-up
        const idsSet = new Set(array2);
    
        // Filter array1 based on whether the id is in the idsSet
        return array1.filter(item => idsSet.has(item.id));
    }

    const exportFile = () => {
        let data = getObjectParam(appContainer.drawerProps.data);
        let object = getStringParam(data.object);
        let fields = getArrayParam(listView.data.fields);
        fields = fields.filter(f => { return !f.is_hidden });
        if(object === constants.AUDIT_ANSWERS_OBJECT){
            fields = fields.filter(f => { return f.name != 'photo_url' });
        }
        let ids = getArrayParam(data.id);
        let activeTab = getActiveTab();

        if (exportto === 'LIST_VIEW_SELECTED_RECORDS') {
            let ChildListViewFields = getArrayParam(detailViewTaskList.data.fields);
            let records;
            if(activeTab?.info?.object === constants.TASKS && activeTab?.info?.perent_object === constants.ACCOUNTS_OBJECT){
                let allRecords = detailViewTaskList.data.records;
                records = filterByIds(allRecords, ids);
                ChildListViewFields = ChildListViewFields.filter(f => { return !f.is_hidden });
                ChildListViewFields = ChildListViewFields.filter(f => { return f.name != 'photo_url' });
                ChildListViewFields = ChildListViewFields.filter(f => { return f.name != 'has_attachments' });
                fields = ChildListViewFields;
            }else{
                records = listView.data.records.filter(f => { return ids.indexOf(f.id) >= 0 });
            }
            let htmlData = getHTMLTable(fields, records);
            downloadFile(htmlData);
        } else {
            let activeTab = getActiveTab();
            activeTab = getObjectParam(activeTab);
            let tabType = getStringParam(activeTab.type);
            let inputParam = getObjectParam(activeTab.info.input_param);
            let isReport = getBooleanParam(activeTab.info.isReport);
            let searchText = getStringParam(activeTab.info.searchText);
            let searchFieldName = getStringParam(activeTab.info.searchFieldName);
            let groupByFieldName = getStringParam(activeTab.info.groupByFieldName);
            let groupByCondition = getStringParam(activeTab.info.groupByCondition);

            let params = {};

            if (searchFieldName !== '') {
                params.search_field_name = searchFieldName;
                params.search_type = constants.SEARCH_TYPE_ANYWHERE;
            }

            if (searchText !== '') {
                params.search_text = searchText;
            }

            if (activeTab.object === constants.SEARCH_RESULTS || activeTab.object === constants.ADVANCE_SEARCH_RESULTS) {
                let info = getObjectParam(activeTab.info);
                params = getObjectParam(params);
                params.query_name = info?.actionObject =="advance-partner-search" ? 'Partners': constants.SEARCH_QUERY_NAME;
                if (activeTab.object === constants.SEARCH_RESULTS) {
                    let search = getObjectParam(info.search);
                    params.query_type = constants.SEARCH_QUERY_TYPE_GLOBAL;
                    params.search_text = getStringParam(search.searchText);
                } else if (activeTab.object === constants.ADVANCE_SEARCH_RESULTS) {
                    params.query_type = constants.SEARCH_QUERY_TYPE_ADVANCE;
                    params.search_type = constants.SEARCH_TYPE_START_WITH;
                    let advanceSearch = getObjectParam(info.advanceSearch);
                    let searchParams = getObjectParam(advanceSearch.searchParams);
                    params.search = getArrayParam(searchParams.search);
                }
            } else if (isReport) {
                let filterObj = getObjectParam(activeTab.info.filter);
                let queryId = getIntParam(filterObj.id);
                let queryName = getStringParam(filterObj.name);
                let queryType = getStringParam(filterObj.type);
                params.query_id = queryId;
                params.query_name = queryName;
                params.query_type = queryType;
                params.start_index = 0;
                params.page_size = getIntParam(activeTab.info.recordCount);
                if(object === constants.COMPLIANCE_OBJECT){
                    params.input_param_sql = getStringParam(activeTab.info.input_param_sql);
                    params.is_report = activeTab.info.isReport;
                }
            } else {
                let filterObj = getObjectParam(activeTab.info.filter);
                let queryId = getIntParam(filterObj.id);
                let queryName = getStringParam(filterObj.name);
                let queryType = getStringParam(filterObj.type); 

                if (!params.hasOwnProperty('query_id')) {
                    params.query_id = queryId;
                }

                if (!params.hasOwnProperty('query_name')) {
                    params.query_name = queryName;
                }

                if (!params.hasOwnProperty('query_type')) {
                    params.query_type = queryType;
                }
            }
             if(object === constants.AUDIT_ANSWERS_OBJECT){
                fields = fields.filter((f) => {
                    return f.name != "photo_url";
                })
             }
            let fieldNames = fields.map(f => {
                return f.name;
            });


            /** operations-conversion report */
            if (activeTab.hasOwnProperty("item") && getStringParam(activeTab.item) === constants.OPERATIONS_CONVERSIONS) {
                params.is_operations_conversion_report = true;
                params.inxpress_project_id = activeTab.info.hasOwnProperty("inXpressProjectId") ? activeTab.info.inXpressProjectId : 0;
            }
            /** */

            params.fields = fieldNames;
            params.parent_object = getStringParam(listView.data.parent_object);
            params.parent_record_id = getIntParam(listView.data.parent_record_id);
            params.group_by_field_name = groupByFieldName;
            params.group_by_condition = groupByCondition;
            params.sort_field_name = getStringParam(listView.data.sort_field_name);
            params.sort_type = getStringParam(listView.data.sort_type);
            if (isReport === true && (activeTabLabel.includes("Activities") || activeTabLabel.includes("Activity"))) {
                params.is_report = true;
            }
            // params.start_index = 0;
            // params.page_size = 100;
            if(object === constants.AUDIT_ANSWERS_OBJECT && reduxApp.me.is_franchisee){
                params.group_by_field_name = '';
                params.group_by_condition = '';
            }
            if(activeTab?.info?.object === constants.TASKS && activeTab?.info?.perent_object === constants.ACCOUNTS_OBJECT){
                let queryId = detailViewTaskList.data.query_id ;
                let queryName = detailViewTaskList.data.query_name;
                let queryType = detailViewTaskList.data.query_type; 

                params.query_id = queryId;
                params.query_name = queryName;
                params.query_type = queryType;
                params.parent_object = getStringParam(detailViewTaskList.data.parent_object);
                params.parent_record_id = getIntParam(detailViewTaskList.data.parent_record_id);
                params.group_by_field_name = groupByFieldName;
                params.group_by_condition = groupByCondition;
                params.sort_field_name = getStringParam(detailViewTaskList.data.sort_field_name);
                params.sort_type = getStringParam(detailViewTaskList.data.sort_type);
                params.search_type = "exact";
                delete params['fields'];
            }
            params = { ...params, ...inputParam };
            let promise = Promise.resolve(getListData(object, params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response) && response.hasOwnProperty('fields') && response.hasOwnProperty('records')) {
                        const hasTotal = listView.data.hasOwnProperty('opertunity_sumdata');
                        if (hasTotal) {
                            try {
                                const total = [listView.data?.opertunity_sumdata[1]];
                                const schema = Object.keys(listView.data.records[0]).filter(key => key !== 'id');
                                const totalDataNames = Object.keys(listView.data.opertunity_sumdata[1]);
                                const isFirstColumn = schema[0] === totalDataNames[0];
                                let totalData = [];
                                if (isFirstColumn) {
                                    totalData = [listView.data?.opertunity_sumdata[1]];
                                } else {
                                    total.forEach((element, index) => {
                                        totalData.push({ ...element, [schema[0]]: "Grand Total" });
                                    });
                                }
                                let htmlData = getHTMLTable(getArrayParam(response.fields), getArrayParam(response.records.concat(totalData)));
                                downloadFile(htmlData);
                            } catch (error) {
                                console.log(error);
                            }
                        } else {
                            let htmlData = getHTMLTable(getArrayParam(response.fields), getArrayParam(response.records));
                            downloadFile(htmlData);
                        }
                        
                    }
                });
            }

        }
    }

    const downloadFile = (htmlData) => {
        if (isValidParam(htmlData) && htmlData !== '') {
            // console.log(htmlData);
            htmlData = htmlData.replace("%22", "\"");
            // console.log("the value after conversion");
            // console.log(htmlData);
            var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
            var downloadUrl = URL.createObjectURL(blob);
            var a = document.createElement("a");
            a.href = downloadUrl;
            a.download = 'ResultSet_' + new Date().getTime() + '.xls';
            document.body.appendChild(a);
            a.click();
            a.remove();
        }
    }


    const generateLabelForCompany = (fields) => {
        let fieldsData = { name: "company", label: "Company" };
        fields.push(fieldsData);
        return fields;
    }

    const generateRecordForCompany = (records) => {
        records.map((items, Index) => {
            if (items.child_objects[0].records.length > 0) {
                if (items.child_objects[0].records[0].company !== null) {
                    items.company = items.child_objects[0].records[0].company;
                } else {
                    items.company = '';
                }
            } else {
                items.company = '';
            }
        }
        )
        return records;
    }

    const getHTMLTable = (fields, records) => {
        if (isReport === true && (activeTabLabel.includes("Activities") || activeTabLabel.includes("Activity"))) {
            fields = generateLabelForCompany(fields);
            records = generateRecordForCompany(records);
        }
        let htmlTable = '';
        fields = getArrayParam(fields);
        records = getArrayParam(records);
        if (fields.length > 0) {
            let tHead = '';
            fields.forEach(field => {
                let dataField = field.label.toString();
                dataField = dataField.replace(/</g, '&lt;');
                dataField = dataField.replace(/>/g, '&gt;');
                tHead += '<th align="left" valign="top">' + dataField + '</th>';
            });

            if (tHead !== '') {
                tHead = '<thead><tr>' + tHead + '</tr></thead>';
            }

            let tBody = ''
            records.forEach(record => {
                let row = '';
                let dataValue = '';
                fields.forEach(field => {
                    dataValue = getStringParam(record[field.name]);
                    // console.log(dataValue);
                    dataValue = dataValue.replace(/</g, '&lt;');
                    dataValue = dataValue.replace(/>/g, '&gt;');
                    if (field.name === 'company') {
                        dataValue = getStringParam(dataValue);
                        dataValue = dataValue === '' ? 'Empty' : dataValue;
                    }
                    if (record.hasOwnProperty("id")) {
                        row += '<td align="left" valign="top">' + dataValue + '</td>';
                    } else {
                        row += '<td align="left" valign="top">' + `<b>${dataValue}</b>` + '</td>';
                    }
                });

                if (row !== '') {
                    tBody += '<tr>' + row + '</tr>';
                }
            });

            if (tBody !== '') {
                tBody = '<tbody>' + tBody + '</tbody>';
            }

            if (tHead !== '') {
                htmlTable = '<table>' + tHead + tBody + '</table>';
                htmlTable = '<html><meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\"><head></head><body>' + htmlTable + '</body></html>';
            }
        }
        return htmlTable;
    }

        return (
            <div style={{ width: '89%', marginTop: '20px', marginLeft: '23px', paddingRight: '0', color: '#717171' }}>
                 <FormControl component="fieldset">
                    <RadioGroup name="selectApp" onChange={(event,value) => exportTo(event,value)} defaultValue="LIST_VIEW_SELECTED_RECORDS" >
                    <FormControlLabel   value='LIST_VIEW_SELECTED_RECORDS'  control={<Radio    color="default" />}   label={getLocalizedStrings().label.LIST_VIEW.EXPORT_SELECTED_RECORDS} />
                    <FormControlLabel  value='ALL_RECORDS'  control={<Radio   color="default" />} label={getLocalizedStrings().label.LIST_VIEW.EXPORT_ALL_RECORDS} />
                    </RadioGroup>
                    </FormControl>
              
                <div>
                    <Button onClick={()=>exportFile()} style={styles.primaryButton} > {getLocalizedStrings().label.COMMON.EXPORT}</Button>
                    <Button onClick={() => dispatch(getAppDrawer(false, null, null, null, null))}  style={styles.secondaryButton} >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                </div>
            </div>
        );
    
}

export default ListViewExport ;

