import React, { useEffect, useState } from 'react';
import { styles } from '../../../services/constants/styles';
import { Radio, RadioGroup, FormControlLabel, FormControl, Button } from '@mui/material';
import { isValidParam, getStringParam, getObjectParam } from '../../../services/helper/parameterVerifier';
import { dateFormat, getKey, dateFormatFlatPicker } from '../../../services/helper/utils';
import { DATE_KEY, ADVANCE_SEARCH_DATE_CONSTANT } from '../../../services/constants/constants';
import * as sfDialogs from './sfDialogs';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import PopOver from './PopOver';
import { useDispatch, useSelector } from "react-redux";

const moment = require('moment');

const AdvancePartnerSearchDate = ({ onSetDate, fieldData, defaultValue, object, fieldLabel }) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);

    const OPERATOR_IS_EQUAL_TO = '=';
    const OPERATOR_GREATER_THEN = '>';
    const OPERATOR_LESS_THEN = '<';
    const RANGE = 'Range';
    const NONE = '{none}';
    const arrMonth = new Array(31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31);

    const [defaultSelected, setdefaultSelected] = useState('=');
    const [relationalOperator, setRelationalOperator] = useState('=');
    const [date, setDate] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isDateRange, setIsDateRange] = useState(false);
    const [customDate, setCustomDate] = useState(null);
    const [rangeOptions, setRangeOptions] = useState([]);
    const [changeLog, setchangeLog] = useState({ relationalOperator: '=' });

    useEffect(() => {
        getDefaultValue();
    }, []);

    useEffect(() => {
        if (isValidParam(defaultValue)) {
            if (changeLog.relationalOperator === NONE) {
                setRelationalOperator({});
                let log = changeLog;
                log.relationalOperator = {};
                setchangeLog({ ...log });
            } else if (changeLog.relationalOperator === RANGE) {
                handleDateFocus('fromDate');
                handleDateFocus('toDate');
            } else {
                handleDateFocus('date');
            }
        }
    }, [fromDate, toDate, customDate, date, relationalOperator, defaultSelected]);


    const getDefaultValue = () => {
        if (isValidParam(defaultValue)) {
            let dateObj = getObjectParam(defaultValue);
            let relationalOperator = getStringParam(dateObj.operator);

            setdefaultSelected(relationalOperator);
            setRelationalOperator(relationalOperator);
            let log = changeLog;
            log.relationalOperator = relationalOperator;
            setchangeLog({ ...log });

            if (relationalOperator === RANGE) {
                setIsDateRange(true);
                setCustomDate(getKey(ADVANCE_SEARCH_DATE_CONSTANT, getStringParam(dateObj.rangeType)));
                setFromDate(moment(getStringParam(dateObj.from_date)).format(dateFormat[app.me.date_format]));
                setToDate(moment(getStringParam(dateObj.to_date)).format(dateFormat[app.me.date_format]));
            } else if (relationalOperator === NONE) {
                setDate(null);
            } else {
                setDate(moment(getStringParam(dateObj.value)).format(dateFormat[app.me.date_format]));
            }
        }
    }



    const getField = () => {
        let data = getObjectParam(fieldData);
        return data;
    }


    const getActions = () => {
        const actions = <div>
            <Button
                primary={true}
                onClick={() => saveDate()}
                style={styles.primaryButton}
            >Save</Button>
            <Button
                primary={true}
                onClick={() => handleClose()}
                style={styles.secondaryButton}
            >Clear</Button></div>
        return actions;
    }

    const handleClose = () => {
        setCustomDate(null);
        setFromDate(null);
        setToDate(null);
        setDate(null);
    }

    const changeRadioButton = (value) => {
        if (value === 'Range') {
            setIsDateRange(true);
        } else {
            setIsDateRange(false);
        }

        if (value === NONE) {
            setdefaultSelected(value);
            setDate(null);
            let log = changeLog;
            log.relationalOperator = value;
            setchangeLog({ ...log });
            setIsDateRange(false);
            setRelationalOperator(value);
            setCustomDate(null);

        } else {
            setRelationalOperator(value);
            let log = changeLog;
            log.relationalOperator = value;
            setchangeLog({ ...log });
            setdefaultSelected(value);
        }
    }


    const handleDateFocus = (fieldName, event) => {
        let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + fieldName);
        if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
            dateTimeDiv[0].classList.add('label-top');
        }
    }

    const handleDateBlur = (fieldName, event) => {

        let value = getStringParam(date);
        if (fieldName === 'fromDate') {
            value = getStringParam(fromDate);
        } else if (fieldName === 'toDate') {
            value = getStringParam(toDate);
        }

        if (value === '') {
            let dateTimeDiv = document.getElementsByClassName("dateTimeDiv-" + fieldName);
            if (isValidParam(dateTimeDiv) && dateTimeDiv.length > 0) {
                dateTimeDiv[0].classList.remove('label-top');
            }
        }
    }

    const handleDateChange = (date, fieldName) => {
        if (fieldName === 'fromDate') {
            setFromDate(date);
        } else if (fieldName === 'toDate') {
            setToDate(date);
        } else {
            setDate(date);

        }
    }

    const getFormatedDateString = (jsDate) => {
        let field = getObjectParam(getField());
        let fieldType = getStringParam(field.field_type);
        //let format = fieldType === constants.FIELD_TYPE_DATE_TIME ? dateTimeFormat[app.me.date_format] : dateFormat[app.me.date_format];
        let format = dateFormat[app.me.date_format];
        let momentDate = isValidParam(jsDate) ? new moment(jsDate) : new moment();
        let dateAfterFormat = momentDate.format(format);
        return dateAfterFormat;
    }

    const fnSetCustomDate = (value) => {
        value = parseInt(value);
        let dateAfterFormat = getFormatedDateString();

        let date = new Date();
        let y = date.getFullYear();
        let m = date.getMonth();
        let d = date.getDate();

        let fromDate = null;
        let toDate = null;
        if (value === DATE_KEY.TODAY) {
            fromDate = dateAfterFormat;
            toDate = dateAfterFormat;
        } else if (value === DATE_KEY.THIS_WEEK) {
            fromDate = new Date(y, m, d - date.getDay());

            y = date.getFullYear();
            m = date.getMonth();
            d = date.getDate();
            d += 6 - date.getDay();
            toDate = new Date(y, m, d);
        } else if (value === DATE_KEY.THIS_WEEK_TO_DATE) {
            fromDate = new Date(y, m, d - date.getDay());
            toDate = new Date();
        } else if (value === DATE_KEY.THIS_MONTH) {
            fromDate = new Date(y, m, 1);

            d = arrMonth[m];
            if ((m === 1) && (y % 4 === 0)) {
                d += 1;
            }
            toDate = new Date(y, m, d);
        } else if (value === DATE_KEY.THIS_MONTH_TO_DATE) {
            fromDate = new Date(y, m, 1);
            toDate = new Date();
        } else if (value === DATE_KEY.THIS_FISCAL_QUARTER) {
            if (m < 3) {
                fromDate = new Date(y, 0, 1);
                toDate = new Date(y, 2, 31);
            } else if (m > 2 && m < 6) {
                fromDate = new Date(y, 3, 1);
                toDate = new Date(y, 5, 30);
            } else if (m > 5 && m < 9) {
                fromDate = new Date(y, 6, 1);
                toDate = new Date(y, 8, 30);
            } else if (m > 8) {
                fromDate = new Date(y, 9, 1);
                toDate = new Date(y, 11, 31);
            }
        } else if (value === DATE_KEY.THIS_FISCAL_QUARTER_TO_DATE) {
            if (m < 3) {
                fromDate = new Date(y, 0, 1);
            } else if (m > 2 && m < 6) {
                fromDate = new Date(y, 3, 1);
            } else if (m > 5 && m < 9) {
                fromDate = new Date(y, 6, 1);
            } else if (m > 8) {
                fromDate = new Date(y, 9, 1);
            }
            toDate = new Date();
        } else if (value === DATE_KEY.THIS_FISCAL_YEAR) {
            fromDate = new Date(y, 0, 1);
            toDate = new Date(y, 11, 31);
        } else if (value === DATE_KEY.THIS_FISCAL_YEAR_TO_DATE) {
            fromDate = new Date(y, 0, 1);
            toDate = new Date();
        } else if (value === DATE_KEY.YESTERDAY) {
            date.setDate(d - 1);
            fromDate = date;
            toDate = date;
        } else if (value === DATE_KEY.LAST_WEEK) {
            d = (d - date.getDay() - 7);
            fromDate = new Date(y, m, d);
            toDate = new Date(y, m, d + 6);
        } else if (value === DATE_KEY.LAST_WEEK_TO_DATE) {
            d = (d - date.getDay() - 7);
            fromDate = new Date(y, m, d);
            toDate = new Date();
        } else if (value === DATE_KEY.LAST_MONTH) {
            m -= 1;
            fromDate = new Date(y, m, 1);

            d = arrMonth[m];
            if ((m === 1) && (y % 4 === 0)) {
                d += 1;
            }
            toDate = new Date(y, m, d);
        } else if (value === DATE_KEY.LAST_MONTH_TO_DATE) {
            m -= 1;
            fromDate = new Date(y, m, 1);
            toDate = new Date();
        } else if (value === DATE_KEY.LAST_FISCAL_QUARTER) {
            m -= 3;
            date = new Date(y, m, 1);
            y = date.getFullYear();
            m = date.getMonth();
            if (m < 3) {
                fromDate = new Date(y, 0, 1);
                toDate = new Date(y, 2, 31);
            } else if (m > 2 && m < 6) {
                fromDate = new Date(y, 3, 1);
                toDate = new Date(y, 5, 30);
            } else if (m > 5 && m < 9) {
                fromDate = new Date(y, 6, 1);
                toDate = new Date(y, 8, 30);
            } else if (m > 8) {
                fromDate = new Date(y, 9, 1);
                toDate = new Date(y, 11, 31);
            }
        } else if (value === DATE_KEY.LAST_FISCAL_QUARTER_TO_DATE) {
            m -= 3;
            date = new Date(y, m, 1);
            y = date.getFullYear();
            m = date.getMonth();
            if (m < 3) {
                fromDate = new Date(y, 0, 1);
            } else if (m > 2 && m < 6) {
                fromDate = new Date(y, 3, 1);
            } else if (m > 5 && m < 9) {
                fromDate = new Date(y, 6, 1);
            } else if (m > 8) {
                fromDate = new Date(y, 9, 1);
            }
            toDate = new Date();
        } else if (value === DATE_KEY.LAST_FISCAL_YEAR) {
            y -= 1;
            fromDate = new Date(y, 0, 1);
            toDate = new Date(y, 11, 31);
        } else if (value === DATE_KEY.LAST_FISCAL_YEAR_TO_DATE) {
            y -= 1;
            fromDate = new Date(y, 0, 1);
            toDate = new Date();
        } else if (value === DATE_KEY.NEXT_WEEK) {
            d = (d - date.getDay() + 7);
            fromDate = new Date(y, m, d);

            d += 6;
            toDate = new Date(y, m, d);
        } else if (value === DATE_KEY.NEXT_FOUR_WEEKS) {
            d = (d - date.getDay() + 7);
            fromDate = new Date(y, m, d);

            d += 27;
            toDate = new Date(y, m, d);
        } else if (value === DATE_KEY.NEXT_MONTH) {
            m += 1;
            fromDate = new Date(y, m, 1);

            d = arrMonth[m];
            if ((m === 1) && (y % 4 === 0)) {
                d += 1;
            }
            toDate = new Date(y, m, d);
        } else if (value === DATE_KEY.NEXT_FISCAL_QUARTER) {
            m += 3;
            date = new Date(y, m, 1);
            y = date.getFullYear();
            m = date.getMonth();

            if (m < 3) {
                fromDate = new Date(y, 0, 1);
                toDate = new Date(y, 2, 31);
            } else if (m > 2 && m < 6) {
                fromDate = new Date(y, 3, 1);
                toDate = new Date(y, 5, 30);
            } else if (m > 5 && m < 9) {
                fromDate = new Date(y, 6, 1);
                toDate = new Date(y, 8, 30);
            } else if (m > 8) {
                fromDate = new Date(y, 9, 1);
                toDate = new Date(y, 11, 31);
            }
        } else if (value === DATE_KEY.NEXT_FISCAL_YEAR) {
            y += 1;
            fromDate = new Date(y, 0, 1);
            toDate = new Date(y, 11, 31);
        }

        if (value === DATE_KEY.ALL || value === DATE_KEY.CUSTOM) {
            setFromDate(null);
            setToDate(null);
        }

        if (fromDate !== null && toDate !== null) {
            dateAfterFormat = getFormatedDateString(fromDate);
            setFromDate(dateAfterFormat);
            dateAfterFormat = getFormatedDateString(toDate);
            setToDate(dateAfterFormat);
        }

        setCustomDate(value);

        if (fromDate !== null && toDate !== null) {
            handleDateFocus('fromDate');
            handleDateFocus('toDate');
        } else {
            handleDateBlur('fromDate');
            handleDateBlur('toDate');
        }
    }

    const saveDate = () => {
        let msg = '';
        let _date = getStringParam(date);
        let _relationalOperator = getStringParam(changeLog.relationalOperator);
        if (_relationalOperator === RANGE) {
            if (getStringParam(fromDate) === '' && getStringParam(toDate) === '') {
                msg = 'Please enter From date and To date.';
            } else if (getStringParam(fromDate) === '') {
                msg = 'Please enter From date.';
            } else if (getStringParam(toDate) === '') {
                msg = 'Please enter To date.';
            } else if (moment(getStringParam(fromDate)) > moment(getStringParam(toDate))) {
                msg = 'From date cannot be greater than To date.';
            }
        } else if (_relationalOperator !== NONE && _date === '') {
            msg = 'Please enter date.';
        }

        if (msg === '') {
            let actualDate = {};
            actualDate.operator = _relationalOperator;
            if (_relationalOperator === OPERATOR_IS_EQUAL_TO || _relationalOperator === OPERATOR_GREATER_THEN || _relationalOperator === OPERATOR_LESS_THEN) {
                actualDate.value = _date;
            } else if (_relationalOperator === RANGE) {
                actualDate.rangeType = ADVANCE_SEARCH_DATE_CONSTANT[customDate];
                actualDate.from_date = fromDate;
                actualDate.to_date = toDate;
            } else if (_relationalOperator === NONE) {
                actualDate.value = 'none';
            }

            let field = getObjectParam(getField());
            onSetDate(field.name, actualDate)
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null)
        }
    }

    const generateRangeOptions = () => {
        let rangeArr = [];
        (Object.keys(ADVANCE_SEARCH_DATE_CONSTANT)).map((key) => {
            let data = {};
            data.value = key;
            data.label = ADVANCE_SEARCH_DATE_CONSTANT[key];
            rangeArr.push(data);
        })
        return rangeArr;
    }
    let field = getField();
    if (!isValidParam(field)) {
        return null;
    }
    let HHFormat = new RegExp("HH");
    let datePickerOptions = {
        enableTime: false,
        dateFormat: dateFormatFlatPicker[app.me.date_format],
        minuteIncrement: 1,
        static: true,
        time_24hr: HHFormat.test(app.me.date_format) ? true : false
    };
    let tempRangeOptions = [];
    let _rangeOptions = rangeOptions;
    if (_rangeOptions.length > 0) {
        tempRangeOptions = _rangeOptions;
    } else {
        tempRangeOptions = generateRangeOptions();
    }
    let tempFieldName = field.label;
    tempFieldName = tempFieldName.includes('_') ? tempFieldName.replace('_', ' ') : tempFieldName;
    tempFieldName = tempFieldName.charAt(0).toUpperCase() + tempFieldName.slice(1);
    return (
        <div style={{ width: '100%',padding:15 }}>
            <div>
                {fieldLabel}
            </div>
            <div className="row">
                <div className="col-sm-6">
                    <FormControl component="fieldset">
                        <RadioGroup name="shipSpeed" defaultValue={defaultSelected} value={defaultSelected} onChange={(event) => changeRadioButton(event.target.value)} style={{ width: 'auto' }}>
                            <FormControlLabel value={OPERATOR_IS_EQUAL_TO} control={<Radio color="default" />} label={OPERATOR_IS_EQUAL_TO} />
                            <FormControlLabel value={OPERATOR_GREATER_THEN} control={<Radio color="default" />} label={OPERATOR_GREATER_THEN} />
                            <FormControlLabel value={OPERATOR_LESS_THEN} control={<Radio color="default" />} label={OPERATOR_LESS_THEN} />
                            <FormControlLabel value={RANGE} control={<Radio color="default" />} label={RANGE} />
                            <FormControlLabel value={NONE} control={<Radio color="default" />} label={NONE} />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className="col-sm-6">
                    <div className="row">
                        {!isDateRange &&
                            <div className="col-sm-12">
                                <div key={changeLog.relationalOperator} className={'sf-form-field dateTimeDiv-date'} id="dateholder" style={{ ...styles.sfFormFieldContainer, paddingLeft: 0 }}>
                                    <PopOver
                                        id={'fromEmail'}
                                        key={'fromEmail'}
                                        name={tempFieldName}
                                        btnType={'date'}
                                        buttonStyle={{ fontSize: '16px' }}
                                        containerStyle={{ lineHeight: 1, marginTop: 9 }}
                                        buttonLabel={"Date"}
                                        onChange={(date, fieldName) => handleDateChange(date, fieldName)}
                                        value={date}
                                        innerLabelStyle={{ padding: "10 5 2 5", fontSize: '15px' }}
                                        height={45}
                                        options={datePickerOptions}
                                    />
                                </div>
                            </div>}
                        {isDateRange &&
                            <div className="col-sm-12">

                                <PopOver
                                    id={tempFieldName}
                                    key={field.id + tempFieldName}
                                    name={tempFieldName}
                                    btnType={'div'}
                                    buttonEndIcon={"keyboard_arrow_down"}
                                    buttonStyle={{ fontSize: '16px' }}
                                    containerStyle={{ lineHeight: 1, marginTop: 9 }}
                                    buttonLineOrigin={[46, 15]}
                                    buttonLabel={tempFieldName}
                                    isShowIcon={true}
                                    options={tempRangeOptions}
                                    onclickAction={fnSetCustomDate}
                                    value={tempFieldName}
                                    innerIcon={'left'}
                                    innerLabelStyle={{ padding: "9 5 2 5", fontSize: '15px' }}
                                    height={38}
                                    downArrowStyle={{ marginTop: '-2px', padding: '0 5 0 5' }}
                                />


                                <div className={'sf-form-field dateTimeDiv-fromDate'} id="dateholder" style={styles.sfFormFieldContainer}>
                                    <PopOver
                                        id={'fromEmail'}
                                        key={'fromEmail'}
                                        name={'fromDate'}
                                        btnType={'date'}
                                        buttonStyle={{ fontSize: '16px' }}
                                        containerStyle={{ lineHeight: 1, marginTop: 9 }}
                                        buttonLabel={"From date"}
                                        onChange={(date, fieldName) => handleDateChange(date, fieldName)}
                                        value={fromDate}
                                        innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                        height={45}
                                        options={datePickerOptions}
                                    />
                                </div>
                                <div className={'sf-form-field dateTimeDiv-toDate'} id="dateholder" style={styles.sfFormFieldContainer}>
                                    <PopOver
                                        id={'fromEmail'}
                                        key={'fromEmail'}
                                        name={'toDate'}
                                        btnType={'date'}
                                        buttonStyle={{ fontSize: '16px' }}
                                        containerStyle={{ lineHeight: 1, marginTop: 9 }}
                                        buttonLabel={"To date"}
                                        onChange={(date, fieldName) => handleDateChange(date, fieldName)}
                                        value={toDate}
                                        innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                        height={45}
                                        options={datePickerOptions}
                                    />
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
            <div style={{ ...styles.row, justifyContent: 'flex-end' }}>
                <div style={{ marginTop: '10px' }}>
                    {getActions()}
                </div>
            </div>
        </div>
    );
}


export default AdvancePartnerSearchDate;