import React from 'react';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { styles } from '../../../services/constants/styles';
import Button from '@mui/material/Button';
import { getObjectParam, getBooleanParam, getIntParam, isValidParam} from '../../../services/helper/parameterVerifier';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { openWindow } from '../../../services/helper/utils';
import * as HttpClient from '../../../services/helper/httpClient';
import { isUndefined, isNull } from 'lodash';
import { Select, MenuItem, InputLabel, FormControl, Radio, RadioGroup, FormControlLabel, TextField, Box } from '@mui/material'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as sfDialogs from '../components/sfDialogs';
import { toogleMonerisCheckout } from '../../../services/actions/paymentActions';

const moerisCredentialsFields = [{ name: 'storeId', label: getLocalizedStrings().label.PAYMENT.STORE_ID, type: 'text' }, { name: 'apiToken', label: getLocalizedStrings().label.PAYMENT.API_TOKEN, type: 'text' }, { name: 'checkoutId', label: getLocalizedStrings().label.PAYMENT.CHECKOUT_ID, type: 'text' }];


const PaymentIntegration =({})=> {
    let payemtMethodListArr = [
        {
            value: 'Credit Card',
            label: 'Credit Card',
        },
        {
            value: 'ACH',
            label: 'ACH'
        }
    ];
    const dispatch = useDispatch();
    const [isConnected, setIsConnected] = useState(false); 
    const [isConnecting, setIsConnecting] = useState(false);
    const [isShowConnectButton, setIsShowConnectButton] = useState(false);
    const [attempts, setAttempts] = useState(0);
    const [details, setDetails] = useState(null);
    const [connectedEmail, setConnectedEmail] = useState(null);
    const [payemtMethodList, setPayemtMethodList] = useState(payemtMethodListArr);
    const [defaultpaymentmethod, setDefaultpaymentmethod] = useState("Credit Card");
    const [paymentIntegrationOption, setPaymentIntegrationOption] = useState('');
    const [fieldType, setFieldType] = useState('password');
    const [monerisCredentials, setMonerisCredentials] = useState({ storeId: '', apiToken: '', checkoutId: '', monerisCredentialsFields: {}, error: false });
    useEffect(() => {
        checkIsConnected()
    },[]);



    const checkIsConnected = () => {
        try {
            const url = endPoints.PAYMENT_INTEGRATION.CHECK_CONNECT
            setIsConnecting(true);
            
            HttpClient.post(url, {})
                .then(res => {
                    console.log(`ConnectedAPIPayment`)
                    console.log(res)
                    setIsConnecting(false);
                    if (res.data.flag) {
                        setIsConnected(true);
                        setConnectedEmail(res.data.userStripeEmail);
                        setDefaultpaymentmethod(res.data.paymentMethod);
                        setPaymentIntegrationOption("Stripe");
                    }else if (!res.data.flag && res.data.hasOwnProperty('storeId')){
                        const {storeId, apiToken, checkoutId} = res.data;
                        setMonerisCredentials(prevCredentials => ({ ...prevCredentials, storeId, apiToken, checkoutId, monerisCredentialsFields: res.data }));
                        setPaymentIntegrationOption("Moneris");
                        setIsConnected(true);
                    }
                    else {
                        setIsConnected(false);
                    }
                })

        } catch (error) {
            setIsConnecting(false);
            console.error("Error in 'quickBooksIntegration.js -> checkIsConnected()':" + error);
        }
    }

    const connect = (e) => {
        if(!paymentIntegrationOption){
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.PAYMENTS.PAYMENT_INTEGRATION_REQUIRED, null);
            return;
        }
        // e.preventDefault()
        try {
            if (paymentIntegrationOption === getLocalizedStrings().label.PAYMENT.STRIPE) {
            const url = endPoints.PAYMENT_INTEGRATION.ONBOARD
            console.log(url)
            let openQBWindow = null
            console.log(HttpClient)
            HttpClient.post(url, {})
                .then(res => {
                    if (!isUndefined(res) && res.status !== -1) {
                        if (!isNull(res.data.url)) {
                            openQBWindow = openWindow({ location: res.data.url, spaces: 'scrollbars=1, location=no', width: 850, height: 650 });
                        }
                    }
                })
                setIsConnecting(true);
            let intervalVar = setInterval(() => {
                tryAndCheckIsConnected(openQBWindow, intervalVar)
            }, 3000);
            } else if (paymentIntegrationOption === getLocalizedStrings().label.PAYMENT.MONERIS) {
                handleMonerisSave();
            }
        } catch (error) {
            console.error("Error in 'paymentIntegration.js -> connect()':" + error);
        } <div style={{ borderBottom: '2.0px solid #afafaf5c' }}><span style={{ fontWeight: 'bold', fontSize: '20px' }}>{getLocalizedStrings().label.MY_INTEGRATION.PAYMENT_INTEGRATION}</span></div>
    }

   const disconnectOnboard = () => {
        try {
            if (paymentIntegrationOption === getLocalizedStrings().label.PAYMENT.MONERIS) {
                HttpClient.post(endPoints.PAYMENT_INTEGRATION.MONERIS_DISCONNECT, {})
                    .then(res => {
                        res = getObjectParam(res)
                        let flag = getIntParam(res.status)
                        setIsConnected(false);
                        setMonerisCredentials((prevCredentials) => ({ ...prevCredentials, storeId: '', apiToken: '', checkoutId: '', monerisCredentialsFields: {} }));
                        setPaymentIntegrationOption("");
                        dispatch(toogleMonerisCheckout(false));
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.PAYMENTS.DISCONNECTED_FROM_MONERIS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    })
            } else if (paymentIntegrationOption === getLocalizedStrings().label.PAYMENT.STRIPE) {
            const url = endPoints.PAYMENT_INTEGRATION.DISCONNECT_ONBOARD
            HttpClient.post(url, {})
                .then(res => {
                    console.log(`DisconnectOnboard`)
                    console.log(res)
                    res = getObjectParam(res)
                    let flag = getIntParam(res.status)
                    if (flag === 0 && isConnected === true) {
                        setIsConnected(false);
                    }
                })
            }
        } catch (e) {
            console.error(`Problem in disconnect method of paymentIntegration.js -> disconnectOnboard`)
            console.error(e)
        }
    }


    const tryAndCheckIsConnected = (openQBWindow, intervalVar) => {
        console.log(`PopUp`)
       setIsConnecting(true);
        if (openQBWindow !== null && !getBooleanParam(openQBWindow.closed)) {
            try {
                const url = endPoints.PAYMENT_INTEGRATION.CHECK_CONNECT
                HttpClient.post(url, {})
                    .then(res => {
                        console.log(`ConnectedAPIPayment`)
                        console.log(res)
                        if (res.data.flag === true) {
                           
                            if (openQBWindow !== null)
                                openQBWindow.close();
                            clearInterval(intervalVar);
                            if (isConnecting) {
                                setAttempts(0);
                                setDetails(null);
                                setIsConnecting(false);
                                setIsConnected(true);
                                setConnectedEmail(res.data.userStripeEmail);
                                setDefaultpaymentmethod(res.data.paymentMethod);
                            }
                        } else {
                            
                        }
                    })
            } catch (e) {
                console.error(`Error in paymentIntegration.js-> tryAndCheckIsConnected`)
                console.error(e)
            }
        } else if (openQBWindow !== null && getBooleanParam(openQBWindow.closed)) {
            clearInterval(intervalVar)
            setIsConnecting(false);
        }
    }

    const changePaymentMethod = (event,value) =>{
         value = event.target.value;
        try {
            setDefaultpaymentmethod(value);
        } catch (error) {
            console.error("Error in 'news.js -> changePaymentMethod()': " + error);
        }
    }
    const generatePayementMethodList = () => {
        let listItems = payemtMethodList.map((type, i) => {
            return (
                <MenuItem
                    key={i}
                    id={type.value}
                    value={type.value}
                    style={{ ...styles.popoverMenuItem }}
                    title={type.label} > {type.label}</MenuItem>
            );
        });

        return listItems;
    }

    const changePaymentIntegrationOption = (event) => {
        const { value } = event.target;
        setPaymentIntegrationOption(value);
    }
    const toggleVisibility = () => {
        setFieldType(fieldType === 'password' ? 'text' : 'password')
    }
    const savePaymentMethod = () =>{
        let params = {};
        try {
                params.default_payment_method = defaultpaymentmethod;
                var promise = Promise.resolve(HttpClient.post(endPoints.PAYMENT_INTEGRATION.SAVE_PAYEMENT_METHOD, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            dispatch (showCustomSnackBar(getLocalizedStrings().message.SECURITY_LEVEL.SUCCESS_MSG_1, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop))
                        }
                    });

                }
            
        } catch (error) {
            console.error("Error in 'payementIntegration.js -> PaymentMethod()':" + error);
        }
    }

    const handleMonerisSave = async () => {
        setIsConnecting(true);
        if (paymentIntegrationOption === "Moneris") {
            if (!monerisCredentials.storeId || !monerisCredentials.apiToken || !monerisCredentials.checkoutId) {
                setMonerisCredentials((prevCredentials) => ({ ...prevCredentials, error: true }));
                setIsConnecting(false);
                return;
            }
            const params = {
                store_id: monerisCredentials.storeId,
                api_token: monerisCredentials.apiToken,
                checkout_id: monerisCredentials.checkoutId
            };
            const response = await HttpClient.post(endPoints.PAYMENT_INTEGRATION.MONERIS_CONNECT, params);
            console.log(response);
            if (response.data?.ticket) {
                setIsConnected(true);
                setIsConnecting(false);
                dispatch(toogleMonerisCheckout(true));
                dispatch(showCustomSnackBar(getLocalizedStrings().message.PAYMENTS.CONNECTED_TO_MONERIS, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.PAYMENTS.INVALID_MONERIS_CREDENTIALS, null);
                setIsConnecting(false);
            }
        }
    }


    const handleMonerisTextFieldChange = (event) => {
        const { name, value } = event.target;
        setMonerisCredentials((prevCredentials) => ({ ...prevCredentials, [name]: value }));
        if (monerisCredentials.storeId.trim() && monerisCredentials.apiToken.trim() && monerisCredentials.checkoutId.trim()) {
            setMonerisCredentials((prevCredentials) => ({ ...prevCredentials, error: false }));
        }
    }
        let payementMethodList = generatePayementMethodList();
        return <div style={{ marginLeft: '3%' }}>


            <div style={{ borderBottom: '2.0px solid #afafaf5c' }}><span style={{ fontWeight: 'bold', fontSize: '20px' }}>{getLocalizedStrings().label.MY_INTEGRATION.PAYMENT_INTEGRATION}</span></div>


            {false && <div style={{ marginTop: '10px' }}>
                <span ><img style={{
                    width: 100,
                    height: 70
                }} src='/asset/images/stripe_dark.png' /></span>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}>
                    <div style={{ marginBottom: '0', marginLeft: '10' }}>
                        {
                            <div>
                                {
                                    (isConnected && !isConnecting) && <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                        {`Connected as ${connectedEmail}`}
                                    </span>

                                }
                                {
                                    (!isConnected && !isConnecting) && <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
                                        {getLocalizedStrings().label.PAYMENT.LABEL}
                                    </span>
                                }
                            </div>
                        }

                    </div>
                    {
                        !isConnecting &&
                        <div>
                            {
                                !isConnected ? <Button style={styles.primaryButton} onClick={()=>connect()} >{getLocalizedStrings().label.PAYMENT.CONNECT_BUTTON}</Button> :
                                    <Button style={styles.primaryButton} onClick={()=> disconnectOnboard()} >{getLocalizedStrings().label.PAYMENT.DISCONNECT_BUTTON}</Button>
                            }
                        </div>
                    }
                   
                </div>
                {isConnected && 
                        <div style={{marginTop:'20px' }}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                            <InputLabel id="sf-whitelabel-simple-select-outlined-label" className="sf-paymenttype">{"Default Payment Method"}</InputLabel>
                            <Select
                                onChange={(event,value)=>changePaymentMethod(event,value)}
                                value={defaultpaymentmethod}
                                style={{ height: '42px' }}
                                label={"Default Payment Method"}
                                labelId="user"
                                className={"sf-fields-bg"}
                            >
                                {payementMethodList}
                            </Select>
                            </FormControl>

                            <div style={{ paddingBottom: '30px', overflow: 'hidden', float: 'right',marginTop:'15px' }}>
                                <Button
                                    key='save'
                                    onClick={()=>savePaymentMethod()}
                                    style={{ ...styles.primaryButton, verticalAlign: 'top', marginRight: '-3px' }}
                                >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                            </div>
                        </div>
                       

                    }
            </div>}

            <div style={{ marginTop: '10px', marginBottom:'2rem' }}>
            <div><span style={{ fontWeight: 'bold', fontSize: '18px' }}>{getLocalizedStrings().label.MY_INTEGRATION.PHONE_AND_TEXT_PROVIDER}</span></div>
            <FormControl component="fieldset">
                <RadioGroup name="serviceradio" onChange={changePaymentIntegrationOption} value= {paymentIntegrationOption} style={{ marginTop: '10px', fontSize: '15px' }} >
                    <FormControlLabel value={getLocalizedStrings().label.PAYMENT.STRIPE} title={getLocalizedStrings().label.PAYMENT.STRIPE} disabled={(paymentIntegrationOption === getLocalizedStrings().label.PAYMENT.MONERIS && isConnected) || isConnecting} control={<Radio color="default" />} label={getLocalizedStrings().label.PAYMENT.STRIPE} />
                    {isConnected && paymentIntegrationOption === getLocalizedStrings().label.PAYMENT.STRIPE &&
                        <div style={{marginTop:'20px' }}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                            <InputLabel id="sf-whitelabel-simple-select-outlined-label" className="sf-paymenttype">{getLocalizedStrings().label.PAYMENT.DEFAULT_PAYMENT_METHOD}</InputLabel>
                            <Select
                                onChange={(event,value)=>changePaymentMethod(event,value)}
                                value={defaultpaymentmethod}
                                style={{ height: '42px' }}
                                label={getLocalizedStrings().label.PAYMENT.DEFAULT_PAYMENT_METHOD}
                                labelId="user"
                                className={"sf-fields-bg"}
                            >
                                {payementMethodList}
                            </Select>
                            </FormControl>

                            <div style={{ paddingBottom: '30px', overflow: 'hidden', float: 'right',marginTop:'15px' }}>
                                <Button
                                    key='save'
                                    onClick={()=>savePaymentMethod()}
                                    style={{ ...styles.primaryButton, verticalAlign: 'top', marginRight: '-3px' }}
                                >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                            </div>
                        </div>
                    }
                    <FormControlLabel value={getLocalizedStrings().label.PAYMENT.MONERIS} title={getLocalizedStrings().label.PAYMENT.MONERIS} disabled={(paymentIntegrationOption === getLocalizedStrings().label.PAYMENT.STRIPE && isConnected) || isConnecting} control={<Radio color="default" />} label={getLocalizedStrings().label.PAYMENT.MONERIS} />
                    
                </RadioGroup>
            </FormControl>
                {paymentIntegrationOption == getLocalizedStrings().label.PAYMENT.MONERIS && <Box sx={{display: 'flex',alignItems: 'center',gap: '2rem'}}> <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px',width: '20rem'}}> {moerisCredentialsFields.map((field, index) => {
                    return <TextField size='small' key={index} name={field.name} label={field.label} type={fieldType} value={monerisCredentials[field.name]} onChange={handleMonerisTextFieldChange} error = {Boolean (!monerisCredentials[field.name.trim()] && monerisCredentials.error)} helperText={!monerisCredentials[field.name.trim()] && monerisCredentials.error ? `${field.label} cannot be empty.` : ''}/>
                })}</Box>
                <Box>
                    {fieldType !== 'password' ? <VisibilityIcon onClick={toggleVisibility}/> : <VisibilityOffIcon onClick={toggleVisibility}/>}
                </Box>  
                 </Box>}
                
            {!isConnected && !isConnecting && <div style={{ textAlign: 'right', width: '100%', marginTop: '2rem' }}>
                <div style={{ display: 'inline-block' }}>
                    <Button
                        key='save'
                        onClick={connect}
                        title={getLocalizedStrings().label.PAYMENT.CONNECT_BUTTON}
                        style={{ ...styles.primaryButton, marginLeft: '6px', marginTop: '1.5%', marginRight: '0px' }}
                    >{getLocalizedStrings().label.PAYMENT.CONNECT_BUTTON}</Button>
                </div>
            </div>}

        </div>



                <Box style={{display: 'flex', flexDirection: 'column', gap: '10px',width: '100%'}}>
            {isConnecting && <span style={{ width: '68%' }}>Connecting<img src='/asset/images/processing.gif' style={{ height: '10px', width: '50px' }} /></span>}
      
                {
                    (isConnected && !isConnecting && paymentIntegrationOption === getLocalizedStrings().label.PAYMENT.STRIPE) && <span style={{ fontWeight: 'normal', fontSize: '14px' }}>
                        Connected as <b>{connectedEmail}</b>
                    </span>

                }
      
                {isConnected && <span style={{display:'flex',backgroundColor: 'rgb(227, 230, 232)', padding: '10px', borderRadius: '5px',marginTop:'10px',alignItems:'center',gap:'0.5rem'}}>{'You are connected to ' + paymentIntegrationOption}{paymentIntegrationOption && <Button style={{...styles.secondaryButton}} title={getLocalizedStrings().label.DOCUSIGN.DISCONNECT_BUTTON} onClick={()=>disconnectOnboard()}>{getLocalizedStrings().label.DOCUSIGN.DISCONNECT_BUTTON}</Button>}</span>}
            </Box>
      
        </div>;
    
}
export default PaymentIntegration