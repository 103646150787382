import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DataTable from '../components/dataTable';
import ListViewHeader from '../components/listViewHeader';
import NoRecord from '../components/noRecord';
import { getListViewData, getListViewSelectedRecord, populateFiltersFromActiveTab, removeScrollPosition, setMounted, setSelectedAuditfilter } from '../../../services/actions/listViewAcions';
import { setHeader } from '../../../services/actions/headerActions';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import {
    getArrayParam,
    getBooleanParam,
    getIntParam,
    getObjectParam,
    getStringParam,
    isValidParam,
} from '../../../services/helper/parameterVerifier';
import {
    getActiveTab,
    getActiveTabInfo,
    TYPE_COLUMN_VIEW,
    TYPE_LIST_VIEW,
    updateActiveTab,
    updateTabInfo,
} from '../../../services/helper/sfTabManager';
import GroupByFieldComponent from '../components/groupByFieldComponent';
import { makeTiteleCase } from '../../../services/helper/utils';
import ListViewFilterDropDown from '../components/listViewFilterDropDown';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';

import { getSelectedObject, hasAccessPermission, saveRecentFilter } from '../../../services/helper/common';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import NoAssignmentRule from '../components/noAssignmentRule';
import { Icon } from '@mui/material';
import { getVerifiedEmais } from '../../../services/actions/sendMailActions';
import SFListBox from '../components/sfListBox';
import DraggableDataTable from '../components/draggableDataTable';
import * as sfDialogs from '../components/sfDialogs';
import _ from 'lodash';
import { getCookie,setCookie } from '../../../services/helper/sfCookies';
import { useLocation } from 'react-router';

const getListViewInstanceFromRedux = (state, props) => {
    let tabInfo = getActiveTabInfo();
    let isReport = getBooleanParam(tabInfo.isReport);
    let isAssignRule = getBooleanParam(tabInfo.isAssignRule);
    if (getBooleanParam(props.isDetailView)) {
        return state.childListView;
    } else if (getBooleanParam(isReport)) {
        return state.otherListView;
    } else if (getBooleanParam(isAssignRule)) {
        return state.assignListView;
    } else if (getBooleanParam(props.isDuplicate)) {
        return state.duplicateListView;
    } else if (getBooleanParam(props.suffix === constants.TASK_LIST)) {
        return state.detailViewTaskList;
    } else {
        return state.listView;
    }
};

const getGroupByInfoFromRedux = (state) => {
    let tabInfo = getActiveTabInfo();
    let isAssignRule = getBooleanParam(tabInfo.isAssignRule);
    if (getBooleanParam(isAssignRule)) {
        return state.assignListView.groupByInfo;
    } 
    else if (state.childListView.data.object === constants.AUDIT_ANSWERS_OBJECT && tabInfo.isAuditAnswerChild) {
        return state.childListView.groupByInfo;
    } 
    else {
        return state.listView.groupByInfo;
    }
}

const ListView = (props) => {
    const dispatch = useDispatch();
    const listRef = useRef(null)
    const reduxApp = useSelector((state) => state.app);
    const reduxTab = useSelector((state) => state.tab);
    const reduxSendMail = useSelector((state) => state.sendMail);
    const reduxListView = useSelector((state) => getListViewInstanceFromRedux(state, props));
    const reduxGroupByInfo = useSelector((state) => getGroupByInfoFromRedux(state));
    const selectedFilter = useSelector((state) => state.childListView.selectedFilter);
    const tabs = useSelector((state) => state.tab.tabs);
    const tempfilterValue = useSelector((state) => state.listView.filterByInfo.filterValue);
    const dialogPropsDetails = useSelector((state) => state.appContainer.dialogProps);
    const hasFindAndLink = Object.keys(dialogPropsDetails?.data || {}).some(key => key === 'isFindAndLink');
    const [searchValue, setSearchValue] = useState('')

    const componentMounted = useRef(false);
    const location = useLocation();
    const path = location.pathname.split("/")[1];

    const [detailViewParams, setDetailViewParams] = useState({
        parent_object: '',
        parent_record_id: '',
    });
    const [activeObject, setActiveObject] = useState('');
    const [activeSearchText, setActiveSearchText] = useState('');
    const [activeSelectedModuleName, setActiveSelectedModuleName] = useState('');
    const [activeSearchParams, setActiveSearchParams] = useState({});
    const [activeQueryId, setActiveQueryId] = useState(-1);
    const [activeQueryType, setActiveQueryType] = useState('');
    const [activeQueryName, setActiveQueryName] = useState('');
    const [activeParentRecordId, setActiveParentRecordId] = useState(0);
    const [activeListViewRecordCount, setActiveListViewRecordCount] = useState(0);
    const [activeListViewQueryId, setActiveListViewQueryId] = useState(0);
    const [noRecordFilterText, setNoRecordFilterText] = useState('');
    const [isGlobalSearch, setGlobalSearch] = useState(false);

    function listViewCallBack(object, params, isRefresh, selectedFilterValue,callFrom) {
        isRefresh = getBooleanParam(isRefresh);
        let isDetailView = getBooleanParam(props.isDetailView);
        let isLookup = getBooleanParam(props.isLookup);
        let isChildIdListView = props.hasOwnProperty('isChildIdListView') && props.isChildIdListView;
        if (object === constants.BROADCAST_CAMPAIGNS_OBJECT || object === constants.AUTOMATION_DESIGNER_OBJECT) {
            dispatch(setHeader(object, null, object, false, false));
        }
        //let _activeTab = getActiveTab();
        // if(_activeTab.label && _activeTab.type === "TYPE_LIST_VIEW"){
        //     dispatch(setHeader(_activeTab.object, _activeTab.label, null, false, true));
        // }
        if (isLookup) {
            setNoRecordFilterText(getStringParam(params.search_text));
            props.getLookupListViewData(object, params);
        } else {
            if (isRefresh || isDetailView) {
                dispatch(setMounted(false, isDetailView));
                isRefresh = true;
            }
            let activeTab = getActiveTab();
            activeTab = getObjectParam(activeTab);
            let info = getObjectParam(activeTab.info);
            let startIndex = getIntParam(info.startIndex);
            let pageSize = getIntParam(info.pageSize);

            let groupByFieldName = getStringParam(info.groupByFieldName);
            let groupByCondition = getStringParam(info.groupByCondition);
            let searchText = getStringParam(info.searchText);
            let searchFieldName = getStringParam(info.searchFieldName);
            let inputParam = getObjectParam(info.input_param);
            let isReport = getBooleanParam(info.isReport);
            let isSearchWithinList = getBooleanParam(info.isSearchWithinList);
            let isAssignRule = getBooleanParam(info.isAssignRule);
            let statusfieldName = getStringParam(info.statusfieldName);
            let getExtraFilter = getBooleanParam(info?.getExtraFilter);
            let selectedObjectSet;

            params = getObjectParam(params);
            let cookieName = 'select-' + object + 'groupby' + reduxApp.me.projectId + '-' + reduxApp.me.id;
            let field_name = getCookie(cookieName); 
            if(object === constants.MARKETING_TEMPLATE_OBJECT){                       
                if(field_name){
                    groupByFieldName= field_name
                }
                else{
                    groupByFieldName = "t_subcategory"
                }
            }
            if (groupByFieldName !== '' && !isChildIdListView) {
                params.group_by_field_name = groupByFieldName;
            }
            if (groupByCondition !== '' && !isChildIdListView) {
                params.group_by_condition = groupByCondition;
            }
            if (searchFieldName !== '') {
                params.search_field_name = searchFieldName;
                params.search_type = constants.SEARCH_TYPE_ANYWHERE;
            }
            if (searchText !== '') {
                params.search_text = searchText;
            }
            if (props.object === constants.WORKFLOW_OBJECT) {
                if (statusfieldName !== '') {
                    params.status_field_name = statusfieldName;
                } else {
                    params.status_field_name = tempfilterValue;
                }
            }
            if (activeTab.object === constants.ROYALTY_RUN_OBJECT) {
                params.is_royalty_run_create = getBooleanParam(info.is_royalty_run_create);
                if (groupByFieldName == '' && !isChildIdListView) {
                    params.group_by_field_name = "t_status";
                    info.groupByFieldName = 't_status';
                    updateTabInfo(info);
                }
                if (groupByCondition == '' && !isChildIdListView) {
                    params.group_by_condition = "Processing";
                    info.groupByCondition = 'Processing';
                    updateTabInfo(info);
                }
            }
            if (activeTab.object === constants.SEARCH_RESULTS || activeTab.object === constants.ADVANCE_SEARCH_RESULTS) {
                params.query_id = 0;
                params.query_name = constants.SEARCH_QUERY_NAME;
                if (activeTab.object === constants.SEARCH_RESULTS) {
                    params.query_type = constants.SEARCH_QUERY_TYPE_GLOBAL;
                    let search = getObjectParam(info.search);
                    params.search_text = getStringParam(search.searchText);
                    let selectedModuleName = reduxApp.selectedModuleName;
                    if (reduxApp.selectedSearchObject === null) {
                        let selectedSearchItemObject = localStorage.getItem(
                            'selectedSearchItemObject_' + reduxApp.me.projectId + '_' + reduxApp.me.id,
                        );
                        let objects = new Set();
                        objects.add(selectedSearchItemObject);
                        let selectedSearchObject = getObjectParam(reduxApp.selectedSearchObject);
                        selectedSearchObject[selectedModuleName] = objects;
                        reduxApp.selectedSearchObject = selectedSearchObject;
                        selectedObjectSet = objects;
                    } else {
                        selectedObjectSet = reduxApp.selectedSearchObject[selectedModuleName];
                        localStorage.setItem('selectedSearchItemObject_' + reduxApp.me.projectId + '_' + reduxApp.me.id, [...selectedObjectSet][0]);
                    }
                    let selectedObject = [...selectedObjectSet][0];
                    if (selectedObject === 'Unit’s Accounts' && info.hasOwnProperty('isUnitsListView') && info.isUnitsListView) {
                        params.click_form = 'Units_Accounts';
                    }
                } else if (activeTab.object === constants.ADVANCE_SEARCH_RESULTS) {
                    params.query_type = constants.SEARCH_QUERY_TYPE_ADVANCE;
                    params.search_type = constants.SEARCH_TYPE_START_WITH;
                    let advanceSearch = getObjectParam(info.advanceSearch);
                    let searchParams = getObjectParam(advanceSearch.searchParams);
                    params.search = getArrayParam(searchParams.search);
                }
            } else if (isReport) {
                let filterObj = getObjectParam(activeTab.info.filter);
                let queryId = getIntParam(filterObj.id);
                let queryName = getStringParam(filterObj.name);
                let queryType = getStringParam(filterObj.type);

                params.query_id = queryId;
                params.query_name = queryName;
                params.query_type = queryType;

                if (activeTab.object === constants.ROYALTY_OBJECT || activeTab.object === constants.COMPLIANCE_OBJECT) {
                    let inputParamSql = getStringParam(activeTab.info.input_param_sql);
                    if (inputParamSql && inputParamSql.length > 0) {
                        params.input_param_sql = inputParamSql;
                    }
                }
            } else {
                if (object === constants.EMAIL_INTEGRATION_OBJECT) {
                    if (!reduxSendMail || !reduxSendMail.verifiedEmails || reduxSendMail.verifiedEmails.length === 0) {
                        dispatch(getVerifiedEmais(false));
                    }
                }
                let queryId = 0;
                let queryName = '';
                let queryType = '';
                if (activeTab.hasOwnProperty('info') && activeTab.info && activeTab.info.hasOwnProperty('filter')) {
                    let filterObj = getObjectParam(activeTab.info.filter);
                    if (filterObj.hasOwnProperty('id')) {
                        queryId = getIntParam(filterObj.id);
                        queryName = getStringParam(filterObj.name);
                        queryType = getStringParam(filterObj.type);
                    }
                }

                if (!params.hasOwnProperty('query_id')) {
                    params.query_id = queryId;
                }

                if (!params.hasOwnProperty('query_name')) {
                    params.query_name = queryName;
                }
                if (object === 'notetypes' && params.query_name === '') {
                    params.query_name = 'Note Types';
                }
                if (!params.hasOwnProperty('query_type')) {
                    params.query_type = queryType;
                }
                if (params.hasOwnProperty('sort_type') && !params.query_name && object === constants.APPOINTMENT_TYPES_OBJECT) {
                    params.query_name = queryName;
                }
            }
            if (isValidParam(params.sort_field_name) && isValidParam(params.sort_type)) {
                inputParam.sort_field_name = getStringParam(params.sort_field_name);
                inputParam.sort_type = getStringParam(params.sort_type);
            }
            if (props.suffix === constants.TASK_LIST) {
                params.parent_object = info.filter.parent_object;
                params.parent_record_id = info.filter.parent_record_id;
            }
            if(activeTab.object === constants.ADVANCE_SEARCH_RESULTS && activeTab.actionObject == constants.ADVANCE_PARTNE_RSEARCH) {
                params.query_name = 'Partners';
            }
            //start

            if (getExtraFilter) {
                params.query_id = info.filter.query_id;
                let _query_name = info.filter.query_name;
                let _search_text = info.filter.search_text;
                let _search_field_name = info.filter.search_field_name; 
                if (_query_name === "All Tasks" || _query_name === 'My Tasks') {
                    params.query_name = 'All records';
                    if (_query_name === "All Tasks") {
                        params.task_for = _query_name
                    }
                } else {
                    params.query_name = _query_name;
                }
                params.query_type = info.filter.query_type;
                // if(_search_text.split(",")[0].trim() === 'All' && props.object === constants.TASKS && props.parentObject !== constants.AUDITS_OBJECT){
                //     _search_text = _search_text.split(",")[1].trim();
                //     _search_field_name = _search_field_name.split(",")[1].trim();
                // }
                // if(props.object === constants.TASKS && props.parentObject === constants.AUDITS_OBJECT){
                //     if (_search_text.includes(",") && _search_text.includes("undefined")) {
                //         let  _searchText =  _search_text.split(",")[0];
                //         _search_text = _searchText;
                //     } 
                // }
                if(props.object === constants.TASKS && (props.parentObject === constants.AUDITS_OBJECT || props.parentObject === constants.ACCOUNTS_OBJECT || props.suffix === constants.TASK_LIST)){
                    if(!params.parent_object){
                        params.parent_object = props.parentObject;
                    }
                    if(!params.parent_record_id){
                        params.parent_record_id = props.parentRecordId;
                    }
                    if(_search_text === 'All'){
                        if(info.hasOwnProperty('searchText') && info.searchText.trim()){
                            params.search_text = info.searchText;
                            params.search_field_name = info.searchFieldName;
                        }else{
                            delete params['search_text'];
                            delete params['search_field_name'];
                        }
                    }else{
                        if(info.hasOwnProperty('searchText') && info.searchText.trim()){
                            _search_text = `${_search_text},${info.searchText}`;
                            _search_field_name = `${_search_field_name},${info.searchFieldName}`;
                        }
                        params.search_text = _search_text;
                        params.search_field_name = _search_field_name;
                    }
                    delete params['group_by_condition'];
                }else{
                    params.search_text = _search_text;
                    params.search_field_name = _search_field_name;
                }
                params.search_type = info.filter.search_type;
            }

            //end
            params = { ...params, ...inputParam };

            if (!isValidParam(params) && startIndex >= 0 && pageSize > 0) {
                params = {};
                if (!isChildIdListView) {
                    params.start_index = startIndex;
                }
                params.page_size = pageSize;
            } else if (
                isValidParam(params) &&
                !params.hasOwnProperty('start_index') &&
                !params.hasOwnProperty('page_size') &&
                startIndex >= 0 &&
                pageSize > 0
            ) {
                if (!isChildIdListView) {
                    params.start_index = startIndex;
                }
                params.page_size = pageSize;
            }
            if (isDetailView) {
                params.isDetailView = true;
            } else if (isReport) {
                params.isReport = true;
            } else if (isAssignRule) {
                let object = getSelectedObject();
                if (object === constants.EMAIL_MARKETING) {
                    object = constants.CONTACTS_OBJECT;
                }
                params.parent_object = object;
            }
            let settingObject = object;
            if (activeTab.object === 'settings' && isValidParam(info.is_duplicate_object) && getBooleanParam(info.is_duplicate_object)) {
                settingObject = constants.FIND_DUPLICATE_OBJECT;
            }
            if (object === constants.DOWNLOAD_LEADS_OBJECT) {
                if (info.hasOwnProperty('leadStoreSearch') && getBooleanParam(info.leadStoreSearch)) {
                    let param = {};
                    if (info.hasOwnProperty('company')) {
                        param.company = getStringParam(info.company);
                    }
                    if (info.hasOwnProperty('industry')) {
                        param.industry = getStringParam(info.industry);
                    }
                    if (info.hasOwnProperty('state')) {
                        param.state = getStringParam(info.state);
                    }
                    if (info.hasOwnProperty('city')) {
                        param.city = getStringParam(info.city);
                    }
                    if (info.hasOwnProperty('zip')) {
                        param.zip = getStringParam(info.zip);
                    }
                    param.leadStoreSearch = true;
                    params.leadstoreSearchObj = param;
                }
            }
            if (object === constants.PRODUCTS_OBJECT) {
                params.input_param_sql = ' product_type != "Royalty"';
            }
            let isMounted = getBooleanParam(reduxListView.mounted);
            if (
                info.hasOwnProperty('isUnitsListView') &&
                info.isUnitsListView &&
                info.hasOwnProperty('isShow') &&
                info.isShow &&
                object === constants.ACCOUNTS_OBJECT
            ) {
                isRefresh = true;
            }
            if (info.hasOwnProperty('globalAccount') && info.globalAccount && object === constants.ACCOUNTS_OBJECT) {
                // isRefresh = true;
            }
            if (isValidParam(info) && info.hasOwnProperty('isUnitsAccount') && info.hasOwnProperty('UnitsAccountProjectId') && info.isUnitsAccount) {
                params.isUnitsAccount = info.isUnitsAccount;
                params.UnitsAccountProjectId = info.UnitsAccountProjectId;
            }
            if (isDetailView && props.parentObject === constants.AUDITS_OBJECT && props.object === constants.AUDIT_ANSWERS_OBJECT && props.suffix !== 'auditList') {
                let childQueryParams = getObjectParam(props.childQueryParams);
                let activeTab = getActiveTab();
                activeTab = getObjectParam(activeTab);
                let info = getObjectParam(activeTab.info);
                let pageSize = getIntParam(info.pageSize);
                let startIndex = getIntParam(info.startIndex);
                params.parent_object = props.parentObject;
                params.parent_record_id = props.parentRecordId;
                params.query_id = getIntParam(childQueryParams.query_id);
                delete params['is_group_by_info'];
                if (pageSize) {
                    params.page_size = pageSize;
                }else{
                    params.page_size = reduxListView.data.page_size;
                }
                if (startIndex) {
                    params.start_index = startIndex;
                }
                params.group_by_condition = activeTab.info.groupByCondition ? activeTab.info.groupByCondition : activeTab.info.group_by_condition;
                if (selectedFilterValue === "Answers with Corrective Actions" || (selectedFilterValue === undefined && selectedFilter === "Answers with Corrective Actions")) {
                    params.query_name = "All child records";
                    params.query_type = "-1";
                    if(params.start_index){
                        delete params['start_index'];
                    }
                    if(params.is_group_by_info){
                        delete params['is_group_by_info'];
                    }
                    if(!params.search_text){
                        params.search_text = activeTabInfo?.search_text;
                    }
                    if(!params.search_field_name){
                        params.search_field_name = activeTabInfo?.search_field_name;
                    }
                    if(!params.search_type){
                        params.search_type = activeTabInfo?.search_type;
                    }
                    if(!params.search_field_name){
                        dispatch(setSelectedAuditfilter('All Audit Answers'));
                    }
                } 
            }    
            if(activeTab.info?.object === constants.TASKS && activeTab.info?.perent_object === constants.ACCOUNTS_OBJECT){
                if(!params.group_by_condition){
                    params.group_by_condition = activeTab.info.groupByCondition ? activeTab.info.groupByCondition : activeTab.info.group_by_condition;
                }
            }  
            if (
                info.hasOwnProperty('callFrom') &&
                info.callFrom === 'task_listview' &&
                activeTab.object === constants.TASKS &&
                activeTab.item === constants.CALENDAR_TASK
            ) {
                params.click_form = 'task_listview';
                params.task_status = info.status;
                params.userName = info.userName;
                isRefresh = true;
            }
            if (!isMounted || isRefresh || isSearchWithinList) {
                dispatch(getListViewData(settingObject, params, '', props.suffix));
            }
            if(isRefresh && activeTab.info.startIndex && activeTab.info.startIndex > 0 && (selectedFilterValue !== "Answers with Corrective Actions" && selectedFilter !== "Answers with Corrective Actions")){
                params.start_index = activeTab.info.startIndex;
            }
            if (object === constants.DOWNLOAD_LEADS_OBJECT) {
                let isSearchFieldInfoMounted = getBooleanParam(reduxListView.isSearchFieldInfoMounted);
                if (!isSearchFieldInfoMounted) {
                    let searchInfoParams = {
                        is_search_field_info: true,
                        ...params,
                    };
                    dispatch(getListViewData(object, searchInfoParams, '', props.suffix));
                }

                if (info.hasOwnProperty('leadStoreSearch') && getBooleanParam(info.leadStoreSearch) && !isRefresh) {
                    dispatch(getListViewData(object, params, '', props.suffix));
                }
            } else{
                let isGroupByMounted = getBooleanParam(reduxListView.isGroupByMounted);
                if (
                    (!isGroupByMounted || isRefresh || isSearchWithinList) &&
                    !isReport &&
                    !isLookup &&
                    (!isDetailView || (isDetailView && object === constants.AUDIT_ANSWERS_OBJECT))
                ) {
                    let groupByParams = {
                        is_group_by_info: true,
                        ...params,
                    };
                    dispatch(getListViewData(settingObject, groupByParams, '', props.suffix));
                }
            }
        }
    }

    useEffect(() => {
        const franchiseAppLoginDetails = JSON.parse(localStorage.getItem('franchiseAppLoginDetails'))
        let shouldReturnToInitialPosition = JSON.parse(localStorage.getItem(`shouldReturnToInitialPos_${reduxApp.me.projectId}_${reduxApp.me.id}`));
        let callFrom;
        if (franchiseAppLoginDetails && franchiseAppLoginDetails.length >= 2) {
            const index = franchiseAppLoginDetails.length - 1;
            const previousUnitLoginDetails = franchiseAppLoginDetails[index]
            callFrom = previousUnitLoginDetails.callFrom
        } else if (franchiseAppLoginDetails && franchiseAppLoginDetails.length === 1) {
            const previousUnitLoginDetails = franchiseAppLoginDetails[0]
            callFrom = previousUnitLoginDetails.callFrom
        }
        if (callFrom === constants.DETAIL || callFrom === constants.LIST) {
            if (shouldReturnToInitialPosition) {
                localStorage.removeItem('selectedSearchItemObject_' + reduxApp.me.projectId + '_' + reduxApp.me.id);
                localStorage.removeItem('searchCriteria_' + reduxApp.me.projectId + '_' + reduxApp.me.id);
            }
        }
        return () => {
            let isDetailView = getBooleanParam(props.isDetailView);
            let isLookup = getBooleanParam(props.isLookup);
            let isReport = getBooleanParam(props.isReport);
            let isAssignRule = getBooleanParam(props.isAssignRule);
            let isDuplicate = getBooleanParam(props.isDuplicate);
            let fromSettings = getBooleanParam(props.fromSettings);
            if (!isLookup && (isDetailView || isReport || isAssignRule || isDuplicate || fromSettings)) {
                dispatch(setMounted(false, isDetailView, isReport, isAssignRule, isDuplicate));
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // **useEffect
    useEffect(() => {
        try {
            let isLookup = getBooleanParam(props.isLookup);
            if (!isLookup) {
                let object = getStringParam(props.object);
                let parentObject = getStringParam(props.parentObject);
                let isDetailView = getBooleanParam(props.isDetailView);
                let parentRecordId = getIntParam(props.parentRecordId);
                let childQueryParams = getObjectParam(props.childQueryParams);

                let isSearch = getBooleanParam(props.isSearch);
                let searchParams = getObjectParam(props.searchParams);

                let params = getObjectParam(populateFiltersFromActiveTab());

                if (parentObject === constants.RECYCLE_BIN_OBJECT) {
                    parentObject = constants.ACCOUNTS_OBJECT;
                }

                if (isDetailView) {
                    if (object === constants.ACCOUNTS_OBJECT && parentObject === constants.ACCOUNTS_OBJECT) {
                        params = {
                            parent_object: parentObject,
                            parent_record_id: parentRecordId,
                            relationTableId: 10,
                            query_id: 0,
                            query_name: '',
                            query_type: '',
                        };
                    } else {
                        params = {
                            parent_object: parentObject,
                            parent_record_id: parentRecordId,
                            query_id: getIntParam(childQueryParams.query_id),
                            query_name: getStringParam(childQueryParams.query_name),
                            query_type: getStringParam(childQueryParams.query_type),
                        };
                    }
                    setDetailViewParams(params);
                }
                let shouldFetch = false;
                if (isSearch) {
                    params = { ...searchParams };
                    if (componentMounted.current) {
                        setGlobalSearch(true);
                    }
                }

                let isFetch = true;
                let tab = getActiveTab();
                tab = getObjectParam(tab);
                let tabObject = getStringParam(tab.object);

                let queryId = getIntParam(params.query_id);
                let queryType = getStringParam(params.query_type);
                let queryName = getStringParam(params.query_name);

                if (!componentMounted.current) {
                    if (activeQueryType === '' && activeQueryId === -1 && activeQueryName === '') {
                        shouldFetch = true;
                        isFetch = false;
                    }
                } else {
                    if (activeQueryType === '' && activeQueryId === -1 && activeQueryName === '') {
                        shouldFetch = true;
                        isFetch = false;
                    } else if (tabObject === object) {
                        // if (activeQueryType !== queryType) {
                        // 	shouldFetch = true;
                        // } else
                        if (activeQueryType === queryType) {
                            if (activeQueryName !== queryName) {
                                shouldFetch = true;
                            } else if (activeQueryName === queryName) {
                                if (activeQueryId !== queryId) {
                                    shouldFetch = true;
                                } else if (isValidParam(props) && props.isDetailView) {
                                    if (props.parentRecordId !== activeParentRecordId) {
                                        shouldFetch = true;
                                    }
                                }
                            }
                        }
                    } else if (isValidParam(props) && props.isDetailView) {
                        if (props.parentRecordId !== activeParentRecordId) {
                            shouldFetch = true;
                        }
                    }
                }
                if (getBooleanParam(tab.info?.isFetchListView)) {
                    shouldFetch = true;
                    delete tab.info.isFetchListView;
                    updateTabInfo(tab.info);
                }
                let refreshFromSettings = false;
                if (tab.hasOwnProperty('refreshFromSettings') && tab.refreshFromSettings) {
                    delete tab.refreshFromSettings;
                    updateActiveTab(tab);
                    refreshFromSettings = true;
                }
                let listview = reduxListView.data;

                if (componentMounted.current) {
                    listViewCallBack(object, params);
                    componentMounted.current = true;
                } else {
                    if (
                        reduxApp.selectedModuleName !== activeSelectedModuleName &&
                        (object === constants.SALES_TEMPLATE_OBJECT ||
                            object === constants.MARKETING_TEMPLATE_OBJECT ||
                            object === constants.SMS_TEMPLATE_OBJECT ||
                            object === constants.AGREEMENT_TEMPLATE_OBJECT ||
                            object === constants.CALL_SCRIPT_OBJECT)
                    ) {
                        shouldFetch = true;
                    }
                    if (shouldFetch && params) {
                        shouldFetch = isFetch;
                    }
                    
                    if (activeQueryName !== queryName && params && Object.keys(params).length > 0) { 
                        shouldFetch = true;
                    }

                    if (
                        tab.info.hasOwnProperty('isUnitsListView') &&
                        tab.info.isUnitsListView &&
                        tab.info.hasOwnProperty('isShow') &&
                        tab.info.isShow &&
                        object === constants.ACCOUNTS_OBJECT
                    ) {
                        shouldFetch = true;
                        tab.info = { ...tab.info, isShow: false };
                        updateActiveTab(tab);
                    }
                    if (tab.info.hasOwnProperty('globalAccount') && tab.info.globalAccount && object === constants.ACCOUNTS_OBJECT) {
                        tab.info = { ...tab.info, globalAccount: false };
                        updateActiveTab(tab);
                    }
                    if (params?.reportCallFrom === 'broadReport') {
                        object = constants.CONTACTS_OBJECT
                    }
                    if (
                        (object !== activeObject ||
                            (isSearch && (getStringParam(activeSearchText) !== getStringParam(props.searchText) || props.object !== activeObject)) ||
                            (isSearch && JSON.stringify(searchParams) !== JSON.stringify(activeSearchParams)) ||
                            (object === activeObject && shouldFetch)) &&
                        !shouldFetch
                    ) {
                       listViewCallBack(object, params, true);
                    } else if (
                        activeListViewRecordCount > 0 &&
                        getArrayParam(listview.records).length === 0 &&
                        activeListViewQueryId === listview.query_id
                    ) {
                        let info = getObjectParam(getActiveTabInfo());
                        if (info.groupByCondition && !info.searchText) {
                            delete info.groupByCondition;
                            updateTabInfo(info);
                            listViewCallBack(object, params, true);
                        }
                    } else if (refreshFromSettings) {
                        listViewCallBack(object, params, true);
                    } else if (shouldFetch && params) {
                        listViewCallBack(object, params, true);
                    }

                    // redirect to search All condition record - only parents listView
                    if (!props.isDetailView && reduxListView?.groupByInfo?.group_by && reduxListView?.groupByInfo?.group_by?.length > 0) {
                        let isRecordCountAvailableInAllConditon = getArrayParam(reduxListView?.groupByInfo?.group_by).filter((item) =>
                            item.label === "All" && item.record_count > 0
                        )

                        if (listview.records !== undefined && getArrayParam(listview.records).length === 0 && isRecordCountAvailableInAllConditon.length > 0) {
                            const { label, record_count } = isRecordCountAvailableInAllConditon[0]
                            callBack(label, record_count)
                        }
                    }
                    // end
                }

                // update active state
                if (listview) {
                    if (listview['query_id'] !== activeListViewQueryId) {
                        setActiveListViewQueryId(listview.query_id);
                    }
                    const count = getArrayParam(listview['records']).length;
                    if (count !== activeListViewRecordCount) {
                        setActiveListViewRecordCount(count);
                    }
                }
                if (object !== activeObject) setActiveObject(object);
                if (parentRecordId !== activeParentRecordId) setActiveParentRecordId(parseInt(parentRecordId));
                if (queryId !== activeQueryId) setActiveQueryId(parseInt(queryId));
                if (queryType !== activeQueryType) setActiveQueryType(queryType);
                if (queryName !== activeQueryName) setActiveQueryName(queryName);
                if (reduxApp.selectedModuleName !== activeSelectedModuleName) setActiveSelectedModuleName(reduxApp.selectedModuleName);
                if (props.searchText !== activeSearchText) setActiveSearchText(props.searchText);
                if (!_.isEqual(searchParams, activeSearchParams)) setActiveSearchParams(searchParams);
            }
        } catch (error) {
            console.error("Error in 'listView.js -> **useEffect':" + error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.object, reduxApp, props.searchText, props.isDetailView, props.isLookup, reduxListView.data?.records, reduxListView?.groupByInfo]);

   const callBack = (groupByCond, recordCount) => {
        try {
            let tabinfo = getObjectParam(getActiveTabInfo());
            let groupByFieldName = getStringParam(reduxGroupByInfo.group_by_field);
            dispatch(removeScrollPosition(props.object));
            getListView(groupByFieldName, groupByCond, recordCount);
            if (props.object === constants.WORKFLOW_OBJECT) {
                let groupByField = { object: props.object, groupByCondition: groupByCond, groupByFieldName: tabinfo.groupByFieldName };
                setCookie('groupBy_' + props.object + '_' + tabinfo.groupByFieldName + '_' + reduxApp.me.projectId, JSON.stringify(groupByField));
            }
        } catch (error) {
            console.error("error in 'groupByFieldComponent.js' --> 'callBack()':" + error);
        }
    }

    const getListView = (fieldName, groupByCond, recordCount) => {
        try {
            let filterObj = {};
            let activeTab = getActiveTab();
            activeTab = getObjectParam(activeTab);
            let info = getObjectParam(activeTab.info);
            info.groupByFieldName = fieldName;
            if (isValidParam(groupByCond) && groupByCond !== '') {
                filterObj.group_by_condition = groupByCond;
                info.groupByCondition = groupByCond;
                info.groupByConditionTotalCount = getIntParam(recordCount);
            } else {
                delete info.groupByCondition;
                delete info.groupByConditionTotalCount;
            }
            delete info.startIndex;
            delete info.pageSize;
            delete info.selectedPageNo;
            updateTabInfo(info);
            listViewCallBack(props.object, filterObj, true);
        } catch (error) {
            console.error("Error in 'groupByFieldComponent.js -> getListView()':" + error);
        }
    }

    const getFilterNameWithCount = () => {
        let headerLabel = '';
        let filterAndCount = null;
        let filterName = '';
        let arrGroupByObj = null;
        let groupByCondition = '';
        let totalCount = 0;
        let activeTab = null;
        let tabInfo = null;
        let object = '';
        let isReport = false;
        let filter = null;
        let queryType = '';
        let isShowQueryByFilter = false;
        let isForecast = false;
        let hasPermission = false;

        if (reduxListView.mounted) {
            activeTab = getObjectParam(getActiveTab());
            tabInfo = getObjectParam(activeTab.info);
            object = getStringParam(activeTab.object);
            isReport = getBooleanParam(tabInfo.isReport);
            isForecast = getBooleanParam(tabInfo.isForecast);
            if (!isReport) {
                filter = getObjectParam(tabInfo.filter);
                queryType = getStringParam(filter.type);
            }

            if (
                !isReport &&
                object !== constants.SEARCH_RESULTS &&
                object !== constants.ADVANCE_SEARCH_RESULTS &&
                object !== constants.AUTOMATION_DESIGNER_OBJECT &&
                object !== constants.BROADCAST_CAMPAIGNS_OBJECT &&
                object !== constants.SALES_TEMPLATE_OBJECT &&
                object !== constants.MARKETING_TEMPLATE_OBJECT &&
                object !== constants.AGREEMENT_TEMPLATE_OBJECT &&
                object !== constants.SOLUTIONS_OBJECT &&
                object !== constants.ASSIGNMENT_RULES_OBJECT &&
                object !== constants.WEBHOOKS_OBJECT &&
                object !== constants.COUPONS_OBJECT &&
                object !== constants.UNITS_OBJECT &&
                object !== constants.NEWS_OBJECT &&
                object !== constants.LANDING_PAGES_FROALA_OBJECT &&
                queryType !== 'group' &&
                !isForecast &&
                object !== constants.CALL_SCRIPT_OBJECT &&
                object !== constants.SMS_TEMPLATE_OBJECT &&
                object !== constants.PHONE_NUMBERS_OBJECT && 
                props.suffix !== constants.TASK_LIST
            ) {
                isShowQueryByFilter = true;
            }

            let listViewData = getObjectParam(reduxListView.data);
            if (isReport) {
                filterName = getStringParam(tabInfo.reportLabel);
                groupByCondition = getStringParam(tabInfo.reportFieldValue);
                if (groupByCondition === 'Sent') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.SENT;
                }
                if (groupByCondition === 'Opened' || groupByCondition === 'open') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.OPENED;
                }
                if (groupByCondition === 'Unopened') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.UNOPENED;
                }
                if (groupByCondition === 'Clicked') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.CLICKED;
                }
                if (groupByCondition === 'Bounced' || groupByCondition === 'bounce') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.BOUNCED;
                }
                if (groupByCondition === 'Unsubscribed') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.UNSUBSCRIBED;
                }
                if (groupByCondition === 'Spam') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.SPAM;
                }
                if (groupByCondition === 'Dropped') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.DROPPED;
                }
                if (groupByCondition === 'Bounce') {
                    groupByCondition = getLocalizedStrings().label.CAMPAIGNS.BOUNCE;
                }
                if (filterName === 'Pipeline by Sales Rep' && groupByCondition === '') {
                    groupByCondition = tabInfo.groupByCondition;
                }
            } else {
                if (object === constants.SEARCH_RESULTS) {
                    //"search-results"
                    filterName = 'Found Records';
                } else if (object === constants.ADVANCE_SEARCH_RESULTS) {
                    filterName = 'Found Records';
                } else if (
                    object === constants.SALES_TEMPLATE_OBJECT ||
                    object === constants.MARKETING_TEMPLATE_OBJECT ||
                    object === constants.WEB_FORMS ||
                    object === constants.SMS_TEMPLATE_OBJECT ||
                    object === constants.CALL_SCRIPT_OBJECT ||
                    object === constants.AGREEMENT_TEMPLATE_OBJECT
                ) {
                    let tempObject = object;
                    // if(object === constants.CALL_SCRIPT_OBJECT){
                    //     tempObject = constants.PHONE_TEMPLATE_OBJECT;
                    // }
                    tempObject = tempObject.replace('-', ' ');
                    tempObject = tempObject.replace('_', ' ');
                    if (
                        object === constants.SALES_TEMPLATE_OBJECT ||
                        object === constants.SMS_TEMPLATE_OBJECT ||
                        object === constants.AGREEMENT_TEMPLATE_OBJECT
                    ) {
                        filterName = makeTiteleCase(activeTab.label);
                    } else {
                        filterName = makeTiteleCase(tempObject);
                    }
                } else {
                    filterName = getStringParam(activeTab.label);
                }
                //priyanka 15.1.20
                if (isValidParam(tabInfo) && tabInfo.hasOwnProperty('groupByCondition') && tabInfo.hasOwnProperty('groupByConditionTotalCount')) {
                    groupByCondition = tabInfo.groupByCondition;
                } //
                else {
                    groupByCondition = getStringParam(listViewData.group_by_condition);
                }
            }

            if (groupByCondition !== '' && !isReport) {
                let listViewGroupByInfo = getObjectParam(reduxListView.groupByInfo);
                arrGroupByObj = listViewGroupByInfo.hasOwnProperty('group_by') ? getArrayParam(listViewGroupByInfo.group_by) : [];
                arrGroupByObj = arrGroupByObj.filter((f) => {
                    return f.label === groupByCondition;
                });
                if (arrGroupByObj.length > 0) {
                    groupByCondition = arrGroupByObj[0].label;
                    if (groupByCondition === 'All') {
                        groupByCondition = getLocalizedStrings().label.COMMON.ALL;
                    } else if (object === constants.APPOINTMENTS || object === constants.TASKS) {
                        groupByCondition = getLocalizedStrings().label.APPOINTMENTS_TASKS.hasOwnProperty(groupByCondition)
                            ? getLocalizedStrings().label.APPOINTMENTS_TASKS[groupByCondition]
                            : groupByCondition;
                    } else {
                        groupByCondition = getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(groupByCondition)
                            ? getLocalizedStrings().label.CAMPAIGNS[groupByCondition]
                            : groupByCondition;
                    }

                    totalCount = arrGroupByObj[0].record_count;

                }
            } else {
                totalCount = listViewData.hasOwnProperty('total_count') ? getIntParam(listViewData.total_count) : 0;

            }
            if (props.object === constants.WORKFLOW_OBJECT || props.object === constants.ASSIGNMENT_RULES_OBJECT || object === constants.WEBHOOKS_OBJECT) {
                groupByCondition = getStringParam(listViewData.group_by_condition);
                filterName = getStringParam(listViewData.query_name);
                let objectList = reduxApp.objectList;
                if (isValidParam(objectList) && getArrayParam(objectList).length > 0) {
                    objectList = getArrayParam(objectList).filter((f) => {
                        let temp;
                        if (f.name === groupByCondition.toLowerCase()) {
                            temp = true;
                        }
                        return temp;
                    });
                    if (objectList.length > 0) {
                        groupByCondition = objectList[0].label;
                    }
                }
            }
            if (object === constants.WEB_FORMS && filterName === 'Web Forms') {
                filterName = getLocalizedStrings().label.MODULE_ITEMS['Web Forms'];
            }
            if (object === constants.MARKETING_TEMPLATE_OBJECT && filterName === 'Marketing Templates') {
                filterName = getLocalizedStrings().label.MODULE_ITEMS['Marketing Templates'];
            }
            if (object === constants.BROADCAST_CAMPAIGNS_OBJECT && filterName === 'All Broadcasts') {
                filterName = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.All_BROADCASTS;
            }
            if (object === constants.BROADCAST_CAMPAIGNS_OBJECT && filterName === 'All AB Campaigns') {
                filterName = getLocalizedStrings().label.AB_CAMPAIGNS.ALL_AB_CAMPAIGNS;
            }
            if (object === constants.AUTOMATION_DESIGNER_OBJECT && filterName === 'All Automated Campaigns') {
                filterName = getLocalizedStrings().label.AUTOMATION_DESIGNER.ALL_AUTOMATED_CAMPAIGNS;
            }
            if (object === constants.AUTOMATION_DESIGNER_OBJECT && filterName === 'All Touch Campaigns') {
                filterName = getLocalizedStrings().label.TOUCH_CAMPAIGNS.ALL_TOUCH_CAMPAIGNS;
            }
            if (object === constants.BROADCAST_CAMPAIGNS_OBJECT && filterName === 'Broadcasts') {
                filterName = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.BROADCASTS;
            }
            if (object === constants.BROADCAST_CAMPAIGNS_OBJECT && filterName === 'A/B Tests') {
                filterName = getLocalizedStrings().label.AB_CAMPAIGNS.AB_TESTS;
            }
            if (object === constants.APPOINTMENTS && filterName === 'All Appointments') {
                filterName = getLocalizedStrings().label.HEADER.ALL_APPOINMENTS;
            }
            if (object === constants.APPOINTMENTS && filterName === 'My Appointments') {
                filterName = getLocalizedStrings().label.HEADER.MY_APPOINMENTS;
            }
            if (object === constants.APPOINTMENTS && filterName === 'My Completed Appointments') {
                filterName = getLocalizedStrings().label.HEADER.MY_COMPLETED_APPOINMENTS;
            }
            if (object === constants.APPOINTMENTS && filterName === 'My Missed Appointments') {
                filterName = getLocalizedStrings().label.HEADER.MY_MISSED_APPOINMENTS;
            }
            if (object === constants.APPOINTMENTS && filterName === 'Todays Appointments') {
                filterName = getLocalizedStrings().label.HEADER.TODAYS_APPOINMENTS;
            }
            if (object === constants.TASKS && filterName === 'All Tasks') {
                filterName = getLocalizedStrings().label.HEADER.ALL_TASKS;
                if (
                    tabInfo.hasOwnProperty('callFrom') &&
                    tabInfo.callFrom === 'task_listview' &&
                    tabInfo.hasOwnProperty('userName') &&
                    tabInfo.userName !== null
                ) {
                    filterName = tabInfo.userName + "'s Tasks";
                }
            }
            if (object === constants.TASKS && filterName === 'My Completed Tasks') {
                filterName = getLocalizedStrings().label.HEADER.MY_COMPLETED_TASKS;
            }
            if (object === constants.TASKS && filterName === 'My Future Tasks') {
                filterName = getLocalizedStrings().label.HEADER.MY_FUTURE_TASKS;
            }
            if (object === constants.TASKS && filterName === 'My Pending Tasks') {
                filterName = getLocalizedStrings().label.HEADER.MY_PENDING_TASKS;
            }
            if (object === constants.TASKS && filterName === 'My Todays Tasks') {
                filterName = getLocalizedStrings().label.HEADER.MY_TODAYS_TASKS;
            }
            // if (object === constants.CONTACTS_OBJECT && filterName === 'Contacts without Account') {
            //     filterName = getLocalizedStrings().label.HEADER.CONTACTS_WITHOUT_ACCOUNT;
            // }
            // if (object === constants.CONTACTS_OBJECT && filterName === 'Contacts without Groups') {
            //     filterName = getLocalizedStrings().label.HEADER.CONTACTS_WITHOUT_GROUPS;
            // }
            // if (object === constants.CONTACTS_OBJECT && filterName === 'Contacts') {
            //     filterName = getLocalizedStrings().label.HEADER.CONTACTS;
            // }
            if (object === constants.AUTOMATION_DESIGNER_OBJECT && filterName === 'Automated Campaigns') {
                filterName = getLocalizedStrings().label.MODULE_ITEMS['Automated Campaigns'];
            }
            // if (object === constants.CONTACTS_OBJECT && filterName === 'My Contacts') {
            //     filterName = getLocalizedStrings().label.HEADER.MY_CONTACTS;
            // }
            if (object === constants.CONTACTS_OBJECT) {
                filterName = getLocalizedStrings().label.FILTER.hasOwnProperty(filterName)
                    ? getLocalizedStrings().label.FILTER[filterName]
                    : filterName;
            }
            if (filterName === 'All records') {
                filterName = getLocalizedStrings().label.FILTER['All records'];
            }
            if (object === constants.SALES_TEMPLATE_OBJECT && filterName === 'Sales Templates') {
                filterName = getLocalizedStrings().label.SALES_TEMPLATE.SALES_TEMPLATES;
            }
            if (object === constants.AGREEMENT_TEMPLATE_OBJECT && filterName === 'Agreement Templates') {
                filterName = getLocalizedStrings().label.AGREEMENT_TEMPLATE.AGREEMENT_TEMPLATES;
            }
            if (object === constants.OPPORTUNITIES_OBJECT) {
                if (filterName === 'Added this Month') {
                    filterName = getLocalizedStrings().label.FILTER['Added this Month'];
                }
                if (filterName === 'Added this Week') {
                    filterName = getLocalizedStrings().label.FILTER['Added this Week'];
                }
                if (filterName === 'My Opportunities') {
                    filterName = getLocalizedStrings().label.FILTER['My Opportunities'];
                }
                if (filterName === 'Opportunities') {
                    filterName = getLocalizedStrings().label.FILTER['Opportunities'];
                }
            }
            if (object === constants.ACCOUNTS_OBJECT) {
                filterName = getLocalizedStrings().label.FILTER.hasOwnProperty(filterName)
                    ? getLocalizedStrings().label.FILTER[filterName]
                    : filterName;
            }
            if (object === constants.CASES_OBJECT && filterName === 'Cases' && reduxApp.selectedModuleName !== constants.MODULE_HUB) {
                filterName = getLocalizedStrings().label.FILTER['Cases'];
            }
            if (object === constants.CASES_OBJECT && filterName === 'Cases' && reduxApp.selectedModuleName === constants.MODULE_HUB) {
                let listData = getObjectParam(reduxListView?.data);
                filterName = listData?.query_name;
            }
            if (object === constants.PROJECTS_OBJECT && filterName === 'Projects') {
                filterName = getLocalizedStrings().label.FILTER['Projects'];
            }
            if (object === constants.ISSUES_OBJECT && filterName === 'Issues') {
                filterName = getLocalizedStrings().label.FILTER['Issues'];
            }
            if (object === constants.ASSIGNMENT_RULES) {
                filterName = getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULES;
            }
            if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
                filterName = getLocalizedStrings().label.LANDING_PAGE.LANDING_PAGES;
            }
            if (object === constants.PHONE_NUMBERS_OBJECT) {
                filterName = getLocalizedStrings().label.COMMON.ALL_PHONE_NO;
            }
            if (object === constants.LMS_USERS_OBJECT) {
                filterName = getLocalizedStrings().label.SETTINGS.LMS_USERS;
            }

            if (object === constants.WEBHOOKS_OBJECT) {
                filterName = getLocalizedStrings().label.SETTINGS.WEBHOOKS_OBJECT;
            }

            if (object === constants.TEMPLATE_CATEGORIES) {
                filterName = getLocalizedStrings().label.SETTINGS.TEMPLATE_CATEGORIES;
            }

            if (
                object === constants.SOURCE_OBJECT ||
                object === constants.RELATIONS_OBJECT ||
                object === constants.CAMPAIGN_TYPES_OBJECT ||
                object === constants.NOTE_TYPES_OBJECT ||
                object === constants.APPOINTMENT_TYPES_OBJECT ||
                object === constants.LIBRARY_OBJECT ||
                object === constants.USERS_OBJECT ||
                object === constants.SECURITY_LEVEL_OBJECT ||
                object === constants.EMAIL_INTEGRATION_OBJECT ||
                object === constants.WORKFLOW_OBJECT ||
                object === constants.RECYCLE_BIN_OBJECT ||
                object === constants.ASSIGNMENT_RULES_OBJECT ||
                object === constants.WEBHOOKS_OBJECT ||
                object === constants.LOCATION_OBJECT ||
                object === constants.TASKSLIST_OBJECT ||
                object === constants.AUDIT_QUESTIONS_OBJECT ||
                object === constants.ROLL_UP_OBJECT ||
                object === constants.AUDIT_CHECKLISTS_OBJECT
            ) {
                let suffixName = '';
                if (
                    object === constants.SOURCE_OBJECT ||
                    object === constants.RELATIONS_OBJECT ||
                    object === constants.LOCATION_OBJECT ||
                    object === constants.TASKSLIST_OBJECT ||
                    object === constants.CAMPAIGN_TYPES_OBJECT
                ) {
                    suffixName = 's';
                }
                if(object === constants.AUDIT_CHECKLISTS_OBJECT){
                    filterName = getLocalizedStrings().label.SETTINGS.AUDIT_CHECKLISTS;
                }else{
                    filterName = getLocalizedStrings().label.SETTINGS[object.toUpperCase()] + suffixName;
                }
            }
            if (isReport) {
                //****For Sales Report******
                if (filterName === 'Activities By Type') {
                    filterName = getLocalizedStrings().label.REPORT.ACTIVITIES_BY_TYPE;
                }
                if (filterName === 'Activities By Sales Rep') {
                    filterName = getLocalizedStrings().label.REPORT.ACTIVITIES_BY_SALES_REP;
                }
                if (filterName === 'All Conversions By Status') {
                    filterName = getLocalizedStrings().label.REPORT.ALL_CONVERSIONS_BY_STATUS;
                }
                if (filterName === 'Customers By Source') {
                    filterName = getLocalizedStrings().label.REPORT.CUSTOMERS_BY_SOURCE;
                }
                if (filterName === 'Pipeline Report') {
                    filterName = getLocalizedStrings().label.REPORT['Pipeline Report'];
                }
                if (filterName === 'Pipeline by Sales Rep') {
                    filterName = getLocalizedStrings().label.REPORT.DEALS_BY_SALES_PER;
                }
                if (filterName === 'Conversion By Sales Rep') {
                    filterName = getLocalizedStrings().label.REPORT.CONVERSION_BY_SALES_REP;
                }
                if (filterName === 'Deals by Stages') {
                    filterName = getLocalizedStrings().label.REPORT.DEALS_BY_STAGES;
                }
                if (filterName === 'Total Calls By Sales Rep') {
                    filterName = getLocalizedStrings().label.REPORT.TOTAL_CALLS_BY_SALES_REP;
                }
                if (filterName === 'Total Call Duration By Sales Rep') {
                    filterName = getLocalizedStrings().label.REPORT.TOTAL_CALL_DURATION_BY_SALES_REP;
                }
                if (filterName === 'Activity Matrix') {
                    filterName = getLocalizedStrings().label.REPORT.ACTIVITY_MATRIX;
                }
                if (filterName === 'Matrix By Status') {
                    filterName = getLocalizedStrings().label.REPORT.MATRIX_BY_STATUS;
                }
                if (filterName === 'Matrix By Source') {
                    filterName = getLocalizedStrings().label.REPORT.MATRIX_BY_SOURCE;
                }

                //***** For Service Report *****/
                if (filterName === 'Cases by Status') {
                    filterName = getLocalizedStrings().label.REPORT.CASES_BY_STATUS;
                }
                if (filterName === 'Cases by Priority') {
                    filterName = getLocalizedStrings().label.REPORT.CASES_BY_PRIORITY;
                }
                if (filterName === 'Projects by Status') {
                    filterName = getLocalizedStrings().label.REPORT.PROJECTS_BY_STATUS;
                }
                if (filterName === 'Projects by Type') {
                    filterName = getLocalizedStrings().label.REPORT.PROJECTS_BY_TYPE;
                }
                if (filterName === 'Issues by Status') {
                    filterName = getLocalizedStrings().label.REPORT.ISSUES_BY_STATUS;
                }
                if (filterName === 'Issues by Priority') {
                    filterName = getLocalizedStrings().label.REPORT.ISSUES_BY_PRIORITY;
                }
                if (filterName === 'Support Matrix') {
                    filterName = getLocalizedStrings().label.REPORT.SUPPORT_MATRIX;
                }
                if (filterName === 'Customers by Cases') {
                    filterName = getLocalizedStrings().label.REPORT.CUSTOMER_BY_CASES;
                }
                if (filterName === 'Project Matrix') {
                    filterName = getLocalizedStrings().label.REPORT.PROJECT_MATRIX;
                }
                if (filterName === 'Issue Matrix') {
                    filterName = getLocalizedStrings().label.REPORT.ISSUE_MATRIX;
                }
            }

            if (activeTab.object === constants.ROYALTY_OBJECT) {
                if (filterName === 'Royalties') {
                    filterName = '';
                    groupByCondition = tabInfo.total_invoiced;
                }
            }

            // if (activeTab.object === constants.COMPLIANCE_OBJECT) {
            // 	if (filterName === "Compliance") {
            // 		filterName = "";
            // 		groupByCondition = tabInfo.franchisee;
            // 	}
            // }
            headerLabel = getFilterNameCountsElement(filterName, groupByCondition);
            if (activeTab.object === constants.TASKSLIST_OBJECT) {
                headerLabel = 'Tasks';
            }
            hasPermission = hasAccessPermission(null, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_FILTERS);
            try {
                filterAndCount = (
                    <div
                        className="hdrfiltr"
                        style={
                            {
                                display: 'flex',
                                fontSize: '24px',
                                // border: '1px solid black',
                                alignItems: 'center',
                            }
                            // { border: '1px solid black', float: 'left', fontSize: '24px', position: 'relative', marginTop: '0px', paddingLeft: '0px', height: 31, width: '100%' }
                        }>
                        {headerLabel}
                        <div
                            style={
                                {
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                    marginLeft: '2px',
                                    marginBottom: '-4px',
                                }
                                // { fontSize: '14px', cursor: 'pointer', display: 'inline-block', marginLeft: '2px', marginTop: 10 }
                            }>
                            {' (' + totalCount + ')'}
                        </div>
                        <div
                            style={
                                {}
                                // { marginTop: 6, float: "right" }
                            }>
                            {isShowQueryByFilter && <ListViewFilterDropDown object={object} queryName={activeTab.label} />}
                        </div>
                        <div>
                            {hasPermission && isValidParam(filter) && filter.type === 'filter' &&
                                props.object !== constants.TASKS && (
                                    <div
                                        style={
                                            {}
                                            // { display: 'inline-block', verticalAlign: 'middle' }
                                        }>
                                        <span style={{ cursor: 'pointer', color: '#717171' }} onClick={() => editFilter(filter)}>
                                            <Icon key={filter.id} style={{ fontSize: '0.8em' }} title={getLocalizedStrings().label.COMMON.EDIT}>
                                                edit
                                            </Icon>
                                        </span>
                                    </div>
                                )}
                        </div>
                    </div>
                );
            } catch (error) {
                console.error("Error in 'listView.js -> getFilterNameWithCount()':" + error);
            }
        }
        return filterAndCount;
    };

    function getFilterNameCountsElement(filterName, groupByCondition) {
        let elements = [];
        try {
            filterName = getStringParam(filterName);
            if (props.object === constants.FIND_DUPLICATE_OBJECT) {
                filterName = getLocalizedStrings().label.FIND_DUPLICATES.FIND_DUPLICATES;
            }
            groupByCondition = getStringParam(groupByCondition);
            if (filterName !== '') {
                elements.push(
                    <div
                        title={filterName}
                        key={filterName}
                        style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: 930,
                            float: 'left',
                        }}>
                        {filterName}
                    </div>,
                );

                elements.push(
                    <div title={groupByCondition} style={{ float: 'left' }} key={filterName + groupByCondition}>
                        &nbsp;{'- ' + groupByCondition}
                    </div>,
                );
            } else {
                elements.push(
                    <div title={groupByCondition} key={groupByCondition} style={{ float: 'left' }}>
                        {groupByCondition}
                    </div>,
                );
            }
        } catch (error) {
            console.error("Error in 'listView.js -> getFilterNameCountsElement()':" + error);
        }
        return elements;
    }

    function setListViewHeader() {
        let activeTabInfo = getObjectParam(getActiveTabInfo());
        let filterNameWithCount = getFilterNameWithCount();
        let show = false;
        let currentView = null;
        let showAddIcon = true;
        let showHeader = true;
        if (
            props.object === constants.BROADCAST_CAMPAIGNS_OBJECT ||
            props.object === constants.AB_CAMPAIGNS_OBJECT ||
            ((props.object === constants.AUTOMATION_DESIGNER_OBJECT || props.object === constants.TOUCH_CAMPAIGNS_OBJECT) &&
                reduxApp.me.is_show_touch) ||
            props.object === constants.ACCOUNTS_OBJECT ||
            props.object === constants.PARTNERS_OBJECT ||
            props.object === constants.CONTACTS_OBJECT ||
            props.object === constants.OPPORTUNITIES_OBJECT ||
            (props.object === constants.CASES_OBJECT && reduxApp.selectedModuleName !== constants.MODULE_HUB) ||
            props.object === constants.PROJECTS_OBJECT ||
            props.object === constants.ISSUES_OBJECT ||
            props.object === constants.CUSTOM_TABLE1_OBJECT ||
            props.object === constants.CUSTOM_TABLE2_OBJECT ||
            props.object === constants.CUSTOM_TABLE3_OBJECT ||
            props.object === constants.CUSTOM_TABLE4_OBJECT  || props.object === constants.PARTNERS_OBJECT
        ) {
            show = true;
            currentView = 'list';
        }

        let kanbanObject = {};
        if (!isGlobalSearch) {
            kanbanObject.currentView = currentView;
            kanbanObject.show = show;
        }

        if (
            props.object === constants.APPOINTMENTS ||
            props.object === constants.TASKS ||
            props.object === constants.UNITS_OBJECT ||
            props.object === constants.FIND_DUPLICATE_OBJECT
        ) {
            showAddIcon = false;
        }

        if (props.object === constants.NEWS_OBJECT) {
            let isAdmin = reduxApp.me.t_name;
            if (isAdmin !== 'Admin') {
                showAddIcon = false;
            }
        }

        if (props.object === constants.DOWNLOAD_LEADS_OBJECT) {
            showAddIcon = false;
            showHeader = false;
        }
        if (activeTabInfo.object == constants.AB_CAMPAIGNS_OBJECT) {
            dispatch(setHeader(activeTabInfo.object, filterNameWithCount, kanbanObject, showAddIcon, showHeader));
        } else {
            dispatch(setHeader(props.object, filterNameWithCount, kanbanObject, showAddIcon, showHeader));
        }

        //listView.isSetHeader = false;
    }

    function getSelectedRecord(selectedRows) {
        let isLookup = getBooleanParam(props.isLookup);
        let isDetailView = getBooleanParam(props.isDetailView);
        if (isLookup) {
            props.getLookupSelectedRecord(selectedRows);
        } else {
            dispatch(getListViewSelectedRecord(selectedRows, isDetailView, getBooleanParam(props.suffix === constants.TASK_LIST)));
        }
    }

    function getGroupByShowHide() {
        let tab = getActiveTab();
        let object = props.object;
        if (isValidParam(tab)) {
            let info = getObjectParam(tab.info);
            if (info.hasOwnProperty('isShowGroupBy')) {
                if (
                    object === constants.SOURCE_OBJECT ||
                    object === constants.RELATIONS_OBJECT ||
                    object === constants.CAMPAIGN_TYPES_OBJECT ||
                    object === constants.NOTE_TYPES_OBJECT ||
                    object === constants.APPOINTMENT_TYPES_OBJECT ||
                    object === constants.LIBRARY_OBJECT ||
                    object === constants.DOWNLOAD_LEADS_OBJECT ||
                    object === constants.LOCATION_OBJECT ||
                    object === constants.TASKSLIST_OBJECT ||
                    object === constants.ROLL_UP_OBJECT
                ) {
                    reduxListView.isShowGroupBy = false;
                } 
                // else if (object === constants.NEWS_OBJECT && reduxApp.selectedModuleName !== constants.MODULE_HUB) {
                //     reduxListView.isShowGroupBy = !(
                //         reduxListView.hasOwnProperty('groupByInfo') &&
                //         reduxListView.groupByInfo.hasOwnProperty('total_count') &&
                //         reduxListView.groupByInfo.total_count === 0
                //     );
                // } 
                else {
                    reduxListView.isShowGroupBy = getBooleanParam(info.isShowGroupBy);
                }
            } else if (
                object === constants.ASSIGNMENT_RULES_OBJECT ||
                object === constants.WORKFLOW_OBJECT ||
                object === constants.BROADCAST_CAMPAIGNS_OBJECT ||
                object === constants.AUTOMATION_DESIGNER_OBJECT ||
                object === constants.AB_CAMPAIGNS_OBJECT ||
                object === constants.ACCOUNTS_OBJECT ||
                object === constants.CONTACTS_OBJECT ||
                object === constants.OPPORTUNITIES_OBJECT ||
                object === constants.CUSTOM_TABLE1_OBJECT ||
                object === constants.CUSTOM_TABLE2_OBJECT ||
                object === constants.CUSTOM_TABLE3_OBJECT ||
                object === constants.CUSTOM_TABLE4_OBJECT ||
                object === constants.SMS_TEMPLATE_OBJECT ||
                object === constants.SALES_TEMPLATE_OBJECT ||
                object === constants.MARKETING_TEMPLATE_OBJECT ||
                object === constants.COUPONS_OBJECT ||
                object === constants.WEB_FORMS ||
                object === constants.CASES_OBJECT ||
                object === constants.SOLUTIONS_OBJECT ||
                object === constants.PROJECTS_OBJECT ||
                object === constants.ISSUES_OBJECT ||
                object === constants.UNITS_OBJECT ||
                object === constants.SALES_LEADS_OBJECT ||
                object === constants.NEWS_OBJECT ||
                object === constants.TASKS ||
                object === constants.APPOINTMENTS ||
                object === constants.CALL_SCRIPT_OBJECT ||
                object === constants.AGREEMENT_TEMPLATE_OBJECT ||
                object === constants.SECURITY_LEVEL_OBJECT ||
                object === constants.AUDIT_ANSWERS_OBJECT ||
                object === constants.AUDITS_OBJECT ||
                object === constants.COMPLIANCE_OBJECT ||
                object === constants.REPORTED_SALES_OBJECT ||
                object === constants.ROYALTY_PLANS_OBJECT ||
                object === constants.ROYALTY_RUN_OBJECT ||
                object === constants.LMS_USERS_OBJECT ||
                object === constants.PHONE_NUMBERS_OBJECT ||
                object === constants.WEBHOOKS_OBJECT ||
                object === constants.TEMPLATE_CATEGORIES ||
                object === constants.PARTNERS_OBJECT
            ) {
                reduxListView.isShowGroupBy = !(
                    reduxListView.hasOwnProperty('groupByInfo') &&
                    reduxListView.groupByInfo.hasOwnProperty('total_count') &&
                    reduxListView.groupByInfo.total_count === 0
                );
            } else {
                reduxListView.isShowGroupBy = false;
            }
            return getBooleanParam(reduxListView.isShowGroupBy);
        }
    }

    function getGroupByDraggable() {
        let tab = getActiveTab();
        let object = props.object;
        if (isValidParam(tab)) {
            let info = getObjectParam(tab.info);
            if (info.hasOwnProperty('isGroupByDraggable')) {
                if (object === constants.AUDIT_QUESTIONS_OBJECT ) {
                    reduxListView.isGroupByDraggable = !(
                        reduxListView.hasOwnProperty('groupByInfo') &&
                        reduxListView.groupByInfo.hasOwnProperty('total_count') &&
                        reduxListView.groupByInfo.total_count === 0
                    );
                } else {
                    reduxListView.isGroupByDraggable = getBooleanParam(info.isGroupByDraggable);
                }
            } else if (object === constants.AUDIT_QUESTIONS_OBJECT ) {
                reduxListView.isGroupByDraggable = !(
                    reduxListView.hasOwnProperty('groupByInfo') &&
                    reduxListView.groupByInfo.hasOwnProperty('total_count') &&
                    reduxListView.groupByInfo.total_count === 0
                );
            } else {
                reduxListView.isGroupByDraggable = false;
            }
            return getBooleanParam(reduxListView.isGroupByDraggable);
        }
    }

    function editFilter(filter) {
        if (isValidParam(filter)) {
            let hasPermission = hasAccessPermission(null, constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_MANAGE_FILTERS);
            if (hasPermission) {
                try {
                    const label = filter.name;
                    const data = {};
                    data.object = 'filter';
                    data.id = filter.id;
                    data.table = props.object.toLowerCase();
                    data.mode = 'edit';
                    data.callFromListView = true;
                    dispatch(getAppDrawer(true, label, constants.FILTER, data, null));
                } catch (error) {
                    console.error("Error in 'listView.js -> editFilter()':" + error);
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
            }
        }
    }

    function getLeadstoreSearchFldShowHide() {
        return getBooleanParam(reduxListView.isShowLeadstoreSearchFld);
    }

    function setTabName() {
        try {
            let tab = getActiveTab();
            let listData = getObjectParam(reduxListView.data);
            let { info = {} } = tab;
            if (
                (tab.type === TYPE_LIST_VIEW || tab.type === TYPE_COLUMN_VIEW) &&
                listData.query_name &&
                Object.keys(info).length === 0 &&
                tab.label !== listData.query_name &&
                listData.query_type !== constants.SEARCH_QUERY_TYPE_GLOBAL &&
                listData.query_type !== constants.SEARCH_QUERY_TYPE_ADVANCE
            ) {
                tab.label = listData.query_name;
                let { filter = {} } = info;
                filter.id = listData.query_id;
                filter.name = listData.query_name;
                filter.type = listData.query_type;
                tab.info = { filter, object: props.object };
                updateActiveTab(tab);
                saveRecentFilter(props.object, filter);
            }
        } catch (error) {
            console.error("Error in 'ListView.js -> setTabName()':" + error);
        }
    }

    // ** render **
    let activeTabInfo = getActiveTabInfo();
    let isDetailView = getBooleanParam(props.isDetailView);
    let isSetHeader = getBooleanParam(reduxListView.isSetHeader);
    let isLookup = getBooleanParam(props.isLookup);
    let isReport = getBooleanParam(activeTabInfo.isReport);
    let isDraggable = getBooleanParam(props.isDraggable);
    let isAssignRule = getBooleanParam(activeTabInfo.isAssignRule);
    if (isValidParam(props.isShowHeader) && !props.isShowHeader) {
        isSetHeader = false;
    }
    let isGroupByShow = isReport ? false : getGroupByShowHide();
    let isGroupByDraggable = getGroupByDraggable();
    let isLeadstoreSearchFld = false;
    if (props.object === constants.DOWNLOAD_LEADS_OBJECT) {
        isLeadstoreSearchFld = getLeadstoreSearchFldShowHide();
    }
    let listViewData;
    let totalRecordCount;
    let leftPadding = '0px';
    if (!isDetailView) {
        leftPadding = '10px';

        let reportType = isReport ? getStringParam(activeTabInfo.reportType) : '';
        if (!isReport || (reportType !== constants.AUTO_RESPONDER_REPORT_TYPE && reportType !== constants.BROADCAST_REPORT_TYPE)) {
            document.documentElement.style.overflow = 'hidden';
        }
    }
    let mTop = '0px';
    if (props.object === constants.LINE_ITEMS_OBJECT || props.object === constants.ROYALTY_OBJECT) {
        mTop = '40px';
    } else if (props.object === constants.AUDIT_QUESTIONS_OBJECT && props.parentObject === undefined) {
        mTop = '-28px'
    }
    if (isLookup) {
        listViewData = getObjectParam(props.data);
    } else {
        listViewData = getObjectParam(reduxListView);
    }
    let isMounted = false;
    if (props.object == constants.UNITS_OBJECT) {
        isMounted = getBooleanParam(listViewData.mounted && listViewData.data.object == props.object);
    } else {
        isMounted = getBooleanParam(listViewData.mounted);
    }
    const isSearch = getBooleanParam(props.isSearch);
    if (isMounted && isSetHeader && !isDetailView && !isLookup && props.object !== 'tasks') {
        setListViewHeader();
        setTabName();
    } if (isSetHeader && !isDetailView && !isLookup && props.object == 'tasks') {
        setListViewHeader();
        setTabName();
    }
    totalRecordCount = isMounted ? getIntParam(listViewData.data.total_count) : 0;
    if (isMounted && props.object !== constants.DOWNLOAD_LEADS_OBJECT) {
        if (!isDetailView && !isLookup && !isReport && !isAssignRule) {
            totalRecordCount = getIntParam(listViewData.groupByInfo.total_count);
            listViewData.data.total_count = totalRecordCount;
        } else if (isDetailView && props.object === constants.AUDIT_ANSWERS_OBJECT && props.suffix !== 'auditList') {
            totalRecordCount = getIntParam(listViewData.groupByInfo.total_count);
            listViewData.data.total_count = totalRecordCount;
            isDetailView = false;
        } else if (props.object === constants.AUDITS_OBJECT && props.suffix === 'auditList') {
            totalRecordCount = getIntParam(listViewData.groupByInfo.total_count);
            listViewData.data.total_count = totalRecordCount;
            isDetailView = false;
        }
    }

    let contentHeight = document.body.clientHeight - 250;

    let contentWidth = '88%';
    if (isLookup || isDetailView) {
        contentWidth = '100%';
    } else if (!isGroupByShow && !isLeadstoreSearchFld) {
        contentWidth = '100%';
    }

    let listViewWidth = '98%';
    if (isSearch) {
        listViewWidth = '100%';
    }

    let tempObject = props.object;
    if (activeTabInfo.object == constants.AB_CAMPAIGNS_OBJECT) {
        tempObject = activeTabInfo.object;
    }
    if (
        props.object === constants.FIND_DUPLICATE_OBJECT &&
        reduxTab.hasOwnProperty('tabs') &&
        reduxTab.tabs.length > 0 &&
        reduxTab.tabs[0].hasOwnProperty('info') &&
        reduxTab.tabs[0].info.is_duplicate_object
    ) {
        tempObject = getStringParam(reduxTab.tabs[0].info.input_param.object);
    }

    let divPadding = '0px';
    if (props.object === constants.DOWNLOAD_LEADS_OBJECT) {
        divPadding = '16px';
    }

    let progressDivWidth = '100%';
    if (isGroupByShow && !isLookup && !isDetailView) {
        progressDivWidth = '88%';
    } else if (isLeadstoreSearchFld && props.object === constants.DOWNLOAD_LEADS_OBJECT) {
        progressDivWidth = '86%';
    }
    let loaderLeftPadding = '55%';
    if (!isGroupByShow && !isLeadstoreSearchFld) {
        loaderLeftPadding = '47%';
    }
    if (isGroupByShow) {
        loaderLeftPadding = '55%';
    }
    let top = (contentHeight - 10) / 2;
    return (
        <div
            id={props.object}
            className="pageBg clearfix"
            key={props.object}
            ref={listRef}
            style={{
                padding: divPadding,
                width: listViewWidth,
                marginBottom: '0px',
                display: 'flex',
            }}>
            {isGroupByShow && !isGroupByDraggable && !isLookup && !isDetailView && totalRecordCount > 0 && (
                <div
                    style={{
                        float: 'left',
                        width: '12%',
                        overflowY: 'auto',
                        height: contentHeight + 46,
                    }}>
                    <GroupByFieldComponent
                        isSearch={isSearch}
                        object={tempObject}
                        suffix={props.suffix}
                        listViewCallBack={(object, params, isRefresh) => listViewCallBack(object, params, isRefresh)}
                    />
                </div>
            )}
            {isMounted && isGroupByShow && isGroupByDraggable && (
                <div style={{ float: 'left', width: '14%', height: contentHeight }}>
                    <SFListBox listViewCallBack={(object, params, isRefresh) => listViewCallBack(object, params, isRefresh)} object={tempObject} />
                </div>
            )}
            {isMounted && (
                <div
                    style={{
                        float: 'right',
                        width: contentWidth,
                        paddingLeft: props.parentObject === constants.AUDITS_OBJECT ? '15px' : '',

                    }}>
                    <div
                        style={{
                            height: 'auto',
                            width: '100%',
                            marginBottom: (props.parentObject === 'jobs' && props.object === 'lineitems') ? '40px' : '12px',
                        }}
                        id="list-view-hdr">
                        <ListViewHeader
                            parentObject={props.parentObject}
                            object={props.object}
                            listView={listViewData}
                            isDetailView={props.isDetailView}
                            detailViewParams={detailViewParams}
                            isLookup={isLookup}
                            listViewCallBack={(object, params, isRefresh, selectedFilterValue) => listViewCallBack(object, params, isRefresh, selectedFilterValue)}
                            isSearch={props.isSearch}
                            childObject={tempObject}
                            propsData={props}
                            suffix={props.suffix}
                            setSearchValue={setSearchValue}
                        />
                    </div>
                    {totalRecordCount > 0 ? (
                        <div
                            style={{
                                margin: '0px',
                                marginTop: mTop,
                                paddingLeft: leftPadding,
                                width: '100%',
                            }}>
                            {!isDraggable ? (
                                <DataTable
                                    object={tempObject}
                                    listView={listViewData}
                                    isDetailView={props.isDetailView}
                                    isLookup={isLookup}
                                    isExpandedTaskList={getBooleanParam(props.suffix === constants.TASK_LIST)}
                                    listViewCallBack={(object, params, isRefresh) => listViewCallBack(object, params, isRefresh)}
                                    getSelectedRecord={(sr) => getSelectedRecord(sr)}
                                    parentObject={props.parentObject}
                                    parentRecordId={props.parentRecordId}
                                    isDuplicate={props.isDuplicate}
                                />
                            ) : null}
                            {isDraggable && (
                                <DraggableDataTable
                                    object={tempObject}
                                    listView={listViewData}
                                    isDetailView={props.isDetailView}
                                    isLookup={isLookup}
                                    listViewCallBack={(object, params, isRefresh) => listViewCallBack(object, params, isRefresh)}
                                    getSelectedRecord={(sr) => getSelectedRecord(sr)}
                                    parentObject={props.parentObject}
                                    parentRecordId={props.parentRecordId}
                                />
                            )}
                        </div>
                    ) : props.object === constants.ASSIGNMENT_RULES ? (
                        <NoAssignmentRule />
                    ) : (
                        Object.keys(props?.data?.data || {}).length === 0 && Object.keys(props).some(key => key === 'isFindAndLink') ? null : <NoRecord
                            object={props.object}
                            parentObject={props.parentObject}
                            isDuplicate={props.isDuplicate}
                            view={constants.LIST_VIEW}
                            noRecordFilterText={noRecordFilterText}
                            childObject={props.childObject}
                            totalRecordCount={totalRecordCount}
                            suffix={props.suffix}
                        />
                    )}
                </div>
            )}

            {!isMounted && (
                <div style={{ width: progressDivWidth, height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: loaderLeftPadding }}>
                        <div
                            style={{
                                ...styles.assetLoaderContainer,
                                height: 55,
                                width: 55,
                                padding: 6,
                            }}>
                            <ShowCircularProgress size={35} style={{ marginTop: '4', marginLeft: '4' }} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

ListView.propTypes = {
    object: PropTypes.string.isRequired,
    parentObject: PropTypes.string,
    parentRecordId: PropTypes.number,
    isDetailView: PropTypes.bool,
    isSearch: PropTypes.bool,
    isShowHeader: PropTypes.bool,
    isChildIdListView: PropTypes.bool,
    isLookup: PropTypes.bool,
    data: PropTypes.object,
};

export default ListView;
