import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import ShowCircularProgress from '../components/circularProgress';
import { getConvert, saveBatchConvert, getWorkFlowStage, applyWorkFlowBatch } from '../../../services/actions/batchActions';
import { styles } from '../../../services/constants/styles';
import { constants } from '../../../services/constants/constants';
import map from 'lodash/map';
import * as sfDialogs from '../components/sfDialogs';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import { isValidParam, getObjectParam, getStringParam, getArrayParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { refreshListView, setRequiredFieldData } from '../../../services/actions/listViewAcions';
import { refreshDetailViewData, refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import { getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { getObjectLabelByObject } from '../../../services/helper/common';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { updateBatchConvertData } from '../../../services/actions/convertAction';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { useDispatch, useSelector } from "react-redux";
import {updateActiveTab} from '../../../services/helper/sfTabManager';

const BatchConvert = ({ object, data, selectedRecords, closeDrawer }) => {

    const dispatch = useDispatch();

    const detailView = useSelector((state) => state.detailView);
    const sfForm = useSelector((state) => state.sfForm);
    let batchConvertReducer = useSelector((state) => state.convert.batchConvertProps.batchConvert);
    const customDrawer = useSelector((state) => state.appContainer.customDrawerProps);

    const [recordIds, setRecordIds] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [currentWorkflow, setCurrentWorkflow] = useState(null);
    const [currentWorkflowId, setCurrentWorkflowId] = useState(0);
    const [workflowList, setWorkflowList] = useState([]);
    const [currentStage, setCurrentStage] = useState(null);
    const [stages, setStages] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [isChangeWorkflow, setIsChangeWorkflow] = useState(false);
    const [changeWorkflowstage, setChangeWorkflowstage] = useState([]);
    const [stateObject, setStateObject] = useState(null);

    const [prevWorkflowId, setPrevWorkflowId] = useState(null);
    const [disableConvert, setDisableConvert] = useState(false);
    const [fromData, setFormData] = useState({});
    const [convertData, setconvertData] = useState({});

    useEffect(() => {
        let minimizeList = customDrawer.minimizeList;
        if (minimizeList.hasOwnProperty('Convert') && minimizeList.Convert && batchConvertReducer !== null) {
            let _fromData = fromData;
            setChangeWorkflowstage(batchConvertReducer.ChangeWorkflowstage);
            setCurrentStage(batchConvertReducer.currentStage);
            setCurrentWorkflow(batchConvertReducer.currentWorkflow);
            setCurrentWorkflowId(batchConvertReducer.currentWorkflowId);
            setDisableConvert(batchConvertReducer.disableConvert);
            setIsChangeWorkflow(batchConvertReducer.isChangeWorkflow);
            setMounted(batchConvertReducer.mounted);
            setStateObject(batchConvertReducer.object);
            setPrevWorkflowId(batchConvertReducer.prevWorkflowId);
            setRecordIds(batchConvertReducer.recordIds);
            setSelectedStatus(batchConvertReducer.selectedStatus);
            setStages(batchConvertReducer.stages);
            setWorkflowList(batchConvertReducer.workflowList);
            setconvertData({ ...batchConvertReducer });
            _fromData.changeWorkflowstage = batchConvertReducer.ChangeWorkflowstage;
            _fromData.selectedStatus = batchConvertReducer.selectedStatus;
            _fromData.stages = batchConvertReducer.stages;
            setFormData({ ..._fromData });
        } else {
            getAndUpdateBatchConvertData();
        }
    }, []);

    const getAndUpdateBatchConvertData = () => {
        let objectName = object;
        if (object === constants.SEARCH_RESULTS) {
            let activeTab = getObjectParam(getActiveTab());
            let activeTabInfo = getObjectParam(activeTab.info);
            let search = getObjectParam(activeTabInfo.search);
            objectName = getStringParam(search.object);
            setStateObject(objectName);
        } else if (object === constants.ADVANCE_SEARCH_RESULTS) {
            let activeTab = getObjectParam(getActiveTab());
            let advanceSearch = getObjectParam(activeTab.info.advanceSearch);
            objectName = getStringParam(advanceSearch.object);
            setStateObject(objectName);
        } else {
            setStateObject(objectName);
        }
        if (isValidParam(data.isChangeWorkflow) && getBooleanParam(data.isChangeWorkflow)) {
            setIsChangeWorkflow(true);
        }

        getConvertDetails();
    }

    /*Need to Revisit this area*/
    /*componentDidUpdate(prevState , nextState){
        if(prevState !== nextState && mounted){
            dispatch(updateBatchConvertData(nextState));
        }
    }*/


    const getConvertDetails = () => {
        let params = null;
        let convertObj = {};
        let prevWFId = null;
        let _currentWorkflow = null;
        let _currentWorkflowId = 0;
        let _electedStatus = null;
        let _stages = [];
        let _workflowList = [];
        let _records = selectedRecords;
        let _fromData = fromData;
        if (_records !== null && _records.length > 0) {
            params = {
                id: _records[0].id
            }
        }
        var response = getConvert(object, params);
        response.then((response) => {
            console.log(response,convertObj, _fromData);
            let currentStageId = 0;
            _currentWorkflowId = response.current_workflow_id;
            _currentWorkflow = response.current_workflow;
            _electedStatus = response.current_stage;
            _stages = response.stages;
            if(response.hasOwnProperty('workflow_list') && response?.workflow_list !== undefined){
                _workflowList = response.workflow_list.filter(e => (e.name !== 'Onboarding' && e.name !== 'Franchisee' && e.type !== 'Onboarding'));
            }
            _fromData.changeWorkflowstage = _stages;
            setRecordIds(_records);
            setMounted(true);
            setCurrentWorkflowId(_currentWorkflowId);
            setCurrentWorkflow(_currentWorkflow);
            setCurrentStage(_electedStatus);
            setSelectedStatus(_electedStatus);
            setStages(_stages);
            setWorkflowList(_workflowList);
            setChangeWorkflowstage(_stages);
            if (stateObject === constants.CONTACTS_OBJECT && response.current_workflow_id === 0) {
                let status_field = sfForm.data.contacts.fields.filter(f => f.name === 't_status');
                if (Array.isArray(status_field) && status_field.length === 1) {
                    let workflowStage = status_field[0].list_values.map((f) => {
                        return { id: f.id, name: f.value };
                    });
                    setStages(workflowStage);
                    _stages = workflowStage;
                    response.stages = workflowStage;
                }
                if(response.hasOwnProperty('workflow_list') && response?.workflow_list !== undefined){
                _workflowList = getArrayParam(response.workflow_list);
                _workflowList.unshift({ id: 0, name: '' })
                setWorkflowList(_workflowList);
                }
                setCurrentWorkflow('');
                _currentWorkflow = '';
                setCurrentWorkflowId(0);
                _currentWorkflowId = 0;
            }
            if (stateObject === constants.CONTACTS_OBJECT) {
                _fromData.changeWorkflowstage = response.stages;
                prevWFId = response.current_workflow_id;
                setPrevWorkflowId(prevWFId);
                setChangeWorkflowstage(response.stages);
            }

            let workflowStage = response.stages;
            let tempStageId = workflowStage[0]?.id;
            map(workflowStage, function (stage, index) {
                if (stage.name === response.current_stage) {
                    if ((index + 1) === workflowStage.length) {
                        currentStageId = tempStageId;
                    } else {
                        let i = index + 1;
                        currentStageId = workflowStage[i].id;
                    }
                }
            });
            setSelectedStatus(currentStageId);
            _electedStatus = currentStageId;
            let _disableConvert = false;
            if(response.hasOwnProperty('workflow_list') && response?.workflow_list !== undefined){
            let workflow = response.workflow_list.filter(f => {
                return (f.id === response.current_workflow_id)
            });
            if (workflow.length === 0) {
                setCurrentWorkflowId(response.workflow_list[0].id);
                _currentWorkflowId = response.workflow_list[0].id;
            }
            if (workflow.length > 0 && isValidParam(workflow[0]) && workflow[0].hasOwnProperty("name")) {
                if (constants.GREENLIGHT_WORKFLOW === getStringParam(workflow[0].name)) {
                    _disableConvert = true;
                    setDisableConvert(_disableConvert);
                }
            }
        }          

            convertObj.ChangeWorkflowstage = response.stages;
            convertObj.currentStage = response.current_stage;
            convertObj.currentWorkflow = _currentWorkflow;
            convertObj.currentWorkflowId = _currentWorkflowId;
            convertObj.disableConvert = _disableConvert;
            convertObj.isChangeWorkflow = isChangeWorkflow;
            convertObj.mounted = true;
            convertObj.object = response.object_label.toLowerCase();
            convertObj.prevWorkflowId = prevWFId;
            convertObj.recordIds = _records;
            convertObj.selectedStatus = _electedStatus;
            convertObj.stages = _stages;
            convertObj.workflowList = _workflowList;
            dispatch(updateBatchConvertData(convertObj));
            setconvertData({ ...convertObj });
            _fromData.selectedStatus = _electedStatus;
            _fromData.stages = _stages;
            setFormData({ ..._fromData });
            console.log(response,convertObj, _fromData);
        });
    }

    const populateStatusList = () => {
        let statusList = '';
        let statusField = []

        if (fromData.changeWorkflowstage != undefined)
            if (object === constants.CONTACTS_OBJECT && currentWorkflowId === 0) {
                statusField = sfForm.data?.contacts?.fields.filter(f => f.name === 't_status');
                let workflowStages = []
                if (Array.isArray(statusField) && statusField.length === 1) {
                    workflowStages = statusField[0].list_values.map((f) => ({ id: f.id, name: f.value }));
                }
                let _statusArray = workflowStages;
                if (_statusArray !== null && _statusArray !== '' && _statusArray !== undefined) {
                    return _statusArray.map((item, i) => {
                        return <MenuItem value={item.id} style={styles.popoverMenuItem} > {item.name} </MenuItem>;
                    }
                    )
                }
            }
            else if (fromData.changeWorkflowstage.length === 0) {
                let selectedStage = currentStage;
                let statusArray = fromData.stages;
                if (statusArray !== null && statusArray !== '' && statusArray !== undefined) {
                    return statusArray.map((item, i) => {
                        let disabled = false;
                        if (item.name === selectedStage) {
                            disabled = true;
                        } else {
                            disabled = false;
                        }
                        return <MenuItem value={item.id} disabled={disabled}> {item.name}  </MenuItem>;
                    }
                    )
                }
            } else {
                let statusArray = fromData.changeWorkflowstage;
                if (statusArray !== null && statusArray !== '' && statusArray !== undefined) {
                    return statusArray.map((item, i) => {
                        return <MenuItem value={item.id} style={styles.popoverMenuItem} > {item.name} </MenuItem>;
                    }
                    )
                }
            }
        return statusList;
    }

    const changeStatus = (value) => {
        if (value !== null) {
            setSelectedStatus(value);
            let convertObj = convertData;
            convertObj.selectedStatus = value;
            setconvertData({ ...convertObj });
            dispatch(updateBatchConvertData(convertObj));
            let _fromData = fromData;
            _fromData.selectedStatus = value;
            setFormData({ ..._fromData });

        } else if (fromData.changeWorkflowstage !== undefined) {
            let tempStages = fromData.changeWorkflowstage;
            value = tempStages[0]?.id;
            setSelectedStatus(tempStages[0]?.id);
            let convertObj = convertData;
            convertObj.selectedStatus = value;
            setconvertData({ ...convertObj });
            dispatch(updateBatchConvertData(convertObj));
            let _fromData = fromData;
            _fromData.selectedStatus = tempStages[0]?.id;
            setFormData({ ..._fromData });
        }
        batchConvertReducer.selectedStatus = value;
    }

    const batchConvert = () => {
        if (!isChangeWorkflow) {
            let object = stateObject;
            let stagevalue = fromData.selectedStatus;
            let stagelist = fromData.stages;
            let objectLabel = getObjectLabelByObject(object);
            let tempRecordIds = [];
            let selectedRecord = recordIds;
            map(stagelist, function (stage, index) {
                if (stagevalue === stage.id) {
                    stagevalue = stage.name;
                }
            });
            for (var i = 0; i < selectedRecord.length; i++) {
                tempRecordIds.push(selectedRecord[i].id);
            }
            let params = {
                "id": tempRecordIds,
                "current_status": currentStage,
                "changed_status": stagevalue,
                "convert_type": constants.BATCH_CONVERT_REC,
                "workflow_id": currentWorkflowId,
            }

            let recLength = recordIds.length;
            var confirmMsg = recLength + ' ' + objectLabel + getLocalizedStrings().message.BATCH_ACTION.CONVERT_ALERT_MSG + stagevalue + "." + getLocalizedStrings().message.BATCH_ACTION.CLICK_TO_CONFIRM;
            if (stateObject == null && data.object == "accounts") {
                object = "accounts";
            }
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, confirmMsg, () => {
                var response = saveBatchConvert(object, params);
                setMounted(false);
                response.then((response) => {
                    if (response.status === 0) {
                        closeDrawer();
                        let tabInfo = getObjectParam(getActiveTabInfo());
                        let params = null;
                        if (tabInfo.hasOwnProperty('searchFieldName') && tabInfo.hasOwnProperty('searchText')) {
                            params = {
                                search_field_name: getStringParam(tabInfo.searchFieldName),
                                search_text: getStringParam(tabInfo.searchText)
                            }
                        }
                        if (detailView.isDetailView) {
                            params = {
                                ...params,
                                isDetailView: true
                            }
                        }
                        if (detailView.isDetailView) {
                            dispatch(refreshDetailViewData(true));
                            dispatch(refreshDetailViewListViewData(true));
                            refreshListView(stateObject, params);
                        } else {
                            let listViewObject = data.hasOwnProperty("listViewObject") ? data.listViewObject : "";
                            if (listViewObject === constants.FIND_DUPLICATE_OBJECT) {
                                dispatch(refreshListView(data.listViewObject, params));
                            } else {
                                let activeTab = getActiveTab();
                                activeTab.info = { ...activeTab.info, rememberSelection: { ...activeTab.info.rememberSelection ?? {}, [object]: new Set()} };
                                updateActiveTab(activeTab);
                                refreshListView(stateObject);
                                
                            }
                        }

                        let requiredFieldList = [];
                        let requiredFieldNameList = [];
                        if (response.data) {
                            requiredFieldList = getArrayParam(response.data.required_list);
                            let data = { [stateObject]: requiredFieldList }
                            dispatch(setRequiredFieldData(data));
                            recLength = recLength - requiredFieldList.length;
                            requiredFieldNameList = getArrayParam(response.data.required_field_list);
                        }
                        let snackbarStyle = styles.snackBarStyleTop;
                        let snackbarBodyStyle = styles.snackbarBodyStyleSuccess;
                        let msg = recLength + ' ' + objectLabel + getLocalizedStrings().message.BATCH_ACTION.CONVERT_SUCCESSFULL;
                        if (requiredFieldList.length > 0) {
                            let msg1 = ` ${requiredFieldList.length} _records have not been converted due to required field(s). `;
                            let msg2 = "";

                            requiredFieldNameList.forEach((name) => {

                                if (msg2 != "") {
                                    msg2 += ", " + name
                                } else {
                                    msg2 = name;
                                }
                            });
                            let tmpMsg = "";
                            if (requiredFieldNameList.length > 1) {
                                tmpMsg = "Required fields are "
                            } else {
                                tmpMsg = "Required field is: "
                            }
                            msg1 = msg1 + tmpMsg + msg2;
                            msg = msg + msg1;
                            snackbarStyle = styles.snackBarStyleLongMsg;
                            if (requiredFieldList.length === recordIds.length) {
                                msg = msg1;
                                snackbarBodyStyle = styles.snackbarBodyStyleError;
                            }
                        }
                        closeDrawer();
                        dispatch(showCustomSnackBar(msg, snackbarBodyStyle, snackbarStyle, 6000));
                    } else if (response.status === -1) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.CONVERT_UNSUCCESSFULL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                });
            }, null);
        } else {
            let wfStageList = fromData.changeWorkflowstage;
            let tempRecordIds = [];
            let stageName = null;
            let stageId = fromData.selectedStatus;
            let recordObject = recordIds;
            for (var i = 0; i < recordObject.length; i++) {
                tempRecordIds.push(recordObject[i].id);
            }

            map(wfStageList, function (stage, index) {
                if (stage.id === stageId) {
                    stageName = stage.name;
                }
            });

            let params = {
                "id": tempRecordIds,
                "workflow_id": currentWorkflowId,
                "stage": stageName
            }
            if (stateObject === constants.CONTACTS_OBJECT && currentWorkflowId === 0) {
                params.current_status = currentStage;
                params.changed_status = stageName;
            }
            var confirmMsg = getLocalizedStrings().message.BATCH_ACTION.BATCH_WORKFLOW_CONFIRMATION;
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, confirmMsg, () => {
                var response = applyWorkFlowBatch(object, params);
                setMounted(false);
                response.then((response) => {
                    if (response.status === 0) {
                        let tabInfo = getObjectParam(getActiveTabInfo());
                        let params = null;
                        if (tabInfo.hasOwnProperty('searchFieldName') && tabInfo.hasOwnProperty('searchText')) {
                            params = {
                                search_field_name: getStringParam(tabInfo.searchFieldName),
                                search_text: getStringParam(tabInfo.searchText)
                            }
                        }
                        if (detailView.isDetailView) {
                            params = {
                                ...params,
                                isDetailView: true
                            }
                        }

                        let listViewObject = data.hasOwnProperty("listViewObject") ? data.listViewObject : "";
                        if (listViewObject === constants.FIND_DUPLICATE_OBJECT) {
                            refreshListView(data.listViewObject, params);
                        } else {
                            let activeTab = getActiveTab();
                            activeTab.info = { ...activeTab.info, rememberSelection: { ...activeTab.info.rememberSelection ?? {}, [object]: new Set()} };
                            updateActiveTab(activeTab);
                            refreshListView(object, params);
                        }

                        let requiredFieldList = [];
                        let requiredFieldNameList = [];
                        let recLength = recordIds.length;
                        if (response.data) {
                            requiredFieldList = getArrayParam(response.data.required_list);
                            let data = { [stateObject]: requiredFieldList }
                            dispatch(setRequiredFieldData(data));
                            recLength = recLength - requiredFieldList.length;
                            requiredFieldNameList = getArrayParam(response.data.required_field_list);
                        }
                        let msg = '';
                        if (stateObject === constants.CONTACTS_OBJECT && prevWorkflowId !== null && prevWorkflowId === currentWorkflowId) {
                            let objectLabel = getObjectLabelByObject(object);
                            msg = recLength + ' ' + objectLabel + getLocalizedStrings().message.BATCH_ACTION.CONVERT_SUCCESSFULL;
                        } else {
                            msg = getLocalizedStrings().message.BATCH_ACTION.WORKFLOW_APPLIED;
                        }
                        let snackbarStyle = styles.snackBarStyleTop;
                        let snackbarBodyStyle = styles.snackbarBodyStyleSuccess;
                        if (requiredFieldList.length > 0) {
                            let msg1 = ` ${requiredFieldList.length} _records have not been converted due to required field(s). `;
                            let msg2 = "";

                            requiredFieldNameList.forEach((name) => {

                                if (msg2 != "") {
                                    msg2 += ", " + name
                                } else {
                                    msg2 = name;
                                }
                            });
                            let tmpMsg = "";
                            if (requiredFieldNameList.length > 1) {
                                tmpMsg = "Required fields are: "
                            } else {
                                tmpMsg = "Required field is: "
                            }
                            msg1 = msg1 + tmpMsg + msg2;

                            msg = msg + msg1;
                            if (requiredFieldList.length === recordIds.length) {
                                msg = msg1;
                                snackbarBodyStyle = styles.snackbarBodyStyleError;
                            }
                            snackbarStyle = styles.snackBarStyleLongMsg;
                        }
                        closeDrawer();
                        dispatch(showCustomSnackBar(msg, snackbarBodyStyle, snackbarStyle, 6000));
                    } else if (response.status === -1) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.CONVERT_UNSUCCESSFULL, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                });

            }, null);
        }
    }



    const getWFList = () => {
        let List = workflowList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.id} style={styles.popoverMenuItem} >{item.name}</MenuItem>;
            })
        }
    }

    const changeWorkflow = (value) => {
        let disable = disableConvert;
        let wf = workflowList.find(f => {
            if (f.id === value) {
                return f;
            }
        });

        if (isValidParam(wf) && wf.hasOwnProperty("name") && constants.GREENLIGHT_WORKFLOW === getStringParam(wf.name)) {
            disable = true;
        } else {
            disable = false;
        }

        setCurrentWorkflowId(value);
        setIsChangeWorkflow(true);
        setDisableConvert(disable);
        let convertObj = convertData;
        convertObj.currentWorkflowId = value;
        convertObj.disableConvert = disable;
        convertObj.isChangeWorkflow = true;
        setconvertData({ ...convertObj });
        dispatch(updateBatchConvertData(convertObj));
        changeWFStage(value);

    }


    const changeWFStage = (workFlowId) => {
        let params = {
            workflow_id: workFlowId
        }
        var response = getWorkFlowStage(object, params);
        response.then((response) => {
            setChangeWorkflowstage(response.stages);
            let convertObj = convertData;
            convertObj.ChangeWorkflowstage = response.stages;
            setconvertData({ ...convertObj });
            dispatch(updateBatchConvertData(convertObj));
            let _fromData = fromData;
            _fromData.changeWorkflowstage = response.stages;
            setFormData({ ..._fromData });
            changeStatus(null);
        });
    }

    if (mounted) {
        var wfListLength = workflowList !== undefined ? workflowList.length : 0;
        var stagevalue = fromData.selectedStatus;
        let statusList = populateStatusList();
        let wfList = getWFList();
        let statusLabel = getLocalizedStrings().label.COMMON.CONVERTING;
        let convertButtonStyle = { ...styles.primaryButton };

        if (!isChangeWorkflow) {
            statusLabel = getLocalizedStrings().label.COMMON.CONVERTING + ' ' + currentStage + ' ' + getLocalizedStrings().label.COMMON.TO;
            let stagelist = fromData.stages;
            map(stagelist, function (stage, index) {
                if (stagevalue === stage.name) {
                    stagevalue = stage.id;
                }
            });
        }

        let showEditButton = 'none';
        if (wfListLength > 1) {
            showEditButton = 'inline-block';
        }

        return (
            <div style={{ width: '100%', marginTop: '10px', paddingLeft: '25px', paddingRight: '25px' }} >
                {(wfListLength > 1 || (stateObject === constants.CONTACTS_OBJECT && wfListLength > 0)) && <div style={{ color: '#717171' }}>
                    <FormControl style={{ width: '100%' }} variant="outlined">
                        <InputLabel id="sf-batch-convert-from-batchconvert-simple-select-outlined-label" className="sf-batch-convert-from-batchconvert">{getLocalizedStrings().label.COMMON.CURRENT_WORKFLOW + ":"}</InputLabel>
                        <Select
                            labelId='sf-label-workflowlist'
                            id='workflowlist'
                            name='workflowlist'
                            value={currentWorkflowId}
                            onChange={(e) => changeWorkflow(e.target.value)}
                            style={{ height: '42px' }}
                            className={"sf-fields-bg"}
                            label={getLocalizedStrings().label.COMMON.CURRENT_WORKFLOW + ":"}
                        >
                            {wfList}
                        </Select>
                    </FormControl>
                </div>}
                <div>



                    <FormControl style={{ width: '100%', marginTop: '10px' }} variant="outlined">
                        <InputLabel id="sf-batch-converts-from-batchconvert-results-simple-select-outlined-label" className="sf-batch-converts-from-batchconvert-results">{statusLabel}</InputLabel>
                        <Select
                            labelId='sf-label-statusList'
                            id='statusList'
                            name='statusList'
                            value={stagevalue}
                            onChange={(e) => changeStatus(e.target.value)}
                            className={"sf-fields-bg"}
                            style={{ height: '42px' }}
                            label={statusLabel}
                            fullWidth={true}
                        >
                            {statusList}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ textAlign: 'right', marginTop: '10px' }}>

                    <Button
                        onClick={() => batchConvert()}
                        style={{ ...convertButtonStyle }}
                    > {getLocalizedStrings().label.COMMON.CONVERT}</Button>

                    <Button
                        onClick={() => closeDrawer()}
                        style={{ backgroundColor: '#ffffff', color: '#717171', border: 'solid 1px #e0e0e0', zIndex: '0', height: '28px', lineHeight: '28px' }}
                    > {getLocalizedStrings().label.COMMON.CANCEL} </Button>
                </div>
            </div>
        );
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    };
};

export default BatchConvert;
