import React, { useState,useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import {endPoints} from "../../../services/constants/endPoints";
import * as HTTPClient from '../../../services/helper/httpClient';
import {Button,FormControlLabel,Checkbox} from '@mui/material';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import { updateAuditChecklistcategories } from '../../../services/actions/auditActions';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";

const AddAuditCategoryQuestion = ({data}) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const AuditChecklist = useSelector((state)=> state.auditDetails?.AuditChecklist);
    const [isMounted, setIsMounted] = useState(false);
    const [questionList,setQuestionList] = useState([]);
    const [selectedQuestions,setSelectedQuestions] = useState(AuditChecklist);
    const [count,setCount] = useState(0);

    useEffect(()=>{
        getSelectedCategoies();
    },[])

    const getSelectedCategoies = () =>{
        let params = {}
        params.id = 0;
        params.group_by_condition = data?.category;
        params.query_name = "All Audit Questions";
        params.query_type = 'query';
        setIsMounted(true);
        const promise = Promise.resolve(HTTPClient.get(endPoints.AUDIT_QUESTIONS.LIST_VIEW, params));
        if (promise) {
            promise.then(res => {
                let allQuestionsOfCategory = res?.records;
                allQuestionsOfCategory.map((ele)=>{
                    ele.master_id = ele?.id;
                })
                let selectedCategory = AuditChecklist.filter((ele)=> {return ele.master_id === data?.master_id});

                let selectedCategoryQuestionsList = selectedCategory[0].questions;
                const namesArray1 = allQuestionsOfCategory?.map(obj => obj.master_id);
                const namesArray2 = selectedCategoryQuestionsList?.map(obj => obj.master_id);

                // Find non-similar objects
                const uniqueFromArray1 = allQuestionsOfCategory.filter(obj => !namesArray2.includes(obj.master_id));
                const uniqueFromArray2 = selectedCategoryQuestionsList.filter(obj => !namesArray1.includes(obj.master_id));

                const uniqueObjects = [...uniqueFromArray1, ...uniqueFromArray2];
                setQuestionList([...uniqueObjects]);
                setIsMounted(false);

            }
        )
        }
    }

    const handleAddQuestionChange = (event, ele) => {
        let isChecked = event.target.checked;

        // Create a shallow copy of the AuditChecklist
        let _AuditChecklist = selectedQuestions?.map((category) => ({
            ...category, 
            questions: [...category.questions]  // Make a shallow copy of the questions array as well
        }));

        if (isChecked) {
            _AuditChecklist.map((element) => {
                if (element.t_category_name === ele.audit_category_name) {
                    element.questions.push(ele);
                }
            });
            setCount(count+1);
        } else {
            _AuditChecklist.map((element) => {
                if (element.t_category_name === ele.audit_category_name) {
                    let filterData = element.questions.filter((data) => data.questions !== ele.questions);
                    element.questions = filterData;
                }
            });
            setCount(count-1);
        }
        // Update the state without mutating the original Redux state
        setSelectedQuestions(_AuditChecklist);
    };


    const getQuestions = (ele,idx) =>{
        return (
            <div style={{width:''}}>
                <FormControlLabel control={<Checkbox />} label={ele.questions} title={ele.questions}  onChange={(event) => handleAddQuestionChange(event,ele)}/>
            </div>
        )
    }

    const AddCategoryDeletedQuestions = () =>{
        let _selectedQuestions = selectedQuestions;
        if(count > 0){
            dispatch(updateAuditChecklistcategories(_selectedQuestions));
            dispatch(getAppDialog(false, null, null, null, null, null));
        }else{ 
            let msg = 'Please select atleast one question to add.'
            dispatch(
            showCustomSnackBar(
              msg,
              styles.snackbarBodyStyleError,
              styles.snackBarStyleTop
            )
            );
        }
    }

    const closeDialog = () =>{
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const getReportView = () =>{
        let div = 
        <div style={{width:'100%',height:'fit-content'}}>
            <div className='deleted_questions_area' style={{width:'33rem',marginLeft:'12px',height:'fit-content',overflow:'hidden',textOverflow:'ellipsis'}}>
                {  questionList?.length > 0 ? questionList.map((ele,idx)=>{
                    return getQuestions(ele,idx);
                })
                :
                <div style={{width:'100%',height:'100%',paddingLeft:'0px',fontSize:'16px',fontWeight:'normal',fontFamily:'-apple-system, Roboto, Helvetica, Arial, sans-serif',marginBottom:'10px'}}>{getLocalizedStrings().message.AUDIT_CATEGORY.NO_AUDIT_CATEGORY_QUESTION_FOUND}</div> 
            }
            </div>
            <div style={{display:'flex',alignItems:'right',justifyContent:'right'}}>
                {questionList?.length > 0  && <Button onClick={() => AddCategoryDeletedQuestions()}
                    style={styles.primaryButton}>{getLocalizedStrings().label.COMMON.ADD}</Button>}
                <Button onClick={() => closeDialog()}
                    style={styles.primaryButton}>{getLocalizedStrings().label.COMMON.CLOSE}</Button>
            </div>
        </div>
        return div;
    }

    let tempStyle = { width: '100%'};
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
  return (
    <div style={tempStyle}>
            {!isMounted ?
                <div style={{width:'100%',height:'100%'}}>
                    {
                        getReportView()
                    }
                </div>
                :
                <div style={{ width: '100%', height: contentHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>
  )
}

export default AddAuditCategoryQuestion