import React from 'react';
import { Select, MenuItem, InputLabel, FormControl, Button } from '@mui/material';
import { styles } from '../../../services/constants/styles';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { isValidParam, getArrayParam, getStringParam, getObjectParam, getBooleanParam, getIntParam } from '../../../services/helper/parameterVerifier';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { setHeader } from '../../../services/actions/headerActions';
import { constants, EVENT_TYPE_KEYS } from '../../../services/constants/constants';
import { setMarketingResults, setEmailMarketingReportDetails, setEmailMarketingReportDetailsScroll } from '../../../services/actions/marketingReportAction';
import { addTab, TYPE_LIST_VIEW, getActiveTabInfo, closeTab } from '../../../services/helper/sfTabManager';
import ShowCircularProgress from '../components/circularProgress';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import SFDataTable from '../components/sfDataTable';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


const FETCH_SIZE = 10;
const EmailMarketingReport = ({ object }) => {

    const [drpdwn1_val, setDrpdwn1_val] = useState('myCampaigns');
    const [drpdwn2_val, setDrpdwn2_val] = useState('rc');
    const [drpdwn2_options, setDrpdwn2_options] = useState('');
    const [myloc_campaign_options, setMyloc_campaign_options] = useState([]);
    const [myCampaign_ops, setMyCampaign_ops] = useState(
        { "id": "td", "name": getLocalizedStrings().label.DASHBOARD.TODAY },
        { "id": "yd", "name": getLocalizedStrings().label.DASHBOARD.YESTERDAY },
        { "id": "tw", "name": getLocalizedStrings().label.DASHBOARD.THIS_WEEK },
        { "id": "tm", "name": getLocalizedStrings().label.DASHBOARD.THIS_MONTH },
        { "id": "tq", "name": getLocalizedStrings().label.DASHBOARD.THIS_QUARTER },
        { "id": "ty", "name": getLocalizedStrings().label.DASHBOARD.THIS_YEAR },
        { "id": "lw", "name": getLocalizedStrings().label.DASHBOARD.LAST_WEEK },
        { "id": "lm", "name": getLocalizedStrings().label.DASHBOARD.LAST_MONTH },
        { "id": "lq", "name": getLocalizedStrings().label.DASHBOARD.LAST_QUARTER },
        { "id": "ly", "name": getLocalizedStrings().label.DASHBOARD.LAST_YEAR });
    const [is_connect_enabled, setIs_connect_enabled] = useState(false);
    const [connected_client_ids, setConnected_client_ids] = useState([]);
    const [selectedToggleOpt, setSelectedToggleOpt] = useState('broadcast');
    const [report_result, setReport_result] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [start_index, setStart_index] = useState(0);
    const [is_more, setIs_more] = useState(false);
    const [stateObject, setStateObject] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
    const [fields, setFields] = useState([]);
    const [changeLog,setChangeLog] = useState({drpdwn1_val:'myCampaigns'});
    const dispatch = useDispatch();

    const marketingReportResults = useSelector((state) => state.marketingReportResults);

    useEffect(() => {
        console.log("marketingReportResults",marketingReportResults)
        let marketingResultRpt = marketingReportResults;
        setStateObject(object);
        setIsMounted(true);
        let info = getObjectParam(getActiveTabInfo());
        if (object === constants.OPERATIONS_CAMPAIGNS || object === constants.OPERATIONS_RECENT_CAMPAIGNS) {
            if (info.hasOwnProperty('report_type')) {
                setSelectedToggleOpt(getStringParam(info.report_type));
             }

            if (isValidParam(marketingResultRpt) && marketingResultRpt.hasOwnProperty('object')) {
                let campaignRptObj = getStringParam(marketingResultRpt.object);
                let campaignrpt = getStringParam(marketingResultRpt.campaignReport);
                if (campaignRptObj === object) {
                    if (campaignrpt !== '' && campaignrpt.length > 0) {
                        setSelectedToggleOpt(campaignrpt);

                    }
                }
            }
            setDrpdwn1_val('myLocCampaigns');
            let log = changeLog;
            log.drpdwn1_val = 'myLocCampaigns';
            setChangeLog({...log});
            setDrpdwn2_val('rc');
            isClientConnectEnabled(true);

        } else {

            if (info.hasOwnProperty('report_type')) {
                setSelectedToggleOpt(getStringParam(info.report_type));

            }
            if (info.hasOwnProperty('object') && getStringParam(info.object) === getStringParam(marketingResultRpt.object)) {
                if (info.hasOwnProperty('report_crt_1')) {
                    setDrpdwn1_val(getStringParam(info.report_crt_1));
                    let log = changeLog;
                    log.drpdwn1_val = getStringParam(info.report_crt_1);
                    setChangeLog({...log});
                }
                if (info.hasOwnProperty('report_crt_2')) {
                    setDrpdwn2_val(getStringParam(info.report_crt_2));

                }

                if (isValidParam(marketingResultRpt) && info.hasOwnProperty('report_crt_1') && info.hasOwnProperty('report_crt_2')) {
                    if (marketingResultRpt.hasOwnProperty('marketingReportData'))  {
                        let reportData = getArrayParam(marketingResultRpt.marketingReportData);
                        setReport_result([...reportData]);
                        if (info.hasOwnProperty('start_index')) {
                            setStart_index(getIntParam(info.start_index));
                        }
                        if (info.hasOwnProperty('is_more')) {
                            setIs_more(getBooleanParam(info.is_more));
                        }

                        if (info.hasOwnProperty('fields')) {
                            let field = getObjectParam(info.fields);
                            setFields([...field]);
                        }
                   setMounted(true);
                    }
                } else {
                    loadResults('useEffect1',selectedToggleOpt,connected_client_ids);
              }
            } else {
                 loadResults('useEffect2',selectedToggleOpt,connected_client_ids);
                }
        }
   
    }, [object, changeLog.drpdwn1_val, drpdwn2_val]);

    //    useEffect(() => {
    //          setIsMounted(true);
    //             setSelectedToggleOpt(marketingReportResults.campaignReport);
    //             console.log("marketingReportResults",marketingReportResults);
    //             setDrpdwn2_val('rc');
    //             setConnected_client_ids([]);
    //             setMyloc_campaign_options([]);
    //             setStart_index(0);
    //             setIs_more(false);
    //             setReport_result([]);
    //             setMounted(false);
    //             if (object === constants.MARKETING_OBJECT) {
    //                 setDrpdwn1_val('myCampaigns');
    //                 getConnectedClientId();
    //                 loadResults('useEffect',selectedToggleOpt,connected_client_ids);
    //             }
    //             if (object === constants.OPERATIONS_CAMPAIGNS) {
    //                 setDrpdwn1_val('myLocCampaigns');
    //                 getConnectedClientId(true);
    //               }
    //             closeTab(constants.MARKETING_OBJECT, 1);
    //   }, []);

    const isClientConnectEnabled = (loadData) => {
         try {
            var promise = Promise.resolve(HTTPClient.get(endPoints.EMAIL_MARKETING_REPORT.IS_CONNECT_ENABLED));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let isEnabled = getBooleanParam(response);
                    setIs_connect_enabled(isEnabled);
                    getConnectedClientId(loadData,selectedToggleOpt);

                }

            });
        } catch (error) {
            console.log("Error in 'EmailMarketingReport.js -> isClientConnectEnabled()': " + error);
        }
    }

    const getConnectedClientId = (loadData,value) => {
       try {
        let param = {};
        if (value === 'broadcast') {
            param.report_type = constants.BROADCAST_REPORT;
        } else if (value === 'touch') {
            param.report_type = constants.AUTO_RESPONDER_REPORT;
        }
        if(object === constants.OPERATIONS_CAMPAIGNS ){
            param.is_check_push  = true;
        }else if(object === constants.OPERATIONS_RECENT_CAMPAIGNS){
            param.is_check_push  = false;
        }
        
        var promise = Promise.resolve(HTTPClient.get(endPoints.EMAIL_MARKETING_REPORT.GET_CONNECTED_CLIENT, param));
        promise.then((response) => {
            if (isValidParam(response)) {
                let clientIds = getArrayParam(response);
                if (clientIds.length > 0) {
                    setConnected_client_ids(clientIds);
                    getPushedCampaigns(clientIds, value);
                    loadResults('getConnectedClientId', value, clientIds);
                } else {
                    if (getBooleanParam(loadData)) {
                        setMounted(true);
                        setReport_result([]);
                        setConnected_client_ids([]);
                        setMyloc_campaign_options([]);
                    }
                }
            }
        });
        
        } catch (error) {
            console.log("Error in 'EmailMarketingReport.js -> getConnectedClientId()': " + error);
        }
    }

    const getPushedCampaigns = (clientIds,value) => {
        try {
            let param = { clientIds: clientIds };
            let endPoint = '';
            if (value === 'broadcast') {
                endPoint = endPoints.BROADCAST_CAMPAIGNS.GET_PUSHED_CAMPAIGNS;
            }
             if (value === 'touch') {
                endPoint = endPoints.TOUCH_CAMPAIGNS.GET_PUSHED_CAMPAIGNS;
            }
            var promise = Promise.resolve(HTTPClient.get(endPoint, param));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let pCampaigns = getArrayParam(response);
                    if (pCampaigns.length > 0) {
                        setMyloc_campaign_options(pCampaigns);;
                    }
                }
            });
        } catch (error) {
            console.log("Error in 'EmailMarketingReport.js -> getPushedCampaigns()': " + error);
        }
    }

    const handleChange = (name, value) => {
        let temp = name + "_val";
      if (name === 'reportdropdown') {
            setSelectedToggleOpt(value);
            setDrpdwn2_val('rc');
        } else {
            if (name === 'drpdwn1') {
                setDrpdwn1_val(temp);
                let log = changeLog;
                log.drpdwn1_val = temp;
                setChangeLog({...log});
                
            }
            else if (name === 'drpdwn2') {
                setDrpdwn2_val(temp);
             }
           
        }
        if (name === 'drpdwn1') {
            setDrpdwn2_val('rc');
            let tempDrpdwn2_options = loadDropDown2Options(value);
            setDrpdwn2_options(tempDrpdwn2_options);
        }
        closeTab(constants.MARKETING_OBJECT, 1);
        setMounted(false);
        let objectData = getStringParam(object);
        if (objectData === constants.OPERATIONS_CAMPAIGNS || objectData === constants.OPERATIONS_RECENT_CAMPAIGNS && name === 'reportdropdown') {
            getConnectedClientId(true,value);
        } else {
            loadResults('onCnange',value,connected_client_ids);
        }
      }


    const loadDropDown2Options = (value) => {
        let options = null;
        try {
            let optValues = '';

            if (value === 'myCampaigns') {
                optValues = getArrayParam(myCampaign_ops);
            } else {
                optValues = getArrayParam(myloc_campaign_options);
            }
            if (optValues != null && optValues.length > 0) {
                options = optValues.map((opt, index) => {
                    return <MenuItem key={opt.id} value={opt.id} style={styles.popoverMenuItem} >{<div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', width: '268px' }} title={opt.name}>{opt.name}</div>} </MenuItem>
                });
            }
            return options;
        } catch (error) {
            console.log("Error in 'EmailMarketingReport.js -> loadDropDown2Options()': " + error);
        }

    }

    const setHeaderData = () => {
        let objectName = getLocalizedStrings().label.EMAIL_MARKRTING_REPORT.REPORT_HEADER;
        let kanbanObject = {};
        kanbanObject.currentView = 'broadcast';
        kanbanObject.show = true;
        if (object === constants.OPERATIONS_CAMPAIGNS) {
            objectName = getLocalizedStrings().label.OPERATIONS_CAMPAIGNS.REPORT_HEADER;
        }

        if (object === constants.OPERATIONS_RECENT_CAMPAIGNS) {
            objectName = getLocalizedStrings().label.OPERATIONS_RECENT_CAMPAIGNS.REPORT_HEADER;
        }

        dispatch(setHeader(object, objectName, null, false, true));
    }

    const loadResults = (callFrom, value, clientIds) => {
        try {
            let isScroll = getBooleanParam(callFrom === 'scroll');
            let _drpdwn1_val = changeLog.drpdwn1_val;

            if (callFrom !== 'scroll') {
                setMounted(false);
                setStart_index(0);
                setFields([]);
                setReport_result([]);
                setIs_more(false);
            }
            let tempStartIndex = 0;
            if (isScroll) {
                tempStartIndex = start_index + FETCH_SIZE;
            }

            let param = {};
            param.start_index = tempStartIndex;
            param.fetch_size = FETCH_SIZE;

            if (value === 'broadcast') {
                param.report_type = constants.BROADCAST_REPORT;
            }
             if (value === 'touch') {
                param.report_type = constants.AUTO_RESPONDER_REPORT;
            }
            param.rpt_criteria_1 = _drpdwn1_val;
            param.rpt_criteria_2 = drpdwn2_val;
            param.connected_tenants = clientIds;

            param.is_recent = 0;

            let info = getObjectParam(getActiveTabInfo());
            info.report_crt_1 = _drpdwn1_val;
            info.report_crt_2 = drpdwn2_val;
            info.start_index = tempStartIndex;
            info.report_type = value;
            info.object = object;

            if (object === constants.OPERATIONS_RECENT_CAMPAIGNS) {
                param.is_recent = 1;
            }
           var promise = Promise.resolve(HTTPClient.get(endPoints.EMAIL_MARKETING_REPORT.RESULTS, param));
            promise.then((response) => {
                let itemLength = 0;
                let results = null;
                if (isValidParam(response) && response.hasOwnProperty('marketing_results')) {
                    results = getArrayParam(response.marketing_results);
                    setReport_result([...results]);
                    info.fields = response.fields;
                    setFields([...response.fields]);
                    itemLength = response.marketing_results.length > 0 ? Object.keys(getObjectParam(response.marketing_results[0])).length : 0;
                    if (object === constants.MARKETING_OBJECT) {
                        if (isScroll) {
                            dispatch(setEmailMarketingReportDetailsScroll(response.marketing_results));
                        } else {
                            dispatch(setEmailMarketingReportDetails(response.marketing_results, object));
                            setMounted(true);

                        }
                    }
                    else if (object === constants.OPERATIONS_CAMPAIGNS ) {
                        dispatch(setEmailMarketingReportDetails([], object));
                    }
                }

                let ismore = true;

                if ((_drpdwn1_val === 'myCampaigns' && drpdwn2_val === 'rc') || (isValidParam(itemLength) && itemLength < FETCH_SIZE)) {
                    ismore = false;
                }
                info.is_more = ismore;
                setStart_index(tempStartIndex);
                setIs_more(ismore);
              });
        } catch (error) {
            console.log("Error in 'EmailMarketingReport.js -> loadResults()': " + error);
        }
    }


    useEffect(() => {
        if(fields.length>0 && report_result.length>0){
            setMounted(true);
        }
 }, [fields,report_result]);
    useEffect(() => {
        setDrpdwn2_options(loadDropDown2Options(changeLog.drpdwn1_val));

    }, [drpdwn2_options]);

   
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2;

    if (isValidParam(object) && isMounted) {
        setIsMounted(false);
        setHeaderData();
    }
   let style = { width: '25%', display: 'inline-block', marginLeft: '2%' };
    let resultData = getArrayParam(report_result);
    return (<div style={{ width: '100%', paddingLeft: '30px', paddingRight: '20px' }}>
        {isValidParam(object) ?
            <div className="row">
                <div className="col-sm-12">
                    <div style={{ width: '25%', display: 'inline-block' }}>
                        <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                            <InputLabel id="sf-emailmarketingreport-simple-select-outlined-label" className="sf-emailmarketingreport"></InputLabel>
                            <Select
                                id="reportdropdown"
                                name="reportdropdown"
                                value={selectedToggleOpt}
                                onChange={(e) => handleChange("reportdropdown", e.target.value)}
                                className='asf'
                                style={{ height: '40px', paddingBottom: '8px', paddingTop: '8px', marginBottom: '20px', backgroundColor: '#fff' }}
                            >
                                <MenuItem value='broadcast' title={getLocalizedStrings().label.OPERATIONS_CAMPAIGNS.BROADCAST_REPORT} style={styles.popoverMenuItem} >{getLocalizedStrings().label.OPERATIONS_CAMPAIGNS.BROADCAST_REPORT}</MenuItem>
                                <MenuItem value='touch' title={getLocalizedStrings().label.OPERATIONS_CAMPAIGNS.TOUCH_REPORT} style={styles.popoverMenuItem} >{getLocalizedStrings().label.OPERATIONS_CAMPAIGNS.TOUCH_REPORT}</MenuItem>

                            </Select>
                        </FormControl>
                    </div>

                    <div style={style}>
                        {object === constants.OPERATIONS_CAMPAIGNS && <FormControl variant="outlined" size="small" style={{ width: '100%' }}>
                            <InputLabel id="sf-emailmarketing-report-simple-select-outlined-label" className="sf-emailmarketing-report"></InputLabel>
                            <Select
                                id="drpdwn2"
                                name="drpdwn2"
                                value={drpdwn2_val}
                                onChange={(e) => handleChange("drpdwn2", e.target.value)}
                                className='asf'
                                style={{height: '40px', paddingBottom: '8px', paddingTop: '8px', marginBottom: '20px', backgroundColor: '#fff' }}
                            >
                                <MenuItem value='rc' title={getLocalizedStrings().label.EMAIL_MARKRTING_REPORT.RECENT_CAMPAIGNS} style={styles.popoverMenuItem} >{getLocalizedStrings().label.EMAIL_MARKRTING_REPORT.RECENT_CAMPAIGNS}</MenuItem>
                                {drpdwn2_options}
                            </Select>
                        </FormControl>}
                    </div>
                    <div>
                        {mounted ?
                            <div>
                                {resultData.length > 0 ?
                                    <div>
                                        <SearchResults resultContent={resultData}
                                            criteria_1={changeLog.drpdwn1_val}
                                            criteria_2={drpdwn2_val}
                                            loadResults={(data) => loadResults(data,selectedToggleOpt,connected_client_ids)}
                                            isMore={is_more}
                                            selectedReport={selectedToggleOpt}
                                            fields={fields}

                                        />
                                    </div> :
                                    <div
                                        style={
                                            {
                                                // width: '66%',
                                                marginTop: '10%',
                                                textAlign: 'center',
                                                fontSize: '16px',
                                                color: '#868686',

                                            }}>
                                        {
                                        selectedToggleOpt == "touch" ? "No touch Campaigns found":
                                        getLocalizedStrings().message.COMMON.NO_RESULTS_FOUND}
                                    </div>
                                }

                            </div>
                            :
                            <div style={{ width: '100%', height: contentHeight }}>
                                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div> :
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>}</div>);

}

export default EmailMarketingReport;

const SearchResults = ({ resultContent, criteria_1, loadResults, isMore, selectedReport, fields,selectedToggleOpt }) => {

    const [reportResult, setReportResult] = useState([]);
    const [disableScroll, setDisableScroll] = useState(false);
    const [stateMore, setStateMore] = useState(false);
    const [reportData, setReportData] = useState([]);
    
    const [optReportResult, setOptReportResult] = useState({
        fields: [],
        records: [],
        rowSort: {
            sortFieldName: 'company',
            isDescending: false,
            onSort: (fieldName, isDescending) => onSortFn(fieldName, isDescending)
        }
    });
    const dispatch = useDispatch();

   useEffect(() => {
        let resultArr = getReportResult();
        setReportResult(resultArr);
        setDisableScroll(false);
        setStateMore(isMore);
        setReportData(resultContent);
    }, []);

//  useEffect(() => {
//         let resultContent_1 = getArrayParam(resultContent);
//         let resultContent_2 = getArrayParam(resultContent);

//         if (resultContent_1.length !== resultContent_2.length) {
//             let resultArr = getReportResult();
//             setReportResult(resultArr);
//             setStateMore(isMore);
//             setReportData(resultContent);
//         } else {
//             setStateMore(isMore);
//         }
//     }, []);

    const drilldownMarketingReport = (obj, eventType, reportType) => {
        try {
            let params = {
                name: 'All records',
                tableId: 9,
            }
            var promise = Promise.resolve(HTTPClient.get(endPoints.REPORTS.QUERY_DETAIL, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let params = response;
                    let label = getStringParam(obj.campaign_name);
                    if (eventType === 'click') {
                        let reportName = obj.subject;
                        let eventTypeText = getLocalizedStrings().label.CAMPAIGNS.CLICK_URL;
                        label = eventTypeText + " " + reportName;
                    } else {
                        let eventTypeText = eventType.charAt(0).toUpperCase() + eventType.slice(1);
                        if (reportType === constants.BROADCAST_REPORT_TYPE) {
                            let eventTypeTextUpper = eventTypeText.toUpperCase();
                            eventTypeText = getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(eventTypeTextUpper) ? getLocalizedStrings().label.CAMPAIGNS[eventTypeTextUpper] : eventTypeText;
                            label = eventTypeText + " " + getLocalizedStrings().label.BROADCAST_CAMPAIGNS.EMAIL_CAMPAIGN_REPORT;
                        } else if (reportType === constants.AUTO_RESPONDER_REPORT_TYPE) {
                            label = eventTypeText + " " + getLocalizedStrings().label.AUTOMATION_DESIGNER.TOUCH_CAMPAIGN_REPORT;
                        }
                    }
                    let url = "/" + constants.CONTACTS_OBJECT + "/" + reportType.toLocaleLowerCase();
                    openTab(label, constants.CONTACTS_OBJECT, response.query_id, "fa fa-bullhorn", url, eventType, obj, reportType);
                }
            });
        } catch (error) {
            console.log("Error in 'EmailMarketingReport.js -> drilldownMarketingReport()':" + error);
        }
    }

    const openTab = (label, component, queryId, imgName, url, eventType, obj, reportType) => {
        let filter = {
            id: queryId,
            name: '',
            type: constants.TYPE_AUTORESPONDER_REPORT,
        }
        let tabType = TYPE_LIST_VIEW;
        let reportFieldValue = eventType;
        if (eventType === 'click') {
            reportFieldValue = 'Clicked';
        }

        let arrMarketingResults = obj;
        let tab = {
            label: label,
            object: component,
            type: tabType,
            imgName: imgName,
            info: {
                filter: filter,
                input_param: {
                    stat_id: obj.id,
                    event_type: eventType,
                },
                isReport: true,
                reportType: reportType,
                event_type_key: EVENT_TYPE_KEYS[eventType],
                campaignId: obj.campaign_id,
                reportFieldValue: getStringParam(eventType),
                reportLabel: getStringParam(obj.campaign_name),
                recordCount: arrMarketingResults[EVENT_TYPE_KEYS[eventType]],
            },
            url: url,
            isActive: true
        };

        let resultArr = getArrayParam(reportData);
        for (let result of resultArr) {
            if (Object.keys(result).length > 0) {
                if (isValidParam(result) && result.hasOwnProperty("camp_" + obj.campaign_id)) {
                    let campObj = result["camp_" + obj.campaign_id];
                    let msgArr = campObj.msg_list;

                    dispatch(setMarketingResults(msgArr));

                    break;

                }
            }
        }

        addTab(tab, true);

    }

    const getPercentage = (value, sent) => {
        let percentage_value = '';
        let sent_value = sent;
        if (sent_value !== 0) {
            percentage_value = (value / sent_value) * 100;
            if (Math.round(percentage_value) !== percentage_value) {
                percentage_value = percentage_value.toFixed(2);
            }
            return percentage_value + '%';
        }
    }

    const getReportResult = () => {
        let _reportResult = getArrayParam(resultContent);
        if (_reportResult.length > 0) {
            fields.map((f, i) => {
            if (f.field_type === "integer") { f.style = { textAlign: "center" }; }
        });
        let tempOptReportResult = optReportResult;
        tempOptReportResult.fields = getArrayParam(fields);
      
        let optRptObjArr = [];
        let optRptObj = null;

        if (selectedReport === 'broadcast') {
            _reportResult.forEach((result) => {
                Object.keys(result).map((itemKey) => {
                    let campObj = result[itemKey];
                    optRptObj = {};
                    let msgArr = getArrayParam(campObj.msg_list);
                    if (msgArr != null && msgArr.length > 0) {
                        let msgObj = msgArr[0];
                        optRptObj.id = msgObj.id;
                        optRptObj.campaign_id = msgObj.campaign_id;
                        optRptObj.campaign_name = msgObj.campaign_name;
                        optRptObj.company = campObj.company;
                        optRptObj.email = campObj.email;
                        if (selectedToggleOpt === 'touch') {
                            optRptObj.msg_name = msgObj.msg_name;
                        }
                        optRptObj.sent = msgObj.sent;
                        optRptObj.opened = msgObj.opened + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.opened, msgObj.sent) + ')');
                        optRptObj.unopened = msgObj.unopened + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.unopened, msgObj.sent) + ')');
                        optRptObj.clicked = msgObj.clicked + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.clicked, msgObj.sent) + ')');
                        optRptObj.bounced = msgObj.bounced + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.bounced, msgObj.sent) + ')');
                        optRptObj.unsubscribed = msgObj.unsubscribed + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.unsubscribed, msgObj.sent) + ')');
                        optRptObj.spam = msgObj.spam + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.spam, msgObj.sent) + ')');
                        optRptObj.dropped = msgObj.dropped + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.dropped, msgObj.sent) + ')');
                    }
                    optRptObjArr.push(optRptObj);

                    optRptObj = null;
                });
            });

            if (getBooleanParam(tempOptReportResult.rowSort.isDescending)) {
                sortArrayObjectByPropertyDesc(optRptObjArr, tempOptReportResult.rowSort.sortFieldName);
            }

            tempOptReportResult.records = getArrayParam(optRptObjArr);
            setOptReportResult({...tempOptReportResult});
            if (criteria_1 === 'myCampaigns') {
                return (<div style={{ paddingBottom: '8px' }}><SFDataTable columns={tempOptReportResult.fields} rowSort={tempOptReportResult.rowSort} rows={tempOptReportResult.records} customEvent={(eventType, column, row, event)=>customEvent(eventType, column, row, event)} /></div>);
            } else {
                return (<div style={{ paddingBottom: '8px' }}><SFDataTable columns={tempOptReportResult.fields} rowSort={tempOptReportResult.rowSort} rows={tempOptReportResult.records} /></div>);
            }

        } else if (selectedReport === 'touch') {
            let arr = [];
            _reportResult.forEach((result) => {
                Object.keys(result).map((itemKey) => {
                    optRptObjArr = [];
                    let campObj = result[itemKey];
                    let msgArr = getArrayParam(campObj.msg_list);
                    if (msgArr != null && msgArr.length > 0) {

                        msgArr.map((msgObj, index) => {
                            optRptObj = {};
                            optRptObj.id = msgObj.id;
                            optRptObj.campaign_id = msgObj.campaign_id;
                            optRptObj.campaign_name = msgObj.campaign_name;
                            optRptObj.msg_name = msgObj.msg_name;
                            optRptObj.sent = msgObj.sent;
                            optRptObj.opened = msgObj.opened + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.opened, msgObj.sent) + ')');
                            optRptObj.unopened = msgObj.unopened + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.unopened, msgObj.sent) + ')');
                            optRptObj.clicked = msgObj.clicked + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.clicked, msgObj.sent) + ')');
                            optRptObj.bounced = msgObj.bounced + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.bounced, msgObj.sent) + ')');
                            optRptObj.unsubscribed = msgObj.unsubscribed + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.unsubscribed, msgObj.sent) + ')');
                            optRptObj.spam = msgObj.spam + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.spam, msgObj.sent) + ')');
                            optRptObj.dropped = msgObj.dropped + (msgObj.sent === 0 ? '' : ' (' + getPercentage(msgObj.dropped, msgObj.sent) + ')');

                            optRptObjArr.push(optRptObj);

                            optRptObj = null;
                            });
                    }
                    arr.push(
                        <div style={{ height: ((optRptObjArr.length + 1) * 30) + 40 }}>
                            {(criteria_1 === 'myCampaigns') ?
                                <div style={{ fontWeight: 'bold', fontSize: '16px', paddingBottom: '8px' }}>{campObj.campaign_name}</div>
                                :
                                <div style={{ fontWeight: 'bold', fontSize: '16px', paddingBottom: '8px' }}>{campObj.company}, {campObj.campaign_name}, {campObj.email}</div>
                            }
                            <div style={{ paddingBottom: '8px' }}>
                                {(criteria_1 === 'myCampaigns') ?
                                    <SFDataTable columns={tempOptReportResult.fields} rowSort={tempOptReportResult.rowSort} rows={optRptObjArr} customEvent={(eventType, column, row, event)=>customEvent(eventType, column, row, event)} />
                                    :
                                    <SFDataTable columns={tempOptReportResult.fields} rowSort={tempOptReportResult.rowSort} rows={optRptObjArr} />
                                }
                            </div>
                        </div>
                    );
                    optRptObjArr = null;

                });
            });

            return arr;
        }

    }

    }

    const sortArrayObjectByPropertyDesc = (array, property) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = val1 instanceof String ? val1.toLowerCase() : val1;
                    val2 = val2 instanceof String ? val2.toLowerCase() : val2;

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }
    const onSortFn = (fieldName, isDescending) => {
        let rowSorts = optReportResult.rowSort;
        let records = getArrayParam(optReportResult.records);
        let sortFieldName = getStringParam(optReportResult.rowSort.sortFieldName);
        try {
            if (getBooleanParam(rowSorts.isDescending)) {
                sortArrayObjectByProperty(records, sortFieldName);
                rowSorts.isDescending = false;
            }
            else {
                sortArrayObjectByPropertyDesc(records, sortFieldName);
                rowSorts.isDescending = true;
            }

            let tempOptReportResult = optReportResult;
            tempOptReportResult.rowSort = rowSorts;
            setOptReportResult({...tempOptReportResult});

        }
        catch (error) {
            console.error("Error in 'EmailMarketingReport.js -> onSort()':" + error);
        }
    }

    const customEvent = (eventType, column, row, event) => {
        try {
            var value = 0;
            row = getObjectParam(row);
            if (column.name === 'sent') {
                value = getIntParam(row[column.name]);
            } else {
                var val = getStringParam(row[column.name]);
                val = val.split('(')[0];
                value = getIntParam(val.trim());
            }

            if (value > 0) {
                let reportType = constants.BROADCAST_REPORT_TYPE;

                if (row.hasOwnProperty('msg_name')) {
                    reportType = constants.AUTO_RESPONDER_REPORT_TYPE;
                }
                eventType = EVENT_TYPES_KEYS_MAP[column.name];
                drilldownMarketingReport(row, eventType, reportType);
            }
        }
        catch (error) {
            console.error("Error in 'EmailMarketingReport.js -> customEvent()':" + error);
        }
    }

    const handleMore = () => {
        loadResults('scroll');
    }


    return <div id="email-report">
        {reportResult}
        {stateMore &&
            <div>
                <Button
                    key='more'
                    onClick={() => handleMore()}

                    style={{ ...styles.primaryButton, marginRight: '0px', marginTop: '5px', verticalAlign: 'top' }}
                >{getLocalizedStrings().label.COMMON.MORE}</Button>
            </div>
        }
    </div >;
}
const EVENT_TYPES_KEYS_MAP = {
    sent: 'Sent',
    opened: 'Open',
    unopened: 'Unopened',
    clicked: 'click',
    bounced: 'Bounce',
    unsubscribed: 'Unsubscribed',
    spam: 'spamreport',
    dropped: 'dropped'
}