import React, { useEffect, useState, useRef } from 'react';
import * as HTTPClient from '../../../services/helper/httpClient';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { getFloatParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { refreshDetailViewListViewData, refreshQuotation } from '../../../services/actions/detailViewActions';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { endPoints } from '../../../services/constants/endPoints';
import { setMonerisCheckout } from '../../../services/actions/paymentActions';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import * as sfDialogs from "../components/sfDialogs";

const MonerisCheckout = () => {

  const [ticket, setTicket] = useState();
  const dispatch = useDispatch();

  const quoteID = getIntParam(getActiveTabInfo().quotation_id);

  const sfForm = useSelector((state) => state.sfForm);


  const generateTicket = async () => {
    try {
      const response = await HTTPClient.post(endPoints.PAYMENT_INTEGRATION.MONERIS_PROCESS_PAYMENT, { quotation_id: quoteID === 0 ? sfForm.checkoutQuoteId : quoteID });
      const balanceDue = getFloatParam(response?.data?.balanceDue);
      if (Number(balanceDue) <= 0) {
        sfDialogs.alert(
          getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
          (sfForm.checkoutQuoteId || quoteID) && response?.data?.status === "Paid" 
            ? getLocalizedStrings().message.PAYMENTS.PAYMENT_ALREADY_PAID 
            : (sfForm.checkoutQuoteId || quoteID) && response?.data?.status === "New" 
              ? getLocalizedStrings().message.PAYMENTS.PAYMENT_INVALID 
              : getLocalizedStrings().message.PAYMENTS.PAYMENT_INVALID_MSG
        );
        dispatch(setMonerisCheckout(false));
      }
      setTicket(response.data.ticket);
    } catch (error) {
      console.error('Error generating ticket:', error);
    } 
  };

  useEffect(() => {
    generateTicket();

    return () => {
      dispatch(setMonerisCheckout(false));
    };
  }, []);

  const initializeCheckout = () => {
    const windowFeatures = {
      width: 600,
      height: 700,
      top: window.top.outerHeight / 2 + window.top.screenY - 700 / 2,
      left: window.top.outerWidth / 2 + window.top.screenX - 600 / 2,
      toolbar: 'no',
      scrollbars: 'yes',
      resizable: 'yes',
    };

    const windowFeaturesStr = Object.entries(windowFeatures)
      .map(([key, value]) => `${key}=${value}`)
      .join(',');

    const checkoutWindow = window.open('', 'MonerisCheckout', windowFeaturesStr);

    checkoutWindow.document.write(`
      <html>
        <head>
          <title>Product Checkout</title>
        </head>
        <body>
          <div id="monerisCheckout"></div>
          <script>
            window.addEventListener('beforeunload', (event) => {
              const confirmationMessage = "Are you sure you want to close this window?";
              event.returnValue = confirmationMessage; // For modern browsers
              return confirmationMessage; // For older browsers
            });
          </script>
        </body>
      </html>
    `);

    const script = checkoutWindow.document.createElement('script');
    script.src = constants.MONERIS_CDN_LINK;
    script.async = true;
    script.onload = () => {
      const myCheckout = new checkoutWindow.monerisCheckout();
      checkoutWindow.current = myCheckout;
      myCheckout.setMode(constants.MONERIS_MODE);
      myCheckout.setCheckoutDiv("monerisCheckout");

      myCheckout.setCallback("page_loaded", myPageLoad);
      myCheckout.setCallback("cancel_transaction", (ex) => myCancelTransaction(ex, checkoutWindow));
      myCheckout.setCallback("error_event", myErrorEvent);
      myCheckout.setCallback("payment_receipt", (ex) => myPaymentReceipt(ex, checkoutWindow));
      myCheckout.setCallback("payment_complete", (ex) => myPaymentComplete(ex, checkoutWindow));

      myCheckout.startCheckout(ticket);
    };

    checkoutWindow.document.head.appendChild(script);

    const checkClosed = setInterval(() => {
      if (checkoutWindow.closed) {
        clearInterval(checkClosed);
        onWindowClose();
      }
    }, 500);
  };

  const onWindowClose = () => {
    
    dispatch(setMonerisCheckout(false));
  };

  useEffect(() => {
    if (ticket) {
      initializeCheckout();
    }
  }, [ticket]);

  const myPageLoad = (ex, data) => {
   
  };

  const myCancelTransaction = (ex, checkoutWindow) => {
    

    checkoutWindow.close();

    dispatch(setMonerisCheckout(false));
  };

  const myErrorEvent = (ex) => {
    
  };

  const myPaymentReceipt = async (ex) => {
    try {
      const { ticket } = JSON.parse(ex);
      await HTTPClient.post(endPoints.PAYMENT_INTEGRATION.MONERIS_CALLBACK, { ticket, quotation_id: quoteID === 0 ? sfForm.checkoutQuoteId : quoteID });
      dispatch(getAppDrawer(false, null, null, null, null));
      dispatch(refreshDetailViewListViewData(true));
      dispatch(refreshQuotation(true));
    } catch (error) {
      console.error('Error processing payment receipt:', error);
    }
  };

  const myPaymentComplete = (ex, checkoutWindow) => {
    
    dispatch(setMonerisCheckout(false));
    checkoutWindow.close();
  };

  return (
    ticket ? (
      <div id="outerDiv">
        <div id="monerisCheckout"></div>
      </div>
    ) : null
  );
};

export default MonerisCheckout;
