import { actions } from '..//constants/actions';
import { sortHomeItems } from '..//helper/common';

const appInitialState = {
    me: {},
    home: null,
    selectedModuleName: null,
    lastSelectedModuleName: null,
    selectedModuleItems: null,
    objFilterMenu: {
        popOverOpen: false,
        anchorEl: null,
        objectName: null,
        itemLabel: null,
        itemType: null,
        itemImgName: null
    },
    menuDrawerIsOpen: true,
    searchCriteria: {},
    objectList: [],
    pop: [],
    securityInfo: {},
    linkChildInfo: {},
    advanceSearch: null,
    openSearchPopover: {
        advance: false,
        global: false
    },
    isRedirect: false,
    redirectUrl: '',
    selectedModuleInfo: null,
    objectIconImageMap: {},
    selectedSearchObject: null,
    isInvalidToken: false,
    queryInfo: {},
    notificationInfo: {},
    selectAppDialog: false,
    email_integration_apikey: null,
    aurinko_integration_apikey: null,
    countryISO2: null,
    eventbrite_apikey: null,
    dashBoardInfo: null,
    notification: [],
    appointment_scheduler_slug: null,
    homeSetup: null,
    homeItemLableList: {},
    externalMenuLink: null,
    isNewTree: true,
    mainAvailableModules: [],
}

const appReducer = (state = appInitialState, action) => {
    switch (action.type) {
        case actions.APP_ME_GET_FULFILLED:
            if (action.payload.hasOwnProperty('invalid_token')) {
                return {
                    ...state,
                    me: null,
                    securityInfo: null,
                    isInvalidToken: action.payload.invalid_token
                }
            } else {
                return {
                    ...state,
                    me: action.payload.me[0],
                    securityInfo: action.payload.securityInfo.data,
                    isInvalidToken: false,
                    email_integration_apikey: action.payload.email_integration_apikey,
                    aurinko_integration_apikey: action.payload.aurinko_integration_apikey,
                    countryISO2: action.payload.country_iso2,
                    eventbrite_apikey: action.payload.eventbrite_clientId
                }
            }
        case actions.APP_FRANCHISOR_INDUSTRY_UPDATE:
            return {
                ...state,
                me: { ...state.me, franchise_industry: action.payload.franchise_industry, franchise_priceid: action.payload.franchise_priceid, franchisor_add_unit: action.payload.franchisor_add_unit }
            }
        case actions.APP_INFORMATION_STATUS_UPDATE:
            return {
                ...state,
                me: { ...state.me, show_aurinko_reauth_msg: action.payload }
            }
        case actions.APP_REAUTHENTICATE_AURINKO_UPDATE_ALL:
            return {
                ...state,
                me: { ...state.me, is_aurinko_reauth_required: action.payload, show_aurinko_reauth_msg: action.payload }
            }
        case actions.APP_REAUTHENTICATE_AURINKO_UPDATE:
            return {
                ...state,
                me: { ...state.me, is_aurinko_reauth_required: action.payload}
            }
        case actions.APP_IS_SEND_MAIL_USING_AURINKO_UPDATE:
            return {
                ...state,
                me: { ...state.me, is_send_mail_using_aurinko: action.payload }
            }
        case actions.APP_AURINKO_CASE_MAILBOX_UPDATE:
            return {
                ...state,
                me: { ...state.me, aurinko_cases_mailbox: action.payload }
            }
        case actions.APP_HOME_GET_FULFILLED:
            let home = sortHomeItems(action.payload, action.state);
            return {
                ...state,
                home,
                objectIconImageMap: home.objectIconImageMap,
                homeItemLableList: home.homeItemLableList,
                mainAvailableModules: action.payload
            }
        case actions.APP_HOME_UPDATE:
            return {
                ...state,
                home: action.payload,
            }
        case actions.APP_NOTIFICATION_UPDATE:
            return {
                ...state,
                notification: [...action.payload],
            }
        case actions.APP_HOME_SET_MODULE_ITEMS:
            return {
                ...state,
                selectedModuleName: action.payload.moduleName,
                selectedModuleItems: action.payload.moduleItems,
                lastSelectedModuleName: action.payload.lastSelectedModuleName,
            }
        case actions.OPEN_FILTER_MENU:
            return {
                ...state,
                objFilterMenu: action.payload
            }
        case actions.APP_SEARCH_POPOVER_OPEN_CLOSE:
            return {
                ...state,
                openSearchPopover: action.payload
            }

        case actions.OPEN_LEFT_MENU_DRAWER:
            return {
                ...state,
                menuDrawerIsOpen: action.payload
            }
        case actions.APP_SEARCH_CRITERIA_SET:
            return {
                ...state,
                searchCriteria: action.payload
            }
        case actions.APP_ADVANCE_SEARCH_CRITERIA_SET:
            return {
                ...state,
                advanceSearch: action.payload
            }
        case actions.APP_LINK_OBJECT_INFO_FULFILLED:
            return {
                ...state,
                linkChildInfo: action.payload.linkChildInfo
            }
        case actions.SET_APP_LINK_OBJECT_INFO:
            return {
                ...state,
                linkChildInfo: action.payload
            }
        case actions.APP_REDIRECT:
            return {
                ...state,
                isRedirect: action.payload.isRedirect,
                redirectUrl: action.payload.redirectUrl,
            }
        case actions.APP_UPDATE_USER_LANGUAGE:
            return {
                ...state,
                me: { ...state.me, language: action.payload.language }
            }
        case actions.DASHBOARD_INFO_GET_FULFILLED:
            return {
                ...state,
                dashboardInfo: action.payload.dashboardInfo
            }
        case actions.APP_OBJECT_LIST_GET_FULFILLED:
            return {
                ...state,
                objectList: action.payload.objects
            }
        case actions.SCHEDULER_SLUG_GET_FULFILLED:
            return {
                ...state,
                appointment_scheduler_slug: action.payload.slug
            }
        case actions.APP_UPDATE_CURRENCY:
            return {
                ...state,
                me: { ...state.me, currency: action.payload }
            }
        case actions.UPDATE_QB_SETUP_FLAG:
            return {
                ...state,
                me: { ...state.me, is_qbsetup: action.payload }
            }
        case actions.UPDATE_XERO_SETUP_FLAG:
            return {
                ...state,
                me: { ...state.me, xero_integration: action.payload }
            }
        case actions.GET_APP_HOME_SETUP_FULFILLED:
            return {
                ...state,
                homeSetup: action.payload
            }
        case actions.SET_CALENDAR_SET_UP:
            return {
                ...state,
                me: { ...state.me, calendar_setup: action.payload }
            }
        case actions.APP_EXTERNAL_LINK_MENU_UPDATE:
            return {
                ...state,
                externalMenuLink: action.payload
            }
        case actions.APP_REDIRECT_BACK_FR_LOGIN:
            return {
                ...state,
                selectedModuleInfo: action.payload.selectedModuleInfo,
                selectedModuleName: action.payload.selectedModuleName,
                selectedModuleItems: action.payload.selectedModuleItems,
                isRedirect: true,
                redirectUrl: action.payload.redirectUrl
            }
        case actions.APP_NOTIFY_TEXT_SALESREP_UPDATE:
            return {
                ...state,
                me: { ...state.me, notify_text_salesrep: action.payload }
            }
        case actions.APP_PHONE_TEXT_PROVIDER_UPDATE:
            return {
                ...state,
                me: { ...state.me, phone_text_provider: action.payload }
            }
        case actions.APP_LMS_NODE_UPDATE:
            return {
                ...state,
                me: { ...state.me, is_lms_setup: true, lms_node: action.payload }
            }
        case actions.APP_PHONE_TEXT_MANAGE_UNIT_PHONE_UPDATE:
            return {
                ...state,
                me: { ...state.me, is_manage_unit_phone: action.payload }
            }
        case actions.TOOGLE_MONERIS_CHECKOUT:
            return {
                ...state,
                me: { ...state.me, is_moneris_connected: action.payload }
            }
        default:
            return state;
    }
}

export default appReducer;