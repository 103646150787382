import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "../../../services/constants/styles";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { getAppDrawer } from "../../../services/actions/appContainerActions";
import { constants } from "../../../services/constants/constants";
import { getAppDialog } from "../../../services/actions/appContainerActions";
import {
  refreshDetailViewData,
  refreshDetailViewGroup,
  detailViewIsMounted,
  isDetailViewSet,
} from "../../../services/actions/detailViewActions";
import * as sfDialogs from "../components/sfDialogs";
import { endPoints } from "../../../services/constants/endPoints";
import * as HTTPClient from "../../../services/helper/httpClient";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import FormGroup from "@mui/material/FormGroup";
import { getStringParam } from "../../../services/helper/parameterVerifier";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Checkbox,
  FormControlLabel,
  FormControl,
  MenuItem,
  Icon,
  IconButton,
  InputLabel,
  Select,
} from "@mui/material";
import PopOver from "../components/PopOver";
import {
  isValidParam,
  getArrayParam,
} from "../../../services/helper/parameterVerifier";
import { getAuditCategory } from "../../../services/actions/auditActions";
import AuditCheckListCard from "./AuditCheckListCard";
import { updateAuditChecklistcategories } from "../../../services/actions/auditActions";
import ShowCircularProgress from "../components/circularProgress";
import {
  setListViewMounted,
  refreshListView,
} from "../../../services/actions/listViewAcions";
import { getActiveTab,updateActiveTab } from "../../../services/helper/sfTabManager";
const AuditChecklist = ({ object, id, type, data, closeDrawer }) => {
  const dispatch = useDispatch();
  const [auditName, setAuditName] = useState("");
  const [status, setStatus] = useState("");
  const [auditType, setAuditType] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [isNameEmpty, setIsNameEmpty] = useState(false);
  const [isTypeEmpty, setisTypeEmpty] = useState(false);
  const [isStatusEmpty,setIsStatusEmpty] = useState(false);

  const AuditChecklist = useSelector(
    (state) => state.auditDetails?.AuditChecklist
  );

  const TypeList = [
    { id: 2341, name: "Field", label: "Field" },
    { id: 2342, name: "Self", label: "Self" },
  ];

  const StatusList = [
    { id: 2343, name: "Active", label: "Active" },
    { id: 2344, name: "Inactive", label: "Inactive" },
  ];

  useEffect(() => {
    let promise = Promise.resolve(getAuditCategory());
    dispatch(updateAuditChecklistcategories([]));
    if (isValidParam(promise)) {
      promise.then((response) => {
        setCategoryList([...response]);
        setMounted(false);
      });
    }
  }, []);

  useEffect(() => {
    if (id > 0) {
      getAuditCategoryData();
    }
  }, []);

  const getAuditCategoryData = () => {
    setMounted(true);
    let params = {};
    params.id = id;
    const promise = Promise.resolve(
      HTTPClient.get(endPoints.AUDIT_CHECKLIST.GET_AUDIT_CATEGORY_DATA, params)
    );
    if (promise) {
      promise.then((res) => {
        setAuditName(res.form_name);
        let _type = "";
        if (res.t_type === "field") {
          _type = "Field";
        } else if (res.t_type === "self") {
          _type = "Self";
        } else {
          _type = res.t_type;
        }
        setAuditType(_type);
        setStatus(res.t_status);
        dispatch(updateAuditChecklistcategories(res?.list));
        setMounted(false);
      });
    }
  };

  const UpdateAuditCheckList = () => {
    let inputElement = null;
    let msg = "";
    if (!auditName.trim()) {
      setIsNameEmpty(true);
      setAuditName('');
      msg = getLocalizedStrings().message.AUDIT_QUESTIONS.EMPTY_NAME;
      dispatch(
        showCustomSnackBar(
          msg,
          styles.snackbarBodyStyleError,
          styles.snackBarStyleTop
        )
      );
      inputElement = document.getElementById("auditCheckListName");
      if (isValidParam(inputElement) && inputElement != null) {
        if (inputElement != null) {
          inputElement.focus();
        }
      }
    } else if (!auditType) {
      setisTypeEmpty(true);
      msg = getLocalizedStrings().message.AUDIT_CHECKLIST.EMPTY_AUDIT_TYPE;
      dispatch(
        showCustomSnackBar(
          msg,
          styles.snackbarBodyStyleError,
          styles.snackBarStyleTop
        )
      );
      inputElement = document.getElementById("audit_type");
      if (isValidParam(inputElement) && inputElement != null) {
        if (inputElement != null) {
          inputElement.focus();
        }
      }
    } else if(!status){
      setIsStatusEmpty(true);
      msg = getLocalizedStrings().message.AUDIT_CHECKLIST.EMPTY_AUDIT_STATUS;
      dispatch(
        showCustomSnackBar(
          msg,
          styles.snackbarBodyStyleError,
          styles.snackBarStyleTop
        )
      );
      inputElement = document.getElementById("audit_status");
      if (isValidParam(inputElement) && inputElement != null) {
        if (inputElement != null) {
          inputElement.focus();
        }
      }
    }else {
      let params = {};
      params.id = id ? id : 0;
      params.name = auditName.trim();
      params.type = auditType;
      params.status = status;
      let _auditCheckist = resetOrderIds(AuditChecklist);
      params.list = _auditCheckist;
      setMounted(true);
      const promise = Promise.resolve(
        HTTPClient.post(endPoints.AUDIT_CHECKLIST.UPDATE, params)
      );
      if (promise) {
        promise.then((res) => {
          let message;
          if(res?.status === 0){
            if (id) {
              message =
                getLocalizedStrings().message.AUDIT_CHECKLIST
                  .UPDATED_SUCCESSFULLY;
            } else {
              message =
                getLocalizedStrings().message.AUDIT_CHECKLIST.SAVED_SUCCESSFULLY;
            }
            dispatch(
              showCustomSnackBar(
                message,
                styles.snackbarBodyStyleSuccess,
                styles.snackBarStyleTop
              )
            );
            let activeTab = getActiveTab();
            activeTab.info.selectedPageNo = 1;
            updateActiveTab(activeTab);
            setListViewMounted(false, object);
            refreshListView(object);
            setMounted(false);
            closeDrawer();
          }else{
            message = res?.error?.message;
            dispatch(
            showCustomSnackBar(
              message,
              styles.snackbarBodyStyleError,
              styles.snackBarStyleTop
            )
            );
            setMounted(false);
          }
        });
      }
    }
  };

  function resetOrderIds(data) {
    // Iterate over each category
    let count = 0;
    let c = 0;
    data.forEach((category) => {
      // Set category order_id to undefined
      category.order_id = ++count;
      c = 0;
      // Iterate over each question in the category
      category.questions.forEach((question) => {
        // Set question order_id to undefined
        question.order_id = ++c;
      });
    });
    return data;
  }

  const handleOnChangeName = (event) => {
    let value = event.target.value;
    setAuditName(value);
    setIsNameEmpty(false);
  };

  const handleOnChange = (event, actionType) => {
    let value = event.target.value;
    if (actionType === "type") {
      setAuditType(value);
      setisTypeEmpty(false);
    } else {
      setStatus(value);
      setIsStatusEmpty(false);
    }
  };

  const getTypeList = () => {
    let menuItems = [];
    let afList = TypeList;
    if (afList !== null && afList?.length > 0) {
      afList.map((fieldOption, i) => {
        menuItems.push(
          <MenuItem
            value={fieldOption.name}
            id={fieldOption.id}
            title={fieldOption.name}
            key={fieldOption.id}
            style={styles.popoverMenuItem}
          >
            {fieldOption.label}{" "}
          </MenuItem>
        );
      });
    }
    return menuItems;
  };
  const getStatusList = () => {
    let menuItems = [];
    let afList = StatusList;
    if (afList !== null && afList?.length > 0) {
      afList.map((fieldOption, i) => {
        menuItems.push(
          <MenuItem
            value={fieldOption.name}
            id={fieldOption.id}
            title={fieldOption.name}
            key={fieldOption.id}
            style={styles.popoverMenuItem}
          >
            {fieldOption.label}{" "}
          </MenuItem>
        );
      });
    }
    return menuItems;
  };

  const menuOnChange = (value, event) => {
    let _AuditChecklist = AuditChecklist;
    const isCategoryAlreadySelected = _AuditChecklist?.filter((ele) => {
      return ele.t_category_name === value;
    });
    if (isCategoryAlreadySelected.length === 0) {
      let selectedCategory = categoryList.filter((ele) => {
        return ele.t_category_name === value;
      });
      let params = {};
      params.id = 0;
      params.group_by_condition = selectedCategory[0]?.t_category_name;
      params.query_name = "All Audit Questions";
      params.query_type = "query";
      params.page_size = 50;
      const promise = Promise.resolve(
        HTTPClient.get(endPoints.AUDIT_QUESTIONS.LIST_VIEW, params)
      );
      if (promise) {
        promise.then((res) => {
          selectedCategory[0].questions = res?.records;
          selectedCategory?.map((ele) => {
            ele.master_id = ele.t_pk;
            ele?.questions.map((item) => {
              item.master_id = item.id;
            });
          });
          let _categorydndList = AuditChecklist;
          _categorydndList = [..._categorydndList, ...selectedCategory];
          dispatch(updateAuditChecklistcategories(_categorydndList));
        });
      }
    } else {
      let message =
        getLocalizedStrings().message.AUDIT_CHECKLIST
          .ALREADY_SELECTED_CTEAGORY_VALIDATION;
      dispatch(
        showCustomSnackBar(
          message,
          styles.snackbarBodyStyleError,
          styles.snackBarStyleTop
        )
      );
    }
  };

  const getSelectedCategory = () => {
    let element = null;
    let fieldItems = [...categoryList];
    try {
      let popOverList = [];
      fieldItems.forEach((item, index) => {
        if (isValidParam(item)) {
          let data = {};
          data.label = item.t_category_name;
          data.value = item.t_category_name;
          data.order_id = item.order_id;
          data.t_pk = item.t_pk;
          data.t_projectid = item.t_projectid;
          data.t_status = item.t_status;
          data.t_timestamp = item.t_timestamp;
          popOverList.push(data);
        }
      });
      element = (
        <div title={getLocalizedStrings().message.SMS_INTEGRATION.MERGE_FIELD}>
          <div
            style={{
              marginRight: 5,
              width: "8rem",
              paddingLeft: "5px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            <PopOver
              id={"phoneTemplate"}
              key={"phoneTemplate"}
              name={"phoneTemplate"}
              title={"Select Category"}
              buttonEndIcon={""}
              buttonStyle={{
                color: "none",
                marginTop: "0px",
                fontSize: 14,
                width: "8.8rem",
                textDecoration: "underline",
              }}
              labelposition={"before"}
              buttonLabel={"Select Category"}
              btnViewType={"text"}
              options={popOverList}
              onclickAction={(value, event) => menuOnChange(value, event)}
              isShowIcon={false}
              buttonLineOrigin={[28, 145]}
            />
          </div>
        </div>
      );
    } catch (error) {
      console.error("Error in 'SmsTemplate.js -> openfields()':" + error);
    }

    return element;
  };

  const renderCard = (card, index) => {
    return (
      <AuditCheckListCard
        key={card.master_id}
        index={index}
        id={card.master_id}
        card={card}
      />
    );
  };

  const getAuditChecklistFilter = () => {
    let element = (
      <>
        <div
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            float: "right",
            justifyContent: "right",
            marginTop: "20px",
          }}
          className="save_section"
        >
          <Button
            onClick={() => UpdateAuditCheckList()}
            style={styles.primaryButton}
          >
            {getLocalizedStrings().label.COMMON.SAVE}
          </Button>
          <Button
            onClick={() => {
              closeDrawer();
            }}
            style={styles.secondaryButton}
          >
            {getLocalizedStrings().label.COMMON.CANCEL}
          </Button>
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "inline",
            marginLeft: "20px",
            marginTop: "15px",
          }}
          className="audit_fields"
        >
          <div style={{ width: "100%", height: "fit-content" }}>
            <FormControl
              style={{ width: "100%" }}
              className="test"
              noValidate
              autoComplete="off"
            >
              <TextField
                variant="outlined"
                label={getLocalizedStrings().label.COMMON.NAME}
                id="auditCheckListName"
                name="auditCheckListName"
                error={isNameEmpty}
                autoFocus={true}
                value={auditName}
                onChange={(e) => handleOnChangeName(e)}
                autoComplete="new-password"
                inputProps={{
                  maxLength: '125',
                }}
                style={{
                  zIndex: 0,
                  width: "97%",
                  marginLeft: "1rem",
                  marginRight: "1rem",
                }}
                margin="dense"
                size="small"
                className={"sf-fields-bg"}
              />
            </FormControl>
          </div>
          <div
            style={{ width: "100%", height: "fit-content", display: "flex" }}
          >
            <div className="type" style={{ width: "50%", marginLeft: "1rem" }}>
              <FormControl
                style={{ width: "100%", height: "38px", marginTop: "15px" }}
                variant="outlined"
              >
                <InputLabel
                  id="sf-add-units-from-simple-select-outlined-label"
                  className="sf-add-units-plan-name"
                  style={{ lineHeight: "0.9" }}
                >
                  {getLocalizedStrings().message.AUDIT_CHECKLIST.TYPE}
                </InputLabel>
                <Select
                  key={"Type"}
                  id={"audit_type"}
                  label="Type"
                  value={auditType}
                  error={isTypeEmpty}
                  onChange={(e) => handleOnChange(e, "type")}
                  style={{ height: "40px", marginTop: "2px" }}
                  className={"sf-fields-bg"}
                  variant="outlined"
                >
                  {getTypeList()}
                </Select>
              </FormControl>
            </div>
            <div
              className="status"
              style={{ width: "50%", marginLeft: "10px" }}
            >
              <FormControl
                style={{ width: "100%", height: "38px", marginTop: "15px" }}
                variant="outlined"
              >
                <InputLabel
                  id="sf-add-units-from-simple-select-outlined-label"
                  className="sf-add-units-plan-name"
                  style={{ lineHeight: "0.9" }}
                >
                  {getLocalizedStrings().message.AUDIT_CHECKLIST.STATUS}
                </InputLabel>
                <Select
                  key={"Type"}
                  id={"audit_status"}
                  label="Type"
                  value={status}
                  error={isStatusEmpty}
                  onChange={(e) => handleOnChange(e, "status")}
                  style={{ height: "40px", marginTop: "2px" }}
                  className={"sf-fields-bg"}
                  variant="outlined"
                >
                  {getStatusList()}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <div
          className="select_category"
          style={{
            width: "fit-content",
            height: "fit-content",
            float: "right",
            marginTop: "20px",
          }}
        >
          {getSelectedCategory()}
        </div>
        <div
          className="checklist_dnd"
          style={{
            marginLeft: "1rem",
            marginBottom: "20px",
            height: "fit-content",
          }}
        >
          {AuditChecklist?.map((card, i) => renderCard(card, i))}
        </div>
      </>
    );
    return element;
  };

  let tempStyle = {
    marginLeft: 42,
    width: "96%",
    paddingTop: 5,
    paddingLeft: 11,
  };
  let contentHeight = window.innerHeight - 240;
  let top = (contentHeight - 10) / 2;

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {!mounted ? (
        <div style={{ paddingBottom: "20px", overflow: "scroll" }}>
          {getAuditChecklistFilter()}
        </div>
      ) : (
        <div style={{ width: "100%", height: contentHeight }}>
          <div
            className="asset-loaderh"
            style={{ paddingTop: top, paddingLeft: "48%" }}
          >
            <div
              style={{
                ...styles.assetLoaderContainer,
                height: 50,
                width: 50,
                padding: 7,
              }}
            >
              <ShowCircularProgress
                size={30}
                style={{ marginTop: "3", marginLeft: "3" }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuditChecklist;
