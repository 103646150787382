import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ShowCircularProgress from './circularProgress';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {endPoints} from "../../../services/constants/endPoints";
import * as HTTPClient from "../../../services/helper/httpClient";
import { IconButton } from '@mui/material';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import DeleteIcon from '@mui/icons-material/Delete';
import {updateAuditPhotos,updateAuditAnswers} from '../../../services/actions/auditActions';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { styles } from '../../../services/constants/styles';

const MobileAuditAttachments = ({data}) => {
    const dispatch = useDispatch();
    const { answers, photos } = useSelector((state) => state.auditDetails);
    const { question, encodedParams } = data;
    const appContainer = useSelector((state)=> state.appContainer);
    const [isLoading, setIsLoading] = useState(false)
    const [attachFiles, setAttachFiles] = useState([])

    useEffect(()=>{
        getImagesData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    


    const getImagesData = () => {
        setIsLoading(true)
        const masterId = question?.master_id;
        const answerIds = getAnswerIdsByMasterId(masterId);
        let answer_id = answerIds.length > 0 ? answerIds[0] : 0;

        const response = Promise.resolve(HTTPClient.getWithoutToken(`${endPoints.AUDITS.AUDIT_MOBILE_ATTACMENT}/${answer_id}`, {
            encodedParams: encodedParams,
        }));
        response.then((response) => {
            setAttachFiles(response)
            setIsLoading(false)
        })
    }


    function getAnswerIdsByMasterId(masterId) {
        let answerIds = [];

        // Loop through all categories in the data
        for (const categoryName in answers) {
            // Get the list of questions in this category
            const category = answers[categoryName];

            // Filter questions by master_id and collect answer_ids
            const filteredAnswerIds = category
                .filter(question => question.master_id === masterId)
                .map(question => question.answer_id);

            // Append the filtered answer_ids to the result array
            answerIds = answerIds.concat(filteredAnswerIds);
        }

        return answerIds;
    }


    const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        lazyLoad: true,
        arrows: attachFiles.length > 1 ? true : false,
    };

    const handleDeleteImage = (image,index)  =>{
        const masterId = question?.master_id;
        const answerIds = getAnswerIdsByMasterId(masterId);
        let answer_id = answerIds.length > 0 ? answerIds[0] : 0;

        let _attachFiles = [...attachFiles];
        _attachFiles.splice(index, 1);
        setAttachFiles([..._attachFiles]);
        const tempPhotos = { ...photos };
        let question_id = question.questions_id;
        let _photos = tempPhotos[question_id];
        _photos?.splice(index, 1);
        tempPhotos[question_id] = _photos;
        const response = Promise.resolve(HTTPClient.postWithoutToken(endPoints.AUDIT_ANSWERS.DELETE_AUDIT_ANSWER_PHOTO, {
            encodedParams: data.encodedParams,
            file_name : image.file_name,
            id : image.id,
            answer_id : answer_id,
        }));
        response.then((response) => {
            dispatch(updateAuditPhotos(tempPhotos));
            let auditCategory = data.question.audit_category_name;
            let QuestionDetails= {};
            QuestionDetails.image = image;
            QuestionDetails.index = index;
            QuestionDetails.question_id = question_id;
            QuestionDetails.SelectedAuditCategory = auditCategory;
            appContainer.dialogProps.eventName(QuestionDetails);
            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
        })
        
    }

    if(isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <ShowCircularProgress size={30} />
            </div>
        );
    }

    if (attachFiles.length === 0) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                <span style={{fontSize: '16px'}}>No Images Found</span>
            </div>
        )
    }

    return (
        <div className="audit-slider-container">
            <Slider {...settings}>
                {Array.isArray(attachFiles) && attachFiles.map((image, index) => (
                    <div key={index} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',height:'fit-content' }}>
                        <div style={{ maxWidth: '90%', margin: '0 auto' }}>
                            <img src={image.url ? image.url : image} alt={`${image.file_name}`} height={'250'} width={'250'}/>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
                                <IconButton title={getLocalizedStrings().label.COMMON.REMOVE} onClick={() => handleDeleteImage(image,index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default MobileAuditAttachments;