import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { setAppDialogActions, getAppDialog } from '../../../services/actions/appContainerActions';
import { styles } from '../../../services/constants/styles';
import { dateTimeFormat, timeZone, dateTimeFormatFlatPicker, getDateObject } from '../../../services/helper/utils';
import moment from 'moment';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import PopOver from './PopOver';
import { getStringParam } from '../../../services/helper/parameterVerifier';
import { useDispatch, useSelector } from "react-redux";

const SFScheduleDialog = ({ }) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const appContainer = useSelector((state) => state.appContainer);

    const [schedule_date, setScheduleDate] = useState(moment(getStringParam(new Date())).add(1, 'days').format(dateTimeFormat[app.me.date_format]));
    const [schedule_date_error, setScheduleDateError] = useState('');

    useEffect(() => {
        dispatch(setAppDialogActions(getActions()));
    }, [schedule_date]);

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    const handleSchedule = () => {
        if (schedule_date.length === 0) {
            setScheduleDateError(getLocalizedStrings().message.SCHEDULE.EMPTY);
            return false;
        }
        let obj = { date: moment(schedule_date) };
        appContainer.dialogProps.eventName(obj);
    }


    const handleChange = (date) => {
        setScheduleDate(date);
        if (date.length > 0) {
            setScheduleDateError('');
        }
    }

    const getActions = () => {
        const actions = [
            <Button
                key={'schedule'}
                primary={'true'}
                onClick={() => handleSchedule()}
                style={{...styles.primaryButton2 , backgroundColor : app.me.background}}
            >{getLocalizedStrings().label.CAMPAIGNS.SCHEDULE}</Button>,
            <Button
                key={'cancel'}
                primary={'true'}
                onClick={() => handleClose()}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>,

        ];
        return actions;
    }


    let HHFormat = new RegExp("HH");
    let datePickerOptions = {
        enableTime: true,
        dateFormat: dateTimeFormatFlatPicker[app.me.date_format],
        minuteIncrement: 1,
        time_24hr: HHFormat.test(app.me.date_format) ? true : false,
        minDate: "today"
    };

    return (
        <div style={{ paddingRight: 100 }}>
            <div className="row">
                <div className="col-sm-8">
                    <PopOver
                        id={'dateTime'}
                        key={'dateTime'}
                        btnType={'dateTime'}
                        buttonStyle={{ fontSize: '16px' }}
                        containerStyle={{}}
                        onChange={(date) => handleChange(date)}
                        value={schedule_date}
                        innerLabelStyle={{ lineHeight:'13px', fontSize: '15px' , padding:'10px'}}
                        height={38}
                        options={datePickerOptions}
                    />
                    <span style={{ fontSize: '12px', color: '#FF0000', marginTop: 5, display: 'block' }}>{schedule_date_error}</span>
                </div>

                <div className="col-sm-4">

                </div>
            </div>
        </div>
    );
};

export default SFScheduleDialog;