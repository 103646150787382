
import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { styles } from '../../../services/constants/styles';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { Button, Menu, MenuItem, Icon} from '@mui/material';
import { isValidParam, getArrayParam, getStringParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { getAppDrawer } from '../../../services/actions/appContainerActions';
import { refreshDetailViewListViewData } from '../../../services/actions/detailViewActions';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import ShowCircularProgress from '../components/circularProgress';
import SuperSelectField from '../components/sfSuperSelect';
import AsyncSelect from 'react-select/async';
import { useDispatch, useSelector } from "react-redux";

const RelationshipDrawerStyle  = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold' 
  };

const Relationship = ({ id,object, closeDrawer}) => {
    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const detailView = useSelector((state) => state.detailView);
    const sfForm = useSelector((state) => state.sfForm);

    const [openList, setOpenList] = useState(false);
    const [selectedSearchFieldName, setSelectedSearchFieldName] = useState('Company')
    const [selectedLeadsValue, setSelectedLeadsValue] = useState('')
    const [selectedLeads, setSelectedLeads] = useState(null)
    const [relationList, setRelationList] = useState([])
    const [selectedParentRelation, setSelectedParentRelation] = useState([])
    const [selectedChildRelation, setSelectedChildRelation] = useState([])
    const [mounted, setMounted] = useState(false)
    const [options, setOptions] = useState([])
    const [anchorEl, setAnchorEl] = useState()
    const childListView = useSelector((state) => state.childListView);

    useEffect(()=>{
        getRelationshipIdsList();
        loadRelationList();
    },[])

    const showSearchFieldsPopup = (event) => {
        event.preventDefault();
        setOpenList(true)
        setAnchorEl(event.currentTarget)
    }

    const closeSearchFieldsPopup = () => {
        setOpenList(false);
    };

    const onChangeSearchField = (value, event) => {
        setSelectedSearchFieldName(value)
        setOpenList(false)
        setSelectedLeadsValue('')
    }

    const getOptions = (input) => {
        try{
            if (!input || (input && input.length < 1)) {
                return Promise.resolve( [] );
            }
            let params = {};
            params.tableId = 10;
            params.parentRecordId = detailView.record.id;
            params.searchFieldName = selectedSearchFieldName;
            params.relatedRecordText = input;
            params.start = 0;
            params.fetchSize = 20;
            return HTTPClient.get(endPoints.ACCOUNTS.RELATED_RECORD_LIST_GET, params)
                .then((response) => {
                    let options = [];
                    response.forEach(function (data) {
                        options.push(
                            {
                                id: data.id,
                                label: data.value,
                                value: data.value
                            }
                        );

                    });
                    setOptions(options)

                    return options;
                });
        } catch (error) {
            console.error("error in getOptions:::" + error)
        }
    }

    const onChange = (obj) => {
        try{
            setSelectedLeadsValue(obj.label)
            setSelectedLeads(obj)
        }catch(error){

            console.error("error in onChange:::" + error)
        }
    }
   
    const loadRelationList = () => {
        let params = {};
        let options = [];
        params.fields = ["t_relation_name", "t_tstamp"];
        HTTPClient.get(endPoints.RELATIONSHIP.RELATIONSHIP_INFO, params)
            .then((response) => {
                let relArr = response.records;
                relArr.forEach(function (data) {
                    options.push(
                        {
                            value: data.id,
                            label: data.t_relation_name

                        });
                });
                setRelationList(options)
            });
    }

    const onChangeRelation = (values, name) => {
        setSelectedParentRelation(values)
    }

    const onChangeRelationChild = (values, name) => {
        setSelectedChildRelation(values)
    }

    const getRelationshipIdsList = () => {
        if (id !== null && id > 0) {
            let params = {};
            params.tableId = 10;
            params.parentRecordId = detailView.record.id;
            params.reletedRecordId = id;
            var parentRelList = [];
            var childRelList = [];
            HTTPClient.get(endPoints.ACCOUNTS.RELATIONSHIP_RECORDID_LIST_GET, params)
                .then((response) => {
                    if (isValidParam(response)) {
                        let relationObj = null;
                        if (isValidParam(response.parent_relation_id)) {
                            response.parent_relation_id.map(function (obj) {
                                relationObj = {};
                                Object.keys(obj).map((itemKey) => {
                                    relationObj.value = parseInt(itemKey);
                                    relationObj.label = obj[itemKey];
                                    parentRelList.push(relationObj);
                                });

                            });
                        }

                        if (isValidParam(response.child_relation_id)) {
                            response.child_relation_id.map(function (obj) {
                                relationObj = {};
                                Object.keys(obj).map((itemKey) => {
                                    relationObj.value = parseInt(itemKey);
                                    relationObj.label = obj[itemKey];
                                    childRelList.push(relationObj);
                                });
                            });
                        }

                        let selectedLead = {};
                        selectedLead.id = id
                        
                        setSelectedLeads(selectedLead)

                        if (isValidParam(response.company_title) && getArrayParam(response.company_title).length > 0) {
                            Object.values(response.company_title[0]).map((CompanyTitle) => {
                                setSelectedLeadsValue(CompanyTitle)
                                selectedLead.value = CompanyTitle
                                selectedLead.label = CompanyTitle
                                setSelectedLeads(selectedLead)


                            });
                        }
                        setSelectedParentRelation(parentRelList)
                        setSelectedChildRelation(childRelList)
                    }
                    setMounted(true)
                });
        } else {
            setMounted(true);
        }
    }

    const saveRelation = () => {
        if (relationList !== null && relationList.length > 0) {
            let isValidForm = true;
            if (!(isValidParam(selectedLeadsValue) && selectedLeadsValue !== '')) {
                isValidForm = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.RELATIONSHIP.BLANK_RECORD + " " + document.getElementById('detailviewHeaderId')?.title + ".", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            if (isValidForm && !(isValidParam(selectedParentRelation) && Array.isArray(selectedParentRelation) && selectedParentRelation.length > 0)) {
                isValidForm = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.RELATIONSHIP.BLANK_PARENT_RELATION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
            if (isValidForm && !(isValidParam(selectedChildRelation) && Array.isArray(selectedChildRelation) && selectedChildRelation.length > 0)) {
                isValidForm = false;
                dispatch(showCustomSnackBar(getLocalizedStrings().message.RELATIONSHIP.BLANK_CHILD_RELATION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }

            if (isValidForm) {
                let mode = 'New';
                if (id !== null && id > 0) {
                    mode = 'Edit';
                }
               
                let params = {};
                params.tableId = 10;
                params.parentRecordId = detailView.record.id;
                params.reletedRecordId = id !== null && id > 0 ? id : selectedLeads.id;
                params.mode = mode;
                let parentRelatedIds = '';
                selectedParentRelation.map(function (data) {
                    parentRelatedIds += data.value + ',';
                });
                if (parentRelatedIds !== '') {
                    parentRelatedIds = $.trim(parentRelatedIds.substring(0, parentRelatedIds.lastIndexOf(",")));
                }
                let childRelatedIds = '';
                selectedChildRelation.map(function (data) {
                    childRelatedIds += data.value + ',';
                });
                if (childRelatedIds !== '') {
                    childRelatedIds = $.trim(childRelatedIds.substring(0, childRelatedIds.lastIndexOf(",")));
                }

                params.parentRelationIds = parentRelatedIds;
                params.childRelationIds = childRelatedIds;

                HTTPClient.post(endPoints.ACCOUNTS.RELATED_RECORD_SAVE, params)
                    .then((response) => {
                        if (isValidParam(response) && response.status === 0) {
                            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            dispatch(getAppDrawer(false, null, null, null, null));
                            //Detail View Listview Refresh
                            dispatch(refreshDetailViewListViewData(true));
                        }
                    });
            }
        } else {
            if (app.me.is_tenant) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.RELATIONSHIP.NO_PARENT_RELATION, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.RELATIONSHIP.NO_PARENT_RELATION_FOR_USER, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }
        }
    }

    const closeDrawerFun = () => {
        closeDrawer();
    }

    const getAsyncHtmlAutocomplete = (placeholderText) => {
       
        let htmlContent = <AsyncSelect
            name="releatedSearch"
            id="releatedSearch"
            key="releatedSearch"
            value={selectedLeads}
            getOptionLabel={e => e.label}
            getOptionValue={e => e.value}
            loadOptions={getOptions}
            onChange={onChange}
            backspaceRemoves={true}
            clearable={false}
            searchPromptText={placeholderText}
            placeholder={placeholderText}
            components={{
                IndicatorSeparator: () => null
              }}
        />
        return htmlContent;
    }

    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight-10) / 2;
    if (mounted) {
        let parentRecTitle = '';
        let placeholderText = '';
        let editMode = false;
        let searchFieldLabel = '';
        let companyLabel = '';

        if (id !== null && id > 0) {
            editMode = true;
        }

        parentRecTitle = document.getElementById('detailviewHeaderId')?.title;

        var showRelationList = relationList;
        if (showRelationList !== null && showRelationList.length > 0) {
        }
        if (isValidParam(sfForm.data['accounts'])) {
            let companyLabelObj = sfForm.data['accounts'].fields.filter((obj) => { return obj.name === 'company' });
            companyLabel = getStringParam(companyLabelObj[0].label);
        }


        if (selectedLeadsValue !== null && selectedLeadsValue.length > 0) {
            placeholderText = selectedLeadsValue;
        } else {
            if (selectedSearchFieldName === 'Company') {
                placeholderText = 'Type ' + companyLabel + ' and press enter';
                        }
            else if (selectedSearchFieldName === 'Full Name') {
                placeholderText = getLocalizedStrings().message.RELATIONSHIP.PLACEHOLDERTEXT_NAME;
            }
            else if (selectedSearchFieldName === 'Email') {
                placeholderText = getLocalizedStrings().message.RELATIONSHIP.PLACEHOLDERTEXT_EMAIL;
            }
        }

        if (selectedSearchFieldName === 'Company') {
            searchFieldLabel = companyLabel;
        }
        else if (selectedSearchFieldName === 'Full Name') {
            searchFieldLabel = getLocalizedStrings().label.RELATIONSHIP.FULL_NAME;
        }
        else if (selectedSearchFieldName === 'Email') {
            searchFieldLabel = getLocalizedStrings().label.RELATIONSHIP.EMAIL;
        }
        let width = '100%';
        if (document.getElementById("RELATIONSHIP_DRAWER") != null) {
          width = document.getElementById("RELATIONSHIP_DRAWER")?.offsetWidth;
        };
        return (
            <div id='RELATIONSHIP_DRAWER' style={{...styles.sfFormContainer,marginLeft:'13px',height:'0%', marginLeft:'25px'}} >
            <div className="row" style={{ marginTop: '10px', marginBottom: '10px', height: '28px' ,marginLeft:'-16px'}}>
                <div className="col-sm-12" style={{ fontWeight: 'bold' }}>
                    {getLocalizedStrings().label.RELATIONSHIP.RELATE} <span style={{ fontWeight: '400' }} title={parentRecTitle}>{parentRecTitle}</span> {getLocalizedStrings().label.COMMON.TO}
                </div>

            </div>
            <div className="row" style={{ marginTop: '10px', marginBottom: '10px', height: '28px' }}>
                <div className="col-sm-12" style={{ marginLeft: editMode ? '26px': '-18px' , width : editMode ? '92%': '100%' }}>
                    {!editMode &&
                        <table style={{ width: '520px' }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: '17%', fontSize: '14px', color: '#666' }}>
                                        <div style={{ width: '120px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={searchFieldLabel}> {searchFieldLabel} </div>
                                    </td>
                                    <td >
                                        <Icon style={{ top: "8px", float: 'right', display: 'inline', cursor: 'pointer' }} onClick={showSearchFieldsPopup}>keyboard_arrow_down</Icon>
                                        <Menu
                                            open={openList}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                                            onClose={closeSearchFieldsPopup}
                                            value={searchFieldLabel}
                                        >
                                            <MenuItem
                                                key='Company'
                                                value='Company'
                                                style={styles.popoverMenuItem}
                                                fullWidth={true}
                                                onClick={()=>onChangeSearchField('Company')}
                                            >{companyLabel}
                                            </MenuItem>
                                            <MenuItem
                                                key='Full Name'
                                                value='Full Name'
                                                style={styles.popoverMenuItem}
                                                fullWidth={true}
                                                onClick={()=>onChangeSearchField('Full Name')}
                                            >
                                                {getLocalizedStrings().label.RELATIONSHIP.FULL_NAME}
                                            </MenuItem>
                                            <MenuItem
                                                key='Email'
                                                value='Email'
                                                style={styles.popoverMenuItem}
                                                fullWidth={true}
                                                onClick={()=>onChangeSearchField('Email')}
                                            >
                                                {getLocalizedStrings().label.RELATIONSHIP.EMAIL}
                                            </MenuItem>
                                        </Menu>
                                    </td>
                                    <td style={{ width: '80%' }}>

                                        <div style={{ fontSize: '14px' }}>
                                             {getAsyncHtmlAutocomplete(placeholderText)} 
                                        </div>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    }
                    {editMode &&
                        <div style={{ fontSize: '14px' }}>
                           

                        <AsyncSelect
                            name="releatedSearch"
                            id="releatedSearch"
                            key="releatedSearch"
                            value={selectedLeads}
                            getOptionLabel={e => e.label}
                            getOptionValue={e => e.value}
                            loadOptions={getOptions}
                            onChange={onChange}
                            backspaceRemoves={true}
                            clearable={false}
                            searchPromptText={placeholderText}
                            placeholder={placeholderText}
                            components={{
                                IndicatorSeparator: () => null
                              }}
                        />
                        </div>
                    }
                </div>
            </div>
            <div className="row" style={{ marginTop: '22px', marginBottom: '10px', height: '28px',marginLeft:'-15px' }}>
                <div className="col-sm-12" style={{ ...RelationshipDrawerStyle,width:width}}>
                    {parentRecTitle}
                </div>
            </div>
            <div className="row" style={{ marginTop: '10px', marginBottom: '10px', height: '28px',marginRight:'18px' }}>
                {showRelationList !== null && showRelationList.length > 0 &&
                    <div className='col-sm-12 no-line '>
                        <SuperSelectField
                            name='parentRelationId'
                            id='parentRelationId'
                            key='parentRelationId'
                            multiple
                            colorBox={false}
                            options={relationList}
                            checkPosition='left'
                            onChange={onChangeRelation}
                            value={selectedParentRelation}
                            floatingLabelStyle={{ display: 'none' }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            buttonStyle={{ float: 'right', backgroundColor: '#fff', border: '1px solid #e0e0e0', padding: '1px 8px', borderRadius: '3px', color: '#616161', lineHeight: 2.5 }}
                            downArrowStyle={{ marginTop: '7px' }}
                            width={470}
                            height={45}
                            showAutocompleteThreshold={0}
                        />
                    </div>
                }
                {showRelationList !== null && showRelationList.length === 0 &&
                    <div className='col-sm-12 no-line '>{getLocalizedStrings().message.RELATIONSHIP.NO_RELATIONSHIP_SETUP}</div>
                }
            </div>

            <div className="row" style={{ marginTop: '22px', marginBottom: '10px', height: '28px',marginLeft:'-16px' }}>
                <div className="col-sm-12" style={{ ...RelationshipDrawerStyle,width:width }}>
                    {selectedLeads !== null ? selectedLeads.value : 'Accounts'}
                </div>
            </div>
            <div className="row" style={{ marginTop: '10px', marginBottom: '10px', height: '28px',marginRight:'18px' }}>
                {showRelationList !== null && showRelationList.length > 0 &&
                    <div className='col-sm-12 no-line '>
                        <SuperSelectField
                            name='childRelationId'
                            id='childRelationId'
                            key='childRelationId'
                            multiple
                            colorBox={false}
                            options={relationList}
                            checkPosition='left'
                            onChange={onChangeRelationChild}
                            value={selectedChildRelation}
                            floatingLabelStyle={{ display: 'none' }}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            buttonStyle={{ float: 'right', backgroundColor: '#fff', border: '1px solid #e0e0e0', padding: '1px 8px', borderRadius: '3px', color: '#616161', lineHeight: 2.5 }}
                            downArrowStyle={{ marginTop: '7px' }}
                            width={470}
                            height={45}
                            showAutocompleteThreshold={0}
                        />
                    </div>
                }
                {showRelationList !== null && showRelationList.length === 0 &&
                    <div className='col-sm-12 no-line '>{getLocalizedStrings().message.RELATIONSHIP.NO_RELATIONSHIP_SETUP}</div>
                }
            </div>
            <div className="row" style={{ marginTop: '39px', marginBottom: '10px', height: '28px',marginRight:'18px' }}>
                <div className="col-sm-12" style={{ textAlign: 'right' }}>
                    <Button
                        key='save'
                        onClick={saveRelation}

                        style={{ ...styles.primaryButton, display: 'inline-grid', marginLeft: '6px', marginRight: '0px',lineHeight: "17px", }}
                    >{getLocalizedStrings().label.COMMON.OK}</Button>
                    <Button
                        key='cancel'
                        onClick={closeDrawerFun}

                        style={{ ...styles.secondaryButton, display: 'inline-grid', marginLeft: '6px', marginRight: '0px',lineHeight: "17px", }}
                    >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                </div>
            </div>
            </div>
        );
    } else {
        return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
       );
    }
}

export default Relationship; 
