import React, { useEffect, useState } from "react";
import $ from "jquery";
import { Step, Stepper, StepLabel, StepButton } from "@mui/material";
import * as sfDialogs from "../components/sfDialogs";
import {
	getAppDrawer,
	getAppCustomDrawer,
} from "../../../services/actions/appContainerActions";
import {
	isValidParam,
	getBooleanParam,
	getStringParam,
	getObjectParam,
} from "../../../services/helper/parameterVerifier";
import { constants } from "../../../services/constants/constants";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import { getActiveTabInfo } from "../../../services/helper/sfTabManager";
import { styles } from "../../../services/constants/styles";
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";
import { useDispatch, useSelector } from "react-redux";
const _ = require('lodash');

const DetailViewWorkflowStages = ({ workflowProps, object, mounted }) => {
	const dispatch = useDispatch();
	const app = useSelector((state) => state.app);
	const detailView = useSelector((state) => state.detailView);
	const customDrawer = useSelector((state) => state.appContainer.customDrawerProps);

	const [stepIndex, setStepIndex] = useState(0);
	const [workflowData, setWorkflowData] = useState(0);
	const [isWorkflowStatusMounted, setIsWorkflowStatusMounted] = useState(false);
	const [stapes, setStapes] = useState([]);

	useEffect(() => {
		if (!isWorkflowStatusMounted) {
			generateWorkflowData();
		}
	}, [isWorkflowStatusMounted]);
	useEffect(() => {
		if (!_.isEqual(workflowProps, workflowData)) {
			setWorkflowData(workflowProps);
			generateWorkflowData();
		}
	}, [workflowProps]);
	
	useEffect(() => {
		setWorkflowData(workflowProps);
		deletedWorkflowCheck();
		setIsWorkflowStatusMounted(false);
	}, []);

	useEffect(() => {
		let selectedStage = $("#workflowStagesList #" + stepIndex);
		let workflowStagesList = $("#workflowStagesList");
		if (isValidParam(selectedStage) && selectedStage.length === 1) {
			selectedStage = selectedStage[0];
			let scrollPos = $(selectedStage).offset().left + $(selectedStage).outerWidth(true) / 2
				+ workflowStagesList.scrollLeft() - workflowStagesList.width() / 2;
			workflowStagesList.scrollLeft(scrollPos);
		}
	}, [stepIndex]);
	
	
	const generateWorkflowData = () => {
		let selectedModuleName = getStringParam(app.selectedModuleName);
		let workflowStages = [];
		let stepButtonProps = null;
		let objWorkflow = workflowProps.workflow;
		let stages = objWorkflow?.stages||[];
		let themeColor = app.me.background;
		if (isValidParam(stages) && stages.length > 0) {
			let stepIndex = 0;
			let current_stageIndex = stages.findIndex((i) => i === objWorkflow.current_stage)
			console.log("objWorkflow",objWorkflow)
			workflowStages = stages.map((stage, index) => {
				if (stage === objWorkflow.current_stage) {
					stepIndex = index;
					return (
						<Step key={stage + index}>
							<StepLabel
								StepIconProps={{
									style: { color: themeColor },
								}}
							>
								{
									<div
										id={index}
										style={{ color: themeColor, fontWeight: "bold", whiteSpace: 'nowrap' }}
									>
										{stage}
									</div>
								}
							</StepLabel>
						</Step>
					);
				} else if (object === constants.JOBS_OBJECT) {
					return (
						<Step key={stage + index}>
							<StepLabel>
								{
									<div id={index} style={{ color: "#000", whiteSpace: 'nowrap' }}>
										{stage}
									</div>
								}
							</StepLabel>
						</Step>
					);
				} 
				else if ((index < current_stageIndex || index > current_stageIndex+1) && ( objWorkflow.type == "Onboarding" )) {
					stepIndex = index;
					return (
						<Step key={stage + index}>
							<StepLabel
								StepIconProps={{
									style: { color: 'rgba(0, 0, 0, 0.38)' },
								}}
							>
								{
									<div
										id={index}
										style={{ whiteSpace: 'nowrap' , color:'rgba(113, 113, 113)', fontWeight:400}}
									>
										{stage}
									</div>
								}
							</StepLabel>
						</Step>
					);
				} 	
				else {
					stepButtonProps = { value: stage };

					if(selectedModuleName !== constants.MODULE_HUB) {
						// Restrict actionConvert for PARTNERS_OBJECT and is_franchisee is true
						if (object === constants.PARTNERS_OBJECT && app.me.is_franchisee) {
							stepButtonProps.onClick = () => {}; // Disable actionConvert
						} else {
							stepButtonProps.onClick = () => actionConvert(stage);
						}
					} else if (
						selectedModuleName === constants.MODULE_HUB &&
						object === constants.CASES_OBJECT
					) {
						let logedInUSerName = getStringParam(app.me.name);
						let record = getObjectParam(detailView.record);
						let submitter = getStringParam(record['custom_field1']);
						if (
							logedInUSerName === submitter &&
							objWorkflow.current_stage !== "Close"
						) {
							stepButtonProps.onClick = () => actionConvert(stage);
						}
					}
					if (index > stepIndex && stepIndex > 0) {
						return (
							<Step key={stage + index}>
								<StepButton {...stepButtonProps}>
									{
										<div id={index} style={{ color: "#000", whiteSpace: 'nowrap' }}>
											{stage}
										</div>
									}
								</StepButton>
							</Step>
						);
					} else {
						return (
							<Step key={stage + index}>
								<StepButton {...stepButtonProps}>
									{
										<div id={index} style={{ color: "#717171", whiteSpace: 'nowrap' }}>
											{stage}
										</div>
									}
								</StepButton>
							</Step>
						);
					}
				}
			});

			setStepIndex(stepIndex);
			setStapes(workflowStages);
			setIsWorkflowStatusMounted(true);
		}
	};

	const showDivScroll = () => {
		document.getElementById("workflowStagesList").style.overflowX = "auto";
	};

	const hideDivScroll = () => {
		document.getElementById("workflowStagesList").style.overflowX = "hidden";
	};

	const deletedWorkflowCheck = () => {
		if (mounted && isValidParam(workflowProps)) {
			let wfDelete_Status = workflowProps.workflow && workflowProps.workflow['is_workflow_deleted'];
			if (wfDelete_Status) {
				sfDialogs.confirm(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					getLocalizedStrings().message.WORKFLOWS.DELETED_WORKFLOW,
					deletedWorkflowCheckOnConfirm.bind(),
					null
				);
			}
		}
	};

	const deletedWorkflowCheckOnConfirm = () => {
		let objectName = object;
		let value = constants.CONVERT;
		if (isValidParam(objectName)) {
			let labelName = getLocalizedStrings().label.COMMON[value.toUpperCase()];
			let data = {
				wfDelete_Status: workflowProps.workflow['is_workflow_deleted'],
				isDetailviewChild: false,
				object: object,
			};
			dispatch(getAppDrawer(true, labelName, value, data, constants.DETAIL_VIEW));
		}
	};

	
	const actionConvert = (value) => {
		// Issue is here
		let labelName = null;
		let objectName = null;
		let selectedStage = value;
		let isUnitsAccount = false;
		try {
			let tabInfo = getActiveTabInfo();
			let isInXpress = getBooleanParam(tabInfo.isInXpress);
			if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
				isUnitsAccount = tabInfo.isUnitsAccount;
			}
			if (!isInXpress && !isUnitsAccount) {
				objectName = object;
				let value = constants.CONVERT;
				let style = {};
				style.width = "40%";
				if (isValidParam(objectName) && objectName !== constants.CASES_OBJECT) {
					let minimizeList = customDrawer.minimizeList;
					if (
						!minimizeList.hasOwnProperty(constants.CONVERT)
						|| (minimizeList.hasOwnProperty(constants.CONVERT) && !minimizeList.Convert)
					) {
						labelName = getLocalizedStrings().label.COMMON[value.toUpperCase()];
						let data = { stage: selectedStage, object: object };
						dispatch(
							getAppCustomDrawer(
								true,
								labelName,
								value,
								style,
								data,
								null,
								true,
								constants.OTHER
							)
						);
					} else {
						dispatch(
							showCustomSnackBar(
								getLocalizedStrings().message.ACCOUNTS.ALERT_MULTI_DRAWER_OPEN,
								styles.snackbarBodyStyleError,
								styles.snackBarStyleTop
							)
						);
					}
				} else if (isValidParam(objectName) && objectName === constants.CASES_OBJECT) {
					let data = {};
					if (
						app.selectedModuleName === constants.MODULE_HUB &&
						selectedStage === "Close"
					) {
						value = constants.HUB_CASE_CLOSE;
						labelName = getLocalizedStrings().label.COMMON.CLOSE_CASE;

						data.isDetailView = true;
						data.record_id = workflowProps.recordId;
						dispatch(getAppDrawer(true, labelName, value, data, constants.DETAIL_VIEW));
					
					} else {
						labelName = getLocalizedStrings().label.COMMON[value.toUpperCase()];
						data = { stage: selectedStage, object: object };
					dispatch(getAppCustomDrawer(true, labelName, value, style, data, null,true , constants.OTHER));
					
					}
					
				}
			} else {
				let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
				sfDialogs.alert(
					getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
					permissionMessage,
					null
				);
			}
		} catch (error) {
			console.error(
				"Error in 'DetailViewWorkflowStages.js -> actionConvert()':" + error
			);
		}
	};
	return (
		<div onMouseOver={() => showDivScroll()} onMouseOut={() => hideDivScroll()} style={{backgroundColor: 'white'}}>
			{stapes && stapes.length > 0 && (
				<div
					style={{
						width: "100%",
						overflow: "hidden",
						marginTop: "10px",
						marginBottom: "10px",
						borderRadius: "4px",
					}}
					id="workflowStagesList"
				>
					<Stepper nonLinear activeStep={stepIndex} sx={{paddingLeft: '4px',paddingRight:'24px',paddingTop:'24px',paddingBottom:'24px', backgroundColor: '#fff'}}>
						{stapes}
					</Stepper>

				</div>
			)}
		</div>
	);
};

export default DetailViewWorkflowStages;
