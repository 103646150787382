import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import { setHeader } from '../../../services/actions/headerActions';
import {
	detailViewIsMounted,
	getDetailviewData,
	isDetailViewSet,
	refreshDetailViewData, refreshDetailViewListViewData
} from '../../../services/actions/detailViewActions';

import DetailViewHeader from '../components/detailViewHeader';
import TaskQueueHeader from '../components/TaskQueueHeader';

import ShowCircularProgress from '../components/circularProgress';
import DetailViewWorkflowStages from '../components/detailViewWorkflowStages';
import DetailViewFields from '../components/detailViewFields';
import DetailViewChildObjects from '../components/detailViewChildObjects'
import DetailViewTaskApps from '../components/detailViewTaskApps';
import DetailviewGroups from '../components/detailviewGroups';
import { styles } from '../../../services/constants/styles';
import { getActiveTab, getActiveTabInfo, closeTab, updateTabInfo } from '../../../services/helper/sfTabManager';
import {
	getArrayParam,
	getBooleanParam,
	getObjectParam,
	getStringParam,
	isValidParam, getIntParam
} from '../../../services/helper/parameterVerifier';
import { isFranchiseRecord, saveRecentRecord } from '../../../services/helper/common';
import NoRecords from '../components/noRecord';
import { getCacheFromFieldsWithPromise } from '../../../services/actions/sfFormActions';
import { constants, JOB_ACTIONS_ARRY, OBJECT_TABLEID_MAP, QUEUE_LIST } from '../../../services/constants/constants';
import GroupDetailView from '../containers/GroupDetailView';
import { getTasksRefresh } from '../../../services/actions/taskActions';
import { getQueueList, loadQueueList, openDetailviewQueueTab, removeRecordFromRedux } from '../../../services/actions/queueActions';
import $ from "jquery";
import _ from 'lodash';
import { showCustomSnackBar } from "../../../services/actions/snackBarAction";

const createDummyPromise = () => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, 1500);
    });
};


const DetaiLView = () => {
	const dispatch = useDispatch();
	const reduxApp = useSelector((state) => state.app, _.isEqual);
	const reduxAppContainer = useSelector((state) => state.appContainer, _.isEqual);
	const reduxDetailView = useSelector((state) => state.detailView, _.isEqual);
	const reduxQueueList = useSelector((state) => state.queueList, _.isEqual);
	const reduxSfForm = useSelector((state) => state.sfForm, _.isEqual);
	const reduxChildListView = useSelector((state) => state.childListView);

	const data = reduxApp?.redirectUrl?.split("/");
	const props = {
		object: data[1],
		id: data[3],
	};

	const [fieldsData, setFieldsData] = useState([]);
	const [isFranchise, setIsFranchise] = useState(false);
	const [recordId, setRecordId] = useState(0);
	const [iconImage, setIconImage] = useState(null);
	const [changeLog, setchangeLog] = useState({ isFranchise: false, reduxRecords: {} });
	const [isLoading, setIsLoading] = useState(false);

	const detailViewTaskAppintmentReducer = useSelector(
		(state) => state.detailViewTaskAppintmentReducer
	);
	useEffect(() => {
		setScrollPosition(props.object, reduxDetailView);

	}, []);

	useEffect(() => {
		dispatch(detailViewIsMounted(false));
		dispatch(isDetailViewSet(false));
		dispatch(refreshDetailViewData(false));
		if (!props.id) return;
		if (props.object === constants.ACCOUNTS_OBJECT) {
			processFranchiseRecord(props.id);
		}

		let label = null;
		let kanbanObject = {};
		kanbanObject.currentView = null;
		kanbanObject.show = false;

		dispatch(setHeader(props.object, label, kanbanObject, false, false));

		let urlParams = {
			object: props.object,
			recordId: props.id
		}

		let recentRecordParams = {
			recordId: props.id,
			type: "Object"
		}

		dispatch(getDetailviewData(urlParams));

		if (props.id !== recordId) {
			dispatch(isDetailViewSet(true));

			reduxDetailView.mounted = false;
			dispatch(getTasksRefresh(false));
			setRecordId(props.id);
		}

		if (props.object !== constants.GROUP_OBJECT && props.object !== constants.RECYCLE_BIN_OBJECT) {
			saveRecentRecord(props.object, recentRecordParams);
		}
		let data = reduxSfForm.data[props.object];

		if (data) {
			setFieldsData(data.fields);
		} else {
			const promise = getCacheFromFieldsWithPromise(props.object);
			if (promise) {
				promise.then((response) => {
					if (isValidParam(response)) {
						setFieldsData(response.fields);
					}
				});
			}
		}
		let temp = changeLog;
		temp.reduxRecords = reduxDetailView?.record;
		setchangeLog({ ...temp })
		customPromise();
	}, [props.id, props.object, reduxSfForm.data, reduxQueueList?.data?.openedRecordInQueueId]);

	useEffect(() => {
		if (reduxDetailView.isRefresh) {
			let urlParams = {
				object: props.object,
				recordId: props.id
			}
			dispatch(isDetailViewSet(true));
			let tabInfo = getActiveTabInfo();
			let is_callFrom_queue = tabInfo.hasOwnProperty('is_callFrom_queue') ? getBooleanParam(tabInfo.is_callFrom_queue) : false;
			if (props.object !== constants.CONTACTS_OBJECT || is_callFrom_queue == true) {
				dispatch(getDetailviewData(urlParams));
			}
			if (props.object == constants.CONTACTS_OBJECT || is_callFrom_queue == true) {
				dispatch(getDetailviewData(urlParams));
			}
			dispatch(refreshDetailViewData(false));
			if (props.object === constants.ACCOUNTS_OBJECT) {
				processFranchiseRecord(props.id);
			}

		}
	}, [reduxDetailView.isRefresh]);

	useEffect(() => {
		if (reduxChildListView.isRefreshDetailViewListView) {
			dispatch(refreshDetailViewListViewData(false));
		}
	}, [reduxChildListView.isRefreshDetailViewListView, dispatch]);

	useEffect(() => {
		let moduleItems = reduxApp.selectedModuleItems;
		let object = props.object;
		let iconImage = null;
		if (moduleItems) {
			for (let i = 0; i < moduleItems.length; i++) {
				const itemObject = moduleItems[i];
				if (itemObject.object === object && itemObject.object !== constants.COUPONS_OBJECT) {
					iconImage = itemObject.image_name;
					break;
				}
			}

			if (iconImage == null) {
				iconImage = (object === constants.RECYCLE_BIN_OBJECT)
					? reduxApp.home?.objectIconImageMap[constants.ACCOUNTS_OBJECT]
					: reduxApp.home?.objectIconImageMap[object];
			}

			if (!isValidParam(iconImage) && object === constants.PETS_OBJECT) {
				iconImage = "fas fa-paw";
			}

			if (!isValidParam(iconImage) && object === constants.TASKS) {
				iconImage = 'fa fa-clock-o';
			}
			if (object === constants.TASKS) {
				iconImage = 'fa fa-clock-o';
			}
			setIconImage(iconImage);
		}
	}, [reduxApp.selectedModuleItems, reduxApp.home?.objectIconImageMap, props.object])

	useEffect(() => {
		let tabInfo = getActiveTabInfo();
		let is_callFrom_queue = tabInfo.hasOwnProperty('is_callFrom_queue') ? getBooleanParam(tabInfo.is_callFrom_queue) : false;


		if (is_callFrom_queue && !reduxQueueList.mounted) {
			proceesRecordInQueue()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reduxQueueList.mounted]);

	const customPromise = async () => {
		setIsLoading(true);
		await createDummyPromise();
		setIsLoading(false);
	}

	const proceesRecordInQueue = () => {
		let label = '';
		let recordId = 0;
		let objectName = getStringParam(constants.TASKS);
		let recordIndex = 0;

		try {
			/* To process Seleted Records */
			let activeTab = getObjectParam(getActiveTab());
			let info = getObjectParam(activeTab.info);
			/* API call propeties*/
			let initilaParam = {};
			initilaParam.object = info.object == 'accounts' ? 'accounts' : objectName;
			initilaParam.call_from_queue = true;
			let filter = getObjectParam(activeTab.info.filter);
			if (getIntParam(filter.id) > 0 || getStringParam(filter.name) !== '') {


				if (info.object == 'accounts' && reduxQueueList.mounted == false && info.is_callFrom_queue) {
					initilaParam = {
						...initilaParam,
						query_id: getIntParam(filter.id),
						query_name: getStringParam(filter.name),
						query_type: getStringParam(filter.type),
						is_group_by_info: true,
						page_size: QUEUE_LIST.FETCH_SIZE,
						sort_field_name: getStringParam(activeTab.info.sort_field_name),
						sort_type: getStringParam(activeTab.info.sort_type),
						call_from: constants.LIST_VIEW,
					};
				} else {
					initilaParam = {
						...initilaParam,
						query_id: getIntParam(filter.id),
						query_name: getStringParam(filter.name),
						query_type: getStringParam(filter.type),
						is_group_by_info: true,
						page_size: QUEUE_LIST.FETCH_SIZE,
						sort_field_name: getStringParam(activeTab.info.sort_field_name),
						sort_type: getStringParam(activeTab.info.sort_type),
						call_from: constants.LIST_VIEW,
						task_status: info.status,
						userName: info.userName,
						click_form: 'task_listview',
						group_by_field_name: info.groupByFieldName,
						group_by_condition: info.groupByCondition,
						search_field_name: info.searchFieldName
					};
				}


			}

			if (objectName !== '') {
				let object = objectName.toLowerCase();
				let promise = Promise.resolve(getQueueList(object, initilaParam));
				promise.then((response) => {
					if (isValidParam(response)) {
						let queueList = response.records;
						if (isValidParam(queueList) && queueList.length > 0) {
							let getRecordFromQueueList = queueList.find((record) => record.id === info.openedRecordInQueueId)
							if (info?.clickFrom == 'login' && getRecordFromQueueList == undefined) {
								getRecordFromQueueList = info?.selectedAccountRecord[0]
							}
							recordIndex = getRecordFromQueueList && getRecordFromQueueList.idx;
							if(info?.clickFrom == 'login' && info?.objectName == 'accounts'){
							recordIndex = info?.record_index;
								
							}
							recordId = getRecordFromQueueList && getRecordFromQueueList.id;
							label = getRecordFromQueueList && getRecordFromQueueList.title
						}

						response['openedRecordInQueueId'] = recordId;
						response['openedRecordInQueueIndex'] = recordIndex;
						response['openedRecordInQueuelabel'] = label;
						response['selecteddrpdwnObj'] = constants.ACCOUNTS_OBJECT;
						if (info.hasOwnProperty('total_count')) {
							response['total_count'] = info?.total_count;

						}
						if (info.hasOwnProperty('records')) {
							response['records'] = info?.records;

						}
						console.log("info??", info)
						if (info?.objectName == 'tasks' && info?.callFrom == "task_listview" && info?.clickFrom == 'login') {
							let params = {
								call_from: "Listview",
								object: "tasks",
								openedRecordInQueueId: recordId,
								openedRecordInQueueIndex: recordIndex,
								openedRecordInQueuelabel: label,
								selecteddrpdwnObj: "accounts",
								records: info?.records,
								total_count: info?.total_count
							}
							console.log("params", params)
							loadQueueList(getObjectParam(params));

						} else if(info?.objectName == "accounts"  && info?.clickFrom == 'login'){
							console.log("response", response)
							let params = {
								call_from: "Listview",
								group_by : response?.group_by ?? '',
								group_by_condition: response?.group_by_condition ?? '',
								group_by_field :response.group_by_field ?? '',
								object: "accounts",
								openedRecordInQueueId: recordId,
								openedRecordInQueueIndex:  info?.record_index,
								openedRecordInQueuelabel: label,
								page_size: response.page_size ?? 200,
								query_id : response?.query_id ,
								query_name :'Accounts',
								query_type : response.query_type ?? 'all',
								selecteddrpdwnObj: "accounts",
								records: info?.records,
								sort_field_name : response?.sort_field_name ,
								sort_type : response?.sort_type,
								total_count: info?.total_count
							}
							console.log("params", params)
							loadQueueList(getObjectParam(params));
						}
						
						else {
							loadQueueList(getObjectParam(response));

						}

						console.log("ressss", response)
						delete info.openedRecordInQueueId
						updateTabInfo(info);
					}
				})
			}
		} catch (error) {
			console.error("error in listViewHeader.js -> openRecordInQueue() " + error)
		}
	}

	function processFranchiseRecord(id) {
		let info = getActiveTabInfo();
		let param = { record_id: id };
		if (isValidParam(info) && info.hasOwnProperty("isUnitsAccount") && info.hasOwnProperty("UnitsAccountProjectId") && info.isUnitsAccount) {
			param.isUnitsAccount = info.isUnitsAccount;
			param.UnitsAccountProjectId = info.UnitsAccountProjectId;
		}
		const promise = isFranchiseRecord(param);
		promise.then((response) => {
			if (isValidParam(response)) {
				setIsFranchise(response);
				let log = changeLog;
				log.isFranchise = response;
				setchangeLog({ ...log });
			}
		});
	}

	function refreshView() {
		reduxDetailView.mounted = false;
		dispatch(refreshDetailViewData(true));
	}

	function getChildFieldsSetup(setupInfo) {
		let details = null;
		let objects = [];
		let childArr = [];
		let objectFieldsRecords = null;
		let propsChildObjects = null;
		try {
			if (isValidParam(setupInfo) && setupInfo.hasOwnProperty("child_setup_details")) {
				details = setupInfo.child_setup_details;
			}

			if (details != null) {
				objects = getArrayParam(Object.keys(details));
				objects.forEach(object => {
					let detail = details[object];
					let fields = reduxSfForm.data.hasOwnProperty(object) ? reduxSfForm.data[object].fields : null;
					objectFieldsRecords = {};
					objectFieldsRecords.records = detail.record;
					objectFieldsRecords.fields = detail.fields;
					objectFieldsRecords.isShowFieldLabel = setupInfo.is_show_field_label;
					objectFieldsRecords.object = object;
					objectFieldsRecords.detailViewFieldsData = fields;

					propsChildObjects = {};
					propsChildObjects.childObjects = getObjectParam(reduxDetailView).childObjects;
					propsChildObjects.records = detail.record;
					propsChildObjects.object = object;
					propsChildObjects.projectId = reduxApp.me.projectId;
					propsChildObjects.parent_record_id = props.id;

					childArr.push({
						object: object,
						fieldsRecords: objectFieldsRecords,
						childObjects: propsChildObjects
					});
				});
			}
		} catch (error) {
			console.error("Error in ' detailView.js -> getChildFieldsSetup()'" + error);
		}
		return childArr;
	}

	function generateChildDetailViewFields(setupInfo) {
		let component = null;
		if (getArrayParam(setupInfo).length > 0) {
			component = setupInfo.map((m, i) => {
				if (getStringParam(m.object).length > 0 && m.object !== '-99') {
					return (
						<div key={"detail_view_child_fields" + (i + 1)} style={{ paddingBottom: '15px' }}>
							<DetailViewFields
								key={"detail_view_child_fields" + (i + 1)}
								fieldsRecords={m.fieldsRecords}
								childFieldsView={true}
								object={m.object}
								childObjProps={m.childObjects}
								isChildView={true}
							/>
						</div>
					)
				}
			})
		}
		return component;
	}
	const removeFromQueue = (detailViewId, action) => {
		let nextRecordIndex = 0;
		let newIdx = 0;
		if (detailViewId > 0) {
			let tempQueList = reduxQueueList;
			let data = getObjectParam(reduxQueueList.data);
			let _queueList = getArrayParam(data.records);
			let recordId = parseInt(detailViewId);
			let queueObj = _queueList.filter((obj) => { return obj.id == recordId });
			let index = isValidParam(queueObj) && queueObj.length > 0 ? queueObj[0].idx : -1;
			let objectName = getStringParam(props.object);

			if (action == constants.DELETE && _queueList.length <= 1) {
				let activeTab = getActiveTab();
				if (isValidParam(activeTab)) {
					closeTab(activeTab.item, activeTab.index);
				}
			} else {
				if (index >= 0) {
					if (index + 1 < _queueList.length) {
						nextRecordIndex = index + 1;
						newIdx = index;
						// open next records for detailview
					} else {
						nextRecordIndex = index - 1;
						newIdx = nextRecordIndex;
						// get the last record  for detailview 
					}

					let prevObj = _queueList[nextRecordIndex];
					let preId = isValidParam(prevObj) && prevObj != null ? getIntParam(prevObj.id) : 0;
					if (preId > 0) {
						let total_count = getIntParam(data.total_count);
						total_count = total_count - 1;
						removeRecordFromRedux(objectName, recordId, total_count);

						let label = getStringParam(prevObj.title);
						tempQueList.data['openedRecordInQueueId'] = preId;
						tempQueList.data['openedRecordInQueueIndex'] = newIdx;
						tempQueList.data['openedRecordInQueuelabel'] = label;
						if (objectName == constants.TASKS) {

							let linkInfo = prevObj.linkInfo;
							if (linkInfo != null && linkInfo != undefined) {
								let linkInfoArr = linkInfo.split(',');
								let selectedObjId = getIntParam(OBJECT_TABLEID_MAP[tempQueList.data.selecteddrpdwnObj]);
								linkInfoArr.map((item, i) => {
									let ObjRecordArr = item.split('_');
									let tableId = getIntParam(ObjRecordArr[0].trim());
									if (selectedObjId == tableId) {
										preId = getIntParam(ObjRecordArr[1]);
										objectName = tempQueList.data.selecteddrpdwnObj;
									}

								});
							}
						}
						openDetailviewQueueTab(objectName, label, preId, newIdx, total_count);
						dispatch(refreshDetailViewListViewData(true, recordId));
						let urlParams = {
							object: objectName,
							recordId: preId
						}
						dispatch(getDetailviewData(urlParams));
						refreshDeatilsView();
					}
				}
			}

		}
	}
	const refreshDeatilsView = () => {
		dispatch(detailViewIsMounted(false));
		dispatch(isDetailViewSet(false));
		dispatch(refreshDetailViewData(true));
	};
	function handleScroll(event) {
		let scrollTop = event.currentTarget.scrollTop;
		let enableScrolll = false;
		if (!reduxAppContainer.drawerProps.isOpenDrawer &&
			reduxAppContainer.customDrawerProps.isOpen) {
			enableScrolll = true;
		} else if (reduxAppContainer.drawerProps.isOpenDrawer &&
			!reduxAppContainer.customDrawerProps.isOpen) {
			enableScrolll = false;
		} else if (!reduxAppContainer.drawerProps.isOpenDrawer &&
			!reduxAppContainer.customDrawerProps.isOpen) {
			enableScrolll = true;
		} else {
			enableScrolll = false;
		}
		if (isValidParam(reduxDetailView.prevScrollPosition[props.object])) {
			reduxDetailView.scrollPosition[props.object] = reduxDetailView.prevScrollPosition[props.object];
			enableScrolll = false;
		}
		if (reduxAppContainer.customDrawerProps.minimizeList.new || reduxAppContainer.customDrawerProps.minimizeList.email) {
			if (isValidParam(reduxDetailView.prevScrollPosition[props.object])) {
				reduxDetailView.scrollPosition[props.object] = reduxDetailView.prevScrollPosition[props.object];
				enableScrolll = false;
			} else {
				enableScrolll = true;
			}
		}
		if (enableScrolll) {
			reduxDetailView.scrollPosition[props.object] = scrollTop;
		} else {
			setScrollPosition(props.object, reduxDetailView);
		}
	}

	function setScrollPosition(object, detailView) {
		let scrollInfo = null;
		if (isValidParam(detailView.scrollPosition[object])) {
			scrollInfo = detailView.scrollPosition[object];
		}
		if (scrollInfo != null) {
			$("#DetailViewBody").scrollTop(scrollInfo);
		}
		detailView.prevScrollPosition = {};
	}

	let homeSetUp = getObjectParam(reduxApp.homeSetup);
	if (reduxDetailView.mounted && reduxSfForm.data[props.object] && !reduxDetailView.isDetailviewLoading && !isLoading) {
		let tabInfo = getActiveTabInfo();
		let is_callFrom_queue = tabInfo.hasOwnProperty('is_callFrom_queue') ? getBooleanParam(tabInfo.is_callFrom_queue) : false;
		let queueObj = reduxQueueList && reduxQueueList.hasOwnProperty('data') && reduxQueueList.data ? reduxQueueList.data.object : "";

		if (!reduxDetailView.record) {
			if (is_callFrom_queue && queueObj == constants.TASKS) {
				removeFromQueue(props.id, constants.DELETE);
				return null;
			} else {
				return <NoRecords object={props.object} />
			}

		}
		let headerProps = {
			title: reduxDetailView.data.title,
			actions: reduxDetailView.actions,
			iconImage: iconImage,
			leadScore: reduxDetailView.data.lead_score,
			titleFieldName: reduxDetailView.data.title_field_name
		}

		let fields = getArrayParam(reduxDetailView.fields);
		let childObjects = getArrayParam(reduxDetailView.childObjects);
		let dataChilObjects = getArrayParam(reduxDetailView.data.child_objects);
		let fieldsArray = getArrayParam(fieldsData);

		if (props.object === constants.ACCOUNTS_OBJECT && !changeLog.isFranchise) {
			fields = fields.filter(f => {
				return f.name !== 'royalty_percent';
			});
			childObjects = childObjects.filter(f => {
				return f.child_object !== constants.ROYALTY_OBJECT;
			});
			fieldsArray = fieldsArray.filter(f => {
				return f.name !== 'royalty_percent';
			});
			dataChilObjects = dataChilObjects.filter(f => {
				return f.child_object !== constants.ROYALTY_OBJECT;
			});
		}

		// let reduxRecordData = reduxDetailView?.record
		// if (queueObj == 'tasks' && is_callFrom_queue && changeLog.reduxRecords !== null) {
		// 	reduxRecordData = changeLog?.reduxRecords;
		// }
		let objectFieldsRecords = {
			records: reduxDetailView?.record,
			fields: fields,
			isShowFieldLabel: reduxDetailView.data.is_show_field_label,
			object: props.object,
			leadScore: reduxDetailView.data.lead_score,
			totalLeadScore: reduxDetailView.data.total_lead_score,
			detailViewFieldsData: fieldsArray
		}

		let propsChildObjects = {
			childObjects: childObjects,
			records: reduxDetailView.record,
			object: props.object,
			projectId: reduxApp.me.projectId,
			parent_record_id: props.id
		}

		let selectedChildObjects = dataChilObjects;
		let availableChildObjects = reduxDetailView.data.available_objects;
		let isAppointmentInChild = false;
		let isTaskInChild = false;

		if (reduxApp.selectedModuleName === constants.MODULE_HUB && props.object === constants.CASES_OBJECT) {
			selectedChildObjects = selectedChildObjects.filter(f => {
				return f.child_object === constants.NOTES || f.child_object === constants.ATTACHMENT_OBJECT;
			});
			propsChildObjects.childObjects = propsChildObjects.childObjects.filter(f => {
				return f.child_object === constants.NOTES || f.child_object === constants.ATTACHMENT_OBJECT;
			});
		}

		if (selectedChildObjects != null && selectedChildObjects.length > 0) {
			let tempObject = selectedChildObjects.filter(f => {
				return f.child_object === constants.APPOINTMENTS
			});
			let tempTasksObject = selectedChildObjects.filter(f => {
				return f.child_object === constants.TASKS
			});

			if (tempObject != null && tempObject.length > 0) {
				isAppointmentInChild = true;
			}

			if (tempTasksObject != null && tempTasksObject.length > 0) {
				isTaskInChild = true;
			}
		}

		if (isAppointmentInChild === false && availableChildObjects != null && availableChildObjects.length > 0) {
			let tempObject = null;
			if (props.object !== constants.ISSUES_OBJECT && props.object !== constants.PROJECTS_OBJECT) {
				tempObject = availableChildObjects.filter(f => {
					return f.t_child_tableid === 142
				});
			}

			if (tempObject != null && tempObject.length > 0) {
				isAppointmentInChild = true;
			}
		}

		if (isTaskInChild === false && availableChildObjects != null && availableChildObjects.length > 0) {
			let tempObject = availableChildObjects.filter(f => {
				return f.t_child_tableid === 349
			});

			if (tempObject != null && tempObject.length > 0) {
				//isTaskInChild = true;
			}
		}


		let propsTaskApp = {
			object: props.object,
			recordId: reduxDetailView.record.id,
			workflowId: !reduxDetailView.workflow ? 0 : reduxDetailView.workflow.id,
			isDetailView: true,
			callFrom: 'DetailView',
			isAppointmentInChild: isAppointmentInChild,
			currentStatus: reduxDetailView.workflow?.current_stage
		}

		let objWorkflow = {
			workflow: reduxDetailView.workflow,
			recordId: reduxDetailView.record.id,
		}
		if (props.object === constants.JOBS_OBJECT) {
			objWorkflow.workflow.stages = JOB_ACTIONS_ARRY;
			objWorkflow.workflow.current_stage = reduxDetailView.record.t_status;
		}

		let propsDetailviewGroup = {
			object: props.object,
			recordId: reduxDetailView.record.id,

		}
		let showTaskAppDiv = false;
		if (props.object === constants.ACCOUNTS_OBJECT || props.object === constants.CONTACTS_OBJECT || props.object === constants.JOBS_OBJECT) {
			showTaskAppDiv = true;
		} else {
			if (isAppointmentInChild || isTaskInChild) {
				showTaskAppDiv = true;
			}
		}
		if(props.object === constants.PARTNERS_OBJECT && reduxApp.me.is_franchisee ){
			showTaskAppDiv = false;
		}
		let isTimelineView = false;
		let detailViewChildObjClassName = "col-sm-6";
		let detailViewChildOjWith = 'calc(100% - 440px)';
		if (!showTaskAppDiv) {
			detailViewChildObjClassName = "col-sm-9";
			detailViewChildOjWith = 'calc(100% - 220px)';
			if (isTimelineView) {
				let isOpenMenuDrawer = reduxApp.menuDrawerIsOpen;
				detailViewChildOjWith = document.body.clientWidth - 220;
				detailViewChildOjWith = isOpenMenuDrawer ? detailViewChildOjWith - 235 : detailViewChildOjWith - 122;
			}

		}
		
		let childFieldsView = null;
		let childDetails = null;
		let avaliableContacts = null;
		let avaliableAccounts = null;
		if (props.object === constants.OPPORTUNITIES_OBJECT) {
			let childSetupInfo = getArrayParam(getChildFieldsSetup(reduxDetailView.data));
			childFieldsView = generateChildDetailViewFields(childSetupInfo);
			childDetails = reduxDetailView.data.child_setup_details;
			avaliableContacts = reduxDetailView.data.avaliableContacts;
			avaliableAccounts = reduxDetailView.data.avaliableAccounts;
		}
		let contentHeight = window.innerHeight - 200;
		return (
			<div>
				<div className="headerDetail">
					{
						(queueObj === constants.TASKS && is_callFrom_queue) &&
						(<div className="headerDetailView" style={{ marginBottom: '20px', display: 'flex' }}>
							<TaskQueueHeader
								headerProps={headerProps}
								object={reduxQueueList.data.object}
								refreshView={refreshView}
								is_callFrom_queue={is_callFrom_queue}
							/>
						</div>)
					}
					<div className="headerDetailView">

						<DetailViewHeader
							headerProps={headerProps}
							object={props.object}
							refreshView={refreshView}
							queueObj={queueObj}
						/>
					</div>
					<div
						className="DetailViewBody" id="DetailViewBody" style={{
							marginBottom: '0',
							width: '100%',
							height: contentHeight,
							overflow: 'auto',
							backgroundColor: 'transparent'
						}}
						onScroll={handleScroll}
					>
						{objWorkflow && <div style={{ marginLeft: '24px', minHeight: '25px' }}>
							<DetailViewWorkflowStages workflowProps={objWorkflow} object={props.object}
								mounted={reduxDetailView.mounted} />
						</div>}
						<div className="taskMain adetailview" style={{
							width: 'auto',
							backgroundColor: '#fff',
							marginLeft: '24px',
							borderRadius: '6px',
							boxShadow: '0 0 12px #ccc',
							minHeight: 'calc(100% - 25px)'
						}}>
							{props.object === constants.GROUP_OBJECT &&
								<GroupDetailView id={props.id} fieldsRecords={objectFieldsRecords} object={props.object} />
							}
							{props.object !== constants.GROUP_OBJECT && props.object !== constants.AUDITS_OBJECT &&
								<div className="container" id="detail-view-container" style={{ width: '100%' }}>
									<div className="row">
										<div className="col-sm-3"
											style={{ width: '220px', borderRight: '1px solid #eee', fontSize: '14px' }}>
											<DetailViewFields
												fieldsRecords={objectFieldsRecords}
												object={props.object}
												childObjProps={propsChildObjects}
												childDetails={childDetails}
												avaliableContacts={avaliableContacts}
												avaliableAccounts={avaliableAccounts}
											/>
											{
												(props.object === constants.OPPORTUNITIES_OBJECT && getArrayParam(childFieldsView).length > 0)
												&& (<div style={{ marginTop: 30 }}>{childFieldsView}</div>)
											}
										</div>

										{!isTimelineView && !reduxChildListView.isRefreshDetailViewListView && (
											<div className={detailViewChildObjClassName}
												style={{ width: detailViewChildOjWith, borderRight: '1px solid #eee' }}>
												<DetailViewChildObjects
													childObjProps={propsChildObjects}
													isFranchiseRecord={changeLog.isFranchise}
												/>
											</div>)
										}

										{showTaskAppDiv ?
											<div className="col-sm-3" style={{ width: '220px' }}>
												<DetailViewTaskApps
													propsTaskApp={propsTaskApp}
													isFranchiseRecord={changeLog.isFranchise}
												/>
												{/* {
													(props.object === constants.ACCOUNTS_OBJECT && changeLog.isFranchise && detailViewTaskAppintmentReducer.taskAppValue !== undefined && detailViewTaskAppintmentReducer.taskAppValue !== 'My Open' && detailViewTaskAppintmentReducer.taskAppValue !== 'My Done')
													&& <DetailViewUnitOwnerTasks propsTaskApp={propsTaskApp} />
												} */}
												{
													(props.object === constants.ACCOUNTS_OBJECT || props.object === constants.CONTACTS_OBJECT)
													&& (homeSetUp.is_detailview_group === 1)
													&& <DetailviewGroups propsDetailviewGroup={propsDetailviewGroup} />
												}
											</div> : null}
									</div>
								</div>
							}
							{props.object === constants.AUDITS_OBJECT &&
								<div className="container" id="detail-view-container" style={{ width: '100%' }}>
									<div className="row">
										{!isTimelineView &&
											<div className="col-sm-12" style={{ borderRight: '1px solid #eee' }}>
												<DetailViewChildObjects
													childObjProps={propsChildObjects}
													isFranchiseRecord={changeLog.isFranchise}
												/>
											</div>
										}
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		)
	} else {
		let contentHeight = window.innerHeight - 240;
		let top = (contentHeight - 10) / 2
		return (
			<div style={{ width: '100%', height: contentHeight }}>
				<div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
					<div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
						<ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
					</div>
				</div>
			</div>
		);
	}
}

export default DetaiLView;