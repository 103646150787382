import React, { useState, useEffect } from 'react';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from './circularProgress';
import { isValidParam } from '../../../services/helper/parameterVerifier';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { useSelector } from "react-redux";
import { JsonView, allExpanded, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

const WebhookLastRunDialog = () => {
  const successStatusCodes = [200 , 201 , 202, 204];
  const appContainer = useSelector((state) => state.appContainer);
  const [mounted, setMounted] = useState(false);
  const [webhookLastRanList, setWebhookLastRanList] = useState([]);

  useEffect(() => {
    let id = 0;
    console.log(appContainer)
    if (appContainer.dialogProps.hasOwnProperty('data') && appContainer.dialogProps.data !== null) {
      id = appContainer.dialogProps.data.id;
    }
    let params = { id: id };
    var promise = Promise.resolve(HTTPClient.get(endPoints.WEBHOOKS.GET_WEBHOOKS_DETAILS, params));
    if (isValidParam(promise)) {
      promise.then((response) => {
        if (isValidParam(response)) {
          setWebhookLastRanList(response);
          setMounted(true)
        }
      });
    }
  }, []);

  let contentHeight = window.innerHeight - 200;
  let contentWidth = '100%'
  let top = (contentHeight - 50) / 2;
  if (!mounted) {
    return (
      <div>
        <div style={{ width: contentWidth, height: contentHeight }} >
          <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }} >
            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
              <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  const getPayload = (item) => {
    const _fullPayload = {};
    if (item.header) {
      _fullPayload.header = item.header;
    }
    _fullPayload.payload = JSON.parse(item.payload)
    return _fullPayload;
  }
  return (
    <div style={{ width: contentWidth, height: contentHeight, overflow: 'auto' }}>
      {webhookLastRanList.length > 0 ? <table style={{ width: '100%', borderCollapse: 'collapse' }} id="webhook-last-ran-list">
        <thead style={{
          position: 'sticky',
          top: 0,
          zIndex: 1500,
          background: '#a9a9a93b',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          boxShadow: '0px 3px 3px rgba(69, 69, 69, 0.21)',
        }}>
          <tr>
            <th style={{ padding: 10, textAlign: 'center', width: '50%' }}>Payload</th>
            <th style={{ padding: 10, textAlign: 'center', width: '10%' }}>Status</th>
            <th style={{ padding: 10, textAlign: 'center', width: '20%' }}>Run Time</th>
          </tr>
        </thead>
        <tbody>
          {webhookLastRanList.map((item) => (
            <tr key={item.webhook_log_id} style={{ borderBottom: '1px solid #d4d4d4', padding: '10px 0px' }}>
              <td className="JSON-view" style={{ width: '50%' }}>
                <JsonView data={getPayload(item)} shouldExpandNode={allExpanded} style={defaultStyles} />
              </td>
              <td style={{ textAlign: 'center', color: successStatusCodes.includes(parseInt(item.status)) ? 'green' : 'red', width: '10%' }}>
                {parseInt(item.status)}<br/>
                {item.error_msg && `${item.error_msg}`}
              </td>
              <td style={{ textAlign: 'center', width: '20%' }}>
                {item.run_time}
              </td>
            </tr>
          ))}
        </tbody>
      </table> :
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: contentHeight,
        }}>
          <span style={{
            color: '#aeaeae',
            fontSize: '20px'
          }}>No webhook logs found</span>
        </div>}
    </div>
  );
};

export default WebhookLastRunDialog;