import { constants } from '..//constants/constants';
import { actions } from '..//constants/actions';
import { endPoints } from '..//constants/endPoints';
import * as HTTPClient from '..//helper/httpClient';
function getFilters(object) {
    let url = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        url = endPoints.ACCOUNTS.QUERY_FILTER;
    } else if (object === constants.CONTACTS_OBJECT) {
        url = endPoints.CONTACTS.QUERY_FILTER;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        url = endPoints.OPPORTUNITIES.QUERY_FILTER;
    } else if (object === constants.CASES_OBJECT) {
        url = endPoints.CASES.QUERY_FILTER;
    } else if (object === constants.SOLUTIONS_OBJECT) {
        url = endPoints.SOLUTIONS.QUERY_FILTER;
    } else if (object === constants.ISSUES_OBJECT) {
        url = endPoints.ISSUES.QUERY_FILTER;
    } else if (object === constants.PROJECTS_OBJECT) {
        url = endPoints.PROJECTS.QUERY_FILTER;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        url = endPoints.CUSTOM_TABLE1.QUERY_FILTER;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        url = endPoints.CUSTOM_TABLE2.QUERY_FILTER;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        url = endPoints.CUSTOM_TABLE3.QUERY_FILTER;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        url = endPoints.CUSTOM_TABLE4.QUERY_FILTER;
    } else if (object === constants.APPOINTMENTS) {
        url = endPoints.APPOINTMENT.QUERY_FILTER;
    } else if (object === constants.TASKS) {
        url = endPoints.TASK.QUERY_FILTER;
    }  else if (object === constants.INVOICES_OBJECT) {
        url = endPoints.INVOICES.QUERY_FILTER;
    } else if (object === constants.SALES_LEADS_OBJECT) {
        url = endPoints.SALES_LEADS.QUERY_FILTER;
    } else if (object === constants.ORDERS_OBJECTS) {
        url = endPoints.ORDERS.QUERY_FILTER;
    }else if (object === constants.PRODUCTS_OBJECT) {
        url = endPoints.PRODUCTS.QUERY_FILTER;
    }else if (object === constants.QUOTATION_OBJECT) {
        url = endPoints.QUOTATIONS.QUERY_FILTER;
    } else if (object === constants.PURCHASE_ORDER_OBJECT) {
        url = endPoints.PURCHASE_ORDER.QUERY_FILTER;
    } else if (object === constants.SALES_ORDER_OBJECT) {
        url = endPoints.SALES_ORDER.QUERY_FILTER;
    }else if (object === constants.INVENTORY_OBJECT) {
        url = endPoints.INVENTORY.QUERY_FILTER;
    }else if (object === constants.JOBS_OBJECT) {
        url = endPoints.JOBS.QUERY_FILTER;
    } else if (object === constants.COMPLIANCE_OBJECT) {
        url = endPoints.COMPLIANCE.QUERY_FILTER;
    } else if (object === constants.ROYALTY_RUN_OBJECT) {
        url = endPoints.ROYALTY_RUN.QUERY_FILTER;
    } else if (object === constants.PARTNERS_OBJECT) {
        url = endPoints.PARTNERS.QUERY_FILTER;
    }
    return HTTPClient.get(url, null);
}

function getMyGroups(object) {
    let url = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        url = endPoints.ACCOUNTS.MY_GROUP;
    } else if (object === constants.CONTACTS_OBJECT) {
        url = endPoints.CONTACTS.MY_GROUP;
    }
   
    return HTTPClient.get(url, null);
}

function setGroups(groupDetails){
    return {
        type: actions.GROUPS_GET_FULFILLED,
        payload: groupDetails
    }
}

function setRecords(records){
    return {
        type: actions.RECENT_RECORD_GET_FULFILLED,
        payload: {records: records}
    }
}

function getUserFilter(object) {
    let url = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        url = endPoints.ACCOUNTS.USER_FILTER_GET;
    } else if (object === constants.CONTACTS_OBJECT) {
        url = endPoints.CONTACTS.USER_FILTER_GET;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        url = endPoints.OPPORTUNITIES.USER_FILTER_GET;
    } else if (object === constants.CASES_OBJECT) {
        url = endPoints.CASES.USER_FILTER_GET;
    } else if (object === constants.PROJECTS_OBJECT) {
        url = endPoints.PROJECTS.USER_FILTER_GET;
    } else if (object === constants.ISSUES_OBJECT) {
        url = endPoints.ISSUES.USER_FILTER_GET;
    } else if (object === constants.SOLUTIONS_OBJECT) {
        url = endPoints.SOLUTIONS.USER_FILTER_GET;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        url = endPoints.CUSTOM_TABLE1.USER_FILTER_GET;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        url = endPoints.CUSTOM_TABLE2.USER_FILTER_GET;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        url = endPoints.CUSTOM_TABLE3.USER_FILTER_GET;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        url = endPoints.CUSTOM_TABLE4.USER_FILTER_GET;
    } else if (object === constants.PARTNERS_OBJECT) {
        url = endPoints.PARTNERS.USER_FILTER_GET;
    }
    return HTTPClient.get(url, null);
}

function setQueryFilter(queryFilters){
    return {
        type: actions.FILTER_SET_FULFILLED,
        payload: queryFilters
    }
}

function filterSave(filter){
    let url = endPoints.HOME.FILTER_SAVE;
    return HTTPClient.post(url, filter);
}

function filterDelete(filter,object){
    let url = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        url = endPoints.ACCOUNTS.FILTER_DELETE;
    } else if (object === constants.CONTACTS_OBJECT) {
        url = endPoints.CONTACTS.FILTER_DELETE;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
        url = endPoints.OPPORTUNITIES.FILTER_DELETE;
    } else if (object === constants.CASES_OBJECT) {
        url = endPoints.CASES.FILTER_DELETE;
    } else if (object === constants.PROJECTS_OBJECT) {
        url = endPoints.PROJECTS.FILTER_DELETE;
    } else if (object === constants.ISSUES_OBJECT) {
        url = endPoints.ISSUES.FILTER_DELETE;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
        url = endPoints.CUSTOM_TABLE1.FILTER_DELETE;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
        url = endPoints.CUSTOM_TABLE2.FILTER_DELETE;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
        url = endPoints.CUSTOM_TABLE3.FILTER_DELETE;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
        url = endPoints.CUSTOM_TABLE4.FILTER_DELETE;
    } else if (object === constants.PARTNERS_OBJECT) {
        url = endPoints.PARTNERS.FILTER_DELETE;
    }
    return HTTPClient.post(url, filter);
}

function saveUserRecentGroupStatus(object, recentGroup) {
    let url = null;
    if (object === constants.ACCOUNTS_OBJECT) {
        url = endPoints.ACCOUNTS.MY_GROUP_STATUS_SAVE;
    } else if (object === constants.CONTACTS_OBJECT) {
        url = endPoints.CONTACTS.MY_GROUP_STATUS_SAVE;
    }
    return HTTPClient.post(url, recentGroup);
}

function setSearchText(recentSearchesText){
    return {
        type: actions.RECENT_SEARCHES_TEXT,
        payload: {recentSearchesText: recentSearchesText}
    }
}

function setSearchRecord(recentSearchRecords){
    return {
        type: actions.RECENT_SEARCHES_RECORD,
        payload: {recentSearchRecords: recentSearchRecords}
    }
}

function setObjList(recentObjectList){
    return {
        type: actions.RECENT_OBJECT_TABLE_LIST,
        payload: recentObjectList
    }
}

function setAdvanceSearchRecord(searchCondition) {
    return {
        type: actions.RECENT_ADVANCE_SEARCH_LIST,
        payload: searchCondition
    }
}
function setAdvancePartnersSearch(searchCondition) {
    return {
        type: actions.SET_RECENT_ADVANCE_PARTNERS_SEARCH,
        payload: searchCondition
    }
}
function setRecentFilterReport(recentFilters){
    return{
        type: actions.SET_REPORT_RECENT_FILTER,
        payload: recentFilters
    }
}

function setRecentReport(recentRecords){
    return{
        type: actions.SET_REPORT_RECENT_RECORD,
        payload: recentRecords
    }
}

export {
    getFilters,
    getMyGroups,
    setGroups,
    setRecords,
    getUserFilter,
    setQueryFilter,
    filterSave,        
    filterDelete,
    saveUserRecentGroupStatus,
    setSearchText,
    setSearchRecord,
    setObjList,
    setAdvanceSearchRecord,
    setAdvancePartnersSearch,
    setRecentReport,
    setRecentFilterReport,
}