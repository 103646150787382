import * as HTTPClient from '..//helper/httpClient';
import { constants } from '..//constants/constants';
import { endPoints } from '..//constants/endPoints';
import { actions } from '..//constants/actions';
import store from '../../../src/services/store';
import { getActiveTabInfo, getActiveTab, TYPE_DETAIL_VIEW, } from '..//helper/sfTabManager';
import { isValidParam, getIntParam, getStringParam, getObjectParam, getBooleanParam } from '..//helper/parameterVerifier';

function getDetailviewData(urlParams) {
    let state = store.getState();
    let url = null;
    let object = urlParams.object;
    let recordId = urlParams.recordId;
    let urlSuffix = '/' + recordId + '/detailview';
    try {
        if (object === constants.ACCOUNTS_OBJECT) {
            url = endPoints.ACCOUNTS.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.OPPORTUNITIES_OBJECT) {
            url = endPoints.OPPORTUNITIES.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.PRODUCTS_OBJECT) {
            url = endPoints.PRODUCTS.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.CASES_OBJECT) {
            url = endPoints.CASES.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.SOLUTIONS_OBJECT) {
            url = endPoints.SOLUTIONS.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.PROJECTS_OBJECT) {
            url = endPoints.PROJECTS.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.ISSUES_OBJECT) {
            url = endPoints.ISSUES.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.CONTACTS_OBJECT) {
            url = endPoints.CONTACTS.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
            url = endPoints.CUSTOM_TABLE1.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
            url = endPoints.CUSTOM_TABLE2.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
            url = endPoints.CUSTOM_TABLE3.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
            url = endPoints.CUSTOM_TABLE4.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.GROUP_OBJECT) {
            url = endPoints.GROUP.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.COUPONS_OBJECT) {
            url = endPoints.COUPONS.DETAIL_VIEW + urlSuffix;
        }else if (object === constants.SALES_LEADS_OBJECT) {
            url = endPoints.SALES_LEADS.DETAIL_VIEW + urlSuffix;
        }else if (object === constants.RECYCLE_BIN_OBJECT) {
            url = endPoints.ACCOUNTS.RECYCLE_BIN_DETAIL_VIEW + urlSuffix;
        }else if (object === constants.PETS_OBJECT) {
            url = endPoints.PETS.DETAIL_VIEW + urlSuffix;
        }else if (object === constants.ORDERS_OBJECTS) {
            url = endPoints.ORDERS.DETAIL_VIEW + urlSuffix;
        }else if (object === constants.ORDER_ITEMS_OBJECTS) {
            url = endPoints.ORDER_ITEMS.DETAIL_VIEW + urlSuffix;
        } else if(object === constants.TASKS) {
            url = endPoints.TASK.DETAIL_VIEW + urlSuffix;
        } else if(object === constants.APPOINTMENTS) {
            url = endPoints.APPOINTMENT.DETAIL_VIEW + urlSuffix;
        } else if(object === constants.INVENTORY_OBJECT) {
            url = endPoints.INVENTORY.DETAIL_VIEW + urlSuffix;
        } else if(object === constants.JOBS_OBJECT) {
            url = endPoints.JOBS.DETAIL_VIEW + urlSuffix;
        } else if(object === constants.AUDITS_OBJECT) {
            url = endPoints.AUDITS.DETAIL_VIEW + urlSuffix;
        } else if(object === constants.COMPLIANCE_OBJECT) {
            url = endPoints.COMPLIANCE.DETAIL_VIEW + urlSuffix;
        } else if(object === constants.ROYALTY_RUN_OBJECT) {
            url = endPoints.ROYALTY_RUN.DETAIL_VIEW + urlSuffix;
        } else if(object === constants.REPORTED_SALES_OBJECT) {
            url = endPoints.REPORTED_SALES.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.PARTNERS_OBJECT) {
            url = endPoints.PARTNERS.DETAIL_VIEW + urlSuffix;
        } 

        delete urlParams.object;
        delete urlParams.recordId;
        let info = getActiveTabInfo(state);
        if (isValidParam(info) && info.isInXpress) {
            urlParams.is_inxpress = info.isInXpress;
            urlParams.inxpress_project_id = info.inXpressProjectId;
        }
        if (isValidParam(info) && info.hasOwnProperty("isUnitsAccount") && info.hasOwnProperty("UnitsAccountProjectId") && info.isUnitsAccount) {
            urlParams.isUnitsAccount = info.isUnitsAccount;
            urlParams.UnitsAccountProjectId = info.UnitsAccountProjectId;
        }
        if(state.app.selectedModuleName === constants.MODULE_HUB && object !== constants.COMPLIANCE_OBJECT) {
            urlParams.call_from = constants.MODULE_HUB;
        }
        return {
            type: actions.DETAILVIEW_GET,
            payload: HTTPClient.get(url, urlParams)
        }
    } catch (error) {
        console.error("Error in 'detailViewActions.js -> getDetailviewData()': " + error);
    }
}

function getDetailviewFields(urlParams) {
    let url = null;
    let object = urlParams.object;
    let urlSuffix = '/fields';
    try {
        if (object === constants.ACCOUNTS_OBJECT) {
            url = endPoints.ACCOUNTS.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.OPPORTUNITIES_OBJECT) {
            url = endPoints.OPPORTUNITIES.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.PRODUCTS_OBJECT) {
            url = endPoints.PRODUCTS.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.CASES_OBJECT) {
            url = endPoints.CASES.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.SOLUTIONS_OBJECT) {
            url = endPoints.SOLUTIONS.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.PROJECTS_OBJECT) {
            url = endPoints.PROJECTS.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.ISSUES_OBJECT) {
            url = endPoints.ISSUES.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.CONTACTS_OBJECT) {
            url = endPoints.CONTACTS.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
            url = endPoints.CUSTOM_TABLE1.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
            url = endPoints.CUSTOM_TABLE2.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
            url = endPoints.CUSTOM_TABLE3.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
            url = endPoints.CUSTOM_TABLE4.DETAIL_VIEW + urlSuffix;
        }  else if (object === constants.COUPONS_OBJECT) {
            url = endPoints.COUPONS.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.COMPLIANCE_OBJECT) {
            url = endPoints.COMPLIANCE.DETAIL_VIEW + urlSuffix;
        } else if (object === constants.PARTNERS_OBJECT) {
            url = endPoints.PARTNERS.DETAIL_VIEW + urlSuffix;
        } 

        return {
            type: actions.DETAILVIEW_FIELDS_GET,
            payload: HTTPClient.get(url, null)
        }
    } catch (error) {
        console.error("Error in 'detailViewActions.js -> getDetailviewFields()': " + error);
    }
}

function refreshDetailViewData(isRefresh) {

    return function (dispatch) {
        dispatch({
            type: actions.DETAILVIEW_IS_REFERSH,
            payload: isRefresh
        })
    }
}

function refreshDetailViewHeader(isRefresh){
    return function (dispatch) {
        dispatch({
            type: actions.DETAILVIEW_HEADER_IS_REFERSH,
            isHeaderRefresh: isRefresh,
        })
    }
}

function refreshDetailViewListViewData(isRefresh, id) {
    return function (dispatch) {
        dispatch({
            type: actions.DETAILVIEWLISTVIEW_IS_REFERSH,
            payload: {isRefresh: isRefresh, id: id}
        })
    }
}


function refreshDetailViewGroup(isRefresh) {
    return function (dispatch) {
        dispatch({
            type: actions.DETAILVIEW_GROUP_IS_REFERSH,
            payload: {isRefresh: isRefresh}
        })
    }
}

function isDetailViewSet(isDetailview) {

    return function (dispatch) {
        dispatch({
            type: actions.IS_DETAILVIEW,
            payload: isDetailview
        })
    }
}

function detailViewIsMounted( isDetailviewMounted){
    return function (dispatch) {
        dispatch({
            type: actions.IS_DETAILVIEW_MOUNTED,
            payload: isDetailviewMounted
        })
    }

}

function getDetailViewTaskAppointment(object, params) {
    let state = store.getState()
    let url = null;
    try {
        if (object === constants.ACCOUNTS_OBJECT) {
            url = endPoints.ACCOUNTS.DETAIL_VIEW_TASK_APP;
            let info = getActiveTabInfo(state);
            if (isValidParam(info) && info.isInXpress) {
                params.is_inxpress = info.isInXpress;
                params.inxpress_project_id = info.inXpressProjectId;
            }
          
        } else if (object === constants.OPPORTUNITIES_OBJECT) {
            url = endPoints.OPPORTUNITIES.DETAIL_VIEW_TASK_APP;
        } else if (object === constants.CASES_OBJECT) {
            url = endPoints.CASES.DETAIL_VIEW_TASK_APP;
        } else if (object === constants.PROJECTS_OBJECT) {
            url = endPoints.PROJECTS.DETAIL_VIEW_TASK_APP;
        } else if (object === constants.ISSUES_OBJECT) {
            url = endPoints.ISSUES.DETAIL_VIEW_TASK_APP;
        } else if (object === constants.CONTACTS_OBJECT) {
            url = endPoints.CONTACTS.DETAIL_VIEW_TASK_APP;
        } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
            url = endPoints.CUSTOM_TABLE1.DETAIL_VIEW_TASK_APP;
        } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
            url = endPoints.CUSTOM_TABLE2.DETAIL_VIEW_TASK_APP;
        } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
            url = endPoints.CUSTOM_TABLE3.DETAIL_VIEW_TASK_APP;
        } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
            url = endPoints.CUSTOM_TABLE4.DETAIL_VIEW_TASK_APP;
        } else if (object === constants.RECYCLE_BIN_OBJECT) {
            url = endPoints.ACCOUNTS.DETAIL_VIEW_TASK_APP;
        } else if (object === constants.JOBS_OBJECT) {
            url = endPoints.JOBS.DETAIL_VIEW_TASK_APP;
        } else if(object === constants.COMPLIANCE_OBJECT){
            url = endPoints.COMPLIANCE.DETAIL_VIEW_TASK_APP;
        } else if(object === constants.PARTNERS_OBJECT){
            url = endPoints.PARTNERS.DETAIL_VIEW_TASK_APP;
        }

        return {
            type: actions.DETAILVIEW_TASK_APP_GET,
            payload: HTTPClient.get(url, params)
        }
    } catch (error) {
        console.log("Error in 'detailViewActions.js -> getDetailViewTaskAppointment()': " + error);
    }
}

function saveDetailViewTaskAppointmentParam(params){
    return {
        type: actions.SAVE_DETAILVIEW_TASK_APP_PARAM,
        payload: params
    }
}

function clearDetailViewTaskAppointmentReducer() {
    try {
        let state = store.getState();
        state.detailView.taskAppointment = null;
    } catch (error) {

    }

}

function getGroupCampaignDetails(params){
    return HTTPClient.get(endPoints.GROUP.GROUP_CAMPAIGN_DETAILS, params);
 }

 function groupUsedInCampaigns(params){
   let promise = Promise.resolve(HTTPClient.get(endPoints.GROUP.GROUP_USEDIN_CAMPAIGN, params));
   return promise;
 }

 function getLinkChildInfo(params){
    return HTTPClient.get(endPoints.GROUP.GROUP_LINK_OBJECTS, params);
 }

 function getAccountContacts(recordId){
     let url = endPoints.ACCOUNTS.CONTACTS_GET;
     url = url.replace('{recordid}', recordId);
    return HTTPClient.get(url, null);
 }
 
 function getPartnerContacts(recordId){
    let url = endPoints.PARTNERS.CONTACTS_GET;
    url = url.replace('{recordid}', recordId);
   return HTTPClient.get(url, null);
}

 function getDetailViewPinnedNote() {
    try {
        let url = endPoints.NOTES.PIN_GET;
        let activeTab = getActiveTab();
        let tabInfo = getObjectParam(activeTab.info);
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        /*let isUnitsAccount = false;
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }*/
        if(activeTab.type === TYPE_DETAIL_VIEW && !isInXpress){
            let tabUrl = getStringParam(activeTab.url);
            let arr = tabUrl.split("/");
            let parentRecordId = getIntParam(arr[3]);
            let parentObject = getStringParam(activeTab.object);
            parentObject = parentObject === constants.UNITS_OBJECT ? constants.ACCOUNTS_OBJECT : parentObject
            let params = {parent_object: parentObject, parent_record_id: parentRecordId};
            store.dispatch({type: actions.DETAILVIEW_PINNED_NOTE_GET, payload: HTTPClient.get(url, params)}); 
        }
    } catch (error) {
        console.log("Error in 'detailViewActions.js -> getDetailViewPinnedNote()': " + error);
    }
}

function refreshQueueDetailViewData(isRefresh) {
    return function (dispatch) {
        dispatch({
            type: actions.QUEUEVIEW_IS_REFERSH,
            payload: isRefresh
        })
    }
}

function refreshQueueDetailViewNote(isRefresh) {
    return function (dispatch) {
        dispatch({
            type: actions.QUEUEVIEW_NOTE_IS_REFERSH,
            payload: isRefresh
        })
    }
}

function refreshQuotation(isRefresh) {
    return function (dispatch) {
        dispatch({
            type: actions.QUOTATIONS_IS_REFRESH,
            payload: isRefresh
        })
    }
}

function refreshPurchaseOrder(isRefresh) {
    return function (dispatch) {
        dispatch({
            type: actions.PURCHASE_ORDER_IS_REFRESH,
            payload: isRefresh
        })
    }
}

function getUnitOwnerTasks(params) {
    try {
       let url = endPoints.ACCOUNTS.DETAIL_VIEW_UNIT_OWNER_TASKS;
        return {
            type: actions.DETAILVIEW_UNIT_OWNER_TASKS_GET,
            payload: HTTPClient.get(url, params)
        }
    } catch (error) {
        console.error("Error in 'detailViewActions.js -> getUnitOwnerTasks()':" + error);
    }
}

function updateDetailViewScrollPosition(pos, obj) {
    return {
        type: actions.UPDATE_DETAIL_VIEW_SCROLL_POSITION,
        payload: { pos, obj }
    }
  }
  
  function removeDetailViewScrollPosition(obj) {
    return {
        type: actions.REMOVE_DETAIL_VIEW_SCROLL_POSITION,
        payload: { obj }
    }
  }
export {
    getDetailviewData,
    refreshDetailViewData,
    refreshDetailViewHeader,
    getDetailviewFields,
    isDetailViewSet,
    refreshDetailViewListViewData,
    getDetailViewTaskAppointment,
    clearDetailViewTaskAppointmentReducer,
    getGroupCampaignDetails,
    groupUsedInCampaigns,
    getLinkChildInfo,
    saveDetailViewTaskAppointmentParam,
    detailViewIsMounted,
    refreshDetailViewGroup,
    getAccountContacts,
    getDetailViewPinnedNote,
    refreshQueueDetailViewData,
    refreshQueueDetailViewNote,
    refreshQuotation,
    refreshPurchaseOrder,
    getUnitOwnerTasks,
    updateDetailViewScrollPosition,
    removeDetailViewScrollPosition,
    getPartnerContacts
}