import $ from 'jquery';
import _ from 'lodash';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import AdvancePartnerSearchDate from "../components/AdvancePartnerSearchDate";
import { isValidParam, getStringParam, getObjectParam, getArrayParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import * as SFFormActions from '../../../services/actions/sfFormActions'
import { constants, OBJECT_TABLEID_MAP } from '../../../services/constants/constants';
import { styles } from '../../../services/constants/styles';
import ShowCircularProgress from '../components/circularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl, TextField, Button, Select, MenuItem, InputLabel, Icon, Checkbox, IconButton, ListItemText } from '@mui/material';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { dateFormat, phoneFormat, sortArrayObjectByProperty } from '../../../services/helper/utils';
import moment from 'moment';
import { addTab, TYPE_LIST_VIEW } from '../../../services/helper/sfTabManager';
import { getObjectLabelByObject } from '../../../services/helper/common';
import { getImageNameByObject, setSelectedModuleInfo } from '../../../services/helper/common';
import { setAdvanceSearchCriteria } from '../../../services/actions/appActions';
import * as sfDialogs from '../components/sfDialogs';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import { setAdvancePartnersSearch } from '../../../services/actions/filterMenuAction';
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Popover } from "@mui/material";


const AdvancePartnerSearch = () => {
    const object = constants.PARTNERS_OBJECT;
    const redirectUrl = '/advance-search-results/search';
    const defaultSearchFieldsCount = 20;
    const maximumSearchFieldsCount = 50;
    const minimumSearchFieldsCount = 1;
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const app = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);
    const filters = useSelector((state) => state.filters);
    const [isMounted, setIsMounted] = useState(false);
    const [fields, setFields] = useState([]);
    const [dateControlEl, setDateControlEl] = useState(null);
    const [resentSearchData, setResentSearchData] = useState(null);
    const [searchableFields, setSearchableFields] = useState([]);
    const [searchableFieldProps, setSearchableFieldProps] = useState({});
    const [listValueMenuItems, setListValueMenuItems] = useState({});
    const [modeOfOperation, setModeOfOperation] = useState(null);
    useEffect(() => {
        getRecentSearchData();

    }, []);
    useEffect(() => {
        updateSearchableFields(searchableFields);
    }, [searchableFields]);

    useEffect(() => {
        if (searchableFields.length > defaultSearchFieldsCount && modeOfOperation === 'add') {
            $("#sfas-cointainer-from-id").animate({ scrollTop: searchableFields.length * 60 });
        }
        setIsMounted(true);
    }, [searchableFieldProps]);
    const closeForm = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    };
    const handleKeyUp = useCallback((event) => {
        try {
            if (event.keyCode === 13) {
                handleSearchResult()
            }
        } catch (error) {
            console.error("Error in 'advanceSearchForm.js -> handleKeyUp()':" + error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchableFieldProps])

    useEffect(() => {
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [handleKeyUp]);

    const getRecentSearchData = () => {
        try {
            let objectTableId = OBJECT_TABLEID_MAP[getStringParam(object)];
            let url = endPoints.SEARCH.GET_RECENT_ADVANCED_SEARCH;
            let params = {
                tableid: objectTableId,
                object: object,
                type: "List"
            }
            let searchData = filters.advancePartnersSearch[object];
            if (searchData === null || searchData === undefined) {
                let promise = Promise.resolve(HTTPClient.post(url, params));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.status === 0 && isValidParam(response.data)) {
                            let data = getObjectParam(response.data);
                            for (let key in data) {
                                data[key] = JSON.parse(data[key]);
                            }
                            dispatch(setAdvancePartnersSearch(data));
                            setResentSearchData(data);
                            checkFieldsData(data);
                        }
                    }
                });
            } else {
                setResentSearchData(filters.advancePartnersSearch);
                checkFieldsData(filters.advancePartnersSearch);
            }

        } catch (error) {
            console.error("Error in 'AdvancePartnerSearch.js -> getRecentSearchData()':" + error);
        }
    }

    const checkFieldsData = (searchObj) => {
        let formData = sfForm.data[object];
        if (!isValidParam(formData)) {
            getAllFields(searchObj);
        } else {
            let tempData = JSON.parse(JSON.stringify(getObjectParam(formData)));
            processFieldsData(tempData, searchObj);
        }
    }
    const prepareSearchableProps = (allFields, resentSearchDataObj) => {
        let tempSearchableFields = [];
        let resentSearchDataTemp = resentSearchDataObj[object];
        let resetSearchFieldsCount = 0;
        if (isValidParam(resentSearchDataTemp) && resentSearchDataTemp.length > 0 && isValidParam(allFields) && allFields.length > 0) {
            resentSearchDataTemp.map((_searchData, index) => {
                allFields.map((field, index) => {
                    if (field.name === _searchData.name) {
                        field.value = _searchData.value;
                        tempSearchableFields.push(field);
                    }
                })
            })
            resetSearchFieldsCount = resentSearchDataTemp.length;

        }
        if (resetSearchFieldsCount < defaultSearchFieldsCount) {
            for (let j = 0; j < (defaultSearchFieldsCount - resetSearchFieldsCount); j++) {
                tempSearchableFields.push(getDefaultFieldsType(tempSearchableFields.length));
            }
        }
        setSearchableFields([...tempSearchableFields]);


    }

    const updateSearchableFields = (finalSearchableFields) => {
        try {
            let tempSearchableFieldProps = {};
            let tempfield = null;
            let workflowId = 0;
            if (isValidParam(finalSearchableFields) && finalSearchableFields.length > 0) {
                let tempFields = finalSearchableFields.filter(f => { return f.field_type !== constants.FIELD_TYPE_HEADER });
                tempFields.map((field, index) => {
                    if (isValidParam(field)) {
                        if(field.name === "sales_rep"){
                              field.field_type = constants.FIELD_TYPE_TEXT;
                        }
                        if (field.field_type === 'list') {
                            setListValueMenuItemsFunction(field.name, field.list_values);
                        }
                        let fieldList = getFieldWiseFieldListOptions(field);;
                        tempfield = {};
                        tempfield.id = field.id;
                        tempfield.name = field.name;
                        tempfield.label = field.label;
                        tempfield.isPhoneField = field.is_phone_field;
                        tempfield.value = field.value;
                        tempfield.fieldType = field.field_type;
                        tempfield.listType = field.list_type;
                        tempfield.selectedListIds = '';
                        if (field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                            tempfield.selectedListIds = '';

                            tempfield.selectedListValues = getStringParam(tempfield.value);
                            tempfield.selectedListValues = tempfield.selectedListValues !== '' ? tempfield.selectedListValues.split(',') : [];

                        }
                        tempfield.queryName = field.query_name;
                        tempfield.isOpenPopover = false;
                        tempfield.popoverTarget = null;
                        tempfield.helperText = '';
                        tempfield.fieldList = fieldList;
                        if (isValidParam(tempfield.value) && tempfield.value === '' && tempfield.fieldType === 'list' && field.list_values.length > 0) {
                            tempfield.value = field.list_values[0].value;
                        }
                    }
                    tempSearchableFieldProps[field.name] = tempfield;
                    return 0;
                });

                if (tempSearchableFieldProps.hasOwnProperty('workflow_id')) {
                    tempSearchableFieldProps['workflow_id'].value = workflowId;
                }

                setSearchableFieldProps({ ...tempSearchableFieldProps });
                setWFMenuItemList(workflowId,
                    tempSearchableFieldProps['t_status']?.name,
                    tempSearchableFieldProps['t_status']?.queryName,
                    constants.LIST_FIELD_TYPE_REGULAR);

            }


        } catch (error) {
            console.error("Error in 'AdvancePartnerSearch.js -> updateSearchableFields()':" + error);
        }
    }
    const getFieldWiseFieldListOptions = (fieldObj) => {
        let allFields = fields;
        let selectedObj = {};
        let filteredTempFields = allFields;
        let tempFields = [];
        searchableFields.forEach((searchableField, index) => {
            filteredTempFields = filteredTempFields.filter(f => {
                let temp;
                if (f.name !== searchableField.name) {
                    temp = f.name;
                }
                return temp;
            });
        });
        filteredTempFields.map((field, index) => {
            let tempObj = {};
            tempObj.label = field.label;
            tempObj.name = field.name;
            tempObj.id = field.id;
            tempFields.push(tempObj);
            return 0;
        });
        selectedObj.label = fieldObj.label;
        selectedObj.name = fieldObj.name;
        selectedObj.id = fieldObj.id;
        if (fieldObj.id !== -99999) {
            tempFields.push(selectedObj);
        }
        sortArrayObjectByProperty(tempFields, 'label');
        if (fieldObj.id === -99999) {
            tempFields.unshift(selectedObj);
        }
        return tempFields;
    }

    const setListValueMenuItemsFunction = (fieldName, listValues) => {
        let menuItems = null;
        try {
            listValues = getArrayParam(listValues);
            let count = listValues.filter(f => { return f.id === '' });

            if (count.length === 0) {
                listValues.unshift({ id: '', value: '' });
            }
            menuItems = []
            listValues.map((listValue, listValueIndex) => {
                let tempObj = {};
                tempObj.key = listValue.id;
                tempObj.value = listValue.value;
                tempObj.label = listValue.value;
                tempObj.id = listValue.id;
                menuItems.push(tempObj);
                return 0;
            });

            let listValueMenuItemsTemp = listValueMenuItems;
            listValueMenuItemsTemp[fieldName] = menuItems;
            setListValueMenuItems({ ...listValueMenuItemsTemp });
        } catch (error) {
            console.error("Error in 'AdvancePartnerSearch.js -> setListValueMenuItems()':" + error);
        }
        return menuItems;
    }
    const getSearchResult = (obj) => {

        let isRedirect = getBooleanParam(obj.isRedirect);
        let advanceSearch = getObjectParam(obj.advanceSearch);
        advanceSearch = { advanceSearch: advanceSearch };
        if (isRedirect) {
            closeForm();
            setSelectedModuleInfo(object);
            let labelName = getObjectLabelByObject(object) + ' ' + getLocalizedStrings().label.COMMON.ADVANCED_SEARCH;
            let iconName = getImageNameByObject(constants.ADVANCE_SEARCH_RESULTS);
            let tempTab = { item: object, label: labelName, object: constants.ADVANCE_SEARCH_RESULTS, actionObject: constants.ADVANCE_PARTNE_RSEARCH, type: TYPE_LIST_VIEW, imgName: iconName, info: advanceSearch, url: obj.redirectUrl, refreshFromSettings: true };
            addTab(tempTab, true);
        }
    }

    const setWFMenuItemList = (workflowId, name, queryName, listType) => {
        try {
            if (workflowId > 0) {

                let params = {};
                params.object = object;
                params.id = workflowId;
                params.name = name;
                params.query_name = queryName;
                params.query_type = 'customQuery';
                params.list_type = listType;

                var promise = SFFormActions.getFieldListValuesPromise(params);
                promise.then((response) => {
                    if (isValidParam(response)) {
                        setListValueMenuItems({ ...listValueMenuItems });
                        setListValueMenuItemsFunction('t_status', response);
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'AdvancePartnerSearch.js -> setWFMenuItemList()':" + error);
        }
    }
    const getAllFields = (searchObj) => {
        try {
            var promise = SFFormActions.getSFFormFieldsPromise(object);
            promise.then((response) => {
                if (isValidParam(response)) {
                    sfForm.data[object] = JSON.parse(JSON.stringify(getObjectParam(response)));
                    processFieldsData(response, searchObj);
                }
            });
        } catch (error) {
            console.error("Error in 'AdvancePartnerSearch.js -> getAllFields()':" + error);
        }
    }
    const processFieldsData = (data, searchObj) => {
        try {
            if (isValidParam(data)) {
                let _fields = [];
                let tempFields = data.fields?.filter(f => { return f.field_type !== constants.FIELD_TYPE_HEADER && f.is_hidden_field !== true });
                tempFields?.map((field, index) => {
                    if (field.is_readonly) {
                        field.is_readonly = false;
                    }
                    if (field.name !== undefined && field.name !== null && field.name !== '') {
                        _fields.push(field);
                    }
                });
                sortArrayObjectByProperty(_fields, 'label');
                setFields(_fields);
                prepareSearchableProps(_fields, searchObj);
            }
        } catch (error) {
            console.error("Error in 'AdvancePartnerSearch.js -> processFieldsData()':" + error);
        }
    }



    const getFromCointainer = (cointainerStyle) => {
        let component = null;
        component =
            <div key={'sfas-cointainer-from'}>
                <div className='sfas-cointainer-from-class' id='sfas-cointainer-from-id' style={cointainerStyle}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid sx={12} container columnSpacing={{ xs: 1, sm: 2, md: 4 }}>
                            {getFromFieldSet()}
                        </Grid>
                    </Box>
                </div>
                <div>
                    {(searchableFields.length < maximumSearchFieldsCount) &&
                        <div style={{ marginTop: '10px', }}>
                            <a style={{ cursor: 'pointer' }} onClick={() => addTextField()}>
                                {getLocalizedStrings().label.ADVANCE_SEARCH.ADD_FIELD}
                            </a>
                        </div>
                    }</div>
            </div>
        return component;
    }
    const getFromFieldSet = () => {

        let fieldRows = [];
        let keyCount = 0;
        try {
            if (isValidParam(searchableFieldProps)) {
                for (var key in searchableFieldProps) {
                    ++keyCount;
                    let component = getTypeWiseFormField(searchableFieldProps[key], key, keyCount);
                    let fieldList = null;
                    let listComponent = null;
                    let fieldName = key;
                    if (searchableFieldProps[key].hasOwnProperty('fieldList')) {
                        fieldList = searchableFieldProps[key].fieldList;
                    }
                    if (fieldList === null) {
                        listComponent = <MenuItem
                            onClick={() => changeField(fieldName, keyCount)}
                            value={searchableFieldProps[key].name}
                            style={{ ...styles.popoverMenuItem }}
                            className='menuItemUpdateFields'
                            title={searchableFieldProps[key].label} >{searchableFieldProps[key].label}</MenuItem>;
                    } else {
                        listComponent = populateFieldList(searchableFieldProps[key].fieldList, keyCount);
                    }

                    fieldRows.push(
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            key={`grid-${keyCount}`}

                        >
                            <div id={'col_' + keyCount} key={"1_" + key} style={{ ...styles.row, padding: 0, marginRight: 5 }}>
                                <div key={"2_" + key} style={{ ...styles.sf_5 }}>
                                    <FormControl variant="outlined" style={{ width: '100%', maxWidth: '150px' }}>
                                        <InputLabel id="sf-automation-fields-update-from-automationfieldupdate-simple-select-outlined-label" className="sf-automation-fields-update-from-automationfieldupdates"> </InputLabel>
                                        <Select
                                            key={"3_" + key}
                                            value={searchableFieldProps[key].name}
                                            labelId='sf-label-field'
                                            id='sf-field'
                                            style={{ height: '38px', width: '100%', margin: '8 0 8 0' }}
                                            className={"sf-fields-bg"}
                                        >
                                            {listComponent}
                                        </Select>
                                    </FormControl>
                                    <input key={'input_hidden_' + keyCount} type="hidden" id={"field_name_" + keyCount} value={fieldName} />
                                </div>

                                <div key={'component_' + keyCount} style={{ width: '100%' }}>
                                    {component}
                                </div>

                                <div key={'cross_icon_div_' + keyCount} style={{ width: 25, paddingTop: '15px' }}>
                                    <IconButton size="small" aria-label="remove" onClick={() => removeField(fieldName)}>
                                        <Icon key={'icon_' + key} style={{ 'fontSize': '18px', fontWeight: 'normal', color: '#717171' }} title={getLocalizedStrings().label.HOME.REMOVE}>close</Icon>
                                    </IconButton>

                                </div>
                            </div>
                        </Grid>
                    );
                }
            }
        } catch (error) {
            console.error("Error in 'AdvancePartnerSearch.js -> getFromFieldSet()': " + error);
        }
        return fieldRows;
    }
    const getDateToShow = (fieldName) => {
        let dateToShow = '';
        let dateInfo = getObjectParam(searchableFieldProps[fieldName].value);
        let operator = getStringParam(dateInfo.operator);
        if (operator === '=') {
            dateToShow = moment(getStringParam(dateInfo.value)).format(dateFormat[app.me.date_format]);
        } else if (operator === 'Range') {
            dateToShow = moment(getStringParam(dateInfo.from_date)).format(dateFormat[app.me.date_format]) + ' - ' + moment(getStringParam(dateInfo.to_date)).format(dateFormat[app.me.date_format]);
        } else if (operator === '>' || operator === '<') {
            dateToShow = dateInfo.operator + ' ' + moment(getStringParam(dateInfo.value)).format(dateFormat[app.me.date_format]);
        }
        return dateToShow;
    }
    const getTypeWiseFormField = (field, key, index) => {
        let component = null;
        let sfFormFieldContainerStyle = null;
        let autoCompleteValue = "new-password";
        let positionIndex = index - 1;
        if (field.name === "sc_source") {
            field.fieldType = "text";
        }
        if (field.is_hidden_field !== true) {
            sfFormFieldContainerStyle = { ...styles.sfFormFieldContainer };
            component = null;
            let fieldLabel = null;
            fieldLabel = field.label;
            if (searchableFieldProps.hasOwnProperty(field.name) && getStringParam(searchableFieldProps[field.name].value) !== '') {
                sfFormFieldContainerStyle.border = '1px solid #555';

            }
            if (field.id !== -99999) {
                if (field.fieldType === constants.FIELD_TYPE_TEXT
                    || (field.fieldType === constants.FIELD_TYPE_INTEGER && field.name !== 'workflow_id')
                    || field.fieldType === constants.FIELD_TYPE_FLOAT
                    || field.fieldType === constants.FIELD_TYPE_URL
                    || field.fieldType === constants.FIELD_TYPE_MEMO) {
                    component = <FormControl style={{ width: '100%', maxWidth: '365px' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            key={field.id}
                            inputstyle={styles.textField.input}
                            disabled={field.is_readonly}
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '96%',
                                }
                            }}
                            fullWidth={true}
                            name={field.name}
                            value={searchableFieldProps[field.name].value}
                            onChange={(e) => setFieldValue(e, positionIndex, field.name, field.fieldType)}
                            className={field.is_lookup_field ? "form-field-search-icon" : ""}
                            inputprops={{ maxlength: searchableFieldProps[field.name].field_length }}
                            ref={inputRef}
                            autoComplete={autoCompleteValue}
                            margin='dense'
                            size='small'
                            autoFocus={true}
                        />
                    </FormControl>;
                } else if (field.fieldType === constants.FIELD_TYPE_LIST) {
                    if (field.listType === constants.LIST_FIELD_TYPE_REGULAR
                        || field.listType === constants.LIST_FIELD_TYPE_USER
                        || field.listType === constants.LIST_FIELD_TYPE_EXTERNAL) {
                        let _listValueMenuItems = isValidParam(listValueMenuItems[field.name]) ? listValueMenuItems[field.name] : [];
                        let autocompleteValue = searchableFieldProps[field.name] !== null && searchableFieldProps[field.name] !== undefined ? searchableFieldProps[field.name].value : null;
                        component = <FormControl style={{ width: '100%', maxWidth: '365px' }}>
                            <Autocomplete
                                style={{ width: '100%' }}
                                disableClearable
                                options={_listValueMenuItems}
                                getOptionLabel={(option) => option.label ? option.label : ''}
                                value={_listValueMenuItems.find(v => v.value === autocompleteValue)}
                                disabled={field.is_readonly}
                                id={'adv-sf-auto-complete-id-' + field.name}
                                name={'adv-sf-auto-complete-name-' + field.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        inputprops={{ maxlength: searchableFieldProps[field.name].field_length, ...params.InputProps }}
                                        disabled={field.is_readonly}
                                        fullWidth={true}
                                        margin='dense'
                                        size='small'
                                        className={"sf-fields-bg"}
                                        InputLabelProps={{
                                            style: {
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                width: '90%',
                                            }
                                        }}
                                    />
                                )}
                                onChange={(event, value) => setFieldValue(event, positionIndex, field.name, field.fieldType, value)}
                            />

                        </FormControl>
                    } else if (field.listType === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                        let _listValueMenuItems = isValidParam(listValueMenuItems[field.name]) ? listValueMenuItems[field.name] : [];
                        component =
                            <FormControl style={{ width: '100%', maxWidth: '365px' }} variant="outlined">
                                <Select
                                    key={field.id}
                                    id={'id' + field.id}
                                    multiple
                                    value={searchableFieldProps[field.name].selectedListValues}
                                    onChange={(e) => setMultiSelectValue(e, field.name, positionIndex)}
                                    disabled={field.is_readonly}
                                    className={"sf-fields-bg"}
                                    style={{ height: '38px', width: '100%', margin: '8 0 8 0' }}
                                    renderValue={(selected) => selected.join(', ')}
                                >
                                    {
                                        _listValueMenuItems.map((listValue, listValueIndex) => {
                                            return <MenuItem id={listValue.id} key={listValue.id} value={listValue.value} title={listValue.value}>
                                                <Checkbox checked={searchableFieldProps[field.name].selectedListValues && searchableFieldProps[field.name].selectedListValues.indexOf(listValue.value) > -1 ? true : false} />
                                                <ListItemText primary={listValue.value} />
                                            </MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                    }
                } else if (field.fieldType === constants.FIELD_TYPE_DATE_TIME
                    || field.fieldType === constants.FIELD_TYPE_DATE) {
                    component = <> <FormControl style={{ width: '100%', maxWidth: '365px' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            key={field.id}
                            disabled={field.is_readonly}
                            InputLabelProps={{
                                style: {
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    width: '96%',
                                }
                            }}
                            fullWidth={true}
                            name={field.name}
                            value={getDateToShow(field.name)}
                            className={field.is_lookup_field ? "form-field-search-icon" : ""}
                            onClick={(e) => setDateControlEl(e)}
                            autoComplete={autoCompleteValue}
                            margin='dense'
                            size='small'
                        />
                    </FormControl>
                        <Popover
                            open={Boolean(dateControlEl)}
                            anchorEl={dateControlEl}
                            onClose={() => setDateControlEl(null)}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                            }}
                        >
                            <div style={{ width: 450 }}>
                                <AdvancePartnerSearchDate style={{ marginTop: '20px' }} fieldData={field}
                                    onSetDate={(fieldName, dateInfo) => {setDate(fieldName, dateInfo, positionIndex);setDateControlEl(null)}}
                                    defaultValue={searchableFieldProps[field.name].value}
                                    fieldLabel={fieldLabel}
                                /></div>
                        </Popover>
                    </>;
                }
            } else if (field.fieldType === constants.FIELD_TYPE_TEXT && field.id === -99999) {
                component = <div
                    name={field.name}
                    key={"button" + field.name}
                    id={"button" + field.id}
                    onClick={(e) => openAlertPopOver(e)}
                    style={{ color: 'rgb(97, 97, 97)', border: '1px solid #c4c4c4', height: '37px', borderRadius: '4px', background: '#fff', padding: '1px 8px', margin: '8 0 8 0', cursor: 'pointer', width: '100%' }}
                ></div>
            }
            return (
                <div key={field.name} style={{ padding: '0 5 0 5' }}>{component}</div>);
        }
        return component;
    }
    const changeField = (fieldName, keyCount) => {
        try {
            let field = fields.filter(f => { return f.name === fieldName });
            let tempSearchableFields = searchableFields;
            tempSearchableFields.splice((keyCount - 1), 1, field[0]);
            setModeOfOperation('update')
            setSearchableFields([...tempSearchableFields]);
            updateSearchableFields(tempSearchableFields);
        } catch (error) {
            console.error("Error in 'AdvancePartnerSearch.js -> changeField()': " + error);
        }
    }
    const populateFieldList = (arrayFields, keyCount) => {
        let fieldList = null;
        if (isValidParam(arrayFields)) {
            fieldList = arrayFields.map((objField, i) => {
                return (
                    <MenuItem
                        onClick={() => changeField(objField.name, keyCount)}
                        value={objField.name}
                        style={{ ...styles.popoverMenuItem }}
                        className='menuItemUpdateFields'
                        title={objField.label}
                    >{objField.label}</MenuItem>
                );
            });
        }
        return fieldList;
    }

    const setStateMenuItemList = (value) => {
        try {
            let params = {};
            params.object = object;
            params.name = 'state';
            params.value = value;
            params.list_type = searchableFieldProps[params.name]?.listType;

            var promise = SFFormActions.getFieldListValuesPromise(params);
            promise.then((response) => {
                if (isValidParam(response)) {
                    setListValueMenuItemsFunction(params.name, response);
                    if (isValidParam(searchableFieldProps[params.name])) {
                        let searchableFieldPropsTemp = searchableFieldProps;
                        searchableFieldPropsTemp[params.name].value = response.length > 0 ? response[0].value : '';
                        setModeOfOperation('update')
                        setSearchableFieldProps({ ...searchableFieldPropsTemp });
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'AdvancePartnerSearch.js -> setStateMenuItemList()':" + error);
        }
    }

    const setFieldValue = (event, positionIndex, fieldName, fieldType, value) => {
        let searchableFieldPropsTemp = searchableFieldProps;
        let searchableFieldsTemp = searchableFields;
        if (fieldType === constants.FIELD_TYPE_TEXT || fieldType === constants.FIELD_TYPE_FLOAT || fieldType === constants.FIELD_TYPE_MEMO || fieldType === constants.FIELD_TYPE_URL || fieldType === constants.FIELD_TYPE_INTEGER) {
            value = (event.target.value);
        }
        if (searchableFieldProps[fieldName].fieldType === constants.FIELD_TYPE_TEXT
            || searchableFieldProps[fieldName].fieldType === constants.FIELD_TYPE_INTEGER
            || searchableFieldProps[fieldName].fieldType === constants.FIELD_TYPE_FLOAT
            || searchableFieldProps[fieldName].fieldType === constants.FIELD_TYPE_MEMO) {
            searchableFieldPropsTemp[fieldName].value = value;
        } else if (searchableFieldProps[fieldName].fieldType === constants.FIELD_TYPE_LIST) {
            searchableFieldPropsTemp[fieldName].value = isValidParam(value) ? value.value : '';
            if (fieldName === 'country') {
                setStateMenuItemList(value);
            }
        } else {
            searchableFieldPropsTemp[fieldName].value = value;
        }
        let isPhoneField = getBooleanParam(searchableFieldProps[fieldName].isPhoneField);
        if (isPhoneField) {
            searchableFieldPropsTemp[fieldName].value = phoneFormat(searchableFieldProps[fieldName].value);
        }
        searchableFieldsTemp[positionIndex].value = value;
        if (searchableFieldProps[fieldName].fieldType === constants.FIELD_TYPE_LIST) {
            searchableFieldsTemp[positionIndex].value = isValidParam(value) ? value.value : '';
        }
        searchableFieldPropsTemp[fieldName].helperText = '';
        setModeOfOperation('update')
        setSearchableFieldProps({ ...searchableFieldPropsTemp });
        setSearchableFields([...searchableFieldsTemp]);
    }
    const setMultiSelectValue = (event, fieldName, positionIndex) => {
        try {
            let value = event.target.value;
            let stringValue = '';
            if (value.length > 0) {
                for (let j = 0; j < value.length; j++) {
                    if (j < (value.length - 1)) {
                        stringValue += value[j] + ",";
                    } else {
                        stringValue += value[j];
                    }
                }
            }
            let searchableFieldsTemp = searchableFields;
            searchableFieldsTemp[positionIndex].value = stringValue;
            searchableFieldsTemp[positionIndex].selectedListValues = value;
            searchableFieldsTemp[positionIndex].helperText = '';
            setSearchableFields([...searchableFieldsTemp]);

            let searchableFieldPropsTemp = searchableFieldProps;
            searchableFieldPropsTemp[fieldName].value = stringValue;
            searchableFieldPropsTemp[fieldName].selectedListValues = value;
            searchableFieldPropsTemp[fieldName].helperText = '';
            setModeOfOperation('update')
            setSearchableFieldProps({ ...searchableFieldPropsTemp });

        } catch (error) {
            console.error("Error in 'AdvancePartnerSearch.js -> setMultiSelectValue()':" + error);
        }
    }
    const setDate = (fieldName, dateInfo, positionIndex) => {
        let searchableFieldsTemp = searchableFields;
        let searchableFieldPropsTemp = searchableFieldProps;
        dateInfo = getObjectParam(dateInfo);
        searchableFieldsTemp[positionIndex].value = dateInfo;
        searchableFieldPropsTemp[fieldName].value = dateInfo;
        setModeOfOperation('update')
        setSearchableFields([...searchableFieldsTemp]);
        setSearchableFieldProps({ ...searchableFieldPropsTemp });
    }
    const openAlertPopOver = (e) => {
        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ADVANCE_SEARCH.SELECT_FIELD_ALERT, null);
    }
    const removeField = (fieldName) => {
        let _fields = searchableFields.filter(f => { return f.name !== fieldName });
        if (_fields.length >= minimumSearchFieldsCount) {

            setSearchableFields(_fields);
            setModeOfOperation('remove');
            updateSearchableFields(_fields);
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ADVANCE_SEARCH.MIN_ADD_FIELD_ALERT, null);
        }
    }
    const addTextField = () => {
        let _fields = searchableFields;
        if (_fields.length < maximumSearchFieldsCount) {
            _fields.push(getDefaultFieldsType(_fields.length));

            setSearchableFields(_fields);
            setModeOfOperation('add');
            updateSearchableFields(_fields);
        }
        if (_fields.length >= maximumSearchFieldsCount) {
            // sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ADVANCE_SEARCH.MAX_ADD_FIELD_ALERT, null);
        }
    }
    const getDefaultFieldsType = (idx) => {
        let fieldObject = {
            "id": -99999,
            "name": "sf_default_option_select_field_" + idx + '_' + Date.now(),
            "label": "Select",
            "field_type_id": null,
            "field_type": "text",
            "field_length": 64,
            "is_hidden_field": false,
            "is_required_field": false,
            "is_system_field": false,
            "is_readonly": false,
            "default_value": "",
            "t_tablefldid": null,
            "is_title_field": false,
            "is_email_field": false,
            "is_phone_field": false,
            "is_url_field": false,
            "is_currency_field": false,
            "is_notify_field": false,
            "is_modify_list": false,
            "list_type": "",
            "list_values": [],
            "list_type_id": -1,
            "is_lookup_field": false,
            "lookup_object": "",
            "lookup_field_name": "",
            "query_name": "",
            "query_id": null,
            "col_span": 1
        }
        return fieldObject;
    }
    const getActionButtons = () => {
        let buttons = [];
        buttons.push(
            <Button
                key='search'
                onClick={() => handleSearchResult()}
                style={{ ...styles.primaryButton, marginRight: '12px', verticalAlign: 'top' }}
            >{getLocalizedStrings().label.COMMON.SEARCH}</Button>
        );
        buttons.push(
            <Button
                key='clear'
                onClick={() => clearSearch()}
                style={{ ...styles.secondaryButton, marginRight: '6px', verticalAlign: 'top' }}
            >{getLocalizedStrings().label.COMMON.CLEAR}</Button>
        );
        buttons.push(
            <Button
                onClick={() => closeForm()}
                style={{ ...styles.secondaryButton, marginRight: '6px', verticalAlign: 'top' }}
            >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
        );
        return buttons;
    }
    const handleSearchResult = (target) => {
        let selectedObject = object;
        let searchFields = [];
        let fieldObj = getObjectParam(searchableFieldProps);
        let isRedirect = false;
        let advanceSearch = null;
        let searchValues = [];
        try {
            if (fieldObj !== '') {
                for (let fieldName in fieldObj) {
                    if (isValidParam(fieldName) && isValidParam(fieldObj[fieldName].value)
                        && (fieldObj[fieldName].fieldType === constants.FIELD_TYPE_DATE || fieldObj[fieldName].fieldType === constants.FIELD_TYPE_DATE_TIME)) {

                        let dateInfo = getObjectParam(fieldObj[fieldName].value);

                        if ((dateInfo.operator === 'Range' && getStringParam(dateInfo.from_date) !== '' && getStringParam(dateInfo.to_date) !== '')
                            || (dateInfo.operator !== 'Range' && getStringParam(dateInfo.value) !== '')) {

                            if (dateInfo.operator === 'Range') {
                                let fromDate = getStringParam(dateInfo.from_date);
                                fromDate = moment(fromDate);
                                if (isValidParam(fromDate)) {
                                    fromDate = fromDate.format('YYYY-MM-DD');
                                    fromDate = getStringParam(fromDate);
                                } else {
                                    fromDate = '';
                                }

                                let toDate = getStringParam(dateInfo.to_date);
                                toDate = moment(toDate);
                                if (isValidParam(toDate)) {
                                    toDate = toDate.format('YYYY-MM-DD');
                                    toDate = getStringParam(toDate);
                                } else {
                                    toDate = '';
                                }

                                if (fromDate !== '' && toDate !== '') {
                                    dateInfo.from_date = fromDate;
                                    dateInfo.to_date = toDate;
                                    let object = {};
                                    object[fieldName] = dateInfo;
                                    searchFields.push(object);
                                    let searchObject = {};
                                    searchObject.name = fieldName;
                                    searchObject.value = dateInfo;
                                    searchValues.push(searchObject);
                                }
                            } else if (dateInfo.operator === '{none}') {
                                dateInfo.value = null;
                                let object = {};
                                object[fieldName] = dateInfo;
                                searchFields.push(object);
                                let searchObject = {};
                                searchObject.name = fieldName;
                                searchObject.value = dateInfo;
                                searchValues.push(searchObject);
                            } else {
                                let value = getStringParam(dateInfo.value);
                                value = moment(value);
                                if (isValidParam(value)) {
                                    value = value.format('YYYY-MM-DD');
                                    value = getStringParam(value);
                                } else {
                                    value = '';
                                }

                                if (value !== '') {
                                    dateInfo.value = value;
                                    let object = {};
                                    object[fieldName] = dateInfo;
                                    searchFields.push(object);
                                    let searchObject = {};
                                    searchObject.name = fieldName;
                                    searchObject.value = dateInfo;
                                    searchValues.push(searchObject);
                                }
                            }
                        }
                    } else if (isValidParam(fieldName) && isValidParam(fieldObj[fieldName].value)) {
                        let object = {};
                        let searchObject = {};
                        if (selectedObject === constants.CONTACTS_OBJECT && fieldName === "workflow_name") {
                            object[fieldName] = fieldObj[fieldName].value;
                            searchObject.name = fieldName;
                            searchObject.value = fieldObj[fieldName].value;
                        } else if (fieldObj[fieldName].value !== '') {
                            if (fieldObj[fieldName].fieldType === constants.FIELD_TYPE_FLOAT) {
                                let tempVal = getStringParam(fieldObj[fieldName].value);
                                tempVal = parseFloat(tempVal);
                                object[fieldName] = tempVal;
                                searchObject.name = fieldName;
                                searchObject.value = tempVal;
                            } else if (fieldObj[fieldName].fieldType === constants.FIELD_TYPE_INTEGER) {
                                object[fieldName] = parseInt(fieldObj[fieldName].value);
                                searchObject.name = fieldName;
                                searchObject.value = parseInt(fieldObj[fieldName].value);
                            } else {
                                object[fieldName] = fieldObj[fieldName].value.trim();
                                searchObject.name = fieldName;
                                searchObject.value = fieldObj[fieldName].value.trim();
                                if (fieldObj[fieldName].listType === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                                    searchObject.listType = constants.LIST_FIELD_TYPE_MULTI_SELECT;
                                }
                            }
                        }
                        let isEmptyObject = _.isEmpty(object)
                        let isEmptySearchObject = _.isEmpty(searchObject)
                        if (!isEmptyObject) {
                            searchFields.push(object);
                        }
                        if (!isEmptySearchObject) {
                            searchValues.push(searchObject);
                        }
                    }
                }
            }

            if (searchFields.length > 0) {
                advanceSearch = { object: object, searchParams: { search: searchFields } };
                dispatch(setAdvanceSearchCriteria(advanceSearch));
                saveAdvancedSearch(searchValues);
                filters.advancePartnersSearch[object] = searchValues;
                isRedirect = true;
                getSearchResult({ isRedirect: isRedirect, redirectUrl: redirectUrl, advanceSearch: advanceSearch });
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ADVANCE_SEARCH.SEARCH_FIELDS_NOT_FOUND, null);
                return false;
            }
        } catch (error) {
            console.error("Error in 'AdvancePartnerSearch.js -> handleSearchResult()':" + error);
        }
    }

    const saveAdvancedSearch = (searchFields) => {
        let objectTableId = OBJECT_TABLEID_MAP[getStringParam(object)];
        let url = endPoints.SEARCH.SAVE_RECENT_ADVANCED_SEARCH;
        let params = {
            tableid: objectTableId,
            condition: searchFields,
            object: object,
            type: "List"
        }
        try {
            let promise = Promise.resolve(HTTPClient.post(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === 0) {
                        filters.advancePartnersSearch[object] = searchFields;
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'AdvancePartnerSearch.js -> saveAdvancedSearch()':" + error);
        }
    }
    const clearSearch = () => {
        let objectTableId = OBJECT_TABLEID_MAP[getStringParam(object)];
        let url = endPoints.SEARCH.DELETE_RECENT_ADVANCED_SEARCH;
        let params = {
            tableid: objectTableId,
            object: object
        }
        try {
            let searchData = filters.advancePartnersSearch[object];
            if (searchData !== null && searchData !== undefined) {
                let promise = Promise.resolve(HTTPClient.post(url, params));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.status === 0) {
                            clearSearchFields();
                            //this.props.filters.advancePartnersSearch[object] = null;
                        }
                    }
                });
            } else {
                clearSearchFields()
            }
        } catch (error) {
            console.error("Error in 'AdvanceSearchForm.js -> clearSearch()':" + error);
        }
    }
    const clearSearchFields = () => {
        let tempSearchableFields = [];
        let resentSearchDataTemp = resentSearchData;
        resentSearchDataTemp[object] = null;
        setResentSearchData(resentSearchDataTemp);
        searchableFields.map((field, index) => {
            field.value = "";
            if (field.list_type === constants.LIST_FIELD_TYPE_MULTI_SELECT) {
                field.selectedListIds = '';
                field.selectedListValues = '';
                field.value = '';
            }
            tempSearchableFields.push(field);
            return 0;
        });

        setSearchableFields(tempSearchableFields);
        updateSearchableFields(tempSearchableFields);
    }

    let objLabel = getObjectLabelByObject(object);
    let contentHeight = window.innerHeight - 320;
    let top = (contentHeight - 40) / 2;

    let cointainerStyle = { height: contentHeight, overflowY: 'auto', width: '100%' }
    return (
        <div>
            <div  >
                <div>
                    {isMounted && searchableFields.length > 0 ?
                        <div>
                            {getFromCointainer(cointainerStyle)}
                        </div>
                        :
                        <div style={{ width: '100%', height: contentHeight }}>
                            <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                                <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                                    <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                                </div>
                            </div>
                        </div>
                    }
                </div>


                <div style={{ ...styles.row, justifyContent: 'flex-end' }}>
                    <div style={{ marginTop: '20px', paddingRight: '10px' }}>
                        {getActionButtons()}
                    </div>
                </div>
            </div>
        </div >
    )
}


export default AdvancePartnerSearch;