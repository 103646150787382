import store from '../../../src/services/store';
import { isValidParam, getObjectParam, getArrayParam, getStringParam, getIntParam, getBooleanParam, makeValidObjectName, makeValidAppUrl } from './parameterVerifier';
import { getUserInfo, getImageNameByObject, getObjectNameByLabel, getSelectedObject, getObjectLabelByObject } from '..//helper/common';
import { getCookie } from '..//helper/sfCookies';
import { setSFTabs } from '..//actions/tabActions';
import { appRedirect } from '..//actions/appActions';
import { constants } from '..//constants/constants';
import { LinkToType } from '..//constants/link';
import { getLocalizedStrings } from '..//constants/MultiLingual';
import { getIconClass } from '..//helper/common';
import * as sfDialogs from '../../app/scripts/components/sfDialogs';
import { removeScrollPosition } from '..//actions/listViewAcions';
import { setPowerCallingNoteButtonVisibilityUpdate, resetPowerCallingData } from '..//actions/callAction';
import { updateColumnViewHorizontalScrollPosition, updateColumnViewScrollPosition } from '..//actions/listsActions';
import { DeleteIntakeFormData } from '../actions/IntakeFormAction';
import { setHeader } from "..//actions/headerActions";
import { setSelectedAuditfilter } from '..//actions/listViewAcions';
/**
 * @author Pradyut(2017-11-02) 
 */
const MAX_TAB_SIZE = 7;
const TYPE_HOME = 'TYPE_HOME';
const TYPE_LIST_VIEW = 'TYPE_LIST_VIEW';
const TYPE_DETAIL_VIEW = 'TYPE_DETAIL_VIEW';
const TYPE_DETAIL_VIEW_TASK_LIST = 'TYPE_DETAIL_VIEW_TASK_LIST';
const TYPE_REPORT = 'TYPE_REPORT';
const TYPE_WEBSITE_TRACKING = 'TYPE_WEBSITE_TRACKING';
const TYPE_DEFAULT = TYPE_HOME;
const TYPE_LINK_CLICK_PAGE = 'TYPE_LINK_CLICK_PAGE';
const TYPE_DASHBOARD = 'TYPE_DASHBOARD';
const TYPE_OTHER_VIEW = 'TYPE_OTHER_VIEW';
const TYPE_COLUMN_VIEW = 'TYPE_COLUMN_VIEW';
const TYPE_EMAIL_REPORT_LISTVIEW = 'TYPE_EMAIL_REPORT_LIST_VIEW';
const TYPE_POWER_DIALER = 'TYPE_POWER_DIALER';
const TYPE_GANTT_CHART = 'TYPE_GANTT_CHART';
const TYPE_JOB_SCHEDULER = 'TYPE_JOB_SCHEDULER';


const ICON_NAME_HOME = 'fa fa-home';

const defaultTab = [{
    index: 0,
    item: 'calendartask',
    label: 'Calendar/Tasks',
    object: 'calendartask',
    type: TYPE_DEFAULT,
    imgName: 'fa fa-calendar',
    url: '/appointments/calendar',
    info: {},
    isActive: true
}, {
    index: 0,
    item: 'calendartask',
    label: 'Calendar/Tasks',
    object: 'calendartask',
    type: TYPE_DEFAULT,
    imgName: 'fa fa-calendar',
    url: '/appointments/calendar',
    info: {},
    isActive: true
},
{
    index: 0,
    item: 'calendartask',
    label: 'Calendar/Tasks',
    object: 'calendartask',
    type: TYPE_DEFAULT,
    imgName: 'fa fa-calendar',
    url: '/appointments/calendar',
    info: {},
    isActive: true
}];


const initialProps = {
    index: 0,
    item: '', // required
    label: '', // required
    object: '', // required
    type: '', // required
    imgName: '',
    url: '', // required
    info: {},
    isActive: false,
    parentTabIndex: -1
}

const addTab = (tabProps, isRedirect = false, homeTabProps = null, homeTabReq = false, initialState) => {
    let object = null;
    let item = null;
    let tab = null;
    let tabs = [];
    let isExist = false;
    let tempTabs = [];
    let activeTabIndex = -1;
    let redirectUrl = null;
    let state = initialState || store.getState()
    try {
        let selectedModuleName = getStringParam(state.app.selectedModuleName);

        let queueObj = state.queueList !== null && state.queueList !== undefined && state.queueList.hasOwnProperty('data') && state.queueList.data !== null ? state.queueList.data.object : "";
        tabProps = getObjectParam(tabProps);
        if (tabProps.object === constants.TASKS && (tabProps.item === constants.CALENDAR_TASK || tabProps.item === constants.TASKS) && tabProps.type === TYPE_HOME && tabProps.url === '/tasks/taskpage') {
            tabProps.url = '/appointments/calendar';
            object = 'calendartask';
            tabProps.label = 'Calendar/Tasks'
            tabProps.imgName = 'fa fa-calendar';
        }
        item = getStringParam(tabProps.item).trim();
        item = item === '' ? getStringParam(getActiveTabItem(state)).trim() : item;
        object = getStringParam(tabProps.object).trim();
        tabProps.item = item;
        if (item !== '') {
            let tempInitialProps = JSON.parse(JSON.stringify(initialProps));
            tab = { ...tempInitialProps, ...tabProps };

            tab = getTabPropsForListOrColumnView(tab);
            //START: This section used only for "MTD Invoices" query for Invoice Object. Date: 02-04-2018
            if (tab.type === TYPE_LIST_VIEW) {
                let filter = getObjectParam(tab.info.filter);
                if (constants.MTD_INVOICES_QUERYID === getIntParam(filter.id)) {
                    tab.info.groupByFieldName = constants.SALE_TYPE;
                }
            }
            //END: This section used only for "MTD Invoices" query for Invoice Object. Date: 02-04-2018

            if (getStringParam(tab.imgName) === '') {
                if (tab.item === constants.SETTING && tab.object === constants.RECYCLE_BIN_OBJECT && tab.type === TYPE_DETAIL_VIEW) {
                    tab.imgName = getImageNameByObject(constants.ACCOUNTS_OBJECT, initialState);
                } else if (object === constants.SALES_OBJECT && tab.type === TYPE_REPORT) {
                    let selectedReport = isValidParam(tab.info.selectedReport) ? tab.info.selectedReport : {};
                    let reportSubtypeId = selectedReport.item_type === constants.USER_REPORT_TYPE ? getIntParam(selectedReport.report_subtype) : selectedReport.item_type;
                    let reportItemType = selectedReport.item_type === constants.USER_REPORT_TYPE ? selectedReport.item_type : 'system';     // is SystemReport or User  
                    tab.imgName = getIconClass(reportItemType, reportSubtypeId);

                } else if (queueObj === constants.TASKS) {
                    let isCallFromtakQueue = getBooleanParam(tab.info.is_callFrom_queue);
                    if (isCallFromtakQueue) {
                        tab.imgName = 'fa fa-clock-o';
                    } else {
                        tab.imgName = getImageNameByObject(tab.object, initialState);
                    }
                }
                else if (tab.object === "appointments" && tab.item === "calendartask" && tab.type === "TYPE_DETAIL_VIEW" && tab?.columnName?.columnName === "company") {
                    tab.imgName = "fa fa-building";
                }
                else if (tab.object === "appointments" && tab.item === "calendartask" && tab.type === "TYPE_DETAIL_VIEW" && tab?.columnName?.columnName === "opportunity_name") {
                    tab.imgName = "fa fa-usd";
                }
                else if (tab.object === "appointments" && tab.item === "calendartask" && tab.type === "TYPE_DETAIL_VIEW" && tab?.columnName?.columnName === "fullname") {
                    tab.imgName = "fa fa-user";
                }
                else {
                    tab.imgName = getImageNameByObject(tab.object, initialState);
                }
            }

            tabs = getTabsByItem(item, state);
            tabs = getArrayParam(tabs);
            tabs = processForHomeTab(selectedModuleName, tabs, tab);

            /** START: Checking here tab exist or not */
            if (constants.SETTING === tab.item && TYPE_HOME === tab.type) {
                tempTabs = tabs.filter(t => { return t.item === constants.SETTING });
            } else if (TYPE_DASHBOARD === tab.type) {
                tempTabs = tabs.filter(t => { return t.type === TYPE_DASHBOARD });
            } else if (TYPE_HOME === tab.type) {
                tempTabs = tabs.filter(t => { return t.type === TYPE_HOME });
            } else if (TYPE_LIST_VIEW === tab.type || TYPE_COLUMN_VIEW === tab.type) {
                let isReport = getBooleanParam(tab.info.isReport);
                if (isReport) {
                    tempTabs = tabs.filter(t => { return t.type === TYPE_LIST_VIEW && t.info.hasOwnProperty('isReport') });
                } else {
                    tempTabs = tabs.filter(t => { return (t.type === TYPE_LIST_VIEW || t.type === TYPE_COLUMN_VIEW) });
                }
            } else if (TYPE_DETAIL_VIEW === tab.type) {
                let isCallFromQueue = getBooleanParam(tab.info.is_callFrom_queue);
                if (isCallFromQueue) {
                    tempTabs = tabs.filter(t => { return t.type === TYPE_DETAIL_VIEW });
                } else {
                    tempTabs = tabs.filter(t => { return t.type === TYPE_DETAIL_VIEW && t.url === tab.url });
                }

            } else if (TYPE_REPORT === tab.type) {
                tempTabs = tabs.filter(t => { return t.type === TYPE_REPORT && t.url === tab.url });
                isRedirect = true;
            } else if (TYPE_WEBSITE_TRACKING === tab.type) {
                tempTabs = tabs.filter(t => { return t.type === TYPE_WEBSITE_TRACKING });
                isRedirect = true;

                let trackingInfo = getObjectParam(tab.info.websiteObject);
                let isWebsiteDashboard = getBooleanParam(trackingInfo.isWebsiteDashboard);
                tab.url = isWebsiteDashboard ? '/' + LinkToType.TYPE_WEBSITE_TRACKING_DASHBOARD : '/' + LinkToType.TYPE_WEBSITE_TRACKING_SETUP;

            } else if (TYPE_LINK_CLICK_PAGE === tab.type) {
                tempTabs = tabs.filter(t => { return t.type === TYPE_LINK_CLICK_PAGE });
                isRedirect = true;
            } else if (TYPE_OTHER_VIEW === tab.type) {
                //priyanka 01.05.2018
                tempTabs = tabs.filter(t => { return t.type === TYPE_OTHER_VIEW });
                isRedirect = true;
            } else if (TYPE_EMAIL_REPORT_LISTVIEW === tab.type) {
                /** Priyanka 9.5.19 */
                tempTabs = tabs.filter(t => { return t.type === TYPE_EMAIL_REPORT_LISTVIEW && t.url === tab.url });
                if (tempTabs === null || getArrayParam(tempTabs).length === 0) {
                    tempTabs = tab;
                }
                isRedirect = true;
            } else if (TYPE_POWER_DIALER === tab.type) {
                tempTabs = tabs.filter(t => { return t.type === TYPE_POWER_DIALER });
                isRedirect = true;
            }
            else if (TYPE_GANTT_CHART === tab.type) {
                tempTabs = tabs.filter(t => { return t.type === TYPE_GANTT_CHART });
                isRedirect = true;
            }
            else if (TYPE_DETAIL_VIEW_TASK_LIST === tab.type) {
                tempTabs = tabs.filter(t => { return t.type === TYPE_DETAIL_VIEW_TASK_LIST });
                isRedirect = true;
            }
            else if (TYPE_JOB_SCHEDULER === tab.type) {
                tempTabs = tabs.filter(t => { return t.type === TYPE_JOB_SCHEDULER });
                isRedirect = true;
            }

            isExist = tempTabs.length > 0 ? true : false;
            /** END: Checking here tab exist or not */
            if (isExist) {
                activeTabIndex = tempTabs.length > 0 ? tempTabs[0].index : -1;
                if (TYPE_WEBSITE_TRACKING === tab.type || (tab.item === constants.SETTING && tab.type === TYPE_HOME)) {
                    tabs = [];
                    tabs.push(tab);
                } else if (TYPE_LIST_VIEW === tab.type || TYPE_COLUMN_VIEW === tab.type) {
                    let oldTabObject = getStringParam(tabs[activeTabIndex].object);
                    let info = getObjectParam(tabs[activeTabIndex].info);
                    let isReport = getBooleanParam(info.isReport);
                    if (info.hasOwnProperty('isShowGroupBy')) {
                        tab.info.isShowGroupBy = getBooleanParam(info.isShowGroupBy);
                    }

                    if (object === constants.MARKETING_TEMPLATE_OBJECT || object === constants.AUTOMATION_DESIGNER_OBJECT
                        || object === constants.AB_CAMPAIGNS_OBJECT || object === constants.BROADCAST_CAMPAIGNS_OBJECT
                        || object === constants.SALES_TEMPLATE_OBJECT || object === constants.SOLUTIONS_OBJECT
                        || object === constants.COUPONS | object === constants.AUDIT_ANSWERS_OBJECT) {
                        //bypass
                        if ((object === constants.SOLUTIONS_OBJECT || object === constants.COUPONS || object === constants.AUDIT_ANSWERS_OBJECT)
                            && (oldTabObject === constants.SEARCH_RESULTS || oldTabObject === constants.ADVANCE_SEARCH_RESULTS)) {
                            tabs[activeTabIndex] = tab;
                        }
                    } else if (oldTabObject === constants.SEARCH_RESULTS && object === constants.SEARCH_RESULTS && JSON.stringify(info.search) !== JSON.stringify(tab.info.search)) {
                        tabs[activeTabIndex] = tab;
                    } if (oldTabObject === constants.ADVANCE_SEARCH_RESULTS && object === constants.ADVANCE_SEARCH_RESULTS && JSON.stringify(info.advanceSearch) !== JSON.stringify(tab.info.advanceSearch)) {
                        tabs[activeTabIndex] = tab;
                    } else if ((object === constants.SEARCH_RESULTS || object === constants.ADVANCE_SEARCH_RESULTS) && oldTabObject !== object) {
                        tabs[activeTabIndex] = tab;
                    } else if (JSON.stringify(info.filter) !== JSON.stringify(tab.info.filter)) {
                        tabs[activeTabIndex] = tab;
                    } else if ((info.hasOwnProperty('query_link_id') || tab.info.hasOwnProperty('query_link_id')) && JSON.stringify(info) !== JSON.stringify(tab.info)) {
                        tabs[activeTabIndex] = tab;
                    } else if (isReport && JSON.stringify(info.input_param) !== JSON.stringify(tab.info.input_param)) {
                        tabs[activeTabIndex] = tab;
                    } else if (object === constants.OPPORTUNITIES_OBJECT) {
                        let isForecast = getBooleanParam(info.isForecast);
                        if (isForecast !== getBooleanParam(tab.info.isForecast)
                            || (isForecast === getBooleanParam(tab.info.isForecast) && JSON.stringify(info.input_param) !== JSON.stringify(tab.info.input_param))) {
                            //tab.info.isFetchListView = true;
                            tabs[activeTabIndex] = tab;
                        }
                    } else {
                        tabs[activeTabIndex] = tab;
                    }
                } else if (tab.item === 'royalty-report' && tab.type === TYPE_OTHER_VIEW && tab.object === constants.QUOTATION_OBJECT) {
                    tabs.push(tab);
                    activeTabIndex = tabs.length - 1;
                } else if (tab.item === constants.JOBS_OBJECT && tab.type === TYPE_OTHER_VIEW && tempTabs[0].object === constants.JOB_SCHEDULER) {
                    if (tab.object === constants.JOB_SCHEDULER && tempTabs[0].object === constants.JOB_SCHEDULER) {
                        let jobtab = tabs.find((tab) => { return tab.type === TYPE_OTHER_VIEW && tab.object === object });
                        if (jobtab !== undefined && jobtab !== null) {
                            tabs[activeTabIndex] = tab;
                        }
                    } else {
                        tabs.push(tab);
                        activeTabIndex = tabs.length - 1;
                    }
                } else {
                    tabs[activeTabIndex] = tab;
                }
            } else if (tabs.length >= MAX_TAB_SIZE) {
                activeTabIndex = tabs.length - 1;
                tabs[activeTabIndex] = tab;
            } else if (tabs.length < MAX_TAB_SIZE) {
                let existingTabIndex = tabs.findIndex(t => t.type === tab.type);
                let isReport = getBooleanParam(tab.info.isReport);
                if (TYPE_HOME === tab.type) {
                    tabs.unshift(tab);
                    activeTabIndex = 0;
                } else if ((TYPE_LIST_VIEW === tab.type || TYPE_COLUMN_VIEW === tab.type) && !isReport && tabs.length === 1 && (tabs[0].type !== TYPE_HOME && tabs[0].type !== TYPE_DASHBOARD)) {
                    tabs.splice(0, 0, tab);
                    activeTabIndex = 0;
                }//priyanka 18.6.20
                else if ((TYPE_LIST_VIEW === tab.type || TYPE_COLUMN_VIEW === tab.type) && tabs.length >= 2 && isReport) {
                    tabs.splice(tabs.length, 0, tab);
                    activeTabIndex = tabs.length - 1;
                }// 
                else if ((TYPE_LIST_VIEW === tab.type || TYPE_COLUMN_VIEW === tab.type) && tabs.length >= 2) {
                    tabs.splice(1, 0, tab);
                    activeTabIndex = 1;
                } else if ((tab.type === constants.REPORT_DASHBOARD && existingTabIndex > 0)) {
                    tabs.splice(existingTabIndex, 1, tab);
                    activeTabIndex = existingTabIndex;
                } else if ((tab.type === constants.ROYALTY_REPORTED_SALES_DRILLDOWN && existingTabIndex > 0)) {
                    tabs.splice(existingTabIndex, 1, tab);
                    activeTabIndex = existingTabIndex;
                }
                else {
                    tabs.push(tab);
                    activeTabIndex = tabs.length - 1;
                }
            }

            if (isValidParam(tabs) && Array.isArray(tabs)) {
                tabs.forEach((tab, index) => {
                    tab.index = index;
                    if (activeTabIndex === index) {
                        tab.isActive = true;
                        redirectUrl = tab.url;
                        if (tab.type === TYPE_LIST_VIEW) {
                            setListViewGroupByShowHide(tab);
                        }
                    } else {
                        tab.isActive = false;
                    }
                });
                setTabsByItem(item, tabs);
            }
        }

        /** START:  Drawer close*/
        let isOpenDrawer = getBooleanParam(state.appContainer.drawerProps.isOpenDrawer);
        if (isOpenDrawer) {
            state.appContainer.drawerProps.isOpenDrawer = false;
        }
        /** END:  Drawer close*/
        if (tab !== null) {
            updateActiveTabObjectScrollPosition(tab.object, initialState);
            setListViewMountedByTab(tempTabs.length > 0 ? tempTabs[0] : null, tab);
        }
        store.dispatch(setSFTabs(tabs));
        if (isRedirect) {
            store.dispatch(appRedirect(true, redirectUrl));
        }
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> addTab()':" + error);
    }
}

const processForHomeTab = (selectedModuleName, tabs, tab) => {
    let isHomeTabRequired = false;
    let object = null;
    let item = null;
        let state = store.getState()

    try {
        object = getStringParam(tab.object);
        item = getStringParam(tab.item);
        if ((object === constants.SEARCH_RESULTS || object === constants.ADVANCE_SEARCH_RESULTS)
            && (isValidParam(tab.info.search) || isValidParam(tab.info.advanceSearch))) {
            item = object === constants.SEARCH_RESULTS ? getStringParam(tab.info.search.object) : getStringParam(tab.info.advanceSearch.object);
            object = object === constants.SEARCH_RESULTS ? getStringParam(tab.info.search.object) : getStringParam(tab.info.advanceSearch.object);

        }

        if (item === constants.ACCOUNTS_OBJECT || item === constants.CONTACTS_OBJECT || item === constants.OPPORTUNITIES_OBJECT || item === constants.PRODUCTS_OBJECT ||
            item === constants.PROJECTS_OBJECT || (item === constants.CASES_OBJECT && selectedModuleName !== constants.MODULE_HUB) || item === constants.ISSUES_OBJECT ||
            item === constants.CUSTOM_TABLE1_OBJECT || item === constants.CUSTOM_TABLE2_OBJECT || item === constants.CUSTOM_TABLE3_OBJECT ||
            item === constants.CUSTOM_TABLE4_OBJECT || item === constants.SALES_LEADS_OBJECT || item === constants.SALES_OBJECT || item === constants.QUOTATION_OBJECT ||
            item === constants.PURCHASE_ORDER_OBJECT || item === constants.SALES_ORDER_OBJECT || item === constants.INVENTORY_OBJECT || item === constants.JOBS_OBJECT ||
            item === constants.COMPLIANCE_OBJECT || (object === constants.PARTNERS_OBJECT && state.app.me.is_franchisor)) {
            let tempTabs = tabs.filter(t => { return t.type === TYPE_HOME });
            isHomeTabRequired = tempTabs.length === 0 ? true : false;
        }

        if (isHomeTabRequired) {
            let initHomeProps = Object.assign({}, initialProps);
            let homeLabel = getLocalizedStrings().label.HEADER[getObjectLabelByObject(object)];
            homeLabel = getStringParam(homeLabel) !== '' ? homeLabel : getObjectLabelByObject(object)
            homeLabel = homeLabel + ' ' + getLocalizedStrings().label.HOME.HOME;
            let homeTab = { item: item, type: TYPE_HOME, imgName: ICON_NAME_HOME, object: object, label: homeLabel, url: "/" + object + "/" + (object === constants.SALES_OBJECT ? LinkToType.TYPE_REPORT : "filters"), };
            homeTab = { ...initHomeProps, ...homeTab };
            tabs.splice(0, 0, homeTab);
        }
        /* if (homeTabReq) {
            tempTabs = tabs.filter(t => { return t.type === TYPE_HOME });
            if (tempTabs.length === 0) {
                let initialProps = Object.assign({}, initialProps)
                let homeTab = getObjectParam(homeTabProps);
                homeTab = { ...initialProps, ...homeTab };
                tabs.push(homeTab);
            }
        } */
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> processForHomeTab()':" + error);
    }
    return tabs;
}
const setListViewMountedByTab = (prevTab, newTab) => {
    let prevFilter = {};
    let newFilter = {};
    let object = '';
    try {
        let state = store.getState();
        if (isValidParam(prevTab) && isValidParam(newTab)) {
            prevTab = getObjectParam(prevTab);
            newTab = getObjectParam(newTab);
            object = getStringParam(newTab.object);

            if ((TYPE_LIST_VIEW === newTab.type || TYPE_COLUMN_VIEW === newTab.type) && prevTab.info.hasOwnProperty('filter') && newTab.info.hasOwnProperty('filter')) {
                prevFilter = prevTab.info.filter;
                prevFilter = { id: prevFilter.id, name: prevFilter.name, type: prevFilter.type };

                newFilter = newTab.info.filter;
                newFilter = { id: newFilter.id, name: newFilter.name, type: newFilter.type };
            }

            if (JSON.stringify(prevFilter) === JSON.stringify(newFilter)
                && prevTab.hasOwnProperty('info') && newTab.hasOwnProperty('info')
                && ((prevTab.info.hasOwnProperty('isReport') && newTab.info.hasOwnProperty('isReport'))
                    || prevTab.info.hasOwnProperty('isForecast') || newTab.info.hasOwnProperty('isForecast'))) {
                prevFilter = getObjectParam(prevTab.info.input_param);
                newFilter = getObjectParam(newTab.info.input_param);
            }

            if ((Object.keys(prevFilter).length === 0 || Object.keys(newFilter).length === 0)
                && ((prevTab.object === constants.SEARCH_RESULTS || prevTab.object === constants.ADVANCE_SEARCH_RESULTS)
                    || (newTab.object === constants.SEARCH_RESULTS || newTab.object === constants.ADVANCE_SEARCH_RESULTS))) {

                if (prevTab.object === constants.SEARCH_RESULTS) {
                    prevFilter = getObjectParam(prevTab.info.search);
                } else {
                    prevFilter = getObjectParam(prevTab.info.advanceSearch);
                }

                if (newTab.object === constants.SEARCH_RESULTS) {
                    newFilter = getObjectParam(newTab.info.search);
                } else {
                    newFilter = getObjectParam(newTab.info.advanceSearch);
                }
            }

            if (TYPE_LIST_VIEW === newTab.type && (object === constants.SALES_TEMPLATE_OBJECT
                || object === constants.MARKETING_TEMPLATE_OBJECT
                || object === constants.WEB_FORMS
                || object === constants.SOLUTIONS_OBJECT
                || object === constants.COUPONS_OBJECT
                || object === constants.AUDIT_ANSWERS_OBJECT)) {
                //bypass
                if ((newTab.object === constants.SOLUTIONS_OBJECT || object === constants.COUPONS_OBJECT || object === constants.AUDIT_ANSWERS_OBJECT) && (prevTab.object === constants.SEARCH_RESULTS || prevTab.object === constants.ADVANCE_SEARCH_RESULTS)) {
                    state.listView.mounted = false;
                    state.listView.isGroupByMounted = false;
                }
            } else if ((TYPE_HOME === newTab.type || TYPE_LIST_VIEW === newTab.type || TYPE_COLUMN_VIEW === newTab.type)
                && (JSON.stringify(prevFilter) !== JSON.stringify(newFilter))) {
                let isReport = getBooleanParam(newTab.info.isReport);
                if (isReport) {
                    state.otherListView.mounted = false;
                } else {
                    try {
                        state.listView.mounted = false;
                        state.listView.isGroupByMounted = false;
                        state.lists.mounted = false;
                    } catch (error) {
                        console.error("Error in 'sfTabManager.js -> setListViewMountedByTab() ->':" + error);
                    }
                }
            }
        }

        newTab = getObjectParam(newTab);
        object = getStringParam(newTab.object);
        if (object !== constants.AUDIT_ANSWERS_OBJECT) {
            object = object.replace('_', '-');
        }
        let currentAppTabSet = getArrayParam(state.tab.tabs);
        let tempTabs = currentAppTabSet.filter(f => { return f.type === TYPE_LIST_VIEW || f.type === TYPE_COLUMN_VIEW });
        if (tempTabs.length > 0 && tempTabs[0].object !== getStringParam(newTab.object) && (newTab.type === TYPE_COLUMN_VIEW
            || ((newTab.type === TYPE_LIST_VIEW && tempTabs[0].object === constants.SEARCH_RESULTS) || tempTabs[0].object === constants.ADVANCE_SEARCH_RESULTS))) {
            try {
                state.listView.mounted = false;
                state.listView.isGroupByMounted = false;
                state.lists.mounted = false;
            } catch (error) {
                console.error("Error in 'sfTabManager.js -> setListViewMountedByTab() ->':" + error);
            }
        } else if (newTab.type === TYPE_LIST_VIEW && getBooleanParam(state.listView.mounted)) {
            if (object === constants.SEARCH_RESULTS && isValidParam(newTab.info.search)) {
                object = getStringParam(newTab.info.search.object);
            } else if (object === constants.ADVANCE_SEARCH_RESULTS && isValidParam(newTab.info.advanceSearch)) {
                object = getStringParam(newTab.info.advanceSearch.object);
            }

            let listViewObject = getStringParam(state.listView.data.object);
            listViewObject = getObjectNameByLabel(listViewObject);
            let queryName = getStringParam(state.listView.data.query_name);
            listViewObject = queryName === 'All deleted records' ? constants.SETTING : listViewObject;
            if (object !== listViewObject) {
                state.listView.mounted = false;
                state.listView.isGroupByMounted = false;
            }
        } else if ((newTab.type === TYPE_COLUMN_VIEW || newTab.type === TYPE_LIST_VIEW || newTab.type === TYPE_HOME) && getBooleanParam(state.lists.mounted)) {
            let columnViewObject = getStringParam(state.lists.object);
            columnViewObject = getObjectNameByLabel(columnViewObject);
            if (object !== columnViewObject) {
                state.lists.mounted = false;
            }
        }


    } catch (error) {
        console.error("Error in 'sfTabManager.js -> setListViewMountedByTab()':" + error);
    }
}

const getSFTabFromRedux = (item, initialState) => {
    let tabDetails = null;
    let itemName = '';
    try {
        let state = initialState || store?.getState();
        itemName = getItemNameForTab(item, initialState);
        let rememberTab = getObjectParam(state?.tab?.rememberTab);
        if (item === constants.OPERATIONS_REPORTS || item === constants.EMAIL_MARKETING || item === constants.LEAD_CAPTURE
            || item === constants.OPERATIONS_ONBOARDING || item === constants.HUB || item === constants.OPERATIONS_DASHBOARD
            || item === constants.SALES_MODULE_TEMPLATE || item === constants.LANDING_PAGES_FROALA_OBJECT
            || item === constants.PROJECTS_OBJECT || item === constants.OPERATIONS_ORDER_OBJECT || item === constants.ROYALTY) {
            let keys = Object.keys(rememberTab);
            let finds = keys.filter(f => { return f?.indexOf(item) > -1 });
            tabDetails = finds.length > 0 ? rememberTab[finds[0]] : null;
        } else {
            tabDetails = getArrayParam(rememberTab[itemName]);
        }

    } catch (error) {
        console.error("Error in 'sfTabManager.js -> getSFTabFromRedux()':" + error);
    }
    return tabDetails;
}

const getTabsByItem = (item, state) => {
    let tabs = [];
    try {
        item = getStringParam(item);
        tabs = getSFTabFromRedux(item, state);
        tabs = getArrayParam(tabs);
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> getTabsByItem()':" + error);
    }
    return tabs;
}

const setTabsByItem = (item, tabs, state) => {

    try {
        if (isValidParam(tabs) && Array.isArray(tabs)) {
            setSFTabToRedux(item, tabs, state);
        }
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> setTabsByItem()':" + error);
    }
}

const setSFTabToRedux = (item, tabs, initialState) => {

    let itemName = '';
    try {
        if (isValidParam(tabs) && item !== constants.LEAD_CAPTURE && item !== constants.EMAIL_MARKETING
            && item !== constants.OPERATIONS_REPORTS && item !== constants.OPERATIONS_ONBOARDING
            && item !== constants.HUB && item !== constants.SALES_MODULE_TEMPLATE && item !== item !== constants.ROYALTY) {
            let state = initialState || store.getState();
            itemName = getItemNameForTab(item, state);
            let rememberTab = getObjectParam(state.tab.rememberTab);
            let selectedMenuObject = getSelectedObject();

            if (item === constants.WEB_FORMS || item === constants.LANDING_PAGES_FROALA_OBJECT) {
                let keys = Object.keys(rememberTab);
                let finds = keys.filter(f => { return f.indexOf(constants.LEAD_CAPTURE + '-') > -1 });
                finds.forEach(f => {
                    delete rememberTab[f];
                });
            } else if (item === constants.AUTOMATION_DESIGNER_OBJECT || item === constants.BROADCAST_CAMPAIGNS_OBJECT || item === constants.MARKETING_OBJECT) {
                let keys = Object.keys(rememberTab);
                let finds = keys.filter(f => { return f.indexOf('email-marketing-') > -1 });
                finds.forEach(f => {
                    delete rememberTab[f];
                });
            } else if (item === constants.SALES_TEMPLATE_OBJECT || item === constants.SMS_TEMPLATE_OBJECT || item === constants.CALL_SCRIPT_OBJECT || item === constants.MARKETING_TEMPLATE_OBJECT || item === constants.AGREEMENT_TEMPLATE_OBJECT) {
                let keys = Object.keys(rememberTab);
                let finds = keys.filter(f => { return f.indexOf(constants.SALES_MODULE_TEMPLATE + '-') > -1 });
                finds.forEach(f => {
                    delete rememberTab[f];
                });
            } else if (selectedMenuObject === constants.OPERATIONS_ONBOARDING && (item === constants.ONBOARDING_OBJECT || item === constants.PROJECTS_OBJECT)) {
                let keys = Object.keys(rememberTab);
                let finds = keys.filter(f => { return f.indexOf(constants.OPERATIONS_ONBOARDING + '-') > -1 });
                finds.forEach(f => {
                    delete rememberTab[f];
                });
            } else if (selectedMenuObject === constants.OPERATIONS_DASHBOARD) {
                /** changes the filter condidition since filter has removed form opdashbord menu - lalu  */
                let keys = Object.keys(rememberTab);
                let finds = keys.filter(f => { return f.indexOf(constants.OPERATIONS_DASHBOARD + '-') > -1 });
                finds.forEach(f => {
                    delete rememberTab[f];
                });
            } else if (selectedMenuObject === constants.HUB && (item === constants.NEWS_OBJECT || item === constants.USEFUL_LINK_OBJECT)) {
                let keys = Object.keys(rememberTab);
                let finds = keys.filter(f => { return f.indexOf(constants.HUB + '-') > -1 });
                finds.forEach(f => {
                    delete rememberTab[f];
                });
            } else if (item === constants.JOB_SCHEDULER) {
                let keys = Object.keys(rememberTab);
                let finds = keys.filter(f => { return f.indexOf("operations-") > -1 });
                finds.forEach(f => {
                    delete rememberTab[f];
                });
            } else if (selectedMenuObject === constants.DASHBOARD) {
                let keys = Object.keys(rememberTab);
                let finds = keys.filter(f => { return f.indexOf(constants.DASHBOARD + '-') > -1 });
                finds.forEach(f => {
                    delete rememberTab[f];
                });
            } else if (selectedMenuObject === constants.OPERATIONS_REPORTS) {
                let keys = Object.keys(rememberTab);
                let finds = keys.filter(f => { return f.indexOf(constants.OPERATIONS_REPORTS + '-') > -1 });
                finds.forEach(f => {
                    delete rememberTab[f];
                });
            } else if (selectedMenuObject === constants.OPERATIONS_ORDER_OBJECT && (item === constants.PURCHASE_ORDER_OBJECT || item === constants.SALES_ORDER_OBJECT || item === constants.INVENTORY_OBJECT)) {
                let keys = Object.keys(rememberTab);
                let finds = keys.filter(f => { return f.indexOf(constants.OPERATIONS_ORDER_OBJECT) > -1 });
                finds.forEach(f => {
                    delete rememberTab[f];
                });
            }

            rememberTab[itemName] = getArrayParam(tabs);
            state.tab.rememberTab = rememberTab;
        }
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> setSFTabToRedux()':" + error);
    }
}

const getItemNameForTab = (item, initialState) => {
    try {
        let state = initialState || store.getState();
        let userInfo = getUserInfo(initialState);
        let userId = getIntParam(userInfo.id);
        let projectId = getIntParam(userInfo.projectId);
        item = makeValidObjectName(item);
        let selectedMenuObject = getSelectedObject(null, initialState);
        let selectedModuleName = getStringParam(state.app.selectedModuleName);

        if (item === constants.SETTING) {
            //by pass
        } else if (item === 'web-forms' || item === constants.LANDING_PAGES_FROALA_OBJECT) {
            item = constants.LEAD_CAPTURE + '-' + item;
        } else if (item === 'automation-designer' || item === 'broadcast-campaigns' || item === 'marketing') {
            item = 'email-marketing-' + item;
        } else if (item === 'operations-activities' || item === 'operations-conversions' || item === 'operations-campaigns' || item === 'custom-operations-reports' || item === 'operations-assets-reports' || item === 'royalty-report' || item === constants.ONBOARDING_OBJECT) {
            item = constants.OPERATIONS_REPORTS + '-' + item;
        } else if (item === constants.SALES_TEMPLATE_OBJECT || item === constants.SMS_TEMPLATE_OBJECT || item === constants.CALL_SCRIPT_OBJECT || item === constants.MARKETING_TEMPLATE_OBJECT || item === constants.AGREEMENT_TEMPLATE_OBJECT) {
            item = constants.SALES_MODULE_TEMPLATE + '-' + item;
        } else if (selectedMenuObject === constants.OPERATIONS_ONBOARDING && (item === constants.ONBOARDING_OBJECT || item === constants.PROJECTS_OBJECT)) {
            item = constants.OPERATIONS_ONBOARDING + '-' + item;
        } else if (item === constants.JOB_SCHEDULER) {
            item = 'operations-' + item;
        } else if (selectedMenuObject === constants.HUB && (item === constants.NEWS_OBJECT || item === constants.USEFUL_LINK_OBJECT)) {
            item = constants.HUB + '-' + item;
        } else if (item === constants.PURCHASE_ORDER_OBJECT || item === constants.SALES_ORDER_OBJECT || item === constants.INVENTORY_OBJECT) {
            item = constants.OPERATIONS_ORDER_OBJECT + '-' + item;
        } else if (item === constants.ROYALTY_PLANS_OBJECT || item === constants.ROYALTY_RUN_OBJECT) {
            item = constants.ROYALTY + '-' + item;
        }
        /** changed for Operation dashboard to store tab in redux -- lalu  */
        else if (selectedMenuObject === constants.OPERATIONS_DASHBOARD) {
            item = constants.OPERATIONS_DASHBOARD + '-' + selectedModuleName;
        } else if (selectedMenuObject === constants.DASHBOARD) {
            let selectedModuleName = getStringParam(state.app.selectedModuleName);
            item = constants.DASHBOARD + '-' + selectedModuleName;
        }

        let itemName = 'TabInfo_' + item + '_' + userId + '_' + projectId;
        return itemName;
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> getItemNameForTab()':" + error);
    }
}

const initTab = (item, isRedirect) => {
    let state = store.getState();
    let tabs = [];
    let isInitTab = false;
    let activeTab = null;
    let redirectUrl = '';
    let isShowGroupBy = false;
    let tempTabs = null;
    try {
        isRedirect = getBooleanParam(isRedirect);
        item = getStringParam(item).trim();
        item = makeValidObjectName(item);
        tabs = getTabsByItem(item, state);
        tabs = getArrayParam(tabs);
        let selectedModuleName = getStringParam(state.app.selectedModuleName);
        if (tabs.length > 0) {
            tabs.forEach((tab, pos) => {
                if (pos === 0) {
                    tab.isActive = true;
                } else {
                    tab.isActive = false;
                }
            });

            activeTab = tabs[0];
            if (activeTab.item === constants.CALENDAR_TASK || (activeTab.item === constants.TASKS && selectedModuleName !== constants.MODULE_HUB)) {


                tempTabs = { ...defaultTab[0], info: activeTab.info };
                redirectUrl = isValidParam(tempTabs) ? getStringParam(tempTabs.url) : '';
                tabs[0] = tempTabs;
            }
            if ((activeTab.item === constants.CALENDAR_TASK || activeTab.item === constants.TASKS) && selectedModuleName === constants.MODULE_HUB) {
                activeTab = getCalendarTab({ ...defaultTab[0], info: activeTab.info });
                redirectUrl = isValidParam(activeTab) ? getStringParam(activeTab.url) : '';
                tabs[0] = activeTab;
            }

            if (activeTab.item !== constants.APPOINTMENTS && activeTab.item !== constants.TASKS && activeTab.item !== constants.CALENDAR_TASK) {
                redirectUrl = isValidParam(activeTab) ? getStringParam(activeTab.url) : '';
            }
            if (isValidParam(activeTab) && activeTab.type === TYPE_LIST_VIEW && activeTab.info.hasOwnProperty('isShowGroupBy')) {
                isShowGroupBy = getBooleanParam(activeTab.info.isShowGroupBy);
                state.listView.isShowGroupBy = isShowGroupBy;
            }

            tempTabs = tabs.filter(f => { return f.type === TYPE_LIST_VIEW });
            if (tempTabs.length > 0) {
                state.listView.mounted = false;
                state.listView.isGroupByMounted = false;
            }

            isInitTab = true;
            setTabsByItem(item, tabs, state);
            store.dispatch(setSFTabs(tabs));
            store.dispatch(appRedirect(true, redirectUrl));
            updateActiveTabObjectScrollPosition(activeTab.item, state)
        }

        else {
            let selectedModuleItems = getArrayParam(state.app.selectedModuleItems).filter(f => f.is_show === 1);
            let moduleItem = {};
            if (selectedModuleItems.length > 0) {
                moduleItem = getObjectParam(selectedModuleItems[0]);
                if (getArrayParam(moduleItem.sub_items).length > 0) {
                    moduleItem = getObjectParam(moduleItem.sub_items[0]);
                }

                let requiredModuleItem = {};

                for (let i = 0; i < selectedModuleItems.length; i++) {
                    if (selectedModuleItems[i].hasOwnProperty('sub_items')) {
                        
                        for (let j = 0; j < selectedModuleItems[i].sub_items.length; j++) {
                            if(selectedModuleItems[i].sub_items[j].temp_object
                                === item || selectedModuleItems[i].sub_items[j].object === item) {
                                requiredModuleItem = selectedModuleItems[i].sub_items[j];
                            } else if (selectedModuleItems[i].object === item && selectedModuleItems[i].hasOwnProperty('sub_items')) {
                                moduleItem = selectedModuleItems[i].sub_items[0];
                            }
                        }
                    } else {
                        if (selectedModuleItems[i].temp_object === item || selectedModuleItems[i].object === item) {
                            requiredModuleItem = selectedModuleItems[i];
                        }
                    }
                }
                if (Object.keys(requiredModuleItem).length > 0) {
                    moduleItem = requiredModuleItem;
                }
            }
            let object = makeValidAppUrl(moduleItem.object);
            if (object === constants.APPOINTMENTS || (object === constants.TASKS && selectedModuleName !== constants.MODULE_HUB)) {

                tempTabs = { ...defaultTab[0], imgName: moduleItem.image_name };
            } else if (object === constants.TASKS && selectedModuleName === constants.MODULE_HUB) {
                tempTabs = getCalendarTab({ ...defaultTab[0], imgName: moduleItem.image_name, });
            } else {
                let type = TYPE_LIST_VIEW;
                let url = '/' + object + '/' + getStringParam(moduleItem.type).toLowerCase();
                if (moduleItem.has_filter || object === constants.SALES_OBJECT) {
                    type = TYPE_HOME;
                } else if (object === constants.JOB_SCHEDULER) {
                    type = TYPE_OTHER_VIEW;
                }
                if (moduleItem.has_filter || object === constants.DASHBOARD || object === constants.LANDING_PAGES_FROALA_OBJECT) {
                    url = '/' + object + '/queries';
                    if (moduleItem.has_filter) {
                        url = '/' + object + '/filters';
                    } else if (object === constants.DASHBOARD) {
                        url = '/' + object + '/' + getStringParam(moduleItem.home_name).toLowerCase();
                        type = TYPE_DASHBOARD;
                    }
                }
                tempTabs = {
                    index: 0,
                    info: {},
                    imgName: moduleItem.image_name,
                    item: object,
                    label: moduleItem.label,
                    object: object,
                    type: type,
                    parentTabIndex: 0,
                    url: url
                };
            }


            addTab(tempTabs, isRedirect);
            isInitTab = true;
        }
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> initTab()':" + error);
    }
    return isInitTab;
}

const getActiveTab = (state) => {
    let item = null;
    let tab = null;
    let tabs = null;
    try {
        item = getActiveTabItem(state);
        tabs = getTabsByItem(item, state);
        tabs = getArrayParam(tabs);
        tabs = tabs.filter(f => { return f.isActive });
        tab = tabs.length > 0 ? tabs[0] : null;
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> getActiveTab()':" + error);
    }
    return tab;
}

const selectTabByIndex = (activeIndex) => {
    let activeTab = null;
    let item = null;
    let tabs = null;
    let redirectUrl = null;
    try {
        activeTab = getActiveTab();
        if (activeTab.index !== activeIndex) {
            item = getActiveTabItem();
            tabs = getTabsByItem(item);
            tabs = getArrayParam(tabs);
            if (tabs.length >= activeIndex + 1) {
                tabs.forEach((tab, index) => {
                    if (tab.index === activeIndex) {
                        tab.isActive = true;
                        redirectUrl = getStringParam(tab.url);
                        setListViewGroupByShowHide(tab);
                        activeTab = tab;
                    } else {
                        tab.isActive = false;
                    }
                });
                if (tabs.length > 0) {
                    let state = store.getState();
                    state.header.selectedHeader = null;
                    //state.listView.mounted = false;
                    let object = activeTab.object;

                    if (isValidParam(activeTab) && activeTab.object === constants.ADVANCE_SEARCH_RESULTS) {
                        let advanceSearch = activeTab.info.advanceSearch;
                        if (isValidParam(advanceSearch)) {
                            state.app.advanceSearch = advanceSearch;
                            object = getStringParam(advanceSearch.object);
                        }
                    } else if (isValidParam(activeTab) && activeTab.object === constants.SEARCH_RESULTS) {
                        let search = activeTab.info.search;
                        if (isValidParam(search)) {
                            state.app.searchCriteria = search;
                            object = getStringParam(search.object);
                        }
                    }

                    if (isValidParam(activeTab) && (activeTab.type === TYPE_LIST_VIEW || activeTab.type === TYPE_COLUMN_VIEW)) {
                        let listViewObject = getStringParam(state.listView.data.object);
                        listViewObject = getObjectNameByLabel(listViewObject).toLowerCase();

                        if (object !== listViewObject) {
                            state.listView.mounted = false;
                            state.listView.isGroupByMounted = false;
                            if (isValidParam(state.lists) && state.lists.hasOwnProperty('mounted')) {
                                state.lists.mounted = false;
                            }
                        }
                    }

                    setTabsByItem(item, tabs);
                    store.dispatch(setSFTabs(tabs));
                    store.dispatch(appRedirect(true, redirectUrl));
                }
            }
        }
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> selectTabByIndex()':" + error);
    }
}

const closeTab = (object, index) => {
    let tabs = null;
    let tempTabs = null;
    let currentTab = null;
    let parentTabIndex = 0;
    let parentTab = null;
    let activeTab = null;
    let activeTabIndex = -1;
    let isRedirect = false;
    let redirectUrl = null;
    try {
        let state = store.getState();

        tabs = getTabsByItem(object);
        tabs = getArrayParam(tabs);

        tempTabs = tabs.filter(tab => { return tab.index === index });
        currentTab = tempTabs.length > 0 ? tempTabs[0] : null;
        let _activeTab = getActiveTab();
        if (_activeTab?.object === constants.AUDITS_OBJECT) {
            store.dispatch(setSelectedAuditfilter('All Audit Answers'));
        }
        if (isValidParam(currentTab)) {
            parentTabIndex = isValidParam(currentTab) && isValidParam(currentTab.parentTabIndex) ? getIntParam(currentTab.parentTabIndex) : -1;

            tempTabs = tabs.filter(tab => { return tab.index === parentTabIndex });
            parentTab = tempTabs.length > 0 ? tempTabs[0] : null;
            parentTab = getObjectParam(parentTab);

            if (getBooleanParam(currentTab.isActive) && parentTabIndex > -1 && parentTab.type === TYPE_LIST_VIEW) {
                tabs = tabs.filter(tab => { return tab.index !== index });
                tabs.forEach(tab => {
                    if (tab.index === parentTabIndex) {
                        tab.isActive = true;
                        redirectUrl = tab.url;
                    } else {
                        tab.isActive = false;
                    }
                });
                isRedirect = true;
            } else {
                activeTab = tabs.filter(f => { return f.isActive });
                activeTab = activeTab.length > 0 ? activeTab[0] : null;
                activeTabIndex = isValidParam(activeTab) ? getIntParam(activeTab.index) : -1;
                tabs = tabs.filter(tab => { return tab.index !== index });

                if (activeTabIndex >= 0 && activeTabIndex === index) {
                    activeTabIndex = index - 1;
                    tabs.forEach(tab => {
                        if (tab.index === activeTabIndex) {
                            tab.isActive = true;
                            redirectUrl = tab.url;
                            activeTab = tab;
                        } else {
                            tab.isActive = false;
                        }
                    });
                    isRedirect = true;
                }
            }
            let isReport = isValidParam(currentTab.info) ? getBooleanParam(currentTab.info.isReport) : false;
            if (currentTab.type === TYPE_LIST_VIEW && isReport) {
                state.otherListView.mounted = false;
                if (object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                    store.dispatch(setHeader(
                        object,
                        null,
                        object,
                        false,
                        false
                    ));
                }

            } else if (currentTab.type === TYPE_LIST_VIEW || currentTab.type === TYPE_COLUMN_VIEW) {
                state.listView.mounted = false;
                state.listView.isGroupByMounted = false;
                if (isValidParam(state.lists) && state.lists.hasOwnProperty('mounted')) {
                    state.lists.mounted = false;
                }
                store.dispatch(removeScrollPosition(currentTab.object));
                // store.dispatch(removeColumnViewScrollPosition(currentTab.object));
                // store.dispatch(removeColumnViewHorizontalScrollPosition(currentTab.object));

            } else if (isValidParam(activeTab) && (activeTab.type === TYPE_LIST_VIEW || activeTab.type === TYPE_COLUMN_VIEW)) {
                let listViewObject = getStringParam(state.listView.data.object);
                listViewObject = getObjectNameByLabel(listViewObject).toLowerCase();
                let object = activeTab.object;
                if (object === constants.SEARCH_RESULTS || object === constants.ADVANCE_SEARCH_RESULTS) {
                    let info = getObjectParam(activeTab.info);
                    if (object === constants.SEARCH_RESULTS) {
                        let search = getObjectParam(info.search);
                        object = getStringParam(search.object);
                    } else if (object === constants.ADVANCE_SEARCH_RESULTS) {
                        let advanceSearch = getObjectParam(info.advanceSearch);
                        object = getStringParam(advanceSearch.object);
                    }
                }
                if (object === constants.RECYCLE_BIN_OBJECT) {
                    object = constants.ACCOUNTS_OBJECT;
                }

                if (listViewObject && object !== listViewObject) {
                    state.listView.mounted = false;
                    state.listView.isGroupByMounted = false;
                    if (isValidParam(state.lists) && state.lists.hasOwnProperty('mounted') && object !== state.lists.object) {
                        state.lists.mounted = false;
                    }
                }
            } else if (currentTab.isActive && currentTab.type === TYPE_DETAIL_VIEW && currentTab.object === constants.WORKFLOW_OBJECT) {
                state.workflowAutomation.tabs = [];
                state.workflowAutomation.data = [];
                state.workflowAutomation.activeIndex = 0;
            } else if (currentTab.object === constants.JOBS_OBJECT && getObjectParam(currentTab.info).isClose) {
                isRedirect = false;
            } else if (currentTab.info.object === constants.INTAKE_FORM && currentTab.type === constants.TYPE_OTHER_VIEW) {
                store.dispatch(DeleteIntakeFormData());
            }

            if (tabs.length > 0) {
                tabs.forEach((tab, index) => {
                    tab.index = index;
                });
                setTabsByItem(object, tabs, state);
                store.dispatch(setSFTabs(tabs));
                if (isRedirect) {
                    store.dispatch(appRedirect(true, redirectUrl));
                }
            }

            if (currentTab.type === TYPE_POWER_DIALER) {
                try {
                    let callData = 0;
                    store.dispatch(resetPowerCallingData(callData));
                    store.dispatch(setPowerCallingNoteButtonVisibilityUpdate(false));
                }
                catch (e) { console.error("Error in 'sfTabManager.js -> POWERCALLINNG_PROCESS_END':" + e); }

            }
        }
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> closeTab()':" + error);
    }
}

const updateTabInfo = (info) => {
    try {
        let item = getActiveTabItem();
        let activeTab = getActiveTab();
        let tabs = getTabsByItem(item);
        // let state = store.getState();
        // let listViewRecordCount = isValidParam(state.listView.data.records) ? state.listView.data.records.length:0;
        if (isValidParam(activeTab)) {
            // if (activeTab.info.hasOwnProperty("search") && listViewRecordCount > 0){
            //     let subLabel = activeTab.label.substring(0, activeTab.label.indexOf("="));
            //     activeTab.label = subLabel + "=" +  activeTab.info.search.searchText;
            // } else if (activeTab.info.hasOwnProperty("search") && listViewRecordCount === 0){
            //     let subLabel = activeTab.label.substring(0, activeTab.label.indexOf("="));
            //     activeTab.label = subLabel + "=" +  activeTab.info.search.prevSearchText;
            // }
            let tabIndex = activeTab.index;
            activeTab.info = info;
            tabs[tabIndex] = activeTab;
            setTabsByItem(item, tabs);
            store.dispatch(setSFTabs(tabs));
            updateTabInfoByItem(item, info);
        }
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> updateTabInfo()':" + error);
    }
}

const getActiveTabItem = (initialState) => {
    try {
        let state = initialState || store.getState();
        let tabs = getArrayParam(state.tab.tabs);
        let item = tabs.length > 0 ? tabs[0].item : '';
        return item;
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> getActiveTabItem()':" + error);
    }
}

const setListViewGroupByShowHide = (activeTab) => {
    let isShowGroupBy = false;
    try {
        if (isValidParam(activeTab) && activeTab.type === TYPE_LIST_VIEW) {
            isShowGroupBy = activeTab.info.hasOwnProperty('isShowGroupBy') ? getBooleanParam(activeTab.info.isShowGroupBy) : isShowGroupBy;

            let state = store.getState();
            state.listView.isShowGroupBy = isShowGroupBy;
        }
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> setListViewGroupByShowHide()':" + error);
    }
}

const updateTabInfoByItem = (item, info) => {
    item = getStringParam(item);
    try {
        // if (item === constants.SALES_TEMPLATE_OBJECT) {
        //     item = constants.MARKETING_TEMPLATE_OBJECT;
        // }
        if (item === constants.MARKETING_TEMPLATE_OBJECT) {
            item = constants.SALES_TEMPLATE_OBJECT;
        } else {
        }
        item = '';

        if (item !== '' && isValidParam(info)) {
            let tempInfo = { ...info };
            let tabs = getTabsByItem(item);
            tabs = getArrayParam(tabs);
            if (tabs.length > 0) {
                delete tempInfo.groupByCondition;
                delete tempInfo.groupByConditionTotalCount;
                delete tempInfo.groupByFieldName;
                delete tempInfo.selectedPageNo;
                delete tempInfo.startIndex;
                delete tempInfo.startPageNo;
                delete tempInfo.searchFieldName;
                delete tempInfo.searchText;

                tabs[0].info = tempInfo;
            }
            setTabsByItem(item, tabs);//Only cookie updated.
        }
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> updateTabInfoByItem()':" + error);
    }
}

const updateActiveTab = (updatedTab) => {
    let item = getActiveTabItem();
    let activeTab = getActiveTab();
    let tabs = getTabsByItem(item);
    if (isValidParam(activeTab) && isValidParam(updatedTab)) {
        let tabIndex = activeTab.index;
        tabs[tabIndex] = updatedTab;
        setTabsByItem(item, tabs);
        store.dispatch(setSFTabs(tabs));
    }
}

const updateTabByTabIndex = (updatedTab) => {
    let item = getActiveTabItem();
    let tabs = getTabsByItem(item);
    if (isValidParam(updatedTab)) {
        let tabIndex = updatedTab.index;
        tabs[tabIndex] = updatedTab;
        setTabsByItem(item, tabs);
        store.dispatch(setSFTabs(tabs));
    }
}

const updateActiveTabProps = (props) => {
    let item = getActiveTabItem();
    let activeTab = getActiveTab();
    let tabs = getTabsByItem(item);

    if (isValidParam(activeTab) && isValidParam(props)) {
        let tabIndex = activeTab.index;
        props = getObjectParam(props);
        for (let key in props) {
            if (activeTab.hasOwnProperty(key)) {
                activeTab[key] = props[key];
            }
        }
        tabs[tabIndex] = activeTab;
        setTabsByItem(item, tabs);
        store.dispatch(setSFTabs(tabs));
        updateTabInfoByItem(item, activeTab.info);
    }
}

const getActiveTabInfo = (state) => {
    let item = null;
    let tab = null;
    let tabs = null;
    let info = {};
    try {
        item = getActiveTabItem(state);
        tabs = getTabsByItem(item, state);
        tabs = getArrayParam(tabs);
        tabs = tabs.filter(f => { return f.isActive });
        tab = tabs.length > 0 ? tabs[0] : null;
        info = isValidParam(tab) ? getObjectParam(tab.info) : info;
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> getActiveTabInfo()':" + error);
    }
    return info;
}

const updateCurrentTabSet = (item, tabs) => {
    try {
        item = getStringParam(item);
        tabs = getArrayParam(tabs);
        if (item !== '') {
            setTabsByItem(item, tabs)
            store.dispatch(setSFTabs(tabs));
        }
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> updateCurrentTabSet()':" + error);
    }
}

const getTabPropsForListOrColumnView = (props) => {
    try {
        let userInfo = getUserInfo();
        let selectedView = getObjectParam(JSON.parse(getCookie("view_" + props.object + "_" + userInfo.projectId)));
        let isForecast = isValidParam(props.info) ? getBooleanParam(props.info.isForecast) : false;
        let isReport = isValidParam(props.info) ? getBooleanParam(props.info.isReport) : false;
        let state = store.getState();

        let selectedModuleName = state.app.selectedModuleName;
        let validationDateMillisecs = new Date(constants.DEFAULT_COLUMN_VIEW_CUT_OFF_DATE).valueOf();
        let createdOnDateMillisecs = getIntParam(state.app.me.created_on);
        let object = props.object;
        if (isReport || (props.object === constants.OPPORTUNITIES_OBJECT && isForecast)) {
            // by pass
        } else if (selectedView.hasOwnProperty(props.object)) {
            let obj = getObjectParam(selectedView[props.object]);
            if (obj.hasOwnProperty('defaultView')) {
                if (obj.defaultView === 'List') {
                    if (props.type === TYPE_COLUMN_VIEW) {
                        props.url = '/' + props.object + '/queries';
                        props.type = TYPE_LIST_VIEW;
                    }
                } else if (obj.defaultView === 'Column') {
                    if (props.type === TYPE_LIST_VIEW) {
                        props.url = '/' + props.object + '/column';
                        props.type = TYPE_COLUMN_VIEW;
                    }
                } else if (obj.defaultView === 'Forecast') {
                    if (props.type === TYPE_LIST_VIEW || props.type === TYPE_COLUMN_VIEW) {
                        props.url = '/' + props.object + '/kanban';
                        props.type = TYPE_COLUMN_VIEW;
                    }
                }
            }
        } 
        else if (createdOnDateMillisecs > validationDateMillisecs && props.type !== TYPE_HOME && props.type !== TYPE_DETAIL_VIEW && props.type !== TYPE_OTHER_VIEW && props.type !== TYPE_GANTT_CHART && props.type !== TYPE_DETAIL_VIEW_TASK_LIST
            && props.type !== TYPE_REPORT && props.type !== TYPE_WEBSITE_TRACKING && props.type !== TYPE_DASHBOARD && props.type !== TYPE_LINK_CLICK_PAGE
            && props.type !== TYPE_EMAIL_REPORT_LISTVIEW && props.type !== TYPE_COLUMN_VIEW && (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT
                || object === constants.OPPORTUNITIES_OBJECT || object === constants.CUSTOM_TABLE1_OBJECT || object === constants.CUSTOM_TABLE2_OBJECT
                || object === constants.CUSTOM_TABLE3_OBJECT || object === constants.CUSTOM_TABLE4_OBJECT || object === constants.ISSUES_OBJECT
                || object === constants.PROJECTS_OBJECT || (object === constants.CASES_OBJECT && selectedModuleName !== constants.MODULE_HUB))) {
            props.url = '/' + object + '/column';
            props.type = TYPE_COLUMN_VIEW;
        } 
        else {
            if (props.object === constants.OPPORTUNITIES_OBJECT) {
                if (props.type === TYPE_LIST_VIEW) {
                    props.url = '/' + props.object + '/column';
                    props.type = TYPE_COLUMN_VIEW;
                }
            }
        }
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> getTabPropsForListOrColumnView()':" + error);
    }
    return props;
}



function getCalenderPageType() {
    let type = constants.CALENDAR_TASK;
    try {
        let state = store.getState();
        let selectedModuleName = state.app.selectedModuleName;
        let cookieName = "home_view_" + selectedModuleName + "_" + state.app.me.projectId;
        let selectedView = getObjectParam(JSON.parse(getCookie(cookieName)));
        let obj = getObjectParam(selectedView[constants.APPOINTMENTS]);
        if ((obj.hasOwnProperty('defaultView') && obj.defaultView === 'TasksPage')
            || (!obj.hasOwnProperty('defaultView') && (selectedModuleName === constants.MODULE_HUB))) {
            type = constants.TASKS;
        } else if (obj.hasOwnProperty('defaultView') && obj.defaultView === 'Calendar') {
            type = 'calendaronly';
        }
    } catch (error) {
        console.error("Error in 'common.js -> getCalenderPageType()':" + error);
    }
    return type
}

function getCalendarTab(tab) {
    try {
        let type = getCalenderPageType();
        if (type === constants.TASKS) {
            tab = {
                ...tab,
                object: constants.TASKS,
                url: '/' + constants.TASKS + '/taskpage',
                info: tab.info,
                isActive: true
            }
        }
    } catch (error) {
        console.error("Error in 'common.js -> getCalendarTab()':" + error);
    }
    return tab;
}

function addNewFormTabs(info = {}) {

    let object = info.object;
    let id = getIntParam(info.id);
    let title = info.title || 'Add ' + getStringParam(object);
    let type = info.type;
    let tempInfo = {};
    let newTab = {};
    let activeTab = getActiveTab();
    try {
        if (object && type) {
            if (type === constants.GENERATE_INVOICE) {
                tempInfo = { id: 0, type: 'New', object: constants.QUOTATION_OBJECT, parentRecordId: id, parentObject: object, callFor: type };
                type = 'New';
                object = constants.QUOTATION_OBJECT;
                id = 0;
            }
            if (type === constants.JOB_SCHEDULER && object === constants.JOBS_OBJECT) {
                type = constants.JOB_SCHEDULER;
                object = constants.JOB_SCHEDULER;

            }

            if (type !== constants.JOB_SCHEDULER) {
                let suffix = constants.SPREADSHEET;
                if (object === constants.JOBS_OBJECT) {
                    suffix = constants.JOBSVIEW;
                }
                if (object === constants.ROYALTY_PLANS_OBJECT) {
                    suffix = LinkToType.TYPE_ROYALTY_PLAN;
                }
                if (object === constants.PARTNERS_OBJECT) {
                    suffix = LinkToType.TYPE_LIST_VIEW;
                }
                let urlLink = `/${object}/${suffix}/${id}`;
                let tabType = TYPE_OTHER_VIEW;
console.log("suffixsftab", suffix)
                if (activeTab.item === constants.JOB_SCHEDULER && info.object === constants.JOBS_OBJECT) {
                    tabType = TYPE_JOB_SCHEDULER
                    if (type !== 'Edit') {
                        title = getLocalizedStrings().label.COMMON.ADD + ' ' + constants.JOBS_OBJECT;
                    } else {
                        title = info.title;
                    }
                }

                newTab = {
                    object: object,
                    url: urlLink,
                    type: tabType,
                    label: title,
                    info: info
                };

            } else {
                let suffix;
                if (object === constants.JOB_SCHEDULER) {
                    suffix = 'jobDetailviewScheduler'
                }
                let urlLink = `/${object}/${suffix}`;

                newTab = {
                    info: info,
                    // item: "jobscheduler",
                    label: title,
                    object: object,
                    type: "TYPE_OTHER_VIEW",
                    url: urlLink
                }
            }
            if (tempInfo.callFor === constants.GENERATE_INVOICE) {
                newTab.info = { ...newTab.info, ...tempInfo };
            }
            let state = store.getState();
            let tabs = getArrayParam(state.tab.tabs);
            if (object === constants.JOB_SCHEDULER) {
                let jobtab = tabs.find((tab) => { return tab.type === TYPE_OTHER_VIEW && tab.object === object });
                if (jobtab !== undefined && jobtab !== null) {
                    tabs = tabs.filter(f => { return f.object !== object });
                }
            }
            let tempTab = tabs.find((tab) => { return tab.type === TYPE_OTHER_VIEW && tab.object === object });

            if (!isValidParam(tempTab)) {
                addTab(newTab);
            } else {
                let url = tempTab.url;
                if (url.includes(title)) {
                    addTab(newTab);
                } else {
                    let message = '';
                    if (object === constants.QUOTATION_OBJECT) {
                        const crIndex = tabs.findIndex((tab) => {
                            return tab.type === TYPE_OTHER_VIEW && tab.object === object;
                        });
                        closeTab(object, crIndex);
                        addTab(newTab);
                    } else {
                        if (object === constants.PURCHASE_ORDER_OBJECT) {
                            message = getLocalizedStrings().message.SALES_PURCHASE_ORDER.RESTRICT_TO_OPEN_TWO_OR_MORE_PURCHSE_RECORD;
                        } else if (object === constants.SALES_ORDER_OBJECT) {
                            message = getLocalizedStrings().message.SALES_PURCHASE_ORDER.RESTRICT_TO_OPEN_TWO_OR_MORE_SALES_RECORD;
                        } else if (object === constants.JOBS_OBJECT) {
                            message = getLocalizedStrings().message.JOBS.RESTRICT_TO_OPEN_TWO_OR_MORE_JOBS_RECORD;
                        } else if (object === constants.INTAKE_FORM) {
                            message = getLocalizedStrings().message.INTAKE_FORM.RESTRICT_TO_OPEN_TWO_OR_MORE_INTAKE_FORM;
                        }
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message, () => addNewFormTabsOnConfirm(tempTab));
                    }
                }
            }
        }
    } catch (error) {
        console.error("Error in 'sfTabManager.js -> addNewFormTabs()':" + error);
    }
}
function addNewFormTabsOnConfirm(tempTab) {
    let tabIndex = tempTab.index;
    selectTabByIndex(tabIndex);
}

function updateActiveTabObjectScrollPosition(activeTabObject, initialState) {
    let state = initialState || store.getState()
    let tempVrPositionsObj = {};
    let vScrollData = state.lists.scrollPosition;
    if (vScrollData.hasOwnProperty(activeTabObject)) {
        tempVrPositionsObj = {
            [activeTabObject]: vScrollData[activeTabObject]
        };
    }
    let tempHrPositionsObj = {};
    let hScrollData = state.lists.HorizontalscrollPosition;
    if (hScrollData.hasOwnProperty(activeTabObject)) {
        tempHrPositionsObj = {
            //...tempHrPositionsObj,
            [activeTabObject]: hScrollData[activeTabObject]
        };
    }
    store.dispatch(updateColumnViewHorizontalScrollPosition(tempHrPositionsObj, activeTabObject));
    store.dispatch(updateColumnViewScrollPosition(tempVrPositionsObj, activeTabObject));
}
const updateTabByIndex = (tabs, isRedirect, indexNo) => {

    if (tabs.length > 0) {
        tabs.forEach((tab, index) => {
            tab.index = index;
        });
        let redirectUrl = tabs[indexNo].url;
        setTabsByItem(tabs[indexNo].item, tabs);
        store.dispatch(setSFTabs(tabs));
        if (isRedirect) {
            store.dispatch(appRedirect(true, redirectUrl));
        }
    }
}

export {
    MAX_TAB_SIZE,
    TYPE_HOME,
    TYPE_LIST_VIEW,
    TYPE_DETAIL_VIEW,
    TYPE_REPORT,
    TYPE_WEBSITE_TRACKING,
    TYPE_DASHBOARD,
    TYPE_COLUMN_VIEW,
    TYPE_EMAIL_REPORT_LISTVIEW,
    TYPE_DETAIL_VIEW_TASK_LIST,
    TYPE_LINK_CLICK_PAGE,
    TYPE_OTHER_VIEW,
    TYPE_POWER_DIALER,
    TYPE_GANTT_CHART,
    addTab,
    getTabsByItem,
    initTab,
    getActiveTab,
    selectTabByIndex,
    closeTab,
    updateActiveTab,
    updateTabInfo,
    updateActiveTabProps,
    getActiveTabInfo,
    updateCurrentTabSet,
    getTabPropsForListOrColumnView,
    getCalenderPageType,
    getCalendarTab,
    addNewFormTabs,
    updateTabByIndex,
    updateTabByTabIndex,
    addNewFormTabsOnConfirm,
}
