import React from 'react';
import { styles } from '../../../services/constants/styles';
import { connect } from 'react-redux';
import { isValidParam, getStringParam, getArrayParam, getBooleanParam, getIntParam, getObjectParam, getFloatParam } from '../../../services/helper/parameterVerifier';
import { setReportRedirection, setRecentReportResponseData, drillDownMatrixReport, processChartData } from '../../../services/actions/reportActions';
import { MatrixReportTable, ReactChart, matrixReportInitialData } from './reportMatrixTable';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { constants, PIPELINE_REPORTS_MAP,SALES_SUMMARY_REPORT_PIPILINE } from '../../../services/constants/constants';
import { updatePipelineReportData, updatePipelineReportSalesRep, updatePipelineReportWorkflows } from '../../../services/actions/pipelineReportActions';
import { setHeaderByDispatch, setHeader } from '../../../services/actions/headerActions';
import { getActiveTabInfo, addTab, TYPE_LIST_VIEW, getActiveTab, updateTabInfo } from '../../../services/helper/sfTabManager';
import { getSalesReportInfo, getReportDataPromise, getSalesRepListPromise } from '../../../services/actions/SalesReportAction';
import { reportFormatDate, reportToDate, getDateOptions } from '../../../services/helper/common';
import ReportFilter from '../components/reportFilter';
import { getWorkFlowStage } from '../../../services/actions/batchActions';
import SFDataTable from '../components/sfDataTable';
import { getQueryDetails } from '../../../services/actions/appActions';
import { sortArrayObjectByProperty } from '../../../services/helper/utils';
import { updateNoteTypeListAll } from '../../../services/actions/noteActions';
import { endPoints } from '../../../services/constants/endPoints';
import * as HTTPClient from '../../../services/helper/httpClient';
import ShowCircularProgress from '../components/circularProgress';
import moment from 'moment';


const optionvalue = {
    td: 'Today',
    yd: 'Yesterday',
    tw: 'This Week',
    tm: 'This Month',
    tq: 'This Quarter',
    ty: 'This Year',
    lw: 'Last Week',
    lm: 'Last Month',
    lq: 'Last Quarter',
    ly: 'Last Year',
    cust: 'Custom'
};

class SalesReport extends React.Component {
    constructor(props) {
        super(props);
        this.localizedStrings = getLocalizedStrings();
        this.selectedReport = getObjectParam(getActiveTabInfo()).selectedReport;
        this.state = {
            reportData: {},
            recentRecordId: null,
            parameterId: null,
            itemType: null,
            objectName: null,
            dueBy: 'tm',
            fromDate: null,
            toDate: null,
            workflowId: -99,
            workflowName: null,
            workflowList: null,
            isGlobal: false,
            isCountry: false,
            isFranchise: false,
            franchiseList: [],
            salesRepList: [],
            selectedFrnProjectId: null,
            selectedFranchise: null,
            userNames: [],
            selectedActivityTypes: [],
            selectedUserName: [],
            selectedStatus: [],
            workflowstages: [],
            noteTypes: [],
            showType: [
                { id: 'count', label: getLocalizedStrings().label.REPORT.COUNT },
                { id: 'value', label: getLocalizedStrings().label.REPORT.VALUE }
            ],
            selectedShow: 'value',
            chartType: [
                { id: 'column', label: 'Column' },
                // { id: 'column3d', label: '3D bar graph' },
                { id: 'pie', label: 'Pie' },
                { id: 'funnel', label: 'Funnel' },
                //{ id: 'pyramid', label: 'Pyramid' },
                { id: 'line', label: 'Line' },
                { id: 'bar', label: 'Bar' },
                //{ id: 'pie3d', label: '3D pie graph' }
            ],
            selectedChartType: 'funnel',
            pipelineType: [
                { id: 'value', label: 'Pipeline' },
                { id: 'forecast', label: 'Forecast' }
            ],
            selectedPipelineType: 'value',
            rowSort: {
                // enableAllColumn: true,
                sortFieldName: 'sales_rep',
                isDescending: false,
                onSort: this.onSort
            },
            objectList: [
                { id: 'accounts', label: getLocalizedStrings().label.REPORT.REPORT_ON_OBJECTS.ACCOUNTS },
                { id: 'opportunities', label: getLocalizedStrings().label.REPORT.REPORT_ON_OBJECTS.OPPORTUNITIES }
            ]
        }
    }



    componentDidMount() {
        let itemType = this.selectedReport.item_type;
        if (itemType === "Activity Matrix" || itemType === "Activities By Sales Rep") {
            this.getNoteTypeValues();
        }
        getSalesReportInfo(this.selectedReport);
        this.setHeader();
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        let itemType = this.selectedReport.item_type;
        if (this.props.reportInfo.mounted !== nextProps.reportInfo.mounted && nextProps.reportInfo.mounted) {
            this.updateStateForRecentReport(nextProps);
            this.loadReport();
        }
    }

    setHeader = (props) => {
        let selectedReport = this.selectedReport;
        setHeaderByDispatch(constants.SALES_OBJECT, getStringParam(selectedReport.item_name), null, false, true);
    }

    updateStateForRecentReport = (props) => {
        try {
            let _state = this.state;
            let data = props.reportInfo.recentReport;
            if (isValidParam(_state)) {
                /*************************** START: Default values  ***************************/
                let selectedReport = this.selectedReport;
                _state.parameterId = selectedReport.parameter_id;
                _state.itemType = selectedReport.item_type;
                _state.recentRecordId = 0;
                _state.objectName = constants.ACCOUNTS_OBJECT;
                let chartType = _state.chartType;
                sortArrayObjectByProperty(chartType, "label");
                _state.chartType = chartType;
                let showTypes = [];
                let homedetails = this.props.app.homeItemLableList
                _state.objectList.map((objectData, index) => {
                    let homedata = homedetails.filter(e => e.object === objectData.id);
                    objectData.label = homedata.length > 0 ? homedata[0].label : objectData.label;
                    showTypes.push(objectData);
                })
                _state.objectList = showTypes;
                /*************************** END: Default values    ***************************/

                if (isValidParam(data) && Object.keys(data).length > 0) {
                    _state.recentRecordId = data.recent_record_id;

                    _state.parameterId = data.parameter_id;
                    _state.itemType = data.item_type;
                    _state.selectedUserName = data?.user_names ? data.user_names.split(",") : [];
                    _state.objectName = getStringParam(data.object_name) !== '' ? getStringParam(data.object_name) : constants.ACCOUNTS_OBJECT;

                    if (getStringParam(data.due_by) !== '') {
                        _state.dueBy = getStringParam(data.due_by);
                    }
                    if (data.due_by === "cust") {
                        _state.fromDate = reportToDate(data.from_date);
                        _state.toDate = reportToDate(data.to_date);
                    } else {
                        _state.fromDate = null;
                        _state.toDate = null;
                    }
                    _state.workflowId = getIntParam(data.workflow_id);

                    if (getStringParam(data.itemType) === "InXpress Report 18" && getBooleanParam(data.isCountry)) {
                        _state.parameterId = data.linkId;
                        _state.itemType = data.itemType;
                        _state.isGlobal = data.isGlobal;
                        _state.isCountry = data.isCountry;
                        _state.isFranchise = data.isFranchise;
                        _state.franchiseList = getArrayParam(data.franchises).map(m => { return { id: m.projectId, label: m.franchise_no } });
                        _state.selectedFrnProjectId = getStringParam(_state.franchiseList[0].id);
                        _state.selectedFranchise = getStringParam(_state.franchiseList[0].label);
                        _state.selectedUserName = 'All';
                        this.getSalesRepList(_state.selectedFrnProjectId);
                    }
                    if (getStringParam(data.item_type) === "Pipeline Report" || getStringParam(data.item_type) === "Conversion Report" || getStringParam(data.item_type) === "Conversion By Sales Rep" || getStringParam(data.item_type) === "Pipeline by Sales Rep"
                        || getStringParam(data.item_type) === "SALES SUMMARY REPORT" || getStringParam(data.item_type) === "Conversion Matrix" || getStringParam(data.item_type) === "Activity Matrix" || getStringParam(data.item_type) === "Activities By Sales Rep") {
                        let userNameList = this.generateUserNames();
                        let usersDetails = [];
                        if (getStringParam(data.item_type) === "Activities By Sales Rep") {
                            usersDetails = data.user_names !== null && data.user_names !== "" ? data.user_names.split(",") : ['All'];
                        } else {
                            usersDetails = data.user_names !== null && data.user_names !== "" ? data.user_names.split(",") : [];
                        }
                        if (usersDetails.length === 1 && usersDetails[0] === 'All') {
                            userNameList.map((user, index) => {
                                usersDetails.push(user.id);
                            });
                        } else if (userNameList.length === usersDetails.length) {
                            let isContain = usersDetails.filter((e => e === "All")).length > 0 ? true : false;
                          
                        } else if (userNameList.length !== usersDetails.length) {
                            let isContain = usersDetails.filter((e => e === "All")).length > 0 ? true : false;
                            if (isContain) {
                                let tempUsersDetails = usersDetails;
                                usersDetails = [];
                                tempUsersDetails.map((user, index) => {
                                    if (user !== "All") {
                                        usersDetails.push(user);
                                    }
                                });
                            }
                     
                        }
                        _state.userNames = usersDetails;
                        _state.selectedUserName = usersDetails;
                        _state.selectedStatus = data.status !== null && data.status !== "" ? data.status.split(",") : [];
                        _state.selectedChartType = data.chart_type !== null && data.chart_type !== "" ? data.chart_type : _state.selectedChartType;
                        _state.selectedShow = data.show_type !== null && data.show_type !== undefined && data.show_type !== "" ? data.show_type : _state.selectedShow;
                        _state.selectedPipelineType = data.pipeline_type !== null && data.pipeline_type !== "" ? data.pipeline_type : _state.selectedPipelineType;
                        if (getStringParam(data.item_type) === "Pipeline by Sales Rep" || getStringParam(data.item_type) === "Conversion By Sales Rep") {
                            _state.chartType = [{ id: 'stackedBar', label: 'Stacked bar' },
                            { id: 'stackedColumn', label: 'Stacked Column' }];
                            _state.selectedChartType = data.hasOwnProperty("chart_type") && data.chart_type !== null && data.chart_type !== undefined && data.chart_type !== '' ? data.chart_type : 'stackedColumn';
                        }
                        if (getStringParam(data.item_type) === "Pipeline Report") {
                            _state.selectedChartType = data.hasOwnProperty("chart_type") && data.chart_type !== null && data.chart_type !== undefined && data.chart_type !== '' ? data.chart_type : 'funnel';
                        }
                        if (getStringParam(data.item_type) === "Conversion Report") {
                            let chartType = this.state.chartType;
                            chartType = chartType.filter(e => e.id !== 'funnel');
                            _state.chartType = chartType;
                            _state.selectedChartType = data.hasOwnProperty("chart_type") && data.chart_type !== null && data.chart_type !== undefined && data.chart_type !== '' ? data.chart_type : 'column';
                        }
                        if (getStringParam(data.item_type) === "Activity Matrix" || getStringParam(data.item_type) === "Activities By Sales Rep") {
                            if (getStringParam(data.item_type) === "Activities By Sales Rep") {
                                _state.selectedChartType = data.hasOwnProperty("chart_type") && data.chart_type !== null && data.chart_type !== undefined && data.chart_type !== '' ? data.chart_type : 'column';
                            }
                            let activityLists = getArrayParam(this.getActivityLists());
                            let activityDetails = data.activity_type !== null && data.activity_type !== "" ? data.activity_type.split(",") : ['All'];
                            if (activityDetails.length === 1 && activityDetails[0] === 'All') {
                                activityLists.map((activity, index) => {
                                    activityDetails.push(activity.id);
                                });
                            } else if (activityLists.length === activityDetails.length) {
                                let isContain = activityDetails.filter((e => e === "All")).length > 0 ? true : false;
                                if (!isContain) {
                                    activityDetails.unshift('All');
                                }
                            } else if (activityLists.length !== activityDetails.length) {
                                let isContain = activityDetails.filter((e => e === "All")).length > 0 ? true : false;
                                if (isContain) {
                                    let tempActivityDetails = activityDetails;
                                    activityDetails = [];
                                    tempActivityDetails.map((activity, index) => {
                                        if (activity !== "All") {
                                            activityDetails.push(activity);
                                        }
                                    });
                                }
                            }
                            _state.selectedActivityTypes = activityDetails;
                        }
                    }
                } else {
                    if (getStringParam(selectedReport.item_type) === "Pipeline Report" || getStringParam(selectedReport.item_type) === "Conversion Report" || getStringParam(selectedReport.item_type) === "Pipeline by Sales Rep" || getStringParam(selectedReport.item_type) === "Conversion By Sales Rep"
                        || getStringParam(selectedReport.item_type) === "SALES SUMMARY REPORT" || getStringParam(selectedReport.item_type) === "Conversion Matrix" || getStringParam(selectedReport.item_type) === "Activity Matrix" || getStringParam(selectedReport.item_type) === "Activities By Sales Rep") {
                        _state.userNames = [];
                        _state.selectedStatus = [];
                        _state.selectedChartType = _state.selectedChartType;
                        _state.selectedShow = _state.selectedShow;
                        _state.selectedPipelineType = _state.selectedPipelineType;
                        if (getStringParam(selectedReport.item_type) === "Pipeline by Sales Rep" || getStringParam(selectedReport.item_type) === "Conversion By Sales Rep") {
                            _state.chartType = [{ id: 'stackedBar', label: 'Stacked bar' },
                            { id: 'stackedColumn', label: 'Stacked Column' }];
                            _state.selectedChartType = 'stackedColumn';
                            _state.selectedShow = 'count';
                        }
                        if (getStringParam(selectedReport.item_type) === "Pipeline Report") {
                            _state.selectedChartType = 'funnel';
                            _state.selectedShow = data.show_type !== null && data.show_type !== undefined && data.show_type !== "" ? data.show_type : 'count';
                        }
                        if (getStringParam(selectedReport.item_type) === "Conversion Report") {
                            _state.selectedChartType = 'column';
                        }
                        if (getStringParam(selectedReport.item_type) === "SALES SUMMARY REPORT") {
                            _state.selectedShow = 'count';
                        }
                        if (getStringParam(selectedReport.item_type) === "Activity Matrix" || getStringParam(selectedReport.item_type) === "Activities By Sales Rep") {
                            if (getStringParam(selectedReport.item_type) === "Activities By Sales Rep") {
                                _state.selectedChartType = data.hasOwnProperty("chart_type") && data.chart_type !== null && data.chart_type !== undefined && data.chart_type !== '' ? data.chart_type : 'column';
                            }
                            let activityLists = getArrayParam(this.getActivityLists());
                            let activityDetails = data.activity_type !== null && data.activity_type !== "" ? data.activity_type.split(",") : ['All'];
                            if (activityDetails.length === 1 && activityDetails[0] === 'All') {
                                activityLists.map((activity, index) => {
                                    activityDetails.push(activity.id);
                                });
                            }
                            _state.selectedActivityTypes = activityDetails;
                            let userNameList = this.generateUserNames();
                            let usersDetails = [];
                            if (getStringParam(selectedReport.item_type) === "Activities By Sales Rep") {
                                usersDetails = data.user_names !== null && data.user_names !== "" ? data.user_names.split(",") : ['All'];
                            } else {
                                usersDetails = data.user_names !== null && data.user_names !== "" ? data.user_names.split(",") : [];
                            }
                            if (usersDetails.length === 1 && usersDetails[0] === 'All') {
                                userNameList.map((user, index) => {
                                    usersDetails.push(user.id);
                                });
                            }
                            _state.userNames = usersDetails;
                        }
                    }
                }
                if (getStringParam(selectedReport.item_type) !== "Activity Matrix" && getStringParam(selectedReport.item_type) !== "Activities By Sales Rep") {
                    this.loadWorkflowByObject(props, _state.objectName);
                }
                this.setReportParamsFromTab();
            }
        } catch (error) {
            console.error("Error in 'salesReport.js -> updateStateForRecentReport()':" + error);
        }
    }

    getReportParams = () => {
        let selectedReport = this.selectedReport;
        let reportParams = {};
        try {
            let { recentRecordId, parameterId, itemType, dueBy, selectedUserName, objectName, workflowId, workflowName, selectedFrnProjectId, selectedFranchise } = this.state;
            reportParams.recent_record_id = getIntParam(recentRecordId);
            reportParams.parameter_id = getIntParam(parameterId);
            reportParams.item_type = getStringParam(itemType);
            reportParams.due_by = getStringParam(dueBy);
            reportParams.user_names = getStringParam(selectedUserName);
            reportParams.object_name = getStringParam(objectName);
            reportParams.workflow_id = getIntParam(workflowId);
            reportParams.workflow_name = getStringParam(workflowName);
            reportParams.selectedFrnProjectId = getStringParam(selectedFrnProjectId);
            reportParams.selectedFranchise = getStringParam(selectedFranchise);

            if (this.state.dueBy === 'cust' && isValidParam(this.state.fromDate) && isValidParam(this.state.toDate)) {
                reportParams.from_date = reportFormatDate(this.state.fromDate);
                reportParams.to_date = reportFormatDate(this.state.toDate);
            }
            reportParams.type = null;// [];// parameter "type" is not required for front end but without "type" data will not  come. type checkd in beckend side  
            if (selectedReport !== undefined && (selectedReport.item_name === 'Pipeline Report' || selectedReport.item_name === "Conversion Report" || selectedReport.item_name === 'Pipeline by Sales Rep' || selectedReport.item_name === "Pipeline Summary" || selectedReport.item_name === "Conversion By Sales Rep" || selectedReport.item_name === "Conversion Matrix" || selectedReport.item_name === "Activity Matrix" || selectedReport.item_name === "Activities By Sales Rep")) {
                let tempUserData = selectedUserName.toString();
                if (tempUserData.includes("All,")) {
                    tempUserData = tempUserData.replace('All,', '')
                }
                reportParams.user_names = tempUserData;
                if (selectedReport.item_name !== "Conversion Matrix") {
                    reportParams.chart_type = this.state.selectedChartType;
                }
                let tempStatus = this.state.selectedStatus.toString();
                if (tempStatus.includes("All,")) {
                    tempStatus = tempStatus.replace('All,', '')
                }
                reportParams.status = tempStatus;

                if (selectedReport.item_name !== "Conversion Report" && selectedReport.item_name !== "Conversion By Sales Rep" && selectedReport.item_name !== "Conversion Matrix") {
                    reportParams.pipeline_type = this.state.selectedPipelineType;
                    reportParams.show_type = this.state.selectedShow;
                }
                if (selectedReport.item_name === "Conversion Report" || selectedReport.item_name === "Conversion By Sales Rep" || selectedReport.item_name === "Conversion Matrix") {
                    reportParams.callfrom = 'salesModule';
                    delete reportParams.status;
                }
                if (this.state.selectedPipelineType === "value" && ( this.state.selectedPipelineType === "value" && selectedReport.item_name !== "Pipeline by Sales Rep" && selectedReport.item_name !== "Pipeline Report" && selectedReport.item_name !== "Pipeline Summary") && (selectedReport.item_name !== "Conversion Report" && selectedReport.item_name !== "Conversion By Sales Rep" && selectedReport.item_name !== "Conversion Matrix" && selectedReport.item_name !== "Activity Matrix" && selectedReport.item_name !== "Activities By Sales Rep")) {
                    reportParams.due_by = "";
                    reportParams.from_date = "";
                    reportParams.to_date = "";
                }
                if (selectedReport.item_name === "Activity Matrix" || selectedReport.item_name === "Activities By Sales Rep") {

                    let tempActivityTypes = this.state.selectedActivityTypes.toString();
                    reportParams.activityTypes = tempActivityTypes;
                    delete reportParams.show_type;
                    delete reportParams.pipeline_type;
                    delete reportParams.status;
                }
            }
            let tabInfo = getActiveTabInfo();
            tabInfo.report_params = reportParams;
        } catch (error) {
            console.error("Error in 'salesReport.js -> getReportParams()':" + error);
        }
        return reportParams;
    }

    setReportParamsFromTab = () => {
        try {
            let tabInfo = getActiveTabInfo();
            let reportParams = getObjectParam(tabInfo.report_params);

            if (Object.keys(reportParams).length > 0) {
                let { recent_record_id, parameter_id, item_type, due_by, user_names, object_name, workflow_id, workflow_name, selectedFrnProjectId, selectedFranchise } = reportParams;

                let state = this.state;
                state.recentRecordId = getIntParam(recent_record_id);
                state.parameterId = getIntParam(parameter_id);
                state.itemType = getStringParam(item_type);
                state.dueBy = getStringParam(due_by);
                state.selectedUserName = getStringParam(user_names);
                state.objectName = getStringParam(object_name);
                state.workflowId = getIntParam(workflow_id);
                state.workflowName = getStringParam(workflow_name);
                state.selectedFrnProjectId = getStringParam(selectedFrnProjectId);
                state.selectedFranchise = getStringParam(selectedFranchise);
                if (state.dueBy === "cust" && isValidParam(reportParams.from_date) && isValidParam(reportParams.to_date)) {
                    state.fromDate = reportToDate(reportParams.from_date);
                    state.toDate = reportToDate(reportParams.to_date);
                } else {
                    state.fromDate = null;
                    state.toDate = null;
                }
            }
        } catch (error) {
            console.error("Error in 'salesReport.js -> setReportParamsFromTab()':" + error);
        }
    }

    loadWorkflowByObject = (props, object) => {
        try {
            object = getStringParam(object);
            let selectedReport = this.selectedReport;
            let itemType = getStringParam(selectedReport.item_type);
            let objectWorkflow = isValidParam(props.reportInfo) && props.reportInfo.mounted ? getObjectParam(props.reportInfo.objectWorkflow) : null;
            if (object !== '' && isValidParam(objectWorkflow)) {
                let workflowList = getArrayParam(objectWorkflow[object]);
                    if(workflowList !== null && workflowList !== undefined && this.props.app.me.is_franchisee && (itemType === 'Pipeline Report' || itemType === 'Pipeline by Sales Rep' || itemType === "Pipeline Summary" || itemType === "SALES SUMMARY REPORT")){
                        workflowList = workflowList.filter(f => { return f.name != "Onboarding"});
                       }
                workflowList = workflowList.map(function (wf, index) {
                    return { id: wf.id, label: wf.name };
                });
                let { workflowId, workflowName } = this.state;
                if (workflowList.length > 0 && getIntParam(workflowId) == -99) {
                    workflowId = getIntParam(workflowList[0].id);
                    workflowName = getStringParam(workflowList[0].name);
                } else if (workflowList.length > 0 && workflowId > 0) {
                    workflowList.map((f, i) => {
                        if (f.id === workflowId) {
                            workflowName = f.name;
                        }
                    });
                }
                this.getWorkFlowStageById(workflowId)
                this.state = { ...this.state, workflowList: workflowList, workflowId: workflowId, workflowName: workflowName };
            }
        } catch (error) {
            console.log("Error in 'salesReport.js -> loadWorkflowByObject()':" + error);
        }
    }

    handleDropDownChange = (name, event, value) => {
        let selectedReport = this.selectedReport;
        let _state = this.state;
        _state[name] = value;
        if (name === "dueBy") {
            if (event === 'fromDate' || event === 'toDate') {
                _state[name] = 'cust';
                if (event === 'fromDate') {
                    _state.fromDate = value;
                } else if (event === 'toDate') {
                    _state.toDate = value;
                }
                this.setState(_state);
            } else if (value === "cust") {
                _state.fromDate = this.getDate();
                _state.toDate = this.getDate();
                this.setState(_state);
            } else {
                _state.fromDate = null;
                _state.toDate = null;
                this.setState(_state);
            }
        } else if (name === "objectName" && (selectedReport.item_name  == "Pipeline Report" || selectedReport.item_name === constants.PIPELINE_REPORT || selectedReport.item_name === constants.CONVERSION_REPORT || selectedReport.item_name === constants.DEALS_BY_SALES_REP || selectedReport.item_name === constants.CONVERSION_BY_SALES_REP || selectedReport.item_name === constants.SALES_SUMMARY_REPORT || selectedReport.item_name === constants.CONVERSION_MATRIX)) {
            _state.objectName = value;

            let workflowList = [];
            let object = value;
            let objectWorkflow = getObjectParam(this.props.reportInfo.objectWorkflow);
            let workflowListData = getArrayParam(objectWorkflow[object]);
            if(workflowListData !== null && workflowListData !== undefined &&  this.props.app.me.is_franchisee && (selectedReport.item_name === 'Pipeline Report' || selectedReport.item_name === 'Pipeline by Sales Rep' || selectedReport.item_name === "Pipeline Summary" || selectedReport.item_name === "SALES SUMMARY REPORT")){
                workflowListData = workflowListData.filter(f => { return f.name != "Onboarding"});
               }
            workflowList = workflowListData.map((wf, index) => { return { id: wf.id, label: wf.name }; });
            _state.workflowList = workflowList;
            if (workflowList.length > 0) {
                _state.workflowId = workflowList[0].id;
                _state.workflowName = workflowList[0].label;
                this.getWorkFlowStageById(workflowList[0].id)
            }
            _state.selectedShow = "count";
            _state.selectedStatus = [];
            this.setState(_state);
        } else if (name === "workflowName") {
            let wf = _state.workflowList.find((f) => { return f.id === value; });
            if (isValidParam(wf)) {
                _state.workflowId = wf.id;
                _state.workflowName = wf.label;
            }
            _state.selectedStatus = [];
            this.setState(_state);
            if (isValidParam(wf)) {
                this.getWorkFlowStageById(wf.id)
            }
        } else if (name === "workflowstage") {
            let finalData = [];
            this.state.workflowstages.map((stage, index) => {
                let data = getStringParam(stage.id);
                if (data !== "") {
                    finalData.push(data);
                }
            });
            if(this.state.selectedStatus.length === 0 && value.indexOf("All")  !== -1) {
                this.setState({selectedStatus: ['All',...finalData]})
                return;
            }
            if (value.indexOf("All") === -1) {
                if(value.length === finalData.length) {
                    this.setState((prevState) => { 
                        let data;
                        if(prevState.selectedStatus.length >= finalData.length) {
                            data = []
                        } else {
                            data = ['All',...finalData]
                        }
                        return ({
                            ...prevState,
                            selectedStatus: data
                        })
                    })
                    return;
                }
            } 
            if(this.state.selectedStatus.length  <= finalData.length && value.indexOf("All") !== -1) {
                this.setState({selectedStatus: ['All', ...finalData]})
                return;
            }
            
            if(value.length >= finalData.length && value.indexOf("All")  !== -1) {
                this.setState({selectedStatus: value.splice(1)})
                return;
            }
            this.setState({selectedStatus: value})
        } else if (name === 'selectedFrnProjectId') {
            let franchiseList = getArrayParam(this.state.franchiseList);
            let franchise = franchiseList.find(f => { return f.id === value });
            if (isValidParam(franchise)) {
                _state.selectedFrnProjectId = franchise.id.toString();
                _state.selectedFranchise = franchise.label.toString();
                _state.selectedUserName = 'All';
                this.getSalesRepList(franchise.id);
            }
            this.setState({});
        } else if (name === 'userName') {
            let userNameList = this.generateUserNames();
            let finalData = [];
            userNameList.map((user, index) => {
                let data = getStringParam(user.id);
                if (data !== "") {
                    finalData.push(data);
                }
            });
            if(this.state.selectedUserName.length === 0 && value.indexOf("All")  !== -1) {
                this.setState({selectedUserName: ['All',...finalData]})
                return;
            }
            if (value.indexOf("All") === -1) {
                if(value.length === finalData.length) {
                    this.setState((prevState)=>{ 
                        let data;
                        if(prevState.selectedUserName.length >= finalData.length) {
                            data = []
                        } else {
                            data = ['All',...finalData]
                        }
                        return ({
                            ...prevState,
                            selectedUserName: data
                        })
                    })
                    return;
                }
            } 
            if(this.state.selectedUserName.length  <= finalData.length && value.indexOf("All") !== -1) {
                this.setState({selectedUserName: ['All', ...finalData]})
                return;
            }
            if(value.length >= finalData.length && value.indexOf("All")  !== -1) {
                this.setState({selectedUserName: value.splice(1)})
                return;
            }
            this.setState({selectedUserName: value})
        } else if (name === 'activityTypes') {
            let activityLists = this.getActivityLists();
            let finalData = [];
            activityLists.map((item, index) => {
                let data = getStringParam(item.id);
                if (data !== "") {
                    finalData.push(data);
                }
            });
            if(this.state.selectedActivityTypes.length === 0 && value.indexOf("All")  !== -1) {
                this.setState({selectedActivityTypes: ['All',...finalData]})
                return;
            }
            if (value.indexOf("All") === -1) {
                if(value.length === finalData.length) {
                    this.setState((prevState)=>{ 
                        let data;
                        if(prevState.selectedActivityTypes.length >= finalData.length) {
                            data = []
                        } else {
                            data = ['All',...finalData]
                        }
                        return ({
                            ...prevState,
                            selectedActivityTypes: data
                        })
                    })
                    return;
                }
            } 
            if(this.state.selectedActivityTypes.length  <= finalData.length && value.indexOf("All") !== -1) {
                this.setState({selectedActivityTypes: ['All', ...finalData]})
                return;
            }
            if(value.length >= finalData.length && value.indexOf("All")  !== -1) {
                this.setState({selectedActivityTypes: value.splice(1)})
                return;
            }
            this.setState({selectedActivityTypes: value})
        } else if (name == "pipeLine" && (selectedReport.item_name === constants.PIPELINE_REPORT || selectedReport.item_name ==="Pipeline Report" || selectedReport.item_name === constants.DEALS_BY_SALES_REP || selectedReport.item_name === constants.SALES_SUMMARY_REPORT)) {            _state.selectedPipelineType = value;
            if (this.state.dueBy === 'cust' && value === 'value') {
                _state.dueBy = "tm";
            }
            this.setState(_state);
        } else if(name === 'userName') {
            this.setState({userNames: value})
        } else if (name === 'singleSelectDropDownUserName') {
            this.setState({selectedUserName: value})
        } else {
            this.setState(_state);
        }
    }

    getDate = (date) => {
        let dateValue = isValidParam(date) ? new Date(date) : new Date();
        dateValue = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
        return dateValue;
    }

    getMinDate = () => {
        let minDateVal = null;
        if (isValidParam(this.state.fromDate)) {
            let fromDateVal = new Date(this.state.fromDate);
            minDateVal = new Date(fromDateVal.getFullYear(), fromDateVal.getMonth(), fromDateVal.getDate());
        }
        return minDateVal;
    }

    generateUserNames() {
        let list = [];
        if (this.selectedReport.item_type === 'InXpress Report 18') {
            list = this.state.salesRepList;
        } else {
            list = this.props.userlist.data.map((user, index) => {
                return { id: user.value, label: user.value };
            });
        }
        return list;
    }

    generateChart = (reportTypeName, reportData) => {
        let currency = this.props.app.me.currency_symbol;
        let drWidth= window.innerWidth-60;
        if (reportData !== null && reportData !== undefined && Object.keys(reportData).length > 0 && getArrayParam(reportData.report_values).length > 0) {
            return reportData.report_values.map(( /*reportName*/ reportDataMap, index) => {
                let reportName = reportData.report_values[index].name;
                if (this.props.object === "sales") {
                    if (reportTypeName === 'Activities By Sales Rep') {
                        let reportConfData = reportData.report_values[index].value;

                        if (reportName === 'activity_by_salesrep' || reportName === 'activity_by_type') {
                            if (reportConfData.title.indexOf('Activities') === -1)
                                reportConfData.title = "Activities " + reportConfData.title;
                            let reportConfObject = processChartData(reportConfData, reportData.report_values[index].value.chart_type, reportName, currency);
                            return (
                                <div className="col-md-12" style={{ width: drWidth}}>
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        } else if (reportName === 'activity_matrix') {
                            let configData = JSON.parse(JSON.stringify(matrixReportInitialData));
                            configData.reportName = "Activity Matrix";
                            configData.column = [
                                {
                                    "isVisible": true,
                                    "name": "value",
                                    "label": this.localizedStrings.label.REPORT['ACTIVITY_TYPE'],//"Activity Type",
                                    "isToatalField": false,
                                    //  "isDetailsLink": true
                                },
                                {
                                    "isVisible": true,
                                    "name": "count",
                                    "label": this.localizedStrings.label.REPORT['Count'],//"Count",
                                    "isToatalField": true,
                                    "isDetailsLink": true
                                }
                            ];
                            configData.data = reportConfData === undefined ? [] : reportConfData.sales_report_result;
                            configData.footer = [
                                {
                                    "isVisible": true,
                                    "showTotal": false,
                                    "colspan": 1,
                                    "label": this.localizedStrings.label.REPORT['Grand Total'],//"Grand Total",
                                    //  "isDetailsLink": true
                                },
                                {
                                    "isVisible": true,
                                    "showTotal": true,
                                    "colspan": 1,
                                    "label": "",
                                    "isDetailsLink": true
                                }

                            ]

                            return (
                                <div className="col-md-12">
                                    <MatrixReportTable config={configData} rawData={reportConfData} drillDownMatrixReport={drillDownMatrixReport} checkDataForExport={this.checkDataForExport} />
                                </div>
                            );
                        }
                    } else if (reportTypeName === "Conversion Report" || reportTypeName === "Franchise Conversion Report") {
                        let reportConfData = reportData.report_values[index].value;
                        if (reportName === 'conversion_by_source') {
                            let reportConfObject = processChartData(reportConfData, 'column', reportName, currency);
                            return (
                                <div className="col-md-12">
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        } else if (reportName === 'conversion_by_status') {
                            let reportConfObject = processChartData(reportConfData, reportData.report_values[index].value.chart_type, reportName, currency);
                            return (
                                <div className="col-md-12" style={{ width: drWidth}}>
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        } else if (reportName === 'matrix_by_status') {
                            let arrData = isValidParam(reportConfData) ? reportConfData.sales_report_result : [];
                            return <div className="col-md-12">
                                <MatrixReportTable config={getMatrixByStatusProps(this, arrData)} rawData={reportConfData} drillDownMatrixReport={drillDownMatrixReport} checkDataForExport={this.checkDataForExport} />
                            </div>;
                        } else if (reportName === 'matrix_by_source') {
                            let arrData = isValidParam(reportConfData) ? reportConfData.sales_report_result : [];
                            return <div className="col-md-12">
                                <MatrixReportTable config={getMatrixBySourceProps(this, arrData)} rawData={reportConfData} drillDownMatrixReport={drillDownMatrixReport} checkDataForExport={this.checkDataForExport} />
                            </div>;
                        }
                    } else if (reportTypeName === "Pipeline Report") {
                        if (reportName === 'deals_by_sales_rep' || reportName === 'deals_by_stages') {
                            let reportConfObject = processChartData(reportData.report_values[index].value, 'pie', reportName, currency);
                            return (
                                <div className="col-md-12">
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        } else if (reportName === 'pipeline_report') {
                            let reportConfObject = processChartData(reportData.report_values[index].value, reportData.report_values[index].value.chart_type, reportName, currency);
                            return (
                                <div className="col-md-12" style={{ width: drWidth}}>
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        }
                    } else if (reportTypeName === "Pipeline by Sales Rep") {
                        let reportConfObject = processChartData(reportData.report_values[index].value, reportData.report_values[index].value.chart_type, reportName, currency);
                        return (
                            <div className="col-md-12" style={{ width: drWidth}}>
                                <ReactChart config={reportConfObject} />
                            </div>
                        );
                    } else if (reportTypeName === "Conversion By Sales Rep") {
                        let reportConfObject = processChartData(reportData.report_values[index].value, reportData.report_values[index].value.chart_type, reportName, currency);
                        return (
                            <div className="col-md-12" style={{ width: drWidth}}>
                                <ReactChart config={reportConfObject} />
                            </div>
                        );
                    } else if (reportTypeName === "Call Report") {
                        if (reportName === 'total_calls_by_salesrep' || reportName === 'total_call_duration_by_salesrep') {
                            let reportConfObject = processChartData(reportData.report_values[index].value, 'column', reportName, currency);
                            return (
                                <div style={{...styles.sf_12, width:drWidth}}>
                                    <ReactChart config={reportConfObject} />
                                </div>
                            );
                        }
                    } else if (reportName === 'Conversion Matrix') {
                        let reportConfData = reportData.report_values[index].value;
                        let arrData = isValidParam(reportConfData) ? reportConfData.sales_report_result : [];
                        return <div className="col-md-12">
                            <MatrixReportTable config={getMatrixByStatusProps(this, arrData)} rawData={reportConfData} drillDownMatrixReport={drillDownMatrixReport} checkDataForExport={this.checkDataForExport} />
                        </div>;
                    } else if (reportName === 'Activity Matrix') {
                        let reportConfData = reportData.report_values[index].value;
                        let configData = JSON.parse(JSON.stringify(matrixReportInitialData));
                        configData.reportName = "Activity Matrix";
                        configData.column = [
                            {
                                "isVisible": true,
                                "name": "value",
                                "label": this.localizedStrings.label.REPORT['ACTIVITY_TYPE'],//"Activity Type",
                                "isToatalField": false,
                                //  "isDetailsLink": true
                            },
                            {
                                "isVisible": true,
                                "name": "count",
                                "label": this.localizedStrings.label.REPORT['Count'],//"Count",
                                "isToatalField": true,
                                "isDetailsLink": true
                            }
                        ];
                        configData.data = reportConfData === undefined ? [] : reportConfData.sales_report_result;
                        configData.footer = [
                            {
                                "isVisible": true,
                                "showTotal": false,
                                "colspan": 1,
                                "label": this.localizedStrings.label.REPORT['Grand Total'],//"Grand Total",
                                //  "isDetailsLink": true
                            },
                            {
                                "isVisible": true,
                                "showTotal": true,
                                "colspan": 1,
                                "label": "",
                                "isDetailsLink": true
                            }

                        ]

                        return (
                            <div className="col-md-12">
                                <MatrixReportTable config={configData} rawData={reportConfData} drillDownMatrixReport={drillDownMatrixReport} checkDataForExport={this.checkDataForExport} />
                            </div>
                        );
                    }
                }
            });
        } else if (reportTypeName === "Pipeline Summary") {
            console.log("reportData",reportData)
            // let reportConfObject = processChartData(reportData.report_values[index].value, reportData.report_values[index].value.chart_type, reportName);
            if (reportData.report_results?.length > 0 && reportData.report_results[0].sales_rep !== undefined && reportData.report_results[0].sales_rep !== "") {
                let sfDataTableStyle = {};
                if(reportData.report_results.length > 10){
                    sfDataTableStyle = { marginTop: "1.5%", height: "100%" };
                } else {
                    let heightPercent = (reportData.report_results.length + 1) * 7;
                    sfDataTableStyle = { marginTop: "1.5%", height: 'fit-content',width:'fit-content' }
                }
                return (
                    <div className="col-md-12" style={sfDataTableStyle}>
                        <SFDataTable columns={reportData.fields} rowSort={this.state.rowSort} rows={reportData.report_results} customEvent={this.customEvent} />
                        <div className="col-md-12" style={{ marginTop: "1.5%", height: "95%" }}></div>
                    </div>
                );
            } else {
                return (<table className="layout_maintable" style={{ width: '100%', marginTop: '10px' }}><tr><td><span style={{ color: 'red' }}>{getLocalizedStrings().label.INXPRESS_REPORT.NO_RECORDS_FOUND}</span></td></tr></table>);
            }
        }
    }

    customEvent = (eventType, column, row, event) => {
        try {
            let info = getActiveTabInfo();
            var value = 0;
            row = getObjectParam(row);
            value = getFloatParam(row[column.name]);
            if (value > 0) {
                let object_name = this.state.objectName;
                let queryInfo = getObjectParam(this.props.app.queryInfo);
                //let queryName = 'All';
                let queryName = 'All records';
                let queryId = 0;
                let queryType = 'all';

                if (isValidParam(info) && info.hasOwnProperty(object_name)) {
                    let qryDetails = getObjectParam(info[object_name]);
                    queryId = qryDetails.qId;
                    queryName = qryDetails.qName;
                    queryType = qryDetails.qType;
                }

                if (queryId === 0) {
                    let params = {};
                    params.queries = [];
                    params.queries.push({ object: object_name, query_name: queryName });
                    let promise = getQueryDetails(params);

                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            response = getObjectParam(response);
                            for (let qName in response) {
                                queryInfo[qName] = response[qName];
                            }
                            if (isValidParam(queryInfo[queryName])) {
                                let queryDtls = queryInfo[queryName];
                                let filter = { id: queryDtls.query_id, name: queryDtls.query_name, type: queryDtls.query_type };
                                queryId = queryDtls.query_id;
                                queryName = queryDtls.query_name;
                                queryType = queryDtls.query_type;

                                this.openListView(info, object_name, queryId, queryName, queryType, column.name, row.sales_rep);

                            }
                        });
                    }
                } else {
                    this.openListView(info, object_name, queryId, queryName, queryType, column.name, row.sales_rep);
                }

            }

        } catch (error) {
            console.error("Error in 'salesReport.js -> customEvent()':" + error);
        }
    }

    openListView = (tabinfo, object_name, queryId, queryName, queryType, colName, sales_rep) => {
        try {
            let dueBy = (this.state.selectedPipelineType === "forecast" || (this.state.selectedPipelineType === "value" && this.state.itemType === "SALES SUMMARY REPORT")) ? getStringParam(this.state.dueBy) : "";
            let fromDate = reportFormatDate(this.state.fromDate);
            let toDate = reportFormatDate(this.state.toDate);
            let user_name = sales_rep;
            let workflow_name = this.state.workflow_names;
            let workflow_id = this.state.workflowId;

            tabinfo[object_name] = { qId: queryId, qName: queryName, qType: queryType };

            let filter = {
                id: queryId,
                name: queryName,
                type: queryType,
            }
            let url = "/" + object_name + "/" + 'queries';
            let label = getStringParam(getLocalizedStrings().label.PIPELINE_REPORT.SALES_SUMMARY_REPORT);
            let tab = {
                label: colName,
                object: object_name,
                type: TYPE_LIST_VIEW,
                imgName: "fa fa-bullhorn",
                info: {
                    filter: filter,
                    input_param: {
                        report_due_by: dueBy,
                        report_user_names: user_name,
                        is_report: true,
                        report_type: (this.state.selectedPipelineType === "value" && this.state.itemType === "SALES SUMMARY REPORT")? SALES_SUMMARY_REPORT_PIPILINE.TYPE_PIPELINE_REPORT : PIPELINE_REPORTS_MAP.TYPE_PIPELINE_REPORT,
                        report_name: constants.SALES_SUMMARY_REPORT,
                        report_click_from: 'DRILLDOWN',
                        status: colName,
                        report_search_condition: "workflow_id = " + workflow_id,
                        report_pipeline_type:(this.state.selectedPipelineType === "value" && this.state.itemType === "SALES SUMMARY REPORT") ? "Pipeline Summary": getStringParam(this.state.selectedPipelineType)
                    },
                    isReport: true,
                    reportLabel: label,
                    groupByFieldName: "t_status",
                    groupByCondition: colName,
                    reportFieldValue: colName,
                },
                url: url,
                isActive: true
            };

            if (dueBy === 'cust') {
                tab.info.input_param.report_from_date = reportFormatDate(this.state.fromDate);
                tab.info.input_param.report_to_date = reportFormatDate(this.state.toDate);
            }

            addTab(tab, true);
        } catch (error) {
            console.error("Error in 'salesReport.js -> openListView()':" + error);
        }

    }

    onSort = (fieldName, isDescending) => {
        let rowSort = this.state.rowSort;//this.props.reportData.fields;
        let records = getArrayParam(this.props.reportData.report_results);
        let sortFieldName = getStringParam(fieldName);
        let activeTab = getActiveTab();
        let info = getObjectParam(activeTab.info);
        try {
            if (!getBooleanParam(rowSort.isDescending)) {
                this.sortArrayObjectByPropertyDesc(records, sortFieldName);
                rowSort.isDescending = isDescending;//true;
            }
            else {
                sortArrayObjectByProperty(records, sortFieldName);
                rowSort.isDescending = isDescending;//false;
            }
        }
        catch (error) {
            console.error("Error in 'salesReport.js -> onSort()':" + error);
        }
        info.isDescending = rowSort.isDescending;
        updateTabInfo(info);
        this.setState({ rowSort: rowSort });
    }

    sortArrayObjectByPropertyDesc = (array, property) => {
        if (Array.isArray(array)) {
            array.sort((obj1, obj2) => {
                if (isValidParam(obj1) && obj1.hasOwnProperty(property) && isValidParam(obj2) && obj2.hasOwnProperty(property)) {
                    let val1 = obj1[property];
                    let val2 = obj2[property];

                    val1 = typeof val1 === "string" ? val1.toLowerCase() : val1;
                    val2 = typeof val2 === "string" ? val2.toLowerCase() : val2;

                    if (val1 > val2) {
                        return -1;
                    } else if (val1 < val2) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
        }
    }

    generateReportTypeWiseDiv() {
        let selectedReport = this.selectedReport;
        if (isValidParam(selectedReport)) {
            let reportTypeName = selectedReport.item_name;
            if (reportTypeName === 'Activities By Sales Rep' || reportTypeName === 'Conversion Report' || reportTypeName === 'Pipeline Report'
                || reportTypeName === 'Call Report' || reportTypeName === 'Franchise Conversion Report'
                || reportTypeName === 'Pipeline by Sales Rep' || reportTypeName === 'Conversion By Sales Rep' || reportTypeName === "Pipeline Summary" || reportTypeName === "Conversion Matrix" || reportTypeName === "Activity Matrix") {
                return this.props.reportData.mounted ? this.generateChart(reportTypeName, this.props.reportData) : <Loader />;
            } else {
                return <UnderProcessing />;
            }
        }
    }

    reloadReport = () => {
        let selectedReport = this.selectedReport;
        let isValid = true;
        if (selectedReport !== undefined && selectedReport.item_name === 'Franchise Conversion Report') {
            if (getIntParam(this.state.selectedFrnProjectId) === 0) {
                isValid = false;
                this.props.showCustomSnackBar(this.localizedStrings.message.INXPRESS_REPORT.VALIDATE_EMPTY_FRANCHISE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            } else if (getStringParam(this.state.selectedUserName).length === 0) {
                isValid = false;
                this.props.showCustomSnackBar(this.localizedStrings.message.INXPRESS_REPORT.VALIDATE_EMPTY_SALES_REP, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            } else if (getStringParam(this.state.objectName).length === 0) {
                isValid = false;
                this.props.showCustomSnackBar(this.localizedStrings.message.INXPRESS_REPORT.VALIDATE_EMPTY_OBJECT_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            }
        } else if (selectedReport !== undefined && (selectedReport.item_name === 'Pipeline Report' || selectedReport.item_name === 'Conversion Report' || selectedReport.item_name === 'Pipeline by Sales Rep' || selectedReport.item_name === 'Conversion By Sales Rep' || selectedReport.item_name === "Pipeline Summary" || selectedReport.item_name === "Conversion Matrix")) {
            if (this.state.selectedUserName.length === 0) {
                isValid = false;
                this.props.showCustomSnackBar(this.localizedStrings.message.INXPRESS_REPORT.VALIDATE_EMPTY_SALES_REP, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            } else if (this.state.objectName === "") {
                isValid = false;
                this.props.showCustomSnackBar(this.localizedStrings.message.INXPRESS_REPORT.VALIDATE_EMPTY_OBJECT_NAME, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            } else if (this.state.workflowId === -99) {
                isValid = false;
                this.props.showCustomSnackBar(this.localizedStrings.message.INXPRESS_REPORT.VALIDATE_EMPTY_WORKFLOW, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            } else if (this.state.selectedStatus.length === 0 && selectedReport.item_name !== "Conversion Report" && selectedReport.item_name !== "Conversion Matrix" && selectedReport.item_name !== 'Conversion By Sales Rep') {
                isValid = false;
                this.props.showCustomSnackBar(this.localizedStrings.message.INXPRESS_REPORT.VALIDATE_EMPTY_WORKFLOW_STAGES, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            } else if (this.state.selectedChartType === "") {
                isValid = false;
                this.props.showCustomSnackBar(this.localizedStrings.message.INXPRESS_REPORT.VALIDATE_EMPTY_CHART_TYPE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            } else if (this.state.selectedShow === "") {
                isValid = false;
                this.props.showCustomSnackBar(this.localizedStrings.message.INXPRESS_REPORT.VALIDATE_EMPTY_SHOW_TYPE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            }
        } else if (selectedReport !== undefined && (selectedReport.item_name === "Activity Matrix" || selectedReport.item_name === "Activities By Sales Rep")) {
            if (this.state.selectedUserName.length === 0) {
                isValid = false;
                this.props.showCustomSnackBar(this.localizedStrings.message.INXPRESS_REPORT.VALIDATE_EMPTY_SALES_REP, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            } else if (this.state.selectedActivityTypes.length === 0) {
                isValid = false;
                this.props.showCustomSnackBar(this.localizedStrings.message.INXPRESS_REPORT.VALIDATE_EMPTY_ACTIVITY_TYPE, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            }
        }
        if (isValid) {
            this.loadReport();
        }
    }

    loadReport = () => {
        let reportParams = this.getReportParams();
        let isValidRequest = true;
        let errorMsg = "";
        try {
            if (getStringParam(reportParams.due_by) === 'cust' && (!isValidParam(reportParams.from_date) || !isValidParam(reportParams.to_date))) {
                isValidRequest = false;
                errorMsg = this.localizedStrings.message.INXPRESS_REPORT.BLANK_FROM_TO_DATE;
            }else if(new Date(reportParams.from_date) > new Date(reportParams.to_date)){
                isValidRequest = false;
                errorMsg = this.localizedStrings.message.USER_REPORT.INVALID_START_END_DATE;
            }
            if (isValidRequest) {
                if (this.selectedReport.item_type === "Activities By Sales Rep") {
                    getReportDataPromise({ ...this.selectedReport, type: "Activity Report Sales Rep" }, reportParams);
                    // getReportDataPromise({ ...this.selectedReport, type: "Activity Report Type" }, reportParams);
                    // getReportDataPromise({ ...this.selectedReport, type: "Activity Report Matrix" }, reportParams);
                } else if (this.selectedReport.item_type === "Conversion Report"|| this.selectedReport.item_type === constants.PIPELINE_REPORT) {
                    getReportDataPromise({ ...this.selectedReport, type: "Conversion Report Status" }, reportParams);
                    // getReportDataPromise({ ...this.selectedReport, type: "Conversion Report Source" }, reportParams);
                    // getReportDataPromise({ ...this.selectedReport, type: "Conversion Report Matrix Status" }, reportParams);
                    // getReportDataPromise({ ...this.selectedReport, type: "Conversion Report Matrix Source" }, reportParams);
                } else if (this.selectedReport.item_type === "Activity Matrix") {
                    getReportDataPromise({ ...this.selectedReport, type: "Activity Report Matrix" }, reportParams);
                } else {
                    getReportDataPromise(this.selectedReport, reportParams);
                }
            } else {
                this.props.showCustomSnackBar(errorMsg, styles.snackbarBodyStyleError, styles.snackBarStyleTop);
            }
        } catch (error) {
            console.log("Error in 'salesReport.js -> loadReport()':" + error);
        }
    }

    getSalesRepList = (projectId) => {
        let promise = getSalesRepListPromise(projectId);
        promise.then(response => {
            if (isValidParam(response.salesRep)) {
                let salesRepList = getArrayParam(response.salesRep);
                salesRepList = salesRepList.map(m => { return { id: m, label: m } });
                this.setState({ salesRepList: salesRepList });
            }
        });
    }

    checkDataForExport = (obj, buttonType) => {
        if (getArrayParam(obj.data).length > 0) {
            return true;
        } else {
            if(obj.reportName == "Activity Matrix" && buttonType !== undefined && buttonType == 'pdf'){
                this.props.showCustomSnackBar('No record available for PDF', styles.snackbarBodyStyleError, styles.snackBarStyleTop);

            }else{
                this.props.showCustomSnackBar(this.localizedStrings.message.INXPRESS_REPORT.NO_RECORD_EXPORT, styles.snackbarBodyStyleError, styles.snackBarStyleTop);

            }
            return false;
        }
    }
    getWorkFlowStageById = (workFlowId) => {
        let params = null;
        params = {
            workflow_id: workFlowId,
        }
        var response = getWorkFlowStage(this.state.objectName, params);
        response.then((response) => {
            let stages = [];
            response.stages.map((listValue, listValueIndex) => {
                listValue.id = listValue.name;
                listValue.label = listValue.name;
                stages.push(listValue);
            })
            this.state.workflowstages = stages;
            let selectedStatus = this.state.selectedStatus;
            if (stages.length === selectedStatus.length) {
                selectedStatus.unshift('All');
            }
            this.state.selectedStatus = selectedStatus;
            this.setState({});
        });
    }

    getNoteTypeValues = () => {
        let _state = this.state;
        if (this.props.note.allNoteTypes !== undefined && this.props.note.allNoteTypes.length === 0) {
            let url = endPoints.NOTES.NOTE_TYPE_GET_ALL;
            var promise = Promise.resolve(HTTPClient.get(url, null));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let noteTypes = response.noteTypes
                    sortArrayObjectByProperty(noteTypes, 'note_type');
                    _state.noteTypes = noteTypes;
                    this.setState(_state);
                    updateNoteTypeListAll(noteTypes);

                }
            });
        } else {
            let noteTypes = this.props.note.allNoteTypes;
            _state.noteTypes = noteTypes;
            this.setState(_state);
        }
    }

    getFilterProps = () => {
        let selectedReport = this.selectedReport;
        let itemType = getStringParam(selectedReport.item_type);
        let fieldProps = [];

        if (itemType === 'Pipeline Report' || itemType === 'Pipeline by Sales Rep' || itemType === "SALES SUMMARY REPORT") {
            fieldProps.push({
                dropdown: {
                    label: this.localizedStrings.label.REPORT.PIPELINE,
                    onChange: this.handleDropDownChange.bind(this, 'pipeLine'),
                    list: this.state.pipelineType,
                    defaultValue: this.state.selectedPipelineType,
                    style: { marginLeft: this.state.dueBy === 'cust' && this.state.selectedPipelineType === 'forecast' ? 3 : this.state.selectedPipelineType === 'value' ? 1 : this.state.selectedPipelineType === 'forecast' ? 10 : 1 }
                }
            });
        }

        if (itemType !== 'Pipeline Report' && itemType !== 'Pipeline by Sales Rep' && itemType !== "SALES SUMMARY REPORT") {
            fieldProps.push({
                date: {
                    list: getDateOptions(['blank']),
                    onChange: this.handleDropDownChange.bind(this, 'dueBy'),
                    defaultValue: this.state.dueBy,
                    fromDateOptions: { maxDate: new Date() },
                    toDateOptions: { minDate: this.getMinDate() },
                    profile: this.props.app.me,
                    fromDate: this.state.fromDate,
                    toDate: this.state.toDate
                }
            });
        }
        
        if ((itemType === 'Pipeline Report' || itemType === 'Pipeline by Sales Rep' || itemType === "SALES SUMMARY REPORT") && (this.state.selectedPipelineType === 'forecast' || this.state.selectedPipelineType ==='value')) {
            fieldProps.push({
                date: {
                    list: getDateOptions(['blank']),
                    onChange: this.handleDropDownChange.bind(this, 'dueBy'),
                    defaultValue: this.state.dueBy,
                    fromDateOptions: { maxDate: new Date() },
                    toDateOptions: { minDate: this.getMinDate() },
                    profile: this.props.app.me,
                    fromDate: this.state.fromDate,
                    toDate: this.state.toDate
                }
            });
        }

        let userNameList = this.generateUserNames();
        if (itemType !== 'InXpress Report 18' && itemType !== 'Pipeline Report' && itemType !== 'Conversion Report' && itemType !== 'Pipeline by Sales Rep' && itemType !== 'Conversion By Sales Rep' && itemType !== "SALES SUMMARY REPORT" && itemType !== "Conversion Matrix" && itemType !== "Activity Matrix" && itemType !== "Activities By Sales Rep") {
            // let isContain = userNameList.filter((e => e.id === "All")).length > 0 ? true : false;
            // if (!isContain) {
            //     userNameList.unshift({ id: 'All', label: 'All' });
            // }

            fieldProps.push({
                dropdown: {
                    id: 'userNames',
                    label: this.localizedStrings.label.REPORT.SALES_REP,
                    onChange: this.handleDropDownChange.bind(this, 'userName'),
                    list:  [{ id: 'All', label: 'All' },...userNameList],
                    value: this.state.selectedUserName.includes('All') ? 'All' :  this.state.selectedUserName,
                    defaultValue: this.state.selectedUserName.includes('All') ? 'All' :  this.state.selectedUserName,
                    style: { marginLeft: 10 }
                }
            });
        }
        if (itemType === 'Pipeline Report' || itemType === 'Conversion Report' || itemType === 'Pipeline by Sales Rep' || itemType === 'Conversion By Sales Rep' || itemType === "SALES SUMMARY REPORT" || itemType === "Conversion Matrix" || itemType === "Activity Matrix" || itemType === "Activities By Sales Rep") {
            // let isContain = userNameList.filter((e => e.id === "All")).length > 0 ? true : false;
            // if (!isContain) {
            //     userNameList.unshift({ id: 'All', label: 'All' });
            // }
           // let userArr = this.state.selectedUserName.join(',');

            let _selectedUserName =this.state.selectedUserName;
            let userArr =[];
           if(typeof(_selectedUserName) == 'string'){
              userArr = _selectedUserName.split(',');
           }else{
            userArr = _selectedUserName;
           }
           if (userArr.length === this.props.userlist?.data.length) {
            userArr.unshift('All');
           this.state.selectedUserName = userArr;
           }

            fieldProps.push({
                multiSelect: {
                    id: 'userNames',
                    label: this.localizedStrings.label.REPORT.SALES_REP,
                    onChange: (e) => this.handleDropDownChange('userName', e, e.target.value),
                    list: [{ id: 'All', label: 'All' }, ...userNameList],
                    value: this.state.selectedUserName,
                    style: {},
                    className: "sf-fields-bg",
                    variant: "outlined"
                }
            });
        }

        if (itemType === "Activity Matrix" || itemType === "Activities By Sales Rep") {
            let activityLists = getArrayParam(this.getActivityLists());
            fieldProps.push({
                multiSelect: {
                    id: 'activityTypes',
                    label: this.localizedStrings.label.REPORT.ACTIVITY_TYPE,
                    onChange: (e) => this.handleDropDownChange('activityTypes', e, e.target.value),
                    list: [{ id: 'All', label: 'All' }, ...activityLists],
                    value: this.state.selectedActivityTypes,
                    style: {},
                    className: "sf-fields-bg",
                    variant: "outlined"
                }
            });
        }

        if (itemType === 'Conversion Report' || itemType === 'Pipeline Report' || itemType === 'InXpress Report 18' || itemType === 'Pipeline by Sales Rep' || itemType === 'Conversion By Sales Rep' || itemType === "SALES SUMMARY REPORT" || itemType === "Conversion Matrix") {
            fieldProps.push({
                dropdown: {
                    label: this.localizedStrings.label.REPORT.REPORT_ON,
                    onChange: this.handleDropDownChange.bind(this, 'objectName'),
                    list: this.state.objectList,
                    defaultValue: this.state.objectName,
                    style: { marginLeft: this.state.dueBy === 'cust' && this.state.selectedPipelineType === 'forecast' ? 10 : this.state.selectedPipelineType === 'value' ? 1 : this.state.selectedPipelineType === 'forecast' ? 10 : 1 }
                }
            });
        }

        if (itemType === 'Pipeline Report' || itemType === 'Conversion Report' || itemType === 'Pipeline by Sales Rep' || itemType === 'Conversion By Sales Rep' || itemType === "SALES SUMMARY REPORT" || itemType === "Conversion Matrix") {
            fieldProps.push({
                dropdown: {
                    label: this.localizedStrings.label.REPORT.WORKFLOW_SELECT,
                    onChange: this.handleDropDownChange.bind(this, 'workflowName'),
                    list: this.state.workflowList,
                    defaultValue: this.state.workflowId,
                    style: { marginLeft: 0 }
                }
            });
        }

        if (itemType === 'InXpress Report 18') {
            let isContain = userNameList.filter((e => e.id === "All")).length > 0 ? true : false;
            if (!isContain) {
                userNameList.unshift({ id: 'All', label: 'All' });
            }
            fieldProps.push({
                dropdown: {
                    label: this.localizedStrings.label.INXPRESS_REPORT.FRANCHISE_SELECT,
                    onChange: this.handleDropDownChange.bind(this, 'selectedFrnProjectId'),
                    list: this.state.franchiseList,
                    defaultValue: this.state.selectedFrnProjectId,
                    style: { marginLeft: 10 }
                }
            });
            fieldProps.push({
                dropdown: {
                    label: this.localizedStrings.label.REPORT.SALES_REP,
                    onChange: (e) => this.handleDropDownChange('singleSelectDropDownUserName', e, e.target.value),
                    list: userNameList,
                    defaultValue: this.state.selectedUserName,
                    style: { marginLeft: 10 }
                }
            });
        }

        if (itemType === 'Pipeline Report' || itemType === 'Conversion Report' || itemType === 'Pipeline by Sales Rep' || itemType === 'Conversion By Sales Rep' || itemType === "Conversion Matrix" || itemType === "SALES SUMMARY REPORT" || itemType === "Activities By Sales Rep") {
            if (itemType !== "Conversion Report" && itemType !== "Conversion Matrix" && itemType !== 'Conversion By Sales Rep' && itemType !== "Activities By Sales Rep") {
                fieldProps.push({
                    multiSelect: {
                        id: 'workflowstages',
                        label: this.localizedStrings.label.REPORT.WORKFLOW_STAGES,
                        onChange: (e) => this.handleDropDownChange('workflowstage', e, e.target.value),
                        list: [{ id: 'All', label: 'All' }, ...this.state.workflowstages],
                        value: this.state.selectedStatus,
                        style: {},
                        className: "sf-fields-bg",
                        variant: "outlined"
                    }
                });
            }
            if (itemType !== "SALES SUMMARY REPORT" && itemType !== "Conversion Matrix") {
                fieldProps.push({
                    dropdown: {
                        label: this.localizedStrings.label.REPORT.CHART_TYPE,
                        onChange: this.handleDropDownChange.bind(this, 'selectedChartType'),
                        list: this.state.chartType,
                        defaultValue: this.state.selectedChartType,
                        style: { marginLeft: this.state.dueBy === 'cust' && this.state.selectedPipelineType === 'forecast' ? 3 : this.state.selectedPipelineType === 'value' ? 1 : itemType !== "SALES SUMMARY REPORT" && this.state.selectedPipelineType === 'forecast' ? 10 : itemType === "SALES SUMMARY REPORT" && this.state.selectedPipelineType === 'forecast' ? 10 : 1 }
                    }
                });
            }
            if (itemType !== 'Conversion Report' && itemType !== 'Conversion By Sales Rep' && itemType !== "Conversion Matrix" && itemType !== "Activities By Sales Rep") {
                fieldProps.push({
                    dropdown: {
                        label: this.localizedStrings.label.REPORT.SHOW,
                        onChange: this.handleDropDownChange.bind(this, 'selectedShow'),
                        list: this.state.showType,
                        defaultValue: this.state.selectedShow,
                        style: { marginLeft: this.state.dueBy === 'cust' && this.state.selectedPipelineType === 'forecast' ? 3 : this.state.selectedPipelineType === 'value' ? 1 : itemType !== "SALES SUMMARY REPORT" && this.state.selectedPipelineType === 'forecast' ? 3 : itemType === "SALES SUMMARY REPORT" && this.state.selectedPipelineType === 'forecast' ? 10 : 1 }
                    }
                });
            }
        }

        return fieldProps;
    }

    getActivityLists = () => {
        if (this.state.noteTypes !== undefined && this.state.noteTypes.length > 0) {
            let tempList = this.state.noteTypes.filter(f => { return f.is_active === true });;
            let finalList = [];
            tempList.map((data, Index) => {

                finalList.push({ id: data.note_type, label: data.note_type });
            })
            return finalList;
        }
    }

    downloadExcelReport = (e) => {
        if (e !== null && e !== undefined) {
            let beforeHeaderComponent = this.excelGenerateBeforeHeaders();
            let headerComponents = this.excelGenerateHeaders();
            let rowComponents = this.excelGenerateRows();

            let htmlTable = '<table border="1">' + beforeHeaderComponent + '<thead> ' + headerComponents + ' </thead><tbody>' + rowComponents + '  </tbody> </table>';
            let htmlData = htmlTable;
            if (isValidParam(htmlData) && htmlData !== '') {
                htmlData = htmlData.replace("%22", "\"");
                var blob = new Blob([htmlData], { type: 'application/vnd.ms-excel' });
                var downloadUrl = URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = downloadUrl;
                a.download = 'ResultSet_' + new Date().getTime() + '.xls';
                document.body.appendChild(a);
                a.click();
                a.remove();
            }
        }
    }


    excelGenerateBeforeHeaders = () => {
        let reportData = this.props.reportData;
        let optionsMultilingual = this.getMultilingualOptionsMap();
        let cells = '';
        let dueBy = '';
        let reportName = getStringParam(getLocalizedStrings().label.PIPELINE_REPORT.SALES_SUMMARY_REPORT);
        let tempColumns = getArrayParam(reportData.fields);
        let columns = tempColumns.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        let columnsLength = getArrayParam(columns).length;

        cells = cells + '<tr><td  colspan="' + (columnsLength) + '"> ' + reportName + ' </td></tr>';
        dueBy = getStringParam(optionsMultilingual[optionvalue[this.state.dueBy]]);
        if (reportName.length > 0) {
            if(dueBy === 'Custom'){
                let from_date = this.state.fromDate;
                let to_date = this.state.toDate;
                let start_date = moment(from_date);
                start_date = start_date.format('DD/MM/YYYY');
                let end_date = moment(to_date);
                end_date = end_date.format('DD/MM/YYYY')
                cells = cells +'<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>'+ '<tr><td style="text-align:center;">' + getLocalizedStrings().label.SALES_AND_REFUND.START_DATE + ':</td>  <td style="text-align:center;">' + start_date + ' </td>  <td style="text-align:center;">' + getLocalizedStrings().label.SALES_AND_REFUND.END_DATE + ':</td> <td style="text-align:center;">' + end_date + ' </td></tr>';
            }else{
                cells = cells + '<tr><td> Date :</td>  <td colspan="' + (columnsLength - 1) + '"> ' + dueBy + ' </td></tr>';
            }
        }
        return cells;
    }

    excelGenerateHeaders = () => {
        let reportData = this.props.reportData;
        let cells = '';
        let reportName = getStringParam(getLocalizedStrings().label.PIPELINE_REPORT.PIPELINE_REPORT_LABEL);
        let tempColumns = getArrayParam(reportData.fields);
        let columns = tempColumns.filter(f => {
            if (!f.is_hidden) { return f; }
        });
        columns.map(f => {
            cells = cells + '<th bgcolor="6A8CCB"> ' + f.label + ' </th>';
        });
        cells = '<tr>' + cells + '</tr>';
        return cells;
    }

    excelGenerateRows = () => {
        let reportData = this.props.reportData;
        let rows = getArrayParam(reportData.report_results);
        let columns = getArrayParam(reportData.fields);
        let rowData = '';
        if (rows.length !== 0) {
            let cells = '';
            rows.map((rowObject, rowIndex) => {
                let innerCells = '';
                columns.map((columnObject, colIndex) => {
                    if (!columnObject.is_hidden) {
                        innerCells = innerCells + '<td >' + rowObject[columnObject.name] + ' </td>';
                    }
                })
                if (rowIndex % 2 === 0) {
                    cells = cells + ('<tr>' + innerCells + ' </tr>');
                } else {
                    cells = cells + ('<tr  bgcolor="Lavender">' + innerCells + ' </tr>');
                }

            });
            rowData = cells;
        } else {
            rowData = ('<tr> <td colspan="' + (columns.length) + '"> No data Found  </td>  </tr>');
        }
        return rowData;

    }

    getMultilingualOptionsMap = () => {
        let optionsMultilingual = {
            'Today': getLocalizedStrings().label.DASHBOARD.TODAY,
            'Yesterday': getLocalizedStrings().label.DASHBOARD.YESTERDAY,
            'This Week': getLocalizedStrings().label.DASHBOARD.THIS_WEEK,
            'This Month': getLocalizedStrings().label.DASHBOARD.THIS_MONTH,
            'This Quarter': getLocalizedStrings().label.DASHBOARD.THIS_QUARTER,
            'This Year': getLocalizedStrings().label.DASHBOARD.THIS_YEAR,
            'Last Week': getLocalizedStrings().label.DASHBOARD.LAST_WEEK,
            'Last Month': getLocalizedStrings().label.DASHBOARD.LAST_MONTH,
            'Last Quarter': getLocalizedStrings().label.DASHBOARD.LAST_QUARTER,
            'Last Year': getLocalizedStrings().label.DASHBOARD.LAST_YEAR,
            'Custom': getLocalizedStrings().label.DASHBOARD.CUSTOM
        };
        return optionsMultilingual;
    }

    render() {
        if (!this.props.reportInfo.mounted) {
            return <Loader />
        }
        let isShowGo = true;
        let isCustom = false;
        let count = 0;
        let filters = this.getFilterProps();
        if (this.state.dueBy === 'cust') {
            count = filters.length + 3;
            isCustom = true;
        } else {
            count = filters.length + 1;
            isCustom = false;
        }
        let isShowExcel = false;
        let selectedReport = this.selectedReport;
        let itemType = getStringParam(selectedReport.item_type);
        if (itemType === "SALES SUMMARY REPORT") {
            isShowExcel = true;
        }

    let reportTypeWiseDiv = this.generateReportTypeWiseDiv();
        return <div style={{ width: '97%', marginLeft: 20 }}>
            <ReportFilter reportType={itemType} isShowExcel={isShowExcel} isShowGo={isShowGo} fieldProps={filters} reload={this.reloadReport} printContentId="printable-contents" count={count} isCustom={isCustom} downloadExcel={this.downloadExcelReport} />
            <div style={{ width: '97%',display : 'flex'}}></div>
            <div id="printable-contents">{reportTypeWiseDiv}</div>
        </div>
       
    }
}

const UnderProcessing = () => {
    return (
        <div className="row">
            <div className="col-md-12" style={{ textAlign: 'center', padding: '50px' }}>
                <h4 style={{ textAlign: 'center', padding: '50px' }}>{getLocalizedStrings().label.COMMON.COMING_SOON}</h4>
            </div>
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        header: state.header,
        reportInfo: state.salesReport.reportInfo,
        reportData: state.salesReport.reportData,
        app: state.app,
        userlist: state.userlist,
        pipelineReport: state.pipelineReport,
        note: state.note,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setHeader: (selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader) => {
            dispatch(setHeader(selectedTabObject, selectedHeader, kanban, showAddIcon, showHeader));
        },
        setReportRedirection: () => {
            dispatch(setReportRedirection());
        },
        setRecentReportResponseData: (reportData, reportParams, selectIndex, selectedHeader, reportObject) => {
            dispatch(setRecentReportResponseData(reportData, reportParams, selectIndex, selectedHeader, reportObject));
        },
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        updatePipelineReportData: (pipelineReportData, reportParams) => {
            dispatch(updatePipelineReportData(pipelineReportData, reportParams));
        },
        updatePipelineReportSalesRep: (pipelineReportSalesRep) => {
            dispatch(updatePipelineReportSalesRep(pipelineReportSalesRep));
        },
        updatePipelineReportWorkflows: (pipelineReportWorkflows) => {
            dispatch(updatePipelineReportWorkflows(pipelineReportWorkflows));
        },
        updateNoteTypeListAll: (projectId, object, recordId) => {
            dispatch(updateNoteTypeListAll(projectId, object, recordId));
        },
        /* setReportHeaderData: (reportHeaders, reportObject)=>{
            dispatch(setReportHeaderData(reportHeaders, reportObject));
        }, */
    }
}


SalesReport = connect(mapStateToProps, mapDispatchToProps)(SalesReport);

export default SalesReport;

const Loader = () => {
    let contentHeight = window.innerHeight - 240;
    let top = (contentHeight - 10) / 2
    return <div style={{ width: '100%', height: contentHeight }}>
        <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
            <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
            </div>
        </div>
    </div>
}

const getMatrixBySourceProps = (_this, arrData) => {
    let configData = JSON.parse(JSON.stringify(matrixReportInitialData));
    configData.reportName = "Matrix By Source";
    configData.column = [
        {
            "isVisible": true,
            "name": "value",
            "label": _this.localizedStrings.label.REPORT['Source'],//"Source",
            "isToatalField": false,
            // "isDetailsLink": true
        },
        {
            "isVisible": true,
            "name": "count",
            "label": _this.localizedStrings.label.REPORT['Count'],//"Count",
            "isToatalField": true,
            "isDetailsLink": true
        }
    ];
    configData.data = getArrayParam(arrData);
    configData.footer = [
        { "isVisible": false, "showTotal": false, "colspan": 1, "label": "", "isDetailsLink": true },
        { "isVisible": false, "showTotal": false, "colspan": 1, "label": "", "isDetailsLink": true }
    ];
    return configData;
}

const getMatrixByStatusProps = (_this, arrData) => {
    let configData = JSON.parse(JSON.stringify(matrixReportInitialData));
    configData.reportName = "Matrix By Status";
    configData.column = [
        {
            "isVisible": true,
            "name": "value",
            "label": _this.localizedStrings.label.REPORT['Status'],//"Status",
            "isToatalField": false,
            //  "isDetailsLink": true
        },
        {
            "isVisible": true,
            "name": "count",
            "label": _this.localizedStrings.label.REPORT['Count'],//"Count",
            "isToatalField": true,
            "isDetailsLink": true
        }
    ];
    configData.data = getArrayParam(arrData);;
    configData.footer = [
        { "isVisible": false, "showTotal": false, "colspan": 1, "label": "", "isDetailsLink": true },
        { "isVisible": false, "showTotal": false, "colspan": 1, "label": "", "isDetailsLink": true }
    ]
    return configData;
}

