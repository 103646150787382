 const label = {
    MODULE: {
        Sales: '販売',
        Marketing: 'マーケティング',
        Service: 'サービス',
        Operations: '操作',
        HUB: 'ハブ',
    },
    MODULE_ITEMS: {
        Reports: 'レポート',
        Dashboard: 'ダッシュボード',
        'Marketing Templates': 'マーケティング電子メール テンプレート',
        'Broadcast Campaigns': '放送キャンペーン',
        'Automation Designer': 'オートメーションデザイナー',
        'Email Marketing': 'メールマーケティング',
        'Lead Capture': 'リードキャプチャ',
        'Landing Pages': 'ランディングページ',
        'Web Forms': 'ウェブ フォーム',
        'Automated Campaigns': '自動化されたキャンペーン',
        'Social Marketing': 'ソーシャルマーケティング',
        'Website Tracking': 'ウェブサイトのトラッキング',
        'Website Analytics Setup': 'ウェブサイトのアナリティクスセットアップ',
        'Ab Campaigns': 'Abキャンペーン',
        'Opportunities': '機会',
        'Marketing Reports': 'レポート',
        'Touch Campaigns': 'タッチキャンペーン',
        'A/B Campaigns': 'A / Bキャンペーン',
        'Email Blast': '電子メールの送風',
        Activities: 'アクティビティ',
        Conversion: '変換',
        Campaigns: 'キャンペーン',
        Custom: 'カスタム',
        Sales: '売上高',
        Marketing: 'マーケティング',
        Offers: 'オファー',
        Coupons: 'クーポン',
        'Coupons Claim Report': 'レポート',
        ASSETS_DISTRIBUTION: '資産配分',
        USEFUL_LINKS: '便利なリンク',
        Royalties: 'ロイヤルティ - レガシー',
        COMPLIANCE_REPORT: "コンプライアンス",
    },
    COMMON: {
        PUSH_NO_CONFIRM: '確認せずにプッシュ',
        UNIT_CRM_UPDATE_STAGES:'アップデートステージ',
        UNIT_CRM_UPDATE_TASKS:'タスクを更新する',
        FRENCHISEE_COMPANY_NAME:'フランチャイジー会社名を選択してください',
        SELECT_AN_ACCOUNT:'ユニットを選択してください',
        ADD_TO_PLAN:'プランに追加',
        LOGIN_TO_BASE:'ベースにログイン',
        PLUS_BRANCH: '+支店',
        ADD: '追加',
        ADD_NEW_APPOINTMENT: '新規追加',
        SHOW: '表示',
        HIDE: '隠す',
        REFRESH: '更新',
        EDIT: '編集',
        SAVE: '保存',
        CANCEL: 'キャンセル',
        TEST: 'テスト',
        ENTER_TEXT: 'テキストを入力',
        APPLY: '適用',
        AND: 'そして',
        EMPTY: '空',
        EXPAND: '展開',
        DELETE: '削除',
        REQUIRED: ' (必要)',
        AUTO_SEARCH_TEXT: '同じテキスト、異なる値',
        NOTE: '注意',
        SEARCH: '検索',
        OK: 'わかりました',
        SEND_MAIL: '電子メールを送信',
        ASSIGN: '割り当てる',
        SELECT_A: 'を選択し',
        SELECT_MORE: '1つ以上選択する',
        CLOSE: '近い',
        CREATE: '作成',
        WITH: 'と',
        CONVERT: '変換',
        SEARCH_PLACEHOLDER: '入力して enter キーを押して検索します',
        COPY: 'コピー',
        MORE: 'もっとその',
        CLICK_TO_SEARCH: 'ここをクリックして検索',
        PREVIEW: 'プレビュー',
        BACK: '戻る',
        CONFIRM_REQUEST: '要求の確認',
        SELECT: '選択',
        ADVANCED: '高度',
        ADVANCED_SEARCH: '高度な検索',
        RESULTS_FOR: '結果を',
        SETTINGS: '設定',
        SIGN_OUT: 'サインアウト',
        BACK_TO_YOUR_ACCOUNT: 'アカウントに戻る',
        BACK_TO_LIST: 'リストに戻ります。',
        BACK_TO_COLUMN_VIEW: '列ビューに戻ります。',
        MERGE: 'マージ',
        DISPLAY_DIFFERENT_FIELDS: '* 異なるフィールドのみを表示します。',
        WORKFLOW: 'ワークフロー',
        STATUS: 'ステータス',
        RECORD: 'レコード',
        MERGED_RECORD: '結合レコード',
        EXPORT: 'エクスポート',
        CTR_DETAILS_FOR: 'クリック率の詳細',
        URL: 'URL',
        COUNT: 'カウント',
        CLICK_FOR_KANBAN_VIEW: 'かんばんビューについてはここをクリック',
        CLICK_FOR_LIST_VIEW: 'リストビューはここをクリック',
        INSERT: '挿入',
        CATEGORIES: 'カテゴリ',
        REMOVE: '削除',
        REPLY: '返信',
        REPLY_ALL: 'すべての返信',
        FORWARD: 'フォワード',
        WORKFLOW_NAME: 'ワークフロー名',
        CONVERTING: '変換',
        TO: '宛先',
        CURRENT_WORKFLOW: '現在のワークフロー',
        CHANGE_WORKFLOW: 'ワークフローの変更',
        UPDATE: '更新',
        STATUS_TO_OPTIONAL: 'ステータス (オプション)',
        MANAGE: '管理',
        ADD_PERSONAL_NOTE_OPTIONAL: 'あなたの要求に個人的なメモを加えてください (オプション)',
        CONVERTED: '変換',
        SELECT_USER: 'ユーザーの選択',

        // not found in doc

        COMING_SOON: '近日公開',
        IMPORT: 'インポート',
        NO: '違います',//いいえ',
        FOUND: '見つけた',
        NOTHING_FOUND: '「何も見つかりませんでした」',
        DOT: ' 。',
        UNDER_PROCESSING: '処理中、近日公開予定',
        FIND: '検索',
        LOADING: '読み込み中',
        NAME: '名前',
        CLEAR_ALL: 'すべてクリア',
        CLEAR: 'クリア',
        SHARE: 'シェア',
        ADD_TO_GROUP: 'グループに追加',
        MAP: '地図',
        AUDIT: 'ログを変更します',
        OPEN_AUDIT_FORM: '監査',
        TRAIL: 'トレイル',
        ADD_NOTES: 'メモを追加',
        TO_EXCEL: 'Excelに',
        PUSH: '押す',
        RESCHEDULE: '再スケジュール',
        PRINT: '印刷',
        COMPLETE: 'コンプリート',
        COMPLETE_New: '完全で新しい',
        SEND_EMAIL: 'メールを送る',
        SEND_SMS: 'SMSを送信',
        CONVERT_TO_ACCOUNTS: 'アカウントに変換',
        CONNECT: '接続する',
        DISCONNECT: '切断する',
        DOWNLOAD: 'ダウンロード',
        BATCH_EDIT: 'バッチ編集',
        BATCH_CONVERT: 'バッチ変換',
        INACTIVE: '非アクティブ',
        ACTIVE: 'アクティブ',
        RESTORE: 'リストア',
        PROFILE: 'プロフィール',
        TYPE_TO_SEARCH: '検索するにはここに入力してください',
        CONVERT_AND_ADD_OPP: 'コンバート＆オポチュニティの追加',
        SEND_VERIFICATION_EMAIL: '確認メールを送信',
        THESE: 'これら',
        ALL_APPOINTMENTS: 'すべてのアポイントメント',
        ALL_TASKS: 'タスク',
        CONTINUE: '持続する',
        EDIT_NOTES: 'ノートを編集する',
        RUN: '走る',
        INTEGRATION_TITLE: '統合',
        UNSUBSCRIBE: '退会する',
        MY_INTEGRATIONS: '統合',
        NOTIFY: '通知する ',
        HELP: '助けて',
        PUBLISH: '公開する',
        UNPUBLISH: '公開しない',
        CALL_SCRIPT: '呼び出しスクリプト',
        DOCUSIGN: 'DocuSignを送信',
        DOCUSIGN_INTEGRATION: 'DocuSignを送信',
        ALL: 'すべて',
        USER: 'ユーザー',
        SYSTEM: 'システム',
        MY: 'じぶんの',
        TODAYS: '今日の',
        YES: 'はい',
        DONE: '完了',
        OPEN: '開いた',
        FUTURE: '未来',
        OVERDUE: '延滞',
        TOMORROWS: "明日の",
        TOMORROW: "明日",
        TODAY: '本日',
        CONNECTED_AS: '接続先',
        SALESREP: '営業担当者',
        EVERYONE: 'みんな',
        NOTE_TYPE: 'メモの種類',
        TEXT: 'テキスト',
        FOR: 'ために',
        MODULE_SETTING: '設定',
        TEMPLATE: {
            LIBRARY_TEMPLATE: 'ライブラリテンプレート',
            USER_TEMPLATE: 'ユーザーテンプレート',
        },
        OF: 'の',
        'System Fields': 'システムフィールド',
        'Custom Fields': 'カスタムフィールド',
        'Additional Information': '追加情報',
        'Contact Details': '連絡先の詳細',
        'Phone and Email Information': '電話番号と電子メール情報',
        'Sales rep': '営業担当者',
        IMPORT_CSV_SOURCE_FIELD_HEADER: 'ソースをCSVファイルからマップします',
        ADD_NEW_SOURCE: '新しいソースを追加する',
        ADD_TO_THIS_LIST: 'このリストに追加',
        TYPE_SOMETHING: '何かを入力',
        'Opportunity Details': '機会の詳細',
        'Company Details': '会社概要',
        'Contact Information': '連絡先情報',
        'Case Information': 'ケース情報',
        'Solution Details': 'ソリューションの詳細',
        'Project Details': 'プロジェクトの詳細',
        'Issue Details': '問題の詳細',
        'Assigned to': 'に割り当てら',
        'Sales person': '営業担当者',
        EMAIL_TO: '電子メール',
        RECENT_SEARCHES: '最近の検索',
        RECENT_RECORDS: '最近のレコード',
        'Activities': 'アクティビティ',
        'Conversions': '変換',
        ASSETS: '資産',
        ENABLE_MAILING: 'メール送信を有効にする',
        DISABLE_MAILING: 'メール送信を無効にする',
        CLICK_TO_REMOVE: 'ここをクリックして削除',
        PIN: 'ピン',
        UNPIN: '固定解除',
        RENAME: '変更',
        DEACTIVATE: 'Deactivate',
        ACTIVATE: 'Activate',
        COUPONS: 'クーポン',
        ADD_FOLDER: 'フォルダの追加',
        ADD_FILE: 'ファイルを追加',
        SELECT_CONTACTS: '連絡先の選択',
        NOTIFICATION_REMINDERS: '通知とアラーム',
        PREFERENCES: '設定',
        LOCK_STAGES: 'ロックステージ',
        LOCK_TASKS: 'タスクのロック',
        SEND_BATCH_SMS: 'バッチテキストメッセージを送信',
        SEND_TEXT_MESSAGE: 'テキストメッセージを送信する',
        VERIFY_EMAIL: '電子メールの確認',
        SELECT_UNIT_NAME: 'ユニット名を選択',
        NEWS: 'ニュース',
        CLOSE_CASE: 'ケースを閉じる',
        CUSTOMIZE: 'カスタマイズ',
        VIEW_MODE: 'ビューモード',
        LIST: 'リスト',
        COLUMN: '行',
        SETUP_SCHEDULING_PAGES: 'スケジュール ページの設定',
        SAVE_RUN: '保存して実行する',
        BATCH_CONVERT_TO_ACCOUNTS: 'バッチのアカウントへの変換',
        NEXT: 'Next',
        ADD_QUICKBOOKS: 'Add to Quickbooks',
        ITEM_FROM_QB: 'FROM Quickbooks',
        PUSH_TO_QB: 'Add to Quickbooks',
        ADD_NOTE: 'メモを追加し',
        ADD_NOTE_CONVERT: 'メモを追加して変換',
        OBJECT_NAME: 'オブジェクト名',
        GREENLIGHT_EXPORT_DOWNLOAD: 'プロセスとダウンロード',
        PREVIOUS: '先の',
        VIEW: 'ビュー',
        MENU: 'メニュー',
        NO_DUPLICATE: '重複なし',
        ONGOING: '進行中',
        ONGOING_ONBOARDING: '継続的なオンボーディング',
        SYNC_NOW: 'Sync Now',
        'Generate Invoice': '請求書を生成する',
        'Add Payment': '支払いを追加する',
        'Print' : '印字',
        MOBILE_MENU: 'モバイルメニュー',
        PORTAL_MENU: 'ポータルメニュー',
        TO_QUICKBOOKS:'クイックブックへ',
        CONVERT_INVOICE: '請求書に変換',
        RECEIVE : '受け取る',
        ACCEPT : '受け入れる',
        CONFIRM: '確認',
        FULFILL: '満たす',
        SHIP: '輸送する',
        ADD_JOBS : 'ジョブを追加する',
        ROYALTY_SETUP: 'ロイヤリティの設定',
        SAVE_AND_SCHEDULE:'保存してスケジュールする',
        UNLINK: 'リンク解除',
        TO_XMS: 'XMSへ',
        SEQUENTIAL:"一連の",
        STAR: '星',
        PIN :'ピン',
        CONVERT: '変換する',
        SHARE_WITH : 'と共有する',
        FRANCHISOR: 'フランチャイザー',
        FRANCHISE: 'フランチャイズ',
        ALL_PHONE_NO: 'すべての電話番号',
        BRING_YOUR_OWN:'BYO (Bring Your Own) 番号',
        LEASE_NUMBER:'リース番号',
        ADD_NUMBERS:'数字を追加',
        ADD_CREDITS:'クレジットを追加',
        CALL_MINUTES:'通話議事録と',
        TEXT_CREDIT:'テキストクレジットが残っています',
        LEASE_A_NO:'番号をリースする',
        TOLL_FREE:'無料通話',
        LOCAL:'地元',
        COUNTRY:'国',
        SURVEY_SETUP : '調査のセットアップ',
        THANKS_SURVEY:'いいねアンケート - ありがとうフォーム',
        REVIEW_WEBSITE_REQUIRED:'ウェブサイトの URL を確認してください (必須)',
        THUMBS_UP_TITLE:'いいねのタイトル',
        THUMBS_UP_DESC:'賛成の説明',
        YES_BUTTON:'はいボタン',
        NO_BUTTON:'ボタンなし',
        DAYS: '日々）',
        LOCATION_ALERT:'場所はすでに存在します!',
        TASK_NAME:'タスク名',
        DESC:'説明',
        UNIT_CRM: 'ユニットCRMセットアップ',
        UNIT_CRM_LABEL: 'ユニット CRM 管理者は次のことができます。',
        UNIT_CRM_CUSTOMIZE_FORMS: 'フォームをカスタマイズする',
        UNIT_CRM_CUSTOMIZE_WORKFLOW: 'ワークフローをカスタマイズする',
        ACTION: '行動：',
        INVOICE_ALL : 'すべての請求書',
        EMAIL_ALL:'すべてにメールを送信',
        POST_ALL: 'すべて投稿する',
        REPORT_FIELDS: 'レポートフィールド:',
        COMPLIANCE_NOTIFICATION:'コンプライアンス通知',
        COMPLIANCE_LABEL:'フランチャイジーがコンプライアンス違反をした場合に FR に通知する電子メール アドレスを入力してください',
        ONBOARDING_NOTIFICATION:'オンボーディング通知',
        EMAIL:'Eメール',
        HELPDESK_NOTIFICATION:'ヘルプデスク通知',
        LMS_SETUP:'LMS のセットアップ',
        LMS_LABEL:'LMS アカウント (必須)',
        PLUS_BASE: '+ベース',
        UNITBASE:'ユニットベース'
    },
    NOTE:{
        ALL_NOTES:'すべてのメモ',
        ALL_TYPES:'いろんなタイプ',        
    },
    HOME: {
        MY_FILTERS: 'マイフィルタ',
        GROUPS: 'グループ',
        ADD_NEW: '新規追加',
        RECENT: '最新',
        HOME: 'ホーム',
        CLICK_TO_REFRESH: '更新',
        RECENTLY_VIEWED: '最近の閲覧',
        FILTERS: 'フィルタ',
        ADD_GROUP: 'グループの追加',

        // not found in doc

        NOTIFICATIONS: '通知',
        WHATS_NEW: '新着情報',
        COLLAPSE: '崩壊',
        EXPAND: '展開する',
        ADD_FILTER: 'フィルターを追加',
        CLICK_TO_FIND_GROUP: 'グループを見つけるにはここをクリック',
        CLICK_TO_FILTER_GROUP: 'あなたのグループをフィルタリングする',
        CLICK_TO_FIND_FILTER: 'フィルターを見つけるにはここをクリック',
        FORECASTS: '予測',
        ADD_FIELD: 'フィルタにフィールドを追加するには、ここをクリックしてください',
        FITER_SETUP: 'フィルタ設定',
        ENTER_BY_HAND: '手で入力',
        IMPORT: 'インポート',
        VIEW: 'ビュー',
        DELETE: '削除',
        CLICK_TO_FIND_REPORT: 'レポートを検索するには、ここをクリックしてください',
    },
    CAMPAIGNS: {
        NAME_REQUIRED: 'キャンペーン名（必須）',
        GROUP: 'グループ',
        CONTACT_FILTER: 'コンタクトフィルタ',
        ACCOUNT_FILTER: 'アカウント フィルター',
        FROM_NAME_REQUIRED: '名前から (必須)',
        VIEW_AS_WEB_PAGE: 'web ページとして表示する',
        SUBJECT_REQUIRED: '件名 (必須)',
        DAYS: '日 (秒)',
        EXECUTE_NOW: '今すぐ実行',
        TEST: 'テスト',
        SCHEDULED_FOR: 'に予定',
        EXECUTED_ON: 'で実行',
        SCHEDULE: 'スケジュール',
        CLICK_URL: 'URL をクリックします。',
        SELECT_GROUP: '最大3つのグループを選択',
        SELECT_CONTACT_FILTER: '連絡先からフィルタを選択する',
        SELECT_ACCOUNT_FILTER: 'アカウントからフィルターを選択します。',
        CHANGE_TEMPLATE: 'テンプレートの変更',
        WITHOUT_AB_TEST: 'a/b テストなし',
        WITH_AB_TEST: 'a/b 検定',
        WITH_AUTOMATION: '自動キャンペーン',
        WITHOUT_AUTOMATION: 'タッチキャンペーン（レガシー）',
        SEND_ON_WEEK_DAY: '週末（土、日）は送信しないでください',
        RESEND_AFTER: '再送後',
        RESEND_ALL: 'すべて再送',
        FROM_EMAIL_REQUIRED: 'メールから (必須)',
        CAMPAIGN_TYPE_OPTIONAL: 'キャンペーンタイプ（オプション）',
        CAMPAIGN_TYPE: 'キャンペーンタイプ',
        START_DATE_REQUIRED: '開始日 (必須)',
        SENT: '送信',
        OPENED: '開く',
        UNOPENED: '開封',
        CLICKED: 'クリック',
        BOUNCED: 'バウンス',
        UNSUBSCRIBED: '解除',
        SPAM: 'スパム',
        DROPPED: '落とし',
        TYPE_HERE: 'ここに入力します。',
        ADD_SIGNATURE: '署名の追加',
        INSERT_IMAGE: 'イメージの挿入',
        PERSONALIZE: 'パーソナライズ',


        // not found in doc

        EXECUTING: '実行中',
        ABORTED: '中断された',
        TEMPLATE_DIALOG_TITLE: 'テンプレートを選択',

        // Campaign Status
        'All': 'すべて',
        'Active': 'アクティブ',
        'Running': 'ランニング',
        'Stopped': '停止',

        'New': '新しい',
        'Queued': '待機中',
        'Executed': '実行された',
        OPEN: '開いた',
        BOUNCE: 'バウンス',
        SPAMREPORT: 'スパムレポート',
        'Halted': '停止した',
        'Auto Response': '自動応答',
        'Marketing Template': 'マーケティングテンプレート',
        'Newsletter': 'ニュースレター',
        'Greetings': 'ご挨拶',
        'Inactive': '非アクティブ',
        'Executed - No Winner': '実行 - 勝者なし',
        'Sent to Winner': '勝者に送った',
        'Sending to Winner': '勝者に送信',
        ADD_CAMPAIGN_TYPE: '新しいキャンペーンタイプを追加する',
        CAMPAIGN_NAME: 'キャンペーン名',
        SUBJECT: '件名',
        MANAGE: '管理',
        FROM_EMAIL: 'メールから',
        SELECT_A_FILTER:'からフィルターを選択します ',
        BETWEEN: '間',
        WEEKDAYS_ONLY: '平日のみ'
    },
    BROADCAST_CAMPAIGNS: {
        PERSONALIZE_SUBJECT: '件名をパーソナライズ',
        FROM_EMAIL_REQUIRED: 'メールから (必須)',
        EMAIL_CAMPAIGN_REPORT: 'メールキャンペーンレポート',
        SCHEDULE_BROADCAST: 'ブロードキャストのスケジュール',
        RESCHEDULE_BROADCAST: 'ブロードキャストの再スケジュール',
        EXECUTE_BROADCAST: 'ブロードキャストの実行',
        ADD_GROUP: 'グループの追加',
        CATEGORY: 'カテゴリ',
        TEMPLATE: 'テンプレート',
        PERFORM_AB_TESTING: 'a/B テストの実行',

        // not found in doc

        VERIFY_EMAIL: 'あなたのメールアドレスを確認してください',
        VERIFY_EMAIL_ADDRESS: 'あなたのメールアドレスをここに入力してください',
        VERIFY_EMAIL_ADDRESS_OPTION: '別のメールアドレスを確認する',
        BROADCAST_CAMPAIGN_EDIT_TAB_LABEL: 'ブロードキャストキャンペーンの編集',
        BATCH_EDIT: 'バッチ編集',
        BROADCAST_CAMPAIGN_COPY_TAB_LABEL: 'ブロードキャストキャンペーンのコピー',
        BROADCAST_CAMPAIGN_CREATE_TAB_LABEL: 'ブロードキャストキャンペーンの作成',
        All_BROADCASTS: 'すべての放送',
        BROADCASTS: '放送',
        ADD_SIGNATURE: '署名を追加する',
        BROADCAST_INFO: '放送情報',
        DESIGN: '設計',
        DRAG_AND_DROP: 'ドラッグアンドドロップ',
        LEGACY: 'レガシー',
        EMAIL_BUILDER: '電子メールビルダ',
        QUALIFIED_CONTACTS: '受信者リストを表示する',
    },
    AB_CAMPAIGNS: {
        TARGET_BY: 'ターゲットを',
        AB_TEST_DISTRIBUTION: 'a/B テスト配布',
        MIN_MARGIN_PERCENT: '最小勝利マージン%',
        A_PERCENT: 'A%',//'、%',
        B_PERCENT: 'B%',//'b%',
        AB_CAMPAIGN_REPORT: 'a/B キャンペーンレポート',
        //not found in doc
        AB_SEND_REST: '勝者を使ってレストを送る',
        AB_SELECT_WINNER_BY: '勝者を選ぶ',
        VERIFY_EMAIL_ADDRESS_OPTION: '別のメールアドレスを確認する',
        EMAIL_VIEWS: 'メールビュー',
        EMAIL_CLICKS: '電子メールのクリック',
        AB_CAMPAIGN_EDIT_TAB_LABEL: 'A/B キャンペーンの編集',
        BATCH_EDIT: 'バッチ編集',
        AB_CAMPAIGN_COPY_TAB_LABEL: 'A/B キャンペーンのコピー',
        AB_CAMPAIGN_CREATE_TAB_LABEL: 'A/B キャンペーンの作成',
        ALL_AB_CAMPAIGNS: 'すべてのABキャンペーン',
        AB_TESTS: 'A/B テスト',
        MESSAGE_A: 'メッセージA',
        MESSAGE_B: 'メッセージB',
        AFTER: '後',
        SCHEDULE_AB_CAMPAIGN: 'スケジュールA / Bキャンペーン',
        WINNER_BASEDON_A: '受賞者（メッセージAに基づいて）',
        WINNER_BASEDON_B: '受賞者（メッセージBに基づく）'
    },
    CALENDAR: {
        CALENDAR_TASKS: 'カレンダー/タスク',
        CALENDAR_ONLY: 'カレンダー',
        CALENDARS: 'カレンダーの',
        SHOW_OPTION_1: 'キャンセル',
        SHOW_OPTION_2: '完成',
        SHOW_OPTION_3: '予定',


        // not found in doc

        TODAY: '今日',
        BACK: '戻る',
        NEXT: '次',
        DAY: '日',
        WEEK: '週間',
        MONTH: '月',
        AGENDA: '議題',
        DATE: '日付',
        TIME: '時間',
        EVENT: 'イベント',
        ALL_DAY: '一日中',
        WORK_WEEK: '仕事の週',
        YESTERDAY: '昨日',
        TOMMOROW: '明日',
        SHOW_MORE: '',
        TYPE: 'タイプ',
        MY_CALENDAR_SETTING: 'マイカレンダー設定',
        DISPLAY:'表示',
        ACCOUNT_NAME:'アカウント名',
        JOB_NAME : '職種名',
        COLOR_CODE: 'カラーコード',
        LOGO_COLOR :'ロゴの背景',

    },
    GROUP: {
        GROUP_NAME: 'グループ名',
        DELETE_RECORD: 'レコードの削除',
        USED_IN: 'で使用される',
        REMOVE_FROM: 'から削除',
        SELECT_RECORDS: '選択したレコード',
        ALL_RECORDS: 'すべてのレコード',
        SELECT_OPTIONS: 'オプションの選択',
        GROUP_FILTER: 'グループフィルタを選択',
        ALL: 'すべて',
        ACTIVE: 'アクティブ',
        INACTIVE: '非アクティブ',
        DETAILS: '詳細',
        'Active': 'アクティブ',
        'Inactive': '非アクティブ',
        'New': '新しい',
        'scheduled': 'スケジュールされた',
        'Queued': '待機中',
        'Executed': '実行された',
        'Running': 'ランニング',
        'Halted': '停止した',
    },
    APPOINTMENTS_TASKS: {
        MY_TASKS: 'タスク',
        PERSONALIZE_OPTION_1: '延滞',
        PERSONALIZE_OPTION_2: '本日は',
        PERSONALIZE_OPTION_3: '将来',
        PERSONALIZE_OPTION_4: '明日',
        PERSONALIZE_SORT_1: '日付',
        PERSONALIZE_SORT_2: '優先度',
        TASKS_FOR: 'のタスク',
        SORT_BY: '並び替え',
        COMPLETE_THIS_APPOINTMENT: 'この予定を完了する',
        COMPLETE_THIS_TASK: 'このタスクを完了する',
        NEXT_DAY: '翌日',
        NEXT_WEEK: '来週',
        COMPLETE: '完了',
        DELETE: '削除',
        TASKS: 'タスク',
        APPOINTMENTS: '予定',
        ADD_NOTE: 'メモを追加する (省略可能):',
        NEXT_MONTH: '来月',
        DAYS: '日',
        SPECIFIC_DATE: '特定の日付',
        RESCHEDULE: 'スケジュール',
        RESCHEDULE_FOR: '再スケジュール:',
        REPEAT: '繰り返し',
        DAY_OF_THE_MONTH: '月の日',
        DAY_OF_THE_WEEK: '曜日',
        REPEAT_TYPE: 'リピートタイプ',
        REPEAT_ON: 'を繰り返し',
        REPEAT_BY: 'で繰り返し',
        START_DATE: '開始日',
        END_DATE: '終了日',
        REPEAT_EVERY: 'すべて繰り返す',
        REMOVE_REMINDER: 'リマインダーの削除',
        ONLY_THIS: 'これだけ',
        FOLLOWING: '次',
        ALL: 'すべての',
        THIS:'この',
        THIS_AND_FOLLOWING:'これ以降',
        ONLY_THIS_RECORD: 'このレコードのみ',
        FOLLOWING_RECORDS: '次のレコード',
        ALL_RECORDS: 'すべてのレコード',
        ADD_A_NOTE: 'メモを追加する',
        COMPLETE_TASK_OPTIONAL: '保留中のタスクを完了する',
        COMPLETE_TASK_OPTIONAL_OBJ: '保留中のタスク/メール/テキストメッセージを完了する',
        CREATE_NEXT_TASKS: '次のタスクを作成する',
        CREATE_NEXT_TASKS_OBJ: '次のタスク/メール/テキストメッセージを作成する',
        PENDING_TASKS: '保留中のタスク',
        SEARCH_BY_NAME: '名前で検索',

        // not found in doc

        INVITE_GUEST: 'ゲストを招待する',
        ATTENDEES: '出席者',
        SALES_REP: '営業担当者',
        ADD_REMINDER: 'リマインダを追加する',

        'Email': 'Eメール',
        'Pop-up': '現れる',
        'minutes': '分',
        'hours': '時間',
        'days': '日々',
        'weeks': '週',
        DAILY: '毎日',
        WEEKLY: '毎週',
        MONTHLY: '毎月',
        YEARLY: '毎年',
        DAYS_S: '日々',
        WEEKS: '週',
        MONTHS: '数ヶ月',
        YEARS: '年',
        APPOINTMENT: '任命',
        TASK: '仕事',
        SUBJECT: '件名',
        ASSIGN_TO: '割りあてる',
        COMPANY: '会社',
        CONTACT_NAME: '連絡先',
        OUTLOOK_SYNC: 'Outlookの同期',
        GOOGLE_SYNC: 'Google 同期',
        'Scheduled': '予定',
        'Completed': '完了しました',
        'Cancelled': 'キャンセル',
        'Pending': '保留中',
        TASK_NAME: 'タスク名',
        DUE_BY: '支払期日',
        DESCRIPTION: 'DESCRIPTION',
        OWNER: 'オーナー',
        FOLLOWUP: 'フォロー アップ',
        'All': 'すべての',
        'Empty': '空の',
        'No': 'いいえ',
        'Yes': 'はい',
        'Everyone': 'みんな',
        'Just me': '私だけ',
        ALL_TASK_COMPLETED: 'これらの作業はすでに完了しています。',
        'duration': '期間',
        'days_after': '数日後',
        DEP_TASK_NAME: 'タスク名',
        TASK_COMPLETED: 'タスク完了（％）',
        APPOINTMENTS_AND_TASKS: '予定とタスク',
        APPOINTMENTS_ONLY: '予定のみ',
        TASKS_ONLY: 'タスクのみ',
        EDIT: '編集',
        SENDNOW: '今すぐ送信',
        OPPORTUNITY_NAME: '商談名',
        SELECT_USER: 'ユーザーを選択',
        SELECT_STATUS: 'ステータスを選択',
        VISITS: '訪問',
        UNIT_OWNER_TASKS: 'ユニットのタスク',
        COMPLETE_UNIT_TASK_OPTIONAL: 'ユニット所有者の保留中のタスクを完了するs',
        UNIT_TASKS: 'ユニットのタスク',
        DOCUMENTS: 'ドキュメント',
        LINKS: 'リンク',
        TASK_SETTINGS: 'タスクの設定',
        APPOINTMENT_SETTINGS: '予定の設定',
    },
    ATTACHMENT: {
        UPLOAD: 'アップロード',
        MY_COMPUTER: 'マイコンピュータ',
        BROWSE: '参照',
        DOWNLOAD: 'ファイルのダウンロード',
        ATTACH_FILE: 'ファイルを添付する',
        UPLOADING: 'アップロード',
        NO_IMAGE_FOUND: '画像が見つかりません',
        DOWNLOAD_RECORDING: 'レコーディングのダウンロード',
        ATTACHMENT: '添付',
        ADD_AN_ATTACHMENT: '添付ファイルを追加する'

    },
    LIST_VIEW: {
        CLICK_TO_COLLAPSE: 'ここをクリックして折りたたむ',
        CLICK_TO_EXPAND: 'ここをクリックして展開',
        SHOW_TOTAL: '合計を表示',
        SHOW_PICTURE: '画像を表示する',
        DESCENDING: '降順',
        SORT_BY: '並び替え',
        SELECT_COLUMN_FOR_VIEW: 'このビューのフィールドを選択してください',
        ADD_AND_REORDER: '追加と並べ替え',
        FIELDS: 'フィールド',
        CLICK_TO_ADD_MORE_FIELDS: '(フィールドを追加するには、このボックスをクリックします。フィールドの位置を変更するには、ドラッグして並べ替えます。',
        SELECT_FIELDS: 'フィールドの選択',
        MODIFY_FIELD: 'フィールドの変更',
        EXPORT_SELECTED_RECORDS: '選択したレコードのエクスポート',
        EXPORT_ALL_RECORDS: 'すべてのレコードのエクスポート',

        // not found in doc

        SELECT_COLUMN_FOR_FILTER_VIEW: 'フィルタ結果の列を選択',
        LINK_EXISTING: '既存のリンク',
        SET_UP: 'セットアップ',
        GROUP_BY: 'グループ化する',
        FIND_BY_FIELD: '検索',
        FILTER_TYPE: {
            DEFAULT_FILTERS: '既定のフィルタ',
            SHARED_FILTERS: '共有フィルタ'
        },
        COLUMN_VIEW_SETUP_INFO: 'この列ビューには、最初の5つのフィールドのみが表示されます。リストビューにはすべてのフィールドが表示されます',
    },
    DETAIL_VIEW: {
        SHOW_LABELS: 'ラベルの表示',
        SELECT_OPTIONS: 'オプションの選択',
        CLICK_TO_ADD_MORE_FIELDS: 'フィールドを追加するには、このボックスをクリックします。フィールドの順序を変更するには、ドラッグして並べ替えます。最大50のフィールドを選択できます。',
        SETUP_FIELDS: '設定フィールド',
        ADD_OR_REMOVE_FROM_LIST: 'このリストのフィールドの追加/削除',
        CLICK_TO_KNOW_MORE: 'をクリックして詳細を知る',
        CLICK_TO_ADD_CHILD_OBJECTS: 'このボックスをクリックして、子オブジェクトを追加します。オブジェクトをドラッグアンドオーダーして、位置を変更します。',
        SETUP_CHILD_TABS: '子タブのセットアップ',

        // not found in doc

        SHOW_LABELS_AGAINST_FIELD: 'フィールドに対してラベルを表示するには、これを選択します。',
        CUSTOMIZE_CHILD: '子をカスタマイズする',
        REFUND_CHILD_LABEL: '返金',
        GROUPS: 'グループ',
        MANAGE: '管理',
        ADD_TO_GROUP: 'グループに追加',
        DATA_TIP_1: 'これにより、 ',
        DATA_TIP_2: 'これにより、タスク/メール/テキストメッセージが作成されます。 ',
        DATA_TIP_3: ' ステージ',

        HIGHLIGHT_LABEL_OPPORTUNITY: '機会$',
        HIGHLIGHT_LABEL_FORECAST: '予測$',
        HIGHLIGHT_LABEL_CLOSING_ON: '締めくくり',
        HIGHLIGHT_LABEL_OPPORTUNITY_AGE: '機会の年齢',
        HIGHLIGHT_LABEL_DAYS: '日々',

        CHILD_SETUP_SELECT_CHILD_LABEL: 'ここをクリックして、子オブジェクトをセットアップに追加します。',
        NO_SETUP_OPTION: 'なし'
    },
    RELATIONSHIP: {
        DEFINE_RELATIONSHIP: 'リレーションシップの定義',
        RELATE: '関連',
        COMPANY: '会社',
        FULL_NAME: '氏名',
        EMAIL: '電子メール'
    },
    AUTOMATION_DESIGNER: {
        TOUCH_CAMPAIGN_REPORT: 'タッチキャンペーンレポート',
        INTERVAL: '後',
        NOTE_TYPE: 'メモタイプ',
        TEXT_REQUIRED: '注意 テキスト (必須)',
        TASK_NAME_REQUIRED: 'タスク名 (必須)',
        OWNER_REQUIRED: '所有者 (必須)',
        DUE_BY_REQUIRED: '期限 (必須)',
        DAY_AFTER_REQUIRED: '日後 (必須)',
        EMAIL: '電子メール',
        POP_UP: 'ポップアップ',
        MINUTES: '分',
        HOURS: '時間',
        DAYS: '日',
        WEEKS: '週間',
        REMINDER: 'アラーム',
        GROUP_REQUIRED: 'グループの選択 (必須)',
        ADD_TO_GROUP: 'グループに追加',
        MOVE_TO_GROUP: 'グループに移動',
        REMOVE_FROM_GROUP: 'グループから削除',
        STATUS_REQUIRED: 'ステータス (必須)',
        WORKFLOW_REQUIRED: 'ワークフロー (必須)',
        CHOOSE_ANOTHER_FIELD: '別のフィールドを選択する',
        CHOOSE: '選択',
        FIELD_FOR: 'のフィールド',
        CAMPAIGN_NAME: 'キャンペーン名（必須）',
        START: '開始',
        STOP: '停止',
        REPORT: '報告する',
        RESUME: '再開',
        SELECT_PAGE: 'ページを選択する',
        ENTER_WEB_PAGE_LINK_OPTIONAL: 'リンクのURLを入力します（オプション）。',
        ENTER_WEB_PAGE_LINK: 'web ページのリンクを入力します',
        CLICK_TO_VIEW: 'ここをクリックして表示する',
        CLICK_TO_EDIT: 'ここをクリックして編集する',
        DRAG_TO_MOVE: 'ドラッグして移動',
        DRAG_TO_CONNECT: 'ドラッグして接続する',
        CLICK_TO_REMOVE: 'ここをクリックして削除',
        CLICK_TO_SEE_REPORT: 'レポートを見るにはここをクリック',
        CLICK_TO_ADD_MSG: 'クリックして新しいメッセージを追加します',
        NO: '違います',
        YES: 'うん',
        CLICK_TO_SELECT_USERS: 'ユーザーを選択するにはここをクリック',
        SALES_REP: '営業担当者',
        USERS: 'ユーザー',
        CAMPAIGN_SENDER: 'キャンペーンの送信者',
        SCORE: 'クチコミ',
        START_WITH: '追加',//'から始め',
        DECISIONS: '決定',
        ACTIONS: 'アクション',
        LINK_CLICKED: 'リンクをクリック?',
        SCORE_REACHED: 'スコアに達した?',
        TOUCH_CAMPAIGN: 'タッチキャンペーン',
        WEB_FORM: 'ウェブフォーム',
        LANDING_PAGE: 'ランディングページ',
        WEBSITE_VISITED: 'ウェブサイト訪問',
        CHANGE_GROUP: 'グループの変更',
        ADD_NOTE: 'メモの追加',
        NOTIFY: '通知',
        ADD_TASK: 'タスクの追加',
        FIELD_UPDATED: 'フィールドを更新する',
        CHOOSE_TEMPLATE: 'テンプレートを選択',
        USE_THIS_TEMPLATE: 'このテンプレートを使用する',
        WAIT: '待つ',
        NAME: "名前",
        TARGET_GROUPS: '対象グループ',
        TARGET_FILTER: 'ターゲットフィルタ',
        RESEND_AFTER: '再送後',
        START_DATE: '開始日',
        SUBJECT: '件名',
        TEXT: '本文',
        TASK_NAME: 'タスク名',
        ASSIGN_TO: 'に割り当て',
        FIELD_LABEL: 'フィールドラベル',
        FIELD_VALUE: 'フィールド値',
        LINK: 'リンク',
        SEND_ON_ANY_TIME: '送信: いつでも',
        SEND_ON_BUSINESS_DAY: '送信日: 営業日のみ',
        CONVERT_TO: 'に変換',
        QUALIFIER: '修飾子',
        OBJECT: 'オブジェクト',
        NOTIFY_SENDER: '送信者への通知',
        NOTIFY_SALES_REP: '営業担当者への通知',
        NOTIFY_SENDER_SALESREP: '送信者への通知、営業担当者への通知',
        NOTIFY_USERS: 'ユーザーへの通知',

        // not found in doc

        TEST: 'テスト',
        VIEW_AS_WEBPAGE_INFO: 'このオプションは、電子メールをWebページとして開くために電子メールの上部にリンクを追加します',
        LINK_CLICK_INFORMATION: 'このフィールドを空白のままにすると、電子メール内のすべてのリンクが追跡されます。',
        GROUP_NAME: '新しいグループ名',
        SELECT_USER: 'に割り当てます',
        REMIND_BEFORE: '前に思い出させる',
        INTERVAL_REQUIRED: '間隔（必須）',
        VERIFY_EMAIL_ADDRESS_OPTION: '別のメールアドレスを確認する',
        'Touch Campaign': 'タッチキャンペーン',
        'Link Clicked': 'リンククリック数',
        'Link Clicked?': 'リンククリック数?',
        'Email Opened': '電子メールが開かれました',
        'Email Opened?': '電子メールが開かれました',
        'Unsubscribed': '登録されていない',
        'Score Reached': 'スコアに達しました',
        'Send One-off Email': '一度限りの電子メールを送信する',
        'Send Email': 'メールを送る',
        'Change Group': 'グループの変更',
        'Add Note': 'メモを追加',
        'Notify': '通知する',
        'Add Task': 'タスクを追加',
        'Field Updated': '更新されたフィールド',
        'Assign': '割り当てます',
        'Converted': '変換された',
        'Start': '開始',
        'Add Touch Message': 'タッチメッセージを追加',
        'Web Form': 'Webフォーム',
        'Website Visit': 'ウェブサイト訪問',
        'Landing Page': 'ランディングページ',
        'Wait': '待つ',
        WAIT_FOR_DAYS: '日々）',
        'Simple email nurturing': 'シンプルなメール育成',
        'Capture leads using a web form and nurture': 'ウェブフォームを使用してリードを獲得し、育てる',
        'Track web page visit and nurture': 'ウェブページの訪問と育成を追跡する',
        'Capture leads from a landing page and nurture': 'ランディングページからリードを獲得して育てる',
        'Start with touch campaign': 'タッチキャンペーンから始める',
        'Start with web form': 'ウェブフォームから始める',
        'Start with tracking website visit': 'ウェブサイトの訪問を追跡することから始める',
        'Start with landing page': 'リンク先ページから始める',
        COPY_OF: 'のコピー',
        AUTOMATION_CAMPAIGN_CREATE_TAB_LABEL: '新しい自動キャンペーン',
        TARGET_BY: 'ターゲット',
        'Hours': '時間',
        'Hour(s)': '時間（s）',
        'day(s)': '日(s)',
        'hour(s)': '時間(上)',
        ALL_AUTOMATED_CAMPAIGNS: 'すべての自動キャンペーン',
        MESSAGE: 'メッセージ',
        SELECT_A_CAMPAIGN: 'キャンペーンを選択してください',
        ADD_SIGNATURE: '署名を追加する',
        BIRTHDAY_CAMPAIGN: '誕生日キャンペーン',
        'Add Birthday Message': '誕生日メッセージの追加',
        'Start with Birthday Campaign': '誕生日キャンペーンから始める',
        'SMS Replied': 'SMS返信済み',
        'Send SMS': 'SMSを送信',

        'Touch Campaign Automation': 'タッチキャンペーンの自動化',
        'Birthday Campaign Automation': '誕生日キャンペーンの自動化',
        'Web Form Automation': 'Webフォーム自動化',
        'Landing Page Automation': 'ランディングページの自動化',

        SEND_SMS: 'SMSを送信',
        SMS_REPLY: 'SMSが返信しましたか？',
        BIRTHDAY_CAMPAIGN_REPORT: '誕生日キャンペーンレポート',
        START_IMMEDIATELY: 'すぐに',
        START_SCHEDULE: 'スケジュール',
        SCHEDULE_ON_TEXT: '開始予定',
        SCHEDULE_DIALOG_TITLE: 'スケジュールの日付と時刻を選択してください',
        FROM_NUMBER: '番号から',
        REPLY: '応答',
        NOT_CONVERTED: '変換されません'
    },
    TOUCH_CAMPAIGNS: {
        ALL_TOUCH_CAMPAIGNS: 'すべてのタッチキャンペーン',
    },
    MARKETING_TEMPLATE: {
        PERSONALIZE_SUBJECT: '件名をパーソナライズ',
        NAME_REQUIRED: '名前 (必須)',
        STATUS: 'ステータス',
        SUBJECT_REQUIRED: '件名 (必須)',
        ACTIVE: 'アクティブ',
        INACTIVE: '非アクティブ',
        TEST: 'テスト',
        SELECT_TEMPLATE: '入力を開始してテンプレートを選択してください',
        TYPE_HERE: 'ここに入力します。',
        ADD_SIGNATURE: '署名の追加',
        INSERT_IMAGE: 'イメージの挿入',
        PERSONALIZE: 'パーソナライズ',
        SELECT_FROM_LIBRARY: 'ライブラリから選択',
        USE_THIS_DESIGN: 'このデザインを使用する',
        DEAR: '親愛',

        // not found in doc

        EXPAND_EDITOR: '全画面表示',
        SHARE: 'マーケティングと販売の両方のメールに使用',
        MARKETING_TEMPLATE_CREATE_TAB_LABEL: 'マーケティング電子メール テンプレートの作成',
        COPY_OF: 'コピー_の',
        BATCH_EDIT: 'バッチ編集',
        MARKETING_TEMPLATE_EDIT_TAB_LABEL: 'マーケティング電子メール テンプレートの編集',
        MARKETING_TEMPLATE_COPY_TAB_LABEL: 'マーケティング電子メール テンプレートのコピー',
        CATEGORY: 'カテゴリー',
        LAYOUT: 'レイアウト',
        'Auto Response': '自動応答',
        'Marketing Template': 'マーケティングテンプレート',
        'Newsletter': 'ニュースレター',
        'Greetings': 'ご挨拶',
        CHOOSE_EMAIL_BUILDER: '電子メールビルダーの選択',
        DRAG_AND_DROP_BUILDER: '新しいドラッグアンドドロップビルダを使用する',
        LEGACY_BUILDER: 'レガシービルダを使用する',
        TEMPLATE_INFO: 'テンプレート情報',
        DESIGN: 'デザイン',
    },
    SALES_TEMPLATE: {
        PERSONALIZE_SUBJECT: '件名をパーソナライズ',
        NAME_REQUIRED: '名前 (必須)',
        STATUS: 'ステータス',
        SUBJECT_REQUIRED: '件名 (必須)',
        ACTIVE: 'アクティブ',
        INACTIVE: '非アクティブ',
        TEST: 'テスト',
        TYPE_HERE: 'ここに入力します。',
        ADD_SIGNATURE: '署名の追加',
        INSERT_IMAGE: 'イメージの挿入',
        PERSONALIZE: 'パーソナライズ',
        DEAR: '親愛',
        SALES_TEMPLATE_CREATE_TAB_LABEL: '営業用メール テンプレートの作成',
        SALES_TEMPLATE_EDIT_TAB_LABEL: '販売用メール テンプレートの編集',
        SALES_TEMPLATE_COPY_TAB_LABEL: '販売用メール テンプレートのコピー',
        COPY_OF: 'コピー_の',
        SALES_TEMPLATES: '営業用メール テンプレート',
    },
    AGREEMENT_TEMPLATE: {
        PERSONALIZE_SUBJECT: '件名をパーソナライズ',
        NAME_REQUIRED: '名前 (必須)',
        STATUS: 'ステータス',
        SUBJECT_REQUIRED: '件名 (必須)',
        ACTIVE: 'アクティブ',
        INACTIVE: '非アクティブ',
        TEST: 'テスト',
        TYPE_HERE: 'ここに入力します。',
        ADD_SIGNATURE: '署名の追加',
        INSERT_IMAGE: 'イメージの挿入',
        PERSONALIZE: 'パーソナライズ',
        DEAR: '親愛',
        AGREEMENT_TEMPLATE_CREATE_TAB_LABEL: '契約テンプレートの作成',
        AGREEMENT_TEMPLATE_EDIT_TAB_LABEL: '契約テンプレートの編集',
        AGREEMENT_TEMPLATE_COPY_TAB_LABEL: '契約テンプレートのコピー',
        COPY_OF: 'コピー_の',
        AGREEMENT_TEMPLATES: '契約テンプレート',
        ADD_SIGNATURE_1: '署名 1',
        ADD_SIGNATURE_2: '署名 2',
        ADD_SIGNATURE_3: '署名 3',
        ADD_SIGNATURE_4: '署名 4',
        ADD_SIGNATURE_5: '署名 5',
        COVER_LETTER: 'カバーレター',
    },
    SMS_TEMPLATE: {
        SMS_TEMPLATE_EDIT_TAB_LABEL: 'SMS テンプレートの編集',
        SMS_TEMPLATE_COPY_TAB_LABEL: '合計テンプレートをコピーする',
    },
    WEBSITE_TRACKING: {
        DOMAIN_NAME: 'ドメイン名を入力してください (http または www を除く)',
        GENERATE_TRACKING_CODE: 'トラッキングコードの生成',
        TRACKING_CODE: 'トラッキングコード',
        COPY_TO_CLIPBOARD: 'クリップボードにコピー',
        DONE: '完成です'

    },
    WEB_FORM: {
        SELECT_FORM: 'フォームを選択する',
        WEB_FORM_CREATE_TAB_LABEL: 'Webフォームの作成',
    },
    MAIL: {
        EMAIL_FOR_TESTING: 'テスト用の電子メールを入力',
        SEND_A_TEST_EMAIL: 'テストメールを送信する',
        TYPE_HERE: 'ここに入力します。',
        INSERT_IMAGE: 'イメージの挿入',
        ADD_SIGNATURE: '署名の追加',
        PERSONALIZE: 'パーソナライズ',
        FROM: '差出人',
        SEND_MAIL: '電子メールを送信',
        PERSONALIZE_SUBJECT: '件名をパーソナライズ',
        SUBJECT: '件名',

        // not found in doc
        COMPUTER: 'コンピュータから',
        LIBRARY: 'ライブラリから',
        RESCHEDULE: '再スケジュール',
        TO: 'に',
        ATTACH: 'ファイルを添付する',
        CC: 'CC',
        WHEN: 'いつ',
        SENDING_TEST_MAIL: 'テストメールを送信しています...',
        SCHEDULE: 'スケジュール',
        SCHEDULE_DIALOG_TITLE: 'スケジュールの日付と時刻を選択してください',
        SEND_LATER: '後で送信',
        SEND: '送る',
        RECIPIENTS: '受取人',
        SENDING: '送信...'
    },
    SMS: {
        TO: 'に',
        WHEN: 'いつ',
        FROM: '差出人',
        FROM_NUMBER: '番号から',
        SEND_A_TEST_SMS: 'テストSMSを送信する',
        TO_NUMBER: '番号へ',
        STATUS: '状態',
        ENTER_NUMBER :'テスト用の番号を入力してください'
    },
    AUDIT_LEADSCORE: {
        LEAD_SCORE_HISTORY: 'リードスコアの履歴',

        // not found in doc

        SCORE: 'クチコミ',
        DATE: '日付',
        ACTION: 'アクション',
        USER: 'ユーザー',
        DETAILS: '詳細',
        ACTION_NAME: 'アクション名',
        SCORE_DETAILS: 'スコアの詳細',
        BY: '〜によって',
        FROM: 'から',
        LINK: 'リンク',
        CLICKED: 'クリックした',
        EMAIL: 'Eメール',
        OPENED: '開かれた',
        UNSUBSCRIBED: '登録されていない',
        CONVERTED_FROM: '変換元',
        TO: 'に',
        IN_OPPORTUNITIES: '機会に',
        ADDED_TO: 'に追加',
        REMOVED_FROM: 'から削除',
        UPDATED_TO: '更新された',
        WEBFORM: 'ウェブフォーム',
        SUBMITTED: '提出済み',
        VISITED: '訪問した',
        EXPIRED: '期限切れ',
        'Imported': '輸入された',
        'Created': '作成した',
        'Converted to Account': 'アカウントに変換されました',
        'Created from Web form': 'Webフォームから作成',
        'Created from Landing page': 'ランディングページから作成',
        'Created from Zapier': 'ザピアから作成',
        'Created from Outlook': 'Outlookから作成',
        'Created from Outlook (Client)': 'Outlook（クライアント）から作成',
        'Created from G Suite': 'Gスイートから作成',
        'Created from Office 365': 'Office 365から作成',
        'Created from Facebook/twitter etc': 'Facebook / twitterなどから作成',
        'Pushed': 'プッシュ',
        'Created from API': 'APIから作成',
        'Inline/Quick updated': 'インライン/クイックアップデート',
        'Updated': '更新しました',
        'Batch Updated': 'バッチ更新',
        'Workflow Applied': '適用されるワークフロー',
        'Converted': '変換された',
        'Assigned': '割り当て済み',
        'Deleted': '削除済み',
        'Restored': '復元された',
        'Merged': '合併',
        'Main Contact updated': 'メイン連絡先の更新',
        'Shared': '共有',

        'Link Clicked': 'リンククリック数',
        'Email Opened': '電子メールが開かれました',
        'Unsubscribed': '登録されていない',
        'Web Form Submitted': '提出されたWebフォーム',
        'Removed from Group': 'グループから削除されました',
        'Field Updated': '更新されたフィールド',
        'Added to Group': 'グループに追加',
        'Website Visited': '訪問したウェブサイト',
        'Has Business Email': 'ビジネス電子メールを持っている',
        'Clicked Link': 'クリックされたリンク',
        'Opened Email': '開かれた電子メール',
        ACCOUNT_SCORE: 'アカウントのあなたの完璧なスコアがあります',
        CONTACT_SCORE: 'コンタクトのためのあなたの完璧なスコアがあります'
    },
    DASHBOARD: {
        TODAY: '本日は',
        YESTERDAY: '昨日',
        THIS_WEEK: '今週',
        THIS_MONTH: '今月',
        THIS_QUARTER: 'この四半期',
        THIS_YEAR: '今年の',
        LAST_WEEK: '先週です',
        LAST_MONTH: '先月',
        LAST_QUARTER: '最終四半期',
        LAST_YEAR: '昨年',
        CUSTOM: 'カスタム',

        //Kanban View
        NEXT_MONTH: '来月',
        NEXT_QUARTER: '次の四半期',
        NEXT_THREE_MONTHS: '次の3ヶ月',
        NEXT_TWO_MONTHS: '次の2ヶ月',

        SALES_DASHBOARD: '販売ダッシュボード',
        MARKETING_DASHBOARD: 'マーケティングダッシュボード',
        // SALES_MARKETING_DASHBOARD: '販売およびマーケティングダッシュボード',

        // not found in doc

        REPOSITION_GADGET: 'クリックしてドラッグすると、このガジェットの位置を変更できます',
        'Top Sources By Lead Volume': 'リードソースによるトップソース',
        'Top Sources By Lead Conversion': 'リードソースによるトップソース',
        'Marketing Email Summary': 'マーケティング電子メールの概要',
        'Top Marketing Emails by CTR': 'CTRによるトップのマーケティングEメール',
        'Top Web Pages': 'トップのWebページ',
        'Lead Pipeline': 'リードパイプライン',
        'Opportunity Pipeline': '機会パイプライン',
        'Sales Leaderboard': 'セールスリーダーボード',

        'Status': 'ステータス',
        'Count': 'カウント',
        'Subject': '件名',
        // 'Click-Through Rate(CTR)': '',
        'Website Name': 'ウェブサイト名',
        'Forecast': '予報',
        'Sales person': '営業担当者',
        'Closed': '閉鎖',

        'Activities': '活動',
        'Accounts': 'アカウントマトリックス',
        'Opportunities': '機会マトリックス',
        'Users': 'ユーザー',
        'Appointment': '予定',
        'Dialed': 'ダイヤル',
        'Email': '電子メール',
        'LVM': 'LVM',
        'Note': 'メモ',
        'Spoke': '話した',
        'Task': 'タスク',
        'Transferred': '転送',

        'Qualified': '修飾',
        'Presentation': 'プレゼンテーション',
        'Proposal': '提案',
        'Negotiation': '交渉',
        'Close': '閉じる',

        'Lead': 'リード',
        'Prospect': '見通し',
        'Customer': '顧客',
    },
    LOGIN: {
        LOG_IN: 'Login',
        EMAIL_ADDRESS: 'メールアドレス',
        PASSWORD: 'パスワード',
        REMEMBER_ME: '私を覚えて'
    },
    SOCIAL_CAMPAIGN: {
        SAVE_AS_DRAFT: '下書きとして保存',
        POST_NOW: '今すぐ投稿',
        CONNECT: '接続'

    },
    DOCUMENT_LIBRARY: {
        COMPUTER: 'コンピューター',
        LIBRARY: 'としょかん',


        // not found in doc

        IMAGES: 'イメージ',
        STOCK_IMAGES: 'ストックイメージ',
        DOCUMENTS: 'ドキュメント',
        SEARCH: 'サーチ...',
        UPLOAD_DOCUMENT: 'ドキュメントをアップロードする',
        NAME: '名',
        TITLE: 'タイトル',
        TITLE_REQUIRED: 'タイトル (必須)',
        LINK_REQUIRED: 'リンク (必須)',
        TYPE: 'タイプ',
        OWNER: 'オーナー',
        ALL: 'すべて',
        NO_IMAGE_FOUND: '画像が見つかりません',
        UPLOAD_DIALOG_TITLE: 'アップロードする文書を選択してください',
        SHARED_WITH: 'と共有しました',
        UNITS: '単位',
        UNIT: '単位',
        UNIT_PUSH: '単位:',
        RENAME_FOLDER: 'フォルダの名前変更',
        CREATE_FOLDER: 'フォルダの作成',
        NEW_FOLDER: '新しいフォルダ',
        ASSETS_NOT_FOUND: 'フォルダとファイルが見つかりません。',
        'Folder': 'フォルダー',
        'File': 'ファイル',
        IMAGE: 'イメージ',
        DOCUMENT_AND_FILE: 'ドキュメントとファイル',
    },
    HEADER: {
        SALES_TEMPLATE: '営業用メール テンプレート',
        CALENDAR_TASKS: 'カレンダー/タスク',
        ACCOUNT: '勘定',
        SALES_REPORT: '売上報告書',
        ACCOUNTS: 'アカウント',
        ACCOUNTS_WITHOUT_GROUP: 'グループのないアカウント',
        MY_ACCOUNT: '私のアカウント',
        TODAYS_ACCCOUNTS: '今日のアカウント',
        CONTACTS: '連絡先',
        CONTACTS_WITHOUT_ACCOUNT: 'アカウントのない連絡先',
        CONTACTS_WITHOUT_ACCOUNTS: 'アカウントのない連絡先',
        CONTACTS_WITHOUT_GROUPS: 'グループのない連絡先',
        MY_CONTACTS: '私の連絡先',
        TODAYS_CONTACTS: '今日の連絡先',

        ALL_APPOINMENTS: 'すべてのアポイントメント',
        MY_APPOINMENTS: '私のアポイントメント',
        MY_COMPLETED_APPOINMENTS: '私の完了予定',
        MY_MISSED_APPOINMENTS: '私の逃したアポイントメント',
        TODAYS_APPOINMENTS: '今日のアポイントメント',
        ALL_TASKS: 'すべてのタスク',
        MY_COMPLETED_TASKS: '私の完了した仕事',
        MY_FUTURE_TASKS: '私の将来の仕事',
        MY_PENDING_TASKS: '私の保留中の仕事',
        MY_TODAYS_TASKS: '私の今日の仕事',
        ONBOARDING: 'オンボーディング',
        SALES_PIPELINE: '販売パイプラインレポート',
        COUPONS_CLAIM_REPORTS: 'クーポン請求レポート',
        'Cases': 'ケース',
        'Projects': 'プロジェクト',
        'Issues': '問題',
        'Solutions': 'ソリューション',
        'Contacts': '連絡先',
        'Accounts': 'アカウント',
        'Opportunities': '機会',
        'Appointments': '予約',
        'Tasks': 'タスク',
        'Onboarding': 'オンボーディング',
        'Units': '単位',
        'Assets': '資産',
        'Activities': 'アクティビティ',
        'Conversions': '変換',
        'Campaigns': 'キャンペーン',
        'Reports': 'レポート',
        'Sales_templates ': '販売テンプレート',
        'Dashboard': 'ダッシュボード',
        'Sales Leads': 'セールスリード',
        'Recycle Bin': 'アカウント',
        REPORT: 'レポート',
        TASKS_PAGE: 'タスク',
        'Products': '製品',
    },
    ADVANCE_SEARCH: {

        // not found in doc

        ADVANCE_SEARCH: '高度な検索',
        ADD_FIELD:'別のフィールドを検索'

    },
    IMPORT: {

        // not found in doc

        GROUP: 'グループ ( 必須 )',
        WORKFLOW: 'ワークフロー ( 必須 )',
        STATUS: '状態 ( 必須 )',
        SOURCE: 'ソース ( 必須 )',
        PRIMARY_SOURCE:'一次情報源',
        SECONDARY_SOURCE:'二次資料',
        IMPORT: 'インポート',
        LINK_USING: 'リンクの使用',
        LINK_WITH: 'とのリンク',
        APPLY_WORKFLOW: 'ワークフローの適用',
        AUTO_MAP: '自動マップ',
        USE_SAVED_MAPPING: '保存されたマッピングを使用する',
        SAVE_MAPPING: 'マッピングを保存する',
        DELETE_MAPPING: 'マッピングの削除',
        DONOT_CHECK_DUPLICATE: '重複をチェックしない',
        CHECK_DUPLICATE: '重複をチェックする',
        CHECK_DUPLICATE_BY: '重複を',
        DONOT_IMPORT: 'インポートしない',
        MARK_DUPLICATE: '重複としてインポートしてマークする',
        UPDATE: '更新',

        //not found in doc
        STEP_1: 'ソースを選択',
        STEP_2: 'マップフィールドとインポート',
        FINISH: 'フィニッシュ',
        NEXT: '次',
        MAP_CSV_COLUMNS: 'CSV列をCRMにマップする',
        MAP_XLS_COLUMNS: 'XLS列をCRMにマップする',
        MAP_XLSX_COLUMNS: 'XLSX列をCRMにマップする',
        MAP_TO_CRM_FIELD: 'CRMフィールドへのマッピング',
        COLUMN: 'カラム',
        ADD_NEW_GROUP: '新しいグループを追加',
        WORKFLOW_OPTIONAL: 'ワークフロー ( オプション )',
        FILE_MUST_CSV: 'ファイルは CSV 形式である必要があります。',
        SKIP: 'スキップ',
        MERGE: 'マージ',
        CHECK_DUPLICATE_USING: '使用して重複をチェック',
    },
    REPORT: {

        // not found in doc

        START_DATE: '開始日',
        END_DATE: '終了日',
        REPORT_ON: '結果報告',
        REFRESH: 'リフレッシュする',
        PRINT: '印刷する',
        SALES_REP: '営業担当者',
        DATE_RANGE: '期間',
        SALES_REPORT: 'セールスレポート',
        SERVICE_REPORT: 'サービスレポート',
        'Activity Report': '活動報告',
        'Conversion Report': 'コンバージョンレポート',
        'Call Report': 'コールレポート',
        'Pipeline Report': 'パイプラインレポート',

        'Customer Activation Report': '顧客認証レポート',
        'Customer Service Type Report': 'カスタマーサービスタイプレポート',
        'Customer Assessment Report': '顧客評価レポート',
        'Customer Leads Assessment Report': '顧客リード評価レポート',
        'Customer Status Report': '顧客ステータスレポート',
        'Sales Pipeline Report': '販売パイプラインレポート',
        'Days in Franchise Workflow Report': 'フランチャイズワークフローレポートの日数',
        'Franchise Workflow Report': 'フランチャイズワークフローレポート',
        'Franchise Sales Pipeline Report': 'フランチャイズセールスパイプラインレポート',
        'Franchise Employees Report': 'フランチャイズ従業員レポート',
        'Franchise Last Contact Report': 'フランチャイズ最後の連絡先レポート',
        'InXpress User Baseline Report': 'InXpressユーザーベースラインレポート',
        'Franchise User Report': 'フランチャイズユーザーレポート',
        'Sales Summary Report': '販売概要レポート',
        ACTIVITY_MATRIX: 'アクティビティマトリックス',
        'ACTIVITY_TYPE': 'アクティビティタイプ',
        'Service Reports': 'サービスレポート',
        'Project Reports': 'プロジェクトレポート',
        'CRM Activity by Account': 'アカウント別CRMアクティビティ',
        'Activity by Franchise': 'フランチャイズによる活動',
        'Franchise Activity Report': 'フランチャイズ活動報告',
        'Issue Reports': '課題レポート',
        'Customer Service and Sales Rep Listing': 'カスタマーサービスとセールス担当者のリスト',
         GO: '行こう',
        'CRM Account Details': 'CRMアカウントの詳細',
        'Activity by Coach': 'コーチによる活動',
        'Franchise Conversion Report': 'フランチャイズコンバージョンレポート',
        'Tasks Report': 'タスクレポート',
        'Email Report': 'メールレポート',
        'SMS Report': 'SMSレポート',
        'Orthotic Report': 'お金を報告する',
        'Greenlight Export': 'Greenlightエクスポート',
        REPORT_ON_OBJECTS: {
            ACCOUNTS: 'アカウント',
            OPPORTUNITIES: '機会'
        },
        'Count': 'カウント',
        'Grand Total': '総計',
        ACTIVITIES_BY_TYPE: 'タイプ別アクティビティ',
        ACTIVITIES_BY_SALES_REP: 'セールス担当者によるアクティビティ',
        ALL_CONVERSIONS_BY_STATUS: 'ステータス別のすべてのコンバージョン',
        CUSTOMERS_BY_SOURCE: 'ソースによる顧客',
        DEALS_BY_SALES_PER: '営業担当者によるパイプライン',
        DEALS_BY_STAGES: 'ステージ別取引',
        TOTAL_CALLS_BY_SALES_REP: '営業担当者による総コール',
        TOTAL_CALL_DURATION_BY_SALES_REP: '営業時間別総通話時間',
        EXCEL: 'Excel',
        MATRIX_BY_STATUS: 'ステータス別マトリックス',
        MATRIX_BY_SOURCE: 'ソース別マトリクス',
        'Status': '状態',
        'Source': 'ソース',
        '5 Ways Report': '5通りのレポート',
        'Forecast Report': '予測レポート',
        CASES_BY_STATUS: 'ステータス別のケース',
        CASES_BY_PRIORITY: '優先度によるケース',
        PROJECTS_BY_STATUS: 'ステータス別プロジェクト',
        PROJECTS_BY_TYPE: 'タイプ別プロジェクト',
        ISSUES_BY_STATUS: 'ステータス別の問題',
        ISSUES_BY_PRIORITY: '優先順位による問題',
        SUPPORT_MATRIX: 'サポートマトリックス',
        CUSTOMER_BY_CASES: 'ケース別の顧客',
        PROJECT_MATRIX: 'プロジェクトマトリックス',
        ISSUE_MATRIX: '課題表',
        SUB_TOTAL: '小計',
        SERVICE_MATRIX_HEADER: {
            'Assigned to': 'に割り当てられた',
            'Total count': '総数',
            'Company': '会社',
            'Assigned To': 'に割り当てられた',
        },
        COUPONS_NAME: 'クーポンを選択',
        PRODUCT_SELECT: '製品を選択してください',
        AVAILABLE_REPORTS: '利用可能なレポート',
        WORKFLOW_STAGES: 'ステージ',
        CHART_TYPE: 'チャートタイプ',
        SHOW: '見せる',
        VALUE: '価値',
        COUNT: 'カウント',
        DEALS_BY_SALES_REP: '営業担当者によるパイプライン',
        PIPELINE:'パイプライン',
    },
    FILTER: {

        // not found in doc
        CRITERIA_FOR_ASSOCIATED_OBJECTS:'関連オブジェクトの基準',
        NAME_REQUIRED: 'フィルタ名（必須）',
        REMOVE_CONDITION: '条件を削除',
        NEXT_BUTTON_TEXT: '次',
        PREVIOUS_BUTTON_TEXT: '前',
        AND: 'そして',
        OR: 'または',
        ADD_ANOTHER: '別のものを追加する',
        ADD_CONDITION: '条件を追加する',
        COPY_FILTER: 'のコピー ',
        FROM_DATE: '日付から (From Date)',
        TO_DATE: '現在まで (To Date)',

        CONTAINS: '含む',
        DOES_NOT_CONTAINS: 'が含まれていない',
        IS_EQUAL_TO: 'に等しい',
        IS_NOT_EQUAL_TO: 'に等しくない',
        IS_EMPTY: 'が空',

        IS_GREATER_THAN: 'より大きい',
        IS_GREATER_THAN_OR_EQUAL_TO: 'がより大きいか等しい',
        IS_LESS_THAN: 'よりも少ない',
        IS_LESS_THAN_OR_EQUAL_TO: '以下である',
        'Contacts': '連絡先',
        'Contacts without Groups': 'グループのない連絡先',
        'My Contacts': '私の連絡先',
        'Contacts without Account': 'アカウントなしの連絡先',
        'Todays Contacts': '今日の連絡先',
        'Contacts without Accounts': 'アカウントのない連絡先',
        'All records': 'すべてのレコード',
        'Added this Month': '今月追加されました',
        'Added this Week': '今週追加されました',
        'My Opportunities': '私の機会',
        'Opportunities': '機会',
        'Accounts': 'アカウント',
        'Accounts without Contact': '連絡先のないアカウント',
        'Accounts without Groups': 'グループのないアカウント',
        'My Accounts': '私のアカウント',
        'Todays Accounts': '今日のアカウント',
        'Cases': 'ケース',
        'Projects': 'プロジェクト',
        'Issues': '問題',
        'Accounts without Contacts': '連絡先のないアカウント',
    },
    SOURCE: {

        // not found in doc

        SOURCE_NAME: 'ソース名',
        PRIMARY_SOURCE:'一次情報源',
        SECONDARY_SOURCE:'二次資料',

    },
    INXPRESS_REPORT: {

        // not found in doc

        COUNTRY_SELECT: '国を選択',
        FRANCHISE_SELECT: 'フランチャイズを選択',
        WORKFLOW_SELECT: 'ワークフローの選択',
        START_DATE: '開始日',
        NO_RECORDS_FOUND: 'レコードが見つかりませんでした。',
        'Account': 'アカウント',
        'Sales Rep': '営業担当者',
        'Workflow': 'ワークフロー',
        'Status': 'ステータス',
        REGION_SELECT: '領域を選択します',
    },
    KANBAN_REPORT: {
        WORKFLOW: 'ワークフロー',
        USER: 'ユーザー',
        DURATION: '期間',
        SORT_BY: '並び替え',
        REPORT_BY: '報告者',
        'Forecast amount': '予測金額',
        'Close date': 'クローズ日',
        'Sales rep': '営業担当者',
        'Status': 'ステータス',
        'Convert': '変換',
        'Edit': '編集',
        'Delete': '削除',
        'opportunities': '状態',
        'Probability': '確率',
    },
    APP: {
        // not found in doc

        CURRENT: '私は新しいバージョンを使用します',
        LEGACY: '私はレガシーバージョンを使用します',
        HEADING: 'Soffrontバージョンを選択してください',
        SUB_TITLE: 'あなたの組織は、従来のバージョンにアクセスできます。新しいバージョンの代わりに使用しますか？'
    },
    OTHER_VIEW: {

        // not found in doc

        SUBSCRIBE_LABEL: '申し込む'
    },
    MY_INTEGRATION: {
        CONNECTING: '接続する',
        DISCONNECTING: '切断',
        SYNC_EMAIL: '電子メールの同期',
        SYNC_CONTACT: '連絡先を同期する',
        SYNC_CALENDAR: 'カレンダーの同期',
        APPEND_COMPANY: '会社名を追加する',
        APPEND_CONTACT: '連絡先の名前を追加',
        CALENDAR: 'カレンダー',
        SERVICE: 'サービス',
        INTEGRATION_HEADER: '電子メールとカレンダーの統合',
        PHONE_INTEGRATION: '電話の統合',
        MY_INTEGRATIONS: '統合',
        CONTACT_FILTER: '連絡先フィルタ',
        RINGCENTRAL_HEADER: 'リングセントラル',
        SMS_INTEGRATION: 'SMSの統合',
        QUICKBOOKS: 'QuickBooks',
        XERO:'Xero',
        INTEGRATE:'統合',
        SELECT_INTEGRATION_ITEMS:'統合するアイテムを選択します',
        INVOICES:'請求書',
        PAYMENTS:'支払い',
        MANNUALLY:'手動（CRM> Xero）',
        AUTOMATICALLY:'自動的に（Xero> CRM）',
        ACCOUNTING_INTEGRATION_LABEL: 'と統合する',
        ACCOUNTING_INTEGRATION: '会計統合',
        QUICKBOOKS_INTEGRATION: 'QuickBooksの統合',
        EVENTBRITE_INTEGRATION: 'Eventbriteインテグレーション',
        SELECT_A: 'を選択し',
        CRM_CONTACT_FILTER_SELECT: 'CRM 連絡先フィルタの選択',
        CONTACT_GROUP_NOT_FIND: '連絡先グループが見つかりません。クリックしてください',
        HERE: 'ここは',
        TO_TRY_AGAIN: 'もう一度やり直してください。',
        CONTACT_FILTER_NOT_FIND: '連絡先フィルタが見つかりません。',
        SEND_SALES_EMAILS_USING: 'を使用して販売メールを送信する',
    },
    PROFILE_DETAILS: {
        // not found in doc
        PROFILE_DETAILS_LABEL: 'プロフィール',
        PERSONAL_INFORMATION: '個人情報',
        USERNAME_LABEL: 'これはユーザー名として使用されます。',
        CHANGE_PASSWORD: 'パスワードを変更する',
        MANAGE_NOTIFICATIONS: '通知の管理',
        EMAIL_SIGNATURE: '電子メール署名',
        TASKS: 'タスク',
        APPOINTMENTS: '予約',
        TASK_EMAIL: 'タスクの電子メール',
        TASK_POPUP: 'タスクポップアップ',
        APPOINTMENT_EMAIL: '予約メール',
        APPOINTMENT_POPUP: '予定ポップアップ',
        ON: 'に',
        OFF: 'オフ',
        CHANGE_PHOTO: '写真を変更する',
        NEW_PASSWORD: '新しいパスワード',
        CONFIRM_PASSWORD: '新しいパスワードを確認',
        NAME_REQUIRED: '名前（必須）',
        EMAIL_REQUIRED: 'メール（必須）',
        PHONE: '電話',
        TIME_ZONE_REQUIRED: 'タイムゾーン（必須）',
        DATE_FORMAT: '日付形式',
        LANGUAGE: '言語',
        USER_COLOR: 'ユーザーカラー',
    },

    DETAIL_VIEW_CHILD_NOTES: {
        ALL: 'すべて',
        USER: 'ユーザー',
        SYSTEM: 'システム',
        MY: 'じぶんの',
        ATTACHMENT_INFO: '添付ファイル情報',
    },

    DOCUSIGN: {
        CONNECT_BUTTON: '接続する',
        SAVE_BUTTON: 'セーブ',
        CANCEL_BUTTON: 'キャンセル',
        USERNAME: 'ユーザー名（必須）',
        PASSWORD: 'パスワードが必要）',
        INTREGATOR_KEY: 'インテグレータキー（必須）',
        SELECT_TEMPLATE: 'テンプレートを選択',
        SEND: '送信',
        DISCONNECT_BUTTON: '切断する',
        DOCUSIGN_SETUP_HEADER: 'DocuSignインテグレーション',
        DOCUSIGN_CONNECT_TITLE: 'DocuSignアカウントに接続する',
        ADD_NOTE: 'コメントを追加する（オプション）。',
    },
    TWILIO: {
        CALL: 'コール',
        VERIFY: '確認',
        DISCONNECT: '切断する',
        SHOW_SCRIPTS: 'スクリプトを表示する',
        HIDE_SCRIPTS: 'スクリプトを隠す',
        CALL_SETUP: '通話設定',
        CALL_SCRIPT: '呼び出しスクリプト',
        CALL_SCRIPT_COPY_TAB_LABEL: 'コピーコールスクリプト',
        COPY_OF: 'のコピー',
        ADD_NUMBERS: '発信電話番号を追加する',
        OUTBOUND_PHONE_NUMBERS: '発信電話番号',
        ADD_ANOTHER_NUMBER: '別の番号を追加',
        CALL_RECORDING: '通話録音',
        PHONE_CREDITS: 'あなたの電話クレジット',
        PURCHASE_CREDITS_YOU_HAVE: 'あなたが持っている',
        PURCHASE_CREDITS_MINUTES_LEFT: '分が残った。',
        PURCHASE_CREDITS_MORE: 'より多くのクレジットを購入してください。',
        CALLING: '呼び出し',
        RECORD_ALL_CALL: 'すべての呼び出しを記録する',
        VERIFIED: '検証',
        CLICK_TO_CALL: '私は電話を取る準備ができている',
        ADDITIONAL_INFO: '追加情報を表示',
        ADDITIONAL_INFO_TITLE: '追加情報',
        SELECT_FIELD:'フィールドを選択（最大3フィールド）'
    },
    CALL_SCRIPT: {
        ADD_SCRIPT_NAME: 'スクリプト名（必須）',
        ADD_DETAILS: 'スクリプトの詳細（必須）',
        WORKFLOW_NAME: 'ワークフロー名',
        CALL_SCRIPTS_EDIT_TAB_LABEL: '呼び出しスクリプトの編集',
    },
    TWILIO_SMS: {
        ZIP_CODE: '郵便番号',
        AREA_CODE: '市外局番',
        ADD_TO_ACCOUNT: '追加',
        SMS_CREDITS: 'あなたのSMSクレジット',
        SMS_CREDITS_LEFT: 'SMSクレジットは残った。',
        SEND: '送信',
        NO_NUMBER_PURCHASED: '電話番号はまだありません。電話番号を購入するには、検索オプションを使用します。購入後、番号を使用してメッセージを送信することができます。',
        FIND_PHONE_NUMBER: '郵便番号または電話番号で電話番号を検索する',
        FIND_PHONE_NUMBER_BY_AREA_CODE: '市外局番で電話番号を探す',
        SEND_SMS: 'テキストメッセージを送信する',
        YOUR_MESSAGE: 'あなたのメッセージ',
        OR: 'または',
        PHONE_NUMBER: '電話番号',
        PHONE: '電話',
        AVAILABLE_TO: 'に利用できる',
        USER_NAME: 'ユーザー名',
        USED_BY: 'によって使われた',
        UNIT_NAME: 'ユニット名',
    },
    QUICKBOOKS: {
        CONNECT_BUTTON: '接続する',
        LABEL: 'QuickBooksオンラインアカウントに接続する'
    },
    PREVIEW_EMAIL_TEMPLATE: {
        ALL_LAYOUT: 'すべてのレイアウト',
        LEFT_SIDEBAR_LAYOUT: '左側のサイドバーのレイアウト',
        RIGHT_SIDEBAR_LAYOUT: '右サイドバーのレイアウト',
        HORIZONTAL_LAYOUT: '水平レイアウト',
        MULTIPLE_COLUMN_LAYOUT: '複数の列レイアウト'
    },
    EVENTBRITE: {
        CONNECT_BUTTON: '接続する',
        SELECT_ORGANISATIONS: 'この組織からのイベントを同期する',
        EVENTBRITE_CONNECT_TITLE: 'Eventbriteアカウントに接続する',
        SELECT: '選択',
        EVENTBRITE_TOGGLE_TITLE: '同期中に、CRMに見つからなければ新しい連絡先を追加する',
        EVENTBRITE_MAP_FIELDS: 'マップフィールド',
        CRM_FIELDS_TITLE: 'CRM連絡先フィールド',
        EVENTBRITE_FIELDS_TITLE: 'Eventbriteのフィールド',
    },
    SIDE_NAV: {
        APPOINTMENTS: 'カレンダー/タスク',
        DASHBOARD: 'ダッシュ ボード',
        REPORTS: 'レポート',
        SALES_TEMPLATES: '営業用メール テンプレート',
        SOCIAL_MARKETING: 'ソーシャルマーケティング',
        WEBSITE_TRACKING: 'ウェブサイト分析',
        SOLUTIONS: 'ソリューション',
        CONTACTS: '連絡先',
        ACCOUNTS: 'アカウント',
        OPPORTUNITIES: '機会',
        CASES: 'ケース',
        PROJECTS: 'プロジェクト',
        ISSUES: '問題',
        ONBOARDING: 'オンボーディング',
        UNITS: '単位',
        ASSESTS: '資産',
        OPERATIONS_ACTIVITIES: 'アクティビティ',
        OPERATIONS_CONVERSIONS: '変換',
        OPERATIONS_CAMPAIGNS: 'キャンペーン',
        OPERATIONS_REPORTS: 'レポート',
        LEAD_CAPTURE: 'リードキャプチャ',
        EMAIL_MARKETING: 'メールマーケティング',
        'CALENDAR/TASKS': 'カレンダー/仕事',
        'SALES REPORTS': 'レポート',
        'SALES TEMPLATES': '販売テンプレート',
        'SOCIAL MARKETING': 'ソーシャルマーケティング',
        'ONLINE REPUTATION': 'オンライン評判',
        DASHBOARDS: 'ダッシュボード',
        'SALES LEADS': 'セールスリード',
        'USEFUL LINKS': '便利なリンク',
        NEWS: 'ニュース',
        LIBRARY: 'としょうかん',
        COMMUNITY: 'コミュニティ',
        TRAINING: 'トレーニング',
        HUB: 'ハブ',
        SCROLL_TO_RIGHT: '右にスクロール',
        SCROLL_TO_LEFT: '左にスクロール',
        ROYALTIES: 'ロイヤリティ',
        INBOX: '受信箱',
    },
    NO_RECORD: {
        'Solutions': 'ソリューション',
        'Appointments': '予定',
        'Tasks': 'タスク',
        'Groups': 'グループ',
        'Notes': 'ノート',
        'Attachments': '添付ファイル',
        'Marketing-templates': 'マーケティングテンプレート',
        'Sales-templates': 'セールステンプレート',
        'Web-forms': 'ウェブフォーム',
        'Products': '製品',
        'Broadcast-campaigns': '放送キャンペーン',
        'Ab-campaigns': 'ab-キャンペーン',
        'Automation-designer': 'オートメーションデザイナー',
        'Projects': 'プロジェクト',
        'Invoices': '請求書',
        'Touch-campaigns': 'タッチキャンペーン',
        'Events': 'イベント',
        'Sms-templates': 'SMS テンプレート',

        'Emails': 'メール',
        'Relationships': '関係',
        'Templates': 'テンプレート',

        'Cases': 'ケース',
        'Contacts': '連絡先',
        'Accounts': 'アカウント',
        'Opportunities': '機会',
        'Issues': '問題',
        'Salesleads': 'セールスリード',
        'Source': 'ソース',
        'Users': 'ユーザー',
        'Securitylevel': 'セキュリティレベル',
        'Workflows': 'ワークフロー',
        'Relations': '関係',
        'Notetypes': 'ノートタイプ',
        'Campaigntypes': 'キャンペーンの種類',
        'Emailintegration': 'メールエイリアス',
        'Library': 'としょうかん',
        'Recyclebin': 'アカウント',
        'Sms': 'SMS',
        'Assignmentrules': '割り当てルール',
        'Units': '単位',
        'Orderitems': '注文品',
        'Claims': '請求',
        'Payments': '支払い',

    },
    REPORT_SCHEDULE_DIALOG: {
        REPORT_SCHEDULE_SETUP: 'レポートスケジュール',
        DAY_OF_THE_MONTH: '月の日',
        DAY_OF_THE_WEEK: '曜日',
        REPEAT_TYPE: 'リピートタイプ (必須)',
        REPEAT_ON: 'に繰り返し',
        REPEAT_BY: 'で繰り返し',
        START_DATE: '開始日 (必須)',
        END_DATE: '終了日',
        REPEAT_EVERY: 'すべて繰り返す (必須)',
        SEND_TO: '送信 (必須)',
        SCHEDULE: 'スケジュール',
    },
    LIST_VIEW_HEADER: {
        AUTOMATION_DESIGNER: {
            'Name': '名',
            'Status': '状態',
            'Created By': 'によって作成された',
            'Created On': '作成日',
            'Updated By': '更新者',
            'Updated On': '更新日',
        },
        APPOINTMENT: {
            'Subject': '件名',
            'Company': '会社',
            'Status': '状態',
            'Contact': '接触',
            'Contact Name': '連絡先',
            'Start date': '開始日',
            'End date': '終了日',
            'Assign to': '割りあてる',
            'Visible to': '目に見える',
            'Outlook Sync': 'Outlookの同期',
            'Google Sync': 'Google Sync',
            'Text': 'テキスト',
            'Creator': 'クリエイター',
            'Created on': '作成日',
            'Updated by': '更新者',
            'Updated on': '更新日',
        },
        TASKS: {
            'Task Name': 'タスク名',
            'Company': '会社',
            'Status': '状態',
            'Previous Task': '前のタスク',
            'Complete Date': '完全な日付',
            'Owner': 'オーナー',
            'Due By': '支払期日',
            'Contact Name': '連絡先',
            'Description': '説明',
            'Updated on': '更新日',
            'Contact': '接触',
            'Start Time': '始まる時間',
            'End Time': '終了時間',
            'Item': '項目',
            'Billable': '請求可能',
        },
        BROADCAST: {
            'Broadcast Name': '放送名',
            'Status': 'ステータス',
            'Run Date': '実行日',
            'Owner': '所有者',
            'Created On': '作成日',
            'Updated On': '更新日',
            'Target': 'ターゲット',
            'From email': '電子メールから',
            'From name': '名前から',
            'Updated By': '更新者',
            'Created By': '作成者'
        },
        AB_CAMPAIGN: {
            'Campaign Name': 'キャンペーン名',
            'Created By': '作成者',
            'Created On': '作成日',
            'Run Date': '実行日',
            'Status': 'ステータス',
            'Target Filter': 'ターゲットフィルタ',
            'Updated By': '更新者',
            'Updated On': '更新日'
        },
        SALES_TEMPLATE: {
            'Name': '名前',
            'Category': 'カテゴリ',
            'Subject': '件名',
            'Status': 'ステータス',
            'Owner': '所有者',
            'Created On': '作成日',
            'Updated By': 'によって更新',
            'Updated On': '更新日',
        },
        MARKETING_TEMPLATE: {
            'Name': '名前',
            'Category': 'カテゴリ',
            'Subject': '件名',
            'Status': 'ステータス',
            'Owner': '所有者',
            'Created On': '作成日',
            'Updated By': 'によって更新',
            'Updated On': '更新日',
        },
        WEB_FORM: {
            'Name': '名前',
            'Table Name': 'テーブル名',
            'Group': 'グループ',
            'Source': 'ソース',
            'Status': 'ステータス',
            'Assign To': 'に割り当てる',
        },
        TOUCH_CAMPAIGNS: {
            'Campaign name': 'キャンペーン名',
            'Status': 'ステータス',
            'Owner': '所有者',
            'Target': 'ターゲット',
            'From email': 'メールから',
            'Created On': '作成日',
            'Created By': '作成者',
            'From name': '名前から',
            'Updated On': '更新日',
            'Updated By': 'によって更新',
            'Resend All': 'すべて再送信',
            'Resend After': '後で再送',
        },
        ACCOUNTS: {
            'Status': '状態',
        },
        CONTACTS: {
            'Status': '状態',
        },
        OPPORTUNITIES: {
            'Status': '状態',
        },
        PROJECTS: {
            'Status': '状態',
        },
        ISSUES: {
            'Status': '状態',
        },
        CASES: {
            'Status': '状態',
        },
        SOLUTIONS: {
            'Status': '状態',
        },
        SALES_LEADS: {
            'Status': '状態',
        },
    },
    DEFAULT_NOTE_TYPES: {
        'Appointment': '任命',
        'Dialed': 'ダイヤル',
        'Email': '電子メール',
        'LVM': 'LVM',
        'Note': '注意',
        'Spoke': 'スポーク',
        'Task': '仕事',
        'Transferred': '転送された',
    },
    VIEW_TOGGLE: {
        LIST: 'リスト',
        COLUMN: 'カラム列',
        LIST_TITLE: 'リストビューに切り替える',
        COLUMN_TITLE: '列ビューに切り替える',
        TIMELINE: 'タイムライン',
        TIMELINE_TITLE: 'タイムラインビューに切り替える',
        DETAILS: '詳細',
        DETAILS_TITLE: '詳細ビューに切り替える',
    },
    EMAIL_MARKRTING_REPORT: {
        REPORT_HEADER: '電子メールマーケティングレポート',
        MY_CAMPAIGNS: 'マイキャンペーン',
        MY_LOCATION_CAMPAIGNS: 'マイユニットのキャンペーン',
        RECENT_CAMPAIGNS: '最近のキャンペーン',
        BROADCAST_REPORT: 'ブロードキャストキャンペーンレポート',
        TOUCH_REPORT: 'タッチキャンペーンレポート'
    },
    DOCUMENT_PUSH: {
        DIALOG_TITLE: 'にプッシュ',
        DOCUMENT_NOT_FOUND: '文書が見つかりませんでした。'
    },
    OPERATIONS_REPORT: {
        REQUEST_NEW_REPORT: '新しいレポートを要求する'
    },
    OPERATIONS_CAMPAIGNS: {
        REPORT_HEADER: 'メールキャンペーン',
        BROADCAST_REPORT: 'ブロードキャストキャンペーン',
        TOUCH_REPORT: 'タッチキャンペーン'
    },
    TASKS_REPORT: {
        TASKS_REPORT_LABEL: 'タスクレポート'
    },
    EMAIL_REPORT: {
        EMAIL_REPORT_LABEL: 'メールレポート',
        ERROR_REASON: '理由'
    },
    SMS_REPORT: {
        SMS_REPORT_LABEL: 'SMSレポート'
    },
    BATCH_SMS: {
        MESSAGE: 'あなたのメッセージ',
        SEND: '送る',
        CHARS: ' チャーズ',
        SCHEDULE: 'スケジュール',
        SCHEDULE_SMS: 'スケジュールテキストメッセージ',
        SEND_LATER: '後で送信'
    },
    SETTING_MENU: {
        MARKETING: 'マーケティング',
        SECURITY: 'ユーザーとセキュリティ',
        DATA: 'データ',
        CUSTOMIZATION: 'カスタマイズ',
        INTEGRATION: '統合',
        COMPANY: '会社',
        WORKFLOW_RULES:'ワークフローとルール',
        LISTS: 'リスト',
        FRANCHISE: 'フランチャイズ',
        COMPANY_SCHEDULER: '会社のスケジューラ'
    },
    MARKETING_SETTING: {
        'SOURCE': '出典',
        'LIBRARY': 'としょうかん',
        'LEAD_SCORE': 'リードスコア',
    },
    SECURITY_SETTING: {
        'USERS': 'ユーザー',
        'SECURITY_LEVELS': 'セキュリティレベル'
    },
    DATA_SETTING: {
        'EXPORT': '輸出する',
        'RECYCLE_BIN': 'ごみ箱',
        'FIND_DUPLICATES': '重複を探す'
    },
    CUSTOMIZATION_SETTING: {
        'CHANGE_LOGO': 'ロゴを変更',
        'DATA_BASE': 'データベース',
        'WORKFLOWS': 'ワークフロー',
        'RELATIONS': '関係',
        'AUTO_FILL_VALUES': '自動入力値',
        'NOTE_TYPES': 'タスクとメモの種類',
        'CAMPAIGN_TYPES': 'キャンペーンの種類',
        'CORPORATE_CURRENCY': '企業通貨',
        'HUB_SETTING': 'ハブ',
        'COMPANY_PROFILE': '会社概要',
        'WHITE_LABEL': '白いラベル',
        'MENU': 'メニュー',
        'DESKTOP_MENU': 'デスクトップメニュー',
        'MOBILE_MENU': 'モバイルメニュー',
        'PHONE_NUMBERS': '電話番号',
        'DESIGN_MENUS': 'デザインメニュー',
        'MENU_NAME': 'メニュー名',
    },
    INTEGRATION_SETTING: {
        'MICROSOFT_OUTLOOK': 'Microsoft Outlook（デスクトップ）',
        'GENERATE_API': 'APIを生成',
        'EMAIL_INTEGRATION': '電子メール統合'
    },
    SETTINGS: {
        ADDED: '追加しました',
        EDIT: '更新しました',
        SUCCESSFULL: '成功しました。',
        'SOURCE': 'ソース',
        'NOTETYPES': 'タスクとメモの種類',
        'CAMPAIGNTYPES': 'キャンペーンの種類',
        'CORPORATECURRENCY': '企業通貨',
        'RELATIONS': '関係',
        'LIBRARY': 'としょうかん',
        'USERS': 'ユーザー',
        'SECURITYLEVEL': 'セキュリティレベル',
        'WORKFLOWS': 'ワークフロー',
        'EMAILINTEGRATION': 'メールエイリアス',
        'RECYCLEBIN': 'ごみ箱',
        'ASSIGNMENTRULES': '割り当てルール',
        'APPOINTMENTTYPES': '予定の種類',
        'ROLLUP': 'ロールアップルール',
        'SUBSCRIPTION': '購読',
        'UPGRADE': 'アップグレードの購読',
        'BUYPRODUCT': '購入製品＆トレーニング',
        'BUYPROSERVICE': '購入プロフェッショナルサービス',
        'BILLING': '請求と請求書の詳細',
        'CANCELSUB': '購読をキャンセル',
        'SUBDETAIL': 'サブスクリプションの詳細',
        'PURCHESEMAILCREDIT': '購入メールクレジット',
        'PURCHESPHONECREDIT': '購入携帯電話のクレジット',
        'PURCHESSMSCREDIT': '購入SMSクレジット',
        'PURCHESCARDCREDIT': '購入カードスキャンクレジット',
        'PURCHESCONTACTCREDIT': '複数の連絡先を購入',
        'PURCHESTRANINGCREDIT': '購入トレーニング',
        'PURCHESMERKETINGSERVICE': '購入マーケティングサービス',
        'PURCHESPROFESSONALSERVICE': '購入プロフェッショナルサービス',
        'SETUP': '私の設定',
        SHOW_LOGO: 'ロゴを表示する',
        SHOW_SIGNOUT_LINK: 'サインアウトリンクを表示',
        THEME_COLOR:'テーマカラー',
        AUDIT_QUESTIONS: '監査チェックリスト',
        LMS_USERS: 'LMS ユーザー',
        WEBHOOKS: 'Webhooks',
        },
    CORPORATE_CURRENCY: {
        SELECT_CURRENCY_NAME: '企業通貨を選択してください'
    },
    UNSUBSCRIBE: {
        EMAILS: 'メール',
        TEXTS: 'テキスト',
        BOTHS: '両方'
    },
    HUB_SETUP: {
        SETUP_FIELD_NAME: 'ハブ名',
        USEFUL_LINK: '便利なリンク',
        CASES: '事例',
        SETUP_NAME: 'ハブのセットアップ',
        NEWS: 'ニュース',
        LIBRARY: 'としょうかん',
        COMMUNITY: 'コミュニティ',
        TRAINING: 'トレーニング',
        HYPER_LINK: 'ハイパーリンク',
        SET: 'セット',
    },
    UNITS: {
        TASK_DETAILS: 'タスクの詳細',
        'Unit': '単位',
        'Contact Name': '連絡先の名前',
        'Done': '完成です',
        'Open': '開く',
        'Tasks Name': 'タスク名',
        'Assigned To': 'に割り当てられた',
        'Due By': 'による',
        'Status': '状態',
        UNIT_DETAILS_LABEL: 'ユニットの詳細',
        FIRST_NAME: '名（必須）',
        LAST_NAME: '姓（必須）',
        PASSWORD: 'パスワード  （必須）',
        RETYPE_PASSWORD: 'パスワードの再入力（必須）',
        EMAIL: 'Eメール（必須）',
        PHONE: '電話（必須）',
        COMPANY: '会社（必須）',
        TIME_ZONE_SELECT: 'タイムゾーン',
        COUNTRY_SELECT: '国',
        STATE_SELECT: '状態',
        NO_UNIT_TASKS: 'ユニット所有者に使用できるタスクはありません。',
        NO_ACCOUNT_TASKS: 'アカウントに使用できるタスクはありません。'
    },
    ASSIGNMENT_RULES: {
        SETUP_FIRST_RULE: '最初のルールを設定する',
        ASSIGNMENT_RULE: '割り当てルール',
        ASSIGNMENT_RULE_CREATE_TAB_LABEL: '割り当てルールの作成',
        ASSIGNMENT_RULE_EDIT_TAB_LABEL: '割り当てルールの編集',
        NAME_REQUIRED: '名前 (必須)',
        ASSIGNMENT_RULES: '割り当てルール',
        STATUS: 'ステータス',
        SETUP_RULE: '設定の割り当てルール',
        USE_RULE: '割り当てルールの使用',
        TABLE_NAME: 'テーブル名',
        SELECT_UNIT_WORKFLOW: 'ユニットワークフローの選択',
        WORKFLOW_NAME: 'ワークフロー名',
    },

    COUPONS: {
        PREFIX: '接頭辞',
        'Coupon Name': 'クーポン名',
        'Discount Value': '値引き',
        EXPIRATION_DATE: '有効期限',
        PODUCT_SERVICE: '製品/サービス（オプション）'
    },
    COUPONS_CLAIM_REPORTS: {
        COUPONS_CLAIM_REPORTS_LABEL: 'クーポン請求レポート',
        COUPONS_REPORT: '報告書'
    },
    PIPELINE_REPORT: {
        PIPELINE_REPORT_LABEL: 'パイプライン レポート',
        SALES_SUMMARY_REPORT: '販売概要レポート'
    },
    NOTIFICATION_REMINDERS: {
        NOTIFICATION_REMINDERS: 'アラートと通知',
        NOTIFY_ME: 'いつ通知する ...',
        REMIND_ME: '前に思い出させる ...',
        NOTIFY_EMAIL_OPEN: 'メールが開かれた',
        NOTIFY_EMAIL_CLICK: 'メールがクリックされました',
        NOTIFY_TEXT_REPLY: 'テキストが返信される',
        REMIND_UPCOMING_APPOINTMENT: '今後の予定',
        REMIND_UPCOMING_TASK: '今後のタスク',
        ADD_NEW_REMINDER: '新しいアラームを追加する',
        REMIND: '思い出さ せる',
        DAY_BEFORE: '前の日',
        MY_WEB_FORM_SUBMITTTED: 'Web フォームが送信される',
        NO_DATE_FIELD: '使用できる日付フィールドがありません',
    },
    EMAIL_INTEGRATION: {
        EMAIL: 'メールエイリアス',
    },
    NEWS: {
        HEADER: 'ニュース',
        STATUS: {
            DRAFT: 'ドラフト',
            PUBLISHED: '公開済み'
        },
        EDIT_TAB_LABEL: 'ニュースを編集',
        ADD_TAB_LABEL: 'ニュースを追加',
        CATEGORY_REQUIRED: 'カテゴリー（必須）',
        STATUS_REQUIRED: 'ステータス（必須）',
        TITLE_REQUIRED: 'タイトル（必須）',
        SUMMARY_REQUIRED: '要約（必須）',
        EXTERNAL_LINK_REQUIRED: '外部リンク（必須）',
        UPLOAD_IMAGE: '画像をアップロードする',
        EXTERNAL_LINK: '外部リンク',
        ENTER_DETAILS: '詳細を入力'
    },
    USEFUL_LINKS: {
        USEFUL_LINKS: '便利なリンク',
        CATEGORY: 'カテゴリ',
        LINK: 'リンク',
        TITLE: 'タイトル',
        NO_RECORD_FOUND: '有用なリンクが見つかりません。',
    },
    ORTHOTIC_REPORT: {
        ORTHOTIC_REPORT_LABEL: '装具レポート'
    },
    REPORTS: {
        NO_REPORTS_FOUND: 'レポートが見つかりませんでした。',
    },
    AUTO_FILL_VALUES: {
        SELECT_PARENT: '「親を選択」',
        SELECT_CHILD: '「お子様を選択」',
        PARENT_FIELDS: '親オブジェクトフィールド',
        CHILD_FIELDS: '子オブジェクトフィールド',
    },
    LANDING_PAGE: {
        LANDING_PAGE: 'ランディングページ',
        LANDING_PAGES: 'ランディングページ',
        NAME_REQUIRED: 'お名前（必須）',
        URL_REQUIRED: 'URL（必須）',
        LANDING_PAGE_FORMS: 'フォームビルダー',
        EDIT_LANDING_PAGE: 'ランディングページの編集',
        COPY_LANDING_PAGE: 'リンク先ページをコピー'
    },
    FIND_DUPLICATES: {
        FIND_DUPLICATES: '重複を検索する',
        SELECT_OBJECT: 'オブジェクトの選択',
        SELECT_FIELD: 'フィールドの選択',

    },
    SCHEDULER: {
        APPOINTMENT_SCHEDULER: '予定スケジューラ',
        APPOINTMENT_INFO: '予定情報',
        AVAILABILITY:'可用性',
        APPOINTMENT_TYPES:'予約の種類',
        MY_AVAILABILITY: '自分の可用性',
        INTAKE_FORM: 'インテイクフォーム',
        SCHEDULER_LINK: 'スケジューラーリンク',
        APPOINTMENT_NAME: '予定名 (必須)',
        LOCATION: '場所',
        TASKS:'タスク',
        UNIT_CRM:'ユニットCRM',
        NOTIFICATION_SETUP:'通知の設定',
        DURATION: '期間',
        MIN_ADVANCE_NOTICE: '最低事前通知 ',
        DESCRIPTION: '説明',
        COPY_TO_CLIPBOARD: 'クリップボードにコピーする',
        SCHEDULER_LINK_TITLE: '自分の予定スケジューラ リンク',
        SCHEDULING_PAGE: '予約スケジューラ',
        ADD_BUTTON: '新しいスケジュールを追加する',
        MY_SCHEDULAR_LINK: 'マイスケジューラリンク',
        MY_APPOINTMENT_TYPE: '私の予定の種類',
        APPOINTMENT_TYPE: '予定の種類',
        DEFINE_YOUR_INTAKE_FORM: 'インテークフォームを定義する',
        REQUIRED: '必須',
        ADD_ANOTHER_FIELD: '別のフィールドを追加',
        SUBMIT_BUTTON_LABEL: 'ボタンラベルを送信',
        CHOOSE_A_STATUS_FOR_LEAD: 'スケジューラから追加されたリードのステータスを選択します',
        DEFINE_YOUR_THANK_YOU_MESSAGE: 'ありがとうメッセージを定義する',
        THANK_YOU_MESSAGE: 'ありがとうメッセージ',
    },
    RELATION_REPORT: {
        RELATION_REPORT_LABEL: '関係マトリックス',
        REPORT_SAVE_LABEL: 'カスタマイズを保存',
        REPORT_NAME_LABEL: 'カスタムレポート名',
        REPORT_NAME_TEXTFIELD_LABEL: 'レポート名',
        ROW_FIELD_LABEL: '行フィールド',
        COL_FIELD_LABEL: '列フィールド',
        DATE_FIELD_LABEL: '日付フィールド',
        PDF_LABEL_DURATION: '期間',
        RELATION_FIELD_LABEL: '関係',
        SAVE_CUSTOMIZATION_LABEL: 'カスタマイズを保存',
        RUN_BUTTON_LABEL: '走る'
    },
    COMPANY_PROFILE: {
        UPLOAD_LOGO: 'ロゴをアップロードする',
        WEBSITE: 'ウェブサイト',
        BUSINESS_NAME: '事業名',
        BUSINESS_ADDRESS: '会社住所',
        BUSINESS_PHONE: 'ビジネス用電話',
        BUSINESS_EMAIL: 'ビジネスメール',
        CORPORATE_CURRENCY: '企業通貨'
    },
    EXPORT_SETTINGS: {
        EXPORT_HEADING: '書き出す',
        FILE_NAME_REQUIRED: 'ファイル名（必須）',
        PARENT_OBJECT_REQUIRED: '親オブジェクト名（必須）',
        FILE_TYPE_REQUIRED: 'ファイルの種類（必須）',
        QUERY_NAME_REQUIRED: 'クエリ名（必須）',
        SELECTED_FIELDS_FOR_PARENT: '親の選択されたフィールド',
        AVAILABLE_FIELDS_FOR_PARENT: '親が使用できるフィールド',
        CHILD_OBJECT_NAME: '子オブジェクト名',
        AVAILABLE_FIELDS_FOR_CHILD: '子供が利用できるフィールド',
        SELECTED_FIELDS_FOR_CHILD: '子の選択されたフィールド',
        EXPORT_BUTTON: '書き出す',
        DOWNLOAD_BUTTON: 'ダウンロードファイル',
        ADD: '追加',
        ADD_ALL: '全て追加する',
        REMOVE: '削除する',
        REMOVE_ALL: 'すべて削除する'
    },
    QUEUE: {
        QUEUE: 'キュー',
        PREVIOUS: 'キュー内の前',
        NEXT: 'キューの次',
        REMOVE: 'キューから削除する',
        CLOSE: 'キューを終了する',
    },
    SECURITY_LEVEL: {
        ACCESS: 'アクセス',
        DESCRIPTION: '説明',
        VIEW: '見る',
        PERMISSIONS: '権限',
        OPERATIONS: '操作',
        ADMINISTRATION: '管理',
        ASSIGNED_MENU: '割り当てられたメニュー',
    },
    ROLL_UP: {
        CHILD_OBJECT_NAME: '子オブジェクト',
        PARENT_OBJECT_NAME: '親オブジェクト',
        CHILD_FIELD_NAME: '子供フィールド名',
        PARENT_FIELD_NAME: '親フィールド名',
    },
    ROYALTY_REPORT: {
        ROYALTY_REPORT_LABEL: 'ロイヤリティレポート',
        ROYALTY_PERCENT: 'ロイヤリティ %',
        SHOW: '表示',
        ALL_INVOICES: 'すべての請求書',
        PAID_INVOICE_ONLY: '支払済請求書のみ',
        SETUP_TITLE: 'ロイヤリティの設定',
        PAID_INVOICES: '支払済請求書',
        USE: '使用',
        FIXED: '固定 %',
        VARIABLE: '変数 %',
        TOTAL_INVOICES: '総請求書',
        TOTAL_PAYMENTS: '総支払い',
        CALCULATE: '計算する',
        WEEKLY: '毎週',
        MONTHLY: '毎月',
        WEEK_TYPE: '週のタイプ',
        MON_TO_FRI: '月 - 金',
        MON_TO_SUN: '月 - 太陽',
        ADD_INVOICES: '請求書を追加してください',
        TOTAL_Reported_Sales: '報告された総売上',
        YEAR: '年',
        MARKETING_FEE: 'マーケティング料金',
        TECH_FEE: '技術料',
        PERCENT: 'パーセンテージ',
        FIXED_VALUE: '修理済み',
        WITH: '料金付き',
        WITH_OUT: '料金なし',
        Reported_Sales_Franchisee: '報告された販売 - フランチャイズ',
        Reported_Sales_Franchisor: '報告された売上 - フランチャイザー',
        Reported_Sales_ThirdParty: '報告された販売 - サード パーティ',
    },
    POWER_CALLING: {
      NOTE_AND_CALL_SCRIPTS: 'メモと呼び出しスクリプト',
      SEND: '送信',
      LOG_CALL:'ログコール',
      CALLER_ID: '発信者ID',
      END_CALL: '通話終了',
      MOBILE: 'モバイル',
      PHONE: '電話',
      HOME_PHONE:'自宅の電話',
      NEXT: '次',
      COMPLETE: 'コンプリート',
      OF: 'の',
      POWER_DIALER: 'パワーダイヤラ'
    },
    LEAD_SCORE_SETUP:{ 
        SCORES: 'スコア',
        NO_SCORES_AVAILABLE: '利用可能なアクションはありません。',
        OBJECT: 'オブジェクト',
        GROUPS:'グループ',
        WORKFLOW_NAME: 'ワークフロー名',
        CONVERTED_FROM:'からの変換',
        CONVERTED_TO: 'に変換',
        FIELDS_NAME: 'フィールド名',
        POINTS: 'ポイント',
        EXPIRES_BY: '有効期限',
        APPLY_TO_EXISTING_SCORE: '*有効期限は既存のスコアにさかのぼって適用されます。',
        GROUP_ALREADY_REMOVE: 'グループはすでに削除されています。 保存する前に変更してください。',
        FIELD_ALREADY_REMOVE: 'フィールドはすでに削除されています。 保存する前に変更してください。',
        FIELD_VALUE_ALREADY_REMOVE:'フィールド値はすでに削除されています。 保存する前に変更してください。',
        AND_ABOVE: '以上',
        LESS_THAN: '未満',
    },
    DUPLICATE_RULE: {
        DUPLICATE_RULE: '重複するルール',
        DUPLICATE_RULE_HEAD_LABEL: '使用して重複をチェック',
        SELECT_OBJECT_LABEL: 'オブジェクトを選択',
        ACTIVE: 'アクティブ',
        DEFAULT: 'デフォルト',
        ONE_FIELD: '一つのフィールド',
        TWO_FIELD: '二つのフィールド',
        THREE_FIELD: '三つのフィールド',
        FIELD_ONE: 'フィールド1',
        FIELD_TWO: 'フィールド2',
        FIELD_THREE: 'フィールド3',
        SELECT_FIELD_LABEL: '選択フィールド',
        CHECK_DUPLICATES: '重複をチェック',
        SELECT_RULES: 'ルールを選択',
    },
    JOBS:{
        RESCHEDULE: 'スケジュールを変更する',
        OMW:'omw',
        INVOICE:'請求書',
        PAY:'支払う'
    },
    TASK_REPORT:{
        TITLE:'タスクレポート',
        UNITS:'ユニット',
        NO_TASK_FOUND:'タスクが見つかりません',
    },
    AUDIT_TASK_REPORT_DETAILS:{
        TASK_REPORT_DETAILS:'タスクレポートの詳細',
    },
    AUDIT_QUESTIONS: {
        MINIMUM_OPTIONS: 'この質問に少なくとも2つの選択肢がなければならないはずです',
        SINGLE_RECORD: '一度に移動することが許可されています。',
        AUDIT_QUESTIONS: "監査チェックリスト",
        GIVE_FEEDBACK:'フィードバックを与えます',
        NEW_QUESTION: '新しい質問',
        TEXT: '短いテキスト',
        COMMENT: '長いテキスト',
        LIST: '単一選択（ドロップダウン）',
        CHECKBOX: '複数選択（チェックボックス）',
        QUESTION: '質問',
        ADD_NEW: '新しく追加する',
        ADD_NEW_CATEGORY:'Add a New Category',
        SINGLE_RECORD_EDIT: '編集するには1レコードを選択してください。',
        MESSAGE_DELETE: 'あなたは確かに削除したいです',
        SELECTED_RECORDS: '選択されたレコード？',
        YOUR_ANSWER: "あなたの答え",
        SUBMITTING: '出金',
        SAVE_CATEGORY:'カテゴリを保存',
        SUBMIT_AUDIT:'監査を送信する',
        SUBMIT: '送信',
        SCORE: 'スコア',
        REQUIRED: '必須',
        CHOICE: '選択',
        FIELD_REQUIRED: 'この項目は必須です *',
        NEW_CHOICE: '新しい選択',
        FREE_FORM: '自由な形の',
        SCORE_HELP_TOOLTIP: "回答にポイントまたはスコアを割り当てます。スコアは監査の合計スコアを計算するために追加されます。",
        SURVEY_LINK: "監査/調査リンク",
        NEXT_CATEGORY:'次のカテゴリー',
        GENERATE:'生成する',
        FIELD_AUDIT:'現場監査（フランチャイザーによる監査）',
        SELF_AUDIT:'自己監査（フランチャイジーによる監査）',
        GENERATE_AUDIT_LINK:'チェックリストを選択',
        REQUIRES_PHOTO: '写真が必要',
        ENABLE_NOTE: 'メモを有効にする',
        ADD_PHOTO: '写真',
        UPDATE_PHOTO: 'アップデート',
        NOTE: 'メモを追加',
        PHOTO_REQUIRED: '写真は必須です *',
    },
    AUDIT_ANSWERS: {
        AUDIT_ANSWERS_LABEL: '監査回答',
        QUESTION:'質問',
        SCORE: 'スコア',
        ANSWER: '答え',
        SUBMIT_CORRECTIVE_ACTIONS:'是正措置を提出する',
        REQUEST_CORRECTIVE_ACTIONS:'是正措置の要求',
        INSERT_PHOTO:'写真を挿入',
    },
    AUDIT_WEAKNESS:{
        CATEGORY:'カテゴリー',
        MIN_FAILURE_COUNT:'最小失敗数',
    },
    AUDIT_CATEGORY:{
        ADD_NEW_CATEGORY:'新しいカテゴリーを追加',
        MANAGE_CATEGORY: 'カテゴリの管理',
        CATEGORIES:'カテゴリー',
        CATEGORY_QUESTIONS_LABLE:' カテゴリーの質問',
    },
    AUDIT_CHECKLISTS:{
        TITLE:'新しい監査チェックリスト',
        EDIT_TITLE:'監査チェックリストを編集する',
    },
    UNIT_CRM:{
        MY_SETTINGS:'私の設定',
        COMPANY:'会社',
        USER_AND_SECURITY:'ユーザーとセキュリティ',
        SURVEYS: '調査',
        WORKFLOW_RULES_SECTION:'ワークフローとルールのセクション',
        EMAIL_ALIASES:'メールエイリアス',
        CUSTOMIZATION:'カスタマイズ',
        LISTS:'リスト',
        DATA:'データ',
        COMPANY_SCHEDULER:'会社スケジューラー',
        FRANCHISE:'フランチャイズ',
        INTEGRATION:'統合',
        SUBSCRIPTION:'サブスクリプション',
        CAMPAIGNS:'キャンペーン',
        BOARDCAST_CAMPAIGNS :'放送キャンペーン',
        AUTOMATED_CAMPAIGNS:'自動キャンペーン',
    },
    COMPLIANCE_REPORT:{
        COMPLIANCE_REPORT_LABEL: 'コンプライアンスレポート',
    },
    SALES_AND_REFUND: {
        PERIOD:'期間',
        START_DATE:'開始日',
        END_DATE :'終了日',
        BASED_ON :'に基づく',
        SALES_AND_REFUND_TITLE:'販売と払い戻し',
        SALES_AND_REFUND_DETAIL:'販売と払い戻しの詳細',
    },
    SALES_AND_REFUND_DETAILS_REPORT:{
        SALES_AND_REFUND_DETAILS :'販売および返金レポートの詳細',
        START_DATE:'開始日',
        END_DATE:'終了日',
        BASED_ON:'に基づく',
        NO_RECORD_EXPORT: 'レコードが見つかりませんでした'
    },
    TOTAL_REPORTED_SALES_DRILLDOWN:{
        TOTAL_REPORTED_SALES_DRILLDOWN:'ロイヤルティ報告売上のドリルダウン',
    },
    OTHER_FORM_DATA: {
        CARETAKING:'Caretaking',
        EXPLANATION:'Explanation',
        EXPLANATION_HISTORY_TIMELINE:'Explanation of history/timeline (dates/places/etc)',
        PEOPLE_PLEASING:'People Pleasing',
        RELATIONSHIPS:'Relationships',
        PERFECTIONISM:'Perfectionism',
        CONTROL:'Control',
        WORKAHOLISM:'Workaholism',
        SHOPPING_SPENDING:'Shopping/Spending',
        RELIGION_OR_ABUSE:'Religion/Abuse',
        RISK_TAKING:'Risk Taking',
        EXCESSIVE_SCREEN_TIME:'Excessive Screen time',
        DEPRESSION:'Depression',
        ANXIETY:'Anxiety',
        ANGER_OR_RAGE:'Anger/Rage',
        SELF_EXTEEM:'Self Esteem',
        PAINFUL_OR_TROMATIC :'Describe any painful/traumatic/memorable experiences or events that have been impactful in your life (trauma)',
        MOTHER:'Mother',
        FATHER:'Father',
        SIBLINGS:'Siblings',
        CHILDREN:'Children',
        SIG_OTHER:'Sig. Other',
        OTHER:'Other',
        PRESENTING_PROBLEM:'Describe the main presenting problem or current crisis/goal for coming to TBR',
        ALCOHAL:'Alcohol',
        HOW_MUCH:'How Much & What type?',
        HOW_OFTEN:'How often, and for how long?',
        DATE_LAST_USED:'Date Last Used',
        COCAINE_OR_CRACK :'Cocaine/Crack',
        HEROINE:'Heroin',
        MARIJUANA:'Marijuana',
        METHADONE_OR_SUBOXONE :'Methadone/Suboxone',
        AMPHETAMINES :'Amphetamines & Stimulants (Meth, Adderall, Speed)',
        NARCOTICS_OR_OPIATES:'Narcotics/Opiates',
        BENZODIAZEPINES:'Benzodiazepines',
        TOBACCO:'Tobacco',
        OTHER_ETC:'Other (Hallucinogenic, OTC, Synthesis, etc)',
        WITHDRAWEL_SYMPTOMS :'Withdrawal Symptoms (shaking, sweating, tremors, seizures etc)',
        MEDICAL_DETOX:'Medical Detox',
        PRIMARY_TRATMENT_OR_IOP:'Primary Treatment/IOP',
        SUCIDE_THOUGHT:'Had suicidal Thoughts?',
        ENGAGED_IN_SELF_HARD:'Engaged in Self Hard',
        ATTEMPED_SUCIDE:'Attempted Suicide While under the influence?',
        ENGAGED_IN_CUTTING:'Engaged in "cutting" behaviors',
        ATTEMPED_SUCIDE_WHITE_SOBER:'Attempted suicide while Sober?',
        ENGEGED_IN_SCORINCATION :'Engaged in "scorincation"' ,
        HOSPITALIZED_FOR_A_SUICIDE_ATTEMPT:'Been hospitalized for a suicide attempt?',
        ENGEGED_IN_BODY_MUTATION:'Engaged in any other body mutation bx',
        HISTORY_EXPLANATION:'If history, explanation (use this space to provide further details, including dates, current ideation, thoughts or plans, etc.)',
        PHYCHIATRIC_DIANOSIS:'Psychiatric diagnosis',
        DATES_DIAGNOSIS_TREATMENT:'Date, diagnosis, treatment',
        MENTAL_BREAKDOWNS_OR_HOSPITALIZATIONS:'Mental Breakdowns/Hospitalizations' ,
        ANOREXIA:'Anorexia',
        TREATMENT_HISTORY:'Treatment History',
        PERIODS_OF_ABSTINENCE:'Periods of Abstinence',
        LAST_EPISODE:'Last Episode',
        LOWEST_WEIGHT:'Lowest Weight',
        HIGHEST_WEIGHT:'Highest Weight',
        CURRENT_WEIGHT_OR_HEIGHT: 'Current Weight/Height',
        HOW_LONG_AT_CURRENT_WEIGHT:'How Long at Current Weight',
        BULIMIA:'Bulimia',
        OVEREATING:'Overeating',
        EXCESSING_EXCERCISING:'Excessive Exercising',
        DETAILS_SOBRIETY_LENGTH_PATTERNS:'If history, explanation (use this space to provide further details, sobriety length, patterns etc.)',
        BETTING:'Betting',
        ONSET:'Onset',
        TREATMENT:'Treatment',
        AMOUNT_OF_DEBT_ACCUMULAATED:'Amount of Debt accumulated',
        TIME_PER_DAY_OR_WEEK_SPENT_GAMBLING:'Time per day/week spent gambling',
        GAMING_OR_CASINOS :'Gaming/Casinos',
        ONLINE_GAMES_OR_APPS:'Online Games/Apps' ,
        PORNOGRAPHY:'Pornography',
        FIRED:'Have you ever been fired or had job threatened because of behavior?',
        EXCESSIVE_MASTURBATION:'Excessive Masturbation',
        VOYEURISM_OR_EXHIBITIONIST:'Voyeurism/Exhibitionist',
        ANONYMOUS_SEX:'Anonymous Sex (casual sex, one-night stands, hookup apps/websites)',
        PROSTITUTION:'Prostitution',
        AFFAIRS:'Affairs (Physical/Emotional)',
        SERIAL_RELATIONSHIP:'Serial Relationships',
        INCLUDE_DATE_TEASON_OR_CHARGE:'Include Date, Reason/Charge (What was the charge/reason for arrest), Result (was there a conviction, jail time served, probation/parole, sex offender registration etc), Current Pending/Legal Obligations',
        CODEPENDENCY_AND_PROCESS_ADDITIONS_BEHAVIOURS:'Codependency and process addictions behaviors ("C" for Current, "D" Denies or "H" for History)',
        RELATIONSHIP_AND_FAMILY_HISTORY:'Relationship/family history (List current relationship status and family history of addiction/psych issues)',
        SUBSTANCE_HISTORY:'Substance history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUBSTANCE_TREATMENT_HISTORY:'Substance treatment history - For Applicable items use "C" for Current, "D" Denies or "H" for History of use',
        SUICIDIAL_IDEATION:'Suicidal ideation / self harm behaviors ("C" for Current, "D" Denies or "H" for History)',
        MENTAL_HEALTH_AND_PSHYATRATIC_DIAGNOSIS_HISTORY :'Mental health/ psychiatric diagnosis history- ("C" for Current, "D" Denies or "H" for History)',
        EATING_DISORDER_BEHAVIER:'Eating disorder behaviors - ("C" for Current, "D" Denies or "H" for History)',
        GAMBLIMG_BEHAVIER:'Gambling behaviors - ("C" for Current, "D" Denies or "H" for History)',
        SEXUAL_RELATIONSHIP_LOVE_BEHAVIOUR:'Sexual/relationship/love behaviors- ("C" for Current, "D" Denies or "H" for History)',
        ARRESTED_HISTORY:'Arrest history (including prior/current and pending)',
    },
    PHONE_NUMBERS: {
        USE_NOTIFICATION_TOOLTIP: '通知に使用する',
        REMOVE_NOTIFICATION_TOOLTIP: '通知のために削除',
    },
    PHONE_AND_TEXT_INTEGRATION: {
        MANAGE_UNIT_PHONE: 'ユニットの電話番号、テキスト クレジット、通話時間を管理します',
    },
}
export default label
