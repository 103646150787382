import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ListView from './ListView';
import ShowCircularProgress from '../components/circularProgress';
import { styles } from '../../../services/constants/styles';
import { getAppDialog } from '../../../services/actions/appContainerActions';
import { getListViewData } from '../../../services/actions/listViewAcions';
import { isValidParam, getStringParam, getObjectParam, getBooleanParam, getArrayParam, getIntParam } from '../../../services/helper/parameterVerifier';
import { constants } from '../../../services/constants/constants';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { useDispatch, useSelector } from "react-redux";
import { getActiveTab } from '../../../services/helper/sfTabManager';
import { LinkToType } from '../../../services/constants/link';
import { getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import * as sfDialogs from '../components/sfDialogs';
import {
    updateActiveTab,
    updateTabInfo,
} from '../../../services/helper/sfTabManager';
const Lookup = ({ object }) => {
    const dispatch = useDispatch()
    const appContainer = useSelector((state) => state.appContainer)
    const selectedModuleName = useSelector((state) => state.selectedModuleName)
    const header = useSelector((state) => state.header)
    const listView = useSelector((state) => state.listView);

    const [data, setData] = useState({ mounted: false, data: null });
    const [stateObject, setStateObject] = useState('');
    const [lookupInfo, setLookupInfo] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [startIndex, setStartIndex] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [isFindAndLink, setIsFindAndLink] = useState(false);
    const [sortFieldName, setSortFieldName] = useState('');
    const [sortType, setSortType] = useState('');
    const [stateFieldName, setStateFieldName] = useState('');
    const [selectedRows, setSelectedRows] = useState('');
    const [tableChecker, setTableChecker] = useState(false);

    useEffect(() => {

        let data = getObjectParam(appContainer.dialogProps.data);
        let fieldName = getStringParam(data.fieldName).trim();
        let tempObject;
        let _searchText;
        if (data.hasOwnProperty("isLookupField") && data.isLookupField && data.lookupObject === constants.PRODUCTS_OBJECT && data.lookupFieldName === 'product_name' && appContainer.drawerProps.data !== null && appContainer.drawerProps.data.hasOwnProperty('object') && appContainer.drawerProps.data.object === constants.OPPORTUNITIES_OBJECT) {
            _searchText = '';
        } else if (data.hasOwnProperty("isLookupField") && data.isLookupField && data.lookupObject === constants.PRODUCTS_OBJECT && data.lookupFieldName === 'product_name' && appContainer.customDrawerProps.data !== null && appContainer.customDrawerProps.data.hasOwnProperty('object') && appContainer.customDrawerProps.data.object === constants.OPPORTUNITIES_OBJECT && appContainer.customDrawerProps.actionType === 'Convert') {
            _searchText = '';
        } else {
            _searchText = getStringParam(data.searchText).trim();
        }

        let isLookupField = getBooleanParam(data.isLookupField);
        let isFindAndLink = getBooleanParam(data.isFindAndLink);

        if (isFindAndLink) {
            setIsFindAndLink(isFindAndLink)
            setStateObject(getStringParam(data.findAndLinkObject))
            tempObject = getStringParam(data.findAndLinkObject);
            setStateFieldName(fieldName)
        } else if (isLookupField) {
            tempObject = getStringParam(data.lookupObject).trim();
            fieldName = data.lookupFieldName;
            setStateObject(tempObject)
            setStateFieldName(fieldName)
        } else {
            setStateObject(tempObject)
            setStateFieldName(fieldName)
        }

        let searchValue = _searchText === '' ? '*' : _searchText;
        setSearchText(searchValue)
        let params = {};
        params.search_field_name = fieldName;
        params.search_text = _searchText;
        params.start_index = startIndex;
        params.page_size = pageSize;
        if (data.hasOwnProperty("click_form") && data.click_form === 'appointmentForm') {
            params.click_form = 'appointmentForm';
        }
        getLookupListViewData(tempObject, params);
    }, [object, appContainer])

    const getLookupListViewData = (object, params) => {
        let _params = params;
        if (selectedModuleName === constants.MODULE_OPERATIONS && object === constants.ACCOUNTS_OBJECT && header.selectedTabObject !== constants.JOBS_OBJECT && header.selectedTabObject !== constants.CASES_OBJECT) {
            _params = { ..._params, query_name: 'Units', query_type: 'query' }
        } else {
            _params = { ..._params, query_name: constants.SEARCH_QUERY_NAME, query_type: constants.SEARCH_QUERY_TYPE_ALL }
        }

        if (_params.hasOwnProperty('search_field_name')) {
            setStateFieldName(_params.search_field_name);
        } else {
            _params.search_field_name = stateFieldName;
        }

        if (_params.hasOwnProperty('search_text')) {
            setSearchText(_params.search_text)
        } else {
            _params.search_text = searchText;
        }

        if (_params.hasOwnProperty('start_index') && _params.hasOwnProperty('page_size')) {
            setStartIndex(_params.start_index);
            setPageSize(_params.page_size);
        } else {
            _params.start_index = 0;
            _params.page_size = pageSize;
        }

        if (isValidParam(_params.sort_field_name) && isValidParam(_params.sort_type)) {
            let sort_field_name = getStringParam(_params.sort_field_name);
            let sort_type = getStringParam(_params.sort_type);
            setSortFieldName(sort_field_name)
            setSortType(sort_type)
        } else {
            _params = { ..._params, sort_field_name: sortFieldName, sort_type: sortType }
        }

        _params = { ..._params, is_lookup: true }

        if (getStringParam(_params.search_field_name) === 'product_name') {
            let param_sql = 'product_type != "Royalty"';
            _params = { ..._params, input_param_sql: param_sql }

        }
        if (object === 'units' && header.selectedTabObject == 'projects' && appContainer.dialogProps?.data?.fieldName == "opportunity_name") {
            object = "opportunities";
            setStateObject(object)
        }
        if (object === constants.PARTNERS_OBJECT) {
            _params = { ..._params, query_name: "Partners" }
        }
        let promise = Promise.resolve(getListViewData(object, _params, true));
        if (isValidParam(promise)) {
            promise.then((response) => {
                let data = { mounted: true, data: response, info: _params.info };
                if (appContainer.dialogProps.data?.hasOwnProperty("isFindAndLink") && !_params?.search_field_name) {
                    setData({ mounted: true, data: {}, info: _params.info })
                } else {
                    setData(data)
                }

            });
        }
    }

    const getLookupSelectedRecord = (selectedRows) => {
        setSelectedRows(selectedRows)
        try {
            let objectName = getStringParam(object);
            if (objectName.length > 0) {
                objectName = objectName.charAt(0).toUpperCase() + objectName.slice(1);
            }

            let _selectedRows = getArrayParam(selectedRows);
            let _data = getObjectParam(appContainer.dialogProps.data);
            if (getBooleanParam(_data.isFindAndLink)) {
                let lookUpInfo = {};
                lookUpInfo.lookup_object_name = objectName;
                lookUpInfo.lookup_field_name = stateFieldName;

                let arrLookupRecordInfo = [];

                _selectedRows.forEach((record) => {
                    if (isValidParam(record)) {
                        let recordObj = {};
                        recordObj.record_id = getIntParam(record.id);
                        recordObj.title = getStringParam(record.title);
                        arrLookupRecordInfo.push(recordObj)
                    }
                });

                lookUpInfo.arrLookupRecordInfo = arrLookupRecordInfo;;
                setLookupInfo(lookUpInfo)
            } else if (_data.hasOwnProperty("isLookupField") && _data.isLookupField && _data.lookupObject === constants.PRODUCTS_OBJECT && _data.lookupFieldName === 'product_name' && appContainer.drawerProps.data !== null && appContainer.drawerProps.data.hasOwnProperty('object') && appContainer.drawerProps.data.object === constants.OPPORTUNITIES_OBJECT) {
                let lookUpInfo = {};
                if (getBooleanParam(_data.isLookupField)) {
                    lookUpInfo.is_lookup_field = true;
                    lookUpInfo.field_name = getStringParam(_data.fieldName).trim();
                    lookUpInfo.lookup_object_name = getStringParam(_data.lookupObject).trim();
                    lookUpInfo.lookup_field_name = getStringParam(_data.lookupFieldName).trim();
                } else {
                    lookUpInfo.lookup_object_name = objectName;
                    lookUpInfo.lookup_field_name = stateFieldName;
                }
                let records = appContainer.dialogProps.data.searchText;
                if (records == '') {
                    records = _selectedRows.map((f, i) => {
                        let obj = {};
                        obj.id = f.id;
                        obj.title = f.title;
                        return obj;
                    });
                } else {
                    records = records.map((f, i) => {
                        let obj = {};
                        obj.id = f.id;
                        obj.title = f.name;
                        return obj;
                    });
                }


                let newArray = []
                newArray = [...selectedRows, ...getArrayParam(records)];
                let uniqueArray = [];
                for (let i = 0; i < newArray.length; i++) {
                    let index = uniqueArray.find(x => x.id === newArray[i].id)
                    if (index === undefined) {
                        uniqueArray.push(newArray[i]);
                    }
                }

                let arrLookupRecordInfo = [];

                uniqueArray.forEach((record) => {
                    if (isValidParam(record)) {
                        let recordObj = {};
                        recordObj.record_id = getIntParam(record.id);
                        recordObj.title = getStringParam(record.title);
                        arrLookupRecordInfo.push(recordObj)
                    }
                });

                lookUpInfo.arrLookupRecordInfo = arrLookupRecordInfo;;
                setLookupInfo(lookUpInfo)

            } else if (_data.hasOwnProperty("isLookupField") && _data.isLookupField && _data.lookupObject === constants.PRODUCTS_OBJECT && _data.lookupFieldName === 'product_name' && appContainer.customDrawerProps.data !== null && appContainer.customDrawerProps.data.hasOwnProperty('object') && appContainer.customDrawerProps.data.object === constants.OPPORTUNITIES_OBJECT && appContainer.customDrawerProps.actionType === 'Convert') {
                let lookUpInfo = {};
                if (getBooleanParam(_data.isLookupField)) {
                    lookUpInfo.is_lookup_field = true;
                    lookUpInfo.field_name = getStringParam(_data.fieldName).trim();
                    lookUpInfo.lookup_object_name = getStringParam(_data.lookupObject).trim();
                    lookUpInfo.lookup_field_name = getStringParam(_data.lookupFieldName).trim();
                } else {
                    lookUpInfo.lookup_object_name = objectName;
                    lookUpInfo.lookup_field_name = stateFieldName;
                }
                let records = appContainer.dialogProps.data.searchText;
                if (records == '') {
                    records = _selectedRows.map((f, i) => {
                        let obj = {};
                        obj.id = f.id;
                        obj.title = f.title;
                        return obj;
                    });
                } else {
                    records = records.map((f, i) => {
                        let obj = {};
                        obj.id = f.id;
                        obj.title = f.name;
                        return obj;
                    });
                }


                let newArray = []
                newArray = [...selectedRows, ...getArrayParam(records)];
                let uniqueArray = [];
                for (let i = 0; i < newArray.length; i++) {
                    let index = uniqueArray.find(x => x.id === newArray[i].id)
                    if (index === undefined) {
                        uniqueArray.push(newArray[i]);
                    }
                }

                let arrLookupRecordInfo = [];

                uniqueArray.forEach((record) => {
                    if (isValidParam(record)) {
                        let recordObj = {};
                        recordObj.record_id = getIntParam(record.id);
                        recordObj.title = getStringParam(record.title);
                        arrLookupRecordInfo.push(recordObj)
                    }
                });

                lookUpInfo.arrLookupRecordInfo = arrLookupRecordInfo;;
                setLookupInfo(lookUpInfo)
            } else if (selectedRows.length === 1) {
                let lookUpInfo = {};
                if (getBooleanParam(_data.isLookupField)) {
                    lookUpInfo.is_lookup_field = true;
                    lookUpInfo.field_name = getStringParam(_data.fieldName).trim();
                    lookUpInfo.lookup_object_name = getStringParam(_data.lookupObject).trim();
                    lookUpInfo.lookup_field_name = getStringParam(_data.lookupFieldName).trim();
                } else {
                    lookUpInfo.lookup_object_name = objectName;
                    lookUpInfo.lookup_field_name = stateFieldName;
                }

                lookUpInfo.record_id = selectedRows[0].id;
                lookUpInfo.title = selectedRows[0].title;
                setLookupInfo(lookUpInfo)
            }
        } catch (error) {
            console.error("Error in 'lookup.js -> getLookupSelectedRecord()':" + error);
        }
    }
    const openMailDrawer = (_lookupInfo, _selectedRows) => {
        let _selectedIds = []
        _selectedRows.map((rec) => {
            _selectedIds.push(rec.id)
        })
        let object = 'partners';

        let labelName = null;
        let isEmail = true;
        let recordId = 0;
        if (_selectedIds.length === 1) {
            let endPoint = "";
            let params = {};

            recordId = _selectedIds[0];

            endPoint = endPoints.PARTNERS.MAP_DATA;
            params = { "id": recordId, "fields": ["company", "first_name", "last_name", "email"] };


            const promise = Promise.resolve(HTTPClient.get(endPoint, params));
            if (isValidParam(promise)) {
                promise.then(response => {
                    if (isValidParam(response)) {
                        let records = response.records;
                        let firstName = "";
                        let lastName = "";
                        let fullName = "";
                        let company = "";
                        let email = "";
                        let isFullName = false;
                        if (records) {
                            if (records.hasOwnProperty('first_name')) {
                                firstName = records.first_name;
                            }
                            if (records.hasOwnProperty('last_name')) {
                                lastName = records.last_name;
                            }
                            if (records.hasOwnProperty('company')) {
                                company = records.company;
                            }
                            if (records.hasOwnProperty('email')) {
                                email = records.email;
                            }
                        }

                        if (getStringParam(email) !== '') {
                            if (firstName && firstName !== "" && firstName !== undefined && lastName && lastName !== "" && lastName !== undefined) {
                                fullName = firstName + " " + lastName;
                            }
                            if (firstName && firstName !== "" && firstName !== undefined && (!lastName || lastName === "" || lastName === undefined)) {
                                fullName = firstName;
                            }

                            if ((!firstName || firstName === "" || firstName === undefined) && lastName && lastName !== "" && lastName !== undefined) {
                                fullName = lastName;
                            }

                            if (!fullName || fullName === "" || fullName === undefined) {
                                fullName = company;
                            }

                            if (!fullName || fullName === "" || fullName === undefined) {
                                fullName = email;
                            }

                            if (!fullName || fullName === "" || fullName === undefined) {
                                fullName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                                isEmail = false;
                            }

                            if (fullName && fullName !== "" && fullName !== undefined) {
                                if (isEmail) {
                                    labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + fullName + "";
                                } else if (!isEmail && !isFullName) {
                                    labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                                }
                            }


                        } else{
                            labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                        }
                        openSendEmailDialog(object, _selectedRows, _selectedIds, labelName, firstName, records);


                    }
                });
            }



        } else {
            labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
            openSendEmailDialog(object, _selectedRows, _selectedIds, labelName);
        }

    }

    const openSendEmailDialog = (object, _selectedRows, _selectedIds, labelName, firstName, records) => {
        let listViewRecords = listView.data.records;
        let batchMailObjlist = [];

        _selectedIds.forEach(id => {
            let batchMailObj = {};
            let temprec = _selectedRows.filter(f => {
                return f.id === id
            });
            if (temprec.length == 0 && records != undefined && records != null) {
                temprec = [records];
            }
            batchMailObj.id = id;
            batchMailObj.email = temprec[0].email;
            batchMailObjlist.push(batchMailObj);
            batchMailObj = null;
        });

        let accountId = appContainer.dialogProps.data?.accountsRecordId
        let data = {};
        data.email_id_list = batchMailObjlist
        data.object = object;
        data.batch_mail = _selectedIds.length > 1;
        data.type = constants.TYPE_EMAIL;
        data.firstName = firstName;
        data.fieldName = 'email';
        data.is_share_partner = true;
        data.accountRecordId = accountId;

        let style = {};
        style.width = '90%';

        data = { ...data, email_id_list: [...batchMailObjlist] }
        dispatch(getAppCustomDrawer(true, labelName, LinkToType.TYPE_EMAIL, style, data, null, true));
        handleClose();
    }

    const handleLookUpInfo = (lookupInfo, selectedRows) => {
        try {
            if ((!isValidParam(selectedRows) && !isValidParam(lookupInfo)) || getArrayParam(selectedRows).length === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.LOOKUP_SELECT_A_RECORD, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            } else if (isValidParam(selectedRows) && getArrayParam(selectedRows).length > 0 && appContainer.dialogProps?.data?.actionObject == 'partners') {
                openMailDrawer(lookupInfo, selectedRows)
            }
            else if (isValidParam(selectedRows) && getArrayParam(selectedRows).length > 1 && !appContainer.dialogProps.data.hasOwnProperty("isFindAndLink")) {
                if ((appContainer.drawerProps.data !== null && appContainer.drawerProps.data.hasOwnProperty('object') && appContainer.drawerProps.data.object === constants.OPPORTUNITIES_OBJECT && appContainer.dialogProps.data.lookupObject === constants.PRODUCTS_OBJECT)) {
                    appContainer.dialogProps.eventName(lookupInfo);
                } else if (appContainer.dialogProps.data.fieldName === 'product' && appContainer.customDrawerProps.data.object === constants.OPPORTUNITIES_OBJECT && appContainer.customDrawerProps.actionType === 'Convert') {
                    appContainer.dialogProps.eventName(lookupInfo);
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.LOOKUP_MULTI_SELECT, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }

            } else if (isValidParam(selectedRows) && appContainer.dialogProps.data.hasOwnProperty("isFindAndLink") && appContainer.dialogProps.data.isFindAndLink) {
                appContainer.dialogProps.eventName(lookupInfo)
            } else {
                appContainer.dialogProps.eventName(lookupInfo)
            }
        } catch (error) {
            console.error("Error in 'Lookup.js -> handleLookUpInfo()'" + error)
        }
    }

    const getActions = () => {
        const actions = [
            <Button
                primary={true}
                onClick={() => handleLookUpInfo(lookupInfo, selectedRows)}
                style={{ ...styles.primaryButton }}
            >{getLocalizedStrings().label.COMMON.SELECT}</Button>,
            <Button

                primary={true}
                onClick={handleClose}
                style={styles.secondaryButton}
            >{getLocalizedStrings().label.COMMON.CLOSE}</Button>,

        ];
        if (data?.data?.records?.length > 0) {

            return actions;
        }
        else {
            return actions.slice(1, 2);
        }
    }

    const handleClose = () => {
        dispatch(getAppDialog(false, null, null, null, null, null));
    }

    let pageHeight = 300;
    let top = (pageHeight - 10) / 2;
    if (data.mounted) {
        return <div className="lookuppag">
            <div>
                <ListView object={stateObject} isLookup={true} data={data} getLookupListViewData={getLookupListViewData} getLookupSelectedRecord={getLookupSelectedRecord} isFindAndLink={isFindAndLink} setTableChecker={setTableChecker} />
            </div>
            <div style={{ marginTop: 15, float: 'right' }}>
                {getActions()}
            </div>
        </div>;
    } else {
        return <div style={{ width: '100%', height: pageHeight }}>
            <div style={styles.loaderContainer}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div></div>
    }
}

export default Lookup;

