
import React, { useState, useEffect } from 'react';
import { Select, MenuItem, InputLabel, Typography } from '@mui/material';
import * as HTTPClient from '../../../services/helper/httpClient';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import moment from 'moment';
import { getArrayParam } from '../../../services/helper/parameterVerifier';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { styles } from '../../../services/constants/styles';
import { getMergeRecords, saveMergeRecords } from '../../../services/actions/batchActions';
import { constants } from '../../../services/constants/constants';
import { Checkbox, ListItemText } from '@mui/material';
import { isValidParam, isInteger, isFloat, isEmail, isUrl, getObjectParam, getStringParam, getBooleanParam } from '../../../services/helper/parameterVerifier';
import { getEndPointObject } from '../../../services/actions/sfFormActions';
import * as sfDialogs from '../components/sfDialogs';
import ShowCircularProgress from '../components/circularProgress';
import { dateFormatFlatPicker, dateTimeFormatFlatPicker, dateTimeFormat, dateFormat, timeFormat, timeZone, phoneFormat } from '../../../services/helper/utils';
import { getActiveTabInfo } from '../../../services/helper/sfTabManager';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { getRecentRecord } from '../../../services/helper/common';
import PopOver from './PopOver';
import { useDispatch, useSelector } from "react-redux";
import { refreshListView } from '../../../services/actions/listViewAcions';
import { getActiveTab } from '../../../services/helper/sfTabManager';
import { updateActiveTab } from '../../../services/helper/sfTabManager';
import { endPoints } from '../../../services/constants/endPoints';
import * as SFFormActions from '../../../services/actions/sfFormActions';


const getListViewInstanceFromRedux = (state) => {
    let tabInfo = getActiveTabInfo();
    let isReport = getBooleanParam(tabInfo.isReport);
    if (getBooleanParam(tabInfo.is_duplicate_object)) {
        return state.duplicateListView;
    } else if (getBooleanParam(isReport)) {
        return state.otherListView;
    }
    else {
        return state.listView;
    }
}

const Merge = ({ object, data, closeDrawer }) => {

    const dispatch = useDispatch();

    const app = useSelector((state) => state.app);
    const listView = useSelector((state) => getListViewInstanceFromRedux(state));
    const sfForm = useSelector((state) => state.sfForm);

    const [stateStatus, setStatus] = useState("");
    const [stateWorkflow_id, setWorkflowId] = useState(0);
    const [stateWorkflow_name, setWorkflowName] = useState("");
    const [stateField_label, setFieldLabel] = useState(null);
    const [stateRecords, setRecords] = useState([]);
    //const [stateFieldRows, setFieldRows] = useState([]);
    const [stateSelectedColumn, setSelectedColumn] = useState('record_one');
    const [stateParentRecord, setParentRecord] = useState(null);
    const [stateChildRecord, setChildRecord] = useState(null);
    const [stateFields, setFields] = useState([]);
    const [stateFieldProps, setFieldProps] = useState({});
    const [stateRadioProps, setRadioProps] = useState(null);
    const [stateParentId, setParentId] = useState(0);
    const [stateChildId, setChildId] = useState(0);
    const [stateParentWorkflow, setParentWorkflow] = useState({});
    const [stateChildWorkflow, setChildWorkflow] = useState({});
    const [stateIsShowLoader, setIsShowLoader] = useState(true);
    const [stateIsShowWorkflowName, setIsShowWorkflowName] = useState(true);
    const [disabled, setDisabled] = useState(false);
    const [secondarySourceList, setSecondarySourceList] = useState([]);
    const [stateList, setStateList] = useState([]);

    useEffect(() => {
        let params = {
            first_record_id: data.first_record_id,
            second_record_id: data.second_record_id,
        }
        let fields = null;
        let fieldsData = sfForm.data;
        if (isValidParam(fieldsData[data.object])) {
            let tempField = fieldsData[data.object]
            fields = tempField.fields;
        } else {
            let linkObject = getEndPointObject(data.object);
            let url = linkObject.FIELDS;
            let promise = Promise.resolve(HTTPClient.get(url, null));
            promise.then((response) => {
                fieldsData[data.object] = response;
                fields = response.fields;
            })
        }

        var response = getMergeRecords(data.object, params);
        response.then((response) => {
            let workflow = response.workflow;
            let parentWorkflow = workflow.parent_workflow;
            let childWorkflow = workflow.child_workflow;
            let status = parentWorkflow.status;
            let workflow_id = parentWorkflow.workflow_id;
            let workflow_name = parentWorkflow.workflow_name;
            let isShowWorkflowName = parentWorkflow.hasOwnProperty('is_show_workflow_name') ? parentWorkflow.is_show_workflow_name : stateIsShowWorkflowName;
            let field_label = response.field_label;
            let records = response.records;
            let parentRecord = records[0];
            let childRecord = records[1];
            let fieldProps = stateFieldProps;
            let parentId = parentRecord['id'];
            let childId = childRecord['id'];
            for (var key in parentRecord) {
                let value = parentRecord[key];
                if (!isValidParam(value)) {
                    value = "";
                }
                let fieldObject = getFieldObject(key, fields);
                let fieldType = "";
                if (isValidParam(fieldObject)) {
                    fieldType = fieldObject['field_type']
                    if (key !== "created_on" && key !== "updated_on") {
                        if (fieldObject.field_type === constants.FIELD_TYPE_DATE_TIME) {
                            let momentObj = moment(value);
                            value = momentObj.format(dateTimeFormat[app.me.date_format]);
                        } else if (fieldObject.field_type === constants.FIELD_TYPE_DATE) {
                            let momentObj = moment(value);
                            value = momentObj.format(dateFormat[app.me.date_format]);
                        }
                        if (value === 'Invalid date') {
                            value = '';
                        }
                    }
                }
                fieldProps[key] = { value: value, type: fieldType }
            }

            setStatus(status);
            setWorkflowId(workflow_id);
            setWorkflowName(workflow_name);
            setFieldLabel(field_label);
            setRecords(records);
            setParentRecord(parentRecord);
            setChildRecord(childRecord);
            setFields(fields);
            getSecondarySourceList(fieldProps);
            getStateList(fieldProps)
            setFieldProps({ ...fieldProps });
            setParentId(parentId);
            setChildId(childId);
            setParentWorkflow(parentWorkflow);
            setChildWorkflow(childWorkflow);
            setIsShowLoader(false);
            setIsShowWorkflowName(isShowWorkflowName);

        });
    }, []);

    const getStateList = (fieldProps) => {
        let _country = stateFieldProps?.country?.value ? stateFieldProps?.country?.value : fieldProps?.country?.value;
        let params = {};
        params.object = constants.ACCOUNTS_OBJECT;
        params.name = 'state';
        params.value = _country;
        params.list_type = constants.LIST_FIELD_TYPE_EXTERNAL;

        var promise = SFFormActions.getFieldListValuesPromise(params);
        promise.then((response) => {
            if (isValidParam(response)) {
                setStateList(getArrayParam(response));
            }
        });
    }

    const getSecondarySourceList = (fieldProps) => {
        let _source = stateFieldProps?.source?.value ? stateFieldProps?.source?.value : fieldProps?.source?.value;
        let params = {};
        params.object = object;
        params.name = "sc_source";
        params.value = _source;
        params.list_type = "external";
        params.query_name = "SecSourceByPrimarySource";
        params.query_type = "customQuery";

        let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.LIST_VALUES, params));
        promise.then((response) => {
            if (isValidParam(response)) {
                setSecondarySourceList(response);
            }
        })
    }


    const onhandleChange = (event, s_name) => {
        if (s_name === 'source') {
            let source_name = event.target.value;
            let params = {};
            params.object = object;
            params.name = "sc_source";
            params.value = source_name;
            params.list_type = "external";
            params.query_name = "SecSourceByPrimarySource";
            params.query_type = "customQuery";

            let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.LIST_VALUES, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    setSecondarySourceList(response.length > 0 ? [...response] : []);
                }
            })
        } else if (s_name === 'country') {
            let countryName = event.target.value;
            let params = {};
            params.object = constants.ACCOUNTS_OBJECT;
            params.name = 'state';
            params.value = countryName;
            params.list_type = constants.LIST_FIELD_TYPE_EXTERNAL;

            var promise = SFFormActions.getFieldListValuesPromise(params);
            promise.then((response) => {
                if (isValidParam(response)) {
                    setStateList(getArrayParam(response));
                }
            });
        }
    }


    const setFieldValue = (value, fieldName) => {
        let fieldProps = stateFieldProps;
        fieldProps[fieldName] = { value: value }
        setFieldProps({ ...fieldProps });
    }

    const selectMultiSelectValue = (value, fieldName) => {
        let fieldProps = stateFieldProps;
        fieldProps[fieldName] = { value: value }
        setFieldProps({ ...fieldProps });
    }

    const handleCustomDateChange = (date, fieldName) => {
        let dateFormatted = '';
        //let _state = this.state;
        //let fields = _state.fields;
        let fieldObj = getFieldObject(fieldName, stateFields);
        let dateVal = new Date(date);
        if (fieldObj?.name === fieldName && fieldObj.field_type === constants.FIELD_TYPE_DATE_TIME) {
            let selectedDate = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(), dateVal.getHours(), dateVal.getMinutes());
            const momentDate = moment(selectedDate);
            dateFormatted = momentDate.format(dateTimeFormat[app.me.date_format]);
        }
        else {
            let selectedDate = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate());
            const momentDate = moment(selectedDate);
            dateFormatted = momentDate.format(dateFormat[app.me.date_format]);
        }
        let temp = stateFieldProps;
        temp[fieldName].value = dateFormatted
        setFieldProps({ ...temp })
        //_state.fieldProps[fieldName].value = dateFormatted;
        //this.setState(_state);
    }

    const getFieldObject = (fieldName, fields) => {
        if (fields?.length > 0) {
            let fieldObject = fields?.filter(function (el) {
                if (el.name === fieldName) {
                    return el;
                }
            });
            fieldObject = fieldObject[0];
            return fieldObject;
        }

    }

    const setMultiSelectValue = (event , fieldName) => {
        try {
            
            let fieldPropsTemp = stateFieldProps;
            let values = event.target.value;
            let arrayStr = "";
            values.map((value, index) => {
                if (arrayStr === "") {
                    arrayStr = value;
                } else {
                    const items = arrayStr.split(",").filter(Boolean);
                    if (items.includes(value)) {
                      arrayStr = items.filter(item => item !== value).join(",");
                    } else {
                      items.push(value);
                      arrayStr = items.join(",");
                    }
                }
    
            });
            fieldPropsTemp[fieldName].value = arrayStr;
            fieldPropsTemp[fieldName].helperText = '';
            setFieldProps({...fieldPropsTemp});
        } catch (error) {
            console.error("Error in 'advanceSearchForm.js -> setMultiSelectValue()':" + error);
        }
    }

    const generateTypeWiseFields = (fieldName, value, fields) => {
        let fieldObject = null;
        let component = null;
        let isDisabled = false;
        fieldObject = getFieldObject(fieldName, fields);
        if (fieldObject?.name === "sc_source") {
            fieldObject.list_values = secondarySourceList.length > 0 ? [...secondarySourceList] : [];
        }
        if (fieldObject?.name === "state") {
            fieldObject.list_values = stateList.length > 0 ? [...stateList] : [];
        }
        if (fieldObject) {
            if (fieldName === "created_on" || fieldName === "updated_on" || fieldName === "lead_score" ||
                fieldName === constants.SHARE_WITH || fieldObject.is_readonly) {
                isDisabled = true;
            } else {
                isDisabled = false;
            }
            if (fieldObject.field_type === constants.FIELD_TYPE_TEXT || fieldObject.field_type === constants.FIELD_TYPE_INTEGER ||
                fieldObject.field_type === constants.FIELD_TYPE_FLOAT || fieldObject.field_type === constants.FIELD_TYPE_URL) {
                let fieldValue = stateFieldProps[fieldName].value;
                if (fieldObject?.is_phone_field) {
                    phoneFormat(fieldValue);
                }
                component =
                    <FormControl style={{
                        width: '100%',
                        marginBottom: data.object === constants.CASES_OBJECT && (fieldName === "synopsis") ? '-4px' : '',
                        marginTop: object === constants.CASES_OBJECT && (fieldName === "company" || fieldName === "from_email" || fieldName === "last_name" || fieldName === "phone") ? '-5px' : ''

                    }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            key={fieldObject.id}
                            id={fieldName + "##edit"}
                            value={fieldValue}
                            onChange={(e) => setFieldValue(e.target.value, fieldName)}
                            fullWidth={true}
                            disabled={isDisabled}
                            margin='dense'
                            size='small'
                            autoComplete="new-password"
                            className={"sf-fields-bg"}
                        />
                    </FormControl>

            } 
            else if (fieldObject.field_type === constants.FIELD_TYPE_LIST && fieldObject.list_type == "multi-select") {
                console.log("stateFieldProps[fieldName].valu??",stateFieldProps[fieldName].value)
       
                component =
                
                <FormControl variant="outlined" style={{ width: '100%', marginTop: data.object == constants.CASES_OBJECT && (fieldName == "sales_rep" || fieldName == "severity") ? '10px' : fieldName == "category" ? '5px' : '' }}>
                    <InputLabel id="sf-merge-simple-select-outlined-label" className="sf-merge"></InputLabel>
                    <Select
                        value={ (Array.isArray(stateFieldProps[fieldName].value) ? [...stateFieldProps[fieldName].value] : [stateFieldProps[fieldName].value])}
                        
                        disabled={isDisabled}
                        key={'select_field_' + fieldName}
                        id={fieldName + "##edit"}
                        style={{ height: '42px' }}
                        className={"sf-fields-bg"}
                        multiple= {true}
                       onChange={(e)=>setMultiSelectValue(e, fieldName)}
                        renderValue={(selected) => selected.join(', ')}
                        
                        >
                        {
                        fieldObject.list_values.map((listValue, listValueIndex) => {
                                            return <MenuItem id={listValue.id} key={listValue.id} value={listValue.value} title={listValue.value}>
                                                <Checkbox checked={stateFieldProps[fieldName].value && stateFieldProps[fieldName].value.indexOf(listValue.value) > -1 ? true : false} />
                                                <ListItemText primary={listValue.value} />
                                            </MenuItem>
                                        })
                        }
                    </Select>
                </FormControl>
            } 
            else if (fieldObject.field_type === constants.FIELD_TYPE_LIST) {
                let listValues = fieldObject.list_values.map((listValueObject, i) => {
                    return <MenuItem
                        value={listValueObject.value}
                        onClick={() => selectMultiSelectValue(listValueObject.value, fieldName)}>{listValueObject.value}</MenuItem>
                });

                component = <FormControl variant="outlined" style={{ width: '100%', marginTop: data.object == constants.CASES_OBJECT && (fieldName == "sales_rep" || fieldName == "severity") ? '10px' : fieldName == "category" ? '5px' : '' }}>
                    <InputLabel id="sf-merge-simple-select-outlined-label" className="sf-merge"></InputLabel>
                    <Select
                        key={fieldObject.id}
                        id={fieldName + "##edit"}
                        style={{ height: '42px' }}
                        onChange={(event) => onhandleChange(event, fieldName)}
                        value={stateFieldProps[fieldName].value}
                        disabled={isDisabled}>
                        {listValues}
                    </Select>
                </FormControl>
            } else if (fieldObject.field_type === constants.FIELD_TYPE_MEMO) {
                component =
                    <FormControl style={{ width: '100%', marginTop: data.object == constants.CASES_OBJECT && (fieldName == "problem_description") ? '' : '-6px' }} className="test" noValidate autoComplete="off">
                        <TextField
                            variant="outlined"
                            key={fieldObject.id}
                            id={fieldName + "##edit"}
                            value={stateFieldProps[fieldName].value}
                            onChange={(e) => setFieldValue(e.target.value, fieldName)}
                            type='textarea'
                            multiline={true}
                            rows={5}
                            className={"sf-fields-bg"}
                            disabled={isDisabled}
                            margin='dense'
                            size='small'
                            autoComplete="new-password"
                        />
                    </FormControl>

            } else if (fieldObject.field_type === constants.FIELD_TYPE_DATE_TIME || fieldObject.field_type === constants.FIELD_TYPE_DATE) {
                let HHFormat = new RegExp("HH");
                let datePickerOptions = {
                    enableTime: fieldObject.field_type === constants.FIELD_TYPE_DATE_TIME ? true : false,
                    dateFormat: fieldObject.field_type === constants.FIELD_TYPE_DATE_TIME ? dateTimeFormatFlatPicker[app.me.date_format] : dateFormatFlatPicker[app.me.date_format],
                    minuteIncrement: 1,
                    static: true,
                    time_24hr: HHFormat.test(app.me.date_format) ? true : false
                };
                if (fieldName !== "created_on" && fieldName !== "updated_on") {
                    component = <div style={{ height: '45px', width: '100%' }}>
                        <PopOver
                            id={'task_start_date'}
                            key={'task_start_date'}
                            name={'task_start_date'}
                            btnType={'dateTime'}
                            buttonStyle={{ fontSize: '16px' }}
                            containerStyle={{ lineHeight: 2, marginBottom: '20px' }}
                            onChange={(date) => handleCustomDateChange(date, fieldName)}
                            value={stateFieldProps[fieldName].value}
                            innerLabelStyle={{ padding: 0 }}
                            height={40}
                            options={datePickerOptions}
                        />
                    </div>
                }
                else {
                    component = <div style={{ height: '45px', width: '100%' }}>
                        <PopOver
                            id={'task_start_date'}
                            key={'task_start_date'}
                            name={'task_start_date'}
                            btnType={fieldObject.field_type === constants.FIELD_TYPE_DATE_TIME ? 'dateTime' : 'date'}
                            buttonStyle={{ fontSize: '16px' }}
                            containerStyle={{ lineHeight: 2, marginBottom: '20px' }}
                            value={stateFieldProps[fieldName].value}
                            innerLabelStyle={{}}
                            height={40}
                            options={datePickerOptions}
                            disable={isDisabled}
                            labelstyle={{ paddingLeft: '0px', paddingRight: '0px' }}
                        />
                    </div>
                }
            }
        }
        return component;
    }

    const selectRecordColumn = (value) => {
        let recordObject = null;
        let workflow = {};
        if (value === "record_two") {
            recordObject = stateChildRecord;
            workflow = stateChildWorkflow;
        } else if (value === "record_one") {
            recordObject = stateParentRecord;
            workflow = stateParentWorkflow;
        }
        let fieldProps = stateFieldProps;
        for (var key in recordObject) {
            let value = recordObject[key];
            if (!isValidParam(value)) {
                value = "";
            }
            fieldProps[key] = { value: value }
        }

        setSelectedColumn(value);
        setFieldProps(fieldProps);
        setRadioProps(null);
        setStatus(workflow.status);
        setWorkflowId(workflow.workflow_id);
        setWorkflowName(workflow.workflow_name);
    }

    const selectRecord = (value) => {
        if (isValidParam(value)) {
            let fieldValue = document.getElementById(value).value;
            let selectedValue = value.split("##");
            let fieldName = selectedValue[1];
            if (fieldName === 'source') {
                let params = {};
                params.object = object;
                params.name = "sc_source";
                params.value = fieldValue;
                params.list_type = "external";
                params.query_name = "SecSourceByPrimarySource";
                params.query_type = "customQuery";

                let promise = Promise.resolve(HTTPClient.get(endPoints.FORM.LIST_VALUES, params));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        setSecondarySourceList(response.length > 0 ? [...response] : []);
                    }
                })
            } else if (fieldName === 'country') {

                let params = {};
                params.object = constants.ACCOUNTS_OBJECT;
                params.name = 'state';
                params.value = fieldValue;
                params.list_type = constants.LIST_FIELD_TYPE_EXTERNAL;

                var promise = SFFormActions.getFieldListValuesPromise(params);
                promise.then((response) => {
                    if (isValidParam(response)) {
                        setStateList(getArrayParam(response));
                    }
                });
            }
            let fieldProps = stateFieldProps;
            fieldProps[fieldName].value = fieldValue;
            let radioProps = stateRadioProps;
            if (!isValidParam(radioProps)) {
                radioProps = {};
            }
            radioProps[fieldName] = { value: selectedValue[0] }
            setFieldProps({ ...fieldProps });
            setRadioProps(radioProps);
        }
    }

    const generateFieldRows = () => {
        let parentRecord = stateParentRecord;
        let childRecord = stateChildRecord;
        let fields = stateFields;
        let tempFieldRows = [];
        let field_label = stateField_label;
        for (var key in parentRecord) {
            if (key !== "id" && key !== "t_status") {
                let valueSelected = null;
                if (stateRadioProps === null) {
                    valueSelected = stateSelectedColumn + "##" + key;
                } else {
                    if (stateRadioProps.hasOwnProperty(key)) {
                        valueSelected = stateRadioProps[key].value + "##" + key;
                    } else {
                        valueSelected = stateSelectedColumn + "##" + key;
                    }
                }

                let parentValue = parentRecord[key];
                let childValue = childRecord[key];
                if (!isValidParam(parentValue)) {
                    parentValue = "";
                }
                if (!isValidParam(childValue)) {
                    childValue = "";
                }
                let fieldObject = getFieldObject(key, fields);
                if (isValidParam(fieldObject)) {
                    if (key !== "created_on" && key !== "updated_on") {
                        if (fieldObject.field_type === constants.FIELD_TYPE_DATE_TIME) {
                            if (parentValue !== null && parentValue !== undefined && parentValue !== "") {
                                let pmomentObj = moment(parentValue);
                                parentValue = pmomentObj.format(dateTimeFormat[app.me.date_format]);
                            }

                            if (childValue !== null && childValue !== undefined && childValue !== "") {
                                let cmomentObj = moment(childValue);
                                childValue = cmomentObj.format(dateTimeFormat[app.me.date_format]);
                            }
                        } else if (fieldObject.field_type === constants.FIELD_TYPE_DATE) {
                            if (parentValue !== null && parentValue !== undefined && parentValue !== "") {
                                let pmomentObj = moment(parentValue);
                                parentValue = pmomentObj.format(dateFormat[app.me.date_format]);
                            }

                            if (childValue !== null && childValue !== undefined && childValue !== "") {
                                let cmomentObj = moment(childValue);
                                childValue = cmomentObj.format(dateFormat[app.me.date_format]);
                            }
                        }
                    }
                }

                // let fieldLabel = field_label[key];label
                let fieldLabel = fieldObject ? fieldObject['label'] : '';
                let component = null;
                if (stateSelectedColumn === "record_one") {
                    component = generateTypeWiseFields(key, parentValue, fields);
                } else if (stateSelectedColumn === "record_two") {
                    component = generateTypeWiseFields(key, childValue, fields);
                }
                tempFieldRows.push(

                    <div style={{ ...styles.row, marginBottom: '15px' }}>
                        <div style={{ ...styles.sf_2, marginTop: '6px' }}>
                            <Typography >
                                {fieldLabel}
                            </Typography>

                        </div>
                        <div style={{ ...styles.sf_3 }}>
                            <RadioGroup
                                name="layout" onChange={(e) => selectRecord(e.target.value)} value={valueSelected} style={{ marginLeft: '10px' }} >


                                <FormControlLabel value={"record_one##" + key} control={<Radio disabled={disabled} color="default" />}
                                    label={<div title={parentValue} style={{ color: '#717171', maxWidth: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{parentValue}</div>}

                                />

                                <input type="hidden" id={"record_one##" + key} value={parentValue} />
                            </RadioGroup>
                        </div>
                        <div style={{ ...styles.sf_3, margintop: '5px' }}>
                            <RadioGroup
                                name="layout" onChange={(e) => selectRecord(e.target.value)} value={valueSelected}  >
                                <FormControlLabel value={"record_two##" + key} control={<Radio disabled={disabled} color="default" />}

                                    label={<div title={childValue} style={{ color: '#717171', maxWidth: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{childValue}</div>}


                                />

                                <input type="hidden" id={"record_two##" + key} value={childValue} />
                            </RadioGroup>
                        </div>
                        <div style={{ ...styles.sf_4, marginTop: '1px' }}>
                            {component}
                        </div>
                    </div>
                );
            }
        }
        return tempFieldRows;
    }

    const saveMerge = () => {
        let first_record_id = stateParentId;
        let second_record_id = stateChildId;
        let merge_record_id = second_record_id;
        let params = null;
        let mergeRecordDetails = {};
        let fieldProps = stateFieldProps;
        let msg = null;
        let parentRecord = stateParentRecord;
        let childRecord = stateChildRecord;
        let selectedColumn = stateSelectedColumn;
        let fields = stateFields;
        let error_msg = null;
        let is_error = false;
        let fieldId = "";
        for (var key in fieldProps) {
            if (key !== "id") {
                let fieldObject = getFieldObject(key, fields);
                let fieldValue = fieldProps[key].value;
                if (isValidParam(fieldValue)) {
                    if (!isInteger(fieldValue) && !isFloat(fieldValue)) {
                        fieldValue = fieldValue.trim();
                    }
                }
                if (!isValidParam(fieldValue)) {
                    fieldValue = "";
                }

                if (fieldObject.field_type === constants.FIELD_TYPE_DATE_TIME) {
                    fieldValue = moment(fieldValue);
                    if (isValidParam(fieldValue)) {
                        fieldValue = fieldValue.format("YYYY-MM-DD HH:mm:ss");
                    }
                } else if (fieldObject.field_type === constants.FIELD_TYPE_DATE) {
                    fieldValue = moment(fieldValue);
                    if (isValidParam(fieldValue)) {
                        fieldValue = fieldValue.format("YYYY-MM-DD");
                    }
                }

                if (fieldValue !== null && fieldValue !== undefined && fieldValue !== "") {
                    if (fieldObject.field_type === constants.FIELD_TYPE_INTEGER && !isInteger(fieldValue)) {
                        error_msg = fieldValue + " " + getLocalizedStrings().message.MERGE.NOT_INTEGER;
                        is_error = true;
                        fieldId = key + "##edit";
                        break;
                    } else if (fieldObject.is_email_field && !isEmail(fieldValue)) {
                        error_msg = fieldValue + " " + getLocalizedStrings().message.MERGE.NOT_EMAIL;
                        is_error = true;
                        fieldId = key + "##edit";
                        break;
                    } else if (fieldObject.field_type === constants.FIELD_TYPE_URL && !isUrl(fieldValue)) {
                        error_msg = fieldValue + " " + getLocalizedStrings().message.MERGE.NOT_URL;
                        is_error = true;
                        fieldId = key + "##edit";
                        break;
                    } else if (fieldObject.field_type === constants.FIELD_TYPE_FLOAT && !isFloat(fieldValue)) {
                        error_msg = fieldValue + " " + getLocalizedStrings().message.MERGE.NOT_FLOAT;
                        is_error = true;
                        fieldId = key + "##edit";
                        break;
                    } else {
                        is_error = false;
                    }
                }
                mergeRecordDetails[key] = fieldValue;
            }
        }
        if (is_error) {
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, error_msg, () => handleSfDialogForFocus(fieldId), null);
        } else {
            let workflowId = stateWorkflow_id;
            let workflowName = stateWorkflow_name;
            let status = stateStatus;
            let workflow = {};
            if (data.object !== constants.SOLUTIONS_OBJECT && data.object !== constants.CONTACTS_OBJECT) {
                workflow = {
                    id: workflowId,
                    name: workflowName,
                    stage_name: status
                }
            }

            if (selectedColumn === "record_one") {
                merge_record_id = second_record_id;
            } else if (selectedColumn === "record_two") {
                merge_record_id = first_record_id;
            }
            let firstRecord = "Record: 1";
            let secondRecord = "Record: 2";
            let deletedRecordCompanyName = "";
            let tempListView = listView;
            let listViewFields = tempListView.data.fields;
            let records = tempListView.data.records;
            listViewFields = listViewFields.filter(function (el) {
                return el.is_detail_link === true;
            });
            listViewFields = listViewFields[0];
            let fieldName = listViewFields?.name;
            records = records.map((objRecords, i) => {
                if (objRecords['id'] === childRecord['id']) {
                    if (objRecords[fieldName] !== null && objRecords[fieldName] !== undefined && objRecords[fieldName] !== "") {
                        secondRecord = objRecords[fieldName];
                    }
                }

                if (objRecords['id'] === parentRecord['id']) {
                    if (objRecords[fieldName] !== null && objRecords[fieldName] !== undefined && objRecords[fieldName] !== "") {
                        firstRecord = objRecords[fieldName];
                    }
                }
            });

            if (selectedColumn === "record_one") {
                msg = getLocalizedStrings().message.COMMON.MERGE_ALERT_1 + " " + secondRecord + " " + getLocalizedStrings().message.COMMON.WITH + " " + firstRecord + " " + getLocalizedStrings().message.COMMON.MERGE_ALERT_2 + " " + secondRecord + "?";
                deletedRecordCompanyName = secondRecord;
            } else if (selectedColumn === "record_two") {
                msg = getLocalizedStrings().message.COMMON.MERGE_ALERT_1 + " " + firstRecord + " " + getLocalizedStrings().message.COMMON.WITH + " " + secondRecord + " " + getLocalizedStrings().message.COMMON.MERGE_ALERT_2 + " " + firstRecord + "?";
                deletedRecordCompanyName = firstRecord;
            }
            deletedRecordCompanyName = String(deletedRecordCompanyName)
            params = {
                first_record_id: first_record_id,
                second_record_id: second_record_id,
                merge_record_id: merge_record_id,
                merge_record_details: mergeRecordDetails,
                workflow: workflow,
                deleted_record_company_name: deletedRecordCompanyName
            }

            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => handleSfDialogSaveMerge(params), null);

        }
    }

    const handleSfDialogForFocus = (fieldId) => {
        document.getElementById(fieldId).focus();
    }

    const handleSfDialogSaveMerge = (params) => {
        setIsShowLoader(true);
        var response = saveMergeRecords(data.object, params);
        response.then((response) => {
            if (isValidParam(response)) {
                if (response.hasOwnProperty('is_contain_tenantEmail') && response.is_contain_tenantEmail === true) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.MERGE.MERGE_TENANT_EMAIL_ALERT_MESSAGE, null);
                    closeDrawer();
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.MERGE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    if (data.isDetailView) {

                    } else {
                        let tabInfo = getObjectParam(getActiveTabInfo());
                        let searchParams = null;
                        if (tabInfo.hasOwnProperty('searchFieldName') && tabInfo.hasOwnProperty('searchText')) {
                            searchParams = {
                                search_field_name: getStringParam(tabInfo.searchFieldName),
                                search_text: getStringParam(tabInfo.searchText)
                            }
                        }
                        let object = data.object;
                        if (isValidParam(tabInfo.is_duplicate_object) && getBooleanParam(tabInfo.is_duplicate_object)) {
                            object = constants.FIND_DUPLICATE_OBJECT;
                        }
                        getRecentRecord(data.object);
                    }
                    closeDrawer();
                    let activeTab = getActiveTab();
                    activeTab.info = { ...activeTab.info, rememberSelection: { ...activeTab.info.rememberSelection ?? {}, [object]: new Set() } };
                    updateActiveTab(activeTab);
                    refreshListView(object);

                }
            }
        });
    }

    let height = window.innerHeight - 150;
    let top = (height - 10) / 2;
    if (stateIsShowLoader) {
        return (
            <div style={{ width: '100%', height: height }}>
                {
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                }
            </div>
        );
    } else {
        return (
            <div style={{ padding: '10px 20px', marginBottom: '80px', width: '100%' }}>
                <div style={{ paddingBottom: '10px', display: 'flex', width: '100%' }} className='row clearfix'>
                    <div style={{ float: 'right', marginLeft: '84.5%' }}>
                        <Button
                            style={{ ...styles.listViewPrimaryButton, width: '73px' }}
                            onClick={() => saveMerge()}
                        >{getLocalizedStrings().label.COMMON.MERGE}</Button>
                        <Button
                            onClick={() => closeDrawer()}
                            style={{ ...styles.secondaryButton, height: '30px', marginLeft: '13px' }}
                        >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                    </div>


                </div>
                <div style={{ marginBottom: '10px' }}>
                    {getLocalizedStrings().label.COMMON.DISPLAY_DIFFERENT_FIELDS}
                </div>

                <div style={{ ...styles.row, marginBottom: '10px' }}>
                    <div style={styles.sf_2}>
                        <Typography style={{ fontWeight: 600, color: 'grey' }} >
                            Field
                        </Typography>
                    </div>

                    <div style={styles.sf_3}>
                        <RadioGroup name="layout" onChange={(e) => selectRecordColumn(e.target.value)} value={stateSelectedColumn} style={{ marginTop: '-8px', marginLeft: '10px' }} >
                            <FormControlLabel value="record_one" control={<Radio color="default" />} label={<span style={{ fontWeight: 600, color: 'grey' }} >{getLocalizedStrings().label.COMMON.RECORD + ": 1"}</span>} />
                        </RadioGroup>
                    </div>

                    <div style={styles.sf_3}>
                        <RadioGroup name="layout" onChange={(e) => selectRecordColumn(e.target.value)} value={stateSelectedColumn} style={{ marginTop: '-8px', }} >
                            <FormControlLabel value="record_two" control={<Radio color="default" />} label={<span style={{ fontWeight: 600, color: 'grey' }} >{getLocalizedStrings().label.COMMON.RECORD + ": 2"}</span>} />
                        </RadioGroup>
                    </div>
                    <div style={styles.sf_4}>
                        <Typography style={{ fontWeight: 600, color: 'grey' }} >
                            {getLocalizedStrings().label.COMMON.MERGED_RECORD}
                        </Typography>
                    </div>
                </div>
                {
                    (data.object !== constants.SOLUTIONS_OBJECT && data.object !== constants.CONTACTS_OBJECT && data.object !== constants.SALES_LEADS_OBJECT) && (stateIsShowWorkflowName) &&

                    <div style={{ ...styles.row, marginBottom: '20px' }}>
                        <div style={styles.sf_2}>
                            <Typography style={{ fontWeight: 400, color: 'black' }} >
                                {getLocalizedStrings().label.COMMON.WORKFLOW}
                            </Typography>
                        </div>


                        <div style={styles.sf_3}>
                            <RadioGroup
                                name="layout" value={stateSelectedColumn} style={{ marginLeft: '10px' }} >
                                <FormControlLabel value={"record_one"} control={<Radio disabled={true} color="default" />}
                                    label={<div title={stateParentWorkflow.workflow_name} style={{ color: '#717171', maxWidth: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{stateParentWorkflow.workflow_name}</div>}

                                />
                            </RadioGroup>
                        </div>

                        <div style={styles.sf_3}>
                            <RadioGroup
                                name="layout" value={stateSelectedColumn}  >
                                <FormControlLabel value={"record_two"} control={<Radio disabled={true} color="default" />}
                                    label={<div title={stateChildWorkflow.workflow_name} style={{ color: '#717171', maxWidth: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{stateChildWorkflow.workflow_name}</div>}

                                />
                            </RadioGroup>
                        </div>
                        <div style={styles.sf_4}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <Select
                                    labelId='sf-label-workflow'
                                    value={stateWorkflow_id}
                                    disabled={true}
                                    title={stateWorkflow_name}
                                    style={{ height: '42px' }}>
                                    <MenuItem value={stateWorkflow_id} >{stateWorkflow_name}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                }
                {
                    (data.object !== constants.SOLUTIONS_OBJECT && data.object !== constants.CONTACTS_OBJECT && data.object !== constants.SALES_LEADS_OBJECT) &&
                    <div style={{ ...styles.row, marginBottom: '10px' }}>
                        <div style={styles.sf_2}>
                            <Typography style={{ fontWeight: 400, color: 'black' }} >
                                {getLocalizedStrings().label.COMMON.STATUS}
                            </Typography>
                        </div>

                        <div style={styles.sf_3}>
                            <RadioGroup
                                name="layout" value={stateSelectedColumn} style={{ marginLeft: '10px' }} >
                                <FormControlLabel value={"record_one"} control={<Radio disabled={true} color="default" />}
                                    label={<div title={stateParentWorkflow.status} style={{ color: '#717171', maxWidth: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{stateParentWorkflow.status}</div>}

                                />
                            </RadioGroup>
                        </div>

                        <div style={styles.sf_3}>
                            <RadioGroup
                                name="layout" value={stateSelectedColumn} >
                                <FormControlLabel value={"record_two"} control={<Radio disabled={true} color="default" />}
                                    label={<div title={stateChildWorkflow.status} style={{ color: '#717171', maxWidth: '160px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{stateChildWorkflow.status}</div>}

                                />
                            </RadioGroup>
                        </div>
                        <div style={styles.sf_4}>
                            <FormControl variant="outlined" style={{ width: '100%' }}>
                                <Select
                                    labelId='sf-label-status'
                                    value={stateStatus}
                                    disabled={true}
                                    title={stateStatus}
                                    style={{ height: '42px' }}>
                                    <MenuItem value={stateStatus} >{stateStatus}</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                }
                <div>
                    {generateFieldRows()}
                </div>

                <div >
                    <div >
                        <Button
                            key={'close'}
                            onClick={() => closeDrawer()}
                            style={{ ...styles.secondaryButton, marginRight: '12px', height: '31px', float: 'right' }}
                        >{getLocalizedStrings().label.COMMON.CANCEL}</Button>

                        <Button
                            key={'merge'}
                            style={{ ...styles.listViewPrimaryButton, marginRight: '13px', float: 'right' }}
                            onClick={() => saveMerge()}
                        > {getLocalizedStrings().label.COMMON.MERGE}</Button>
                    </div>
                </div>
            </div>
        );
    }
};

export default Merge;

