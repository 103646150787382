import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { styles } from '../../../services/constants/styles';
import { FormControl, Icon, IconButton, Menu, MenuItem, Popper, Select } from '@mui/material';
import Button from '@mui/material/Button';
import map from 'lodash/map';
import filter from 'lodash/filter';
import Pagination from '../components/pagination';
import { getAppCustomDrawer, getAppDialog, getAppDrawer } from '../../../services/actions/appContainerActions';
import ShowCircularProgress from '../components/circularProgress';
import { detailViewIsMounted } from "../../../services/actions/detailViewActions";
import { updateWorkflowList } from '../../../services/actions/listViewAcions.js';
import { ArrowDropDown } from "@mui/icons-material";
import {
    constants,
    COUNTRY_LIST_FOR_PHONE_SMS,
    OBJECT_TABLEID_MAP,
    QUEUE_LIST,
    TABLEID_OBJECT_MAP,
    ROYALTY_RUN_ACTIONS,
    REPORTED_SALES_ACTIONS,
    INTAKE_FORM,
} from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { LinkToType } from '../../../services/constants/link';
import {
    EMPTY_STRING,
    getArrayParam,
    getBooleanParam,
    getIntParam,
    getObjectParam,
    getStringParam,
    isInvalidParam,
    isValidParam,
    makeValidAppUrl
} from '../../../services/helper/parameterVerifier';
import * as sfDialogs from '../components/sfDialogs';
import { getCallDrawer, getPowerCallingData, resetPowerCallingData } from '../../../services/actions/callAction';
import {
    activeAction,
    checkRecordStatus,
    checkRecordWFId,
    deleteAction,
    deleteWebForms,
    inActiveAction,
    publishWebForm,
    unPublishWebForm,
    updateFields
} from '../../../services/actions/batchActions';
import ListViewSetup from '../components/listViewSetup';
import ListViewSearchField from '../components/listViewSearchField';
import {
    getChildObjectInfo,
    getObjectLabelByObject,
    getObjectNameByLabel,
    getRecentRecord,
    getSelectedObject,
    hasAccessPermission,
    isFranchiseRecord,
    isObjectChildExists
} from '../../../services/helper/common';
import {
    getChildObjectCustomTitle,
    getMultilingualLabelName,
    makeTiteleCase,
    sortArrayObjectByProperty
} from '../../../services/helper/utils';
import {
    checkSyncedProducts,
    getAttachmentSignStatus,
    getLinkRecordsForLookup,
    groupByShowHide,
    isLinkedRecord,
    leadStoreSearchFldShowHide,
    refreshListView,
    removeScrollPosition,
    updateListviewFilter,
    fetchLinkRecords,
    setMounted,
    setSelectedAuditfilter,
    getAttachmentType,
    getAgreementType,
    updateOnboardingTasks
} from '../../../services/actions/listViewAcions';
import {
    addNewFormTabs,
    addTab,
    getActiveTab,
    getActiveTabInfo,
    updateActiveTab,
    selectTabByIndex,
    TYPE_DETAIL_VIEW,
    TYPE_OTHER_VIEW,
    TYPE_DETAIL_VIEW_TASK_LIST,
    TYPE_POWER_DIALER,
    TYPE_GANTT_CHART,
    updateTabInfo
} from '../../../services/helper/sfTabManager';
import { getTasksRefresh } from '../../../services/actions/taskActions';
import {
    groupUsedInCampaigns,
    refreshDetailViewData,
    refreshDetailViewListViewData
} from '../../../services/actions/detailViewActions';
import {
    getRecordPromise,
    getTasksCompletedPromise,
    removeObjectListItemFromRedux,
    getSFFormFieldsPromise,
} from "../../../services/actions/sfFormActions";
import { getLocalizedStrings } from "../../../services/constants/MultiLingual";
import ListViewFilterDropDown from "../components/listViewFilterDropDown";
import { franchiseLogin } from "../../../services/actions/unitsAction.js";
import {
    loadQueueList,
    getQueueList,
    openDetailviewQueueTab,
} from "../../../services/actions/queueActions";
import { storeTemplateListInRedux } from "../../../services/actions/sendMailActions";
import PopOver from "./PopOver";
import { getVerifiedEmais } from "../../../services/actions/sendMailActions";
import { clearSfRecentData } from "../../../services/actions/sfFormActions";
import { isArray } from 'lodash';
import { Link, useParams } from 'react-router-dom'
import { getListViewData } from '../../../services/actions/listViewAcions';
import * as ParameterVerifier from '../../../services/helper/parameterVerifier';
import { updateUnitpermissionList } from '../../../services/actions/listViewAcions.js';

let popOverList = [
    {
        value: "Active",
        label: getLocalizedStrings()?.label?.COMMON.ACTIVE,
    },
    {
        value: 'Inactive',
        label: getLocalizedStrings()?.label?.COMMON.INACTIVE,
    }
];

const FilterMenuItems = [
    { label: 'All Audit Answers', value: 'All Audit Answers' },
    { label: 'Answers with Corrective Actions', value: 'Answers with Corrective Actions' }
]

const optionvalue = ['All', 'Pending', 'Not Started', 'Completed', 'In-progress', 'Cancelled'];

const getisShowGroupByFromRedux = (state, props) => {
    let tabInfo = getActiveTabInfo();
    let isAssignRule = getBooleanParam(tabInfo.isAssignRule);
    if (getBooleanParam(isAssignRule)) {
        return state.assignListView.isShowGroupBy;
    } else if (getBooleanParam(props.suffix === constants.TASK_LIST)) {
        return state.detailViewTaskList.isShowGroupBy;
    } else {
        return state.listView.isShowGroupBy;
    }
}

function processGroupData(response) {

    let tcAlert = [], tcMsgAlert = "";
    let bcAlert = [], bcMsgAlert = "";
    let abAlert = [], abMsgAlert = "";
    let webFormAlert = [], webFormMsgAlert = "";
    let landingPageAlert = [], landingPageMsgAlert = "";
    const message = [];
    let alertMsg = "";

    if (response !== null && response !== '') {
        if (response.touchmsg !== null) {
            tcMsgAlert = response.touchmsg;
        }
        if (response.broadcastmsg !== null) {
            bcMsgAlert = response.broadcastmsg;
        }
        if (response.abmsg !== null) {
            abMsgAlert = response.abmsg;
        }
        if (response.webFormMsg !== null) {
            webFormMsgAlert = response.webFormMsg;
        }
        if (response.landingPageMsg !== null) {
            landingPageMsgAlert = response.landingPageMsg;
        }

        if ((tcMsgAlert !== null && tcMsgAlert !== "") || (bcMsgAlert !== null && bcMsgAlert !== "") ||
            (abMsgAlert !== null && abMsgAlert !== "") || (webFormMsgAlert !== null && webFormMsgAlert !== "") ||
            (landingPageMsgAlert !== null && landingPageMsgAlert !== "")) {

            if (tcMsgAlert !== null && tcMsgAlert !== "") {
                for (let i = 0; i < tcMsgAlert.length; i++) {

                    const touchArr = tcMsgAlert[i].hasOwnProperty("touch") ? tcMsgAlert[i]["touch"] : null;
                    const tcGroup = tcMsgAlert[i].hasOwnProperty("tcGroup") ? tcMsgAlert[i]["tcGroup"] : null;

                    if (touchArr !== null && touchArr.length > 0) {
                        let tcName = "";
                        let messageTC = "";

                        for (let j = 0; j < touchArr.length; j++) {
                            if (tcName === "") {
                                tcName = touchArr[j];
                            } else {
                                tcName = tcName + ", " + touchArr[j];
                            }
                        }
                        messageTC =
                            <span><b><i>{tcGroup}</i></b> {getLocalizedStrings().message.GROUP.USED_IN_TOUCH_CAMPAIGN}
                                <br /><b>{tcName}<br /><br /></b></span>;
                        tcName = "";
                        tcAlert.push(messageTC);

                    }
                }

            }

            if (bcMsgAlert !== null && bcMsgAlert !== "") {
                for (let i = 0; i < bcMsgAlert.length; i++) {

                    const broadcastArr = bcMsgAlert[i].hasOwnProperty("broadcast") ? bcMsgAlert[i]["broadcast"] : null;
                    const broadcastGroup = bcMsgAlert[i].hasOwnProperty("bcGroup") ? bcMsgAlert[i]["bcGroup"] : null;

                    if (broadcastArr !== null && broadcastArr.length > 0) {
                        let bcName = "";
                        let messageBC = "";

                        for (let j = 0; j < broadcastArr.length; j++) {
                            if (bcName === "") {
                                bcName = broadcastArr[j];
                            } else {
                                bcName = bcName + ", " + broadcastArr[j];
                            }
                        }

                        messageBC =
                            <span><b><i>{broadcastGroup}</i></b> {getLocalizedStrings().message.GROUP.USED_IN_BROADCAST_CAMPAIGN}
                                <br /><b>{bcName}<br /><br /></b></span>;
                        bcName = "";

                        bcAlert.push(messageBC);

                    }
                }

            }

            if (abMsgAlert !== null && abMsgAlert !== "") {
                for (let i = 0; i < abMsgAlert.length; i++) {

                    const abArr = abMsgAlert[i].hasOwnProperty("ab") ? abMsgAlert[i]["ab"] : null;
                    const abGroup = abMsgAlert[i].hasOwnProperty("abGroup") ? abMsgAlert[i]["abGroup"] : null;

                    if (abArr !== null && abArr.length > 0) {
                        let abName = "";
                        let messageAB = "";

                        for (let j = 0; j < abArr.length; j++) {
                            if (abName === "") {
                                abName = abArr[j];
                            } else {
                                abName = abName + ", " + abArr[j];
                            }
                        }

                        messageAB =
                            <span><b><i>{abGroup}</i></b> {getLocalizedStrings().message.GROUP.USED_IN_AB_CAMPAIGN}
                                <br /><b>{abName}<br /><br /></b></span>;
                        abName = "";

                        abAlert.push(messageAB);

                    }
                }

            }

            if (webFormMsgAlert !== null && webFormMsgAlert !== "") {
                for (let i = 0; i < webFormMsgAlert.length; i++) {

                    const webformArr = webFormMsgAlert[i].hasOwnProperty("webForm") ? webFormMsgAlert[i]["webForm"] : null;
                    const webformGroup = webFormMsgAlert[i].hasOwnProperty("webFormGroup") ? webFormMsgAlert[i]["webFormGroup"] : null;

                    if (webformArr !== null && webformArr.length > 0) {
                        let webformName = "";
                        let messageWebform = "";

                        for (let j = 0; j < webformArr.length; j++) {
                            if (webformName === "") {
                                webformName = webformArr[j];
                            } else {
                                webformName = webformName + ", " + webformArr[j];
                            }
                        }

                        messageWebform =
                            <span><b><i>{webformGroup}</i></b> {getLocalizedStrings().message.GROUP.USED_IN_WEBFORM}
                                <br /><b>{webformName}<br /><br /></b></span>;
                        webformName = "";

                        webFormAlert.push(messageWebform);

                    }
                }

            }

            if (landingPageMsgAlert !== null && landingPageMsgAlert !== "") {
                for (let i = 0; i < landingPageMsgAlert.length; i++) {

                    const landingpageArr = landingPageMsgAlert[i].hasOwnProperty("landingPage") ? landingPageMsgAlert[i]["landingPage"] : null;
                    const landingpageGroup = landingPageMsgAlert[i].hasOwnProperty("landingPageGroup") ? landingPageMsgAlert[i]["landingPageGroup"] : null;

                    if (landingpageArr !== null && landingpageArr.length > 0) {
                        let landingpageName = "";
                        let messageLandingpage = "";

                        for (let j = 0; j < landingpageArr.length; j++) {
                            if (landingpageName === "") {
                                landingpageName = landingpageArr[j];
                            } else {
                                landingpageName = landingpageName + ", " + landingpageArr[j];
                            }
                        }

                        messageLandingpage =
                            <span><b><i>{landingpageGroup}</i></b> {getLocalizedStrings().message.GROUP.USED_IN_LANDINGPAGE}
                                <br /><b>{landingpageName}<br /><br /></b></span>;
                        landingpageName = "";
                        landingPageAlert.push(messageLandingpage);

                    }
                }
            }
        }

        if (tcAlert !== null) {
            message.push(tcAlert);
        }

        if (bcAlert !== null) {
            message.push(bcAlert);
        }

        if (abAlert !== null) {
            message.push(abAlert);
        }
        if (webFormAlert !== null) {
            message.push(webFormAlert);
        }

        if (landingPageAlert !== null) {
            message.push(landingPageAlert);
        }

        alertMsg = <div style={{ overflow: 'auto', maxHeight: '400px', fontSize: '16px' }}>
            <b>{getLocalizedStrings().message.GROUP.DELETE_MSG} :</b><br /><br />{message}</div>;
        return alertMsg
    } else {
        return alertMsg
    }

}


const HIDE_SETUP_OBJECT_LIST = [
    constants.MARKETING_TEMPLATE_OBJECT,
    constants.SALES_TEMPLATE_OBJECT,
    constants.WEB_FORMS,
    constants.WEBSITE_TRACKING,
    constants.SOURCE_OBJECT,
    constants.RELATIONS_OBJECT,
    constants.ASSIGNMENT_RULES_OBJECT,
    constants.NOTE_TYPES_OBJECT,
    constants.APPOINTMENT_TYPES_OBJECT,
    constants.CAMPAIGN_TYPES_OBJECT,
    constants.WORKFLOW_OBJECT,
    constants.USERS_OBJECT,
    constants.SECURITY_LEVEL_OBJECT,
    constants.EMAIL_INTEGRATION_OBJECT,
    constants.LIBRARY_OBJECT,
    constants.LANDING_PAGES_FROALA_OBJECT,
    constants.SMS_TEMPLATE_OBJECT,
    constants.FIND_DUPLICATE_OBJECT,
    constants.DOWNLOAD_LEADS_OBJECT,
    constants.CALL_SCRIPT_OBJECT,
    constants.AGREEMENT_TEMPLATE_OBJECT,
    constants.ROLL_UP_OBJECT,
    constants.PHONE_NUMBERS_OBJECT,
    constants.AUDITS_OBJECT,
    constants.AUDIT_QUESTIONS_OBJECT,
];
const tasksTypeList = [
    { id: -9999, name: 'All Tasks', label: 'All Tasks' },
    { id: 1237, name: 'My Tasks', label: 'Corporate Tasks' },
    { id: 1238, name: 'Unit’s Tasks', label: 'Unit’s Tasks' },
];
const ListViewHeader = (props) => {
    const dispatch = useDispatch();
    const { suffix } = useParams();
    const reduxApp = useSelector((state) => state['app']);
    const reduxTab = useSelector((state) => state['tab']);
    const reduxAppContainer = useSelector((state) => state['appContainer']);
    const detailViewTaskList = useSelector((state) => state.detailViewTaskList);
    const reduxIsShowGroupBy = useSelector((state) => getisShowGroupByFromRedux(state, props));
    const reduxDetailView = useSelector((state) => state['detailView']);
    const { selectedFilter } = useSelector((state) => state.childListView);
    const reduxSfForm = useSelector((state) => state.sfForm);
    const reduxDetailViewRecord = useSelector((state) => state['detailView']['record']);
    const reduxQueueList = useSelector((state) => state['queueList']);
    const reduxTreeView = useSelector((state) => state['treeView']);
    const reduxPowerCalling = useSelector((state) => state['powerCalling']);
    const reduxSendMail = useSelector((state) => state['sendMail']);
    const reduxListView = useSelector((state) => state['listView']);
    const reduxCustomDrawer = useSelector((state) => state['appContainer']['customDrawerProps']);
    const GroupBy_Condition = useSelector((state) => state.childListView.groupByInfo.group_by_condition);
    const activeTab = useSelector((state) => getActiveTab(state));
    const childListView = useSelector((state) => state.childListView);
    const [isCallingSupport, setIsCallingSupport] = useState(true);
    const [isOpenSetupPopover, setIsOpenSetupPopover] = useState(false);
    const [setupComponent, setSetupComponent] = useState(null);
    const [isShowSetup, setIsShowSetup] = useState(true);
    const [actionsList, setActionsList] = useState(null);
    const [linkChildTableId, setLinkChildTableId] = useState(0);
    const [isChildObjectAdd, setIsChildObjectAdd] = useState(false);
    const [isChildObjectFindAndLink, setIsChildObjectFindAndLink] = useState(false);
    const [isChildObjectCopy, setIsChildObjectCopy] = useState(false);
    const [childObjectLabel, setChildObjectLabel] = useState('');
    const [isReport, setIsReport] = useState(false);
    const [selectedSearchFieldLabel, setSelectedSearchFieldLabel] = useState('');
    const [selectedSearchFieldName, setSelectedSearchFieldName] = useState('');
    const [availableSearchFieldList, setAvailableSearchFieldList] = useState(null);
    const [isOpenSearchFieldsList, setIsOpenSearchFieldsList] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchFieldList, setSearchFieldList] = useState([]);
    const [isRelationshipTabExist, setIsRelationshipTabExist] = useState(false);
    const [isSearchExist, setIsSearchExist] = useState(true);
    const [workflowList, setWorkflowList] = useState([]);
    const [selectedStatusValue, setSelectedStatusValue] = useState('Active');
    const [isQuatationTabExist, setIsQuatationTabExist] = useState(false);
    const [isTwilio, setIsTwilio] = useState(false);
    const [pageNo, setPageNo] = useState(null);
    const [fieldValue, setFieldValue] = useState([]);
    const [openActionList, setOpenActionList] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [stateObject, setStateObject] = useState('');
    const [childObjectInfo, setChildObjectInfo] = useState({})
    const [isFranchise, setIsFranchise] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [selectedTaskStatus, setSelectedTaskStatus] = useState(null);
    const [selectedTaskFor, setSelectedTaskFor] = useState(null);
    const [selectedTaskWorkflow, setSelectedTaskWorkflow] = useState(null);
    const [unitPermission, setUnitPermission] = useState({});

    let franchisorToken = getStringParam(
        localStorage.getItem("is_franchisor_token")
    );

    useEffect(() => {
        if ((props.object === constants.BROADCAST_CAMPAIGNS_OBJECT || props.object === constants.AUTOMATION_DESIGNER_OBJECT) && reduxApp.me.is_franchisee) {
            getActionPermission();
        }
        let info = getActiveTabInfo();
        let _filterText = info?.filter?.search_text || '';
        let _searchText = _filterText;
        let _workflow = '';
        if (_filterText.includes(",")) {
            _searchText = _filterText.split(",")[0];
            _workflow = _filterText.split(",")[1];
        }
        setSelectedTaskStatus(_searchText);
        let _selectedTask = info?.filter?.query_name || 'Unit’s Tasks';
        _selectedTask = _selectedTask === 'Unit Tasks' ? 'Unit’s Tasks' : _selectedTask;
        setSelectedTaskFor(_selectedTask)
        if (!selectedFilter) {
            dispatch(setSelectedAuditfilter('All Audit Answers'));
        }
        let _workflowList = props.isDetailView ? childListView.workflowList : detailViewTaskList.workflowList;
        setWorkflowList(_workflowList);
        setSelectedTaskWorkflow(_workflow);

    }, [])


    useEffect(() => {
        props.setSearchValue(searchValue)
    }, [searchValue]);


    useEffect(() => {
        const franchiseAppLoginDetails = JSON.parse(localStorage.getItem('franchiseAppLoginDetails'))
        let shouldReturnToInitialPosition = JSON.parse(localStorage.getItem(`shouldReturnToInitialPos_${reduxApp.me.projectId}_${reduxApp.me.id}`));
        let callFrom;

        if (franchiseAppLoginDetails && franchiseAppLoginDetails.length >= 2) {
            const index = franchiseAppLoginDetails.length - 1;
            const previousUnitLoginDetails = franchiseAppLoginDetails[index]
            callFrom = previousUnitLoginDetails.callFrom
        } else if (franchiseAppLoginDetails && franchiseAppLoginDetails.length === 1) {
            const previousUnitLoginDetails = franchiseAppLoginDetails[0]
            callFrom = previousUnitLoginDetails.callFrom
        }
        if (callFrom === constants.LIST) {
            if (shouldReturnToInitialPosition) {
                if (franchiseAppLoginDetails.length === 1) {
                    localStorage.removeItem('franchiseAppLoginDetails')
                } else {
                    franchiseAppLoginDetails.pop()
                    localStorage.setItem('franchiseAppLoginDetails', JSON.stringify(franchiseAppLoginDetails))
                }
                localStorage.removeItem(`shouldReturnToInitialPos_${reduxApp.me.projectId}_${reduxApp.me.id}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!selectedFilter) {
            dispatch(setSelectedAuditfilter('All Audit Answers'));
        }
    }, [])

    useEffect(() => {
        let tenantCountry = getStringParam(reduxApp.me.tenant_country);
        if (COUNTRY_LIST_FOR_PHONE_SMS.indexOf(tenantCountry) > -1) {
            setIsCallingSupport(true);
        } else {
            setIsCallingSupport(false);
        }
        if (reduxApp.me.is_power_call) {
            setIsTwilio(true);
        }
    }, [reduxApp.me.tenant_country, reduxApp.me.is_power_call]);

    useEffect(() => {
        let object = getStringParam(props.object);
        if (object && stateObject !== object) {
            setStateObject(object);
        }
    }, [props.object]);

    useEffect(() => {
        if (isValidParam(activeTab)) {
            let report = false;
            if (activeTab.hasOwnProperty('info')) {
                let info = activeTab.info;
                if (isValidParam(info)) {
                    if (info.hasOwnProperty('isReport')) {
                        report = getBooleanParam(info.isReport);
                        setIsReport(report);
                    }
                }
            }
        }
    }, [])

    useEffect(() => {
        const parentObject = getStringParam(props.detailViewParams.parent_object);
        if (props.isDetailView) {
            if (props.detailViewParams.parent_object != null && props.detailViewParams.parent_object != '') {
                let arrChildObjectInfo = getChildObjectInfo(parentObject, props.object);
                let childObjectInfo = arrChildObjectInfo.length > 0 ? arrChildObjectInfo[0] : null;
                let addChildObj = false;
                let isRelationshipTab = false;
                let isQuotationTabExist = false;
                if (isValidParam(childObjectInfo)) {
                    if (childObjectInfo.childTableId === 10 && childObjectInfo.id === 0) {
                        addChildObj = true;
                        isRelationshipTab = true;
                    } else if (childObjectInfo.childTableId === 280) {
                        addChildObj = true;
                        isQuotationTabExist = true;
                    } else {
                        addChildObj = childObjectInfo['linkChildAdd'];
                    }
                    setChildObjectInfo(childObjectInfo)
                    setLinkChildTableId(childObjectInfo.id);
                    setIsChildObjectAdd(addChildObj);
                    setIsChildObjectFindAndLink(childObjectInfo['linkChildFindLink']);
                    setIsChildObjectCopy(childObjectInfo['linkChildCopy'])
                    setChildObjectLabel(childObjectInfo.title);
                    setIsQuatationTabExist(isQuotationTabExist);
                    setIsRelationshipTabExist(isRelationshipTab);
                }
            }
        }
    }, [props.detailViewParams.parent_object, props.isDetailView])


    const getActionPermission = () => {
        var promise = Promise.resolve(HTTPClient.get(endPoints.UNIT_CRM.UNIT_CRM_SETUP_GET, null));
        if (ParameterVerifier.isValidParam(promise)) {
            promise.then((response) => {
                if (ParameterVerifier.isValidParam(response)) {
                    setUnitPermission({ ...response });
                    dispatch(updateUnitpermissionList(response));
                }
            })
        }
    }

    const processFranchiseRecord = (id) => {
        let info = getActiveTabInfo();
        let param = { record_id: id };
        if (isValidParam(info) && info.hasOwnProperty("isUnitsAccount") && info.hasOwnProperty("UnitsAccountProjectId") && info.isUnitsAccount) {
            param.isUnitsAccount = info.isUnitsAccount;
            param.UnitsAccountProjectId = info.UnitsAccountProjectId;
        }
        const promise = isFranchiseRecord(param);
        promise.then((response) => {
            if (isValidParam(response)) {
                setIsFranchise(response);
            }
        });
    }


    useEffect(() => {
        let _isShowSetup = true;
        let type = props.listView.data.type;
        if (props.object === constants.MARKETING_TEMPLATE_OBJECT || props.object === constants.SALES_TEMPLATE_OBJECT || props.object === constants.WEB_FORMS || props.object === constants.SOURCE_OBJECT
            || props.object === constants.RELATIONS_OBJECT || props.object === constants.ASSIGNMENT_RULES_OBJECT || (props.object === constants.NOTES && type == 'DASHBOARD' || props.object === constants.NOTE_TYPES_OBJECT || props.object === constants.APPOINTMENT_TYPES_OBJECT || props.object === constants.CAMPAIGN_TYPES_OBJECT || props.object === constants.WORKFLOW_OBJECT
                || props.object === constants.USERS_OBJECT || props.object === constants.SECURITY_LEVEL_OBJECT || props.object === constants.EMAIL_INTEGRATION_OBJECT || props.object === constants.LIBRARY_OBJECT || props.object === constants.LANDING_PAGES_FROALA_OBJECT)
            || props.object === constants.SMS_TEMPLATE_OBJECT || props.object === constants.FIND_DUPLICATE_OBJECT
            || props.object === constants.CALL_SCRIPT_OBJECT || props.object === constants.AGREEMENT_TEMPLATE_OBJECT || props.object === constants.ROLL_UP_OBJECT
            || props.object === constants.PHONE_NUMBERS_OBJECT || props.object === constants.AUDIT_QUESTIONS_OBJECT || props.object === constants.COMPLIANCE_OBJECT
            || props.object === constants.LMS_USERS_OBJECT || props.object === constants.WEBHOOKS_OBJECT || props.object === constants.TEMPLATE_CATEGORIES) {
            _isShowSetup = false;
        }
        if (props.object == constants.CONTACTS_OBJECT && reduxApp.me.is_franchisee && props.listView.data.hasOwnProperty("doNotShowAction") && props.listView.data?.doNotShowAction) {
            _isShowSetup = false;
        }
      
        setIsShowSetup(_isShowSetup);
        if (props.parentObject === constants.ACCOUNTS_OBJECT && props.isDetailView && props.object === constants.TASKS) {
            if (props.detailViewParams?.parent_record_id) processFranchiseRecord(props.detailViewParams?.parent_record_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        let listviewFields = props.listView.data.fields;
        try {
            if (isValidParam(listviewFields)) {
                let searchFieldListData = listviewFields.filter(el => {
                    return el.is_search
                });
                let tempSearchFieldList = [];
                let firstObject = searchFieldListData[0];
                let flgShowLeadScoreTop = false;
                let j = 0;
                if (searchFieldListData[0] !== null && searchFieldListData[0] !== undefined && searchFieldListData[0].name === 'lead_score') {
                    flgShowLeadScoreTop = true;
                    j++;
                }
                for (let i = j; i < searchFieldListData.length; i++) {
                    tempSearchFieldList.push(searchFieldListData[i]);
                    if (flgShowLeadScoreTop) {
                        firstObject = searchFieldListData[1];
                        tempSearchFieldList.push(searchFieldListData[0]);
                        flgShowLeadScoreTop = false;
                    }
                }
                searchFieldListData = tempSearchFieldList;
                let availableSearchFieldListData = null;
                try {
                    searchFieldListData = getArrayParam(searchFieldListData);
                    searchFieldListData = searchFieldListData.filter(f => {
                        return f.field_type !== 'datetime' && f.field_type !== 'date'
                    });
                    availableSearchFieldListData = searchFieldListData.map((fieldObject, i) => {
                        return (
                            <MenuItem
                                key='menuitemListviewSearch'
                                className='menuitemListviewSearch'
                                value={fieldObject.name}
                                onClick={() => changeSearchFieldName(fieldObject.name, props.listView.data.fields)}
                                style={styles.popoverMenuItem}
                                fullWidth={true}
                                labelstyle={{ color: '#479ccf' }}
                                underlinestyle={{ display: 'none' }}
                                autowidth={"true"}
                                title={getMultilingualLabelName(stateObject, fieldObject.label)}//{fieldObject.label}
                            >
                                {getMultilingualLabelName(stateObject, fieldObject.label)}
                            </MenuItem>
                        );
                    });
                } catch (error) {
                    console.error("Error in 'listViewHeader.js -> useEffect()':" + error);
                }
                let availableSearchFieldListName = searchFieldListData.filter(f => {
                    return (f.field_type !== "datetime")
                });
                if (searchFieldListData.length > 0) {
                    setSearchFieldList(searchFieldListData);
                    setAvailableSearchFieldList(availableSearchFieldListData);
                    setSelectedSearchFieldName(isValidParam(availableSearchFieldListName[0].name) ? getStringParam(availableSearchFieldListName[0].name) : '');
                    setSelectedSearchFieldLabel(isValidParam(availableSearchFieldListName[0].label) ? getStringParam(availableSearchFieldListName[0].label) : '');
                }

                let tab = getActiveTab();
                let tabInfo = tab.info;
                if (stateObject === constants.WORKFLOW_OBJECT) {
                    const selectedStatusValueData = tabInfo.hasOwnProperty('statusfieldName') ? tabInfo.statusfieldName : 'Active';
                    updateListviewFilter(stateObject, popOverList, selectedStatusValueData);
                    setSelectedStatusValue(selectedStatusValueData);
                }

                if (isValidParam(tabInfo) && tabInfo.hasOwnProperty('searchFieldName') && tabInfo.hasOwnProperty('searchText')) {
                    let searchTextData = getStringParam(tabInfo.searchText);
                    let searchFieldName = getStringParam(tabInfo.searchFieldName);

                    if (searchFieldName !== '') {
                        let searchFieldlabel = searchFieldListData.filter((el) => {
                            return el.name === searchFieldName;
                        });
                        searchFieldlabel = searchFieldlabel.length > 0 ? getStringParam(searchFieldlabel[0].label) : '';
                        try {
                            if (searchFieldlabel.length > 0) {
                                setSearchText(searchTextData);
                                setSelectedSearchFieldName(searchFieldName)
                                setSelectedSearchFieldLabel(searchFieldlabel);
                            } else {
                                searchTextData = '';
                                searchFieldName = '';
                                setIsSearchExist(false);
                                setSearchText('');
                                setSelectedSearchFieldName('');
                                setSelectedSearchFieldLabel('');
                                delete tabInfo.searchFieldName;
                                delete tabInfo.searchText;
                                delete tabInfo.isSearchWithinList;
                                updateTabInfo(tabInfo);
                                refreshListView(stateObject);
                            }
                        } catch (error) {
                            console.error("Error in 'listViewHeader.js -> populateListViewSearch()':" + error);
                        }
                    }

                    if (props.listView.data.total_count === 0) {
                        searchTextData = "";
                    }
                    if (searchTextData !== '' && searchFieldName !== '') {
                        setIsSearchExist(true);
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'listViewHeader.js -> populateListViewSearch()':" + error);
        }
    }, [props.listView.data.fields, props.listView.data.total_count]);

    useEffect(() => {
        if (stateObject && stateObject !== EMPTY_STRING) {
            let isLookup = getBooleanParam(props.isLookup);
            let call_type = props.listView.data.type;
            let _isShowSetup = true;
            let listView = getObjectParam(props.listView.data);
            let totalRecordCount = getIntParam(listView.total_count);
            if (isLookup
                || (_isShowSetup && totalRecordCount === 0)
                || (props.isDetailView && linkChildTableId === 0)
                || stateObject === constants.INVOICES_OBJECT
                || (reduxApp.selectedModuleName === constants.MODULE_HUB && stateObject === constants.CASES_OBJECT)
            ) {
                _isShowSetup = false;
            } else if (!isLookup && !_isShowSetup && totalRecordCount > 0) {
                _isShowSetup = true;
            }

            if (!isLookup && !_isShowSetup && totalRecordCount > 0 && props.detailViewParams.parent_object === constants.INVENTORY_OBJECT && props.childObject === constants.PRODUCTS_OBJECT) {
                _isShowSetup = true;
            }

            if (HIDE_SETUP_OBJECT_LIST.includes(stateObject) || (stateObject === constants.NOTES && call_type === 'DASHBOARD')) {
                _isShowSetup = false;
            } else {
                let activeTabInfo = getActiveTabInfo();
                if (getBooleanParam(activeTabInfo.isReport)) {
                    let eventType = getStringParam(activeTabInfo.event_type_key);
                    let reportType = getStringParam(activeTabInfo.reportType);
                    if (stateObject === constants.CONTACTS_OBJECT && eventType === 'bounced' && (reportType === "BroadcastReport" || reportType === "AutoResponderReport")) {
                        _isShowSetup = false;
                    }
                }
            }
            let tabInfo = getActiveTabInfo();
            if (tabInfo.hasOwnProperty('isUnitsListView') && tabInfo.isUnitsListView) {
                _isShowSetup = false;
            }
            if ((stateObject === constants.ROYALTY_OBJECT || stateObject === constants.COMPLIANCE_OBJECT) && isReport) {
                _isShowSetup = false;
            }
            if (reduxApp.selectedModuleName === constants.MODULE_SALES && (stateObject === constants.NOTES) && isReport && (getStringParam(tabInfo.reportLabel) === "Activities By Sales Rep" || getStringParam(tabInfo.reportLabel) === "Activity Matrix")) {
                _isShowSetup = false;
            }
            if (stateObject === constants.AUDITS_OBJECT) {
                _isShowSetup = false;
            }
            if (stateObject === constants.AUDIT_QUESTIONS_OBJECT) {
                _isShowSetup = false;
            }
            if (stateObject === constants.LOCATION_OBJECT || stateObject === constants.TASKSLIST_OBJECT) {
                _isShowSetup = false;
            }
            if (stateObject === constants.LMS_USERS_OBJECT || stateObject === constants.WEBHOOKS_OBJECT || stateObject === constants.TEMPLATE_CATEGORIES) {
                _isShowSetup = false;
            }
            if (totalRecordCount > 0 && stateObject === constants.REPORTED_SALES_OBJECT) {
                _isShowSetup = true;
            }
            if (totalRecordCount > 0 && stateObject === constants.ROYALTY_OBJECT) {
                _isShowSetup = true;
            }
            if (totalRecordCount > 0 && stateObject === constants.UNITS_OBJECT) {
                _isShowSetup = true;
            }
            if (stateObject === constants.AUDIT_CHECKLISTS_OBJECT) {
                _isShowSetup = false;
            }
            if (totalRecordCount <= 0) {
                setSearchText('');
                if (searchFieldList.length > 0) {
                    let fieldListElement = searchFieldList[0];
                    setSelectedSearchFieldLabel(getStringParam(fieldListElement.label));
                    setSelectedSearchFieldName(getStringParam(fieldListElement.name));
                } else {
                    setSelectedSearchFieldLabel('')
                    setSelectedSearchFieldName('')
                }

            }
            if (stateObject == constants.CONTACTS_OBJECT && reduxApp.me.is_franchisee && props.listView.data.hasOwnProperty("doNotShowAction") && props.listView.data?.doNotShowAction) {
                _isShowSetup = false;
            }
            console.log("stateObject", stateObject)
            if ( props.parentObject === constants.PARTNERS_OBJECT && stateObject == constants.CONTACTS_OBJECT && reduxApp.me.is_franchisee ) {
                _isShowSetup = false;
            }
            setIsShowSetup(_isShowSetup);
        }
    }, [stateObject, props.childObject, props.detailViewParams.parent_object, props.isDetailView, props.isLookup, props.listView.data, props.listView.data.total_count, reduxApp.selectedModuleName]);


    function getMatchingData(array1, array2) {
        // Convert the second array to a Set for efficient lookup
        const idsSet = new Set(array2);

        // Filter array1 based on whether the id is in the Set
        return array1.filter(item => idsSet.has(item.id));
    }

    const openListviewActionList = (event) => {
        let object = props.object;
        let valid = true;
        if (object === constants.TASKS) {
            let isTaskEdit = false;
            let totalRecords = props.listView.data.records;
            let selectedRows = props.listView.selectedRows;
            let selectedIds = selectedRows.map(row => row.id);
            let matchedRecords = getMatchingData(totalRecords, selectedIds);
            isTaskEdit = matchedRecords.some(f => f.hasOwnProperty("workflow_task_type") && (f.workflow_task_type === "Email" || f.workflow_task_type === "SMS"));
            if (isTaskEdit) {
                valid = false;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.CANNOT_OPEN_EMAIL_AND_SMS_TASK, null);
            } else {
                valid = true;
            }
        }
        if (valid) {


            if (props.listView.selectedRows !== null && props.listView.selectedRows.length > 0) {
                if (object === constants.WORKFLOW_OBJECT && !reduxApp.me.is_customize_workflow) {
                    let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
                } else if ((object === constants.SOURCE_OBJECT || object === constants.LOCATION_OBJECT || object === constants.TASKSLIST_OBJECT || object === constants.NOTE_TYPES_OBJECT || object === constants.APPOINTMENT_TYPES_OBJECT || object === constants.CAMPAIGN_TYPES_OBJECT || object === constants.RELATIONS_OBJECT || object === constants.COMPLIANCE_OBJECT || object === constants.UNITS_OBJECT) && props.listView.selectedRows.length > 1) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.SELECT_MORE_RECORD_ACTION_ALERT, null)
                } else if ((object === constants.PAYMENTS_OBJECT) && (props.listView.selectedRows[0].paymentType === 'Refund')) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Oparations are not available for Refunded payment.', null);
                } else if (object === constants.WEBHOOKS_OBJECT && props.listView.selectedRows.length > 1) {
                    let permissionMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_MORE_RECORD_ACTION_ALERT;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
                } else if (unitPermission?.MS_BROADCAST === 0 && props.object === constants.BROADCAST_CAMPAIGNS_OBJECT && reduxApp.me.is_franchisee && !franchisorToken) {
                    let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
                } else if (unitPermission?.MS_AUTOMATION === 0 && props.object === constants.AUTOMATION_DESIGNER_OBJECT && reduxApp.me.is_franchisee && !franchisorToken) {
                    let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
                }

                else {
                    let popOverActions = generateActionsList(props.object, props.listView.selectedRows.length);
                    if (object === constants.TASKS && props.listView.selectedRows && reduxApp.me.is_franchisee) {
                        let totalRecords = props.listView.data.records;
                        let selectedRows = props.listView.selectedRows;
                        let selectedIds = selectedRows.map(row => row.id);
                        let matchedRecords = getMatchingData(totalRecords, selectedIds)
                        let isTaskTypeConatins = matchedRecords.some(item => item.task_type === 'Corrective Action');;
                        if (isTaskTypeConatins) {
                            popOverActions = popOverActions.filter((ele) => ele.value !== "Delete");
                        }
                    }
                    if (object === constants.CUSTOM_TABLE1_OBJECT && INTAKE_FORM.TENENT_PROJECTS_ID.includes(reduxApp.me.projectId)) {
                        popOverActions = popOverActions.filter((ele) => ele.value !== "Convert");
                    }
                    if (object === constants.TASKS && props.parentObject === constants.AUDITS_OBJECT) {
                        popOverActions = popOverActions.filter((ele) => ele.value === "Complete");
                    }
                    if (popOverActions.length > 0) {
                        let actionsListsItem = generateCustomMenuItems(popOverActions);
                        setActionsList(actionsListsItem);
                        event.preventDefault();
                        setOpenActionList(true);
                        setAnchorEl(event.currentTarget);
                    } else if (props.listView.selectedRows.length > 1 && popOverActions.length === 0 && props.object === constants.WORKFLOW_OBJECT) {
                        let permissionMessage = "There is no batch action to perform.";
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
                    }
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD, null);
            }
        }
    };

    const closeListviewActionList = () => {
        setOpenActionList(false);
    };

    const openTaskListTab = () => {
        dispatch(updateWorkflowList(workflowList, false));
        let _object = getStringParam(reduxDetailView.data.object);
        let _record = getObjectParam(reduxDetailView.record);
        let activeTab = getActiveTab();
        let urlLink = `/${constants.TASKS}/${constants.TASK_LIST}`;
        let newTab = {
            imgName: "fa fa-clock-o",
            object: constants.TASKS,
            url: urlLink,
            type: TYPE_DETAIL_VIEW_TASK_LIST,
            label: `Task List  - ${activeTab.label}`,
            info: {
                type: 'New',
                id: getIntParam(_record.id),
                object: constants.TASKS,
                perent_object: _object,
                label: `Task List  - ${activeTab.label}`,
                filter: {
                    ...activeTab?.info?.filter || {}
                },
                getExtraFilter: true,
                filterConfig: { ...activeTab?.info?.filterConfig || {} },
            }
        };
        addTab(newTab);
    }

    const generateActionsList = (object, recordCount) => {

        let actionListMultiLingual = {
            'Add Notes': getLocalizedStrings().label.COMMON.ADD_NOTES,
            'Add to Group': getLocalizedStrings().label.COMMON.ADD_TO_GROUP,
            'Assign': getLocalizedStrings().label.COMMON.ASSIGN,
            'Convert to Accounts': getLocalizedStrings().label.COMMON.CONVERT_TO_ACCOUNTS,
            'Convert': getLocalizedStrings().label.COMMON.CONVERT,
            'Delete': getLocalizedStrings().label.COMMON.DELETE,
            'Map': getLocalizedStrings().label.COMMON.MAP,
            'Merge': getLocalizedStrings().label.COMMON.MERGE,
            'Edit': getLocalizedStrings().label.COMMON.EDIT,
            'Send Email': getLocalizedStrings().label.COMMON.SEND_EMAIL,
            'Push': getLocalizedStrings().label.COMMON.PUSH,
            'To Excel': getLocalizedStrings().label.COMMON.TO_EXCEL,
            'Approved': getLocalizedStrings().label.COMMON.APPROVED,
            'Sending for Approval': getLocalizedStrings().label.COMMON.SENDING_FOR_APPROVAL,
            'Rejected': getLocalizedStrings().label.COMMON.REJECTED,
            'Unsubscribe': getLocalizedStrings().label.COMMON.UNSUBSCRIBE,
            'Complete': getLocalizedStrings().label.COMMON.COMPLETE,
            'Reschedule': getLocalizedStrings().label.COMMON.RESCHEDULE,
            'Active': getLocalizedStrings().label.COMMON.ACTIVE,
            'Inactive': getLocalizedStrings().label.COMMON.INACTIVE,
            'Publish': getLocalizedStrings().label.COMMON.PUBLISH,
            'Unpublish': getLocalizedStrings().label.COMMON.UNPUBLISH,
            'COPY': getLocalizedStrings().label.COMMON.COPY,
            'Download': getLocalizedStrings().label.COMMON.DOWNLOAD,
            'Restore': getLocalizedStrings().label.COMMON.RESTORE,
            'Send SMS': getLocalizedStrings().label.COMMON.SEND_SMS,
            'Login': getLocalizedStrings().label.LOGIN.LOG_IN,
            'Verify Email': getLocalizedStrings().label.COMMON.VERIFY_EMAIL,
            'Send DocuSign': getLocalizedStrings().label.COMMON.DOCUSIGN,
            'Add to Quickbooks': getLocalizedStrings().label.COMMON.ADD_QUICKBOOKS,
            'Push to QB': getLocalizedStrings().label.COMMON.PUSH_TO_QB,
            'Add to Xero': getLocalizedStrings().label.COMMON.PUSH_TO_XERO,
            'View': getLocalizedStrings().label.COMMON.VIEW,
            'Close': getLocalizedStrings().label.COMMON.CLOSE,
            'Cancel': getLocalizedStrings().label.COMMON.CANCEL,
            'Generate Invoice': getLocalizedStrings().label.COMMON['Generate Invoice'],
            'Add Payment': getLocalizedStrings().label.COMMON['Add Payment'],
            'Print': getLocalizedStrings().label.COMMON['Print'],
            'To XMS': getLocalizedStrings().label.COMMON.TO_XMS,
            'Refund': getLocalizedStrings().label.COMMON.REFUND,
            'Add Task': getLocalizedStrings().label.COMMON.ADD_TASK,
            'Invoice': getLocalizedStrings().label.COMMON.INVOICE,
            'Collect': object === constants.REPORTED_SALES_OBJECT ? getLocalizedStrings().label.COMMON.MARK_PAID : getLocalizedStrings().label.COMMON.COLLECT,
            'Email': getLocalizedStrings().label.COMMON.EMAIL,
            'Email Invoice': "Email Invoice",
            'Post': getLocalizedStrings().label.COMMON.POST,
            'Royalty Exceptions': getLocalizedStrings().label.COMMON.EXCEPTION,
            'Add To Plan': getLocalizedStrings().label.COMMON.ADD_TO_PLAN,
            "Invoice Recipients": getLocalizedStrings().label.COMMON.INVOICE_RECIPIENTS,
            "Unlink": getLocalizedStrings().label.COMMON.UNLINK,
            "Share with": object == constants.PARTNERS_OBJECT ? "Share with Units" : getLocalizedStrings().label.COMMON.SHARE_WITH_UNITS,
            'Email to Unit': getLocalizedStrings().label.COMMON.EMAIL_TO_UNIT,
            'Disable': getLocalizedStrings().label.COMMON.DISABLE
        }
        let UnitsAccountProjectId = 0;
        let tabInfo = getActiveTabInfo();
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        let isUnitsAccount = false;
        let selectedRow = props.listView.selectedRows;;

        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
            UnitsAccountProjectId = tabInfo.UnitsAccountProjectId;
        }
        let isCallFromDetailView = false;
        if (props.isDetailView !== undefined) {
            if (isValidParam(props.isDetailView)) {
                isCallFromDetailView = true;
            }
        }
        let isNoteChild = false;
        let isClientConnect = false;
        let popOverActions = [];
        let actionsListData = props.listView.data !== null && props.listView.data.hasOwnProperty('actions') ? props.listView.data.actions : null;
        actionsListData = getArrayParam(actionsListData);
        let group_by_condition = props.listView.data.group_by_condition;
        isNoteChild = isObjectChildExists(object, constants.NOTES);
        if (isInXpress || (isUnitsAccount && object !== constants.ATTACHMENT_OBJECT)) {
            actionsListData = actionsListData.filter(f => {
                return f.action_name === 'To Excel'
            });
        }
        if (object === constants.ATTACHMENT_OBJECT) {
            actionsListData = actionsListData.filter(f => {
                return f.action_name !== 'View'
            });
        }
        if (object === constants.ACCOUNTS_OBJECT && isRelationshipTabExist) {
            actionsListData = actionsListData.filter(f => {
                return f.action_name !== 'Send SMS'
            });
        }
        if (isUnitsAccount && UnitsAccountProjectId > 0 && object === constants.ATTACHMENT_OBJECT) {
            actionsListData = actionsListData.filter(f => {
                return f.action_name == 'Download'
                    || f.action_name == 'View'
            });
        }
        let moduleSetupListArr = reduxApp.me.module_setup_list;
        if (moduleSetupListArr !== null && moduleSetupListArr.length > 0) {
            for (let i = 0; i < moduleSetupListArr.length; i++) {
                const moduleSetupId = moduleSetupListArr[i];
                if (moduleSetupId === constants.MS_CLIENT_CONNECT) {
                    isClientConnect = true;
                    break;
                }
            }
        }
        if (actionsListData !== null && actionsListData !== undefined) {
            if (object === constants.WORKFLOW_OBJECT) {
                if (!isClientConnect || (isClientConnect && reduxApp.me.t_name !== 'Admin')) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.SHARE
                    });
                }

            }
            if ((object === constants.ACCOUNTS_OBJECT || object === constants.UNITS_OBJECT) && isValidParam(actionsListData) && selectedRow.myclient_id == 0) {
                actionsListData = actionsListData.filter((item) => item !== constants.INVOICE_RECIPIENTS);
            }
            if ((object !== constants.CONTACTS_OBJECT && object !== constants.PARTNERS_OBJECT) && isValidParam(actionsListData)) {
                actionsListData = actionsListData.filter((item) => item.action_name !== constants.SHARE_WITH_UNITS);
            }
            if ((object === constants.PARTNERS_OBJECT) && isValidParam(actionsListData)) {
                actionsListData = actionsListData.filter((item) => item.action_name !== constants.ADD_TASK);
            }
            if ((object === constants.SALES_LEADS_OBJECT) && isValidParam(actionsListData)) {
                actionsListData = actionsListData.map((item) => {
                    if (item.action_name === getLocalizedStrings().label.COMMON.PUSH) {
                        return { ...item, action_name: getLocalizedStrings().label.COMMON.ASSIGN }; // Update the action_name to "Assign"
                    }
                    return item;
                });
            }
            if (reduxApp.me.is_franchisee && isValidParam(actionsListData)) {
                actionsListData = actionsListData.filter((item) => {
                    return item.action_name !== constants.SHARE_WITH_UNITS
                }
                );
            }

            if (props.isDetailView && (props.childObject === constants.ACCOUNTS_OBJECT || props.childObject === constants.OPPORTUNITIES_OBJECT)) {
                if (isValidParam(actionsListData)) {
                    actionsListData = actionsListData.filter(f => { return f.action_name !== 'Add Task' });
                }
            }
            if (tabInfo.isReport && isValidParam(actionsListData)) {
                actionsListData = actionsListData.filter(f => { return f.action_name !== 'Add Task' });
            }
            if (object === constants.PAYMENTS_OBJECT || object === constants.PRODUCTS_OBJECT) {
                let isXero = false;
                let xeroIntegrationType = null;
                let isXeroPayment = false;
                let xeroIntegration = reduxApp.me.hasOwnProperty('xero_integration') ? reduxApp.me.xero_integration : null;
                if (isValidParam(xeroIntegration)) {
                    isXero = xeroIntegration.is_xero;
                    xeroIntegrationType = xeroIntegration.xero_integration_type;
                    isXeroPayment = xeroIntegration.is_xero_payment;
                }
                let is_qbsetup = getBooleanParam(reduxApp.me.is_qbsetup);

                if (isXero) {

                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.ADD_QUICKBOOKS
                    });
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.PUSH_TO_QUICKBOOKS
                    });
                    if (xeroIntegrationType !== null && xeroIntegrationType === 'MANUALLY' && isXeroPayment &&
                        object === constants.PAYMENTS_OBJECT) {
                        let tempAction = {};
                        tempAction.action_name = 'Add to Xero';
                        tempAction.single = true;
                        tempAction.batch = true;
                        actionsListData.push(tempAction);
                    }
                }
                if (object === constants.PRODUCTS_OBJECT && is_qbsetup == false) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== "Push to QB"
                    });
                }

            }

            if (props.listView.data.parent_object === constants.TASKS &&
                (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT)) {
                actionsListData = actionsListData.filter(f => {
                    return f.action_name !== constants.DELETE
                });
            }

            if (isCallFromDetailView && linkChildTableId === 0 && object !== constants.AUDIT_ANSWERS_OBJECT) {
                actionsListData = actionsListData.filter(f => {
                    if (recordCount === 1) {
                        return f.action_name !== constants.CONVERT &&
                            f.action_name !== constants.ADD_TO_GROUP && f.action_name !== constants.SHARE &&
                            f.action_name !== constants.ASSIGN && f.action_name !== constants.MAP &&
                            f.action_name !== constants.TO_EXCEL && f.action_name !== constants.PUSH &&
                            f.action_name !== constants.MERGE
                    } else {
                        return f.action_name !== constants.CONVERT &&
                            f.action_name !== constants.ADD_TO_GROUP && f.action_name !== constants.SHARE &&
                            f.action_name !== constants.ASSIGN && f.action_name !== constants.MAP &&
                            f.action_name !== constants.TO_EXCEL && f.action_name !== constants.PUSH &&
                            f.action_name !== constants.MERGE && f.action_name !== constants.EDIT &&
                            f.action_name !== constants.SEND_EMAIL
                    }

                });
            }


            if (isReport) {
                actionsListData = actionsListData.filter(f => {
                    return f.action_name !== constants.CONVERT
                });
            }
            if (object === constants.ROYALTY_OBJECT) {
                actionsListData = actionsListData.filter(f => {
                    return f.action_name !== constants.CONVERT &&
                        f.action_name !== constants.EDIT &&
                        f.action_name !== constants.DELETE
                });
            }

            if (object === constants.REPORTED_SALES_OBJECT) {
                let selectedRows = props.listView.selectedRows;
                selectedRows = isValidParam(selectedRows) ? selectedRows : [];
                if (selectedRows.length > 2) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.INVOICE &&
                            f.action_name !== constants.EMAIL_INVOICE &&
                            f.action_name !== constants.POST &&
                            f.action_name !== constants.COLLECT
                    });
                }
                if (selectedRows.length > 1) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.EDIT
                    });
                }
            }

            let showInvoiceAllButton;
            let showEmailAllButton;
            let showPostAllButton;
            let isSubmittedRR;
            let showEditButton;
            if (reduxDetailView.data != null) {
                showInvoiceAllButton = getBooleanParam(reduxDetailView.data.show_invoice_all_button);
                showEmailAllButton = getBooleanParam(reduxDetailView.data.show_email_all_button);
                showPostAllButton = getBooleanParam(reduxDetailView.data.show_post_all_button);
                isSubmittedRR = getBooleanParam(reduxDetailView.data.is_submitted);
                showEditButton = true;
            }

            if (object === constants.REPORTED_SALES_OBJECT && isCallFromDetailView) {
                const excelActionExists = actionsListData.some(action =>
                    action.action_name === constants.TO_EXCEL
                );
                if (!excelActionExists) {
                    let tempAction = {};
                    tempAction.action_name = constants.TO_EXCEL;
                    tempAction.single = true;
                    tempAction.batch = true;
                    actionsListData.push(tempAction);

                } if (!showEditButton) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.EDIT
                    });
                } if (isSubmittedRR) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.EDIT && f.action_name !== 'Approved' && f.action_name !== 'Rejected'
                    });
                }
                if (reduxDetailView.data?.show_es_buttons == true) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== 'Approved' && f.action_name !== 'Rejected'
                    });
                }
                if (!isSubmittedRR) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.COLLECT
                    });
                }

                if (!showInvoiceAllButton) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.INVOICE
                    });
                }

                if (!showEmailAllButton) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.EMAIL_INVOICE
                    });
                }

                if (!showPostAllButton) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.POST
                    });
                }
            }
            if (object === constants.REPORTED_SALES_OBJECT && isCallFromDetailView && reduxApp.me.is_franchisee && reduxApp.me.is_franchisor) {

                actionsListData = actionsListData.filter(f => {
                    return f.action_name == constants.TO_EXCEL || f.action_name == "Approved" || f.action_name == "Rejected"

                });
            }
            if (object === constants.REPORTED_SALES_OBJECT && isCallFromDetailView == false && reduxApp.me.is_franchisee && reduxApp.me.is_franchisor) {
                actionsListData = actionsListData.filter(f => {
                    return f.action_name == constants.TO_EXCEL || f.action_name == "Sending for Approval"

                });
            }
            if (object === constants.REPORTED_SALES_OBJECT && reduxApp.me.is_franchisee == true && reduxApp.me.is_franchisor == false) {
                actionsListData = actionsListData.filter(f => {
                    return f.action_name == constants.TO_EXCEL || f.action_name == 'Sending for Approval'
                });
            }
            if (object === constants.REPORTED_SALES_OBJECT && reduxApp.me.is_franchisor == true && reduxApp.me.is_franchisee == false) {
                actionsListData = actionsListData.filter(f => {
                    return f.action_name == constants.TO_EXCEL || f.action_name == constants.EDIT || f.action_name == "Approved" || f.action_name == "Rejected" || f.action_name == constants.EMAIL_TO_UNIT
                        || f.action_name == constants.COLLECT || f.action_name == constants.EMAIL_INVOICE || f.action_name == constants.INVOICE || f.action_name == constants.POST

                });

            }
            if (props.object === constants.PARTNERS_OBJECT && reduxApp.me.is_franchisee) {
                actionsListData = actionsListData.filter(f => {
                    return f.action_name == constants.TO_EXCEL ;

                });
            }
            if (object === constants.REPORTED_SALES_OBJECT && props.isDetailView && !isSubmittedRR && reduxApp.me.is_franchisor == true && props.parentObject === constants.ROYALTY_RUN_OBJECT) {
                let _selectedRows = childListView.selectedRows;
                if (_selectedRows.length > 1) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== "Approved" && f.action_name !== "Rejected" && f.action_name !== constants.EMAIL_TO_UNIT

                    });

                }

            }
            if (object === constants.REPORTED_SALES_OBJECT && isCallFromDetailView && reduxApp.me.is_franchisee && reduxApp.me.is_franchisor) {
                let editAction = actionsListData.find(action => action.action_name === constants.EDIT);
                if (!editAction) {
                    const tempEditAction = { action_name: constants.EDIT, single: true, batch: true };
                    actionsListData.push(tempEditAction);
                }
                let collectAction = actionsListData.find(action => action.action_name === constants.COLLECT);
                if (!collectAction) {
                    const tempCollectAction = { action_name: constants.COLLECT, single: true, batch: false };
                    actionsListData.push(tempCollectAction);
                }
                let emailAction = actionsListData.find(action => action.action_name === constants.EMAIL_INVOICE);
                if (!emailAction) {
                    const tempEmailAction = { action_name: constants.EMAIL_INVOICE, single: true, batch: false };
                    actionsListData.push(tempEmailAction);
                }
                let invoiceAction = actionsListData.find(action => action.action_name === constants.INVOICE);
                if (!invoiceAction) {
                    const tempInvoiceAction = { action_name: constants.INVOICE, single: true, batch: false };
                    actionsListData.push(tempInvoiceAction);
                }
                let postAction = actionsListData.find(action => action.action_name === constants.POST);
                if (!postAction) {
                    const tempPostAction = { action_name: constants.POST, single: true, batch: false };
                    actionsListData.push(tempPostAction);
                }

                if (!showEditButton || !isSubmittedRR) {
                    actionsListData = actionsListData.filter(f => f.action_name !== constants.COLLECT);
                    actionsListData = actionsListData.filter(f => f.action_name !== constants.EMAIL_INVOICE);
                    actionsListData = actionsListData.filter(f => f.action_name !== constants.INVOICE);
                    actionsListData = actionsListData.filter(f => f.action_name !== constants.POST);
                } else {
                    actionsListData = actionsListData.filter(f => f.action_name !== constants.EDIT);
                }
            }

            if (reduxApp.me.is_franchisor && props.isDetailView && props.object === constants.REPORTED_SALES_OBJECT && props.parentObject === constants.ROYALTY_RUN_OBJECT) {
                actionsListData.push({ action_name: constants.LOGIN, single: true, batch: true });
            }

            if (reduxApp.selectedModuleName === constants.MODULE_SALES && (props.listView.data.object === constants.NOTES) && isReport && (getStringParam(tabInfo.reportLabel) === "Activities By Sales Rep" || getStringParam(tabInfo.reportLabel) === "Activity Matrix")) {
                actionsListData = actionsListData.filter(f => {
                    return f.action_name !== constants.DELETE
                });
            }
        }

        if (actionsListData !== null && actionsListData !== undefined) {
            if (isCallFromDetailView) {
                actionsListData = actionsListData.filter(f => {
                    return f.action_name !== constants.ADD_NOTES &&
                        f.action_name !== constants.RESCHEDULE &&
                        f.action_name !== constants.COPY
                });

                if (object === constants.APPOINTMENTS || object === constants.TASKS) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.DELETE

                    });
                }
                if (object === constants.PAYMENTS_OBJECT && recordCount > 1) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.EDIT
                    });
                }

                if (object === constants.TIME_OBJECT && recordCount > 1) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.EDIT
                    });
                }

                if (object === constants.AUDIT_QUESTIONS_OBJECT && recordCount > 1) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.EDIT
                    });
                }

                if (object === constants.ATTACHMENT_OBJECT && recordCount > 1) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.DOWNLOAD
                    });
                }

                if (object === constants.GROUP_OBJECT) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.DELETE
                    });
                }
                if (object === constants.FIND_DUPLICATE_OBJECT) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name === constants.DELETE || f.action_name === constants.MERGE || f.action_name === constants.CONVERT
                    });
                }
            } else if (object === constants.MARKETING_TEMPLATE_OBJECT && recordCount > 1) {
                actionsListData = actionsListData.filter(f => {
                    return (f.action_name !== constants.ADD_NOTES /*&& f.action_name !== constants.DELETE*/
                        && f.action_name !== constants.INACTIVE && f.action_name !== constants.ACTIVE)
                });
            } else if (object === constants.SALES_TEMPLATE_OBJECT && recordCount > 1) {
                actionsListData = actionsListData.filter(f => {
                    return (f.action_name !== constants.ADD_NOTES
                        && f.action_name !== constants.INACTIVE && f.action_name !== constants.ACTIVE)
                });
            } else if (object === constants.AGREEMENT_TEMPLATE_OBJECT && recordCount > 1) {
                actionsListData = actionsListData.filter(f => {
                    return (f.action_name !== constants.ADD_NOTES
                        && f.action_name !== constants.INACTIVE && f.action_name !== constants.ACTIVE)
                });
            } else if (object === constants.SMS_TEMPLATE_OBJECT && recordCount > 1) {
                actionsListData = actionsListData.filter(f => {
                    return (f.action_name !== constants.ADD_NOTES
                        && f.action_name !== constants.INACTIVE && f.action_name !== constants.ACTIVE)
                });
            } else if (object === constants.WEB_FORMS && recordCount > 1) {
                actionsListData = actionsListData.filter(f => {
                    return (f.action_name !== constants.PUBLISH
                        && f.action_name !== constants.UNPUBLISH)
                });
            } else if ((object === constants.SOURCE_OBJECT || object === constants.RELATIONS_OBJECT
                || object === constants.NOTE_TYPES_OBJECT || object === constants.APPOINTMENT_TYPES_OBJECT || object === constants.CAMPAIGN_TYPES_OBJECT) && recordCount > 1) {
                actionsListData = actionsListData.filter(f => {
                    return (f.action_name !== constants.DELETE)
                });
            } else if (object === constants.WORKFLOW_OBJECT) {
                if (recordCount > 1) {
                    actionsListData = actionsListData.filter(f => (f.action_name === constants.PUSH || f.action_name === constants.SHARE));
                } else {
                    if (group_by_condition === 'All') {
                        actionsListData = actionsListData.filter(f => (f.action_name !== constants.DELETE));
                    }
                }

            } else if (object === constants.EMAIL_INTEGRATION_OBJECT && recordCount > 1) {
                actionsListData = actionsListData.filter(f => {
                    return (f.action_name !== constants.VERIFY_EMAIL)
                });
            } else if (object === constants.CALL_SCRIPT_OBJECT && recordCount > 1) {
                actionsListData = actionsListData.filter(f => {
                    return (f.action_name !== constants.ADD_NOTES
                        && f.action_name !== constants.INACTIVE && f.action_name !== constants.ACTIVE)
                });
            } else if (object === constants.LANDING_PAGES_FROALA_OBJECT) {
                if (recordCount > 1) {
                    actionsListData = actionsListData.filter(f => (f.action_name === constants.DELETE || f.action_name === constants.PUSH));
                }
            } else if (object === constants.UNITS_OBJECT) {
                if (recordCount > 1) {
                    actionsListData = actionsListData.filter(f => (f.action_name !== constants.EXCEPTION));
                }
            } else if (object === constants.COMPLIANCE_OBJECT && recordCount > 0) {
                actionsListData = actionsListData.filter(f => (f.action_name === constants.ADD_NOTES || f.action_name == constants.PUSH));
            } else {
                if (!isNoteChild && object != constants.CONTACTS_OBJECT && object != constants.ACCOUNTS_OBJECT && object !== constants.PARTNERS_OBJECT) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.ADD_NOTES
                    });
                }
            }
            if (object === constants.JOBS_OBJECT && recordCount > 1) {
                actionsListData = actionsListData.filter(f => (f.action_name !== constants.ADD_PAYMENT && f.action_name !== constants.GENERATE_INVOICE));
            }
            if (object === constants.ROYALTY_RUN_OBJECT && recordCount > 1) {
                actionsListData = actionsListData.filter(f => (f.action_name !== constants.DELETE));
            }
            if (object === constants.AUDIT_ANSWERS_OBJECT) {
                actionsListData = actionsListData.filter(f => {
                    return f.action_name !== constants.PRINT
                });
            }

            if (object !== constants.ATTACHMENT_OBJECT) {
                sortArrayObjectByProperty(actionsListData, 'action_name');
            }

            if (!isCallFromDetailView) {
                actionsListData = actionsListData.filter(f => {
                    return f.action_name !== constants.UNLINK
                });
            }

            // Arrange To XMS before To Excel in action list
            if (reduxApp.me.is_inxpress && (props.object === constants.ACCOUNTS_OBJECT || props.object === constants.PARTNERS_OBJECT)) {
                let toExcelExist = actionsListData.filter(f => {
                    return f.action_name === constants.TO_EXCEL
                });
                let toXMSExist = actionsListData.filter(f => {
                    return f.action_name === constants.TO_XMS
                });
                if (toExcelExist.length > 0 && toXMSExist.length > 0) {

                    let toExcelindex = actionsListData.findIndex((f => f.action_name === constants.TO_EXCEL));
                    let xmsindex = actionsListData.findIndex((f => f.action_name === constants.TO_XMS));
                    actionsListData.splice(xmsindex, 1);
                    actionsListData.splice(toExcelindex, 0, toXMSExist[0]);
                }
            }

            if (object === constants.ATTACHMENT_OBJECT) {
                let selectedRows = props.listView.selectedRows;
                selectedRows = isValidParam(selectedRows) ? selectedRows : [];
                if (selectedRows.length > 2) {
                    actionsListData = actionsListData.filter(f => {
                        return f.action_name !== constants.EDIT
                    });
                }
            }
            map(actionsListData, function (actionItem, i) {
                if (isCallFromDetailView) {
                    if (actionItem.action_name !== constants.CONVERT_TO_ACCOUNTS
                        && actionItem.action_name !== constants.PUSH) {
                        if (recordCount > 2) {
                            if (actionItem.action_name !== constants.MERGE && actionItem.action_name !== "Email to Unit") {
                                let data = {};
                                data.value = actionItem.action_name;
                                data.label = actionListMultiLingual[actionItem.action_name];
                                popOverActions.push(data);

                            }
                        } else if ((recordCount === 1 && actionItem.single) || (recordCount > 1 && actionItem.batch)) {
                            let data = {};
                            data.value = actionItem.action_name;
                            data.label = actionListMultiLingual[actionItem.action_name];
                            popOverActions.push(data);

                        }
                    }
                } else if (isReport && object === constants.TASKS) {
                    if (actionItem.action_name !== constants.COPY
                        && actionItem.action_name !== constants.SEND_EMAIL
                        && actionItem.action_name !== constants.SEND_TEXT && actionItem.action_name !== constants.SEND_DOCUSIGN) {
                        if (recordCount > 2) {
                            if (actionItem.action_name !== constants.MERGE && actionItem.action_name !== constants.ADD_NOTES
                                && actionItem.action_name !== constants.VERIFY_EMAIL) {
                                let data = {};
                                data.value = actionItem.action_name;
                                data.label = actionListMultiLingual[actionItem.action_name];
                                popOverActions.push(data);
                            }
                        } else if ((recordCount === 1 && actionItem.single) || (recordCount > 1 && actionItem.batch)) {
                            let data = {};
                            data.value = actionItem.action_name;
                            data.label = actionListMultiLingual[actionItem.action_name];
                            popOverActions.push(data);
                        }
                    }
                } else {
                    if (actionItem.action_name !== constants.EDIT && actionItem.action_name !== constants.COPY
                        && actionItem.action_name !== constants.SEND_EMAIL
                        && actionItem.action_name !== constants.SEND_TEXT && actionItem.action_name !== constants.SEND_DOCUSIGN) {
                        if (recordCount > 2) {
                            if (actionItem.action_name !== constants.MERGE && actionItem.action_name !== constants.ADD_NOTES
                                && actionItem.action_name !== constants.VERIFY_EMAIL) {
                                let data = {};
                                data.value = actionItem.action_name;
                                data.label = actionListMultiLingual[actionItem.action_name];
                                popOverActions.push(data);

                            }
                        } else if ((recordCount === 1 && actionItem.single) || (recordCount > 1 && actionItem.batch)) {
                            let data = {};
                            data.value = actionItem.action_name;
                            data.label = actionListMultiLingual[actionItem.action_name];
                            popOverActions.push(data);

                        }
                    }
                }
            });
        }

        return popOverActions;
    };

    const deleteTypes = (recordId, title) => {
        let params = null;
        let promise = null;
        let url = null;
        try {
            if (props.object === constants.NOTE_TYPES_OBJECT) {
                url = endPoints.NOTE_TYPE.EDIT;
            } else if (props.object === constants.APPOINTMENT_TYPES_OBJECT) {
                url = endPoints.NOTE_TYPE.EDIT;
            } else if (props.object === constants.CAMPAIGN_TYPES_OBJECT) {
                url = endPoints.CAMPAIGN_TYPES.EDIT;
            }
            params = {
                recordId: recordId[0]
            }
            promise = Promise.resolve(HTTPClient.get(url, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.hasOwnProperty('t_system_type') || response.hasOwnProperty('t_default')) {
                            if (props.object === constants.NOTE_TYPES_OBJECT && response.hasOwnProperty('t_system_type') && response.hasOwnProperty('t_default') && (response.t_system_type === 1 || response.t_default === 1)) {
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SETTINGS.SYSTEM_NOTE_TYPE_DELETE_ALERT, null);
                            } else if (props.object === constants.APPOINTMENT_TYPES_OBJECT && response.hasOwnProperty('t_system_type') && response.hasOwnProperty('t_default') && (response.t_system_type === 1 || response.t_default === 1)) {
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SETTINGS.SYSTEM_APPOINTMENT_TYPE_DELETE_ALERT, null);
                            } else if (props.object === constants.CAMPAIGN_TYPES_OBJECT && response.hasOwnProperty('t_system_type') && response.t_system_type === 1) {
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SETTINGS.SYSTEM_CAMPAIGN_TYPE_DELETE_ALERT, null);
                            } else {
                                let msg = "'" + title + "' " + getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
                                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => deleteTypesOnConfirm(recordId, title), null);
                            }
                        }
                    }
                });
            }
        } catch (error) {
            console.error("error in listViewHeader.js -> deleteTypes" + error)
        }
    }


    const deleteTypesOnConfirm = (recordId, title) => {
        let params = { id: recordId };
        const response = deleteAction(props.object, params);
        response.then((response) => {
            if (props.object === constants.NOTE_TYPES_OBJECT || props.object === constants.APPOINTMENT_TYPES_OBJECT || props.object === constants.CAMPAIGN_TYPES_OBJECT) {
                if (props.object === constants.NOTE_TYPES_OBJECT) {
                    removeObjectListItemFromRedux(constants.NOTE_OBJECT, 'note_type', title);
                }
                if (props.object === constants.APPOINTMENT_TYPES_OBJECT) {
                    removeObjectListItemFromRedux(constants.APPOINTMENTS, 'event_type', title);
                }
                let msg = '';
                if (isValidParam(response.error) && isValidParam(response.error.message)) {
                    let msg = getStringParam(response.error.message).trim().split(' ').join('_').toUpperCase();
                }
                if (msg === 'RECORD_CANNOT_DELETED' || msg === 'RECORD_DOES_NOT_EXISTS') {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.SETTINGS[msg], styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    refreshListView(props.object);
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    refreshListView(props.object);
                }
            }
        });
    }
    const deleteRelations = (recordId, title) => {
        try {
            let msg = "'" + title + "' " + getLocalizedStrings().message.BATCH_ACTION.PERMANENT_DELETE_MSG;
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => deleteRelationsOnConfirmed(recordId), null);

        } catch (error) {
            console.error("error in listViewHeader.js -> deleteRelations" + error)
        }
    }
    const deleteRelationsOnConfirmed = (recordId) => {
        let params = { id: recordId };
        const response = deleteAction(props.object, params);
        response.then((response) => {
            if (isValidParam(response)) {
                if (response.data.hasOwnProperty('relation_used')) {
                    if (getBooleanParam(response.data.relation_used) === true) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.SETTINGS.RELATION_LINK_INFO_ALERT_MSG, null);
                    } else {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        refreshListView(props.object);
                    }
                }
            }
        });
    }
    const emptyRecycleBin = () => {
        try {
            let msg = getLocalizedStrings().message.BATCH_ACTION.EMPTY_RECYCLE_BIN;
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => emptyRecycleBinOnConfirmed(), null);
        } catch (error) {
            console.error("error in listViewHeader.js -> emptyRecycleBin" + error)
        }
    }
    const emptyRecycleBinOnConfirmed = () => {
        let params = null;
        params = {
            callFrom: 'EMPTY_BIN',
            totalCount: props.listView.data.total_count
        };
        const response = deleteAction(props.object, params);
        response.then((response) => {
            if (isValidParam(response)) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                refreshListView(props.object);
            }
        });
    }
    const changeWorkflowStatus = (recordId, value) => {
        let params = null;
        let response = null;
        let endPoint = null;
        let msg = '';
        let statusData = '';
        let message = '';
        let selectedRows = props.listView.selectedRows;
        let selectedRowTitle = selectedRows[0].title;
        let setValue = reduxListView.filterByInfo.filterValue !== '' ? reduxListView.filterByInfo.filterValue : selectedStatusValue;
        try {
            if (value !== setValue) {

                if (value === constants.ACTIVE) {
                    statusData = getLocalizedStrings().label.COMMON.ACTIVE;
                } else if (value === constants.INACTIVE) {
                    statusData = getLocalizedStrings().label.COMMON.INACTIVE;
                }
                params = {
                    workflowId: recordId,
                    status: value
                };

                if (props.listView.data.object === constants.WORKFLOW_OBJECT && selectedRowTitle === 'Brandwide' && value === constants.INACTIVE) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You cannot Inactive this workflow.', null);
                } else {
                    endPoint = endPoints.WORKFLOWS.CHANGE_STATUS;
                    response = Promise.resolve(HTTPClient.get(endPoint, params));
                    response.then((response) => {
                        if (isValidParam(response)) {
                            if (isValidParam(response.status) && response.status === -1) {
                                message = getStringParam(response.error.message).trim().split(' ').join('_').toUpperCase();
                                msg = getLocalizedStrings().message.WORKFLOWS[message];
                                if (getStringParam(response.error.message) === 'Same statusData') {
                                    msg = getLocalizedStrings().message.WORKFLOWS['SAME_STATUS'] + ' ' + statusData + ' ' + getLocalizedStrings().label.COMMON.STATUS;
                                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                } else if (getStringParam(response.error.message).trim() === 'Only one active workflow') {
                                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.WORKFLOWS.ONLY_ONE_ACTIVE_WORKFLOW, null);
                                } else if (getStringParam(response.error.message).trim() === 'Rule Present') {
                                    let tempMsg = "This Workflow has been used in the Lead Conversion rule for Scheduler hence you cannot perform Delete/Inactive operation. First remove this workflow form Lead conversion rule then perform Delete/Inactive operation."
                                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, tempMsg, null);
                                } else {
                                    isValidParam(getLocalizedStrings().message.WORKFLOWS[message])
                                        ? dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop))
                                        : dispatch(showCustomSnackBar(response.error.message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                }

                            } else {
                                msg = getLocalizedStrings().message.WORKFLOWS.STATUS_CHANGED + ' ' + statusData + '.';
                                dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                refreshListView(props.object);

                            }
                        }

                    });
                }

            } else {
                if (value === constants.INACTIVE) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_INACTIVE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
                if (value === constants.ACTIVE) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_ACTIVE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            }
        } catch (error) {
            console.error("error in listViewHeader.js -> changeWorkflowStatus" + error)
        }

    }

    const changeWebHookStatus = (recordId, value) => {
        let params = null;
        let response = null;
        let endPoint = null;
        let msg = '';
        let statusData = '';
        let tempRecords = props.listView.data.records.filter(record => record.id === recordId[0]);;
        let setValue = tempRecords[0].t_status;
        try {
            if (setValue === "Disabled") {
                setValue = constants.DISABLE
            }
            if (value !== setValue) {

                if (value === constants.ACTIVE) {
                    statusData = getLocalizedStrings().label.COMMON.ACTIVE;
                } else if (value === constants.DISABLE) {
                    statusData = getLocalizedStrings().label.COMMON.DISABLED;
                }
                params = {
                    id: recordId[0],
                    status: statusData
                };
                endPoint = endPoints.WEBHOOKS.STATUS_CHANGE;
                response = Promise.resolve(HTTPClient.post(endPoint, params));
                response.then((response) => {
                    if (isValidParam(response)) {
                        msg = getLocalizedStrings().message.WORKFLOWS.STATUS_CHANGED + ' ' + statusData + '.';
                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        refreshListView(props.object);
                    }
                });
            } else {
                if (value === constants.DISABLE) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_DISABLE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
                if (value === constants.ACTIVE) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_ACTIVE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }
            }
        } catch (error) {
            console.error("error in listViewHeader.js -> changeWorkflowStatus" + error)
        }

    }


    const processBatchSMS = (selectedIds, objectName, attachmentId) => {
        let params = null;
        let promise = null;
        let msg = '';
        try {
            let sendBatchSMSName = getLocalizedStrings().label.COMMON.SEND_TEXT_MESSAGE;
            params = {
                recordIds: selectedIds,
                type: 'Texts',
                tableId: OBJECT_TABLEID_MAP[getStringParam(objectName)],
                country: reduxApp.me.tenant_country,
            }
            if (selectedIds.length > 1) {
                sendBatchSMSName = getLocalizedStrings().label.COMMON.SEND_BATCH_SMS;
            }
            promise = Promise.resolve(HTTPClient.get(endPoints.COMMON.GET_MOBILE_NO_VERIFY, params));
            promise.then((promise) => {

                if (isValidParam(promise)) {
                    let verifiedMobileList = getArrayParam(promise);
                    let data = {
                        recordIds: verifiedMobileList,
                        objectName: objectName
                    }

                    if (props.isDetailView !== undefined && props.isDetailView === true) {
                        data = { ...data, isDetailView: true };
                    }

                    if (props.object === constants.ATTACHMENT_OBJECT) {
                        data = { ...data, attachmentId: attachmentId, childObject: props.object };
                    }

                    msg = getLocalizedStrings().message.SMS_INTEGRATION.BATCH_SEND_SMS_MSG_1 + ' ' + selectedIds.length + ' ' + objectName + ' ' + getLocalizedStrings().message.SMS_INTEGRATION.BATCH_SEND_SMS_MSG_2;
                    msg = msg + ' ' + verifiedMobileList.length + ' ' + getLocalizedStrings().message.SMS_INTEGRATION.BATCH_SEND_SMS_MSG_3;
                    msg = msg + ' ' + getLocalizedStrings().message.SMS_INTEGRATION.BATCH_SEND_SMS_MSG_4 + ' ' + verifiedMobileList.length + ' ' + objectName + getLocalizedStrings().label.COMMON.DOT;
                    if (selectedIds.length === 1 && verifiedMobileList.length === 0) {
                        msg = '';
                        msg = getLocalizedStrings().message.SMS_INTEGRATION.SINGLE_SEND_SMS_MSG_1 + ' ' + objectName + ' ' + getLocalizedStrings().message.SMS_INTEGRATION.SINGLE_SEND_SMS_MSG_2;
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                    } else if (selectedIds.length > 1 && selectedIds.length !== verifiedMobileList.length) {
                        if (verifiedMobileList.length > 0) {
                            msg = msg + ' ' + getLocalizedStrings().message.SMS_INTEGRATION.BATCH_SEND_SMS_MSG_5;
                            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => processBatchSMSOnConfirmed(sendBatchSMSName, data), null);
                        } else {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                        }
                    } else {
                        dispatch(getAppDrawer(true, sendBatchSMSName, constants.SEND_SMS, data, constants.LIST_VIEW));
                    }
                }
            });
        } catch (error) {
            console.error("error in listViewHeader.js -> processBatchSMS" + error)
        }
    }

    const processAddTask = (selectedIds, objectName) => {
        let selectedRows = reduxListView.selectedRows;
        if (selectedRows && selectedRows.length > 0) {
            let shouldOpenDrawer = true;

            for (let i = 0; i < selectedRows.length; i++) {
                let _isSubUnit = selectedRows[i].parentItem;
                if (_isSubUnit !== 0) {
                    shouldOpenDrawer = false;
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.LIST_VIEW.UNIT_TASK_MESSAGE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    break;
                }
            }
            if (shouldOpenDrawer) {
                if (selectedIds.length == 1) {
                    let params = {
                        object: constants.ACCOUNTS_OBJECT,
                        id: selectedIds[0],
                        callFrom: constants.UNITS_OBJECT,
                    }
                    let _myClientId = '';
                    const response = Promise.resolve(HTTPClient.get(endPoints.COMMON.GET_MYCLIENTID, params));
                    response.then((response) => {
                        if (response.status == 0) {
                            _myClientId = response.myClientId;
                            openTaskForm(selectedIds, _myClientId);
                        }
                    });
                } else {
                    openTaskForm(selectedIds, 0, objectName);
                }
            }
        }
    };

    const openTaskForm = (selectedIds, _myClientId, objectName) => {
        let data = {
            id: 0,
            object: constants.TASKS,
        };
        data['parent_object'] = constants.ACCOUNTS_OBJECT;
        data['parent_record_id'] = selectedIds;
        data['isTreeView'] = true;
        data.isFranchiseRecord = true;
        data.selectedOpt = objectName === constants.TASKS ? constants.TASKS : constants.UNIT_TASKS;
        data.isMultipleRecord = true;
        data.isUnitask = true;
        data.isUnitownerTasks = true;
        if (_myClientId) {
            data.myClientId = _myClientId;
            data.showAttachmentLink = true;
        } else {
            data.showAttachmentLink = false;
        }
        data.callFrom = 'unit_list_view_task';
        let labelName = getLocalizedStrings().label.COMMON.ADD_TASK;
        if (selectedIds.length === 1) {
            data['isDetailView'] = true;
        } else {
            data['isDetailView'] = false;
        }
        dispatch(getAppDrawer(true, labelName, constants.NEW, data, constants.OTHER));
    }


    const processBatchSMSOnConfirmed = (sendBatchSMSName, data) => {
        dispatch(getAppDrawer(true, sendBatchSMSName, constants.SEND_SMS, data, constants.LIST_VIEW));
    }
    const powerCallingDataProcess = () => {
        let tabs = reduxTab.tabs;
        let powerCalling = tabs.find((tab) => {
            return tab.type === TYPE_POWER_DIALER
        });
        if (isValidParam(powerCalling)) {

            let tabIndex = powerCalling.index;
            selectTabByIndex(tabIndex);
            let callData = 0;
            resetPowerCallingData(callData);
        }
    }

    const getPowerCallingDataFn = (selectedIds) => {
        let consFields = ["company", "first_name", "last_name", "phone", "mobile", "hphone", "email", "workflow_id"]
        let additinalDataFields = null;
        let childObject = constants.CONTACTS_OBJECT;
        let object = props.object;
        let index = 0
        if (object === constants.UNITS_OBJECT) {
            object = constants.ACCOUNTS_OBJECT;
        }
        if (object === constants.CONTACTS_OBJECT) {
            childObject = constants.ACCOUNTS_OBJECT
        }
        let data = reduxApp.me.power_calling_setup
        if (data !== null && data !== undefined && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].object === object) {
                    additinalDataFields = data[i].object_fields;
                    index = i;
                }
            }

            let tempFields = additinalDataFields;
            let formFields = reduxSfForm.data[object].fields;
            let tempFieldsString = null;
            if (additinalDataFields !== null && additinalDataFields !== undefined && additinalDataFields !== ' ' && additinalDataFields !== '') {
                tempFields = additinalDataFields.split(',');
                for (let i = 0; i < tempFields.length; i++) {
                    if (formFields.some(e => e.name === tempFields[i])) {
                        consFields.push(tempFields[i])
                        if (tempFields[i] !== '' && tempFields[i] !== null) {
                            tempFieldsString = tempFieldsString + ',' + tempFields[i];
                        }
                    }
                }
            }
            data[index].object_fields = tempFieldsString;
        }
        let params = {
            "ids": selectedIds,
            "callingMode": "both",
            "fields": consFields,
            "parentObject": object,
            "childObject": childObject
        }
        let url = '';
        url = endPoints.POWER_CALLING.GET_POWER_CALLING_RECORD_DETAIL;
        let promise = Promise.resolve(HTTPClient.get(url, params));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    PowerCallingTab(selectedIds, response);
                    getPowerCallingData(params, response);
                }
            });

        }
    }
    const handleSfDialogAlertobjh = (automationTab) => {
        let tabIndex = automationTab.index;
        selectTabByIndex(tabIndex);
    }
    const PowerCallingTab = (selectedIds, response) => {
        let object = props.object;
        if (object === constants.UNITS_OBJECT) {
            object = constants.ACCOUNTS_OBJECT;
        }
        let responseData = response;
        if (responseData.hasOwnProperty("records") && responseData.records.length > 0) {

            let urlLink = `/${object}/powercall`;
            let newTab = {
                imgName: "fa fa-phone",
                object: object,
                url: urlLink,
                data: selectedIds,
                type: TYPE_POWER_DIALER,
                label: 'Power Dialer',
                info: {
                    type: 'New',
                    parentRecordId: getIntParam(selectedIds),
                    parentObject: props.parentObject,
                }
            };
            let tabs = reduxTab.tabs;
            let objectName = object;
            let callTab = tabs.find((tab) => {
                return tab.type === TYPE_POWER_DIALER && tab.object === objectName
            });

            if (!isValidParam(callTab)) {
                addTab(newTab);
                return false;
            } else {
                let url = callTab.url;
                if (!url.includes(objectName)) {
                    addTab(newTab);
                    return false;
                }
            }
        } else {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.POWER_CALLING.ALERT_MSG_1 + ' ' + props.listView.selectedRows.length + '   ' + object + ' ' + getLocalizedStrings().message.POWER_CALLING.ALERT_MSG_8, null);
        }
    }

    const handleSendMailAction = (value, event) => {
        let isAurinkoReAuthRequired = reduxApp.me.is_aurinko_reauth_required;
        let isSendMailUsingAurinko = reduxApp.me.is_send_mail_using_aurinko;
        if (isSendMailUsingAurinko && isAurinkoReAuthRequired) {
            let msg = getLocalizedStrings().message.MY_INTEGRATION.RE_AUTHENTICATE_AURINKO;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
        } else {
            handleAction(value, event);
        }
    }

    const handleAction = (value, event) => {
        let tabInfo = getActiveTabInfo();
        let isUnitsAccount = false;
        let isAccessDenied = false;
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
            isAccessDenied = true;
        }

        if (props.object === constants.COMPLIANCE_OBJECT && value === constants.TO_EXCEL) {
            isAccessDenied = false;
        }

        if (isUnitsAccount && props.object === constants.ATTACHMENT_OBJECT
            && (value === constants.DOWNLOAD || value === constants.VIEW)) {
            isAccessDenied = false;
        }
        if (unitPermission?.MS_BROADCAST === 0 && props.object === constants.BROADCAST_CAMPAIGNS_OBJECT && reduxApp.me.is_franchisee && !franchisorToken) {
            isAccessDenied = true;
        } else if (unitPermission?.MS_AUTOMATION === 0 && props.object === constants.AUTOMATION_DESIGNER_OBJECT && reduxApp.me.is_franchisee && !franchisorToken) {
            isAccessDenied = true;
        }

        if (isAccessDenied) {
            let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
        } else {

            if (props.object === constants.TASKS || props.object === constants.APPOINTMENTS) {
                reduxSfForm.reminder = [];
            }
            if (props.object === constants.UNITS_OBJECT && (value === constants.SEND_EMAIL || value === constants.SEND_SMS || value === constants.CALL || value === constants.ADD_TASK && value === constants.ADD_BATCH_TASK)) {
                let selectedRows = reduxTreeView.selectedRows;
                if (reduxApp.isNewTree) {
                    selectedRows = reduxListView.selectedRows;
                }

                if (!reduxApp.isNewTree) {
                    let hasChild = selectedRows.filter((el) => {
                        return el.isParent === false
                    });
                    if (hasChild.length > 0) {
                        if (value === constants.SEND_EMAIL) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You can only email the units that are directly connected to you.', null);
                        } else if (value === constants.SEND_SMS) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You can only text the units that are directly connected to you.', null);
                        } else if (value === constants.CALL) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You can only call the units that are directly connected to you.', null);
                        } else if (value === constants.ADD_BATCH_TASK) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You can only call the units that are directly connected to you.', null);

                        }
                        return false;
                    }
                } else {
                    let hasChild = selectedRows.filter((el) => {
                        return el.hLabel
                    });
                    if (hasChild.length > 0) {
                        if (value === constants.SEND_EMAIL) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You can only email the units that are directly connected to you.', null);
                        } else if (value === constants.SEND_SMS) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You can only text the units that are directly connected to you.', null);
                        } else if (value === constants.CALL) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You can only call the units that are directly connected to you.', null);
                        } else if (value === constants.ADD_BATCH_TASK) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You can only call the units that are directly connected to you.', null);

                        }
                        return false;
                    }

                }

            }
            let hasSecurityRolePermission = false;
            if (value === constants.SHARE) {
                let params = {
                    "security_level_name": "Admin",
                    "template_id": reduxApp.me.t_id
                }
                if (reduxApp.me.t_id !== 2 && !reduxApp.me.is_tenant) {
                    const response = Promise.resolve(HTTPClient.get(endPoints.COMMON.HAS_SECURITY_ROLE_PERMISSION, params));
                    response.then((response) => {
                        if (response.status === 0) {
                            hasSecurityRolePermission = response.hasSecurityRolePermission;
                        } else {
                            hasSecurityRolePermission = false;
                        }
                        triggerAction(value, hasSecurityRolePermission);
                    });
                } else {
                    triggerAction(value, hasSecurityRolePermission);
                }
            } else if (value === constants.LOGIN || value === constants.EXCEPTION) {
                let selectedRowLength = reduxTreeView.selectedRows.length;
                if (reduxApp.isNewTree) {
                    selectedRowLength = reduxListView.selectedRows.length;
                }
                if (props.isDetailView !== undefined && props.isDetailView === true) {
                    selectedRowLength = childListView.selectedRows.length;
                }
                if ((props.object === constants.UNITS_OBJECT || props.object === constants.REPORTED_SALES_OBJECT) && selectedRowLength === 0) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.UNIT_ALERT, null);
                    return false;
                }

                if ((props.object === constants.UNITS_OBJECT || props.object === constants.REPORTED_SALES_OBJECT) && selectedRowLength === 1) {
                    let selectedRow = reduxTreeView.selectedRows[0];
                    if (reduxApp.isNewTree) {
                        selectedRow = reduxListView.selectedRows[0];
                    }

                    if (props.isDetailView !== undefined && props.isDetailView === true) {
                        selectedRow = childListView.selectedRows[0];
                    }
                    if (props.object === constants.REPORTED_SALES_OBJECT) {
                        fatchClientIdByReportedSales(selectedRow.id, value)
                    } else {

                        let params = {};
                        if (selectedRow.isParent) {
                            params = {
                                object: constants.ACCOUNTS_OBJECT,
                                id: selectedRow.id,
                                callFrom: constants.UNITS_OBJECT,
                            }
                            let _myClientId = '';
                            const response = Promise.resolve(HTTPClient.get(endPoints.COMMON.GET_MYCLIENTID, params));
                            response.then((response) => {
                                if (response.status == 0) {
                                    hasSecurityRolePermission = true;
                                    _myClientId = response.myClientId;
                                } else {
                                    hasSecurityRolePermission = false;
                                }
                                triggerAction(value, hasSecurityRolePermission, _myClientId);
                            });
                        } else {
                            triggerAction(value, true, selectedRow.myclient_id);
                        }
                    }


                } else if ((props.object === constants.UNITS_OBJECT || props.object === constants.REPORTED_SALES_OBJECT) && selectedRowLength > 1) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You have selected multiple units. You can log into one unit at a time. Please select only one unit and try again.', null);
                    return false;
                }
            }
            else if (value === constants.PLAN_NAME) {
                let selectedRows;
                if (props.object === constants.UNITS_OBJECT && !reduxApp.isNewTree) {
                    selectedRows = reduxTreeView.selectedRows;
                } else {
                    selectedRows = props.listView.selectedRows;
                }
                selectedRows = isValidParam(selectedRows) ? selectedRows : [];
                let selectedIds = selectedRows.map(row => {
                    return row.id;
                });

                let selectedRowLength = reduxTreeView.selectedRows.length;
                if (reduxApp.isNewTree) {
                    selectedRowLength = reduxListView.selectedRows.length;
                }
                if (props.object === constants.UNITS_OBJECT && selectedRowLength === 0) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.UNIT_PLAN_NAME_ALERT, null);
                    return false;
                } else if (props.object === constants.UNITS_OBJECT && selectedRowLength > 1) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You have selected multiple units. Please select only one unit and perform add plan name.', null);
                    return false;
                } else if (props.object === constants.UNITS_OBJECT && selectedRowLength === 1) {
                    let recordId = selectedIds[0];
                    let params = {};
                    params.recordId = recordId;
                    let promise = null;
                    let response = Promise.resolve(HTTPClient.get(endPoints.UNITS.OPEN_PLAN_NAME, params));
                    response.then((response) => {
                        if (isValidParam(response)) {
                            let isOpenPlanName = response;

                            if (isOpenPlanName == true) {
                                triggerAction(value, hasSecurityRolePermission);
                            }
                            else if (isOpenPlanName == false) {
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You can not perform add to plan action on AF/MU/Base type units.', null);

                            }
                        }
                    })
                }
            } else if (value === constants.DISCONNECT) {
                let selectedRowLength = reduxTreeView.selectedRows.length;
                if (reduxApp.isNewTree) {
                    selectedRowLength = reduxListView.selectedRows.length;
                }
                if (props.object === constants.UNITS_OBJECT && selectedRowLength == 0) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.UNIT_DISCONNECT_ALERT, null);
                    return false;
                }

                if (props.object === constants.UNITS_OBJECT && selectedRowLength == 1) {
                    let selectedRow = reduxTreeView.selectedRows[0];
                    if (reduxApp.isNewTree) {
                        selectedRow = reduxListView.selectedRows;
                    }
                    if (selectedRow.isParent) {
                        triggerAction(value, hasSecurityRolePermission);
                    } else {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You can disconnect only the units directly connected with you.', null);
                        return false;
                    }
                } else if (props.object === constants.UNITS_OBJECT && selectedRowLength > 1) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You have selected multiple units. You can disconnect one unit at a time. Please select only one unit and try again.', null);
                    return false;
                }
            } else if (value === constants.QUEUE) {
                openRecordInQueue(event, value);

            } else if (value === 'Print') {
                print();
            }
            //calling drawer for clicking on edit button
            else if (value == "Sending for Approval" || value == "Approved" || value == "Rejected") {
                let selectedRowId = null;
                if (childListView.mounted == true) {
                    selectedRowId = childListView.selectedRows[0].id;
                } else {
                    selectedRowId = reduxListView.selectedRows[0].id;
                }
                if (value == "Rejected") {
                    let dialogData = {};
                    dialogData.selectedRowId = selectedRowId;
                    // dialogData.data = data;
                    dialogData.object = constants.REJECTION_REASION_DIALOG;

                    dispatch(getAppDialog(true, constants.REJECTION_REASION_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.DETAIL_VIEW));
                    setOpenActionList(false);
                } else {
                    sendReportedSalesAction(value, selectedRowId);
                }
            }
            else if (value == constants.EMAIL_TO_UNIT) {
                let selectedRowId = null;
                if (childListView.mounted == true) {
                    selectedRowId = childListView.selectedRows[0].id;
                } else {
                    selectedRowId = reduxListView.selectedRows[0].id;
                }
                fatchAccountsIdByReportedSales(selectedRowId, value)
            }

            else if (value === constants.DELETE && (reduxListView?.selectedRows?.length === 1 || props?.listView?.selectedRows?.length == 1)) {

                if (props.object === 'accounts') {

                    let _myClientId = reduxListView?.selectedRows[0]?.myClientId || props?.listView?.selectedRows[0]?.myClientId;
                    if (_myClientId > 0) {
                        triggerAction(value, hasSecurityRolePermission, _myClientId);
                    } else {
                        triggerAction(value, hasSecurityRolePermission);
                    }

                }
                else {
                    triggerAction(value, hasSecurityRolePermission);

                }

            }
            else {
                triggerAction(value, hasSecurityRolePermission);
            }
        }
    }

    const fatchAccountsIdByReportedSales = (selectedRowId, value) => {
        let url = endPoints.ROYALITY.GET_UNIT_ID;
        let params = {
            id: selectedRowId
        };
        const response = Promise.resolve(HTTPClient.get(url, params));
        response.then((response) => {
            let unitId = [response]
            sendEmail(unitId, null, null);
            setOpenActionList(false);
        })
    }
    const fatchClientIdByReportedSales = (selectedRowId, value) => {
        let url = endPoints.ROYALITY.GET_CLIENT_ID;
        let params = {
            id: selectedRowId
        };
        const response = Promise.resolve(HTTPClient.get(url, params));
        response.then((response) => {
            if (value == constants.LOGIN) {
                triggerAction(value, true, response);
            }
        })
    }
    const sendReportedSalesAction = (actionname, id) => {
        let _id = [];
        _id.push(id);
        let params = {
            action: actionname == 'Sending for Approval' ? 'SENDING_FOR_APPROVAL' : actionname,
            id: _id
        }
        let _actionname = actionname == 'Sending for Approval' ? 'SENDING_FOR_APPROVAL' : actionname;
        let endPoint = 'v3/reportedsales/action/{action}';
        endPoint = getObjectSpecificEndpoint(_actionname, endPoint);
        let promise = Promise.resolve(HTTPClient.post(endPoint, params));
        promise.then((response) => {
            if (response.status === 0) {
                let msg;
                if (actionname == "Approved") {
                    msg = "You have Approved the Reported Sales record successfully. " + "  " + " An email notification has been sent to the unit."
                } else {
                    msg = 'You have successfully sent your Reported Sales for the Approval.';
                }
                dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                setOpenActionList(false);
                dispatch(detailViewIsMounted(false));
                dispatch(getTasksRefresh(false));
                dispatch(refreshDetailViewListViewData(true));
                dispatch(refreshDetailViewData(true));
            }
        });
    }

    const getObjectSpecificEndpoint = (object, endpoint) => {
        return endpoint.replace("{action}", object);
    }
    const onConfirmReportedSalesUpdate = (res, selectedId) => {
        let data = {
            isYesButton: true,
            isCancelButton: false,
            newRecordDetails: res,
            selectedRecordId: selectedId
        }

        dispatch(getAppDrawer(true, "Reported Sales Update", REPORTED_SALES_ACTIONS.UPDATE, data, constants.OTHER));
    }

    const onCancelReportedSalesUpdate = (selectedId) => {
        let data = {
            isOkButton: false,
            isCancelButton: true,
            selectedRecordId: selectedId
        }
        if (selectedId !== undefined && selectedId.length > 0) {
            dispatch(getAppDrawer(true, "Reported Sales Update", REPORTED_SALES_ACTIONS.UPDATE, data, constants.OTHER));
        } else {
            let data = {
                onAllRecordEdit: true
            }
            dispatch(getAppDrawer(true, "Reported Sales Update", REPORTED_SALES_ACTIONS.UPDATE, data, constants.OTHER));
        }
    }

    const handleNoteTypesAction = (selectedIds, value, objectName) => {
        let url = null;
        let message = null;
        if (value === constants.INACTIVE) {
            url = endPoints.NOTES.NOTE_TYPE_INACTIVE;
            message = getLocalizedStrings().message.BATCH_ACTION.INACTIVE_SUCCESSFULL;
        } else if (value === constants.ACTIVE) {
            url = endPoints.NOTES.NOTE_TYPE_ACTIVE;
            message = getLocalizedStrings().message.BATCH_ACTION.ACTIVE_SUCCESSFULL;
        }

        let params = {};
        params.id = selectedIds;
        var response = Promise.resolve(HTTPClient.get(url, params));
        response.then((response) => {
            if (response.status === 0) {
                dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                refreshListView(objectName);
                var promise = getSFFormFieldsPromise(constants.NOTES);
                promise.then((response) => {
                    if (isValidParam(response)) {
                        reduxSfForm.data[constants.NOTES] = Object.assign({}, response);
                    }
                });
            } else {
                let message = response?.data?.message;
                if (value === constants.INACTIVE) {
                    dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                } else if (value === constants.ACTIVE) {
                    dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                }

            }
        });
    }


    const print = () => {
        let records = props.listView.data.records;
        let fields = props.listView.data.fields;
        let objectName = isValidParam(props.object) ? props.object : '';
        if (objectName === constants.AUDIT_ANSWERS_OBJECT) {
            fields = fields.filter((f) => {
                return f.name != "photo_url";
            })
        }
        let htmlData = getHTMLTable(fields, records);
        const ifrmae = document.getElementById("ifmcontentstoprint");
        ifrmae.style.display = 'block'
        const pri = document.getElementById("ifmcontentstoprint").contentWindow;
        ifrmae.style.display = 'block'

        pri.document.open();
        pri.document.write(htmlData);
        pri.document.close();
        pri.focus();
        pri.print();
        ifrmae.style.display = 'none';
    }

    const getHTMLTable = (fields, records) => {
        let htmlTable = '';
        fields = getArrayParam(fields);
        records = getArrayParam(records);
        if (fields.length > 0) {
            let tHead = '';
            fields.forEach(field => {
                if (!field.is_hidden) {
                    let dataField = field.label.toString();
                    dataField = dataField.replace(/</g, '&lt;');
                    dataField = dataField.replace(/>/g, '&gt;');
                    tHead += '<th align="left" valign="top">' + dataField + '</th>';
                }
            });

            if (tHead !== '') {
                tHead = '<thead><tr>' + tHead + '</tr></thead>';
            }

            let tBody = ''
            records.forEach(record => {
                let row = '';
                let dataValue = '';
                fields.forEach(field => {
                    if (!field.is_hidden) {
                        dataValue = getStringParam(record[field.name]);
                        dataValue = dataValue.replace(/</g, '&lt;');
                        dataValue = dataValue.replace(/>/g, '&gt;');
                        if (field.name === 'company') {
                            dataValue = getStringParam(dataValue);
                            dataValue = dataValue === '' ? 'Empty' : dataValue;
                        }
                        row += '<td align="left" valign="top">' + dataValue + '</td>';
                    }
                });

                if (row !== '') {
                    tBody += '<tr>' + row + '</tr>';
                }
            });

            if (tBody !== '') {
                tBody = '<tbody>' + tBody + '</tbody>';
            }

            if (tHead !== '') {
                htmlTable = '<table border="1" style="border-collapse: collapse;">' + tHead + tBody + '</table>';
                htmlTable = '<html><meta http-equiv=\"Content-Type\" content=\"text/html; charset=UTF-8\"><head></head><body>' + htmlTable + '</body></html>';
            }
        }
        return htmlTable;
    }

    const generateCustomMenuItems = (menuItemsList) => {
        let actions = [];
        menuItemsList.map((menuItem, i) => {
            actions.push(<MenuItem
                key={menuItem.value + i}
                value={menuItem.value}
                style={styles.popoverMenuItem}
                fullWidth={true}
                labelstyle={{ color: '#479ccf' }}
                underlinestyle={{ display: 'none' }}
                autowidth={"true"}
                onClick={() => handleAction(menuItem.value)}>
                {menuItem.label}
            </MenuItem>);
        });
        return actions;
    }

    const getTaskStatusFilter = () => {
        try {
            return optionvalue.map((item) => {
                return <MenuItem key={item} value={item} style={styles.popoverMenuItem}>{item}</MenuItem>;
            });
        }
        catch (error) {
            console.error("Error in 'pipelineReport.js -> getTaskStatusFilter()':" + error);
        }

    }

    const getTaskTypeFilter = () => {
        let _list = workflowList;
        if (_list !== undefined) {
            return _list.map((item) => {
                return <MenuItem value={item} style={styles.popoverMenuItem} >{item}</MenuItem>;
            })
        }
    }


    const openRecordInQueue = () => {
        let label = '';
        let linkUrl = '';
        let selectedRows = null;
        let recordId = 0;
        let objectName = getStringParam(props.object);
        let totalCount = 0;
        let recordIndex = 0;
        try {
            selectedRows = props.listView.selectedRows;
            selectedRows = isValidParam(selectedRows) ? selectedRows : [];
            let records = null;
            if (selectedRows.length > 0) {
                let object = objectName.toLowerCase();
                if (object === constants.TASKS) {
                    records = selectedRows.map((row, index) => {
                        return { id: row.id, idx: index, title: row.title, linkInfo: row.linkInfo }
                    });
                } else {
                    records = selectedRows.map((row, index) => {
                        return { id: row.id, idx: index, title: row.title }
                    });
                }
                recordId = records[0].id;
                label = records[0].title
                recordIndex = records[0].idx;

                let reduxObject = {};
                reduxObject['records'] = records;
                totalCount = records.length;
                reduxObject['total_count'] = totalCount;
                reduxObject['object'] = object;
                reduxObject['call_from'] = "Listview";
                reduxObject['openedRecordInQueueId'] = recordId;
                reduxObject['openedRecordInQueueIndex'] = recordIndex;
                reduxObject['openedRecordInQueuelabel'] = label;
                if (object === constants.TASKS) {

                    if (reduxQueueList.hasOwnProperty('data') && reduxQueueList.data.hasOwnProperty('selecteddrpdwnObj') && reduxQueueList.data.selecteddrpdwnObj !== undefined && reduxQueueList.data.selecteddrpdwnObj !== null && reduxQueueList.data.selecteddrpdwnObj !== "") {
                        reduxObject['selecteddrpdwnObj'] = reduxQueueList.data.selecteddrpdwnObj;
                    } else {
                        reduxObject['selecteddrpdwnObj'] = object;
                    }

                } else {
                    if (props.hasOwnProperty('queueList') && reduxQueueList.hasOwnProperty('data') && reduxQueueList.data.hasOwnProperty('selecteddrpdwnObj') && reduxQueueList.data.selecteddrpdwnObj !== undefined && reduxQueueList.data.selecteddrpdwnObj !== null && reduxQueueList.data.selecteddrpdwnObj !== "") {
                        reduxObject['selecteddrpdwnObj'] = reduxQueueList.data.selecteddrpdwnObj;
                    } else {
                        reduxObject['selecteddrpdwnObj'] = constants.TASKS;
                    }
                }
                if (object === constants.TASKS && reduxQueueList.hasOwnProperty('data') && reduxQueueList.data.hasOwnProperty('selecteddrpdwnObj') && reduxQueueList.data.selecteddrpdwnObj !== undefined && reduxQueueList.data.selecteddrpdwnObj !== null && reduxQueueList.data.selecteddrpdwnObj !== "") {
                    let linkInfo;
                    let islinkpresent = false;
                    linkInfo = records[0].linkInfo;
                    if (linkInfo !== null && linkInfo !== "" && linkInfo !== undefined && reduxQueueList.data.selecteddrpdwnObj !== constants.TASKS) {
                        let linkInfoArr = linkInfo.split(',');
                        let selectedObjId = getIntParam(OBJECT_TABLEID_MAP[reduxQueueList.data.selecteddrpdwnObj]);
                        linkInfoArr.map((item, i) => {
                            let ObjRecordArr = item.split('_');
                            let tableId = getIntParam(ObjRecordArr[0].trim());
                            if (selectedObjId === tableId) {
                                recordId = getIntParam(ObjRecordArr[1]);
                                object = reduxQueueList.data.selecteddrpdwnObj;
                                islinkpresent = true;
                            }

                        });

                        openDetailviewQueueTab(object, label, recordId, recordIndex, totalCount);
                    } else {
                        openDetailviewQueueTab(constants.TASKS, label, recordId, recordIndex, totalCount);
                    }
                    if (!islinkpresent) {
                        let parentItems = reduxApp.objectList;
                        parentItems = parentItems.filter(f => {
                            return f.name === reduxQueueList.data.selecteddrpdwnObj
                        })
                        if (parentItems.length > 0) {
                            let msg = label + " doesn t have any associated " + parentItems[0].label;
                            dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }

                    }
                } else {
                    openDetailviewQueueTab(object, label, recordId, recordIndex, totalCount);
                }
                loadQueueList(getObjectParam(reduxObject));
            }
            else if (objectName == 'cases' && reduxApp.selectedModuleName === constants.MODULE_HUB) {
                let _records = null;
                _records = props.listView.data.records;
                // let firstRec =    props.listView.data.records[0]
                _records = props.listView.data.records.map((row, index) => {
                    return { id: row.id, idx: index, title: row.company }
                });
                let object = objectName.toLowerCase();
                recordId = _records[0].id;
                label = _records[0].title
                recordIndex = _records[0].idx;

                let reduxObject = {};
                reduxObject['records'] = _records;
                totalCount = _records.length;
                reduxObject['total_count'] = _records.length;
                reduxObject['object'] = object;
                reduxObject['call_from'] = "Listview";
                reduxObject['openedRecordInQueueId'] = recordId;
                reduxObject['openedRecordInQueueIndex'] = recordIndex;
                reduxObject['openedRecordInQueuelabel'] = label;

                if (props.hasOwnProperty('queueList') && reduxQueueList.hasOwnProperty('data') && reduxQueueList.data.hasOwnProperty('selecteddrpdwnObj') && reduxQueueList.data.selecteddrpdwnObj !== undefined && reduxQueueList.data.selecteddrpdwnObj !== null && reduxQueueList.data.selecteddrpdwnObj !== "") {
                    reduxObject['selecteddrpdwnObj'] = reduxQueueList.data.selecteddrpdwnObj;
                } else {
                    reduxObject['selecteddrpdwnObj'] = constants.TASKS;
                }
                openDetailviewQueueTab('cases', label, recordId, recordIndex, totalCount);
                loadQueueList(getObjectParam(reduxObject));
            }

            else {
                let activeTab = getObjectParam(getActiveTab());
                let info = getObjectParam(activeTab.info);
                let initilaParam = {};
                initilaParam.object = objectName;
                initilaParam.call_from_queue = true;

                let filter = getObjectParam(activeTab.info.filter);
                if (getIntParam(filter.id) > 0 || getStringParam(filter.name) !== '') {
                    initilaParam = {
                        ...initilaParam,
                        query_id: getIntParam(filter.id),
                        query_name: getStringParam(filter.name),
                        query_type: getStringParam(filter.type),
                        is_group_by_info: true,
                        page_size: QUEUE_LIST.FETCH_SIZE,
                        sort_field_name: getStringParam(props.listView.data.sort_field_name),
                        sort_type: getStringParam(props.listView.data.sort_type),
                        call_from: constants.LIST_VIEW,
                    };
                    if (info.hasOwnProperty('callFrom') && info.callFrom === "task_listview" && activeTab.object === constants.TASKS && activeTab.item === constants.CALENDAR_TASK) {
                        initilaParam.click_form = "task_listview";
                        initilaParam.task_status = info.status;
                        initilaParam.userName = info.userName;
                    }
                    if (info.hasOwnProperty('groupByFieldName') || info.hasOwnProperty('groupByCondition') || info.hasOwnProperty('searchFieldName')
                        || info.hasOwnProperty('searchText') || info.hasOwnProperty('selectedPageNo')
                        || info.hasOwnProperty('startIndex') || info.hasOwnProperty('startPageNo')) {

                        if (info.hasOwnProperty('groupByFieldName')) {
                            initilaParam.group_by_field_name = getStringParam(info.groupByFieldName);
                        }

                        if (info.hasOwnProperty('groupByCondition')) {
                            initilaParam.group_by_condition = getStringParam(info.groupByCondition);
                        }

                        if (info.hasOwnProperty('searchFieldName')) {
                            initilaParam.search_field_name = getStringParam(info.searchFieldName);
                        }
                        if (info.hasOwnProperty('searchText')) {
                            initilaParam.search_type = constants.SEARCH_TYPE_ANYWHERE;
                            initilaParam.search_text = getStringParam(info.searchText);
                        }
                    }
                }
                info.sort_field_name = initilaParam.sort_field_name
                info.sort_type = initilaParam.sort_type
                delete info.openedRecordInQueueId
                updateTabInfo(info);
                if (objectName !== '') {
                    let object = objectName.toLowerCase();
                    let promise = Promise.resolve(getQueueList(object, initilaParam));
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let queueList = response.records;
                            totalCount = getIntParam(response.total_count);
                            if (isValidParam(queueList) && queueList.length > 0) {
                                recordIndex = 0;
                                recordId = queueList[0].id;
                                label = queueList[0].title
                            }
                            response['openedRecordInQueueId'] = recordId;
                            response['openedRecordInQueueIndex'] = recordIndex;
                            response['openedRecordInQueuelabel'] = label;
                            if (object === constants.TASKS) {
                                if (reduxQueueList.hasOwnProperty('data') && reduxQueueList.data.hasOwnProperty('selecteddrpdwnObj') && reduxQueueList.data.selecteddrpdwnObj !== undefined && reduxQueueList.data.selecteddrpdwnObj !== null && reduxQueueList.data.selecteddrpdwnObj !== "") {
                                    response['selecteddrpdwnObj'] = reduxQueueList.data.selecteddrpdwnObj;
                                } else {
                                    response['selecteddrpdwnObj'] = object;
                                }
                            } else {
                                if (reduxQueueList.hasOwnProperty('data') && reduxQueueList.data.hasOwnProperty('selecteddrpdwnObj') && reduxQueueList.data.selecteddrpdwnObj !== undefined && reduxQueueList.data.selecteddrpdwnObj !== null && reduxQueueList.data.selecteddrpdwnObj !== "") {
                                    response['selecteddrpdwnObj'] = reduxQueueList.data.selecteddrpdwnObj;
                                } else {
                                    response['selecteddrpdwnObj'] = constants.TASKS;
                                }
                            }
                            if (object === constants.TASKS && reduxQueueList.hasOwnProperty('data') && reduxQueueList.data.hasOwnProperty('selecteddrpdwnObj') && reduxQueueList.data.selecteddrpdwnObj !== undefined && reduxQueueList.data.selecteddrpdwnObj !== null && reduxQueueList.data.selecteddrpdwnObj !== "") {
                                let linkInfo;
                                let islinkpresent = false;
                                linkInfo = queueList[0].linkInfo;
                                if (linkInfo !== null && linkInfo !== "" && linkInfo !== undefined && reduxQueueList.data.selecteddrpdwnObj !== constants.TASKS) {
                                    let linkInfoArr = linkInfo.split(',');
                                    let selectedObjId = getIntParam(OBJECT_TABLEID_MAP[reduxQueueList.data.selecteddrpdwnObj]);
                                    linkInfoArr.map((item, i) => {
                                        let ObjRecordArr = item.split('_');
                                        let tableId = getIntParam(ObjRecordArr[0].trim());
                                        if (selectedObjId === tableId) {
                                            recordId = getIntParam(ObjRecordArr[1]);
                                            object = reduxQueueList.data.selecteddrpdwnObj;
                                            islinkpresent = true;
                                        }
                                    });
                                    openDetailviewQueueTab(object, label, recordId, recordIndex, totalCount);
                                } else {
                                    openDetailviewQueueTab(constants.TASKS, label, recordId, recordIndex, totalCount);
                                }
                                if (!islinkpresent) {
                                    let parentItems = reduxApp.objectList;
                                    parentItems = parentItems.filter(f => {
                                        return f.name === reduxQueueList.data.selecteddrpdwnObj
                                    })
                                    if (parentItems.length > 0) {
                                        let msg = label + " doesn t have any associated " + parentItems[0].label;
                                        dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                    }
                                }
                            } else {
                                openDetailviewQueueTab(object, label, recordId, recordIndex, totalCount);
                            }
                            loadQueueList(getObjectParam(response));
                        }
                    })
                }
            }
        } catch (error) {
            console.error("error in listViewHeader.js -> openRecordInQueue() " + error)
        }
    }
    const loginToBase = () => {
        let baseTeantId = 0;
        let params = {
            franchise_industry_type: reduxApp.me.franchise_industry
        }
        var response = Promise.resolve(HTTPClient.get(endPoints.COMMON.GET_BASETENANT_ID, params));
        response.then((response) => {
            baseTeantId = response.baseTenantId;
            login(baseTeantId);

        });
    }

    const triggerAction = (value, hasSecurityRolePermission, _myClientId) => {
        let promise;
        let selectedRows = null;
        let labelName = null;
        let objectName = null;
        let linkTo = null;
        let isOpenDrawer = false;
        let recordId = 0;
        let data = null;
        let isValid = true;
        let alertDialogMessage = null;
        let selectedIds = [];
        let _isUnitAccountSelected = false
        let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
        let hasPermission = false;
        let tabs = reduxTab.tabs;
        let selectedRowTitle = '';
        let group_parent_object = '';
        let group_parent_recordId = '';
        let group_parent_label = '';
        let group_by_condition = props.listView.data.group_by_condition;
        let isRoyaltyRunDelete = false;
        try {
            setOpenActionList(false);
            objectName = isValidParam(props.object) ? props.object : '';
            objectName = isValidParam(props.childObject) && (props.object === constants.FIND_DUPLICATE_OBJECT || props.object === constants.BROADCAST_CAMPAIGNS_OBJECT) ? props.childObject : objectName;
            value = isValidParam(value) ? value : '';
            if (props.object === constants.UNITS_OBJECT && !reduxApp.isNewTree) {
                selectedRows = reduxTreeView.selectedRows;
            } else {
                selectedRows = props.listView.selectedRows;
            }
            selectedRows = isValidParam(selectedRows) ? selectedRows : [];
            selectedIds = selectedRows.map(row => {
                return row.id;
            });
            let tabInfo = getActiveTabInfo();
            let isInXpress = getBooleanParam(tabInfo.isInXpress);
            let isUnitsListView = false;
            if (tabInfo.hasOwnProperty('isUnitsListView') && tabInfo.isUnitsListView) {
                isUnitsListView = getBooleanParam(tabInfo.isUnitsListView);
            }
            let isUnitsAccount = false;
            let UnitsAccountProjectId = 0;
            if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
                isUnitsAccount = tabInfo.isUnitsAccount;
                UnitsAccountProjectId = tabInfo.UnitsAccountProjectId;
            }
            if ((isInXpress || isUnitsListView || (isUnitsAccount && objectName !== constants.ATTACHMENT_OBJECT)) && ((selectedIds.length > 0 && value !== constants.TO_EXCEL) || value === constants.NEW)) {
                let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
                return false;
            }

            if (selectedIds.length === 1) {

                selectedRowTitle = selectedRows[0].title;

            }
            if (objectName !== '' && value !== '' && value !== 'Enter Sales' && (value === constants.NEW ||
                value === ROYALTY_RUN_ACTIONS.REPORT_SALES || value === ROYALTY_RUN_ACTIONS.PROCESS ||
                value === REPORTED_SALES_ACTIONS.UPDATE || (selectedRows.length > 0 && selectedIds.length > 0))) {
                recordId = selectedIds[0];
                if ((value === constants.NEW || value === constants.EDIT || value === constants.COPY)) {
                    if (value === constants.NEW) {
                        if (objectName === constants.SALES_TEMPLATE_OBJECT || objectName === constants.MARKETING_TEMPLATE_OBJECT
                            || objectName === constants.ATTACHMENT_OBJECT || objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                            hasPermission = true;
                            if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT && hasPermission) {
                                if (reduxApp.me.t_name !== 'Admin') {
                                    hasPermission = false;
                                }
                            }
                        } else {
                            hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
                        }
                        if (!hasPermission) {
                            isOpenDrawer = false;
                            isValid = false;
                            alertDialogMessage = permissionMessage;
                        }
                        if (hasPermission) {
                            if (objectName === constants.SALES_TEMPLATE_OBJECT) {
                                labelName = constants.SALES_TEMPLATE_CREATE_TAB_LABEL;
                            } else if (objectName === constants.MARKETING_TEMPLATE_OBJECT) {
                                labelName = constants.MARKETING_TEMPLATE_CREATE_TAB_LABEL;
                            } else if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                                labelName = constants.AGREEMENT_TEMPLATE_CREATE_TAB_LABEL;
                            } else if (objectName === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                                labelName = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.BROADCAST_CAMPAIGN_CREATE_TAB_LABEL;
                            } else if (objectName === constants.SOCIAL_CAMPAIGNS_OBJECT) {
                                labelName = constants.SOCIAL_CAMPAIGN_CREATE_TAB_LABEL;
                            } else if (objectName === constants.NEWS_OBJECT) {
                                labelName = getLocalizedStrings().label.NEWS.ADD_TAB_LABEL;
                            } else if (objectName === constants.AUTOMATION_DESIGNER_OBJECT) {
                                labelName = getLocalizedStrings().label.COMMON.CREATE + ' ' + objectName.charAt(0).toUpperCase() + objectName.slice(1);
                            } else if (objectName === constants.ATTACHMENT_OBJECT) {
                                let detailViewParams = {};
                                if (props.isDetailView !== undefined && props.isDetailView === true) {
                                    detailViewParams = props.detailViewParams;
                                }
                                let dialogData = {};
                                dialogData.fullWidth = true;
                                dialogData.maxWidth = 'md';
                                labelName = getLocalizedStrings().label.COMMON.CREATE + ' ' + objectName.charAt(0).toUpperCase() + objectName.slice(1);
                                dispatch(getAppDialog(true, constants.LISTVIEW_ATTACHMENT_UPLOAD_DIALOG, getLocalizedStrings().message.ATTACHMENT.UPLOAD_FILE, null, { object: objectName, ...detailViewParams, ...dialogData }, null));
                                return;
                            } else if ((objectName === constants.QUOTATION_OBJECT || objectName === constants.PURCHASE_ORDER_OBJECT
                                || objectName === constants.SALES_ORDER_OBJECT || objectName === constants.JOBS_OBJECT) && value === constants.NEW) {
                                let linkinfo = reduxApp.linkChildInfo;
                                let parentObject = getObjectParam(props.detailViewParams).parent_object || '';
                                if (objectName === constants.QUOTATION_OBJECT && value === 'New') {
                                    labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + "Quotes & Invoices";
                                }
                                else {
                                    labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + getChildObjectCustomTitle(parentObject, objectName, linkinfo);
                                }
                                let info = { id: 0, title: labelName, type: value, object: props.object }
                                if (props.isDetailView) {
                                    info = {
                                        ...info,
                                        parentRecordId: getIntParam(reduxDetailView.data.record.id),
                                        parentObject
                                    }
                                    if (objectName === constants.QUOTATION_OBJECT && parentObject === constants.JOBS_OBJECT) {
                                        info = {
                                            ...info,
                                            id: getIntParam(reduxDetailView.data.record.id),
                                            object: parentObject,
                                            type: constants.GENERATE_INVOICE
                                        };
                                    }
                                }
                                addNewFormTabs(info);
                            } else if ((objectName === constants.APPOINTMENTS) && (props.detailViewParams.parent_object === constants.JOBS_OBJECT) && value === constants.NEW) {
                                isOpenDrawer = false;
                                labelName = "Schedule for " + reduxDetailViewRecord.job_id;
                                let info = {
                                    title: labelName,
                                    type: constants.JOB_SCHEDULER,
                                    object: constants.JOBS_OBJECT,
                                    detailView_recordid: reduxDetailView.record.id,
                                    jobName: reduxDetailViewRecord.job_name
                                };
                                addNewFormTabs(info);
                            } else if (objectName === constants.REPORTED_SALES_OBJECT && props.isDetailView !== undefined && props.isDetailView === true) {
                                isOpenDrawer = false;
                                let id = getIntParam(reduxDetailView.data.record.id);
                                createReportedSales(id);
                            } else if ((objectName === constants.CUSTOM_TABLE1_OBJECT && value === constants.NEW) && INTAKE_FORM.TENENT_PROJECTS_ID.includes(reduxApp.me.projectId)) {
                                let labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + 'Intake Info';
                                let info = { id: 0, title: labelName, type: value, object: constants.INTAKE_FORM };
                                addNewFormTabs(info);
                            }
                            else {
                                if (props.isDetailView !== undefined && props.isDetailView === true) {
                                    if (objectName === constants.APPOINTMENTS) {
                                        labelName = getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT + ' ' + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
                                    } else if (objectName === constants.PAYMENTS_OBJECT) {
                                        labelName = 'Add New Payment';
                                    } else {
                                        let arrChildObjectInfo = getChildObjectInfo(props.detailViewParams.parent_object, objectName);
                                        let itemLabel = getStringParam(arrChildObjectInfo[0].title).trim();
                                        itemLabel = itemLabel === '' ? makeTiteleCase(objectName) : itemLabel;
                                        labelName = getLocalizedStrings().label.COMMON.ADD + ' ' + itemLabel;
                                    }
                                }
                                isOpenDrawer = true;
                            }
                            linkTo = LinkToType.TYPE_NEW;
                        }
                    } else if (value === constants.EDIT || value === constants.COPY) {

                        isOpenDrawer = true;
                        let hasPermission = false;
                        let checkValue = null;
                        if (selectedIds.length === 1) {
                            if (objectName === constants.TASKS || objectName === constants.SALES_TEMPLATE_OBJECT || objectName === constants.MARKETING_TEMPLATE_OBJECT
                                || objectName === constants.SMS_TEMPLATE_OBJECT || objectName === constants.CALL_SCRIPT_OBJECT || objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                                checkValue = constants.PERMISSION_NAME_MANAGE_TEMPLATES;
                                hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_PERMISSION, checkValue);
                                if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT && hasPermission) {
                                    if (reduxApp.me.t_name !== 'Admin') {
                                        hasPermission = false;
                                    }
                                } else if (objectName === constants.TASKS) {
                                    let isTaskEdit = true;
                                    let recordsArr = props.listView.data.records;
                                    let recordsObj = recordsArr.find(function (f) {
                                        return f.id === recordId;
                                    });
                                    if (recordsObj && recordsObj.hasOwnProperty('workflow_task_type')) {
                                        let workflowTaskType = recordsObj.workflow_task_type;
                                        if (workflowTaskType != null && (workflowTaskType === "Email" || workflowTaskType === "SMS")) {
                                            isTaskEdit = false;
                                            isOpenDrawer = false;
                                        }
                                    }
                                    if (!isTaskEdit) {
                                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.CANNOT_OPEN_EMAIL_AND_SMS_TASK, null);
                                        return false;
                                    }
                                }
                            } else if (objectName === constants.WEB_FORMS) {
                                hasPermission = true;
                            } else if (objectName === constants.ROYALTY_PLANS_OBJECT) {
                                hasPermission = true;
                            } else if (objectName === constants.REPORTED_SALES_OBJECT) {
                                hasPermission = true;
                            } else if (objectName === constants.WORKFLOW_OBJECT) {
                                hasPermission = true;
                                checkValue = constants.PERMISSION_NAME_DESIGN_WORKFLOWS;
                                hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_PERMISSION, checkValue);
                            } else if (objectName === constants.WEBHOOKS_OBJECT) {
                                hasPermission = true;
                            } else if (objectName === constants.TEMPLATE_CATEGORIES) {
                                hasPermission = true;
                            } else {
                                if (value === constants.COPY && (objectName === constants.BROADCAST_CAMPAIGNS_OBJECT || objectName === constants.AB_CAMPAIGNS_OBJECT)) {
                                    checkValue = constants.ACCESS_TYPE_CREATE;
                                    hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, checkValue);
                                } else if (value === constants.EDIT && (objectName === constants.BROADCAST_CAMPAIGNS_OBJECT || objectName === constants.AB_CAMPAIGNS_OBJECT)) {
                                    checkValue = constants.ACCESS_TYPE_EDIT;
                                    hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, checkValue);
                                } else if (value === constants.COPY && selectedIds.length > 1) {
                                    isValid = false;
                                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_COPY_ONE_RECORD;
                                } else if (value === constants.COPY) {
                                    if (isChildObjectCopy) {
                                        checkValue = constants.ACCESS_TYPE_CREATE;
                                    } else {
                                        checkValue = constants.ACCESS_TYPE_EDIT;
                                    }

                                    hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, checkValue);
                                } else {
                                    checkValue = constants.ACCESS_TYPE_EDIT;
                                    hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_ACCESS, checkValue);
                                }
                            }
                        } else {
                            checkValue = constants.PERMISSION_NAME_BATCH_UPDATE;
                            hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_PERMISSION, checkValue);
                        }
                        if (!hasPermission) {
                            isOpenDrawer = false;
                        }
                        if (objectName === constants.TASKS) {
                            let isTaskEdit = true;
                            let totalRecords = props.listView.data.records;
                            let selectedRows = props.listView.selectedRows;
                            let selectedIds = selectedRows.map(row => row.id);
                            let matchedRecords = getMatchingData(totalRecords, selectedIds);
                            isTaskEdit = matchedRecords.some(f => f.hasOwnProperty("workflow_task_type") && (f.workflow_task_type === "Email" || f.workflow_task_type === "SMS"));
                            if (isTaskEdit) {
                                isOpenDrawer = false;
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.CANNOT_OPEN_EMAIL_AND_SMS_TASK, null);
                                return false;
                            }
                        }
                        if (hasPermission) {
                            if (objectName === constants.SALES_TEMPLATE_OBJECT
                                || objectName === constants.MARKETING_TEMPLATE_OBJECT
                                || objectName === constants.BROADCAST_CAMPAIGNS_OBJECT
                                || objectName === constants.AB_CAMPAIGNS_OBJECT
                                || objectName === constants.SOCIAL_CAMPAIGNS_OBJECT
                                || objectName === constants.ASSIGNMENT_RULES
                                || objectName === constants.NEWS_OBJECT
                                || objectName === constants.SMS_TEMPLATE_OBJECT
                                || objectName === constants.EMAIL_INTEGRATION_OBJECT
                                || objectName === constants.LANDING_PAGES_FROALA_OBJECT
                                || objectName === constants.CALL_SCRIPT_OBJECT
                                || objectName === constants.AGREEMENT_TEMPLATE_OBJECT
                                || objectName === constants.SECURITY_LEVEL_OBJECT
                                || objectName === constants.COMPLIANCE_OBJECT
                                || objectName === constants.WEBHOOKS_OBJECT
                                || objectName === constants.TEMPLATE_CATEGORIES) {

                                if ((objectName === constants.SALES_TEMPLATE_OBJECT || objectName === constants.AGREEMENT_TEMPLATE_OBJECT || objectName === constants.SECURITY_LEVEL_OBJECT
                                    || objectName === constants.MARKETING_TEMPLATE_OBJECT || objectName === constants.COMPLIANCE_OBJECT || objectName === constants.REFUND_OBJECT
                                    || objectName === constants.WEBHOOKS_OBJECT || objectName === constants.TEMPLATE_CATEGORIES)
                                    && value === constants.EDIT && selectedIds.length > 1) {
                                    isValid = false;
                                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT;
                                } else if (value === constants.COPY && selectedIds.length > 1) {
                                    isValid = false;
                                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_COPY_ONE_RECORD;
                                } else if (objectName === constants.SALES_TEMPLATE_OBJECT) {
                                    if (value === constants.EDIT) {
                                        labelName = getLocalizedStrings().label.SALES_TEMPLATE.SALES_TEMPLATE_EDIT_TAB_LABEL;
                                    } else if (value === constants.COPY) {
                                        labelName = getLocalizedStrings().label.SALES_TEMPLATE.SALES_TEMPLATE_COPY_TAB_LABEL;
                                    }
                                } else if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                                    if (value === constants.EDIT) {
                                        labelName = getLocalizedStrings().label.AGREEMENT_TEMPLATE.AGREEMENT_TEMPLATE_EDIT_TAB_LABEL;
                                    } else if (value === constants.COPY) {
                                        labelName = getLocalizedStrings().label.AGREEMENT_TEMPLATE.AGREEMENT_TEMPLATE_COPY_TAB_LABEL;
                                    }

                                } else if (objectName === constants.MARKETING_TEMPLATE_OBJECT) {
                                    if (value === constants.EDIT) {
                                        if (selectedIds.length > 1) {
                                            data = { id: selectedIds };
                                            value = constants.BATCH_EDIT;
                                            labelName = getLocalizedStrings().label.MARKETING_TEMPLATE.BATCH_EDIT;
                                        } else {
                                            labelName = getLocalizedStrings().label.MARKETING_TEMPLATE.MARKETING_TEMPLATE_EDIT_TAB_LABEL;
                                        }
                                    } else if (value === constants.COPY) {
                                        labelName = getLocalizedStrings().label.MARKETING_TEMPLATE.MARKETING_TEMPLATE_COPY_TAB_LABEL;
                                    }
                                } else if (objectName === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                                    if (value === constants.EDIT) {
                                        if (selectedIds.length === 1) {
                                            labelName = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.BROADCAST_CAMPAIGN_EDIT_TAB_LABEL;
                                        } else {
                                            data = { id: selectedIds, selectedRecords: selectedRows };
                                            value = constants.BATCH_EDIT;
                                            labelName = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.BATCH_EDIT
                                        }

                                    } else if (value === constants.COPY) {
                                        labelName = getLocalizedStrings().label.BROADCAST_CAMPAIGNS.BROADCAST_CAMPAIGN_COPY_TAB_LABEL;
                                    }
                                } else if (objectName === constants.AB_CAMPAIGNS_OBJECT) {
                                    if (value === constants.EDIT) {
                                        if (selectedIds.length === 1) {
                                            labelName = getLocalizedStrings().label.AB_CAMPAIGNS.AB_CAMPAIGN_EDIT_TAB_LABEL;
                                        } else {
                                            data = { id: selectedIds, selectedRecords: selectedRows };
                                            value = constants.BATCH_EDIT;
                                            labelName = getLocalizedStrings().label.AB_CAMPAIGNS.BATCH_EDIT;
                                        }
                                    } else if (value === constants.COPY) {
                                        labelName = getLocalizedStrings().label.AB_CAMPAIGNS.AB_CAMPAIGN_COPY_TAB_LABEL;
                                    }
                                } else if (objectName === constants.SOCIAL_CAMPAIGNS_OBJECT) {
                                    if (value === constants.EDIT) {
                                        labelName = constants.SOCIAL_CAMPAIGN_EDIT_TAB_LABEL;
                                    } else if (value === constants.COPY) {
                                        labelName = constants.SOCIAL_CAMPAIGN_COPY_TAB_LABEL;
                                    }
                                } else if (objectName === constants.WEB_FORMS) {
                                    if (value === constants.EDIT) {
                                        labelName = constants.WEB_FORM_EDIT_TAB_LABEL;
                                    } else if (value === constants.COPY) {
                                        labelName = constants.WEB_FORM_COPY_TAB_LABEL;
                                    }
                                } else if (objectName === constants.ASSIGNMENT_RULES) {
                                    if (value === constants.EDIT) {
                                        if (selectedIds.length > 1) {
                                            isValid = false;
                                            alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT;
                                        } else {
                                            labelName = getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULE_EDIT_TAB_LABEL;
                                        }
                                    }
                                } else if (objectName === constants.NEWS_OBJECT) {
                                    if (value === constants.EDIT) {
                                        if (selectedIds.length > 1) {
                                            isValid = false;
                                            alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT;
                                        } else {
                                            labelName = getLocalizedStrings().label.NEWS.EDIT_TAB_LABEL;
                                        }
                                    }
                                } else if (objectName === constants.SMS_TEMPLATE_OBJECT) {
                                    if (value === constants.EDIT) {
                                        if (selectedIds.length > 1) {
                                            isValid = false;
                                            alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT;
                                        } else {
                                            labelName = getLocalizedStrings().label.SMS_TEMPLATE.SMS_TEMPLATE_EDIT_TAB_LABEL;
                                        }
                                    } else if (value === constants.COPY) {
                                        labelName = getLocalizedStrings().label.SMS_TEMPLATE.SMS_TEMPLATE_COPY_TAB_LABEL;
                                    }
                                } else if (objectName === constants.EMAIL_INTEGRATION_OBJECT) {
                                    if (value === constants.EDIT) {
                                        if (selectedIds.length > 1) {
                                            isValid = false;
                                            alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT;
                                        } else {
                                            data = { id: recordId, selectedRecords: selectedRows };
                                            labelName = getLocalizedStrings().label.COMMON.EDIT + " " + getLocalizedStrings().label.EMAIL_INTEGRATION.EMAIL;
                                        }

                                    }
                                } else if (objectName === constants.CALL_SCRIPT_OBJECT) {
                                    if (value === constants.EDIT) {
                                        if (selectedIds.length > 1) {
                                            isValid = false;
                                            alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT;
                                        } else {
                                            labelName = getLocalizedStrings().label.CALL_SCRIPT.CALL_SCRIPTS_EDIT_TAB_LABEL;
                                        }
                                    } else if (value === constants.COPY) {
                                        labelName = getLocalizedStrings().label.TWILIO.CALL_SCRIPT_COPY_TAB_LABEL;
                                    }
                                } else if (props.object === constants.LANDING_PAGES_FROALA_OBJECT) {
                                    if (value === constants.EDIT) {
                                        labelName = getLocalizedStrings().label.LANDING_PAGE.EDIT_LANDING_PAGE;
                                        alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT;
                                    } else if (value === constants.COPY) {
                                        labelName = getLocalizedStrings().label.LANDING_PAGE.COPY_LANDING_PAGE;
                                        alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_COPY;
                                    }
                                    if (selectedIds.length > 1) {
                                        isValid = false;
                                    }
                                } else if (props.object === constants.COMPLIANCE_OBJECT) {
                                    if (value === constants.EDIT) {
                                        labelName = getLocalizedStrings().label.COMPLIANCE.EDIT_TAB_LABEL;
                                        alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT;
                                    }
                                }
                            } else if ((objectName === constants.QUOTATION_OBJECT || objectName === constants.PURCHASE_ORDER_OBJECT
                                || objectName === constants.SALES_ORDER_OBJECT || objectName === constants.JOBS_OBJECT) && (value === constants.EDIT || value === constants.COPY)) {
                                isOpenDrawer = false;
                                if (selectedIds.length === 1) {
                                    let info = {
                                        id: recordId,
                                        title: selectedRowTitle,
                                        type: value,
                                        object: props.object,
                                        callFrom: constants.LIST_VIEW
                                    };
                                    if (props.isDetailView) {
                                        info = {
                                            ...info,
                                            parentRecordId: getIntParam(reduxDetailView.data.record.id),
                                            parentObject: getStringParam(props.detailViewParams.parent_object)
                                        };
                                    }
                                    addNewFormTabs(info);
                                } else {
                                    isValid = false;
                                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT;
                                }

                            } else if (objectName === constants.REPORTED_SALES_OBJECT) {
                                isOpenDrawer = false;
                                if (reduxDetailView.isDetailView == false) {
                                    if (selectedIds.length === 1) {
                                        let rowIdx = reduxListView.data.records.findIndex((row) => row.id === recordId);
                                        let statusValue = reduxListView.data.records[rowIdx].t_status;
                                        if (statusValue !== 'Submitted') {
                                            isOpenDrawer = true;
                                            data = {
                                                object: constants.REPORTED_SALES_OBJECT,
                                                id: selectedIds[0],
                                                mode: constants.SF_FORM_OPEN_MODE_QUICK
                                            }

                                            labelName = getLocalizedStrings().label.REPORTED_SALES.EDIT_TAB_LABEL;
                                        } else {
                                            let msg = 'This royalty has already been submitted.';
                                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                                        }

                                    } else {
                                        isValid = false;
                                        alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT;
                                    }
                                } if (reduxDetailView.isDetailView == true) {
                                    isOpenDrawer = true;
                                    data = {
                                        object: constants.REPORTED_SALES_OBJECT,
                                        id: selectedIds[0],
                                        mode: constants.SF_FORM_OPEN_MODE_QUICK
                                    }

                                    labelName = getLocalizedStrings().label.REPORTED_SALES.EDIT_TAB_LABEL;
                                }

                            } else if (objectName === constants.ROYALTY_PLANS_OBJECT) {
                                isOpenDrawer = true;
                                if (value === constants.EDIT) {
                                    labelName = getLocalizedStrings().label.ROYALTY_PLAN.EDIT_TAB_LABEL;
                                } else if (value === constants.COPY) {
                                    labelName = getLocalizedStrings().label.ROYALTY_PLAN.COPY_TAB_LABEL;
                                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT;
                                }
                            } else {
                                if (props.object === constants.APPOINTMENTS) {
                                    labelName = getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENT;
                                } else if (props.object === constants.TASKS) {
                                    labelName = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
                                } else if (getBooleanParam(props.isDetailView)) {
                                    labelName = childObjectLabel;
                                    if (value === constants.COPY) {
                                        labelName = getLocalizedStrings().label.COMMON.COPY + ' ' + childObjectLabel;
                                        if (isChildObjectCopy && value === constants.COPY && selectedIds.length > 1) {
                                            isValid = false;
                                            alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_COPY_ONE_RECORD;
                                        }
                                    }
                                } else if (props.object === constants.SOURCE_OBJECT || props.object === constants.TASKSLIST_OBJECT || props.object === constants.NOTE_TYPES_OBJECT || props.object === constants.APPOINTMENT_TYPES_OBJECT || props.object === constants.CAMPAIGN_TYPES_OBJECT || props.object === constants.RELATIONS_OBJECT) {
                                    labelName = getLocalizedStrings().label.SETTINGS[props.object.toUpperCase()];
                                } else {
                                    let activeTab = getActiveTab();
                                    if (activeTab.object === constants.SEARCH_RESULTS) {
                                        let obj = isValidParam(activeTab.info) && isValidParam(activeTab.info.search) ? getObjectParam(activeTab.info.search.object) : '';
                                        labelName = getObjectLabelByObject(obj);
                                    } else if (activeTab.object === constants.ADVANCE_SEARCH_RESULTS) {
                                        let obj = isValidParam(activeTab.info) && isValidParam(activeTab.info.advanceSearch) ? getObjectParam(activeTab.info.advanceSearch.object) : '';
                                        labelName = getObjectLabelByObject(obj);
                                    } else {
                                        labelName = getObjectLabelByObject(activeTab.object);
                                    }
                                }
                                if (value !== constants.COPY) {
                                    if (isValidParam(getLocalizedStrings().label.HEADER[labelName])) {
                                        labelName = selectedRows.length === 1 ? getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.HEADER[labelName] : getLocalizedStrings().label.COMMON.BATCH_EDIT;
                                    } else {
                                        labelName = selectedRows.length === 1 ? getLocalizedStrings().label.COMMON.EDIT + ' ' + labelName : getLocalizedStrings().label.COMMON.BATCH_EDIT;
                                    }
                                }

                                labelName = labelName.length === 0 ? getLocalizedStrings().label.COMMON.EMPTY : labelName;
                                if (objectName === constants.APPOINTMENTS || objectName === constants.TASKS || value === constants.EDIT ||
                                    (value === constants.COPY && (objectName === constants.AUTOMATION_DESIGNER_OBJECT || objectName === constants.WEB_FORMS
                                        || objectName === constants.WORKFLOW_OBJECT))) {
                                    if (selectedIds.length === 1) {
                                        data = { id: recordId, selectedRecords: selectedRows };
                                        if (recordId > 0 && objectName === constants.SECURITY_LEVEL_OBJECT && value === constants.EDIT) {
                                            let securityLevelRecords = props.listView.data.records;
                                            let securityLevelobj = securityLevelRecords.filter(securityLevelob => securityLevelob.id === recordId);
                                            if (securityLevelobj.length > 0) {
                                                data.description = securityLevelobj[0].t_description;
                                                data.name = securityLevelobj[0].t_name;
                                            }
                                        }
                                    } else {
                                        data = { id: selectedIds, selectedRecords: selectedRows };
                                        if (value !== constants.COPY) {
                                            value = constants.BATCH_EDIT;
                                        }
                                    }

                                    if (objectName === constants.AUTOMATION_DESIGNER_OBJECT) {
                                        if (selectedIds.length === 1) {
                                            let records = props.listView.data.records.filter(function (el) {
                                                return el.id === selectedIds[0];
                                            });
                                            if (isValidParam(records[0]) && records[0].hasOwnProperty('t_name')) {
                                                labelName = records[0]['t_name'];
                                            }
                                            if (value === constants.COPY) {
                                                labelName = getLocalizedStrings().label.FILTER.COPY_FILTER + " " + labelName;
                                            }
                                            let linkToUrl = null;
                                            let isOpenTab = false;
                                            let automationTab = tabs.find((tab) => {
                                                return tab.type === TYPE_DETAIL_VIEW && tab.object === constants.AUTOMATION_DESIGNER_OBJECT
                                            });
                                            if (tabs.length === 1) {
                                                linkToUrl = '/' + objectName + "/" + value + '/' + recordId;
                                                linkTo = value;
                                                isOpenDrawer = false;
                                                isOpenTab = true;
                                            } else {
                                                tabs = tabs.filter(function (el) {
                                                    return el.object === constants.AUTOMATION_DESIGNER_OBJECT;
                                                });
                                                if (tabs.length > 1) {
                                                    let url = tabs[1].url;
                                                    if (url.includes(recordId)) {
                                                        if (value === constants.COPY) {
                                                            isOpenDrawer = false;
                                                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD, () => handleSfDialogAlertobjh(automationTab), null);

                                                        } else {
                                                            if ((url.includes('copy') && value === constants.EDIT)) {
                                                                isOpenDrawer = false;

                                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD, () => handleSfDialogAlertobjh(automationTab), null);

                                                            } else {
                                                                linkToUrl = '/' + objectName + "/" + value + '/' + recordId;
                                                                linkTo = value;
                                                                isOpenDrawer = false;
                                                                isOpenTab = true;
                                                            }
                                                        }
                                                    } else {
                                                        isOpenDrawer = false;

                                                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD, () => handleSfDialogAlertobjh(automationTab), null);

                                                    }
                                                } else {
                                                    let url = tabs[0].url;
                                                    if (url === '/' + constants.AUTOMATION_DESIGNER_OBJECT + '/queries') {
                                                        linkToUrl = '/' + objectName + "/" + value + '/' + recordId;
                                                        linkTo = value;
                                                        isOpenDrawer = false;
                                                        isOpenTab = true;
                                                    } else {
                                                        isOpenDrawer = false;

                                                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.RESTRICT_TO_OPEN_TWO_OR_MORE_RECORD, () => handleSfDialogAlertobjh(automationTab), null);

                                                    }
                                                }
                                            }
                                            if (isOpenTab) {
                                                linkToUrl = makeValidAppUrl(linkToUrl);
                                                let tab = {
                                                    item: objectName,
                                                    label: labelName,
                                                    object: objectName,
                                                    type: TYPE_DETAIL_VIEW,
                                                    imgName: '',
                                                    info: {},
                                                    url: linkToUrl
                                                };
                                                addTab(tab, true);
                                            }
                                        } else {
                                            if (value !== constants.COPY) {
                                                isOpenDrawer = false;
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_RECORD_FOR_EDIT_ONE_RECORD, null);
                                            } else {
                                                isOpenDrawer = false;
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_RECORD_FOR_COPY_ONE_RECORD, null);
                                            }
                                        }
                                    } else if (objectName === constants.TASKS && value === constants.EDIT) {
                                        if (selectedIds.length === 1) {
                                            isOpenDrawer = false;
                                            let isOpenTab = false;
                                            let records = props.listView.data.records.filter(function (el) {
                                                return el.id === selectedIds[0];
                                            });
                                            if (isValidParam(records[0]) && records[0].hasOwnProperty('t_name')) {
                                                labelName = records[0]['t_name'];
                                            }
                                            let linkUrl = '#';
                                            linkUrl = '/' + objectName + '/' + LinkToType.TYPE_DETAIL_VIEW + '/' + recordId;
                                            isOpenTab = true;
                                            let activeTab = getObjectParam(getActiveTab());
                                            let activeTabIndex = getIntParam(activeTab.index);
                                            if (isOpenTab) {
                                                linkUrl = makeValidAppUrl(linkUrl);
                                                let tab = {
                                                    label: labelName,
                                                    object: objectName,
                                                    type: TYPE_DETAIL_VIEW,
                                                    imgName: null,
                                                    url: linkUrl,
                                                    isActive: true,
                                                    parentTabIndex: activeTabIndex
                                                };
                                                let _info = getActiveTabInfo();
                                                if (_info.selectedPageNo > 1) {
                                                    _info.selectedPageNo = 1;
                                                    updateTabInfo(_info);
                                                }
                                                addTab(tab, true);

                                            }
                                        } else {
                                            isOpenDrawer = false;
                                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_RECORD_FOR_EDIT_ONE_RECORD, null);
                                        }
                                    } else if (objectName === constants.TOUCH_CAMPAIGNS_OBJECT) {
                                        if (selectedIds.length === 1) {
                                            isOpenDrawer = false;
                                            let token = localStorage.getItem('token');
                                            let _redirectUrl = constants.snapshotUrl;
                                            _redirectUrl += '&token=' + token;
                                            _redirectUrl += '&type=edit';
                                            window.open(_redirectUrl);
                                        } else {
                                            isOpenDrawer = false;
                                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_RECORD_FOR_EDIT_ONE_RECORD, null);
                                        }
                                    } else if (objectName === constants.WEB_FORMS) {
                                        isOpenDrawer = false;
                                        if (selectedIds.length === 1) {
                                            let recordsArr = props.listView.data.records;
                                            recordsArr = recordsArr.filter(function (el) {
                                                return el.id === recordId;
                                            });
                                            recordsArr = recordsArr[0];
                                            let tableId = recordsArr['t_temp_tableid'];
                                            let token = localStorage.getItem('token');
                                            let _redirectUrl = constants.snapshotUrl;
                                            _redirectUrl += '&token=' + token;
                                            _redirectUrl += '&callingfor=' + "webform";
                                            _redirectUrl += '&recordId=' + recordId;
                                            _redirectUrl += '&tableId=' + tableId;
                                            if (value === constants.COPY) {
                                                _redirectUrl += '&type=COPY_WEBFORM';
                                                _redirectUrl += '&label=Copy ' + selectedRows[0]['title'];
                                            } else if (value === constants.EDIT) {
                                                _redirectUrl += '&type=' + value.toLowerCase();
                                                _redirectUrl += '&label=Edit ' + selectedRows[0]['title'];
                                            }
                                            window.open(_redirectUrl);
                                        } else {
                                            if (value !== constants.COPY) {
                                                isOpenDrawer = false;
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_RECORD_FOR_EDIT_ONE_RECORD, null);
                                            } else {
                                                isOpenDrawer = false;
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_RECORD_FOR_COPY_ONE_RECORD, null);
                                            }
                                        }
                                    }
                                    else if (objectName === constants.WORKFLOW_OBJECT) {
                                        if (selectedIds.length === 1) {
                                            let recordsArr = props.listView.data.records;
                                            recordsArr = recordsArr.filter(function (el) {
                                                return el.id === recordId;
                                            });
                                            recordsArr = recordsArr[0];
                                            let tableId = recordsArr['t_temp_tableid'];
                                            isOpenDrawer = false;
                                            let token = localStorage.getItem('token');
                                            let _redirectUrl = constants.snapshotUrl;
                                            _redirectUrl += '&token=' + token;
                                            _redirectUrl += '&callingfor=' + "workflows";
                                            _redirectUrl += '&recordId=' + recordId;
                                            if (value === constants.COPY) {
                                                _redirectUrl += '&type=copy';
                                                _redirectUrl += '&tableId=' + tableId;
                                            } else if (value === constants.EDIT) {
                                                _redirectUrl += '&type=edit';
                                                _redirectUrl += '&tableId=' + tableId;
                                            }
                                            if (group_by_condition === 'Appointments' && value === constants.COPY) {
                                                isOpenDrawer = false;
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.CANNOT_COPY_APPOINTMENT_WORKFLOW, null);
                                            } else if (reduxApp.me.is_customize_workflow) {
                                                window.open(_redirectUrl);
                                            } else {
                                                isOpenDrawer = false;
                                                let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
                                            }
                                        } else {
                                            if (value !== constants.COPY) {
                                                isOpenDrawer = false;
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_RECORD_FOR_EDIT_ONE_RECORD, null);
                                            } else {
                                                isOpenDrawer = false;
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_RECORD_FOR_COPY_ONE_RECORD, null);
                                            }
                                        }
                                    } else if (objectName === constants.SOURCE_OBJECT || objectName === constants.TASKSLIST_OBJECT || objectName === constants.NOTE_TYPES_OBJECT || props.object === constants.APPOINTMENT_TYPES_OBJECT || objectName === constants.CAMPAIGN_TYPES_OBJECT || objectName === constants.RELATIONS_OBJECT || objectName === constants.LOCATION_OBJECT) {
                                        isOpenDrawer = false;
                                        if (selectedIds.length === 1) {
                                            isOpenDrawer = true;
                                            data = { id: recordId, selectedRecords: selectedRows };
                                        } else {
                                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.SELECT_RECORD_FOR_EDIT_ONE_RECORD, null);
                                        }

                                    } else if (objectName === constants.ASSIGNMENT_RULES_OBJECT) {
                                        isOpenDrawer = false;
                                        if (selectedIds.length === 1) {
                                            labelName = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULE;
                                            isOpenDrawer = true;
                                            let tableName = constants.ACCOUNTS_OBJECT;
                                            let listviewData = getArrayParam(props.listView.data.records);
                                            if (listviewData.length > 0) {
                                                listviewData = listviewData.filter(f => f.id === recordId);
                                                if (listviewData.length > 0) {
                                                    tableName = TABLEID_OBJECT_MAP[listviewData[0].t_temp_tableid];
                                                }
                                            }
                                            data = { id: recordId, table: tableName, mode: 'edit' };
                                        } else {
                                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.SELECT_RECORD_FOR_EDIT_ONE_RECORD, null);
                                        }
                                    } else if (objectName === constants.ROLL_UP_OBJECT) {
                                        isOpenDrawer = false;
                                        if (selectedIds.length === 1) {
                                            labelName = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.SETTINGS.ROLLUP;
                                            isOpenDrawer = true;
                                            let listviewData = getArrayParam(props.listView.data.records);
                                            if (listviewData.length > 0) {
                                                listviewData = listviewData.filter(f => f.id === recordId);
                                            }
                                            data = {
                                                object: objectName,
                                                id: recordId,
                                                isDetailView: false,
                                                type: constants.EDIT,

                                            };
                                        } else {
                                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.SELECT_RECORD_FOR_EDIT_ONE_RECORD, null);
                                        }
                                    } else if (objectName === constants.CUSTOM_TABLE1_OBJECT && INTAKE_FORM.TENENT_PROJECTS_ID.includes(reduxApp.me.projectId)) {
                                        isOpenDrawer = false;
                                        let info = {};
                                        let labelName = getLocalizedStrings().label.COMMON.EDIT + ' ' + 'Intake Info';
                                        let activeTab = getObjectParam(getActiveTab());
                                        let activeTabIndex = getIntParam(activeTab.index);
                                        let newTab = {
                                            object: constants.INTAKE_FORM,
                                            url: `/${constants.INTAKE_FORM}/${constants.SPREADSHEET}/${recordId}`,
                                            type: TYPE_OTHER_VIEW,
                                            label: labelName,
                                            info: info,
                                            isActive: true,
                                            parentTabIndex: activeTabIndex,
                                            recordId: recordId,
                                            imgName: null,
                                        };

                                        let intakeTab = reduxTab?.tabs?.find((tab) => {
                                            return tab.type === TYPE_OTHER_VIEW && tab.object === constants.INTAKE_FORM;
                                        });

                                        if (!isValidParam(intakeTab)) {
                                            addTab(newTab);
                                            return false;
                                        } else {
                                            let url = intakeTab?.url;
                                            if (url.includes(labelName)) {
                                                addTab(newTab);
                                                return false;
                                            } else {
                                                let message = '';
                                                if (objectName === constants.CUSTOM_TABLE1_OBJECT) {
                                                    message = getLocalizedStrings().message.INTAKE_FORM.RESTRICT_TO_OPEN_TWO_OR_MORE_INTAKE_FORM;
                                                }
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, message, () => {
                                                    let tabIndex = intakeTab.index;
                                                    selectTabByIndex(tabIndex);
                                                })
                                            }
                                        }
                                    } else if (objectName === constants.LANDING_PAGES_FROALA_OBJECT) {
                                        if (selectedIds.length > 1) {
                                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.SELECT_RECORD_FOR_EDIT_ONE_RECORD, null);
                                            isOpenDrawer = false;
                                        }
                                    } else if (objectName === constants.LMS_USERS_OBJECT) {
                                        isOpenDrawer = false;
                                        if (selectedIds.length === 1) {
                                            labelName = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.SETTINGS.LMS_USERS;
                                            isOpenDrawer = true;

                                            data = {
                                                object: objectName,
                                                id: recordId,
                                                isDetailView: false,
                                                type: constants.EDIT,

                                            };
                                        } else {
                                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.SELECT_RECORD_FOR_EDIT_ONE_RECORD, null);
                                        }
                                    }
                                }
                            }
                            data = getObjectParam(data);
                            data.object = objectName;
                            if (objectName !== constants.MARKETING_TEMPLATE_OBJECT && objectName !== constants.BROADCAST_CAMPAIGNS_OBJECT && objectName !== constants.ASSIGNMENT_RULES_OBJECT
                                && objectName !== constants.CALL_SCRIPT_OBJECT && objectName !== constants.SMS_TEMPLATE_OBJECT && objectName !== constants.REPORTED_SALES_OBJECT && objectName !== constants.ROLL_UP_OBJECT && objectName !== constants.LMS_USERS_OBJECT
                                && objectName !== constants.WEBHOOKS_OBJECT && objectName !== constants.TEMPLATE_CATEGORIES) {
                                data.mode = constants.SF_FORM_OPEN_MODE_EXPANDED;
                            }
                            if ((objectName === constants.MARKETING_TEMPLATE_OBJECT && value === constants.BATCH_EDIT)) {
                                // bypass 
                            } else if ((objectName === constants.MARKETING_TEMPLATE_OBJECT || objectName === constants.BROADCAST_CAMPAIGNS_OBJECT) &&
                                (value === constants.EDIT || value === constants.COPY)) {
                                data.id = recordId;
                                data.type = selectedRows[0].type;
                            } else {
                                data.id = recordId;
                            }
                        } else {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
                        }
                    }
                } else if (objectName === constants.NOTE_TYPES_OBJECT && (value === constants.INACTIVE || value === constants.ACTIVE)) {
                    handleNoteTypesAction(selectedIds, value, objectName)
                } else if (value === constants.TO_XMS) {
                    let isListView = props.listView.mounted;
                    let params = {};
                    params.recordId = recordId;
                    params.isListView = isListView;
                    promise = Promise.resolve(HTTPClient.post(endPoints.ACCOUNTS.GET_XMS_FIELD_VALUE, params));
                    promise.then((response) => {
                        if (response.status === 0) {
                            setFieldValue(response.data.fields);
                            let dialogData = {};
                            dialogData.fieldValue = fieldValue;
                            dialogData.data = data;
                            dialogData.isListView = isListView
                            dispatch(getAppDialog(true, constants.XMS_PUSH_DIALOG, "", (val, isSuccess) => xmsAlertDialog(val, isSuccess), dialogData, constants.DETAIL_VIEW));

                        } else {
                            if (response.status === -1) {
                                let error = response.error.message;
                                setFieldValue([]);
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.XMS_ERROR_ALERT_DIALOG_TITLE, error, null)
                            }
                        }
                    });
                } else if (value === constants.ADD_NOTES || value === constants.CONVERT
                    || value === constants.CONVERT_TO_ACCOUNTS || value === constants.ADD_TO_GROUP
                    || value === constants.ASSIGN || value === constants.SHARE
                    || value === constants.PUSH || value === constants.MAP
                    || value === constants.RESCHEDULE || value === constants.TO_EXCEL
                    || value === constants.PRINT || value === constants.COMPLETE
                    || value === constants.NOTE || value === constants.MERGE || value === 'Add Task') {

                    if (value === 'Add Task') {

                        if (selectedIds.length === 1 && (objectName === constants.ACCOUNTS_OBJECT || objectName === constants.PARTNERS_OBJECT || objectName === constants.OPPORTUNITIES_OBJECT || objectName === constants.UNITS_OBJECT)) {
                            let data = {
                                id: 0,
                                object: constants.TASKS,
                            }
                            let objName = "";

                            data['parent_object'] = props.object;
                            data['parent_record_id'] = recordId;
                            data['isDetailView'] = true;
                            objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
                            data.isFranchiseRecord = false;
                            data.selectedOpt = constants.TASKS;
                            data.isSingleRecord = true;
                            let add = getLocalizedStrings().label.COMMON.ADD;
                            let labelName = add + ' ' + objName;
                            dispatch(getAppDrawer(true, labelName, constants.NEW, data, constants.OTHER));

                        }
                        if (selectedIds.length > 1 && (objectName === constants.ACCOUNTS_OBJECT || objectName === constants.PARTNERS_OBJECT || objectName === constants.OPPORTUNITIES_OBJECT || objectName === constants.UNITS_OBJECT)) {

                            let data = {
                                id: 0,
                                object: constants.TASKS,
                            }
                            let objName = "";

                            data['parent_object'] = props.object;
                            data['parent_record_id'] = selectedIds;
                            objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
                            data.isFranchiseRecord = false;
                            data.selectedOpt = constants.TASKS;
                            data.isMultipleRecord = true;
                            let add = getLocalizedStrings().label.COMMON.ADD;
                            let labelName = add + ' ' + objName;
                            dispatch(getAppDrawer(true, labelName, constants.NEW, data, constants.OTHER));
                        }
                    }
                    if (value === constants.SHARE) {
                        labelName = getLocalizedStrings().label.COMMON[value.toUpperCase()] + ' ' + getLocalizedStrings().label.COMMON.WITH;
                    } else {
                        if (value === 'Add to Group') {
                            labelName = getLocalizedStrings().label.COMMON.ADD_TO_GROUP;
                        } else if (value === 'Add Notes') {
                            labelName = getLocalizedStrings().label.COMMON.ADD_NOTES;
                        } else if (value === 'To Excel') {
                            labelName = getLocalizedStrings().label.COMMON.TO_EXCEL;
                        }
                        else if (value === 'Convert to Accounts') {
                            if (selectedIds.length > 1) {
                                labelName = getLocalizedStrings().label.COMMON.BATCH_CONVERT_TO_ACCOUNTS;
                            } else {
                                labelName = getLocalizedStrings().label.COMMON.CONVERT_TO_ACCOUNTS;
                            }
                        } else if (value === 'Push') {
                            labelName = "Push to";
                        } else {
                            labelName = getLocalizedStrings().label.COMMON[value.toUpperCase()];
                        }
                    }
                    let hasPermission = false;
                    let checkValue = null;
                    let securityLevelType = null;
                    if (value === constants.CONVERT && props.isDetailView !== true) {

                        data = {
                            getListView: () => getListView(),
                            selectedRecords: selectedRows,
                        }
                        if (props.object === constants.FIND_DUPLICATE_OBJECT) {
                            data.object = props.childObject !== undefined ? props.childObject : objectName;
                            data.listViewObject = props.object;
                        }
                    }

                    if (value === constants.ADD_NOTES) {
                        checkValue = constants.ACCESS_TYPE_CREATE;
                        securityLevelType = constants.SECURITY_LEVEL_TYPE_ACCESS;
                        hasPermission = hasAccessPermission(constants.NOTES, securityLevelType, checkValue);
                        data = {
                            ...data,
                            selectedRecords: selectedRows
                        }
                    } else if (value === constants.ASSIGN || value === constants.SHARE) {
                        if (value === constants.SHARE) {
                            data = {
                                ...data,
                                selectedRecords: selectedRows
                            }
                            if (reduxApp.me.is_tenant || hasSecurityRolePermission || reduxApp.me.t_id === 2) {
                                hasPermission = true;
                            }
                            permissionMessage = getLocalizedStrings().message.LIST_VIEW.SHARE_PERMISSION;
                        } else {
                            checkValue = constants.ACCESS_TYPE_EDIT;
                            securityLevelType = constants.SECURITY_LEVEL_TYPE_ACCESS;
                            hasPermission = hasAccessPermission(objectName, securityLevelType, checkValue);
                            if (value === constants.ASSIGN) {
                                data = {
                                    ...data,
                                    selectedRecords: selectedRows
                                }
                            }
                        }
                    } else if (value === constants.TO_EXCEL) {
                        checkValue = constants.PERMISSION_NAME_EXPORT_RECORDS;
                        securityLevelType = constants.SECURITY_LEVEL_TYPE_PERMISSION;
                        hasPermission = hasAccessPermission(objectName, securityLevelType, checkValue);
                    } else if (value === constants.MERGE) {
                        checkValue = constants.PERMISSION_NAME_MERGE_RECORDS;
                        securityLevelType = constants.SECURITY_LEVEL_TYPE_PERMISSION;
                        hasPermission = hasAccessPermission(objectName, securityLevelType, checkValue);
                        if (objectName === constants.ACCOUNTS_OBJECT || objectName === constants.UNITS_OBJECT) {
                           
                            map(selectedRows, function (rec, index) {
                                if (rec.hasOwnProperty("myClientId") && rec?.myClientId > 0) {
                                    hasPermission = false;
                                    _isUnitAccountSelected = true;
                                }

                            });
                        }
                    } else if (value === constants.RESCHEDULE) {
                        if (selectedIds.length === 1) {
                            checkValue = constants.ACCESS_TYPE_EDIT;
                            securityLevelType = constants.SECURITY_LEVEL_TYPE_ACCESS;
                            hasPermission = hasAccessPermission(objectName, securityLevelType, checkValue);
                        } else {
                            checkValue = constants.PERMISSION_NAME_BATCH_UPDATE;
                            hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_PERMISSION, checkValue);
                        }

                    } else {
                        hasPermission = true;
                        if (value === constants.ADD_TO_GROUP) {
                            data = {
                                ...data,
                                selectedRecords: selectedRows
                            }
                        } else if (value === constants.PUSH) {
                            data = {
                                ...data,
                                selectedRecords: selectedRows
                            }
                        }
                    }
                    if (!hasPermission) {
                        isOpenDrawer = false;
                    }
                    if (hasPermission) {
                        isOpenDrawer = true;
                        if (value === constants.ADD_NOTES) {
                            data = {
                                object: constants.NOTE_OBJECT,
                                parent_object: props.object,
                                parent_record_id: selectedIds[0],
                                mode: constants.SF_FORM_OPEN_MODE_QUICK
                            }
                        } else if (value === constants.MAP || value === constants.TO_EXCEL) {
                            data = {
                                object: props.object,
                                id: selectedIds
                            }
                        } else if (value === constants.MERGE) {
                            let isDetailView = false;
                            if (props.isDetailView !== undefined && props.isDetailView === true) {
                                isDetailView = true;
                            }
                            data = {
                                object: isValidParam(objectName) ? getStringParam(objectName) : props.object,
                                isDetailView: isDetailView,
                                first_record_id: selectedIds[0],
                                second_record_id: selectedIds[1],
                            }
                        }
                    } else if(value === constants.MERGE && (objectName === constants.ACCOUNTS_OBJECT || objectName === constants.UNITS_OBJECT) && !hasPermission && _isUnitAccountSelected){
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "You cannot merge unit records.", null)

                    } 
                        else {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null)
                    }
                } else if (value === constants.DELETE) {
                    let hasPermission = false;
                    let checkValue = null;
                    let securityLevelType = null;
                    if (selectedIds.length === 1) {
                        if (objectName === constants.SALES_TEMPLATE_OBJECT || objectName === constants.MARKETING_TEMPLATE_OBJECT
                            || objectName === constants.SMS_TEMPLATE_OBJECT || objectName === constants.CALL_SCRIPT_OBJECT || objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                            checkValue = constants.PERMISSION_NAME_MANAGE_TEMPLATES;
                            securityLevelType = constants.SECURITY_LEVEL_TYPE_PERMISSION;
                        } else {
                            checkValue = constants.ACCESS_TYPE_DELETE;
                            securityLevelType = constants.SECURITY_LEVEL_TYPE_ACCESS
                        }
                        hasPermission = hasAccessPermission(objectName, securityLevelType, checkValue);
                        if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT && hasPermission) {
                            if (reduxApp.me.t_name !== 'Admin') {
                                hasPermission = false;
                            }
                        }
                        if (objectName === constants.ATTACHMENT_OBJECT || objectName === constants.WEB_FORMS) {
                            hasPermission = true;
                        }
                        if (objectName === constants.ACCOUNTS_OBJECT || objectName === constants.UNITS_OBJECT) {
                            if (_myClientId && _myClientId > 0) {
                                hasPermission = false
                            }
                        }
                    } else {
                        if (objectName === constants.SALES_TEMPLATE_OBJECT || objectName === constants.MARKETING_TEMPLATE_OBJECT
                            || objectName === constants.SMS_TEMPLATE_OBJECT || objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                            checkValue = constants.PERMISSION_NAME_MANAGE_TEMPLATES;
                        } else {
                            checkValue = constants.PERMISSION_NAME_BATCH_DELETE;
                        }
                        securityLevelType = constants.SECURITY_LEVEL_TYPE_PERMISSION;
                        hasPermission = hasAccessPermission(objectName, securityLevelType, checkValue);
                        if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT && hasPermission) {
                            if (reduxApp.me.t_name !== 'Admin') {
                                hasPermission = false;
                            }
                        }
                        if (objectName === constants.WEB_FORMS) {
                            hasPermission = true;
                        }
                        if (objectName === constants.LMS_USERS_OBJECT) {
                            hasPermission = false
                        }
                        if (objectName === constants.WEBHOOKS_OBJECT) {
                            hasPermission = false
                        }
                    }
                    if (objectName === constants.REPORTED_SALES_OBJECT) {
                        if (selectedIds.length > 0) {
                            let records = props.listView.data.records.filter(function (el) {
                                return selectedIds.includes(el.id);
                            });
                            hasPermission = records.findIndex(record => record.reported_by !== reduxApp.me.name) === -1;
                        }
                    }
                    if (hasPermission) {
                        let detailViewParams = {};
                        if (props.isDetailView !== undefined && props.isDetailView === true) {
                            detailViewParams = props.detailViewParams;
                            group_parent_object = detailViewParams !== null ? detailViewParams.parent_object : '';
                            group_parent_recordId = detailViewParams !== null ? detailViewParams.parent_record_id : ''
                        } else {
                            if (objectName === constants.ACCOUNTS_OBJECT || objectName === constants.PARTNERS_OBJECT || objectName === constants.CONTACTS_OBJECT) {
                                let activeTabInfo = getObjectParam(getActiveTabInfo());
                                let filterObj = getObjectParam(activeTabInfo.filter);
                                let filterId = getIntParam(filterObj.id);
                                let type = getStringParam(filterObj.type);
                                if (type === 'group') {
                                    group_parent_object = constants.GROUP_OBJECT;
                                    group_parent_recordId = filterId;
                                    group_parent_label = filterObj.name;
                                }
                            }
                        }
                        let msg = '';
                        if (props.isDetailView !== undefined && props.isDetailView) {
                            if (linkChildTableId === 0) {
                                if (objectName === constants.ACCOUNTS_OBJECT) {
                                    msg = getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
                                } else {
                                    msg = getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_UNLINK_MSG;
                                }
                            } else if (objectName === constants.CONTACTS_OBJECT) {
                                msg = getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
                            }
                            else {
                                msg = getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
                            }
                        } else {
                            if (props.object === constants.RECYCLE_BIN_OBJECT) {
                                msg = getLocalizedStrings().message.BATCH_ACTION.PERMANENT_DELETE_MSG;
                            } else if (props.object === constants.SOURCE_OBJECT || props.object === constants.TASKSLIST_OBJECT) {
                                msg = getLocalizedStrings().message.BATCH_ACTION.PERMANENT_DELETE_MSG;
                            } else {
                                msg = getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
                            }
                        }
                        if (selectedIds.length === 1) {
                            if (props.object === constants.SOURCE_OBJECT || props.object === constants.TASKSLIST_OBJECT || props.object === constants.NOTE_TYPES_OBJECT || props.object === constants.APPOINTMENT_TYPES_OBJECT || props.object === constants.CAMPAIGN_TYPES_OBJECT
                                || props.object === constants.RELATIONS_OBJECT) {
                                msg = isValidParam(selectedRowTitle) ? "'" + selectedRowTitle + "' " + msg : selectedIds.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORD + msg;
                            } else {
                                msg = selectedIds.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORD + msg;
                            }
                        } else if (selectedIds.length > 1) {
                            if ((objectName === constants.ACCOUNTS_OBJECT || objectName === constants.UNITS_OBJECT) && isRelationshipTabExist == false) {
                                let tempmsg1 = `You have selected ${selectedIds.length} of selected record(s).`
                                let tempmsg2 = "If there is any unit Account record among the records you have selected those cannot be deleted. ";
                                let tempmsg3 = 'Rest of the record(s) will be deleted. Are you sure?'
                                msg = '<span>' + tempmsg1 + '</span><br>';
                                msg += '<span>' + tempmsg2 + '</span><br>';
                                msg += '<span>' + tempmsg3 + '</span>';
                            }else if (objectName === constants.ACCOUNTS_OBJECT  && isRelationshipTabExist ) {

                                msg = selectedIds.length + ' ' + "records will be deleted. Are you sure?";

                            } else {
                                msg = selectedIds.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORDS + msg;
                            }
                        }


                        if (props.object === constants.ROYALTY_RUN_OBJECT) {
                            let records = props.listView.data.records.filter(function (el) {
                                return el.id === selectedIds[0];
                            });
                            if (records[0].t_status === "Processing") {
                                isRoyaltyRunDelete = true;
                            }
                        }
                        if (objectName === constants.GROUP_OBJECT) {
                            let objParam = {};
                            objParam.recordIds = selectedIds;
                            promise = groupUsedInCampaigns(objParam);
                            let groupAlert = "";

                            if (promise !== null) {
                                promise.then((response) => {

                                    if (response !== null && response !== '') {
                                        groupAlert = processGroupData(response);
                                        let dialogData = {};
                                        dialogData.message = groupAlert;
                                        dispatch(getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.list_VIEW));
                                    } else {
                                        sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => triggerActionONConfirmedGroupUsedInCampaigns(selectedIds, objectName), null);
                                    }
                                });

                            }
                        } else if (objectName === constants.WEB_FORMS) {
                            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => triggerActionONConfirmedWebFrom(selectedIds), null);
                        } else if (group_parent_object !== null && group_parent_object === constants.GROUP_OBJECT) {
                            isOpenDrawer = true;
                        } else if ((props.object === constants.NOTE_TYPES_OBJECT || props.object === constants.APPOINTMENT_TYPES_OBJECT || props.object === constants.CAMPAIGN_TYPES_OBJECT) && selectedIds.length === 1) {
                            deleteTypes(selectedIds, selectedRowTitle);
                        } else if (props.object === constants.RELATIONS_OBJECT) {
                            deleteRelations(selectedIds, selectedRowTitle);
                        } else if (props.object === constants.EMAIL_INTEGRATION_OBJECT) {
                            deleteEmail(selectedIds);
                        } else if (props.object === constants.WORKFLOW_OBJECT) {
                            deleteWorkflow(selectedIds);
                        } else if (props.object === constants.AUDIT_QUESTIONS_OBJECT) {
                            deleteAuditQuestion(selectedIds);
                        } else if (props.object === constants.ATTACHMENT_OBJECT) {
                            checkAttachmentBeforeDelete(selectedIds, group_parent_object, group_parent_recordId);
                        } else if (props.object === constants.TEMPLATE_CATEGORIES) {
                            deleteTemplateCategories(selectedIds);
                        } else if (!isRoyaltyRunDelete && props.object === constants.ROYALTY_RUN_OBJECT) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You can not delete already submitted Royalty Run records.', null)
                        } else { 
                            let dataObj = {};
                            dataObj.object = objectName;
                            dataObj.parent_object = group_parent_object;
                            dataObj.parent_record_id = group_parent_recordId;
                            dataObj.id = selectedIds;
                            dataObj.isGroup = false;
                            if (group_parent_object === constants.OPPORTUNITIES_OBJECT && objectName === constants.PRODUCTS_OBJECT) {
                                let selectedProductNames = selectedRows.map(row => {
                                    return row.title;
                                });
                                dataObj.selectedProductNames = selectedProductNames;
                            }
                            labelName = getLocalizedStrings().label.GROUP.DELETE_RECORD;
                            if (props.isDetailView !== undefined &&
                                props.isDetailView === true &&
                                objectName !== constants.ATTACHMENT_OBJECT &&
                                objectName !== constants.ACCOUNTS_OBJECT &&
                                objectName !== constants.TIME_OBJECT &&
                                objectName !== constants.REFUND_OBJECT) {
                                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, (isConfirmed) => { 
                                    const promise = deleteAction(dataObj.object, { id: dataObj.id, parent_object: dataObj.parent_object, parent_record_id: dataObj.parent_record_id });
                                    promise.then((response) => {
                                        if (response.status === 0) {
                                            dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                            dispatch(getAppDrawer(false, null, null, null, null));
                                            if (1 === 1) {
                                                dispatch(refreshDetailViewListViewData(true, data.parent_record_id));
                                            }
                                            let _info = getActiveTabInfo();
                                            if (_info.selectedPageNo > 1) {
                                                _info.selectedPageNo = 1;
                                                updateTabInfo(_info);
                                            }
                                        }
                                    });
                                }, () => console.log(`deny`))
                            } else {
                                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => triggerActionONConfirmedOtherObject(selectedIds, objectName, selectedRows), null);
                            }
                        }
                    } else {
                        if (objectName === constants.LMS_USERS_OBJECT) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.SELECT_RECORD_FOR_DELETE_ONE_RECORD, null);
                        } else if (objectName === constants.ACCOUNTS_OBJECT && constants.UNITS_OBJECT) {
                            sfDialogs.alert(
                                getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                                "Sorry, you do not have the permission to perform delete operation on a Unit Account record. Please contact Brandwide Support to delete the Unit Account record.",
                                null
                            );
                        } else {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
                        }
                    }
                }
                else if (value === constants.UNLINK) {
                    let msg = ' will be unlinked. Are you sure?';
                    if (selectedIds.length === 1) {
                        msg = selectedIds.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORD + msg;
                    } else if (selectedIds.length > 1) {
                        msg = selectedIds.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORDS + msg;
                    }
                    sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => triggerActionONConfirmedOtherObject(selectedIds, objectName, selectedRows), null);

                }
                else if (value === constants.INACTIVE) {
                    if (value === constants.INACTIVE && props.object === constants.WORKFLOW_OBJECT) {
                        changeWorkflowStatus(selectedIds, value);
                    } else {
                        let hasPermission = false;
                        let checkValue = null;
                        if (selectedIds.length === 1) {
                            if (objectName === constants.SALES_TEMPLATE_OBJECT || objectName === constants.MARKETING_TEMPLATE_OBJECT
                                || objectName === constants.SMS_TEMPLATE_OBJECT || objectName === constants.CALL_SCRIPT_OBJECT || objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                                checkValue = constants.PERMISSION_NAME_MANAGE_TEMPLATES;
                            }
                            hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_PERMISSION, checkValue);
                            if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT && hasPermission) {
                                if (reduxApp.me.t_name !== 'Admin') {
                                    hasPermission = false;
                                }
                            }
                        }
                        if (hasPermission) {
                            if (selectedIds.length === 1) {
                                let params = { id: selectedIds };
                                let response = inActiveAction(objectName, params);
                                response.then((response) => {
                                    if (response.status === 0) {
                                        if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT || objectName === constants.SALES_TEMPLATE_OBJECT || objectName === constants.MARKETING_TEMPLATE_OBJECT) {
                                            updateEmailTemplateStatus(selectedRows, objectName, constants.INACTIVE);
                                        }
                                        dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.INACTIVE_SUCCESSFULL, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                        refreshListView(stateObject);
                                    } else if (response.status === -1 && response.error.message !== "") {
                                        dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_INACTIVE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                    }
                                    refreshListView(stateObject);
                                });
                            } else if (selectedIds.length > 1) {
                                isValid = false;
                                alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_COPY_ONE_RECORD;
                            }
                        } else {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
                        }
                    }
                } else if (value === constants.ACTIVE || value === constants.DISABLE) {
                    if (value === constants.ACTIVE && props.object === constants.WORKFLOW_OBJECT) {
                        changeWorkflowStatus(selectedIds, value);
                    } else if ((value === constants.ACTIVE || value === constants.DISABLE) && props.object === constants.WEBHOOKS_OBJECT) {
                        changeWebHookStatus(selectedIds, value);
                    } else {
                        let hasPermission = false;
                        let checkValue = null;
                        if (selectedIds.length === 1) {
                            if (objectName === constants.SALES_TEMPLATE_OBJECT || objectName === constants.MARKETING_TEMPLATE_OBJECT
                                || objectName === constants.SMS_TEMPLATE_OBJECT || objectName === constants.CALL_SCRIPT_OBJECT || objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                                checkValue = constants.PERMISSION_NAME_MANAGE_TEMPLATES;
                            }
                            hasPermission = hasAccessPermission(objectName, constants.SECURITY_LEVEL_TYPE_PERMISSION, checkValue);
                            if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT && hasPermission) {
                                if (reduxApp.me.t_name !== 'Admin') {
                                    hasPermission = false;
                                }
                            }
                        }
                        if (hasPermission) {
                            if (selectedIds.length === 1) {
                                let params = { id: selectedIds };
                                let response = activeAction(objectName, params);
                                response.then((response) => {
                                    if (response.status === 0) {
                                        if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT || objectName === constants.SALES_TEMPLATE_OBJECT || objectName === constants.MARKETING_TEMPLATE_OBJECT) {
                                            updateEmailTemplateStatus(selectedRows, objectName, constants.ACTIVE);
                                        }
                                        dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION.ACTIVE_SUCCESSFULL, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                        refreshListView(stateObject);
                                    } else if (response.status === -1 && response.error.message !== "") {
                                        dispatch(showCustomSnackBar(getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_ACTIVE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                                    }
                                });
                            } else if (selectedIds.length > 1) {
                                isValid = false;
                                alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_COPY_ONE_RECORD;
                            }
                        } else {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
                        }
                    }
                } else if (value === constants.GENERATE_INVOICE) {
                    let info = {
                        id: recordId,
                        title: getLocalizedStrings().label.COMMON['Generate Invoice'],
                        type: value,
                        object: props.object
                    }
                    if (props.isDetailView) {
                        info = {
                            ...info,
                            parentRecordId: getIntParam(reduxDetailView.data.record.id),
                            parentObject: getStringParam(props.detailViewParams.parent_object)
                        }
                    }
                    addNewFormTabs(info);
                } else if (value === constants.ADD_PAYMENT) {
                    let data = { object: constants.PAYMENTS_OBJECT, recordId: 0 };
                    if (props.isDetailView) {
                        data = {
                            ...data,
                            isDetailView: true,
                            parent_record_id: getIntParam(reduxDetailView.data.record.id),
                            parent_object: getStringParam(props.detailViewParams.parent_object)
                        }
                    }
                    dispatch(getAppDrawer(true, 'Add New Payment', constants.NEW, data, null));
                } else if (value === constants.PUBLISH) {
                    let params = { id: selectedIds };
                    let response = publishWebForm(objectName, params);
                    response.then((response) => {
                        let message = "";
                        if (response.status === 0) {
                            if (objectName === constants.LANDING_PAGES_FROALA_OBJECT) {
                                message = getLocalizedStrings().message.BATCH_ACTION.PUBLISH_SUCCESSFULL;
                            } else {
                                message = getLocalizedStrings().message.BATCH_ACTION.ACTIVE_SUCCESSFULL;
                            }
                            dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            refreshListView(stateObject);
                        } else if (response.status === -1 && response.error.message !== "") {
                            if (objectName === constants.LANDING_PAGES_FROALA_OBJECT) {
                                message = getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_PUBLISH;
                            } else {
                                message = getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_ACTIVE;
                            }
                            dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                    });
                } else if (value === constants.UNPUBLISH) {
                    let params = { id: selectedIds };
                    let response = unPublishWebForm(objectName, params);
                    response.then((response) => {
                        let message = "";
                        if (response.status === 0) {
                            if (objectName === constants.LANDING_PAGES_FROALA_OBJECT) {
                                message = getLocalizedStrings().message.BATCH_ACTION.UNPUBLISH_SUCCESSFULL;
                            } else {
                                message = getLocalizedStrings().message.BATCH_ACTION.INACTIVE_SUCCESSFULL;
                            }
                            dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            refreshListView(stateObject);
                        } else if (response.status === -1 && response.error.message !== "") {
                            if (objectName === constants.LANDING_PAGES_FROALA_OBJECT) {
                                message = getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_UNPUBLISH;
                            } else {
                                message = getLocalizedStrings().message.BATCH_ACTION_VALIDATION.ALREADY_INACTIVE;
                            }
                            dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                    });
                } else if ((value === constants.CLOSE || value === constants.CANCEL) && objectName === constants.JOBS_OBJECT) {
                    let statusData = value === constants.CLOSE ? 'Done' : 'Cancelled';
                    let params = { ids: selectedIds, fields: [{ name: 't_status', value: statusData }] };
                    let promise = updateFields(objectName, params);
                    if (isValidParam(promise)) {
                        promise.then((response) => {
                            if (response.status === 0) {
                                let message = getLocalizedStrings().message.WORKFLOWS.STATUS_CHANGED + statusData;
                                dispatch(showCustomSnackBar(message, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                                refreshListView(stateObject);
                            }
                        });
                    }
                } else if (value === constants.SEND_EMAIL && objectName !== constants.ATTACHMENT_OBJECT) {
                    let minimizeList = reduxCustomDrawer.minimizeList;
                    if (!reduxAppContainer.customDrawerProps.isOpen && !reduxAppContainer.customDrawerProps.isMinimize) {
                        sendEmail(selectedIds, null, null);
                    } else {
                        if ((minimizeList.hasOwnProperty('email') && minimizeList.email)) {
                            dispatch(showCustomSnackBar("Send mail popup is already opened for different record", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        }
                        else if (reduxAppContainer.customDrawerProps.minimizeList.Convert) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Convert drawer is already opened.', null)

                        }
                        else if (reduxAppContainer.customDrawerProps.minimizeList.new && reduxAppContainer.customDrawerProps.minimizeList?.agreement == true) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Agreement drawer is already opened.', null)

                        }
                        else if (reduxAppContainer.customDrawerProps.minimizeList.new) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Note drawer is already opened.', null)

                        }
                        else if (value === constants.SEND_EMAIL && minimizeList.email === undefined && (minimizeList?.agreement == true || reduxAppContainer.customDrawerProps?.data?.object == "agreement-templates")) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Agreement drawer is already opened.", null);

                        }
                        else if (value === constants.SEND_EMAIL && minimizeList.email === undefined) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Please select a record to Send Email.", null);

                        }
                        else {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Add note drawer is already opened.', null)
                        }

                    }
                } else if (value === constants.SEND_EMAIL && objectName === constants.ATTACHMENT_OBJECT) {
                    if (selectedIds.length === 1) {
                        let promise = getAttachmentSignStatus({ attchment_id: recordId });
                        promise.then((response) => {
                            if (isValidParam(response)) {
                                let sign_status = getStringParam(response.signature_status);
                                let attachment_type = getStringParam(response.attachment_type);
                                let isExpired = getBooleanParam(response.is_expired);
                                let expirationDate = getStringParam(response.expiration_date);
                                if (isExpired) {
                                    let _msg = getLocalizedStrings().message.AGREEMENT_TEMPLATE.AGREEMENT_EXPIRED_1 + expirationDate + ". " + getLocalizedStrings().message.AGREEMENT_TEMPLATE.AGREEMENT_EXPIRED_2
                                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, _msg, null);
                                    return;
                                } else {
                                    sendEmail(selectedIds, attachment_type, sign_status);
                                }
                            }
                        })

                    } else {
                        alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_ATTACH_ONE_RECORD;
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null);
                    }
                } else if (value === constants.CALL && selectedIds.length > 0) {
                    if (isCallingSupport) {
                        if (reduxApp.me.phone_setup.is_twilio) {
                            if (selectedIds.length === 1) {
                                reduxPowerCalling.callDrawerOpend = true;
                                makeCall(selectedIds);
                            } else {
                                powerCallingDataProcess();
                                getPowerCallingDataFn(selectedIds);
                            }
                        } else {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.NOT_CONFG_CALL_SETUP, null);
                        }

                    } else {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.TWILIO.CALLING_SUPPORT, null);
                    }

                } else if (value === constants.DOWNLOAD && objectName === constants.ATTACHMENT_OBJECT) {

                    let selectedRecords = filter(props.listView.data.records, (item, index) => {
                        return (item.id === recordId)
                    });

                    let documentName = selectedRecords[0].document_name;
                    let params = { id: recordId, isUnitsAccount: isUnitsAccount, UnitsAccountProjectId: UnitsAccountProjectId };
                    if (reduxApp.selectedModuleName === constants.MODULE_HUB && getBooleanParam(props.isDetailView)) {
                        params.call_from = constants.MODULE_HUB;
                    }


                    let param = { attchment_id: recordId, isUnitsAccount: isUnitsAccount, UnitsAccountProjectId: UnitsAccountProjectId }
                    let promise = getAttachmentSignStatus(param);
                    promise.then((response) => {
                        let formData;
                        if (isValidParam(response)) {
                            let sign_status = getStringParam(response.signature_status);
                            let attachment_type = getStringParam(response.attachment_type);
                            if (attachment_type === 'Agreement') {
                                formData = new FormData();
                                formData.append('input_param', JSON.stringify(params));
                                if (sign_status !== null && sign_status == 'Sent') {
                                    HTTPClient.postFormDataDownloadFile(endPoints.ATTACHMENT.DOWNLOAD_ATTACHMENT, formData, documentName);
                                } else {
                                    HTTPClient.postFormDataDownloadFile(endPoints.ATTACHMENT.DOWNLOAD_ATTACHMENT, formData, documentName + ".pdf");

                                }
                                return;
                            } else {
                                formData = new FormData();
                                formData.append('input_param', JSON.stringify(params));
                                HTTPClient.postFormDataDownloadFile(endPoints.ATTACHMENT.DOWNLOAD_ATTACHMENT, formData, documentName);
                                return;
                            }

                        }
                    })

                } else if (value === constants.VIEW && objectName === constants.ATTACHMENT_OBJECT) {
                    let promise = getAttachmentSignStatus({ attchment_id: recordId, isUnitsAccount: isUnitsAccount, UnitsAccountProjectId: UnitsAccountProjectId });
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            let sign_status = getStringParam(response.signature_status);
                            let attachment_type = getStringParam(response.attachment_type);
                            let t_type = getStringParam(response.t_type);
                            let doc_name = getStringParam(response.document_name);

                            if (t_type === 'pdf' && doc_name.indexOf('.pdf') > -1) {
                                let params = { 'id': recordId, isUnitsAccount: isUnitsAccount, UnitsAccountProjectId: UnitsAccountProjectId };
                                const promise = Promise.resolve(HTTPClient.post(endPoints.ATTACHMENT.GET_ATTACHMENT_URL, params));
                                promise.then((response) => {
                                    window.open(response.attachmentUrl);
                                });
                            } else {

                                if (attachment_type === 'Agreement' && t_type === 'draft') {
                                    let data = {};
                                    data.id = recordId;
                                    let label = getLocalizedStrings().label.COMMON.EDIT + " " + doc_name;
                                    data.mode = 'edit';
                                    data.object = props.listView.data.parent_object;
                                    ;
                                    let type = constants.AGREEMENT_OBJECT;
                                    dispatch(getAppCustomDrawer(true, label, type, { width: '83%' }, data, null, true));
                                } else {
                                    let params = { 'id': recordId, isUnitsAccount: isUnitsAccount, UnitsAccountProjectId: UnitsAccountProjectId }; const formData = new FormData();
                                    formData.append('input_param', JSON.stringify(params));
                                    HTTPClient.postFormDataDownloadFile(endPoints.ATTACHMENT.DOWNLOAD_ATTACHMENT, formData, doc_name);
                                    return;
                                }
                            }
                        }
                    });

                } else if (objectName === constants.CONTACTS_OBJECT && value === constants.UNSUBSCRIBE) {
                    data = {
                        recordIds: selectedIds,
                        isDetailView: false
                    }
                    dispatch(getAppDrawer(true, getLocalizedStrings().label.COMMON.UNSUBSCRIBE, value, data, constants.LIST_VIEW));

                } else if (objectName === constants.CONTACTS_OBJECT && value === constants.SEND_DOCUSIGN && ((props.detailViewParams.parent_object === constants.OPPORTUNITIES_OBJECT) || (props.detailViewParams.parent_object === constants.ACCOUNTS_OBJECT))) {
                    isOpenDrawer = true;
                } else if (value === constants.RESTORE) {
                    restoreLeadAccounts(selectedIds, objectName)
                } else if ((value === constants.SEND_SMS || value === constants.SEND_TEXT) && objectName !== constants.ATTACHMENT_OBJECT) {
                    processBatchSMS(selectedIds, objectName);
                } else if (value === constants.ADD_BATCH_TASK) {
                    processAddTask(selectedIds, objectName);
                } else if ((value === constants.SEND_SMS || value === constants.SEND_TEXT) && objectName === constants.ATTACHMENT_OBJECT) {
                    if (selectedIds.length === 1) {
                        let promise = getAttachmentSignStatus({ attchment_id: recordId });
                        promise.then((response) => {
                            if (isValidParam(response)) {
                                let sign_status = getStringParam(response.signature_status);
                                let attachment_type = getStringParam(response.attachment_type);
                                if (attachment_type === 'Agreement' && (sign_status === 'Signed' || sign_status === 'Sent')) {
                                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AGREEMENT_TEMPLATE.SENT_OR_SIGNED, null);
                                    return;
                                } else {
                                    let parentObject = getStringParam(props.detailViewParams.parent_object);
                                    const recordIds = [];
                                    recordIds[0] = getIntParam(reduxDetailViewRecord.id);
                                    processBatchSMS(recordIds, parentObject, recordId);
                                }
                            }
                        })


                    } else {
                        alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_ATTACH_ONE_RECORD;
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null);
                    }
                } else if (value === constants.LOGIN) {
                    login(_myClientId);
                } else if (value === constants.PLAN_NAME) {
                    data = {
                        recordId: selectedIds[0],
                        unitName: selectedRowTitle,
                    }

                    dispatch(getAppDrawer(true, getLocalizedStrings().label.COMMON.PLAN_NAME, value, data, null));
                } else if (value === ROYALTY_RUN_ACTIONS.REPORT_SALES) {
                    let _isDetailView = getBooleanParam(props.isDetailView);
                    dispatch(setMounted(false, _isDetailView));
                    let id = getIntParam(reduxDetailView.data.record.id);
                    reportSales(id, selectedIds);
                } else if (value === ROYALTY_RUN_ACTIONS.PROCESS) {
                    let _isDetailView = getBooleanParam(props.isDetailView);
                    dispatch(setMounted(false, _isDetailView));
                    let id = getIntParam(reduxDetailView.data.record.id);
                    royaltyRunProcess(id, selectedIds);
                } else if (value === REPORTED_SALES_ACTIONS.UPDATE) {
                    reportedSalesUpdate(selectedIds);
                } else if (props.object === constants.EMAIL_INTEGRATION_OBJECT && value === constants.VERIFY_EMAIL) {
                    if (selectedIds.length === 1) {
                        sendVerificationEmail(selectedRows);
                    } else {
                        alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_COPY_ONE_RECORD;
                    }
                } else if (value === constants.ADD_QUICKBOOKS && props.object === constants.PAYMENTS_OBJECT) {
                    addToQuickBooks(selectedIds);
                } else if ((value === 'Refund') && (props.object === constants.PAYMENTS_OBJECT)) {
                    let data = { object: constants.PAYMENTS_OBJECT, recordId: 0 };
                    if (props.isDetailView) {
                        data = {
                            ...data,
                            isDetailView: true,
                            parent_record_id: getIntParam(reduxDetailView.data.record.id),
                            parent_object: getStringParam(props.detailViewParams.parent_object),
                            paymentType: 'Refund',
                            parentPaymentId: selectedIds[0]
                        }
                    }
                    dispatch(getAppDrawer(true, 'Refund', constants.NEW, data, null));
                } else if (value === constants.ADD_XERO && props.object === constants.PAYMENTS_OBJECT) {
                    addToXero(selectedIds);
                } else if (value === constants.PUSH_TO_QUICKBOOKS) {
                    pushToQuickBooks(selectedIds);
                } else if (value === constants.INVOICE) {
                    processInvoice(selectedIds);
                } else if (value === constants.COLLECT) {
                    processMarkPaid(selectedIds);
                } else if (value === constants.EMAIL_INVOICE) {
                    processEmail(selectedIds);
                } else if (value === constants.POST) {
                    processPost(selectedIds);
                } else if (value === constants.EXCEPTION) {
                    if (selectedIds.length === 1) {
                        let data = {
                            id: 0,
                            object: constants.EXCEPTION,
                        }
                        let style = {};
                        style.width = '65%';
                        data.myClientId = _myClientId;
                        let labelName = constants.EXCEPTION;
                        dispatch(getAppDrawer(true, labelName, value, data, null));
                    }
                }

            } else if (value === 'Enter Sales') {
                if (childListView.data?.records.length == 0) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "No Reported Sales found.", null);

                } else if (selectedIds.length > 0) {
                    onCancelReportedSalesUpdate(selectedIds);
                } else {
                    onCancelReportedSalesUpdate();
                }
            }
            else if (value === constants.EMPTY && props.object === constants.RECYCLE_BIN_OBJECT) {
                emptyRecycleBin();
            } else {
                isValid = false;
                if (value !== '' && value === constants.EDIT) {
                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_EDIT;
                } else if (value !== '' && value === constants.COPY) {
                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_COPY;
                } else if (value !== '' && value === constants.SEND_EMAIL) {
                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_SEND_EMAIL;
                } else if (value !== '' && value === constants.SEND_SMS) {
                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_SEND_SMS;
                } else if (value !== '' && value === constants.ADD_BATCH_TASK) {
                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_ADD_TASK;
                }
                else if (value !== '' && value === constants.CALL) {
                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_CALL;
                } else if (value !== '' && value === constants.DOWNLOAD) {
                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_DOWNLOAD;
                } else if (value !== '' && value === constants.DELETE) {
                    alertDialogMessage = getLocalizedStrings().message.COMMON.SELECT_RECORD_FOR_DELETE;
                } else if (value !== '' && value === constants.TO_EXCEL) {
                    alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.SELECT_RECORD_FOR_To_EXCEL;
                }
            }
            if (isValid) {
                if (props.isDetailView !== undefined && props.isDetailView === true && objectName !== constants.AUDIT_ANSWERS_OBJECT) {
                    if (value === constants.NEW) {
                        data = props.detailViewParams;
                        data.isDetailView = true;
                        data.object = objectName;
                    } else if (value === constants.ADD_NOTES) {
                        data = data === null ? {} : data;
                        data.isDetailView = true;
                        data.object = data.object !== undefined ? data.object : objectName;
                    } else if (value === constants.CONVERT) {
                        data = data === null ? {} : data;
                        data.isDetailView = true;
                        data.object = data.object !== undefined ? data.object : objectName;
                        data.selectedRecords = selectedRows;
                    }
                    else if (objectName === constants.REPORTED_SALES_OBJECT && props.isDetailView == true && value == constants.TO_EXCEL || value == constants.EDIT || value == constants.COLLECT) {
                        data = data === null ? {} : data;
                        data.isDetailView = true;
                        data.object = data.object !== undefined ? data.object : objectName;
                        data.selectedRecords = selectedRows;
                        if (isChildObjectCopy && value === constants.COPY) {
                            data = { ...props.detailViewParams, ...data }
                        }
                        if (objectName === constants.APPOINTMENTS || objectName === constants.TASKS) {
                            data = { ...props.detailViewParams, ...data }
                        }
                    }
                    else {
                        if (linkChildTableId === 0 && objectName !== constants.TASKS) {
                            data = data === null ? {} : data;
                            data.id = recordId;
                            let obj = {};
                            obj.title = selectedRowTitle;
                            data.object = obj;
                            data.mode = "Edit";
                            labelName = "Edit Relationship"
                            value = LinkToType.TYPE_RELATIONSHIP;

                        } else {
                            data = data === null ? {} : data;
                            data.isDetailView = true;
                            data.object = data.object !== undefined ? data.object : objectName;
                            data.selectedRecords = selectedRows;
                            if (isChildObjectCopy && value === constants.COPY) {
                                data = { ...props.detailViewParams, ...data }
                            }
                            if (objectName === constants.APPOINTMENTS || objectName === constants.TASKS) {
                                data = { ...props.detailViewParams, ...data }
                            }

                        }
                    }

                } else {
                    if (objectName === constants.APPOINTMENTS || objectName === constants.TASKS) {
                        data = data === null ? {} : data;
                        data.isDetailView = false;
                        data.object = data.object !== undefined ? data.object : objectName;
                        data.selectedRecords = selectedRows;
                    }

                    if (objectName === constants.ASSIGNMENT_RULES) {
                        data = data === null ? {} : data;
                        data.mode = LinkToType.TYPE_EDIT;
                    }
                }
                let isOnboardingWF = false;
                if (objectName === constants.WORKFLOW_OBJECT) {
                    let totalRecords = props.listView.data.records;
                    let selectedRows = props.listView.selectedRows;
                    let selectedIds = selectedRows.map(row => row.id);
                    let matchedRecords = getMatchingData(totalRecords, selectedIds);
                    isOnboardingWF = matchedRecords.some(f => f.t_name === "Onboarding" || f.t_name === "Franchisee" || f.t_workflow_type === "Onboarding");
                }

                if (value === constants.PUSH && selectedRows.length > 20) {
                    isOpenDrawer = false;
                    if (objectName.includes('-')) {
                        let objectNameArr = objectName.split("-");
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_4 + " " + objectNameArr[0] + " " + objectNameArr[1] + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_4_1, null);
                    } else {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_4 + " " + objectName + " " + getLocalizedStrings().message.PUSH.PUSH_SUCCESS_MSG_4_1, null);
                    }
                } else if (value === constants.PUSH && selectedRows.length === 1 && group_by_condition === 'Appointments') {
                    isOpenDrawer = false;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You cannot push this workflow.', null);
                } else if (value === constants.PUSH && isOnboardingWF) {
                    isOpenDrawer = false;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You cannot push Onboarding, Franchisee or Onboarding Type workflows.', null);
                } else if (
                    value === "Invoice Recipients" && selectedRows.length === 1
                ) {
                    let dataValue = {
                        object: constants.INVOICE_RECIPIENTS,
                        myClientId: selectedRows.myclient_id
                    };
                    dispatch(getAppDialog(true, constants.INVOICE_RECIPIENTS, " Invoice Recipients", null, dataValue));

                }
                else if (
                    value === "Share with" && selectedRows.length > 0
                ) {
                    let selectedRows = props.listView.selectedRows;
                    selectedRows = isValidParam(selectedRows) ? selectedRows : [];
                    let selectedIds = selectedRows.map(row => row.id);
                    let msg = `${selectedRows.length} record(s) will be shared with units. Are you sure ?`
                    let param = {}
                    param.call_object = "units"
                    const response = Promise.resolve(HTTPClient.get(endPoints.ACCOUNTS.LIST_VIEW, param));
                    response.then((response) => {

                        if (response?.total_count == 0) {
                            let alertMsg = 'There is no units available to perform this action.';
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);

                        } else {
                            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => shareWithUnitsAction(selectedIds), null);
                        }

                    });


                }
                if (objectName === constants.DOWNLOAD_LEADS_OBJECT) {
                    let dialogData = {};
                    dialogData.selectedIds = selectedIds;
                    dispatch(getAppDialog(true, constants.LEADSTORE_DOWNLOAD_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.list_VIEW));
                }

                if (isOpenDrawer) {
                    value = value === constants.ADD_NOTES ? constants.NEW : value;
                    if (value === constants.CONVERT && selectedIds.length > 0) {
                        let minimizeList = reduxCustomDrawer.minimizeList;
                        let selectedRows = props.listView.selectedRows;
                        selectedRows = isValidParam(selectedRows) ? selectedRows : [];
                        let isUnitAccountSelected = false;
                        if (objectName === constants.ACCOUNTS_OBJECT && selectedIds.length > 1) {
                            map(selectedRows, function (rec, index) {
                                if (rec.hasOwnProperty("myClientId") && rec?.myClientId > 0) {
                                    isUnitAccountSelected = true;
                                }

                            });
                        }
                        if (isUnitAccountSelected == false) {
                            if (reduxCustomDrawer.isOpen === false) {
                                reduxCustomDrawer.minimizeList.Convert = false;
                            }
                            if (!minimizeList.hasOwnProperty(constants.CONVERT) || (minimizeList.hasOwnProperty(constants.CONVERT) && !minimizeList.Convert)) {
                                let convertStyle = {};
                                convertStyle.width = '40%';
                                data.object = objectName;
                                let response = checkRecordWFId(objectName, selectedIds);
                                response.then((response) => {
                                    if (response) {
                                        let msg = getLocalizedStrings().message.BATCH_ACTION.RECORDS_WITH_DIFFERENT_WORKFLOW;
                                        sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => triggerActionONConfirmedRecordsWithDiffWrkFlow(labelName, convertStyle, value, data), null);
                                    } else {
                                        response = checkRecordStatus(objectName, selectedIds);
                                        response.then((response) => {
                                            if (response) {
                                                let msg = getLocalizedStrings().message.BATCH_ACTION.RECORDS_WITH_DIFFERENT_WORKFLOW_STATUS;
                                                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => triggerActionONConfirmedRecordsWithDiffWrkFlowStatus(labelName, convertStyle, value, data), null);
                                            } else {
                                                dispatch(getAppCustomDrawer(true, labelName, value, convertStyle, data, null, true, constants.LIST_VIEW));
                                            }
                                        });
                                    }
                                });
                            } else {
                                dispatch(showCustomSnackBar(getLocalizedStrings().message.ACCOUNTS.ALERT_MULTI_DRAWER_OPEN, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        } else {
                            let alertMsg = 'You cannot do batch convert for unit accounts.';
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMsg, null);

                        }

                    } else if (objectName === constants.CONTACTS_OBJECT && value === constants.CONVERT_TO_ACCOUNTS) {
                        let hasPermission = false;
                        hasPermission = hasAccessPermission(constants.ACCOUNTS_OBJECT, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_CREATE);
                        if (hasPermission) {
                            if (selectedIds.length > 1) {
                                data = {
                                    object: constants.ACCOUNTS_OBJECT,
                                    parent_object: objectName,
                                    selectedRecords: selectedIds,
                                    is_convert: true,
                                    mode: constants.SF_FORM_OPEN_MODE_QUICK
                                };
                                dispatch(getAppDrawer(true, labelName, value, data, constants.LIST_VIEW));
                            } else {
                                let params = {};
                                params.is_linked_contact = true;
                                params.parent_object = 'accounts';
                                params.object = 'contacts';
                                params.record_id = selectedIds[0];
                                let promise = Promise.resolve(isLinkedRecord(params));
                                if (isValidParam(promise)) {
                                    promise.then((response) => {
                                        if (isValidParam(response) && response.is_linked) {
                                            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.CONVERT_CONTACT_TO_ACCOUNT_CONFIRM, () => triggerActionONConfirmedConvertContToAcc(response), null);

                                        } else {
                                            data = {
                                                object: constants.ACCOUNTS_OBJECT,
                                                parent_object: objectName,
                                                parent_record_id: selectedIds[0],
                                                is_convert: true,
                                                mode: constants.SF_FORM_OPEN_MODE_QUICK
                                            };
                                            dispatch(getAppDrawer(true, labelName, value, data, constants.LIST_VIEW));
                                        }
                                    });
                                }
                            }
                        } else {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
                        }
                    } else if (objectName === constants.CONTACTS_OBJECT && value === constants.SEND_DOCUSIGN && ((props.detailViewParams.parent_object === constants.OPPORTUNITIES_OBJECT) || (props.detailViewParams.parent_object === constants.ACCOUNTS_OBJECT))) {
                        //if (reduxApp.me.is_free_crm === false) {
                        labelName = getLocalizedStrings().label.COMMON.DOCUSIGN_INTEGRATION;
                        const recordIds = [];
                        recordIds[0] = reduxDetailViewRecord.id;
                        data = {
                            parent_object: props.detailViewParams.parent_object,
                            parent_record_id: recordIds,
                            childId: selectedIds
                        }
                        dispatch(getAppDrawer(true, labelName, constants.DOCUSIGN, data, constants.DETAIL_VIEW));
                        setOpenActionList(false);
                    } else if (objectName === constants.APPOINTMENTS && value === constants.COMPLETE) {
                        hasPermission = hasAccessPermission(constants.APPOINTMENTS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
                        if (hasPermission) {
                            if (selectedIds.length === 1) {
                                let promise = getRecordPromise(objectName, selectedIds[0]);
                                if (isValidParam(promise)) {
                                    promise.then((response) => {
                                        if (isValidParam(response)) {
                                            if (isValidParam(response.records)) {
                                                labelName = "Complete this Appointment";
                                                dispatch(getAppDrawer(true, labelName, value, data, constants.LIST_VIEW));
                                            }
                                        }
                                    });
                                }
                            } else {
                                labelName = getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE + " " + getLocalizedStrings().label.COMMON.THESE + " " + getLocalizedStrings().label.APPOINTMENTS_TASKS.APPOINTMENTS;
                                dispatch(getAppDrawer(true, labelName, value, data, constants.LIST_VIEW));
                            }
                        } else {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
                        }
                    } else if (objectName === constants.TASKS && value === constants.COMPLETE) {
                        hasPermission = hasAccessPermission(constants.TASKS, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
                        if (hasPermission) {
                            if (selectedIds.length > 1) {
                                let promise = getTasksCompletedPromise(selectedIds);
                                if (isValidParam(promise)) {
                                    promise.then((response) => {
                                        if (isValidParam(response)) {
                                            if ("COMPLETED" === response) {
                                                alertDialogMessage = getLocalizedStrings().label.APPOINTMENTS_TASKS.ALL_TASK_COMPLETED;
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null)
                                            } else if ("NOT-COMPLETED" === response) {
                                                labelName = getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE + " " + getLocalizedStrings().label.COMMON.THESE + " " + getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;
                                                data.taskType = "Task";
                                                dispatch(getAppDrawer(true, labelName, value, data, constants.LIST_VIEW));
                                            }
                                        }
                                    });
                                }
                            } else {
                                let promise = getTasksCompletedPromise(selectedIds);
                                if (isValidParam(promise)) {
                                    promise.then((response) => {
                                        if (isValidParam(response)) {
                                            if ("COMPLETED" === response) {
                                                alertDialogMessage = getLocalizedStrings().label.APPOINTMENTS_TASKS.ALL_TASK_COMPLETED;
                                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null)
                                            } else if ("NOT-COMPLETED" === response) {
                                                let taskParams = { "object": objectName, "isUnitOwnerTasks": false };
                                                let promise1 = getRecordPromise(objectName, selectedIds[0], taskParams);
                                                if (isValidParam(promise1)) {
                                                    promise1.then((response1) => {
                                                        if (isValidParam(response1)) {
                                                            if (isValidParam(response1.records)) {
                                                                labelName = getLocalizedStrings().label.APPOINTMENTS_TASKS.COMPLETE + " this " + getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
                                                                data.taskType = response1.records.task_type !== null && response1.records.task_type !== undefined && response1.records.task_type !== "" ? response1.records.task_type : "Task";
                                                                dispatch(getAppDrawer(true, labelName, value, data, constants.LIST_VIEW));
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        } else {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null)
                        }
                    } else if (group_parent_object === constants.GROUP_OBJECT && (objectName === constants.ACCOUNTS_OBJECT || objectName === constants.CONTACTS_OBJECT)) {
                        let dataObj = {};
                        dataObj.object = objectName;
                        dataObj.parent_object = group_parent_object;
                        dataObj.parent_record_id = group_parent_recordId;
                        dataObj.id = selectedIds;
                        labelName = getLocalizedStrings().label.GROUP.DELETE_RECORD;
                        if (props.isDetailView !== undefined && props.isDetailView === true) {
                            dispatch(getAppDrawer(true, labelName, value, dataObj, constants.DETAIL_VIEW));
                        } else {
                            dataObj.parent_record_label = group_parent_label;
                            dispatch(getAppDrawer(true, labelName, value, dataObj, constants.LIST_VIEW));
                        }
                    } else if (objectName === constants.PAYMENTS_OBJECT && value === constants.EDIT) {
                        data = data === null ? {} : data;
                        data.mode = constants.SF_FORM_OPEN_MODE_QUICK;
                        let detailViewParams = props.detailViewParams;
                        data.parent_object = detailViewParams.parent_object;
                        data.parent_record_id = detailViewParams.parent_record_id;
                        dispatch(getAppDrawer(true, labelName, value, data, null));
                    } else if (objectName === constants.TIME_OBJECT && value === constants.EDIT) {
                        data = data === null ? {} : data;
                        data.mode = constants.SF_FORM_OPEN_MODE_QUICK;
                        let detailViewParams = props.detailViewParams;
                        data.parent_object = detailViewParams.parent_object;
                        data.parent_record_id = detailViewParams.parent_record_id;
                        dispatch(getAppDrawer(true, labelName, value, data, null));
                    } else if (objectName === constants.ROYALTY_PLANS_OBJECT &&
                        (value === constants.EDIT || value === constants.COPY)) {
                        if (value === constants.EDIT) {
                            isOpenDrawer = false;
                            if (selectedIds.length === 1) {
                                let data = {
                                    id: recordId,
                                    title: selectedRowTitle,
                                    type: value,
                                    object: props.object
                                };
                                dispatch(getAppCustomDrawer(true, labelName, value, { width: '60%' }, data, null, false));
                            } else {
                                isValid = false;
                                alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT;
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_EDIT, null);
                            }
                        } else if (value === constants.COPY) {
                            isOpenDrawer = false;
                            if (selectedIds.length === 1) {
                                let data = {
                                    id: recordId,
                                    title: selectedRowTitle,
                                    type: value,
                                    object: props.object
                                };
                                dispatch(getAppCustomDrawer(true, labelName, value, { width: '60%' }, data, null, false));
                            } else {
                                isValid = false;
                                alertDialogMessage = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_COPY;
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.MULTIPLE_RECORD_FOR_COPY, null);
                            }
                        }
                    } else {
                        if ((props.object === constants.MARKETING_TEMPLATE_OBJECT ||
                            props.object === constants.BROADCAST_CAMPAIGNS_OBJECT) && value !== constants.BATCH_EDIT) {
                            if (value === constants.PUSH && props.object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                                dispatch(getAppDrawer(true, labelName, value, data, constants.LIST_VIEW));
                            } else {
                                let style = {};
                                style.width = '100%';
                                let id = data.id;
                                if (value === constants.TO_EXCEL && props.object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                                    id = id[0];
                                }
                                let params = { "id": id };
                                let url = '';
                                if (props.object === constants.MARKETING_TEMPLATE_OBJECT) {
                                    url = endPoints.MARKETING_TEMPLATE.GET_EDITOR_TYPE;
                                } else if (props.object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                                    url = endPoints.BROADCAST_CAMPAIGNS.GET_EDITOR_TYPE;
                                }
                                promise = Promise.resolve(HTTPClient.get(url, params));
                                promise.then((response) => {
                                    data.type = response;
                                    if (response === constants.EDITOR_NEW || response === constants.EDITOR_BEE) {
                                        if (value === constants.TO_EXCEL && props.object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                                            dispatch(getAppDrawer(true, labelName, value, data, constants.LIST_VIEW));
                                        } else {
                                            dispatch(getAppCustomDrawer(true, labelName, value.toLowerCase(), style, data, null, false));
                                        }
                                    } else if (response === constants.LEGACY && value !== constants.TO_EXCEL && props.object === constants.BROADCAST_CAMPAIGNS_OBJECT) {
                                        let style = {};
                                        style.width = '85%';
                                        dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, false));
                                    } else {
                                        dispatch(getAppDrawer(true, labelName, value, data, constants.LIST_VIEW));
                                    }
                                });

                            }
                        } else {
                            if (value === constants.ASSIGN) {
                                let placeValue = props.listView.data.fields.find((obj) => obj.name === "sales_rep");
                                data = data || {};
                                data.placeHolderValue = placeValue.label;
                            }
                            if (isValidParam(data)) {
                                if (data.object === "note") {
                                    value = "new";
                                    if (!reduxAppContainer.customDrawerProps.isOpen && !reduxAppContainer.customDrawerProps.isMinimize) {
                                        dispatch(getAppCustomDrawer(true, labelName, value, { width: '83%' }, data, null, true));
                                    }
                                    else if (reduxAppContainer.customDrawerProps.minimizeList.Convert) {
                                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Convert drawer is already opened.', null)

                                    }
                                    else {
                                        if (reduxAppContainer.customDrawerProps.minimizeList.email)
                                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Send Email drawer is already opened.', null)
                                    }
                                } else if (data.object === constants.SALES_TEMPLATE_OBJECT) {
                                    let style = {};
                                    style.width = '83%';
                                    dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, false));
                                } else if (data.object === constants.AGREEMENT_TEMPLATE_OBJECT) {
                                    let style = {};
                                    style.width = '83%';
                                    dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, true));
                                } else if (data.object === constants.NEWS_OBJECT) {
                                    let style = {};
                                    style.width = '60%';
                                    dispatch(getAppCustomDrawer(true, labelName, value, style, data, null, false));
                                } else if (data.object === constants.ATTACHMENT_OBJECT && value === constants.EDIT) {
                                    let _recordId = data.id;;
                                    if (_recordId > 0) {
                                        let promise = getAttachmentType({ attchment_id: _recordId });
                                        promise.then((response) => {
                                            if (isValidParam(response)) {
                                                let type = getStringParam(response.type);
                                                let attachment_type = getStringParam(response.attachment_type);
                                                if (attachment_type === 'Agreement' && type === 'draft') {
                                                    data = { ...data, mode: 'edit' }
                                                    dispatch(getAppCustomDrawer(true, 'Edit Agreement', constants.AGREEMENT_OBJECT, { width: '83%' }, data, null, true));
                                                } else {
                                                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AGREEMENT_TEMPLATE.AGREEMENT_EDIT, null);
                                                }
                                            }
                                        });
                                    }
                                } else if (data.object === constants.AUDIT_QUESTIONS_OBJECT) {
                                    if (props.listView.selectedRows.length === 1) {
                                        data = {
                                            ...data,
                                            type: props.listView.selectedRows[0]?.row ? props.listView.selectedRows[0].row.Type : ''
                                        }
                                        dispatch(getAppDrawer(true, labelName, data.object, data, constants.LIST_VIEW));
                                    } else {
                                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().label.AUDIT_QUESTIONS.SINGLE_RECORD_EDIT, null);
                                    }
                                } else if (data.object === constants.COMPLIANCE_OBJECT) {
                                    if (props.listView.selectedRows.length === 1 || props.listView.selectedRows.length > 1) {
                                        data = {
                                            ...data,
                                            type: props.listView.selectedRows[0]?.row ? props.listView.selectedRows[0].row.Type : ''
                                        }
                                        dispatch(getAppDrawer(true, labelName, value, data, constants.LIST_VIEW));
                                    } else {
                                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMPLIANCE.SINGLE_RECORD_EDIT, null);
                                    }
                                } else if (data.object === constants.SECURITY_LEVEL_OBJECT) {
                                    if (props.listView.selectedRows.length === 1) {
                                        let id = props.listView.selectedRows[0].hasOwnProperty("id") ? props.listView.selectedRows[0].id : 0;
                                        let listdata = props.listView.data.records.filter(e => e.id === id)[0];

                                        data = {
                                            ...data,
                                            description: listdata.t_description,
                                            name: listdata.t_name
                                        }
                                        dispatch(getAppDrawer(true, listdata.t_name, value, data, constants.LIST_VIEW));
                                    } else {
                                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().label.AUDIT_QUESTIONS.SINGLE_RECORD_EDIT, null);
                                    }
                                }
                                else if (objectName === constants.ASSIGNMENT_RULES_OBJECT && value == constants.COPY) {
                                    isOpenDrawer = false;
                                    if (selectedIds.length === 1) {
                                        labelName = 'Copy' + ' ' + getLocalizedStrings().label.ASSIGNMENT_RULES.ASSIGNMENT_RULE;
                                        isOpenDrawer = true;
                                        let tableName = constants.ACCOUNTS_OBJECT;
                                        let listviewData = getArrayParam(props.listView.data.records);
                                        if (listviewData.length > 0) {
                                            listviewData = listviewData.filter(f => f.id === recordId);
                                            if (listviewData.length > 0) {
                                                tableName = TABLEID_OBJECT_MAP[listviewData[0].t_temp_tableid];
                                            }
                                        }
                                        data = { id: recordId, object: objectName, mode: 'copy', table: tableName };
                                        dispatch(getAppDrawer(true, labelName, value, data, constants.LIST_VIEW));
                                    }
                                    else {
                                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Please select one record to copy. ', null);
                                    }
                                }
                                else if (objectName === constants.WEBHOOKS_OBJECT) {
                                    isOpenDrawer = false;
                                    if (selectedIds.length === 1) {
                                        let tableName = constants.ACCOUNTS_OBJECT;
                                        let listviewData = getArrayParam(props.listView.data.records);
                                        if (listviewData.length > 0) {
                                            listviewData = listviewData.filter(f => f.id === recordId);
                                            tableName = TABLEID_OBJECT_MAP[listviewData[0].t_temp_tableid];
                                        }
                                        labelName = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.WEBHOOKS_RULES.WEBHOOKS_RULE;
                                        isOpenDrawer = true;
                                        data = { id: recordId, object: objectName, mode: 'edit', table: tableName };
                                        dispatch(getAppDrawer(isOpenDrawer, labelName, value, data, constants.LIST_VIEW));
                                    } else {
                                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.SELECT_RECORD_FOR_EDIT_ONE_RECORD, null);
                                    }
                                }
                                else if (objectName === constants.TEMPLATE_CATEGORIES) {
                                    isOpenDrawer = false;
                                    if (selectedIds.length === 1) {
                                        labelName = getLocalizedStrings().label.COMMON.EDIT + ' ' + getLocalizedStrings().label.SETTINGS.TEMPLATE_CATEGORIES;
                                        isOpenDrawer = true;
                                        data = { id: recordId, object: objectName, mode: 'edit' };
                                        dispatch(getAppDrawer(isOpenDrawer, labelName, value, data, constants.LIST_VIEW));
                                    } else {
                                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.SELECT_RECORD_FOR_EDIT_ONE_RECORD, null);
                                    }
                                }
                                else if(isRelationshipTabExist && value == "Edit" &&  objectName == 'accounts' && labelName == "Edit Relationships"){
                                  let type = LinkToType.TYPE_RELATIONSHIP;
                                  let label = "Edit Relationship";
                                    let obj = {};
                                    let _data = {}
                                    _data.id = recordId;
                                    obj.title = selectedRows[0].title;
                                    _data.object = obj;
                                    console.log("data???",_data)
                                    dispatch(getAppDrawer(true, label, type, _data, null));
                                }
                                else {
                                    if (value == "Push") {
                                        labelName = getLocalizedStrings().label.COMMON.PUSH;
                                    }
                                    if (value == "Assign" && objectName === constants.SALES_LEADS_OBJECT) {
                                        value = getLocalizedStrings().label.COMMON.PUSH;
                                    }

                                    dispatch(getAppDrawer(true, labelName, value, data, constants.LIST_VIEW));
                                }
                            }
                        }
                    }
                }
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null);
            }
        } catch (error) {
            console.error("Error in 'listViewHeader.js -> handleAction()':" + error);
        }
    };

    const shareWithUnitsAction = (selectedIds) => {
        let _params = {
            id: selectedIds
        };
        let _endPoint;
        if (props.object === constants.CONTACTS_OBJECT) {
            _endPoint = endPoints.CONTACTS.SHARE_WITH_UNITS;
        } else if (props.object === constants.PARTNERS_OBJECT) {
            _endPoint = endPoints.PARTNERS.SHARE_WITH_UNITS;
        }
        let promise = Promise.resolve(HTTPClient.post(_endPoint, _params));
        promise.then((response) => {
            if (isValidParam(response)) {
                if (response.status === 0) {
                    let selectedRows = props.listView.selectedRows;
                    let msg = `${selectedRows.length} record(s) shared successfully with the units.`
                    dispatch(showCustomSnackBar(msg, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }
            }
        })
    }

    const restoreLeadAccounts = (recordIds, objectName) => {
        var promise = null;
        let url = null;
        let params = null;
        url = endPoints.ACCOUNTS.RESTORE;
        try {
            let statusfield = reduxSfForm?.data?.accounts?.fields.filter(f => f.name == 't_status');
            let status = statusfield[0].default_value;
            params = {
                'record_ids': recordIds,
                'contactStatus': status
            }
            promise = Promise.resolve(HTTPClient.post(url, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response)) {
                        refreshListView('recyclebin');
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.RECYCLE_BIN.RESTORE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    }
                });
            }
        } catch (error) {
            console.error("Error in 'Restore.js -> onRestoreSubmit()':" + error);
        }
    }

    const xmsAlertDialog = (val, isSuccess) => {
        let alertDialogTitle = getLocalizedStrings().message.COMMON.XMS_SUCCESS_ALERT_DIALOG_TITLE;
        if (!isSuccess) {
            alertDialogTitle = getLocalizedStrings().message.COMMON.XMS_ERROR_ALERT_DIALOG_TITLE;
        }
        sfDialogs.alert(alertDialogTitle, val, null);
    }

    const triggerActionONConfirmedGroupUsedInCampaigns = (selectedIds, objectName) => {
        const recordIdList = [];
        for (let i = 0; i < selectedIds.length; i++) {
            recordIdList.push(selectedIds[i]);
        }
        let param = {
            "id": recordIdList
        }
        const response = deleteAction(objectName, param);
        response.then((response) => {
            if (response.status === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                let tabInfo = getObjectParam(getActiveTabInfo());
                let params = null;
                if (tabInfo.hasOwnProperty('searchFieldName') && tabInfo.hasOwnProperty('searchText')) {
                    params = {
                        search_field_name: getStringParam(tabInfo.searchFieldName),
                        search_text: getStringParam(tabInfo.searchText)
                    }
                }
                refreshListView(props.object, params);
            }
        });
    }

    const triggerActionONConfirmedWebFrom = (selectedIds) => {
        let params = { id: selectedIds };
        const promise = deleteWebForms(params);
        if (promise !== null) {
            promise.then((response) => {
                let totalCount = response.data.totalCount;
                let webFormUsedCount = response.data.webFormUsedCount;
                if (webFormUsedCount > 0) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, webFormUsedCount + " " + getLocalizedStrings().message.LIST_VIEW.WEB_FORM_ALERT1 + " " + totalCount + " " + getLocalizedStrings().message.LIST_VIEW.WEB_FORM_ALERT2, null);
                    refreshListView(props.object, params);
                } else {
                    refreshListView(props.object, params);
                }
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
            });
        }
    }
    const triggerActionONConfirmedOtherObject = (selectedIds, objectName, selectedRows) => {
        let params = { id: selectedIds };
        if (props.isDetailView !== undefined && props.isDetailView === true) {
            params["isUnlink"] = true;
            params["parent_record_id"] = props.listView.data.parent_record_id;
            params["parent_object"] = props.listView.data.parent_object;
            if (linkChildTableId === 0) {
                params.relationTableId = 10;
                params.tableId = 10;
            }
        }


        if (objectName === constants.PRODUCTS_OBJECT) {
            let promise = Promise.resolve(checkSyncedProducts(params));
            if (isValidParam(promise)) {
                promise.then((response) => {

                    if (isValidParam(response) && response.hasOwnProperty('syncExists')) {
                        let msgArr = [];
                        let msg = '';
                        if (getBooleanParam(response.syncExists)) {
                            let arrValue = getArrayParam(response.prodNameList);

                            if (arrValue.length > 0) {
                                if (arrValue.length === 1) {
                                    msg = "Following prodcut is already synced.";
                                    msgArr.push(msg);
                                } else {
                                    msg = "Following prodcuts are already synced.";
                                    msgArr.push(msg);
                                }
                                arrValue.forEach(val => {
                                    msg = <span><br /><b>{val}</b></span>;
                                    msgArr.push(msg);
                                });
                                msg =
                                    <span><br />Please remove the product from selection before doing delete operation.</span>;
                                msgArr.push(msg);
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msgArr, null);
                            }
                        } else {
                            doDeleteRecords(objectName, params, selectedRows);
                        }
                    } else {
                        doDeleteRecords(objectName, params, selectedRows);
                    }
                });
            }
        } else {
            doDeleteRecords(objectName, params, selectedRows);
        }
    }
    const triggerActionONConfirmedRecordsWithDiffWrkFlow = (labelName, convertStyle, value, data) => {
        data.isChangeWorkflow = true;
        dispatch(getAppCustomDrawer(true, labelName, value, convertStyle, data, null, true, constants.LIST_VIEW));
    }
    const triggerActionONConfirmedRecordsWithDiffWrkFlowStatus = (labelName, convertStyle, value, data) => {
        data.isChangeWorkflow = true;
        dispatch(getAppCustomDrawer(true, labelName, value, convertStyle, data, null, true, constants.LIST_VIEW));
    }
    const triggerActionONConfirmedConvertContToAcc = (response) => {
        let linkDetails = response.link_details;
        let label = isInvalidParam(linkDetails.title) ? 'Empty' : linkDetails.title;
        let tab = {
            item: constants.CONTACTS_OBJECT,
            label: label,
            object: constants.ACCOUNTS_OBJECT,
            imgName: null,
            url: '/accounts/detailview/' + linkDetails.id,
            type: TYPE_DETAIL_VIEW,
            info: {}
        };
        addTab(tab, true);
    }

    const login = (projectId) => {
        let myclient_id = projectId
        let promise = franchiseLogin(myclient_id);
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    if (getBooleanParam(response.is_franchise_login)) {
                        let previousFRLoginDetails = localStorage.getItem('franchiseAppLoginDetails')
                        previousFRLoginDetails = previousFRLoginDetails ? JSON.parse(previousFRLoginDetails) : [];
                        const { me: { id, background, industry_id, industry_type, is_franchisor, is_franchisee, email, projectId }, selectedModuleItems, selectedModuleName, selectedModuleInfo } = reduxApp
                        const { rememberTab, tabs } = reduxTab;
                        const currentUrl = window.location.href;
                        const franchiseAppLoginDetails = {}
                        franchiseAppLoginDetails.appData = { id: id, background: background, industryId: industry_id, industryType: industry_type, isFranchisor: is_franchisor, is_franchisee: is_franchisee, email: email, projectId: projectId }
                        franchiseAppLoginDetails.selectionDetails = { selectedModuleItems: selectedModuleItems, selectedModuleName: selectedModuleName }
                        if (isValidParam(selectedModuleInfo)) {
                            franchiseAppLoginDetails.selectionDetails = { ...franchiseAppLoginDetails.selectionDetails, selectedModuleInfo: selectedModuleInfo }
                        }
                        franchiseAppLoginDetails.tabDetails = { listrememberedTab: rememberTab, listselectedTab: tabs }
                        franchiseAppLoginDetails.returnUrl = currentUrl
                        franchiseAppLoginDetails.token = response.token
                        franchiseAppLoginDetails.callFrom = constants.LIST
                        franchiseAppLoginDetails.shouldReturnToInitialPos_ = `shouldReturnToInitialPos_${reduxApp.me.projectId}_${reduxApp.me.id}`
                        if (isArray(previousFRLoginDetails)) {
                            previousFRLoginDetails.push(franchiseAppLoginDetails)
                        }
                        localStorage.setItem('token', response.token);
                        localStorage.setItem('franchiseAppLoginDetails', JSON.stringify(previousFRLoginDetails))
                        localStorage.setItem(`shouldReturnToInitialPos_${reduxApp.me.projectId}_${reduxApp.me.id}`, true)
                        localStorage.setItem('is_franchisor_token', true);
                        if (constants.APP_URL === "") {
                            window.location.replace("/");
                        } else {
                            window.location.replace(constants.APP_URL);
                        }
                    } else {
                        if (response.data?.is_subscription_overdue) {
                            localStorage.removeItem(`shouldReturnToInitialPos_${reduxApp.me.projectId}_${reduxApp.me.id}`)
                            let alertDialogMessage = getLocalizedStrings().message.UNITS.SUBSCRIPTION_NOT_VALID;
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null);
                        } else if (response.data?.password_expired) {
                            let alertDialogMessage = getLocalizedStrings().message.LOGIN.FE_EXPIRED_PASSWORD
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null);
                        } else if (response.data.hasOwnProperty('is_tenant_pass_configured') && response.data.is_tenant_pass_configured == false) {
                            localStorage.removeItem(`shouldReturnToInitialPos_${reduxApp.me.projectId}_${reduxApp.me.id}`)
                            let alertDialogMessage = getLocalizedStrings().message.UNITS.TENANT_PASSWORD_NOT_SET_YET;
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertDialogMessage, null);
                        }
                    }
                }
            });
        }
    }

    const reportSales = (id, selectedIds) => {
        let _isDetailView = getBooleanParam(props.isDetailView);
        let params = {
            "id": id,
            "reportedSalesRecordIds": selectedIds.toString()
        }
        var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.REPORT_SALES, params));
        response.then((response) => {
            dispatch(setMounted(true, _isDetailView));
            if (response.status == 0) {
                dispatch(refreshDetailViewData(true));
                dispatch(refreshDetailViewListViewData(true));

                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.REPORT_SALES_SAVED_SUCESS, null);

            } else if (response.status == -4) {
                let msg = "";
                let tempMsg1 = 'No Reported Sales record has been created.';
                let tempMsg2 = 'No units are enrolled in this plan!';
                let tempMsg3 = `<span>Enroll units in this plan then try again.<a href=${'#'}> Click here</a> for instructions</span>`;
                msg += '<span>' + tempMsg1 + '<br/></span>';
                msg += '<span>' + tempMsg2 + '<br/></span>';
                msg += '<span>' + tempMsg3 + '<br/></span>';
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
            } else if (response.status == -5) {
                // if (response.data.hasOwnProperty("reportedBy") && response.data.reportedBy === constants.REPORTED_BY_FR) {
                //     if (selectedIds > 0) {
                //         onConfirmReportedSalesUpdate(response, selectedIds);
                //     } 
                // } else {
                //     sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.REPORT_SALES_SAVED_SUCESS, null);
                // }
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.REPORT_SALES_SAVED_SUCESS, null);

            } else if (response.status == -3) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.REPORTED_SALES.REPORTED_SALES_SOURCE_NOT_ADDED, null);
            }
        });
    }

    const createReportedSales = (id) => {
        let params = {
            "id": id
        }
        var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.REPORT_SALES_CREATE, params));

        response.then((response) => {
            if (response.status == 0) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.REPORTED_SALES.REPORTED_SALES_CREATED, null);
                dispatch(refreshDetailViewData(true));
                dispatch(refreshDetailViewListViewData(true));
            } else if (response.status == -3) {
                let msg = "";
                let tempMsg1 = 'Reported Sales records have been already added for all the enrolled Units!';
                let tempMsg2 = 'Please check the linked Reported Sales records available for this Royalty Run record.';
                msg += '<span>' + tempMsg1 + '<br/></span>';
                msg += '<span>' + tempMsg2 + '<br/></span>';
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
            } else if (response.status == -2) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.NO_UNITS_ENROLLED, null);
            }
        });
    }
    const royaltyRunProcess = (id, selectedIds) => {
        let isDetailView = getBooleanParam(props.isDetailView);
        let params = {
            "id": id,
            "reportedSalesRecordIds": selectedIds.toString(),
        }
        var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.PROCESS, params));
        response.then((response) => {
            dispatch(setMounted(true, isDetailView));
            if (response.status == 0) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.ROYALTIES_PROCESSED, null);
                dispatch(detailViewIsMounted(false));
                dispatch(getTasksRefresh(false));
                dispatch(refreshDetailViewListViewData(true));
                dispatch(refreshDetailViewData(true));
            } else if (response.status == -2) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTY_RUNS.ROYALTIES_NOT_PROCESSED, null);
            }
        });
    }

    const reportedSalesUpdate = (selectedIds) => {
        let params = {
            "ids": selectedIds
        }
        var response = Promise.resolve(HTTPClient.post(endPoints.REPORTED_SALES.UPDATE, params));
        response.then((response) => {
            if (response.status == 0) {
                dispatch(setMounted(true));
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.REPORTED_SALES.REPORTED_SALES_UPDATED, null);
                refreshListView(props.object);
            }
        });
    }

    const getListView = (_isRefresh) => {

        let filterObj = {};
        filterObj.parent_object = props.listView.data.parent_object;
        filterObj.parent_record_id = props.listView.data.parent_record_id;
        filterObj.query_id = props.listView.data.query_id;
        filterObj.query_name = props.listView.data.query_name;
        filterObj.query_type = props.listView.data.query_type;
        dispatch(removeScrollPosition(props.object));
        props.listViewCallBack(props.object, filterObj, _isRefresh);
    }

    const openListViewSetupPopOver = (event) => {
        let tabInfo = getActiveTabInfo();
        let isInXpress = getBooleanParam(tabInfo.isInXpress);

        let isUnitsListView = false;
        if (tabInfo.hasOwnProperty('isUnitsListView') && tabInfo.isUnitsListView) {
            isUnitsListView = getBooleanParam(tabInfo.isUnitsListView);
        }

        if (!isInXpress && !isUnitsListView) {
            event.preventDefault();
            setIsOpenSetupPopover(true);
            setSetupComponent(<ListViewSetup object={props.object} suffix={props.suffix} closeEvent={() => closeListViewSetupPopOver()}
                isDetailView={props.isDetailView} />)
            setAnchorEl(event.currentTarget);
        } else {
            let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
        }
    }

    const closeListViewSetupPopOver = () => {
        setAnchorEl(null);
        setIsOpenSetupPopover(false);
        setSetupComponent(null);
    }

    const groupByShowHideFn = () => {
        let tab = getActiveTab();
        if (isValidParam(tab)) {
            let info = getObjectParam(tab.info);
            info.isShowGroupBy = reduxIsShowGroupBy ? false : true;
            updateTabInfo(info);
        }
        dispatch(groupByShowHide(props.suffix));
    }

    const searchFieldShowHide = () => {
        let tab = getActiveTab();
        if (isValidParam(tab)) {
            let info = getObjectParam(tab.info);
            info.isLeadstoreSearchFld = props.isLeadstoreSearchFld ? false : true;
            updateTabInfo(info);
        }
        dispatch(leadStoreSearchFldShowHide());
    }


    const handleKeyUp = (event) => {
        try {
            let search_text = event.target.value.trim();
            if (event.keyCode === 13) {
                searchWithinList(search_text);
            }
        } catch (error) {
            console.error("Error in 'ListViewHeader.js -> handleKeyUp()':" + error);
        }
    }

    const searchWithinList = (search_text) => {
        try {
            let searchFieldName = selectedSearchFieldName;
            let searchText = search_text.trim();
            let tab = getActiveTab();
            if (isValidParam(tab)) {
                let info = getObjectParam(tab.info);

                if (getStringParam(info.searchFieldName) !== searchFieldName
                    || (getStringParam(info.searchFieldName) === searchFieldName && getStringParam(info.searchText) !== searchText)) {
                    info.searchFieldName = searchFieldName;
                    info.searchText = searchText;
                    info.isSearchWithinList = true;
                    if (info.hasOwnProperty("search")) {
                        if (!info.hasOwnProperty("parentSearchText") && !info.search.hasOwnProperty("parentSearchText")) {
                            info.parentSearchText = info.search.searchText;
                            info.search.parentSearchText = info.search.searchText;
                        }
                        info.search.searchText = searchText;
                    } else if (info.hasOwnProperty("advanceSearch")) {
                        let searchData = getObjectParam(info.advanceSearch.searchParams);
                        let search = {};
                        let data = {};

                        if (searchText !== "") {
                            search = searchData.search;
                            if (info.advanceSearch.searchParams.hasOwnProperty("isSearch") && info.advanceSearch.searchParams.isSearch) {
                                search.pop();
                            } else {
                                info.advanceSearch.searchParams.isSearch = true;
                            }
                            data[searchFieldName] = searchText;
                            search.push(data);
                            searchData.search = search;
                            info.advanceSearch.searchParams = searchData;
                        } else {
                            search = getArrayParam(searchData.search);
                            if (info.advanceSearch.searchParams.hasOwnProperty("isSearch") && info.advanceSearch.searchParams.isSearch) {
                                search.pop();
                            } else {
                                info.advanceSearch.searchParams.isSearch = true;
                            }
                            searchData.search = search;
                            info.advanceSearch.searchParams = searchData;
                        }
                    }

                    delete info.selectedPageNo;
                    delete info.startIndex;
                    delete info.startPageNo;
                } else {
                    delete info.isSearchWithinList;
                }
                updateTabInfo(info);
                if (getBooleanParam(info.isSearchWithinList)) {
                    getListView();
                    refreshListView(props.object);

                }
            }
        } catch (error) {
            console.error("Error in 'ListViewHeader.js -> searchWithinList()':" + error);
        }
    }

    const handleSearchFieldsListOpen = (event) => {
        event.preventDefault();
        setIsOpenSearchFieldsList(true);
        setAnchorEl(event.currentTarget);
    }

    const handleSearchFieldsListClose = () => {
        setIsOpenSearchFieldsList(false);
    }

    const changeSearchText = (event) => {
        let value = event.target.value;
        setSearchText(value);
    }

    const getStatusFieldLable = (value, checker) => {
        let label = value === 'Active' ? getLocalizedStrings().label.COMMON.ACTIVE : getLocalizedStrings().label.COMMON.INACTIVE;
        if (checker !== undefined && checker !== '') {
            label = reduxListView.filterByInfo.filterValue !== undefined && reduxListView.filterByInfo.filterValue !== '' && reduxListView.filterByInfo.filterValue !== null ? getStatusFieldLable(reduxListView.filterByInfo.filterValue) : getStatusFieldLable(value)
        }
        return label
    }

    const changeStatusField = (value) => {
        updateListviewFilter(props.object, popOverList, value);
        let tab = getActiveTab();
        if (isValidParam(tab)) {
            let info = getObjectParam(tab.info);
            info.statusfieldName = value;
            info.searchFieldName = selectedSearchFieldName;
            info.searchText = '';
            info.isSearchWithinList = true;
            delete info.selectedPageNo;
            delete info.startIndex;
            delete info.startPageNo;
            updateTabInfo(info);
            getListView();
        }
        setSelectedStatusValue(value);


    }

    const changeSearchFieldName = (value, listviewFields) => {
        try {
            if (isValidParam(listviewFields)) {
                let selectedSearchFieldObj = listviewFields.filter(function (el) {
                    return el.name === value;
                });
                if (isValidParam(selectedSearchFieldObj)) {
                    selectedSearchFieldObj = selectedSearchFieldObj[0];
                    if (isValidParam(selectedSearchFieldObj) && selectedSearchFieldObj.hasOwnProperty('name') &&
                        selectedSearchFieldObj.hasOwnProperty('label')) {
                        setIsOpenSearchFieldsList(false);
                        setSelectedSearchFieldName(selectedSearchFieldObj.name);
                        setSelectedSearchFieldLabel(selectedSearchFieldObj.label);
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'ListViewHeader.js -> changeSearchFieldName()':" + error);
        }
    }
    const openRelationshipDrawer = () => {
        let tabInfo = getActiveTabInfo();
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        if (!isInXpress) {
            dispatch(getAppDrawer(true, getLocalizedStrings().label.RELATIONSHIP.DEFINE_RELATIONSHIP, LinkToType.TYPE_RELATIONSHIP, null, constants.DETAIL_VIEW));
        } else {
            let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
        }
    }

    const changeTextFieldStyle = () => {
        try {
            setIsSearchExist(true);
        } catch (error) {
            console.error("Error in 'ListViewHeader.js -> changeTextFieldStyle()':" + error);
        }
    }
    const listviewSearch = () => {
        try {
            let searchTextData = searchText;
            searchWithinList(searchTextData);
        } catch (error) {
            console.error("Error in 'ListViewHeader.js -> listviewSearch()':" + error);
        }
    }
    const openLookupDialog = () => {
        try {
            let tabInfo = getActiveTabInfo();
            let isInXpress = getBooleanParam(tabInfo.isInXpress);
            let isUnitsAccount = false;
            const requiredObject = reduxApp.objectList?.find((item) => item?.name === props?.object);

            if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
                isUnitsAccount = tabInfo.isUnitsAccount;
            }
            if (!isInXpress && !isUnitsAccount) {
                let isFindAndLink = true;
                let dialogData = {};
                dialogData.isFindAndLink = isFindAndLink;
                dialogData.findAndLinkObject = props.object;
                dialogData.fullWidth = true;
                dialogData.maxWidth = 'md';
                dialogData.object = props.object;
                dispatch(getAppDialog(true, constants.LOOKUP_DIALOG, `Find and Link ${requiredObject?.label}`, (li) => getLookupSelectedRecord(li), dialogData, null));

            } else {
                let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage, null);
            }
        } catch (error) {
            console.error("Error in 'ListViewHeader.js -> openLookupDialog()':" + error);
        }
    }

    const getLookupSelectedRecord = (lookupInfo) => {
        let recordIds = '';
        try {
            if (isValidParam(lookupInfo)) {
                let parentObject = getStringParam(props.detailViewParams.parent_object);
                let parentObjectId = getIntParam(OBJECT_TABLEID_MAP[parentObject]);
                let parentRecordId = getIntParam(reduxDetailView.data.record.id);
                let object = getStringParam(props.listView.data.object).toLowerCase();
                let objectId = getIntParam(OBJECT_TABLEID_MAP[object]);

                let arrLookupRecordInfo = getArrayParam(lookupInfo.arrLookupRecordInfo);
                arrLookupRecordInfo.forEach(record => {

                    if (isValidParam(record)) {
                        let recordId = getIntParam(record.record_id);
                        recordId = recordId.toString();
                        recordIds = recordIds === '' ? recordId : recordIds + ',' + recordId;
                    }
                });

                let params = {};
                params.parent_object_id = parentObjectId;
                params.parent_record_id = parentRecordId;
                params.object_id = objectId;
                params.record_id = recordIds;

                let promise = Promise.resolve(getLinkRecordsForLookup(params));
                promise.then((response) => {
                    if (isValidParam(response) && getBooleanParam(response.data)) {
                        refreshListView(object, { isDetailView: true });
                        dispatch(refreshDetailViewData(true));
                    }
                });
            }
            dispatch(getAppDialog(false, null, null, null, null, null));
        } catch (error) {
            console.error("Error in 'ListViewHeader.js -> getLookupSelectedRecord()':" + error);
        }
    }

    const sendVerificationEmail = (selectedRows) => {
        let url = endPoints.EMAIL_INTEGRATION.SEND_VERIFICATION_EMAIL;
        let msg1 = getLocalizedStrings().message.EMAIL_INTEGRATION.SEND_VERIFICATION_EMAIL_SUCCESS_1;
        let msg2 = getLocalizedStrings().message.EMAIL_INTEGRATION.SEND_VERIFICATION_EMAIL_SUCCESS_2;
        let msg3 = getLocalizedStrings().message.EMAIL_INTEGRATION.SEND_VERIFICATION_EMAIL_SUCCESS_3;
        let emailVerificationMessage = '<div>' + msg1 + '<b>' + msg2 + '</b>' + msg3 + '</div>';
        try {
            let id = selectedRows[0].id;
            let records = getArrayParam(props.listView.data.records);
            let recordObj = records.filter((obj) => {
                return obj.id === id
            });
            if (recordObj !== null && isValidParam(recordObj) && recordObj[0].status.toLowerCase() === 'new') {
                let param = {};
                param.id = id;
                param.email = selectedRows[0].title;
                let promise = Promise.resolve(HTTPClient.post(url, param));
                promise.then((response) => {
                    if (isValidParam(response)) {
                        if (response.status === 0) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, emailVerificationMessage, null);
                        }
                    }
                });
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.EMAIL_INTEGRATION.EMAIL_INTEGRATION_VERIFIED, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
            }

        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> sendVerificationEmail() '" + error);
        }
    }

    const deleteEmail = (selectedIds) => {
        try {
            let msg = getLocalizedStrings().message.EMAIL_INTEGRATION.CONFIRM_DELETE_EMAIL;
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmDeleteEmail(selectedIds), null);
        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> deleteEmail() '" + error);
        }
    }
    const onConfirmDeleteEmail = (selectedIds) => {
        // Delete email id 
        let url = endPoints.EMAIL_INTEGRATION.DELETE_EMAIL;
        let params = {};
        params.recordIds = selectedIds;
        let promise1 = Promise.resolve(HTTPClient.post(url, params));
        promise1.then((response) => {
            if (isValidParam(response) && response.status === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                dispatch(getVerifiedEmais(false));
                refreshListView(props.object);
            }
        });
    }

    const deleteAuditQuestion = (selectedIds) => {

        try {
            let msg = getLocalizedStrings().label.AUDIT_QUESTIONS.MESSAGE_DELETE + ' ' + selectedIds.length + ' ' + getLocalizedStrings().label.AUDIT_QUESTIONS.SELECTED_RECORDS;
            sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmdeleteAuditQuestion(selectedIds), null);
        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> deleteAuditQuestion() '" + error);
        }
    }


    const onConfirmdeleteAuditQuestion = (selectedIds) => {
        if (selectedIds.length === props.listView.data.records.length) {
            let tab = getActiveTab();
            let tabInfo = tab.info;
            tabInfo.groupByCondition = "All";
            updateTabInfo(tabInfo);
        }
        let url = endPoints.AUDIT_QUESTIONS.DELETE_QUESTION;
        let promise1 = Promise.resolve(HTTPClient.post(url, { "questionIds": selectedIds }));
        promise1.then((response) => {
            if (isValidParam(response) && response.status === 0) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                let params = { "query_name": "All Audit Questions", "query_type": "query","sort_type": "desc","sort_field_name": "created_on"}
                refreshListView(props.object, params);
            }
        });
    }

    const deleteTemplateCategories = (selectedIds) => {
        try {
            let length = selectedIds.length;
            if (length == 1) {
                let msg = '1 ' + getLocalizedStrings().message.COMMON.CONFIRM_DELETE_RECORD;
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmDeleteTemplateCategories(selectedIds), null);
            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.SELECT_RECORD_FOR_DELETE_ONE_RECORD, null);
            }
        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> deleteTemplateCategories() '" + error);
        }
    }

    const onConfirmDeleteTemplateCategories = (selectedIds) => {
        try {
            let params = { id: selectedIds };
            const response = deleteAction(props.object, params);
            response.then((response) => {
                if (isValidParam(response)) {
                    if (response.data) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        refreshListView(props.object);
                    } else if (response.data == false) {
                        let tempMsg = "This template sub category cannot be deleted as it is used in Sales or Marketing Email Templates.";
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, tempMsg, null);
                    }
                }
            });
        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> onConfirmDeleteTemplateCategories() '" + error);
        }
    }

    const addToQuickBooks = (selectedIds) => {
        try {
            let params = {};
            params.ids = selectedIds;
            params.parent_record_id = reduxDetailViewRecord.id;
            params.parent_object = props.detailViewParams.parent_object;
            let url = endPoints.PAYMENTS.ADD_TO_QUICKBOOKS;
            let promise = Promise.resolve(HTTPClient.post(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === 0) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        refreshListView(props.object);
                    } else if (response.status === 101) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.PAYMENTS.NOT_ADDED_QB, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else if (response.status === 100) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.PAYMENTS.NO_LINKED_AVAILABLE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }

                }
            })


        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> addToQuickBooks() '" + error);
        }

    }

    const pushToQuickBooks = (selectedIds) => {
        try {
            let params = {};
            params.recordIds = selectedIds;
            let url = endPoints.PRODUCTS.PUSH_TO_QB;
            let promise = Promise.resolve(HTTPClient.post(url, params));
            promise.then((response) => {
                if (isValidParam(response) && response.status === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.PRODUCTS.ADDED_TO_QUICKBOOK, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    refreshListView(props.object);
                } else {
                    if (response.status === -1) {
                        let msgArr = [];
                        let msg = '';
                        if (response.hasOwnProperty('data')) {
                            if (response.data.hasOwnProperty('failedCreateProductList')) {

                                let arrValue = getArrayParam(response.data.failedCreateProductList);
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg = `Product ${val} couldnot be added to Quickbooks.`;
                                    });

                                }
                            }

                            if (response.data.hasOwnProperty('failedUpdateProductList')) {
                                let arrValue = getArrayParam(response.data.failedUpdateProductList);
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg = `Product ${val} couldnot be updated in Quickbooks.`;
                                    });

                                }
                            }
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);

                        }
                    }
                }
            });


        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> pushToQuickBooks() '" + error);
        }

    }

    const addToXero = (selectedIds) => {
        try {
            let params = {};
            params.ids = selectedIds;
            params.parent_record_id = reduxDetailViewRecord.id;
            params.parent_object = props.detailViewParams.parent_object;
            let url = endPoints.PAYMENTS.ADD_TO_XERO;
            let promise = Promise.resolve(HTTPClient.post(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    if (response.status === 0) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.SAVE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                        refreshListView(props.object);
                    } else if (response.status === 101) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.PAYMENTS.NOT_ADDED_QB, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    } else if (response.status === 100) {
                        dispatch(showCustomSnackBar(getLocalizedStrings().message.PAYMENTS.NO_LINKED_AVAILABLE, styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    }
                }
            })
        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> addToXero() '" + error);
        }

    }

    const processInvoice = (selectedIds) => {
        try {
            let object = getStringParam(props.listView.data.object).toLowerCase();
            let objectId = getIntParam(OBJECT_TABLEID_MAP[object]);
            let params = {};
            params.parent_object_id = 280;
            params.child_record_id = selectedIds[0];
            params.object_id = objectId;
            let promise = Promise.resolve(fetchLinkRecords(params));
            promise.then((response) => {
                if (isValidParam(response) && getIntParam(response.record_id) > 0) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTIES.INVOICE_ALREADY_EXIST, null);
                } else {
                    let params = {
                        "id": reduxDetailViewRecord.id,
                        "rs_record_id": selectedIds[0]
                    }
                    var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.INVOICEALL, params));
                    response.then((response) => {
                        if (response.status == 0) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTIES.INVOICE_CREATED, null);
                            dispatch(detailViewIsMounted(false));
                            dispatch(getTasksRefresh(false));
                            dispatch(refreshDetailViewListViewData(true));
                            dispatch(refreshDetailViewData(true));
                        }
                    });
                }
            });
        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> pushToQuickBooks() '" + error);
        }
    }


    const processMarkPaid = (selectedIds) => {
        try {

            let dataValue = {
                "id": reduxDetailViewRecord.id,
                object: constants.MARK_PAID_ALL,
                selectedIds: selectedIds,
                callFrom: 'reportedSalesListView'
            };
            dispatch(getAppDialog(true, constants.MARK_PAID_ALL, " Mark Paid", null, dataValue));


        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> pushToQuickBooks() '" + error);
        }
    }


    const processEmail = (selectedIds) => {
        try {
            let object = getStringParam(props.listView.data.object).toLowerCase();
            let objectId = getIntParam(OBJECT_TABLEID_MAP[object]);
            let params = {};
            params.parent_object_id = 280;
            params.child_record_id = selectedIds[0];
            params.object_id = objectId;
            let promise = Promise.resolve(fetchLinkRecords(params));
            promise.then((response) => {
                if (isValidParam(response) && getIntParam(response.record_id) > 0) {
                    let params = {
                        "id": reduxDetailViewRecord.id,
                        "rs_record_id": selectedIds[0]
                    }
                    var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.EMAIL_ALL, params));
                    response.then((response) => {
                        if (response.status == 0) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTIES.EMAIL_SUCCESS, null);
                            dispatch(refreshDetailViewListViewData(true));
                        }
                    });
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTIES.NO_INVOICE_EXIST, null);
                }
            });
        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> pushToQuickBooks() '" + error);
        }
    }


    const processPost = (selectedIds) => {
        try {
            let object = getStringParam(props.listView.data.object).toLowerCase();
            let objectId = getIntParam(OBJECT_TABLEID_MAP[object]);
            let params = {};
            params.parent_object_id = 280;
            params.child_record_id = selectedIds[0];
            params.object_id = objectId;
            let promise = Promise.resolve(fetchLinkRecords(params));
            promise.then((response) => {
                if (isValidParam(response) && getIntParam(response.record_id) > 0) {
                    let params = {
                        "id": reduxDetailViewRecord.id,
                        "rs_record_id": selectedIds[0]
                    }
                    var response = Promise.resolve(HTTPClient.post(endPoints.ROYALTY_RUN.POST_ALL, params));
                    response.then((response) => {
                        if (response.status == 0) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTIES.POST_SUCCESS, null);
                            dispatch(detailViewIsMounted(false));
                            dispatch(getTasksRefresh(false));
                            dispatch(refreshDetailViewListViewData(true));
                            dispatch(refreshDetailViewData(true));
                        }
                    });
                } else {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.ROYALTIES.NO_INVOICE_EXIST, null);
                }
            });
        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> processPost() '" + error);
        }
    }

    const deleteWorkflow = (selectedIds) => {
        let group_by_condition = props.listView.data.group_by_condition;

        try {
            if (group_by_condition !== 'Appointments') {

                let objectList = reduxApp.objectList;
                let group_by_condition = props.listView.data.group_by_condition;
                let selectedRows = props.listView.selectedRows;
                let selectedRowTitle = selectedRows[0].title;
                let statusData = selectedStatusValue;
                objectList = objectList.filter(f => { return f.label === group_by_condition });
                let childObject = objectList[0].name;
                childObject = getObjectNameByLabel(childObject);
                let tempParam = { id: selectedIds, objectName: childObject, status: statusData, isDeleteCheck: true };

                var confirmPromise = deleteAction(props.object, tempParam);
                confirmPromise.then((response) => {
                    if (isValidParam(response)) {
                        if (getBooleanParam(response.data.isSingleWF)) {
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.WORKFLOWS.ONLY_ONE_ACTIVE_WORKFLOW_CANNOT_DELETE, null);
                        } else if (getBooleanParam(response.data.isRulePresent)) {
                            let tempMsg = "This Workflow has been used in the Lead Conversion rule for Scheduler hence you cannot perform Delete/Inactive operation. First remove this workflow form Lead conversion rule then perform Delete/Inactive operation."
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, tempMsg, null);
                        } else {
                            if (selectedRowTitle !== 'Onboarding' && selectedRowTitle !== 'Brandwide') {
                                let msg = getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
                                if (selectedIds.length === 1) {
                                    msg = selectedIds.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORD + msg;
                                }
                                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConFirmDeleteWorkflow(selectedIds, childObject, statusData), null);
                            } else {
                                dispatch(showCustomSnackBar('You can not delete ' + selectedRowTitle + ' workflow', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                            }
                        }
                    }
                });


            } else {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'You cannot delete this workflow.', null);
            }
        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> deleteWorkflow() '" + error);
        }

    }

    const onConFirmDeleteWorkflow = (selectedIds, childObject, status) => {
        let params = { id: selectedIds, objectName: childObject, status: status };
        const response = deleteAction(props.object, params);
        response.then((response) => {
            if (isValidParam(response)) {
                if (response.data.isDeleted) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                    refreshListView(props.object);
                    const accountsPromise = getSFFormFieldsPromise(childObject);
                    accountsPromise.then((response) => {
                        if (isValidParam(response)) {
                            reduxSfForm.data[childObject] = response;
                        }
                    });
                } else if (response.data.isRulePresent) {
                    let tempMsg = "This Workflow has been used in the Lead Conversion rule for Scheduler hence you cannot perform Delete/Inactive operation. First remove this workflow form Lead conversion rule then perform Delete/Inactive operation."
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, tempMsg, null);
                }

            }
        });
    }

    const checkAttachmentBeforeDelete = (selectedIds, parent_object, parent_record_id) => {
        let tempParam = { ids: selectedIds };
        var confirmPromise = getAgreementType(tempParam);
        confirmPromise.then((response) => {
            if (isValidParam(response)) {
                let attachmentRes = getObjectParam(response);
                let deleteAttachmentIds = [];
                let notDeletableAttachmentIds = [];
                Object.keys(attachmentRes).map((key) => {
                    if (attachmentRes[key] !== "FDD" && attachmentRes[key] !== "Franchise Agreement") {
                        deleteAttachmentIds.push(key)
                    } else {
                        notDeletableAttachmentIds.push(key);
                    }
                });

                if (selectedIds.length === 1) {
                    if (notDeletableAttachmentIds.length > 0) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.FDD_AND_FA_AGREEMENT_CANNOT_BE_DELETED, null);
                    } else {
                        let msg = getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
                        msg = selectedIds.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORD + msg;
                        sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmDeleteAttachment(deleteAttachmentIds, parent_object, parent_record_id), null);
                    }
                } else {
                    let msg = getLocalizedStrings().message.BATCH_ACTION.CONFIRMATION_DELETE_MSG;
                    msg = selectedIds.length + ' ' + getLocalizedStrings().message.BATCH_ACTION.RECORDS + msg;
                    if (notDeletableAttachmentIds.length > 0) {
                        msg = getLocalizedStrings().message.LIST_VIEW.MULTIPLE_SELECT_FDD_FA_MSG_1 + selectedIds.length + getLocalizedStrings().message.LIST_VIEW.MULTIPLE_SELECT_FDD_FA_MSG_2 + notDeletableAttachmentIds.length + getLocalizedStrings().message.LIST_VIEW.MULTIPLE_SELECT_FDD_FA_MSG_3;
                    }
                    if (selectedIds.length == notDeletableAttachmentIds.length) {
                        sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.FDD_AND_FA_AGREEMENT_CANNOT_BE_DELETED, null);
                    } else {
                        sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, () => onConfirmDeleteAttachment(deleteAttachmentIds, parent_object, parent_record_id), null);
                    }
                }
            }
        });
    }

    const onConfirmDeleteAttachment = (selectedIds, parent_object, parent_record_id) => {
        let dataObj = {};
        dataObj.object = props.object;
        dataObj.parent_object = parent_object;
        dataObj.parent_record_id = parent_record_id;
        dataObj.id = selectedIds;
        dataObj.isGroup = false;
        dataObj.isUnlink = true;
        dataObj.isAttachment = true;
        const response = deleteAction(props.object, dataObj);
        response.then((response) => {
            if (isValidParam(response)) {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                dispatch(refreshDetailViewListViewData(true));
            }
        });
    }

    const sendEmail = (selectedIds, attachment_type, sign_status) => {
        let object = "";
        let attachmentId = 0;
        if (props.object === constants.ATTACHMENT_OBJECT && props.isDetailView === true) {
            object = props.parentObject;
            attachmentId = selectedIds[0];
        } else {
            object = props.object;
        }
        let _attachmentType = attachment_type ? attachment_type : null;
        let _signStatus = sign_status ? sign_status : null;
        let labelName = null;
        let isEmail = true;
        let recordId = 0;
        if (object == constants.REPORTED_SALES_OBJECT) {
            object = 'accounts';
        }
        if (selectedIds.length === 1) {
            if (object === constants.ACCOUNTS_OBJECT || object === constants.PARTNERS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.UNITS_OBJECT) {
                let endPoint = "";
                let params = {};
                if (props.object === constants.ATTACHMENT_OBJECT && props.isDetailView === true) {
                    recordId = reduxDetailViewRecord.id;
                } else {
                    recordId = selectedIds[0];
                }
                if (object === constants.ACCOUNTS_OBJECT || object === constants.UNITS_OBJECT || object === constants.PARTNERS_OBJECT) {
                    endPoint = endPoints.ACCOUNTS.MAP_DATA;
                    if (object === constants.PARTNERS_OBJECT) {
                        endPoint = endPoints.PARTNERS.MAP_DATA;
                    }
                    params = { "id": recordId, "fields": ["company", "first_name", "last_name", "email"] };
                } else if (object === constants.CONTACTS_OBJECT) {
                    endPoint = endPoints.CONTACTS.MAP_DATA;
                    params.id = recordId;
                    if (reduxApp.me.is_franchisee && props.listView.data.hasOwnProperty("doNotShowAction") && props.listView.data?.doNotShowAction) {
                        params = { "id": recordId, "fields": ["company", "first_name", "last_name", "email"], "call_from": "shared_with" };

                    } else {
                        params = { "id": recordId, "fields": ["company", "first_name", "last_name", "email"] };

                    }
                }
                const promise = Promise.resolve(HTTPClient.get(endPoint, params));
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response)) {
                            let records = response.records;
                            let firstName = "";
                            let lastName = "";
                            let fullName = "";
                            let company = "";
                            let email = "";
                            let isFullName = false;
                            if (records) {
                                if (records.hasOwnProperty('first_name')) {
                                    firstName = records.first_name;
                                }
                                if (records.hasOwnProperty('last_name')) {
                                    lastName = records.last_name;
                                }
                                if (records.hasOwnProperty('company')) {
                                    company = records.company;
                                }
                                if (records.hasOwnProperty('email')) {
                                    email = records.email;
                                }
                            }

                            if (getStringParam(email) !== '') {
                                if (firstName && firstName !== "" && firstName !== undefined && lastName && lastName !== "" && lastName !== undefined) {
                                    fullName = firstName + " " + lastName;
                                }
                                if (firstName && firstName !== "" && firstName !== undefined && (!lastName || lastName === "" || lastName === undefined)) {
                                    fullName = firstName;
                                }

                                if ((!firstName || firstName === "" || firstName === undefined) && lastName && lastName !== "" && lastName !== undefined) {
                                    fullName = lastName;
                                }

                                if (!fullName || fullName === "" || fullName === undefined) {
                                    fullName = company;
                                }

                                if (!fullName || fullName === "" || fullName === undefined) {
                                    fullName = email;
                                }

                                if (!fullName || fullName === "" || fullName === undefined) {
                                    fullName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                                    isEmail = false;
                                }

                                if (fullName && fullName !== "" && fullName !== undefined) {
                                    if (isEmail) {
                                        labelName = getLocalizedStrings().label.COMMON.EMAIL_TO + " " + fullName + "";
                                    } else if (!isEmail && !isFullName) {
                                        labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                                    }
                                }
                                if (props.object === constants.ATTACHMENT_OBJECT && props.isDetailView === true) {
                                    // object = props.object;
                                    labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                                    selectedIds.splice(0, 1);
                                    selectedIds.push(reduxDetailViewRecord.id);


                                    openSendEmailDialog(object, selectedIds, labelName, firstName, attachmentId, _attachmentType, _signStatus, null);
                                } else {
                                    openSendEmailDialog(object, selectedIds, labelName, firstName, attachmentId, _attachmentType, _signStatus, records);
                                }
                            } else {
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.LIST_VIEW.NO_EMAIL_TO_SEND_MAIL, null);
                            }

                        }
                    });
                }
            } else {
                labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                if (props.object === constants.ATTACHMENT_OBJECT && props.isDetailView === true) {
                    selectedIds.splice(0, 1);
                    selectedIds.push(reduxDetailViewRecord.id);
                    openSendEmailDialog(object, selectedIds, labelName, "", attachmentId, _attachmentType, _signStatus, null);
                }
            }


        } else {
            labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
            openSendEmailDialog(object, selectedIds, labelName);
        }
    }

    const openSendEmailDialog = (object, selectedIds, labelName, firstName, attachmentId, attachmentType, signStatus, records) => {
        let listViewRecords = props.listView.data.records;
        let detailViewRecord = reduxDetailViewRecord;
        let batchMailObjlist = [];

        if (object === constants.UNITS_OBJECT) {
            let selectedRows = reduxTreeView.selectedRows;
            if (reduxApp.isNewTree) {
                selectedRows = reduxListView.selectedRows;
            }
            batchMailObjlist = selectedRows.map((el) => {
                return { id: el.id, email: el.email };
            });
        } else if (props.object === constants.AUDIT_ANSWERS_OBJECT) {
            let batchMailObj = {};
            batchMailObj.id = selectedIds[0];
            batchMailObj.email = props.listView.data.audit_email
            batchMailObjlist.push(batchMailObj);
            batchMailObj = null;
        } else if (props.object !== constants.ATTACHMENT_OBJECT) {
            selectedIds.forEach(id => {
                let batchMailObj = {};
                let temprec = listViewRecords.filter(f => {
                    return f.id === id
                });
                if (temprec.length == 0 && records != undefined && records != null) {
                    temprec = [records];
                }
                batchMailObj.id = id;
                batchMailObj.email = temprec[0].email;
                batchMailObjlist.push(batchMailObj);
                batchMailObj = null;
            });
        }

        let temprec = [];
        if (props.object === constants.ATTACHMENT_OBJECT && props.isDetailView === true) {
            let batchMailObj = {};
            batchMailObj.id = detailViewRecord.id;
            if (props.object === constants.ATTACHMENT_OBJECT && object === constants.OPPORTUNITIES_OBJECT) {
                batchMailObj.email = detailViewRecord.primaryAccount[0].email;
            } else {

                batchMailObj.email = detailViewRecord.email;
            }
            batchMailObjlist.push(batchMailObj);

            temprec = listViewRecords.filter(f => {
                return f.id === attachmentId
            });

        }
        let value = LinkToType.TYPE_EMAIL;
        let data = {};
        data.email_id_list = batchMailObjlist
        data.object = object;
        data.batch_mail = selectedIds.length > 1;
        data.type = constants.TYPE_EMAIL;
        data.firstName = firstName;
        data.fieldName = 'email';
        if (props.object === constants.AUDIT_ANSWERS_OBJECT) {
            data.full_name = props.listView.data.mail_name;
            data.audit_date = props.listView.data.audit_date;
            data.type = 'corrective action';
            data.parent_record_id = reduxDetailView.data.record.id;
        }
        let style = {};
        style.width = '90%';
        if (props.object === constants.ATTACHMENT_OBJECT && props.isDetailView === true) {
            data.attachmentId = attachmentId;
            data.isAttachment = true;
            data.attachment_type = 'File';
            if (signStatus != null && (signStatus === 'Signed' || signStatus === 'Sent' || signStatus === 'Opened' || signStatus === 'In Progress')) {
                // bypass
            } else {
                if (temprec.length > 0 && (((isValidParam(temprec[0].attachment_type) &&
                    getStringParam(temprec[0].attachment_type) === 'Agreement')) || (attachmentType && attachmentType === 'Agreement'))) {
                    labelName = 'Send Agreement';
                    data.attachment_type = 'Agreement';
                }
            }
        }
        data = { ...data, email_id_list: [...batchMailObjlist] }

        dispatch(getAppCustomDrawer(true, labelName, LinkToType.TYPE_EMAIL, style, data, null, true));
        dispatch(getAppCustomDrawer(true, labelName, LinkToType.TYPE_EMAIL, style, data, null, true));

    }


    const makeCall = (selectedIds) => {
        let object = "";
        if (props.object === constants.ATTACHMENT_OBJECT && props.isDetailView === true) {
            object = props.parentObject;
        } else {
            object = props.object;
        }
        let recordId = 0;
        let fieldName = 'mobile';
        if (selectedIds.length === 1) {
            if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.UNITS_OBJECT || object === constants.PARTNERS_OBJECT) {
                let endPoint = "";
                let params = {};
                if (props.object === constants.ATTACHMENT_OBJECT && props.isDetailView === true) {
                    recordId = reduxDetailViewRecord.id;
                } else {
                    recordId = selectedIds[0];
                }
                if (object === constants.ACCOUNTS_OBJECT || object === constants.UNITS_OBJECT) {
                    endPoint = endPoints.ACCOUNTS.MAP_DATA;
                    params = {
                        "id": recordId,
                        "fields": ["company", "first_name", "last_name", "phone", "mobile", "hphone", "country"]
                    };
                } else if (object === constants.CONTACTS_OBJECT) {
                    endPoint = endPoints.CONTACTS.MAP_DATA;
                    params.id = recordId;
                    params = {
                        "id": recordId,
                        "fields": ["company", "first_name", "last_name", "phone", "mobile", "hphone", "country"]
                    };
                } else if (object === constants.PARTNERS_OBJECT) {
                    endPoint = endPoints.PARTNERS.MAP_DATA;
                    params.id = recordId;
                    params = {
                        "id": recordId,
                        "fields": ["company", "first_name", "last_name", "phone", "mobile", "hphone", "country"]
                    };
                }

                const promise = Promise.resolve(HTTPClient.get(endPoint, params));
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (isValidParam(response)) {
                            getCapabilityTokenForCall(props.object, recordId, response.records.phone, response.records.mobile, response.records.hphone, fieldName)
                        }
                    });
                }
            }
        } else {
            reduxPowerCalling.callDrawerOpend = false;
        }
    }


    const getCapabilityTokenForCall = (object, recordId, phoneField, mobileField, hPhoneField, fieldName) => {
        const promise = Promise.resolve(HTTPClient.get(endPoints.CALLING_INTEGRATION.GENERATE_CAPABILITY_TOKEN, null));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let capabilityToken = getStringParam(response.token);
                    if (capabilityToken !== null && capabilityToken !== "") {
                        getCapabilityToken(capabilityToken, object, recordId, phoneField, mobileField, hPhoneField, fieldName);
                    }
                }
            });
        }
    }

    const getCapabilityToken = (capabilityToken, object, recordId, phoneField, mobileField, hPhoneField, fieldName) => {
        let endPoint = "";
        let params = {};
        if (object === constants.ACCOUNTS_OBJECT || object === constants.UNITS_OBJECT) {
            endPoint = endPoints.ACCOUNTS.MAP_DATA;
            params = {
                "id": recordId,
                "fields": ["company", "first_name", "last_name", "phone", "mobile", "hphone", "workflow_id", "country"]
            };
        } else if (object === constants.CONTACTS_OBJECT) {
            endPoint = endPoints.CONTACTS.MAP_DATA;
            params.id = recordId;
            params = {
                "id": recordId,
                "fields": ["company", "first_name", "last_name", "phone", "mobile", "hphone", "workflow_id", "country"]
            };
        } else if (object === constants.PARTNERS_OBJECT) {
            endPoint = endPoints.PARTNERS.MAP_DATA;
            params.id = recordId;
            params = {
                "id": recordId,
                "fields": ["company", "first_name", "last_name", "phone", "mobile", "hphone", "workflow_id", "country"]
            };
        }

        const promise = Promise.resolve(HTTPClient.get(endPoint, params));
        if (isValidParam(promise)) {
            promise.then(response => {
                if (isValidParam(response)) {
                    let records = response.records;
                    processInfoAndOpenCallDialog(records, capabilityToken, object, recordId, phoneField, mobileField, hPhoneField, fieldName);
                }
            });
        }
    }

    const processInfoAndOpenCallDialog = (records, capabilityToken, object, recordId, phoneField, mobileField, hPhoneField, fieldName) => {
        let firstName = "";
        let lastName = "";
        let fullName = "";
        let workflowIdData = "";
        let company = "";
        let title = "";
        let country = "";
        if (records) {
            if (records.hasOwnProperty('first_name')) {
                firstName = records.first_name;
            }

            if (records.hasOwnProperty('last_name')) {
                lastName = records.last_name;
            }

            if (records.hasOwnProperty('workflow_id')) {
                workflowIdData = records.workflow_id;
            }

            if (records.hasOwnProperty('company')) {
                company = records.company;
            }

            if (records.hasOwnProperty('country')) {
                country = records.country;
            }
        }
        let selectedPhoneNumber = mobileField;
        if (selectedPhoneNumber === undefined && !selectedPhoneNumber) {
            if (phoneField !== undefined && phoneField !== null) {
                selectedPhoneNumber = phoneField;
            } else {
                selectedPhoneNumber = hPhoneField;
            }
        }
        if (firstName && firstName !== "" && firstName !== undefined && lastName && lastName !== "" && lastName !== undefined) {
            fullName = firstName + " " + lastName;
        }
        if (firstName && firstName !== "" && firstName !== undefined && (!lastName || lastName === "" || lastName === undefined)) {
            fullName = firstName;
        }

        if ((!firstName || firstName === "" || firstName === undefined) && lastName && lastName !== "" && lastName !== undefined) {
            fullName = lastName;
        }

        if (!fullName || fullName === "" || fullName === undefined) {
            fullName = company;
        }
        if (!fullName || fullName === "" || fullName === undefined) {
            fullName = selectedPhoneNumber;
        }
        if (fullName && fullName !== "" && fullName !== undefined) {
            title = "Call " + fullName + "";
        }
        let label = title;
        let type = LinkToType.TYPE_CLICK_TO_CALL;
        let data = {};
        data.id = recordId;
        if (object === constants.UNITS_OBJECT) {
            object = constants.ACCOUNTS_OBJECT;
        }

        data.object = object;
        //data.type = dataType;
        data.token = capabilityToken;
        data.selectedPhoneNumber = selectedPhoneNumber;
        data.fieldName = fieldName;
        data.workflowId = workflowIdData;
        data.country = country;
        data.fullName = fullName;
        data.isDetailView = props.isDetailView;
        let style = {};
        style.width = '40%';
        let selectedRecord = [{ rowIdx: props.rowIdx, id: records.workflow_id, title: records.company }];
        data.selectedRecords = selectedRecord;
        data.call_from = constants.LIST_VIEW;
        let alertMassageString = '';
        if (object === constants.ACCOUNTS_OBJECT) {
            alertMassageString = "No Mobile/Phone Number found for this " + object;
        } else {
            alertMassageString = "No Mobile/Home Phone/Phone Number found for this " + object;
        }
        if (selectedPhoneNumber === undefined || selectedPhoneNumber === null || selectedPhoneNumber === "") {
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, alertMassageString, () => dispatch(getCallDrawer(false, null, null, null, null, null)))
        } else if (selectedPhoneNumber !== null && selectedPhoneNumber !== undefined && data !== undefined && label !== undefined) {
            dispatch(getCallDrawer(true, label, type, style, data, null));
        }
    }


    const updateEmailTemplateList = (selectedRows, objectName) => {
        let templateList = getArrayParam(reduxSendMail.templateList);
        if (templateList.length > 0) {
            let templateListObj = getObjectParam(templateList[0]);
            if (objectName === constants.SALES_TEMPLATE_OBJECT) {
                let salesTempList = getArrayParam(templateListObj['Sales Template']);
                let mySalesTempList = getArrayParam(templateListObj['My Sales Template']);
                for (let i = 0; i < selectedRows.length; i++) {
                    let rowObj = selectedRows[i];
                    salesTempList = salesTempList.filter(f => f.id !== rowObj['id']);
                    mySalesTempList = mySalesTempList.filter(f => f.id !== rowObj['id']);
                }
                sortArrayObjectByProperty(salesTempList, 'name');
                templateListObj['Sales Template'] = salesTempList;
                sortArrayObjectByProperty(mySalesTempList, 'name');
                templateListObj['My Sales Template'] = mySalesTempList;
                templateList = new Array(templateListObj);
                dispatch(storeTemplateListInRedux(templateList));

            } else if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                let agrTempList = getArrayParam(templateListObj['Agreement Template']);

                for (let i = 0; i < selectedRows.length; i++) {
                    let rowObj = selectedRows[i];
                    agrTempList = agrTempList.filter(f => f.id !== rowObj['id']);
                }
                sortArrayObjectByProperty(agrTempList, 'name');
                templateListObj['Agreement Template'] = agrTempList;
                templateList = new Array(templateListObj);
                dispatch(storeTemplateListInRedux(templateList));
            } else if (objectName === constants.MARKETING_TEMPLATE_OBJECT) {
                let categoryObj = {};
                for (let i = 0; i < selectedRows.length; i++) {
                    let rowObj = selectedRows[i];
                    categoryObj[rowObj['category']] = rowObj['category'];
                }
                Object.keys(categoryObj).map(key => {
                    let tempListBycat = selectedRows.filter(f => f.category === key);
                    for (let i = 0; i < tempListBycat.length; i++) {
                        let rowObj = tempListBycat[i];
                        let mTempList = getArrayParam(templateListObj[key]);
                        if (mTempList.length > 0) {
                            mTempList = mTempList.filter(f => f.id !== rowObj['id']);
                            sortArrayObjectByProperty(mTempList, 'name');
                            templateListObj[key] = mTempList;
                        }
                    }
                });
                templateList = new Array(templateListObj);
                dispatch(storeTemplateListInRedux(templateList));
            }
        }

    }

    const updateEmailTemplateStatus = (selectedRows, objectName, status) => {
        let templateList = getArrayParam(reduxSendMail.templateList);
        let isShare = true;
        if (templateList.length > 0) {
            let categoryNames = [];
            if (objectName === constants.SALES_TEMPLATE_OBJECT) {
                categoryNames.push('Sales Template');
                categoryNames.push('My Sales Template');
            } else if (objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                categoryNames.push('Agreement Template');
            } else if (objectName === constants.MARKETING_TEMPLATE_OBJECT) {
                categoryNames.push('Marketing Template');
                if (selectedRows[0].share === 0) {
                    isShare = false;
                }
            }
            let templateListObj = getObjectParam(templateList[0]);
            let templateId = selectedRows[0].id;
            for (let i = 0; i < categoryNames.length; i++) {
                let tempList = getArrayParam(templateListObj[categoryNames[i]]);
                let templateObj = tempList.filter(f => f.id === templateId);
                if (templateObj.length > 0) {
                    if (status === constants.ACTIVE && isShare) {
                        templateObj[0].email_status = status;
                    } else {
                        tempList = tempList.filter(f => f.id !== templateId);
                    }
                } else if (status === constants.ACTIVE && isShare) {
                    let editor_type;
                    if (objectName === constants.SALES_TEMPLATE_OBJECT || objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {
                        editor_type = "Legacy";
                    } else {
                        if (selectedRows[0].type === constants.EDITOR_NEW) {
                            editor_type = constants.EDITOR_BEE;
                        } else if (selectedRows[0].type === 'Legacy - Graphical') {
                            editor_type = constants.EDITOR_NEW;
                        } else {
                            editor_type = "Legacy";
                        }
                    }

                    let templateObj = {
                        email_status: status,
                        execution_time: null,
                        id: selectedRows[0].id,
                        name: selectedRows[0].title,
                        preview_image_url: "",
                        editor_type: editor_type,
                    };
                    tempList.push(templateObj);
                }
                sortArrayObjectByProperty(tempList, 'name');
                templateListObj[categoryNames[i]] = tempList;
            }

            templateList = new Array(templateListObj);
            dispatch(storeTemplateListInRedux(templateList));
        }

    }

    const doDeleteRecords = (objectName, params, selectedRows) => {

        const promise = deleteAction(objectName, params);
        promise.then(() => {
            if (props.object !== constants.ASSIGNMENT_RULES) {
                getRecentRecord(objectName);
            }

            if (objectName === constants.SALES_TEMPLATE_OBJECT || objectName === constants.MARKETING_TEMPLATE_OBJECT
                || objectName === constants.AGREEMENT_TEMPLATE_OBJECT) {

                updateEmailTemplateList(selectedRows, objectName);
            }

            if (props.isDetailView) {
                if (linkChildTableId === 0) {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.UNLINK, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                } else {
                    dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.UNLINK, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                }

                dispatch(detailViewIsMounted(false));
                dispatch(getTasksRefresh(false));
                dispatch(refreshDetailViewListViewData(true));
                dispatch(refreshDetailViewData(true));
                let _info = getActiveTabInfo();
                if (_info.selectedPageNo > 1) {
                    _info.selectedPageNo = 1;
                    updateTabInfo(_info);
                }
                if (reduxDetailView.data.object.toLowerCase() === constants.ACCOUNTS_OBJECT && props.object.toLowerCase() === constants.CONTACTS_OBJECT) {
                    dispatch(refreshDetailViewData(true));
                }
                if (reduxDetailView.data.object.toLowerCase() === constants.OPPORTUNITIES_OBJECT &&
                    (props.object === constants.ACCOUNTS_OBJECT || props.object === constants.PARTNERS_OBJECT || props.object === constants.CONTACTS_OBJECT)) {
                    dispatch(refreshDetailViewData(true));
                }
            } else {
                dispatch(showCustomSnackBar(getLocalizedStrings().message.COMMON.DELETE, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                if (props.object === 'accounts' && reduxAppContainer.customDrawerProps.actionType === "Convert" && reduxAppContainer.customDrawerProps.isMinimize === true && reduxAppContainer.customDrawerProps.isOpen === true) {
                    reduxAppContainer.customDrawerProps.actionType = "";
                    reduxAppContainer.customDrawerProps.data = {};
                    reduxAppContainer.customDrawerProps.minimizeList = { Convert: false };
                    reduxAppContainer.customDrawerProps.callFrom = null;
                    reduxAppContainer.customDrawerProps.isMinimize = false;
                    reduxAppContainer.customDrawerProps.isOpen = false;
                    reduxAppContainer.customDrawerProps.style = {};
                    reduxAppContainer.customDrawerProps.dialogName = '';
                    reduxAppContainer.customDrawerProps.minimizable = false;
                }
                if (props.object === 'accounts' && reduxAppContainer.customDrawerProps.isOpen === false && reduxAppContainer.customDrawerProps.minimizeList.Convert === true) {
                    reduxAppContainer.customDrawerProps.minimizeList = { Convert: false };
                }
                let tabInfo = getObjectParam(getActiveTabInfo());
                let params = null;
                if (tabInfo.hasOwnProperty('searchFieldName') && tabInfo.hasOwnProperty('searchText')) {
                    params = {
                        search_field_name: getStringParam(tabInfo.searchFieldName),
                        search_text: getStringParam(tabInfo.searchText)
                    }
                }


                if (props.object === constants.ASSIGNMENT_RULES) {
                    params = { ...params }

                    let sobject = getSelectedObject();
                    if (sobject === constants.EMAIL_MARKETING) {
                        sobject = constants.CONTACTS_OBJECT;
                    }
                    params.parent_object = sobject;
                }
                if (props.object === constants.SOURCE_OBJECT) {
                    let selectedRowTitle = getStringParam(props.listView.selectedRows[0].title);
                    [constants.ACCOUNTS_OBJECT, constants.CONTACTS_OBJECT].forEach(object => {

                        removeObjectListItemFromRedux(object, constants.SOURCE_OBJECT, selectedRowTitle);
                    });
                }
                if (props.object == constants.TASKSLIST_OBJECT) {
                    let title = props.listView.selectedRows[0].title;
                    removeObjectListItemFromRedux('tasks', "t_name", title);
                    let _recentformData = reduxSfForm.sfRecentData["tasks"];
                    let recentSelectedTask;
                    if (_recentformData != undefined) {
                        recentSelectedTask = _recentformData;
                    }
                    if (title == recentSelectedTask) {
                        clearSfRecentData()
                    }

                }
                if (props.object === constants.RECYCLE_BIN_OBJECT) {
                    let activeTab = getActiveTab();
                    activeTab = getObjectParam(activeTab);
                    let info = getObjectParam(activeTab.info);
                    delete info.selectedPageNo;
                    delete info.startPageNo;
                    delete info.startIndex;
                }
                let activeTab = getActiveTab();
                activeTab.info = { ...activeTab.info, rememberSelection: { ...activeTab.info.rememberSelection ?? {}, [objectName]: new Set() } };
                updateActiveTab(activeTab);
                refreshListView(props.object, null, null, suffix);
            }
        });
    }

    const generateAddActions = (object) => {
        let actions = [];
        let arrActions = [];
        let mapActions = {};
        if (object === constants.TASKS) {
            arrActions.push(
                constants.TASKS.charAt(0).toUpperCase() + constants.TASKS.slice(1)
            );
            mapActions[
                constants.TASKS.charAt(0).toUpperCase() + constants.TASKS.slice(1)
            ] = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASKS;
            if (isFranchise && (reduxApp.me.is_franchisor || (reduxApp.me.is_franchisor && reduxApp.me.is_franchisee))) {
                arrActions.push(constants.UNIT_TASKS);
                mapActions[constants.UNIT_TASKS] =
                    getLocalizedStrings().label.APPOINTMENTS_TASKS.UNIT_TASKS;
            }

            if (arrActions.length > 0) {
                if (isValidParam(arrActions)) {
                    arrActions.map((actionName, i) => {
                        let data = {};
                        data.id = `${i + 1}`;
                        data.label = mapActions[actionName];
                        data.value = actionName;
                        actions.push(data);
                    });
                }
                arrActions = actions;
            }

        } else {
            arrActions = [{ id: '1', value: 'Documents', icon: '', label: 'Documents' }, {
                id: '2',
                value: 'Agreements',
                icon: '',
                label: 'Agreements'
            }];
        }
        return arrActions;
    }

    const addTaskFilterDropDownAction = (value) => {
        if (value === 'Tasks' || value === constants.UNIT_TASKS) {
            add(constants.TASKS, value);
        }
    }

    const add = (objectName, value) => {
        let tabInfo = getActiveTabInfo();
        let isInXpress = getBooleanParam(tabInfo.isInXpress);
        let isUnitsAccount = false;
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }
        let hasPermission = hasAccessPermission(
            objectName,
            constants.SECURITY_LEVEL_TYPE_ACCESS,
            constants.ACCESS_TYPE_CREATE
        );
        if (hasPermission && !isInXpress && !isUnitsAccount) {
            if (objectName === constants.TASKS || objectName === constants.UNIT_TASKS) {
                let data = {
                    id: 0,
                    object: objectName,
                };
                let objName = "";
                if (objectName === constants.TASKS) {
                    objName = getLocalizedStrings().label.APPOINTMENTS_TASKS.TASK;
                    data.isFranchiseRecord = getBooleanParam(isFranchise);
                    data.selectedOpt = value;
                    data.parent_object = props.parentObject;
                    data.parent_record_id = props.detailViewParams.parent_record_id;
                    data.isDetailView = props.isDetailView;
                    if (value === constants.UNIT_TASKS) {
                        data.isUnitownerTasks = true;
                    }
                } else {
                    objName = objectName.charAt(0).toUpperCase() + objectName.slice(1);
                }
                let add = getLocalizedStrings().label.COMMON.ADD_NEW_APPOINTMENT
                let labelName = add + " " + objName;
                dispatch(
                    getAppDrawer(true, labelName, constants.NEW, data, constants.OTHER)
                );
            }
        } else {
            sfDialogs.alert(
                getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE,
                getLocalizedStrings().message.COMMON.ACCESS_DENIED,
                null
            );
        }
    };

    const addActionFn = (value) => {
        if (value !== null && value === 'Documents') {
            handleAction(constants.NEW);
        } else {
            let data = {};
            data.id = 0;
            data.mode = 'new';
            data.object = props.listView.data.parent_object;
            dispatch(getAppCustomDrawer(true, 'New Agreement', constants.AGREEMENT_OBJECT, { width: '83%' }, data, null, true));
        }
    }
    const selectedPage = (page) => {
        setPageNo(page);
    }

    const handleTaskForChange = (value) => {
        setSelectedTaskFor(value);
        const _queryName = value === 'Unit’s Tasks' ? 'Unit Tasks' : value;
        let _info = getObjectParam(getActiveTabInfo());
        _info.filter = { ..._info.filter, query_name: _queryName }
        updateTabInfo(_info);
        getListView(true);
    }

    const handleTaskStatusChange = (value) => {
        setSelectedTaskStatus(value)
        let _info = getObjectParam(getActiveTabInfo());
        let _searchText = _info.filter.search_text;
        if (_searchText.includes(",") && !_searchText.includes("undefined")) {
            _searchText = value.concat(",", _searchText.split(",")[1]);
        } else {
            _searchText = value;
        }
        _info.filter = { ..._info.filter, search_text: _searchText }
        updateTabInfo(_info);
        getListView(true);
    }

    const handleTaskTypeChange = (value) => {
        setSelectedTaskWorkflow(value);
        let _searchFieldname = "";
        let _searchText = "";
        let _searchType = ""
        if (value === "All") {
            _searchType = "anywhere"
            _searchText = selectedTaskStatus;
            _searchFieldname = "t_status"
        } else {
            _searchType = "exact"
            _searchText = selectedTaskStatus.concat(",", value);
            _searchFieldname = "t_status,task_type";
        }
        let _info = getObjectParam(getActiveTabInfo());
        _info.filter = {
            ..._info.filter,
            search_type: _searchType,
            search_text: _searchText,
            search_field_name: _searchFieldname,
        }
        updateTabInfo(_info);
        getListView(true);
    }

    const generateTaskTypeOptions = () => {
        let List = tasksTypeList;
        if (List !== undefined) {
            return List.map((item, i) => {
                return <MenuItem value={item.name} style={styles.popoverMenuItem} >{item.label}</MenuItem>;
            })
        }
    }

    const handleAuditSendMail = () => {
        let labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
        let selectedIds = [props.detailViewParams.parent_record_id];
        let object = props.object;
        openSendEmailDialog(object, selectedIds, labelName);
    }

    const handleFilterAudit = (value) => {
        let selectedFilterValue = value;
        dispatch(setSelectedAuditfilter(selectedFilterValue));
        let params = null;
        let tab = getActiveTab();
        let info = getObjectParam(tab.info);
        if (selectedFilterValue === 'All Audit Answers') {
            params = {
                "parent_object": props?.detailViewParams?.parent_object,
                "parent_record_id": props?.detailViewParams?.parent_record_id,
                "query_id": props?.detailViewParams?.query_id,
                "query_name": props?.detailViewParams?.query_name,
                "query_type": props?.detailViewParams?.query_type,
            }
            info.parent_object = props?.detailViewParams?.parent_object;
            info.parent_record_id = props?.detailViewParams?.parent_record_id;
            info.query_id = props?.detailViewParams?.query_id;
            info.query_name = props?.detailViewParams?.query_name;
            info.query_type = props?.detailViewParams?.query_type;
            info.group_by_condition = GroupBy_Condition;
            info.startIndex = 0;
        } else {
            params = {
                "parent_object": props?.detailViewParams?.parent_object,
                "parent_record_id": props?.detailViewParams?.parent_record_id,
                "query_name": "Show Feedbacks",
                "query_id": props?.detailViewParams?.query_id,
                // "callFrom": "AuditFilter",
                "query_type": "-1",
                "search_text": 1,
                "search_field_name": "corrective_action_required",
                "search_type": "exact"
            }
            info.parent_object = props?.detailViewParams?.parent_object;
            info.parent_record_id = props?.detailViewParams?.parent_record_id;
            info.query_id = props?.detailViewParams?.query_id;
            info.query_name = "Show Feedbacks";
            // info.callFrom = "AuditFilter";
            info.query_type = "-1";
            info.group_by_condition = GroupBy_Condition;
            info.startIndex = props?.detailViewParams?.query_id;
            info.search_text = 1;
            info.search_field_name = "corrective_action_required";
            info.search_type = "exact";
        }
        tab.info.selectedPageNo = 1;
        updateActiveTab(tab);
        updateTabInfo(info);
        props.listViewCallBack(props.object, params, true, selectedFilterValue)
    }

    const openPlanChartTab = () => {
        let activeTab = getActiveTab();
        let tabInfo = activeTab.info;
        let _object = tabInfo.object;
        let urlLink = `/${_object}/${constants.GANTT_CHART}`;
        let newTab = {
            imgName: "fa-solid fa-chart-gantt",
            object: _object,
            url: urlLink,
            type: TYPE_GANTT_CHART,
            label: `Gantt Chart  - ${activeTab.label}`,
            info: {
                ...tabInfo.filter,
                type: 'New',
                id: getIntParam(tabInfo?.id),
                object: _object,
                label: `Gantt Chart  - ${activeTab.label}`,
            }
        };
        addTab(newTab);
    }

    const partnerAdvanceSearch = (event) => {
        let dataValue = {
            maxWidth: 'lg'
        };
        dispatch(getAppDialog(true, constants.ADVANCE_PARTNE_RSEARCH, "Search Partners", null, dataValue));
    }
    const fnUpdateOnboardingTasks = () => {
        try {
            let record_id = 0;
            if (reduxDetailView.isDetailView) {
                let _record = getObjectParam(reduxDetailView.record);
                record_id = _record.id;
            } else {
                let activeTab = getActiveTab();
                let tabInfo = activeTab.info;
                record_id = getIntParam(tabInfo?.id);
            }
            let params = {};
            params.record_id = record_id;
            let promise = Promise.resolve(updateOnboardingTasks(params));
            promise.then((response) => {
                if (response.status == 0) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, "Tasks Due by and Expected Open Date updated successfully.", null);
                }
            });
        } catch (error) {
            console.error("Error in 'ListViewHeader.js ---> fnUpdateOnboardingTasks() '" + error);
        }
    }

    let isMounted = isValidParam(props.listView) ? getBooleanParam(props.listView.mounted) : false;
    let isLookup = getBooleanParam(props.isLookup);
    if (isMounted || isLookup) {
        let object = getStringParam(props.object);
        let isDetailView = getBooleanParam(props.isDetailView);
        let isCallFromDetailView = false;
        if (isDetailView) {
            isCallFromDetailView = true;
        }

        let listView = getObjectParam(props.listView.data);
        let totalRecordCount = getIntParam(listView.total_count);


        let isHideTooltip = false;

        if (object === constants.SOURCE_OBJECT || object === constants.TASKSLIST_OBJECT || object === constants.ROLL_UP_OBJECT
            || object === constants.NOTE_TYPES_OBJECT || props.object === constants.APPOINTMENT_TYPES_OBJECT
            || object === constants.CAMPAIGN_TYPES_OBJECT || object === constants.RELATIONS_OBJECT
            || object === constants.LIBRARY_OBJECT || object === constants.ROLL_UP_OBJECT
        ) {
            isHideTooltip = true;
        }

        let groupByImagePath = reduxIsShowGroupBy ? '/asset/images/close.jpg' : '/asset/images/open.jpg';
        let groupByImageTooltip = reduxIsShowGroupBy ? getLocalizedStrings().label.LIST_VIEW.CLICK_TO_COLLAPSE : getLocalizedStrings().label.LIST_VIEW.CLICK_TO_EXPAND;
        let showTaskFor = false;
        let showTaskStatus = false;
        let showTaskWorkflow = false;
        let showExpand = false;
        let showSMSButton = false;
        let showCallButton = false;
        let showAddButton = true;
        let showEmailButton = false;
        let showLinkLookupIcon = false;
        let showEditButton = totalRecordCount > 0;
        let showMoreButton = totalRecordCount > 0;
        let showPaginetion = totalRecordCount > 0;
        let showCopyButton = false;
        let isShowAttachementDrpdn = false;
        let isShowAddDrpdnInTasks = false;
        let queryName = getStringParam(listView.query_name);
        let copyOpacity = 1;
        let editButtonOpacity = 1;
        let emptyButtonOpacity = 1;
        let moreButtonOpacity = 1;
        let selectedRows = props.listView.selectedRows;
        selectedRows = isValidParam(selectedRows) ? selectedRows : [];
        let selectedIds = selectedRows.map(row => row.id);
        let listViewHeaderButtonStyle = { ...styles.listViewHeaderButtonStyle };

        if (totalRecordCount > 0 && (object === constants.ACCOUNTS_OBJECT || object === constants.PARTNERS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.UNITS_OBJECT)) {
            showEmailButton = true;
        }
        if (totalRecordCount > 0 && (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.UNITS_OBJECT || object === constants.PARTNERS_OBJECT)) {
            showSMSButton = true;
        }
        if (totalRecordCount > 0 && (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.PARTNERS_OBJECT)) {
            showCallButton = true;
        }
        if (totalRecordCount > 0 && isCallFromDetailView === false && (object === constants.MARKETING_TEMPLATE_OBJECT || object === constants.SALES_TEMPLATE_OBJECT || object === constants.AGREEMENT_TEMPLATE_OBJECT
            || object === constants.BROADCAST_CAMPAIGNS_OBJECT || object === constants.AB_CAMPAIGNS_OBJECT ||
            object === constants.AUTOMATION_DESIGNER_OBJECT || object === constants.WEB_FORMS || object === constants.LANDING_PAGES_FROALA_OBJECT
            || object === constants.WORKFLOW_OBJECT || object === constants.CALL_SCRIPT_OBJECT || object === constants.SMS_TEMPLATE_OBJECT
            || object === constants.ROYALTY_PLANS_OBJECT || object === constants.ASSIGNMENT_RULES_OBJECT)) {
            showCopyButton = true;
        }

        if (isReport && showEmailButton && showSMSButton && showCallButton) {
            let activeTabInfo = getObjectParam(getActiveTabInfo());
            let reportType = getStringParam(activeTabInfo.reportType);
            if (reportType === constants.AUTO_RESPONDER_REPORT_TYPE) {
                showEmailButton = false;
                showSMSButton = false;
                showCallButton = false;
            }
        }

        if (isCallFromDetailView) {
            if (object === constants.ATTACHMENT_OBJECT && totalRecordCount > 0
                && props.parentObject !== constants.ISSUES_OBJECT && props.parentObject !== constants.SOLUTIONS_OBJECT
                && props.parentObject !== constants.COMPLIANCE_OBJECT && props.parentObject !== constants.TASKS
            ) {
                showEmailButton = true;
                showSMSButton = false;
            } else {
                showEmailButton = false;
                showSMSButton = false;
                showCallButton = false;
            }
            showEditButton = false;
            listViewHeaderButtonStyle.marginRight = isDetailView && object === constants.TASKS ? '5px' : '10px';
            if (!isChildObjectAdd) {
                showAddButton = false;
            }
        }

        if (object === constants.GROUP_OBJECT || object === constants.INVOICES_OBJECT || object === constants.PHONE_NUMBERS_OBJECT) {
            showAddButton = false;
        }
        let addAction = null;
        if (isCallFromDetailView && object === constants.ATTACHMENT_OBJECT
            && (props.parentObject === constants.ACCOUNTS_OBJECT || props.parentObject === constants.CONTACTS_OBJECT)
        ) {
            isShowAttachementDrpdn = true;
            addAction = generateAddActions();
        }
        if (isCallFromDetailView && (object === constants.TASKS && (props.parentObject === constants.ACCOUNTS_OBJECT || props.parentObject === constants.PARTNERS_OBJECT))) {
            isShowAddDrpdnInTasks = true;
            addAction = generateAddActions(object);
        }
        if (selectedIds.length > 1) {
            if (object === constants.SALES_TEMPLATE_OBJECT || object === constants.MARKETING_TEMPLATE_OBJECT || object === constants.AUTOMATION_DESIGNER_OBJECT || object === constants.WEB_FORMS || object === constants.TOUCH_CAMPAIGNS_OBJECT
                || object === constants.WORKFLOW_OBJECT || object === constants.SMS_TEMPLATE_OBJECT || object === constants.CALL_SCRIPT_OBJECT || object === constants.AGREEMENT_TEMPLATE_OBJECT
                || object === constants.COMPLIANCE_OBJECT) {
                editButtonOpacity = 0.5;
                copyOpacity = 0.5;
            } else if (isChildObjectCopy) {
                copyOpacity = 0.5;
            } else if (object === constants.BROADCAST_CAMPAIGNS_OBJECT || object === constants.AB_CAMPAIGNS_OBJECT) {

            }
        }
        if (selectedIds.length > 0 && object === constants.RECYCLE_BIN_OBJECT) {
            emptyButtonOpacity = 0.5;
        }
        let objectLabel = '';
        let tempisChildObjectFindAndLink = isChildObjectFindAndLink;
        if (tempisChildObjectFindAndLink) {
            objectLabel = getObjectLabelByObject(object);
        }

        let showEmailButtonStyle = (props.object === constants.ATTACHMENT_OBJECT && getBooleanParam(props.isDetailView)) ? '-10px' : '0px';
        let sendEmailTitle = (props.object === constants.ATTACHMENT_OBJECT && getBooleanParam(props.isDetailView)) ? getLocalizedStrings().label.COMMON.SEND_EMAIL_ATTACHMENT : getLocalizedStrings().label.COMMON.SEND_EMAIL;

        let showQueueButton = false;

        if (!isLookup && !isReport && (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT
            || object === constants.CASES_OBJECT || object === constants.PROJECTS_OBJECT || object === constants.ISSUES_OBJECT || object === constants.CUSTOM_TABLE1_OBJECT
            || object === constants.CUSTOM_TABLE2_OBJECT || object === constants.CUSTOM_TABLE3_OBJECT || object === constants.CUSTOM_TABLE4_OBJECT
            || object === constants.APPOINTMENTS || object === constants.TASKS)) {
            if (isValidParam(reduxDetailView.isDetailView) && props.isDetailView) {
                showQueueButton = false;
            } else {
                showQueueButton = true;
            }
        }

        let selectedRowLength = isValidParam(props.listView.selectedRows) ? props.listView.selectedRows.length : 0;

        let isDisable = selectedRowLength === 0;
        if (selectedRowLength > 1 && object === constants.UNITS_OBJECT) {
            isDisable = true;
        }
        let isSearchable = true;
        let showSearchField = false;
        if (isLookup) {
            showEmailButton = false;
            showEditButton = false;
            showMoreButton = false;
            showCopyButton = false;
            showSearchField = true;
            showSMSButton = false;
            showCallButton = false;
        }

        if (object === constants.GROUP_OBJECT || object === constants.INVOICES_OBJECT || object === constants.PHONE_NUMBERS_OBJECT) {
            showAddButton = false;
        }
        if (object === constants.TASKS) {
            showAddButton = true;
        }

        if (object === constants.INVOICES_OBJECT || object === constants.EVENTS_OBJECT) {
            tempisChildObjectFindAndLink = false;
            showMoreButton = false;
        }

        if (object === constants.COUPONS_OBJECT || object === constants.CLAIMS_OBJECT) {
            tempisChildObjectFindAndLink = false;
            showMoreButton = false;
            showEditButton = false;
        }
        if (object === constants.SECURITY_LEVEL_OBJECT || object === constants.LINE_ITEMS_OBJECT || object === constants.PHONE_NUMBERS_OBJECT) {
            showMoreButton = false;
        }

        if (reduxApp.selectedModuleName === constants.MODULE_HUB) {
            showEmailButton = false;
            showEditButton = false;
            showMoreButton = false;
            showCopyButton = false;
            showSearchField = false;
            showSMSButton = false;
            showCallButton = false;
            if (object === constants.ATTACHMENT_OBJECT) {
                showMoreButton = true;
            } else if (object === constants.WORKFLOW_OBJECT) {
                showEditButton = true;
                showMoreButton = true;
                showCopyButton = true;
            } else if (object === constants.ASSIGNMENT_RULES_OBJECT) {
                showEditButton = true;
                showMoreButton = true;
                showCopyButton = true;
            } else if (totalRecordCount > 0 && object === constants.ROLL_UP_OBJECT) {
                showEditButton = true;
                showMoreButton = true;
            } else if (totalRecordCount > 0 && object === constants.REPORTED_SALES_OBJECT) {
                showEditButton = true;
                showMoreButton = true;
            } else if (totalRecordCount > 0 && (object === constants.LOCATION_OBJECT
                || object === constants.TASKSLIST_OBJECT)) {
                showEditButton = true;
                showMoreButton = true;
            } else if (totalRecordCount > 0 && object === constants.LMS_USERS_OBJECT) {
                showEditButton = true;
                showMoreButton = true;
            } else if (totalRecordCount > 0 && object === constants.WEBHOOKS_OBJECT) {
                showEditButton = true;
                showMoreButton = true;
            } else if (totalRecordCount > 0 && object === constants.TEMPLATE_CATEGORIES) {
                showEditButton = true;
                showMoreButton = true;
            } else if (totalRecordCount > 0 && object === constants.RECYCLE_BIN_OBJECT){
                showMoreButton = true;
            }
        }
        if (object === constants.RECYCLE_BIN_OBJECT || object === constants.UNITS_OBJECT || object === constants.ORDERS_OBJECTS || object === constants.FIND_DUPLICATE_OBJECT
            || object === constants.QUOTATION_OBJECT || object === constants.PHONE_NUMBERS_OBJECT || object === constants.AUDIT_ANSWERS_OBJECT || object === constants.NOTE_TYPES_OBJECT) {
            showEditButton = false;
        }
        if (object === constants.AUDIT_QUESTIONS_OBJECT) {
            showEditButton = false
            showMoreButton = false
        }
        if (object === constants.AUDIT_CHECKLISTS_OBJECT) {
            showEditButton = false
            showMoreButton = false
        }
        let tabInfo = getActiveTabInfo();
        if (tabInfo.hasOwnProperty('isUnitsListView') && tabInfo.isUnitsListView) {
            showEditButton = false;
            showQueueButton = false;
            showEmailButton = false;
            showSMSButton = false;
            showCallButton = false;
            showMoreButton = false;

        }
        if (props.suffix === constants.TASK_LIST) {
            showQueueButton = false;
        }

        let isIconShow = getArrayParam(props.listView.data.records);
        let themeColor = localStorage.getItem("background");

        if (tabInfo.hasOwnProperty('filterConfig')) {
            showTaskFor = tabInfo?.filterConfig?.showTaskFor || false;
            showTaskStatus = tabInfo?.filterConfig?.showTaskStatus || false;
            showTaskWorkflow = tabInfo?.filterConfig?.showTaskWorkflow || false;
            showEditButton = tabInfo?.filterConfig?.showEditButton || false;
        };
        if (props.object === constants.TASKS && props.parentObject === constants.AUDITS_OBJECT) {
            showTaskFor = false;
            showEditButton = false;
            showTaskWorkflow = false;
            showTaskStatus = tabInfo?.filterConfig?.showTaskStatus || false;
        }
        showExpand = tabInfo?.showExpand || false;
        if (props.object === constants.ROYALTY_OBJECT) {
            showMoreButton = false;
        }
        if ((object === constants.ROYALTY_OBJECT || object === constants.COMPLIANCE_OBJECT) && isReport) {
            showMoreButton = false;
        }
        if (object === constants.AUDITS_OBJECT) {
            showEditButton = false
            showMoreButton = false;
        }
        if (object === constants.ROYALTY_RUN_OBJECT) {
            showEditButton = false
        }
        if (object === constants.AUDITS_OBJECT) {
            showPaginetion = true;
        }
        if (object === constants.AUDIT_ANSWERS_OBJECT && reduxApp.me.is_franchisee && totalRecordCount > 0) {
            showMoreButton = true;
        }

        if (object === constants.AUDIT_QUESTIONS_OBJECT || object === constants.ROYALTY_RUN_OBJECT) {
            showSearchField = false;
            isSearchable = false;
            showPaginetion = totalRecordCount > 0;
        }
        if (object === constants.TASKS && props.suffix === constants.TASK_LIST) {
            showSearchField = false;
        }
        if (!reduxApp.isNewTree && props.object === constants.UNITS_OBJECT) {
            showPaginetion = false;
            isSearchable = false;
        }
        if (INTAKE_FORM.TENENT_PROJECTS_ID.includes(reduxApp.me.projectId) && (props.object === constants.CUSTOM_TABLE1_OBJECT && totalRecordCount > 0)) {
            showAddButton = false;
        }

        if ((tempisChildObjectFindAndLink && !isRelationshipTabExist && !isQuatationTabExist)) {
            if (props.object === constants.CUSTOM_TABLE1_OBJECT && INTAKE_FORM.TENENT_PROJECTS_ID.includes(reduxApp.me.projectId)) {
                showLinkLookupIcon = false
            } else {
                showLinkLookupIcon = true
            }
        }

        let showContactBtn = true;
        if (reduxApp.me.is_franchisee && props.parentObject === constants.PARTNERS_OBJECT && object === constants.CONTACTS_OBJECT) {
            showContactBtn = false;
        }

        let showRSButton = false;
        if (props.parentObject === constants.ROYALTY_RUN_OBJECT && object === constants.REPORTED_SALES_OBJECT
            && props.isDetailView === true) {
            showRSButton = reduxDetailView.data.show_buttons;
        }

        let showCalculateButton = false;
        if (props.parentObject === constants.ROYALTY_RUN_OBJECT && object === constants.REPORTED_SALES_OBJECT
            && props.isDetailView === true && totalRecordCount > 0) {
            showCalculateButton = reduxDetailView.data.show_buttons;
        }

        let phoneTextProvider = reduxApp.me.phone_text_provider ?? constants.NONE;
        if (phoneTextProvider == constants.NONE) {
            showSMSButton = false;
            showCallButton = false;
        }
        if (object == constants.CONTACTS_OBJECT && reduxApp.me.is_franchisee && props.listView.data.hasOwnProperty("doNotShowAction") && props.listView.data?.doNotShowAction) {
            showEditButton = false;
            showQueueButton = false;
            showSMSButton = false;
            showCallButton = false;
            showMoreButton = false;
        }
        if (totalRecordCount > 0) {
            if (isFranchise || (props.suffix === constants.TASK_LIST)) {
                if (selectedTaskFor !== null && (selectedTaskFor == 'All Tasks' || selectedTaskFor == 'Unit’s Tasks')) {
                    showMoreButton = false;
                } else {
                    showMoreButton = true;
                }
            }
        }
        if (props.object === constants.TASKS && props.parentObject === constants.AUDITS_OBJECT && reduxApp.me.is_franchisor) {
            showMoreButton = false;
        } else if (props.object === constants.TASKS && props.parentObject === constants.AUDITS_OBJECT && reduxApp.me.is_franchisee && totalRecordCount > 0) {
            showMoreButton = true;
        }
        if (props.object === constants.SOURCE_OBJECT) {
            if (totalRecordCount > 0) {
                showMoreButton = true;
                showEditButton = true;
            }
            showSMSButton = false;

        }
        if (props.object === constants.PARTNERS_OBJECT && reduxApp.me.is_franchisee) {
            if (totalRecordCount > 0) {
                showMoreButton = true;
                showEmailButton = false;
                showEditButton = false;
            }
        }

        if(props.parentObject === constants.PARTNERS_OBJECT && isCallFromDetailView && reduxApp.me.is_franchisee){
            showMoreButton = false; // for childListView
        } 

       if(isLookup && reduxDetailView.isDetailView && reduxDetailView.data?.is_refer_to_zors){
            showMoreButton = false
        }
        
        let searchDivStyle = {
            ...styles.togButton,
            cursor: 'default',
            height: '27px',
            border: '1px solid #e0e0e0',
            backgroundColor: '#ffffff',
            display: 'flex'
        }
        let searchTextFieldDivStyle = {
            display: 'inline-block',
            verticalAlign: 'top',
            backgroundColor: 'fff',
            height: '25px',
            width: '100px',
            float: 'right'
        }
        let searchTextFieldStyle = {
            backgroundColor: '#fff',
            paddingLeft: '22px',
            paddingRight: '0px',
            border: 'none',
            height: '23px',
            outline: '0 none',
            width: '80px',
            color: '#888888',
            opacity: '0.3'
        }
        let searchTextPlaceHolder = getLocalizedStrings().label.COMMON.SEARCH + "...";
        if (isSearchExist) {
            searchDivStyle.backgroundColor = 'transparent';
            searchTextFieldDivStyle.width = '249px';
            searchTextFieldStyle.width = '220px';
            searchTextFieldStyle.opacity = '0.6';
            searchTextPlaceHolder = getLocalizedStrings().label.COMMON.SEARCH_PLACEHOLDER;
            if (reduxApp.me.language === "de-DE") {
                searchTextFieldDivStyle.width = '423px';
                searchTextFieldStyle.width = '397px';
            }
        }
        let activeTab = getActiveTab();

        let isFindAndLink = getBooleanParam(props.isFindAndLink);
        let isSubmittedRR = getBooleanParam(reduxDetailView.data?.is_submitted);

       let isShowAddorLinkBtn = true

         if(props.parentObject === constants.ACCOUNTS_OBJECT && props.object ===constants.CUSTOM_TABLE1_OBJECT && constants.INDUSTRY_TYPE_FRANNET_BASE.includes(reduxApp.me.industry_type)){
            isShowAddorLinkBtn = false
         }
        return (
            <div style={{ display: 'flex', minHeight:'35px' }}>
                <iframe title={"ifmcontentstoprint"} id="ifmcontentstoprint"
                    style={{ height: '0px', width: '0px', position: 'absolute', display: 'none' }} />
                <div className='clearfix' style={{ marginLeft: isCallFromDetailView || isLookup ? '0px' : '10px', marginTop: isLookup || isCallFromDetailView ? '6px' : '0px', width: isLookup || isCallFromDetailView ? '100%' : '' }}>
                    {totalRecordCount > 0 && !isLookup && !isDetailView && !isReport && !isHideTooltip && object !== constants.RECYCLE_BIN_OBJECT && object !== constants.DOWNLOAD_LEADS_OBJECT && object !== constants.FIND_DUPLICATE_OBJECT && object !== constants.LOCATION_OBJECT &&
                        <div title={groupByImageTooltip} style={styles.togButton}>
                            <img src={groupByImagePath} onClick={() => groupByShowHideFn()} />
                        </div>}
                    {object === constants.DOWNLOAD_LEADS_OBJECT &&
                        <div title={groupByImageTooltip} style={styles.togButton}>
                            <img src={groupByImagePath} onClick={() => searchFieldShowHide()} />
                        </div>}
                    {(isCallFromDetailView && showAddButton) && showContactBtn &&
                        <div style={listViewHeaderButtonStyle}>
                            {(!isRelationshipTabExist && !isShowAttachementDrpdn) && (object !== constants.TASKS && childObjectInfo.objectName !== constants.TASKS) && isShowAddorLinkBtn &&  (
                                <Button
                                    startIcon={<i className="fa fa-plus" style={{ color: '#717171', fontSize: '14px' }} />}
                                    onClick={() => handleAction(constants.NEW)}
                                    style={{ ...styles.secondaryButton, width: '88px' }}
                                >{getLocalizedStrings().label.COMMON.ADD}</Button>
                            )}

                            {isCallFromDetailView && object === constants.TASKS && childObjectInfo.objectName === constants.TASKS && isShowAddDrpdnInTasks && (
                                <div>
                                    <PopOver
                                        id={"add"}
                                        key={"add"}
                                        name={"add"}
                                        buttonEndIcon={"keyboard_arrow_down"}
                                        buttonStyle={{ ...styles.secondaryButton, marginRight: '0px' }}
                                        labelposition={"before"}
                                        buttonLabel={getLocalizedStrings().label.COMMON.ADD}
                                        options={addAction}
                                        onclickAction={(value) => addTaskFilterDropDownAction(value)}
                                        isShowIcon={false}
                                        title={getLocalizedStrings().label.COMMON.ADD}
                                        value={""}
                                    />
                                </div>
                            )}

                            {isRelationshipTabExist && !isShowAttachementDrpdn && (
                                <Button
                                    startIcon={<i className="fa fa-plus" style={{ color: '#717171', fontSize: '14px' }} />}
                                    onClick={() => openRelationshipDrawer()}
                                    style={{ ...styles.secondaryButton, width: '88px' }}
                                >
                                    {getLocalizedStrings().label.COMMON.ADD}
                                </Button>
                            )}

                            {
                                isShowAttachementDrpdn && <div>
                                    <PopOver
                                        id={"PopOver"}
                                        key={"PopOver"}
                                        buttonEndIcon={"keyboard_arrow_down"}
                                        buttonStyle={styles.secondaryButton}
                                        labelposition={"before"}
                                        buttonLabel={getLocalizedStrings().label.COMMON.ADD}
                                        options={addAction}
                                        onclickAction={(value) => addActionFn(value)}
                                    />
                                </div>}
                        </div>
                    }
                    {showTaskFor && (
                        <div style={{ ...listViewHeaderButtonStyle, marginRight: '5px' }} title={selectedTaskFor}>
                            <FormControl letiant="outlined">
                                <Select
                                    key="sort-by"
                                    name="task-type"
                                    labelId='sort-by-label'
                                    id='datetime'
                                    value={selectedTaskFor}
                                    size="small"
                                    sx={{ height: '28px', width: '100px' }}
                                    className={"sf-select"}
                                    onChange={(event) => handleTaskForChange(event.target.value)}
                                >
                                    {generateTaskTypeOptions()}
                                </Select>
                            </FormControl>
                        </div>
                    )
                    }
                    {showTaskStatus && (
                        <div style={{ ...listViewHeaderButtonStyle, marginRight: '5px' }} title={selectedTaskStatus}>
                            <FormControl letiant="outlined">
                                <Select
                                    key="sort-by"
                                    name="task-type"
                                    labelId='sort-by-label'
                                    id='datetime'
                                    value={selectedTaskStatus}
                                    size="small"
                                    sx={{ height: '28px', width: '100px' }}
                                    className={"sf-select"}
                                    onChange={(event) => handleTaskStatusChange(event.target.value)}
                                >
                                    {getTaskStatusFilter()}
                                </Select>
                            </FormControl>
                        </div>
                    )
                    }
                    {showTaskWorkflow && (
                        <div style={{ ...listViewHeaderButtonStyle, marginRight: '5px' }} title={selectedTaskWorkflow}>
                            <FormControl letiant="outlined">
                                <Select
                                    key="sort-by"
                                    name="task-type"
                                    labelId='sort-by-label'
                                    id='datetime'
                                    value={selectedTaskWorkflow}
                                    size="small"
                                    sx={{ height: '28px', width: '80px' }}
                                    className={"sf-select"}
                                    onChange={(event) => handleTaskTypeChange(event.target.value)}
                                >
                                    {getTaskTypeFilter()}
                                </Select>
                            </FormControl>
                        </div>
                    )
                    }
                    {showTaskStatus && props.parentObject === constants.ACCOUNTS_OBJECT && (<div title='Update'
                        style={{
                            ...styles.togButton, backgroundColor: '#ffffff',
                            height: '27px', border: '1px solid rgb(224,224,224)'
                        }}
                        onClick={() => fnUpdateOnboardingTasks()}>
                        <span style={{
                            paddingTop: '2px',
                            paddingLeft: '9px', fontSize: '14px',
                            lineHeight: '22px', paddingRight: '9px'
                        }}>Update</span>
                    </div>
                    )}
                    {object === constants.INVOICES_OBJECT &&
                        <ListViewFilterDropDown object={object} queryName={queryName} isDetailView={isDetailView} />}

                    {isChildObjectCopy && totalRecordCount > 0 && !isRelationshipTabExist && !isQuatationTabExist && showContactBtn &&
                        <div onClick={() => handleAction(constants.COPY)} style={{
                            ...listViewHeaderButtonStyle,
                            backgroundColor: '#ffffff',
                            height: '27px',
                            width: '28px',
                            border: '1px solid rgb(224,224,224)',
                            opacity: copyOpacity
                        }}>
                            <i className="material-icons"
                                title={getLocalizedStrings().label.COMMON.COPY + ' ' + objectLabel} style={{
                                    fontSize: '18px',
                                    color: '#717171',
                                    cursor: 'pointer',
                                    paddingTop: '3px',
                                    paddingLeft: '5px'
                                }}>content_copy</i>
                        </div>
                    }

                    {showLinkLookupIcon && showContactBtn && isShowAddorLinkBtn &&
                        <div onClick={() => openLookupDialog()} style={{
                            ...listViewHeaderButtonStyle,
                            backgroundColor: '#ffffff',
                            height: '27px',
                            width: '28px',
                            border: '1px solid rgb(224,224,224)'
                        }}>
                            <i className="material-icons"
                                title={getLocalizedStrings().label.LIST_VIEW.LINK_EXISTING + ' ' + objectLabel} style={{
                                    transform: 'rotate(-38deg)',
                                    fontSize: '18px',
                                    color: '#717171',
                                    cursor: 'pointer',
                                    paddingTop: '5px',
                                    paddingLeft: '1px'
                                }}>link</i>
                        </div>
                    }

                    {
                        (showEditButton) && (!isReport) &&
                        <div title={getLocalizedStrings().label.COMMON.EDIT}
                            style={{
                                ...styles.togButton,
                                backgroundColor: '#ffffff',
                                height: '27px',
                                width: '28px',
                                border: '1px solid rgb(224,224,224)'
                            }}
                            onClick={() => handleAction(constants.EDIT)}>
                            <Icon style={{
                                fontSize: '20px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '2.5px',
                                paddingLeft: '2.5px',
                                opacity: editButtonOpacity
                            }}>edit</Icon>
                        </div>
                    }

                    {(showEmailButton) &&
                        <div title={sendEmailTitle}
                            style={{
                                ...styles.togButton,
                                backgroundColor: '#ffffff',
                                height: '27px',
                                marginLeft: showEmailButtonStyle,
                                width: '28px',
                                border: '1px solid rgb(224,224,224)'
                            }}
                            onClick={() => handleSendMailAction(constants.SEND_EMAIL)}>
                            <i className="fa fa-paper-plane" aria-hidden="true" style={{
                                fontSize: '16px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '4px',
                                paddingLeft: '5px'
                            }} />
                        </div>
                    }

                    {(showSMSButton) &&
                        <div title={getLocalizedStrings().label.COMMON.SEND_TEXT_MESSAGE}
                            style={{
                                ...styles.togButton,
                                backgroundColor: '#ffffff',
                                height: '27px',
                                width: '28px',
                                border: '1px solid rgb(224,224,224)'
                            }}
                            onClick={() => handleAction(constants.SEND_SMS)}>
                            <Icon style={{
                                fontSize: '19px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '2.5px',
                                paddingLeft: '2.7px'
                            }}>textsms</Icon>
                        </div>
                    }

                    {getStringParam(props.object) === 'units' && isIconShow?.length > 0 &&
                        <div title={getLocalizedStrings().label.COMMON.ADD_TASK}
                            style={{
                                ...styles.togButton,
                                backgroundColor: '#ffffff',
                                height: '27px',
                                width: '28px',
                                border: '1px solid rgb(224,224,224)'
                            }}
                            onClick={() => handleAction(constants.ADD_BATCH_TASK)}>
                            <Icon style={{
                                fontSize: '19px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '2.5px',
                                paddingLeft: '2.7px'
                            }}>task</Icon>
                        </div>
                    }
                    {(showCallButton) && isTwilio && !reduxPowerCalling.callDrawerOpend &&
                        <div title={getLocalizedStrings().label.TWILIO.CALL} style={{
                            ...styles.togButton,
                            backgroundColor: '#ffffff',
                            height: '27px',
                            width: '28px',
                            border: '1px solid rgb(224,224,224)'
                        }} onClick={() => handleAction(constants.CALL)}>
                            <Icon style={{
                                fontSize: '18px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '2.5px',
                                paddingLeft: '2.4px'
                            }}>call</Icon>
                        </div>
                    }
                    {(showCallButton) && isTwilio && reduxPowerCalling.callDrawerOpend &&
                        <div title={getLocalizedStrings().label.TWILIO.CALL} style={{
                            ...styles.togButton,
                            backgroundColor: '#ffffff',
                            height: '27px',
                            width: '28px',
                            border: '1px solid rgb(224,224,224)'
                        }}>
                            <Icon style={{
                                fontSize: '18px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '2.5px',
                                paddingLeft: '2.4px'
                            }}>call</Icon>
                        </div>
                    }

                    {totalRecordCount > 0 && showQueueButton &&
                        <div title={getLocalizedStrings().label.QUEUE.QUEUE}
                            style={{
                                ...styles.togButton,
                                backgroundColor: '#ffffff',
                                height: '27px',
                                marginLeft: showEmailButtonStyle,
                                width: '28px',
                                border: '1px solid rgb(224,224,224)'
                            }}
                            onClick={() => handleAction(constants.QUEUE)}>
                            <i className="fas fa-play" aria-hidden="true" style={{
                                fontSize: '13px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '6px',
                                paddingLeft: '7px',
                                opacity: ' 0.8'
                            }} />
                        </div>
                    }
                    {totalRecordCount > 0 && object === constants.AUDIT_ANSWERS_OBJECT &&
                        <div title={getLocalizedStrings().label.COMMON.PRINT}
                            style={{
                                ...styles.togButton,
                                backgroundColor: '#ffffff',
                                height: '27px',
                                marginLeft: showEmailButtonStyle,
                                width: '28px',
                                border: '1px solid rgb(224,224,224)'
                            }}
                            onClick={() => handleAction(constants.PRINT)}>
                            <i className="fas fa-print" aria-hidden="true" style={{
                                fontSize: '13px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '6px',
                                paddingLeft: '7px',
                                opacity: ' 0.8'
                            }} />
                        </div>
                    }
                    {
                        totalRecordCount > 0 &&
                        object === constants.AUDIT_ANSWERS_OBJECT &&
                        <div title={reduxApp.me.is_franchisee ? getLocalizedStrings().label.AUDIT_ANSWERS.SUBMIT_CORRECTIVE_ACTIONS : getLocalizedStrings().label.AUDIT_ANSWERS.REQUEST_CORRECTIVE_ACTIONS}
                            style={{
                                ...styles.togButton,
                                backgroundColor: '#ffffff',
                                height: '27px',
                                marginLeft: showEmailButtonStyle,
                                width: '28px',
                                border: '1px solid rgb(224,224,224)'
                            }}
                            onClick={() => handleAuditSendMail()}>
                            <i className="fa fa-paper-plane" aria-hidden="true" style={{
                                fontSize: '16px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '4px',
                                paddingLeft: '5px'
                            }} />
                        </div>
                    }
                    {(totalRecordCount > 0 && FilterMenuItems.length > 0) && object === constants.AUDIT_ANSWERS_OBJECT && props.isDetailView &&
                        <FormControl variant="filled" sx={{ marginLeft: reduxApp.me.is_franchisor ? '-10px' : '', maxWidth: 150, minWidth: 150, '& .MuiSelect-select': { paddingTop: '3px', paddingBottom: '3px' } }}>
                            <Select
                                variant="outlined"
                                id={"user"}
                                key={"user"}
                                name={"user"}
                                value={selectedFilter}
                                title={selectedFilter}
                                IconComponent={() => (
                                    <ArrowDropDown
                                        style={{
                                            position: 'absolute',
                                            top: '2',
                                            right: '10',
                                            width: '24px',
                                            height: '24px',
                                            pointerEvents: 'none'
                                        }}
                                    />
                                )}
                                onChange={(e) => handleFilterAudit(e.target.value)}
                                sx={{ p: 0 }}
                            >
                                {FilterMenuItems.map((item, index) => {
                                    return (<MenuItem
                                        key={item.value}
                                        value={item.label}
                                        style={styles.popoverMenuItem} >{item.value}</MenuItem>
                                    );
                                })
                                }
                            </Select>
                        </FormControl>
                    }
                    {totalRecordCount > 0 && object === constants.COMPLIANCE_OBJECT && isReport &&
                        <div title={getLocalizedStrings().label.COMMON.TO_EXCEL}
                            style={{
                                ...styles.togButton,
                                backgroundColor: '#ffffff',
                                height: '27px',
                                marginLeft: showEmailButtonStyle,
                                width: '28px',
                                border: '1px solid rgb(224,224,224)'
                            }}
                            onClick={() => handleAction(constants.TO_EXCEL)}>
                            <i className="far fa-file-excel" aria-hidden="true" style={{
                                fontSize: '13px',
                                color: 'green',
                                cursor: 'pointer',
                                paddingTop: '6px',
                                paddingLeft: '7px',
                                opacity: ' 0.8'
                            }} />
                        </div>
                    }
                    {totalRecordCount > 0 && object === constants.AUDIT_QUESTIONS_OBJECT &&
                        <div title={getLocalizedStrings().label.COMMON.DELETE}
                            style={{
                                ...styles.togButton,
                                backgroundColor: '#ffffff',
                                height: '27px',
                                marginLeft: showEmailButtonStyle,
                                width: '28px',
                                border: '1px solid rgb(224,224,224)'
                            }}
                            onClick={() => handleAction(constants.DELETE)}>
                            <i className="fas fa-trash" aria-hidden="true" style={{
                                fontSize: '13px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '6px',
                                paddingLeft: '7px',
                                opacity: ' 0.8'
                            }} />
                        </div>
                    }
                    {totalRecordCount > 0 && (props.object === constants.RECYCLE_BIN_OBJECT) &&
                        <div title={getLocalizedStrings().label.COMMON.EMPTY}
                            style={{
                                ...styles.togButton,
                                backgroundColor: '#ffffff',
                                height: '27px',
                                width: '28px',
                                border: '1px solid rgb(224,224,224)'
                            }}
                            onClick={() => handleAction(constants.EMPTY)}>
                            <Icon style={{
                                fontSize: '18px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '3px',
                                paddingLeft: '4px',
                                opacity: emptyButtonOpacity
                            }}>delete_outline</Icon>
                        </div>
                    }
                    {(showCopyButton) &&
                        <div title={getLocalizedStrings().label.COMMON.COPY}
                            style={{
                                ...styles.togButton,
                                backgroundColor: '#ffffff',
                                height: '27px',
                                width: '28px',
                                border: '1px solid rgb(224,224,224)',
                                opacity: copyOpacity
                            }}
                            onClick={() => handleAction(constants.COPY)}>
                            <Icon style={{
                                fontSize: '18px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '1.4px',
                                paddingLeft: '2.3px',
                                margin: '3px'
                            }}>content_copy</Icon>
                        </div>
                    }
                    {showMoreButton &&
                        <div style={listViewHeaderButtonStyle}>
                            <div disabled={isDisable}
                                onClick={(e) => openListviewActionList(e)}
                                style={{
                                    ...styles.togButton,
                                    backgroundColor: '#ffffff',
                                    height: '27px',
                                    width: '28px',
                                    border: '1px solid rgb(224,224,224)',
                                    opacity: moreButtonOpacity,
                                }}>
                                <Icon title={getLocalizedStrings().label.COMMON.MORE} style={{
                                    fontSize: '20px',
                                    color: '#717171',
                                    cursor: 'pointer',
                                    paddingTop: '2px',
                                    paddingLeft: '3px'
                                }}>more_vert</Icon>
                            </div>
                            <Menu
                                style={{
                                    border: '0px solid green',
                                    marginTop: '16px',
                                    marginLeft: '-27px'
                                }}
                                open={openActionList}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={() => closeListviewActionList()}>
                                {actionsList}
                            </Menu>

                        </div>
                    }
                    {totalRecordCount > 0 && showExpand && isFranchise &&
                        <div title={"Expand"}
                            style={{
                                ...styles.togButton,
                                backgroundColor: '#ffffff',
                                height: '27px',
                                marginLeft: showEmailButtonStyle,
                                width: '28px',
                                border: '1px solid rgb(224,224,224)'
                            }}
                            onClick={() => openTaskListTab()}>
                            <i className="fa-solid fa-up-right-and-down-left-from-center" aria-hidden="true" style={{
                                fontSize: '13px',
                                color: '#717171',
                                cursor: 'pointer',
                                paddingTop: '6px',
                                paddingLeft: '7px',
                                opacity: ' 0.8'
                            }} />
                        </div>
                    }
                    {showRSButton && props.object === constants.REPORTED_SALES_OBJECT && props.isDetailView && <div title={getLocalizedStrings().label.COMMON.UPDATE}
                        style={{ ...styles.togButton, backgroundColor: '#ffffff', height: '27px', width: '70px', border: '1px solid rgb(224,224,224)' }}
                        onClick={() => handleAction(ROYALTY_RUN_ACTIONS.REPORT_SALES)}>
                        <span style={{
                            paddingTop: '2px',
                            paddingLeft: '9px', fontSize: '14px', lineHeight: '22px'
                        }}>{getLocalizedStrings().label.COMMON.UPDATE}</span>
                    </div>
                    }
                    {isSubmittedRR == false && reduxDetailView.data?.show_es_buttons == true && props.object === constants.REPORTED_SALES_OBJECT && props.isDetailView && <div title='Enter sales'
                        style={{ ...styles.togButton, backgroundColor: '#ffffff', height: '27px', border: '1px solid rgb(224,224,224)' }}
                        onClick={() => handleAction("Enter Sales")}>
                        <span style={{
                            paddingTop: '2px',
                            paddingLeft: '9px', fontSize: '14px', lineHeight: '22px', paddingRight: '9px'
                        }}>Enter sales</span>
                    </div>
                    }
                    {showCalculateButton && props.object === constants.REPORTED_SALES_OBJECT && props.isDetailView && <div title={getLocalizedStrings().label.COMMON.CALCULATE}
                        style={{ ...styles.togButton, backgroundColor: '#ffffff', height: '27px', width: '80px', border: '1px solid rgb(224,224,224)' }}
                        onClick={() => handleAction(ROYALTY_RUN_ACTIONS.PROCESS)}>
                        <span style={{
                            paddingTop: '2px',
                            paddingLeft: '9px', fontSize: '14px', lineHeight: '22px'
                        }}>{getLocalizedStrings().label.COMMON.CALCULATE}</span>
                    </div>
                    }
                    {!showRSButton && props.object === constants.REPORTED_SALES_OBJECT && props.isDetailView && (
                        <div title={getLocalizedStrings().label.ROYALTY_RUN.PROCESS}
                            style={{
                                ...styles.togButton,
                                cursor: 'default',
                                backgroundColor: '#ffffff',
                                height: '27px',
                                width: '28px',
                            }}>
                        </div>)
                    }
                    {totalRecordCount > 0 && props.isDetailView === undefined && props.object == constants.REPORTED_SALES_OBJECT && <div title={getLocalizedStrings().label.COMMON.UPDATE}
                        style={{ ...styles.togButton, backgroundColor: '#ffffff', height: '27px', width: '70px', border: '1px solid rgb(224,224,224)' }}
                        onClick={() => handleAction(REPORTED_SALES_ACTIONS.UPDATE)}>
                        <span style={{
                            paddingTop: '2px',
                            paddingLeft: '9px', fontSize: '14px', lineHeight: '22px'
                        }}>{getLocalizedStrings().label.COMMON.UPDATE}</span>
                    </div>
                    }
                    {totalRecordCount > 0 && props.object == constants.UNITS_OBJECT && reduxApp.me.franchise_industry != '' && <div title={getLocalizedStrings().label.COMMON.LOGIN_TO_BASE}
                        style={{ ...styles.togButton, backgroundColor: themeColor, color: "#ffffff", height: '27px', width: 'fit-content', border: '1px solid rgb(224,224,224)' }}
                        onClick={() => loginToBase(constants.LOGIN_TO_BASE)}>
                        <span style={{
                            paddingTop: '2px',
                            paddingLeft: '9px', fontSize: '14px', lineHeight: '22px', paddingRight: '8px'
                        }}>{getLocalizedStrings().label.COMMON.LOGIN_TO_BASE}</span>
                    </div>

                    }
                    {
                        (isSearchable && !isReport && !isLookup && (!isDetailView || (isDetailView && object == constants.REPORTED_SALES_OBJECT)) && totalRecordCount > 0 && !getBooleanParam(props.isSearch)
                            && props.object !== constants.TOUCH_CAMPAIGNS_OBJECT
                            && object !== constants.DOWNLOAD_LEADS_OBJECT
                            && object !== constants.FIND_DUPLICATE_OBJECT && object !== constants.ROLL_UP_OBJECT) &&
                        <div style={{ ...searchDivStyle, marginLeft: '8px' }}>
                            {
                                (!isSearchExist) &&
                                <div style={{ display: 'inline-block' }}>
                                    <i className="fa fa-search" title={getLocalizedStrings().label.COMMON.SEARCH}
                                        onClick={() => changeTextFieldStyle()} style={{
                                            fontSize: '14px',
                                            marginTop: '6px',
                                            paddingRight: '5px',
                                            color: 'rgb(113, 113, 113)',
                                            paddingLeft: '7px',
                                            cursor: 'pointer'
                                        }} />
                                </div>
                            }

                            {
                                (isSearchExist) &&
                                <div>
                                    <div onClick={(e) => handleSearchFieldsListOpen(e)} style={{
                                        cursor: 'pointer',
                                        display: 'inline-block',
                                        color: '#717171',
                                        paddingLeft: '3px',
                                        width: '89px',
                                        float: 'left',
                                        backgroundColor: '#ffffff',
                                        borderRight: '1px solid rgb(224,224,224)',
                                        borderTopLeftRadius: '4px',
                                        borderBottomLeftRadius: '4px',
                                        height: '25px',
                                    }}>
                                        <div title={getMultilingualLabelName(object, selectedSearchFieldLabel)} style={{
                                            float: 'left',
                                            maxWidth: '60px',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            display: 'inline-block',
                                            padding: '2px',

                                        }}>{getMultilingualLabelName(object, selectedSearchFieldLabel)}</div>
                                        <div style={{ float: 'right' }}>
                                            <Icon style={{ color: '#717171' }}>keyboard_arrow_down</Icon>
                                        </div>
                                    </div>

                                    <div style={{
                                        ...searchTextFieldDivStyle,
                                        borderTopRightRadius: '4px',
                                        borderBottomRightRadius: '4px'
                                    }}
                                    >
                                        <div style={{ display: 'inline-block' }}>
                                            <input type='text' id="searchText" value={searchText}
                                                onKeyUp={(e) => handleKeyUp(e)} onChange={(e) => changeSearchText(e)}
                                                placeholder={searchTextPlaceHolder} style={searchTextFieldStyle}
                                                className="listview-search-input" autoComplete='off' />
                                        </div>
                                        <div style={{ display: 'inline-block' }}>
                                            <i className="fa fa-search"
                                                title={getLocalizedStrings().label.COMMON.CLICK_TO_SEARCH}
                                                onClick={() => listviewSearch()} style={{
                                                    fontSize: '14px',
                                                    marginTop: '6px',
                                                    paddingRight: '5px',
                                                    color: '#ccc',
                                                    cursor: 'pointer',
                                                    paddingLeft: '6px'
                                                }} />
                                        </div>
                                    </div>

                                </div>
                            }

                            <Menu
                                id={'modules'}
                                key={'modules'}
                                name={'modules'}
                                open={isOpenSearchFieldsList}
                                anchorEl={anchorEl}
                                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                                onClose={() => handleSearchFieldsListClose()}
                                value={selectedSearchFieldName}
                            >

                                {availableSearchFieldList}

                            </Menu>
                        </div>
                    }
                    {
                        showSearchField &&
                        <div style={{
                            ...listViewHeaderButtonStyle,
                            marginBottom: '-23px',
                            width: pageNo > 10 && isLookup && props.propsData.isFindAndLink === false ? '37%' : '46%'
                        }}>
                            <ListViewSearchField object={object} listViewCallBack={props.listViewCallBack} setSearchValue={setSearchValue} />
                        </div>
                    }

                    {totalRecordCount > 0 && object === constants.WORKFLOW_OBJECT &&
                        <div style={{ float: "right" }}>
                            <div style={{
                                cursor: 'pointer',
                                display: 'inline-block',
                                color: '#717171',
                                paddingTop: '2px',
                                paddingLeft: '5px',
                                width: '89px',
                                float: 'left',
                                border: '1px solid rgb(224,224,224)',
                                backgroundColor: '#ffffff',
                                borderRadius: '4px'
                            }}>
                                <div title={getStatusFieldLable(selectedStatusValue, 'redux')} style={{
                                    float: 'left',
                                    maxWidth: '60px',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    overflow: 'hidden',
                                    display: 'inline-block'
                                }}>{getStatusFieldLable(selectedStatusValue, 'redux')}</div>
                                <div style={{ float: 'right' }}>
                                    <PopOver
                                        id={'status-modules'}
                                        key={'status-modules'}
                                        name={'status-modules'}
                                        buttonEndIcon={"keyboard_arrow_down"}
                                        buttonStyle={{
                                            float: 'left',
                                            maxWidth: '60px',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            display: 'inline-block'
                                        }}
                                        labelposition={"before"}
                                        buttonLabel={""}
                                        options={popOverList}
                                        value={selectedStatusValue}
                                        onclickAction={(value) => changeStatusField(value)}
                                        isShowIcon={true}
                                        title={""}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    {totalRecordCount > 0 && object == constants.EVENTS_OBJECT &&
                        <div style={{ paddingBottom: "3.5%" }}>

                        </div>
                    }
                     
                    { !isLookup && totalRecordCount > 0 && props.object === constants.PARTNERS_OBJECT && reduxApp.me.is_franchisee && <div title={getLocalizedStrings().label.COMMON.ADVANCED_SEARCH}
                        style={{ ...styles.togButton, backgroundColor: 'transparent', height: '27px', width: '120px' }}
                    >
                        <span style={{
                            paddingTop: '2px',
                            paddingLeft: '9px', fontSize: '14px', lineHeight: '22px',
                            color: '#337ab7',
                        }}
                            onClick={(event) => partnerAdvanceSearch(event)} >{getLocalizedStrings().label.COMMON.ADVANCED_SEARCH}</span>
                    </div>

                    }
                  
                    <div className="pag" style={{ marginLeft: props.isLookup && pageNo > 95 ? '15px' : '22px', marginRight: props.listView.mounted == true && props.isLookup == false ? '25px' : '', marginTop: object === constants.AUDIT_ANSWERS_OBJECT ? '-29px' : '' }}>
                        {/* {totalRecordCount > 0 && object === constants.TASKS && getBooleanParam(props.suffix === constants.TASK_LIST) && (
                            <div
                                style={{
                                    display: "inline",
                                    paddingRight: "5px",
                                    marginRight: "0px",
                                    float: "left",
                                }}
                            >
                                <Button
                                    style={{ ...styles.listViewPrimaryButton }}
                                    labelstyle={{ paddingLeft: "10px", paddingRight: "10px" }}
                                    onClick={() => openPlanChartTab()}
                                >
                                    {'Gantt Chart'}
                                </Button>
                            </div>
                        )} */}
                        {showPaginetion &&
                            <span style={{ marginRight: 20, display: 'flex' }}>
                                <Pagination
                                    object={object}
                                    listView={props.listView}
                                    totalRecordCount={totalRecordCount}
                                    isLookup={props.isLookup}
                                    isFindAndLink={isFindAndLink}
                                    listViewCallBack={props.listViewCallBack}
                                    isReport={isReport}
                                    selectedPage={(page) => selectedPage(page)}
                                />
                            </span>}
                        {isShowSetup &&
                            <div className="pageSet" style={{ marginTop: 'auto', paddingBottom: '17px' }}>
                                <IconButton onClick={(e) => openListViewSetupPopOver(e)}
                                    style={{ color: '#717171', marginTop: props.listView.mounted == true && props.isLookup == false ? '' : '-5px', padding: 5 }}>
                                    <Icon style={{ opacity: 0.7, fontSize: '16px', paddingLeft: '1px' }}
                                        title={getLocalizedStrings().label.LIST_VIEW.SET_UP}>settings</Icon>
                                </IconButton>

                                <Popper
                                    open={isOpenSetupPopover}
                                    anchorEl={anchorEl}
                                    placement="left"
                                    sx={{ ...styles.popperStyle }}
                                >
                                    {setupComponent}
                                </Popper>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        let pageHeight = window.innerHeight - 247;
        let top = (pageHeight - 10) / 2
        return (<div className="loaderh">
            {
                <div style={{ width: '100%', height: pageHeight }}>
                    <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                        <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                            <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                        </div>
                    </div>
                </div>
            }
        </div>);
    }
}

export default ListViewHeader;