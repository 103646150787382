export var actions = {
    /********************************************************/
    /* App
    /********************************************************/
    APP_REDIRECT: 'APP_REDIRECT',

    /* App > Tabs
    /********************************************************/
    APP_SET_TAB: 'APP_SET_TAB',
    APP_SET_HEADER: 'APP_SET_HEADER',
    
    /* setting > unitcrm
    /********************************************************/
    UPADTE_UNIT_CRM_DATA:'UPADTE_UNIT_CRM_DATA',
    /********************************************************/
    /* App > Home
    /********************************************************/
    APP_HOME_GET: 'APP_HOME_GET',
    APP_HOME_GET_FULFILLED: 'APP_HOME_GET_FULFILLED',
    APP_HOME_UPDATE: 'APP_HOME_UPDATE',
    APP_NOTIFICATION_UPDATE: 'APP_NOTIFICATION_UPDATE',
    APP_HOME_SET_MODULE_ITEMS: 'APP_HOME_SET_MODULE_ITEMS',
    APP_SET_SELECTED_TAB: 'APP_SET_SELECTED_TAB',
    APP_EXTERNAL_LINK_MENU_UPDATE: 'APP_EXTERNAL_LINK_MENU_UPDATE',
    GET_APP_HOME_SETUP: 'GET_APP_HOME_SETUP',
    GET_APP_HOME_SETUP_FULFILLED: 'GET_APP_HOME_SETUP_FULFILLED',
    SET_CALENDAR_SET_UP: 'SET_CALENDAR_SET_UP',
    SAVE_CALENDAR_SET_UP: 'SAVE_CALENDAR_SET_UP',
    APP_REDIRECT_BACK_FR_LOGIN: 'APP_REDIRECT_BACK_FR_LOGIN',
    APP_SET_BACK_FR_LOGIN_TAB: 'APP_SET_BACK_FR_LOGIN_TAB',

    /********************************************************/
    /* App > Search
    /********************************************************/
    APP_SEARCH_CRITERIA_SET: 'APP_SEARCH_CRITERIA_SET',
    APP_SEARCH_POPOVER_OPEN_CLOSE: 'APP_SEARCH_POPOVER_OPEN_CLOSE',
    APP_ADVANCE_SEARCH_CRITERIA_SET: 'APP_ADVANCE_SEARCH_CRITERIA_SET',

    /********************************************************/
    /* App > Me (Loggedin User)
	
    /********************************************************/
    APP_ME_GET: 'APP_ME_GET',
    APP_ME_GET_FULFILLED: 'APP_ME_GET_FULFILLED',
    APP_FRANCHISOR_INDUSTRY_UPDATE: 'APP_FRANCHISOR_INDUSTRY_UPDATE',
    APP_REAUTHENTICATE_AURINKO_UPDATE_ALL: 'APP_REAUTHENTICATE_AURINKO_UPDATE_ALL',
    APP_INFORMATION_STATUS_UPDATE: 'APP_INFORMATION_STATUS_UPDATE',
    APP_REAUTHENTICATE_AURINKO_UPDATE: 'APP_REAUTHENTICATE_AURINKO_UPDATE',
    APP_IS_SEND_MAIL_USING_AURINKO_UPDATE: 'APP_IS_SEND_MAIL_USING_AURINKO_UPDATE',
    APP_AURINKO_CASE_MAILBOX_UPDATE: 'APP_AURINKO_CASE_MAILBOX_UPDATE',
    APP_NOTIFY_TEXT_SALESREP_UPDATE: 'APP_NOTIFY_TEXT_SALESREP_UPDATE',
    APP_PHONE_TEXT_PROVIDER_UPDATE: 'APP_PHONE_TEXT_PROVIDER_UPDATE',
    APP_LMS_NODE_UPDATE: 'APP_LMS_NODE_UPDATE',
    APP_PHONE_TEXT_MANAGE_UNIT_PHONE_UPDATE: 'APP_PHONE_TEXT_MANAGE_UNIT_PHONE_UPDATE',
    /********************************************************/
    /* App > Link Objects Info
	
    /********************************************************/
    APP_LINK_OBJECT_INFO: 'APP_LINK_OBJECT_INFO',
    APP_LINK_OBJECT_INFO_FULFILLED: 'APP_LINK_OBJECT_INFO_FULFILLED',
    SET_APP_LINK_OBJECT_INFO: 'SET_APP_LINK_OBJECT_INFO',

    /********************************************************/
    /* App > User
	
    /********************************************************/
    APP_UPDATE_USER_LANGUAGE: 'APP_UPDATE_USER_LANGUAGE',

    /********************************************************/
    /* App > Appointment Type
	
    /********************************************************/
    APPOINTMENT_TYPES_GET: 'APPOINTMENT_TYPES_GET',
    APPOINTMENT_TYPES_GET_FULFILLED: 'APPOINTMENT_TYPES_GET_FULFILLED',

    /********************************************************/
    /* AppContainer
    /********************************************************/
    APP_CONTAINER_DRAWER_OPEN_CLOSE: 'APP_CONTAINER_DRAWER_OPEN_CLOSE',
    APP_CONTAINER_DIALOG_OPEN_CLOSE: 'APP_CONTAINER_DIALOG_OPEN_CLOSE',
    APP_CONTAINER_DIALOG_TITLE_UPDATE: 'APP_CONTAINER_DIALOG_TITLE_UPDATE',
    APP_CONTAINER_DIALOG_SET_ACTIONS: 'APP_CONTAINER_DIALOG_SET_ACTIONS',
    APP_CONTAINER_DIALOG_SET_CALLBACK_ACTIONS: 'APP_CONTAINER_DIALOG_SET_CALLBACK_ACTIONS',
    APP_CONTAINER_DIALOG_RESET_CALLBACK_ACTIONS: 'APP_CONTAINER_DIALOG_RESET_CALLBACK_ACTIONS',
    APP_CONTAINER_CUSTOM_DRAWER_OPEN_CLOSE: 'APP_CONTAINER_CUSTOM_DRAWER_OPEN_CLOSE',
    APP_CONTAINER_CUSTOM_DRAWER_MINIMIZE_MAXIMIZE: 'APP_CONTAINER_CUSTOM_DRAWER_MINIMIZE_MAXIMIZE',
    APP_CONTAINER_DRAWER_SET_TITLE: 'APP_CONTAINER_DRAWER_SET_TITLE',

    TOP_UP_DIALOG_OPEN_CLOSE: 'TOP_UP_DIALOG_OPEN_CLOSE',
    TOP_UP_DIALOG_SET_ACTION: 'TOP_UP_DIALOG_SET_ACTION',

    /********************************************************/
    /* Header
    /********************************************************/
    SET_HEADER: 'SET_HEADER',
    UPDATE_HEADER: 'UPDATE_HEADER',
    REFRESH_ASSETS: 'REFRESH_ASSETS',
    REFRESH_ONBOARDING_UNITS: 'REFRESH_ONBOARDING_UNITS',
    REFRESH_ASSETS_TREE: 'REFRESH_ASSETS_TREE',

    /********************************************************/
    /* Dashboard
    /********************************************************/
    DASHBOARD_GET: 'DASHBOARD_GET',
    DASHBOARD_GET_FULFILLED: 'DASHBOARD_GET_FULFILLED',
    DASHBOARD_ERROR: 'DASHBOARD_ERROR',
    DASHBOARD_CHANGE_LAYOUT: 'DASHBOARD_CHANGE_LAYOUT',
    DASHBOARD_CHANGE_LAYOUT_FULFILLED: 'DASHBOARD_CHANGE_LAYOUT_FULFILLED',
    DASHBOARD_REDUCER_REDIRECTION: 'DASHBOARD_REDUCER_REDIRECTION',
    DASHBOARD_URL_GET: 'DASHBOARD_URL_GET',
    DASHBOARD_URL_GET_FULFILLED: 'DASHBOARD_URL_GET_FULFILLED',
    DASHBOARD_INFO_GET: 'DASHBOARD_INFO_GET',
    DASHBOARD_INFO_GET_FULFILLED: 'DASHBOARD_INFO_GET_FULFILLED',
    DASHBOARD_INACTIVE_WIDGET: 'DASHBOARD_INACTIVE_WIDGET',
    DASHBOARD_INACTIVE_WIDGET_FULFILLED: 'DASHBOARD_INACTIVE_WIDGET_FULFILLED',
    DASHBOARD_IS_REFRESH:'DASHBOARD_IS_REFRESH',
    DASHBOARD_IS_EDIT : 'DASHBOARD_IS_EDIT',
    UPDATE_WIDGET_CONTENT: 'UPDATE_WIDGET_CONTENT',
    
    /********************************************************/
    /* App Object List
    /********************************************************/
    APP_OBJECT_LIST_GET: 'APP_OBJECT_LIST_GET',
    APP_OBJECT_LIST_GET_PENDING: 'APP_OBJECT_LIST_GET_PENDING',
    APP_OBJECT_LIST_GET_FULFILLED: 'APP_OBJECT_LIST_GET_FULFILLED',

    /********************************************************/
    /* Workflow
    /********************************************************/



    /********************************************************/
    /* Lists
    /********************************************************/

    GET_LISTS_START: 'GET_LISTS_START',
    GET_LISTS: 'GET_LISTS',
    GET_LISTS_BY_WF_STATUS: 'GET_LISTS_BY_WF_STATUS',
    MOVE_CARD: 'MOVE_CARD',
    MOVE_LIST: 'MOVE_LIST',
    TOGGLE_DRAGGING: 'TOGGLE_DRAGGING',
    USER_LIST: 'USER_LIST',
    WORKFLOW_LIST: 'WORKFLOW_LIST',
    MOUNTED: 'MOUNTED',
    COLUMNVIEW_FILTER_CHANGE: 'COLUMNVIEW_FILTER_CHANGE',
    FETCHED: 'FETCHED',
    COLUMN_VIEW_TASK_UPDATE: 'COLUMN_VIEW_TASK_UPDATE',
    COLUMN_VIEW_TASK_LIST_UPDATE: 'COLUMN_VIEW_TASK_LIST_UPDATE',
    COLUMN_VIEW_TASK_OPPORTUNITIES_UPDATE: 'COLUMN_VIEW_TASK_OPPORTUNITIES_UPDATE',
    COLUMN_VIEW_OPPORTUNITIES_UPDATE: 'COLUMN_VIEW_OPPORTUNITIES_UPDATE',
    COLUMN_VIEW_CONTACT_UPDATE: 'COLUMN_VIEW_CONTACT_UPDATE',
    SET_LIST_OBJECT: 'SET_LIST_OBJECT',
    GET_TASK_LISTS_START: 'GET_TASK_LISTS_START',
    GET_TASK_LISTS: 'GET_TASK_LISTS',
    MOVE_TASK_CARD: 'MOVE_TASK_CARD',
    MOVE_TASK_LIST: 'MOVE_TASK_LIST',



    /********************************************************/
    /* Listview
    /********************************************************/
    LISTVIEW_GET: 'LISTVIEW_GET',
    LISTVIEW_REFRESH: 'LISTVIEW_REFRESH',
    LISTVIEW_GET_PENDING: 'LISTVIEW_GET_PENDING',
    LISTVIEW_GET_FULFILLED: 'LISTVIEW_GET_FULFILLED',
    LISTVIEW_INLINE_UPDATE: 'LISTVIEW_INLINE_UPDATE',
    LISTVIEWHEADER_UNITBASE_UPDATE: 'LISTVIEWHEADER_UNITBASE_UPDATE',
    SET_LISTVIEW_DATA: 'SET_LISTVIEW_DATA',
    SET_CHILD_LISTVIEW_DATA: 'SET_CHILD_LISTVIEW_DATA',
    CHILD_LISTVIEW_SET_INLINE_FIELD_DATA: 'CHILD_LISTVIEW_SET_INLINE_FIELD_DATA',
    SET_MOUSE_CLICK: 'SET_MOUSE_CLICK',
    LISTVIEW_GET_SELECTED_RECORD: 'LISTVIEW_GET_SELECTED_RECORD',
    LISTVIEW_MODIFY: 'LISTVIEW_MODIFY',
    LISTVIEW_DATA_MODIFY: 'LISTVIEW_DATA_MODIFY',
    LISTVIEW_SET_MOUNTED: 'LISTVIEW_SET_MOUNTED',
    LISTVIEW_GROUPBY_SHOW_HIDE: 'LISTVIEW_GROUPBY_SHOW_HIDE',
    LISTVIEW_GROUPBY_INFO_GET: 'LISTVIEW_GROUPBY_INFO_GET',
    LISTVIEW_GROUPBY_INFO_GET_FULFILLED: 'LISTVIEW_GROUPBY_INFO_GET_FULFILLED',
    LISTVIEW_UPDATE_FILTERBY_INFO: 'LISTVIEW_UPDATE_FILTERBY_INFO',
    DOWNLOAD_LEADS_SEARCH_INFO: 'DOWNLOAD_LEADS_SEARCH_INFO',
    DOWNLOAD_LEADS_SEARCH_INFO_FULFILLED: 'DOWNLOAD_LEADS_SEARCH_INFO_FULFILLED',
    LISTVIEW_LEADSTORE_SEARCH_SHOW_HIDE: 'LISTVIEW_LEADSTORE_SEARCH_SHOW_HIDE',
    LISTVIEW_SET_REQUIRED_FIELD_DATA: 'LISTVIEW_SET_REQUIRED_FIELD_DATA',
    LISTVIEW_SET_INLINE_FIELD_DATA: 'LISTVIEW_SET_INLINE_FIELD_DATA',
    LISTVIEW_REMOVE_REQUIRED_FIELD_DATA: 'LISTVIEW_REMOVE_REQUIRED_FIELD_DATA',
    SET_LISTVIEW_ACTIVE_OBJECT: 'SET_LISTVIEW_ACTIVE_OBJECT',
    UPDATE_UNIT_PERMISSION_LIST:'UPDATE_UNIT_PERMISSION_LIST',

    /********************************************************/
    /* Child Listview
    /********************************************************/
    CHILD_LISTVIEW_GET: 'CHILD_LISTVIEW_GET',
    CHILD_LISTVIEW_GET_FULFILLED: 'CHILD_LISTVIEW_GET_FULFILLED',
    CHILD_LISTVIEW_GET_SELECTED_RECORD: 'CHILD_LISTVIEW_GET_SELECTED_RECORD',
    CHILD_LISTVIEW_SET_MOUNTED: 'CHILD_LISTVIEW_SET_MOUNTED',
    CHILD_LISTVIEW_GROUPBY_INFO_GET_FULFILLED: 'CHILD_LISTVIEW_GROUPBY_INFO_GET_FULFILLED',
    CHILD_LISTVIEW_GROUPBY_INFO_GET: 'CHILD_LISTVIEW_GROUPBY_INFO_GET',
    CHILD_LISTVIEW_TASK_WORKFLOW_LIST_UPDATE: 'CHILD_LISTVIEW_TASK_WORKFLOW_LIST_UPDATE',
    /********************************************************/
    /* Duplicate Listview
    /********************************************************/
    DUPLICATE_LISTVIEW_GET: 'DUPLICATE_LISTVIEW_GET',
    DUPLICATE_LISTVIEW_GET_FULFILLED: 'DUPLICATE_LISTVIEW_GET_FULFILLED',
    DUPLICATE_LISTVIEW_GET_SELECTED_RECORD: 'DUPLICATE_LISTVIEW_GET_SELECTED_RECORD',
    DUPLICATE_LISTVIEW_SET_MOUNTED: 'DUPLICATE_LISTVIEW_SET_MOUNTED',
    DUPLICATE_LISTVIEW_GROUPBY_INFO_GET: 'DUPLICATE_LISTVIEW_GROUPBY_INFO_GET',
    DUPLICATE_LISTVIEW_GROUPBY_INFO_GET_FULFILLED: 'DUPLICATE_LISTVIEW_GROUPBY_INFO_GET_FULFILLED',

    /********************************************************/
    /* Other Listview
    /********************************************************/
    OTHER_LISTVIEW_GET: 'OTHER_LISTVIEW_GET',
    OTHER_LISTVIEW_GET_PENDING: 'OTHER_LISTVIEW_GET_PENDING',
    OTHER_LISTVIEW_GET_FULFILLED: 'OTHER_LISTVIEW_GET_FULFILLED',
    OTHER_LISTVIEW_GET_SELECTED_RECORD: 'OTHER_LISTVIEW_GET_SELECTED_RECORD',
    OTHER_LISTVIEW_SET_MOUNTED: 'OTHER_LISTVIEW_SET_MOUNTED',

    /********************************************************/
    /* Detail Task Listview
    /********************************************************/
    DETAIL_VIEW_TASK_LISTVIEW_GET: 'DETAIL_VIEW_TASK_LISTVIEW_GET',
    DETAIL_VIEW_TASK_LISTVIEW_GET_PENDING: 'DETAIL_VIEW_TASK_LISTVIEW_GET_PENDING',
    DETAIL_VIEW_TASK_LISTVIEW_GET_FULFILLED: 'DETAIL_VIEW_TASK_LISTVIEW_GET_FULFILLED',
    DETAIL_VIEW_TASK_LISTVIEW_GET_SELECTED_RECORD: 'DETAIL_VIEW_TASK_LISTVIEW_GET_SELECTED_RECORD',
    DETAIL_VIEW_TASK_GROUPBY_INFO_GET :'DETAIL_VIEW_TASK_GROUPBY_INFO_GET',
    DETAIL_VIEW_TASK_GROUPBY_INFO_GET_PENDING :'DETAIL_VIEW_TASK_GROUPBY_INFO_GET_PENDING',
    DETAIL_VIEW_TASK_GROUPBY_INFO_GET_FULFILLED :'DETAIL_VIEW_TASK_GROUPBY_INFO_GET_FULFILLED',
    DETAIL_VIEW_TASK_UPDATE_FILTERBY_INFO :'DETAIL_VIEW_TASK_UPDATE_FILTERBY_INFO',
    DETAIL_VIEW_TASK_LISTVIEW_SET_MOUNTED: 'DETAIL_VIEW_TASK_LISTVIEW_SET_MOUNTED',
    DETAIL_VIEW_TASK_GET_SELECTED_RECORD: 'DETAIL_VIEW_TASK_GET_SELECTED_RECORD',
    DETAIL_VIEW_TASK_LISTVIEW_REFRESH:'DETAIL_VIEW_TASK_LISTVIEW_REFRESH',
    DETAIL_VIEW_TASK_GROUPBY_SHOW_HIDE: 'DETAIL_VIEW_TASK_GROUPBY_SHOW_HIDE',
    DETAIL_VIEW_TASK_WORKFLOW_LIST_UPDATE: 'DETAIL_VIEW_TASK_WORKFLOW_LIST_UPDATE',

    /********************************************************/
    /* Assign Listview
    /********************************************************/
    ASSIGN_LISTVIEW_GET: 'ASSIGN_LISTVIEW_GET',
    ASSIGN_LISTVIEW_GET_FULFILLED: 'ASSIGN_LISTVIEW_GET_FULFILLED',
    ASSIGN_LISTVIEW_GET_SELECTED_RECORD: 'ASSIGN_LISTVIEW_GET_SELECTED_RECORD',
    ASSIGN_LISTVIEW_SET_MOUNTED: 'ASSIGN_LISTVIEW_SET_MOUNTED',
    ASSIGN_LISTVIEW_GROUPBY_SHOW_HIDE: 'ASSIGN_LISTVIEW_GROUPBY_SHOW_HIDE',
    ASSIGN_LISTVIEW_GROUPBY_INFO_GET: 'ASSIGN_LISTVIEW_GROUPBY_INFO_GET',
    ASSIGN_LISTVIEW_GROUPBY_INFO_GET_FULFILLED: 'ASSIGN_LISTVIEW_GROUPBY_INFO_GET_FULFILLED',
    /********************************************************/
    /* Detailview
    /********************************************************/
    DETAILVIEW_GET: 'DETAILVIEW_GET',
    DETAILVIEW_PENDING: 'DETAILVIEW_PENDING',
    DETAILVIEW_GET_FULFILLED: 'DETAILVIEW_GET_FULFILLED',

    DETAILVIEW_FIELDS_GET: 'DETAILVIEW_FIELDS_GET',
    DETAILVIEW_FIELDS_GET_FULFILLED: 'DETAILVIEW_FIELDS_GET_FULFILLED',

    IS_DETAILVIEW: 'IS_DETAILVIEW',
    IS_DETAILVIEW_MOUNTED: 'IS_DETAILVIEW_MOUNTED',

    DETAILVIEW_IS_REFERSH: 'DETAILVIEW_IS_REFERSH',
    DETAILVIEWLISTVIEW_IS_REFERSH: 'DETAILVIEWLISTVIEW_IS_REFERSH',
    DETAILVIEW_GROUP_IS_REFERSH: 'DETAILVIEW_GROUP_IS_REFERSH',

    DETAILVIEW_TASK_APP_GET: 'DETAILVIEW_TASK_APP_GET',
    DETAILVIEW_TASK_APP_GET_FULFILLED: 'DETAILVIEW_TASK_APP_GET_FULFILLED',

    DETAILVIEW_UNIT_OWNER_TASKS_GET: 'DETAILVIEW_UNIT_OWNER_TASKS_GET',
    DETAILVIEW_UNIT_OWNER_TASKS_GET_FULFILLED: 'DETAILVIEW_UNIT_OWNER_TASKS_GET_FULFILLED',

    SAVE_DETAILVIEW_TASK_APP_PARAM: 'SAVE_DETAILVIEW_TASK_APP_PARAM',

    DETAILVIEW_PINNED_NOTE_GET: 'DETAILVIEW_PINNED_NOTE_GET',
    DETAILVIEW_PINNED_NOTE_GET_FULFILLED: 'DETAILVIEW_PINNED_NOTE_GET_FULFILLED',

    QUEUEVIEW_IS_REFERSH: 'QUEUEVIEW_IS_REFERSH',
    QUEUEVIEW_NOTE_IS_REFERSH: 'QUEUEVIEW_NOTE_IS_REFERSH',
    DETAILVIEW_HEADER_IS_REFERSH: 'DETAILVIEW_HEADER_IS_REFERSH',
    /********************************************************/
    /* Calendar/ Appointment 
    /********************************************************/
    APPOINTMENT_GET: 'APPOINTMENT_GET',
    JOB_APPOINTMENT_GET: 'JOB_APPOINTMENT_GET',
    APPOINTMENT_GET_PENDING: 'APPOINTMENT_GET_PENDING',
    APPOINTMENT_GET_FULFILLED: 'APPOINTMENT_GET_FULFILLED',
    JOB_APPOINTMENT_GET_FULFILLED: 'JOB_APPOINTMENT_GET_FULFILLED',
    JOB_APPOINTMENT_UPDATE: 'JOB_APPOINTMENT_UPDATE',
    APPOINTMENT_LISTVIEW_GET: 'APPOINTMENT_LISTVIEW_GET',
    APPOINTMENT_LISTVIEW_GET_FULFILLED: 'APPOINTMENT_LISTVIEW_GET_FULFILLED',
    APPOINTMENT_ERROR: 'APPOINTMENT_ERROR',
    APPOINTMENT_DETAILVIEW_GET: 'APPOINTMENT_DETAILVIEW_GET',
    APPOINTMENT_DETAILVIEW_GET_FULFILLED: 'APPOINTMENT_DETAILVIEW_GET_FULFILLED',
    APPOINTMENT_HOME_GET: 'APPOINTMENT_HOME_GET',
    APPOINTMENT_HOME_GET_FULFILLED: 'APPOINTMENT_HOME_GET_FULFILLED',

    APPOINTMENT_UPDATE: 'APPOINTMENT_UPDATE',
    SELECTED_DATE_RANGE_UPDATE: 'SELECTED_DATE_RANGE_UPDATE',
    USER_TIME_AVAILABILITY_UPDATE: 'USER_TIME_AVAILABILITY_UPDATE',
    APPOINTMENT_UPDATE_FULFILLED: 'APPOINTMENT_UPDATE_FULFILLED',
    APPOINTMENT_MODIFY: 'APPOINTMENT_MODIFY',
    APPOINTMENT_DELETE: 'APPOINTMENT_DELETE',
    CLEAR_APPOINTMENT_REDUCER: 'CLEAR_APPOINTMENT_REDUCER',
    APPOINTMENT_UPDATE_DRAG_AND_DROP: 'APPOINTMENT_UPDATE_DRAG_AND_DROP',
    CALENDAR_REFRESH: 'CALENDAR_REFRESH',
    JOB_CALENDAR_REFRESH: 'JOB_CALENDAR_REFRESH',
    SELECTED_DATE_SET_FULFILLED: 'SELECTED_DATE_SET_FULFILLED',
    SET_CALENDAR_INFO: 'SET_CALENDAR_INFO',
    CALENDAR_REFRESH_FOR_BROADCAST: 'CALENDAR_REFRESH_FOR_BROADCAST',
    BROADCAST_GET: 'BROADCAST_GET',
    BROADCAST_GET_FULFILLED: 'BROADCAST_GET_FULFILLED',
    APP_TASK_REFRESH_REFRESH: 'APP_TASK_REFRESH_REFRESH',
    /********************************************************/
    /* Pending Tasks / All Tasks 
    /********************************************************/
    TASK_GET: 'TASK_GET',
    TASK_GET_FULFILLED: 'TASK_GET_FULFILLED',
    TASK_LISTVIEW_GET: 'TASK_LISTVIEW_GET',
    TASK_LISTVIEW_GET_FULFILLED: 'TASK_LISTVIEW_GET_FULFILLED',
    TASK_DETAILVIEW_GET: 'TASK_DETAILVIEW_GET',
    TASK_DETAILVIEW_GET_FULFILLED: 'TASK_DETAILVIEW_GET_FULFILLED',
    TASK_HOME_GET: 'TASK_HOME_GET',
    TASK_HOME_GET_FULFILLED: 'TASK_HOME_GET_FULFILLED',
    TASK_ERROR: 'TASK_ERROR',
    TASK_PORTAL: 'TASK_PORTAL',
    TASK_PORTAL_FULFILLED: 'TASK_PORTAL_FULFILLED',
    TASK_PORTAL_SCROLL: 'TASK_PORTAL_SCROLL',
    TASK_PORTAL_SCROLL_FULFILLED: 'TASK_PORTAL_SCROLL_FULFILLED',
    TASK_MODIFY: 'TASK_MODIFY',
    TASK_PANE_REFRESH: 'TASK_PANE_REFRESH',
    CLEAR_TASK_REDUCER: 'CLEAR_TASK_REDUCER',
    UNIT_TASK_PANE_REFRESH: 'UNIT_TASK_PANE_REFRESH',
    /********************************************************/
    /* Sales reports
    /********************************************************/
    SALES_REPORT_GET: 'SALES_REPORT_GET',
    SALES_REPORT_GET_PENDING: 'SALES_REPORT_GET_PENDING',
    SALES_REPORT_GET_FULFILLED: 'SALES_REPORT_GET_FULFILLED',
    SALES_REPORT_ADD: 'SALES_REPORT_ADD',
    SALES_REPORT_REMOVE: 'SALES_REPORT_REMOVE',
    SALESREPORT_ERROR: 'SALESREPORT_ERROR',
    RECENT_REPORT_GET: 'RECENT_REPORT_GET',
    RECENT_REPORT_ERROR: 'RECENT_REPORT_ERROR',
    REPORTS_REDUCER_REDIRECTION: 'REPORTS_REDUCER_REDIRECTION',
    RECENT_REPORT_RESPONSE_DATA: 'RECENT_REPORT_RESPONSE_DATA',
    SET_REPORT_HEADER_DATA: 'SET_REPORT_HEADER_DATA',
    SET_REPORT_PARAM_DATA: 'SET_REPORT_PARAM_DATA',

    SALES_REPORT_INFO_GET_PENDING: 'SALES_REPORT_INFO_GET_PENDING',
    SALES_REPORT_INFO_GET_FULFILLED: 'SALES_REPORT_INFO_GET_FULFILLED',
    SALES_REPORT_DATA_GET_PENDING: 'SALES_REPORT_DATA_GET_PENDING',
    SALES_REPORT_DATA_GET_PARTIAL_FULFILLED: 'SALES_REPORT_DATA_GET_PARTIAL_FULFILLED',
    SALES_REPORT_DATA_GET_FULFILLED: 'SALES_REPORT_DATA_GET_FULFILLED',

    SET_PIPE_LINE_DATA: 'SET_PIPE_LINE_DATA',
    SET_WORKFLOWS: 'SET_WORKFLOWS',
    SET_STAGES: 'SET_STAGES',
    SET_WORKFLOW_VALUE: 'SET_WORKFLOW_VALUE',
    SET_STAGE_VALUE: 'SET_STAGE_VALUE',
    SET_TABLE_DATA: 'SET_TABLE_DATA',
    SET_SORTABLE: 'SET_SORTABLE',
    SORTED_DATA: 'SORTED_DATA',
    SET_STAGES_VALUES_ERROR: 'SET_STAGES_VALUES_ERROR',
    SET_INITIAL_MSG: 'SET_INITIAL_MSG',

    /********************************************************/
    /* Sales Template
    /********************************************************/
    SALES_TEMPLATE_GET: 'SALES_TEMPLATE_GET',
    SALES_TEMPLATE_GET_FULFILLED: 'SALES_TEMPLATE_GET_FULFILLED',
    SALES_TEMPLATE_GET_ERROR: 'SALES_TEMPLATE_GET_ERROR',

    /********************************************************/
    /* Marketing Template
    /********************************************************/
    MARKETING_TEMPLATE_GET: 'MARKETING_TEMPLATE_GET',
    MARKETING_TEMPLATE_GET_FULFILLED: 'MARKETING_TEMPLATE_GET_FULFILLED',
    MARKETING_TEMPLATE_GET_ERROR: 'MARKETING_TEMPLATE_ERROR',

    /********************************************************/
    /* Users
    /********************************************************/
    USERLIST_GET: 'USERLIST_GET',
    USERLIST_GET_FULFILLED: 'USERLIST_GET_FULFILLED',
    USERLIST_ERROR: 'USERLIST_ERROR',
    SETUSERLIST_DATA: 'SETUSERLIST_DATA',
    /********************************************************/
    /* Convert
    /********************************************************/
    CONVERT_GET: 'CONVERT_GET',
    //IS_OPEN_OPPORTUNITY_EXITS_GET: 'IS_OPEN_OPPORTUNITY_EXITS_GET',
    UPDATE_CONVERT_DATA: 'UPDATE_CONVERT_DATA',
    UPDATE_AGREEMENT_COMPONENT_DATA: 'UPDATE_AGREEMENT_COMPONENT_DATA',
    UPDATE_AGREEMENT_TEMPLATE_DATA: 'UPDATE_AGREEMENT_TEMPLATE_DATA',
    UPDATE_CONVERT_DATA_BY_KEY: 'UPDATE_CONVERT_DATA_BY_KEY',
    UPDATE_BATCH_CONVERT_DATA: 'UPDATE_BATCH_CONVERT_DATA',
    CLEAR_CONVERT_DATA: 'CLEAR_CONVERT_DATA',
    CLEAR_BATCH_CONVERT_DATA: 'CLEAR_BATCH_CONVERT_DATA',

    /********************************************************/
    /* Snack Bar
    /********************************************************/
    SNACKBAR_MESSAGE_VIEW: 'SNACKBAR_MESSAGE_VIEW',
    SNACKBAR_CUSTOM_VIEW: 'SNACKBAR_CUSTOM_VIEW',

    /********************************************************/
    /* get filters/groups/recent/today for mega menu
    /********************************************************/
    FILTERS_GET: 'FILTERS_GET',
    FILTERS_GET_PENDING: 'FILTERS_GET_PENDING',
    FILTERS_GET_FULFILLED: 'FILTERS_GET_FULFILLED',
    FILTER_SET_FULFILLED: 'FILTER_SET_FULFILLED',

    OPEN_FILTER_MENU: 'OPEN_FILTER_MENU',
    OPEN_LEFT_MENU_DRAWER: 'OPEN_LEFT_MENU_DRAWER',
    GROUPS_GET: 'GROUPS_GET',
    GROUPS_GET_PENDING: 'GROUPS_GET_PENDING',
    GROUPS_GET_FULFILLED: 'GROUPS_GET_FULFILLED',

    RECENT_RECORD_GET: 'RECENT_RECORD_GET',
    RECENT_RECORD_GET_PENDING: 'RECENT_RECORD_GET_PENDING',
    RECENT_RECORD_GET_FULFILLED: 'RECENT_RECORD_GET_FULFILLED',

    RECENT_SEARCHES_TEXT: 'RECENT_SEARCHES_TEXT',
    RECENT_SEARCHES_RECORD: 'RECENT_SEARCHES_RECORD',
    RECENT_OBJECT_TABLE_LIST: 'RECENT_OBJECT_TABLE_LIST',

    RECENT_ADVANCE_SEARCH_LIST: 'RECENT_ADVANCE_SEARCH_LIST',
    SET_RECENT_ADVANCE_PARTNERS_SEARCH: 'SET_RECENT_ADVANCE_PARTNERS_SEARCH',

    SET_REPORT_RECENT_FILTER: 'SET_REPORT_RECENT_FILTER',
    SET_REPORT_RECENT_RECORD: 'SET_REPORT_RECENT_RECORD',

    /********************************************************/
    /* Notes
    /********************************************************/
    NOTES_GET: 'NOTES_GET',
    NOTES_GET_PENDING: 'NOTES_GET_PENDING',
    NOTES_GET_FULFILLED: 'NOTES_GET_FULFILLED',

    /*******************************************************/
    /* Note Type Get
    /*******************************************************/
    NOTES_TYPE_GET: 'NOTES_TYPE_GET',
    NOTES_TYPE_GET_ALL: 'NOTES_TYPE_GET_ALL',
    UPDATE_SELECTED_NOTES_TYPES: 'UPDATE_SELECTED_NOTES_TYPES',
    UPDATE_NOTES_RECORD_TYPES: 'UPDATE_NOTES_RECORD_TYPES',
    NOTES_TYPE_GET_PENDING: 'NOTES_TYPE_GET_PENDING',
    NOTES_TYPE_GET_FULFILLED: 'NOTES_TYPE_GET_FULFILLED',
    UPDATE_PINNED_NOTES_VALUE: 'UPDATE_PINNED_NOTES_VALUE',
    UPDATE_IS_ALL_SELECTED: 'UPDATE_IS_ALL_SELECTED',

    /********************************************************/
    /* Common Data Transfer
    /********************************************************/
    SET_DATA: 'SET_DATA',

    /********************************************************/
    /* Automation designer
    /********************************************************/
    AUTOMATION_CAMPAIGN_GET: 'AUTOMATION_CAMPAIGN_GET',
    AUTOMATION_CAMPAIGN_GET_FULFILLED: 'AUTOMATION_CAMPAIGN_GET_FULFILLED',
    AUTOMATION_CAMPAIGN_SET_JSPLUMBINST: 'AUTOMATION_CAMPAIGN_SET_JSPLUMBINST',
    AUTOMATION_CAMPAIGN_TEMPLATES_GET: 'AUTOMATION_CAMPAIGN_TEMPLATES_GET',
    AUTOMATION_CAMPAIGN_TEMPLATES_GET_FULFILLED: 'AUTOMATION_CAMPAIGN_TEMPLATES_GET_FULFILLED',
    AUTOMATION_CAMPAIGN_WEBFORM_GET: 'AUTOMATION_CAMPAIGN_WEBFORM_GET',
    AUTOMATION_CAMPAIGN_WEBFORM_GET_FULFILLED: 'AUTOMATION_CAMPAIGN_WEBFORM_GET_FULFILLED',
    AUTOMATION_CAMPAIGN_SET_TEMPLATE: 'AUTOMATION_CAMPAIGN_SET_TEMPLATE',
    AUTOMATION_CAMPAIGN_ADD_NODE: 'AUTOMATION_CAMPAIGN_ADD_NODE',
    AUTOMATION_CAMPAIGN_SET_MOUNTED: 'AUTOMATION_CAMPAIGN_SET_MOUNTED',

    /********************************************************/
    /* Workflow Automation designer
    /********************************************************/

    WORKFLOW_AUTOMATION_GET: 'WORKFLOW_AUTOMATION_GET',
    WORKFLOW_AUTOMATION_GET_FULFILLED: 'WORKFLOW_AUTOMATION_GET_FULFILLED',
    WORKFLOW_AUTOMATION_SET_JSPLUMBINST: 'WORKFLOW_AUTOMATION_SET_JSPLUMBINST',
    WORKFLOW_AUTOMATION_TEMPLATES_GET: 'WORKFLOW_AUTOMATION_TEMPLATES_GET',
    WORKFLOW_AUTOMATION_TEMPLATES_GET_FULFILLED: 'WORKFLOW_AUTOMATION_TEMPLATES_GET_FULFILLED',
    WORKFLOW_AUTOMATION_WEBFORM_GET: 'WORKFLOW_AUTOMATION_WEBFORM_GET',
    WORKFLOW_AUTOMATION_WEBFORM_GET_FULFILLED: 'WORKFLOW_AUTOMATION_WEBFORM_GET_FULFILLED',
    WORKFLOW_AUTOMATION_SET_TEMPLATE: 'WORKFLOW_AUTOMATION_SET_TEMPLATE',
    WORKFLOW_AUTOMATION_ADD_NODE: 'WORKFLOW_AUTOMATION_ADD_NODE',
    WORKFLOW_AUTOMATION_SET_MOUNTED: 'WORKFLOW_AUTOMATION_SET_MOUNTED',
    WORKFLOW_AUTOMATION_ADD_TABS: 'WORKFLOW_AUTOMATION_ADD_TABS',
    WORKFLOW_AUTOMATION_REMOVE_TABS: 'WORKFLOW_AUTOMATION_REMOVE_TABS',
    WORKFLOW_AUTOMATION_CHANGE_TAB_INDEX: 'WORKFLOW_AUTOMATION_CHANGE_TAB_INDEX',


    /********************************************************/
    /* Workflow setup
    /********************************************************/

    GET_WORKFLOW_SETUP_DATA: 'GET_WORKFLOW_SETUP_DATA',
    SET_WORKFLOW_SETUP_STAGE: "SET_WORKFLOW_SETUP_STAGE",
    SET_SELECTED_REQUIRED_FIELDS: 'SET_SELECTED_REQUIRED_FIELDS',
    ADD_NEW_TASKNAME_IN_TASKLIST: 'ADD_NEW_TASKNAME_IN_TASKLIST',
    CARD: 'card',
    SET_IS_FOCUS_ON_EMAIL_TASK: 'SET_IS_FOCUS_ON_EMAIL_TASK',
    SET_IS_FOCUS_ON_MESSAGE_TASK: 'SET_IS_FOCUS_ON_MESSAGE_TASK',
    SET_IS_FOCUS_ON_APPOINTMENT_TASK: 'SET_IS_FOCUS_ON_APPOINTMENT_TASK',
    SET_TASKLIST_ORDER_ERROR_ID: 'SET_TASKLIST_ORDER_ERROR_ID',
    SET_IS_FOCUS_ON_WEBHOOK: 'SET_IS_FOCUS_ON_WEBHOOK',
    WEBHOOKS_CARD: 'Webhooks_card',
    CATEGORY_CARD:'Category_card',
    CHECKLIST_CARD:'Checklist_card',
    CHECKLIST_CHILD_CARD:'Checklist_Child_card',
    UPADTE_AUDIT_CHECKLIST_REARRANGED_QUESTIONS:'UPADTE_AUDIT_CHECKLIST_REARRANGED_QUESTIONS',
    SET_IS_OPEN_EDIT_TASK: 'SET_IS_OPEN_EDIT_TASK',
    SET_IS_ADD_NEW_TASK: 'SET_IS_ADD_NEW_TASK',
    SET_IS_ADD_NEW_TEXT_MESSAGE: 'SET_IS_ADD_NEW_TEXT_MESSAGE',
    SET_IS_ADD_APPOINTMENT_TASK: 'SET_IS_ADD_APPOINTMENT_TASK',
    SET_IS_OPEN_EMAIL_TASK: 'SET_IS_OPEN_EMAIL_TASK',
    SET_IS_SHOW_REQUIRED_FIELD: 'SET_IS_SHOW_REQUIRED_FIELD',
    SET_IS_SHOW_WEBHOOK: 'SET_IS_SHOW_WEBHOOK',
    CLOSE_DRAWER: 'CLOSE_DRAWER',
    UPDATED_WORKFLOW_TASK_ACTION: 'UPDATED_WORKFLOW_TASK_ACTION',
    WORKFLOW_ADD_NEW_TASK_ACTION: 'WORKFLOW_ADD_NEW_TASK_ACTION',
    ADD_VALUE_IN_TASK_LIST: 'ADD_VALUE_IN_TASK_LIST',
    UPDATE_DND_CARD: 'UPDATE_DND_CARD',
    UPDATE_WORKFLOW_STAGE_DND_CARD: 'UPDATE_WORKFLOW_STAGE_DND_CARD',
    UPDATE_WEBHOOK: 'UPDATE_WEBHOOK',
    WORKFLOW_ADD_NEW_WEBHOOK: 'WORKFLOW_ADD_NEW_WEBHOOK',
    DElETE_DND_ROW_CARD: 'DElETE_DND_ROW_CARD',
    WORKFLOW_COPY_TASK_ACTION: 'WORKFLOW_COPY_TASK_ACTION',
    SET_IS_EDIT_TEXT_MESSAGE: 'SET_IS_EDIT_TEXT_MESSAGE',
    SET_IS_OPEN_EDIT_WEBHOOK: 'SET_IS_OPEN_EDIT_WEBHOOK',
    SET_IS_EDIT_APPOINMENT: 'SET_IS_EDIT_APPOINMENT',
    SET_IS_EDIT_EMAIL_TASK: 'SET_IS_EDIT_EMAIL_TASK',
    SET_IS_DND_CARD_DROPPED: 'SET_IS_DND_CARD_DROPPED',
    SET_ACTIONS: 'SET_ACTIONS',
    WORKFLOW_STAGES_CARD: 'WORKFLOW_STAGES_CARD',
    GET_WORKFLOW_PHONENUMBERS_LIST: 'GET_WORKFLOW_PHONENUMBERS_LIST',
    GET_WORKFLOW_EMAIL_TEMPLATE: 'GET_WORKFLOW_EMAIL_TEMPLATE',
    GET_SELECTED_EMAIL_TEMPLATE_DATA: 'GET_SELECTED_EMAIL_TEMPLATE_DATA',
    SET_IS_EDIT_STAGES: 'SET_IS_EDIT_STAGES',
    SET_IS_SHOW_ACTIONS_BUTTONS: 'SET_IS_SHOW_ACTIONS_BUTTONS',
    SET_EDIT_INDEX: 'SET_EDIT_INDEX',
    GET_WORKFLOW_STAGE_DATA: 'GET_WORKFLOW_STAGE_DATA',
    DELETE_WORKFLOW_STAGE: 'DELETE_WORKFLOW_STAGE',
    ADD_NEW_WORKFLOW_STAGE: 'ADD_NEW_WORKFLOW_STAGE',
    UPDATE_WORKFLOW_STAGE: 'UPDATE_WORKFLOW_STAGE',
    GET_EMAIL_TEMPLATE: 'GET_EMAIL_TEMPLATE',
    /********************************************************/
    /* Object Fields
    /********************************************************/
    FIELDS_GET: 'FIELDS_GET',
    FIELDS_GET_FULLFILLED: 'FIELDS_GET_FULLFILLED',

    FIELDS_GET_FULFILLED: 'FIELDS_GET_FULFILLED',

    SF_FROM_DATA_UPDATE: 'SF_FROM_DATA_UPDATE',
    /********************************************************/
    /* Send Mail
    /********************************************************/
    ATTACHMENT_UPLOAD_GET_FULLFILLED: 'ATTACHMENT_UPLOAD_GET_FULLFILLED',
    STORE_SEND_MAIL_DATA: 'STORE_SEND_MAIL_DATA',
    RESET_SEND_MAIL_DATA: 'RESET_SEND_MAIL_DATA',
    UPDATE_SEND_MAIL_DATA: 'UPDATE_SEND_MAIL_DATA',
    UPDATE_SEND_MAIL_TEMPLATE_DATA: 'UPDATE_SEND_MAIL_TEMPLATE_DATA',
    VERIFIED_EMAILS_GET: 'VERIFIED_EMAILS_GET',
    VERIFIED_EMAILS_GET_FULLFILLED: 'VERIFIED_EMAILS_GET_FULFILLED',

    MARKETING_REPORTS_REDUCER_REDIRECTION: 'MARKETING_REPORTS_REDUCER_REDIRECTION',

    /********************************************************/
    /* power call
    /********************************************************/
    POWER_CALLING_GET: 'POWER_CALLING_GET',
    GET_CALL_DRAWER: 'GET_CALL_DRAWER',
    POWER_CALLING_SKIPTED_DATA_GET: 'POWER_CALLING_SKIPTED_DATA_GET',
    POWERCALL_GET_FULLFILLED: 'POWERCALL_GET_FULLFILLED',
    POWERCALL_STATUS_UPDATE: 'POWERCALL_STATUS_UPDATE',
    POWERCALL_IS_STARTED_UPDATE: 'POWERCALL_IS_STARTED_UPDATE',
    POWERCALL_IS_MAXCOUNT_UPDATE: 'POWERCALL_IS_MAXCOUNT_UPDATE',
    POWERCALL_SCRIPT_UPDATE: 'POWERCALL_SCRIPT_UPDATE',
    POWERCALL_SCRIPT_DATA_UPDATE: 'POWERCALL_SCRIPT_DATA_UPDATE',
    POWERCALL_NOTE_DATA_UPDATE: 'POWERCALL_NOTE_DATA_UPDATE',
    POWERCALL_NOTE_BUTTON_VISIBILITY_UPDATE: 'POWERCALL_NOTE_BUTTON_VISIBILITY_UPDATE',
    POWERCALL_FROM_NO_UPDATE: 'POWERCALL_FROM_NO_UPDATE',
    POWERCALL_SKIPTED_IDS_UPDATE: 'POWERCALL_SKIPTED_IDS_UPDATE',
    POWERCALLINNG_PROCESS_END: 'POWERCALLINNG_PROCESS_END',

    /********************************************************/
    /* InXpress Report
    /********************************************************/
    SET_INXPRESS_REPORT_FRANCHISE_LIST: 'SET_INXPRESS_REPORT_FRANCHISE_LIST',
    SET_INXPRESS_REPORT_WORKFLOW_LIST: 'SET_INXPRESS_REPORT_WORKFLOW_LIST',
    SET_INXPRESS_REPORT_DATA: 'SET_INXPRESS_REPORT_DATA',

    /********************************************************/
    /* NotificationComponent > NotificationInfo	
    /********************************************************/
    NOTIFICATION_INFO_GET: 'NOTIFICATION_INFO_GET',
    NOTIFICATION_INFO_GET_FULFILLED: 'NOTIFICATION_INFO_GET_FULFILLED',
    NOTIFICATION_INFO_UPDATE: 'NOTIFICATION_INFO_UPDATE',
    NOTIFICATION_ACCESS_UPDATE: 'NOTIFICATION_ACCESS_UPDATE',

    /********************************************************/

    /*Campaign Type
    /********************************************************/
    CAMAPIGN_TYPE_GET: 'CAMAPIGN_TYPE_GET',
    CAMAPIGN_TYPE_GET_FULFILLED: 'CAMAPIGN_TYPE_GET_FULFILLED',
    CAMAPIGN_TYPE_SET: 'CAMAPIGN_TYPE_SET',
    /********************************************************/
    EMAIL_MARKETING_REPORTS_DETAILS: 'EMAIL_MARKETING_REPORTS_DETAILS',
    EMAIL_MARKETING_REPORTS_DETAILS_SCROLL: 'EMAIL_MARKETING_REPORTS_DETAILS_SCROLL',
    EMAIL_MARKETING_REPORTS_TOGGLE: 'EMAIL_MARKETING_REPORTS_TOGGLE',

    /********************************************************/
    /* OperationsReports > operationsReportData	
    /********************************************************/
    OPERATIONS_REPORT_DATA_UPDATE: 'OPERATIONS_REPORT_DATA_UPDATE',
    UPDATE_OPERATIONS_DUE_BY_DATA:'UPDATE_OPERATIONS_DUE_BY_DATA',

    /********************************************************/
    /* TasksReport > tasksReportData	
    /********************************************************/
    TASKS_REPORT_DATA_UPDATE: 'TASKS_REPORT_DATA_UPDATE',

    /* Assets > Document
    /****************************************************** */
    ASSETS_DOCUMENT: 'ASSETS_DOCUMENT',
    ASSETS_MAIN_DATA_UPDATE: 'ASSETS_MAIN_DATA_UPDATE',
    ASSETS_INBODY_ACTION_UPDATE: 'ASSETS_INBODY_ACTION_UPDATE',
    ASSETS_CALL_FROM_UPDATE: 'ASSETS_CALL_FROM_UPDATE',
    ASSETS_SELECTED_OBJECT_UPDATE: 'ASSETS_SELECTED_OBJECT_UPDATE',
    ASSETS_MAX_CHILD_ID__UPDATE: 'ASSETS_MAX_CHILD_ID__UPDATE',
    ASSETS_SELECTED_TREE_UPDATE: 'ASSETS_SELECTED_TREE_UPDATE',
    ASSETS_CALL_FORM_UPDATE: 'ASSETS_CALL_FORM_UPDATE',
    ASSETS_OBJECT_UPDATE: 'ASSETS_OBJECT_UPDATE',
    ASSETS_EXPANDED_NODE_UPDATE: 'ASSETS_EXPANDED_NODE_UPDATE',
    ASSETS_SELECTED_NODE_UPDATE: 'ASSETS_SELECTED_NODE_UPDATE',
    ASSETS_SELECTED_TYPE_UPDATE: 'ASSETS_SELECTED_TYPE_UPDATE',
    ASSETS_DOCUMENT_IS_UPDATE: 'ASSETS_DOCUMENT_IS_UPDATE',

    /********************************************************/
    /* EmailReport > emailReportData	
    /********************************************************/
    EMAIL_REPORT_DATA_UPDATE: 'EMAIL_REPORT_DATA_UPDATE',

    /********************************************************/
    /* TasksReport > taskReportWorkflows	
    /********************************************************/
    TASKS_REPORT_WORKFLOW_LIST_UPDATE: 'TASKS_REPORT_WORKFLOW_LIST_UPDATE',

    /* PipelineReport > pipelineReportData	
    /********************************************************/
    PIPELINE_REPORT_DATA_UPDATE: 'PIPELINE_REPORT_DATA_UPDATE',

    /* PipelineReport > pipelineReportSalesRep	
    /********************************************************/
    PIPELINE_REPORT_SALESREP_UPDATE: 'PIPELINE_REPORT_SALESREP_UPDATE',

    /* PipelineReport > pipelineReportWorkflows	
    /********************************************************/
    PIPELINE_REPORT_WORKFLOW_LIST_UPDATE: 'PIPELINE_REPORT_WORKFLOW_LIST_UPDATE',

    /********************************************************/
    /* Useful Links
    /********************************************************/
    USEFUL_LINKS_GET: 'USEFUL_LINKS_GET',
    USEFUL_LINKS_GET_PENDING: 'USEFUL_LINKS_GET_PENDING',
    USEFUL_LINKS_GET_FULFILLED: 'USEFUL_LINKS_GET_FULFILLED',

    /* Timeline View  06-Sep-2019 
    /********************************************************/
    GET_TIMELINE_START: 'GET_TIMELINE_START',
    GET_TIMELINE: 'GET_TIMELINE',
    TIMELINE_VIEW_MOUNTED: 'TIMELINE_VIEW_MOUNTED',

    /* Scheduler details 18-Mar-2020 
    /********************************************************/
    SCHEDULER_DETAILS: 'SCHEDULER_DETAILS',
    SCHEDULER_SLUG_GET: 'SCHEDULER_SLUG_GET',
    SCHEDULER_SLUG_GET_FULFILLED: 'SCHEDULER_SLUG_GET_FULFILLED',
    SCHEDULER_WORKFLOW_LIST_GET: 'SCHEDULER_WORKFLOW_LIST_GET',
    SCHEDULER_LINK_DETAILS: 'SCHEDULER_LINK_DETAILS',

    /****************************************** */
    APP_UPDATE_CURRENCY: 'APP_UPDATE_CURRENCY',
    /****************************************** */

    /* ReferralReport > referralReportData */
    /*******************************************/
    REFERRAL_REPORT_DATA_UPDATE: 'REFERRAL_REPORT_DATA_UPDATE',
    /*******************************************/

    UPDATE_QB_SETUP_FLAG: 'UPDATE_QB_SETUP_FLAG',

    UPDATE_XERO_SETUP_FLAG: 'UPDATE_XERO_SETUP_FLAG',

    /** Pending appointment task */
    /*******************************************/
    TASK_APP_GET: 'TASK_APP_GET',
    TASK_APP_GET_FULFILLED: 'TASK_APP_GET_FULFILLED',
    TASK_APP_UPDATE: 'TASK_APP_UPDATE',
    /*******************************************/

    /** Queue List  */
    /*******************************************/
    QUEUE_RECORDS_GET: 'QUEUE_RECORDS_GET',
    QUEUE_RECORDS_GET_PENDING: 'QUEUE_RECORDS_GET_PENDING',
    QUEUE_RECORDS_GET_FULFILLED: 'QUEUE_RECORDS_GET_FULFILLED',
    UPDATE_QUEUE_LIST_DATA: 'UPDATE_QUEUE_LIST_DATA',

    /** Template redux */
    /*******************************************/
    UPDATE_TEMPLATE_LIST: 'UPDATE_TEMPLATE_LIST',
    UPDATE_TEMPLATE_CATEGORIES_LIST: 'UPDATE_TEMPLATE_CATEGORIES_LIST',
    /*******************************************/

    /**   Quotation Redux */
    QUOTATIONS_IS_REFRESH: 'QUOTATIONS_IS_REFRESH',

    /************************************* */
    /***********TreeView Redux */
    TREEVIEW_REFRESH: 'TREEVIEW_REFRESH',
    TREEVIEW_INFO_GET: 'TREEVIEW_INFO_GET',
    TREEVIEW_INFO_GET_PENDING: 'TREEVIEW_INFO_GET_PENDING',
    TREEVIEW_INFO_GET_FULFILLED: 'TREEVIEW_INFO_GET_FULFILLED',
    TREEVIEW_GET: 'TREEVIEW_GET',
    TREEVIEW_GET_FULLFILLED: 'TREEVIEW_GET_FULLFILLED',
    TREEVIEW_SET_SELECTED_RECORD: 'TREEVIEW_SET_SELECTED_RECORD',
    TREEVIEW_GET_FULFILLED: 'TREEVIEW_GET_FULFILLED',
    /********************************** */

    ROYALTY_REPORT_DATA_UPDATE: 'ROYALTY_REPORT_DATA_UPDATE',
    ROYALTY_REPORT_V2_DATA_UPDATE: 'ROYALTY_REPORT_V2_DATA_UPDATE',
    COMPLIANCE_REPORT_DATA_UPDATE: 'COMPLIANCE_REPORT_DATA_UPDATE',

    /** Operation Dashboard Report */
    DASHBOARD_REPORT_INFO_GET_PENDING: 'DASHBOARD_REPORT_INFO_GET_PENDING',
    DASHBOARD_REPORT_INFO_GET_FULFILLED: 'DASHBOARD_REPORT_INFO_GET_FULFILLED',
    DASHBOARD_REPORT_DATA_GET_PENDING: 'DASHBOARD_REPORT_DATA_GET_PENDING',
    DASHBOARD_REPORT_DATA_GET_FULFILLED: 'DASHBOARD_REPORT_DATA_GET_FULFILLED',
    /** *******************/

    /** Report */
    SET_REPORT_FILTER_DATA:'SET_REPORT_FILTER_DATA',

    /**   Purchase Order  Redux */
    PURCHASE_ORDER_IS_REFRESH: 'PURCHASE_ORDER_IS_REFRESH',

    /**
     * Coupons
     */
    COUPONS_GET: 'COUPONS_GET',
    COUPONS_GET_FULFILLED: 'COUPONS_GET_FULFILLED',

    /**
     * audit
     */

    AUDIT_CATEGORY_GET: 'AUDIT_CATEGORY_GET',
    AUDIT_CATEGORY_GET_PENDING: 'AUDIT_CATEGORY_GET_PENDING',
    AUDIT_CATEGORY_GET_FULFILLED: 'AUDIT_CATEGORY_GET_FULFILLED',
    AUDIT_CATEGORY_UPDATE: 'AUDIT_CATEGORY_UPDATE',
    AUDIT_CATEGORY_SELECTED_VALUE_UPDATE: 'AUDIT_CATEGORY_SELECTED_VALUE_UPDATE',
    UPDATE_AUDIT_CATEGORY_DATA: 'UPDATE_AUDIT_CATEGORY_DATA',
    UPDATE_AUDIT_WEAKNESS_DATA:'UPDATE_AUDIT_WEAKNESS_DATA',
    UPDATE_AUDIT_CHECKLIST_CATEGORIES:'UPDATE_AUDIT_CHECKLIST_CATEGORIES',
    UPDATE_FILTERED_RECORDS_FOR_AUDIT: 'UPDATE_FILTERED_RECORDS_FOR_AUDIT',
    SET_AUDIT_ID: 'SET_AUDIT_ID',
    SET_SELCTED_AUDIT_FILTER: 'SET_SELCTED_AUDIT_FILTER',
    UPDATE_AUDIT_ANSWERS: 'UPDATE_AUDIT_ANSWERS',
    UPDATE_AUDIT_PHOTOS: 'UPDATE_AUDIT_PHOTOS',
    UPDATE_AUDIT_ANSWER_IDS: 'UPDATE_AUDIT_ANSWER_IDS',
    UPDATE_AUDIT_TASK:'UPDATE_AUDIT_TASK',
    UPDATE_AUDIT_CATEGORY_LIST_DATA:'UPDATE_AUDIT_CATEGORY_LIST_DATA',
    SET_SELECTED_TASK_FILTER:'SET_SELECTED_TASK_FILTER',
    SET_SELECTED_AUDIT_TASK_TYPE:'SET_SELECTED_AUDIT_TASK_TYPE',
    SET_SELECTED_TASK_TYPE:'SET_SELECTED_TASK_TYPE',
    SET_AUDIT_CHILD_TASK_FILTER:'SET_AUDIT_CHILD_TASK_FILTER',
    SET_AUDIT_PERFORMANCE_DURATION: 'SET_AUDIT_PERFORMANCE_DURATION',
    SET_AUDIT_PERFORMANCE_CATEGORY_DATA: 'SET_AUDIT_PERFORMANCE_CATEGORY_DATA',
    SET_AUDIT_PERFORMANCE_CATEGORIES: 'SET_AUDIT_PERFORMANCE_CATEGORIES',
    SET_AUDIT_PERFORMANCE_QUESTIONS_DATA: 'SET_AUDIT_PERFORMANCE_QUESTIONS_DATA',
    SET_AUDIT_PERFORMANCE_QUESTIONS: 'SET_AUDIT_PERFORMANCE_QUESTIONS',
    SET_AUDIT_PERFORMANCE_REPORT_DATA: 'SET_AUDIT_PERFORMANCE_REPORT_DATA',
    SET_AUDIT_PERFORMANCE_INITIAL_LOAD: 'SET_AUDIT_PERFORMANCE_INITIAL_LOAD',
    SET_AUDIT_PERFORMANCE_REPORT_PARAMS: 'SET_AUDIT_PERFORMANCE_REPORT_PARAMS',
    /*
     * pagination
     */
    UPDATE_SCROLL_POSITION: 'UPDATE_SCROLL_POSITION',
    REMOVE_SCROLL_POSITION: 'REMOVE_SCROLL_POSITION',
    UPDATE_COLUMN_VIEW_SCROLL_POSITION: 'UPDATE_COLUMN_VIEW_SCROLL_POSITION',
    REMOVE_COLUMN_VIEW_SCROLL_POSITION: 'REMOVE_COLUMN_VIEW_SCROLL_POSITION',
    UPDATE_COLUMN_VIEW_HORIZONTAL_SCROLL_POSITION: 'UPDATE_COLUMN_VIEW_HORIZONTAL_SCROLL_POSITION',
    REMOVE_COLUMN_VIEW_HORIZONTAL_SCROLL_POSITION: 'REMOVE_COLUMN_VIEW_HORIZONTAL_SCROLL_POSITION',
    UPDATE_DETAIL_VIEW_SCROLL_POSITION: 'UPDATE_DETAIL_VIEW_SCROLL_POSITION',
    REMOVE_DETAIL_VIEW_SCROLL_POSITION: 'REMOVE_DETAIL_VIEW_SCROLL_POSITION',
    /*
     * nylas config details
     */

    SET_NYLAS_CONFIG_DETAILS: 'SET_NYLAS_CONFIG_DETAILS',

    /**
     * sms
     */
    GET_SMS_DATA: 'GET_SMS_DATA',
    GET_SMS_REFRESH: 'GET_SMS_REFRESH',
    UPDATE_SMS_DATA: 'UPDATE_SMS_DATA',
    UPDATE_SELECTED_CONTACT: 'UPDATE_SELECTED_CONTACT',
    UPDATE_SELECTED_TONUMBER: 'UPDATE_SELECTED_TONUMBER',
    UPDATE_SELECTED_FROM_NUMBER: 'UPDATE_SELECTED_FROM_NUMBER',
    UPDATE_SCROLL_POSITION_FROM_NUMBER: 'UPDATE_SCROLL_POSITION_FROM_NUMBER',
    UPDATE_SCROLL_POSITION_TO_NUMBER: 'UPDATE_SCROLL_POSITION_TO_NUMBER',
    CLEAR_SELECTED_CONTACT: 'CLEAR_SELECTED_CONTACT',
    UPDATE_NEW_SMS: 'UPDATE_NEW_SMS',

    /************************************* */

    // Form
    UPDATE_SF_RECENT_DATA: 'UPDATE_SF_RECENT_DATA',
    CLEAR_SF_RECENT_DATA: 'CLEAR_SF_RECENT_DATA',

    /*
    * sales & refund
    */
    UPDATE_SALES_AND_REFUND_DETAILS: 'UPDATE_SALES_AND_REFUND_DETAILS',
    UPDATE_SALES_AND_REFUND_DATA: 'UPDATE_SALES_AND_REFUND_DATA',


    /*
     * Intake form
     */
    SET_SELECTED_INTAKE_FORM: 'SET_SELECTED_INTAKE_FORM',
    SET_INTAKE_FORM_DATA: 'SET_INTAKE_FORM_DATA',
    SAVE_INTAKE_FORM_ID: 'SAVE_INTAKE_FORM_ID',
    SET_LINK_INTAKE_FORM_DATA: 'SET_LINK_INTAKE_FORM_DATA',
    SET_IS_LOADING: 'SET_IS_LOADING',
    SET_IS_ERROR_INTAKE_FORM: 'SET_IS_ERROR_INTAKE_FORM',
    SET_CUSTOM_TABLE_ONE_AUTOFILL_DATA: 'SET_CUSTOM_TABLE_ONE_AUTOFILL_DATA',
    SET_FORM_ERROR: 'SET_FORM_ERROR',
    DELETE_INTAKE_FORM_DATA: 'DELETE_INTAKE_FORM_DATA',
    UPDATE_SFFORM_REMINDER_FUNC: 'UPDATE_SFFORM_REMINDER_FUNC',
    SET_FIELD_DETAILS : 'SET_FIELD_DETAILS',

    /*
     * BrandDetails Form
     */
    SET_SELECTED_BRAND_DETAILS_FORM:'SET_SELECTED_BRAND_DETAILS_FORM',
    SET_IS_ERROR_SMSVERIFICATION_FORM: 'SET_IS_ERROR_SMSVERIFICATION_FORM',
    SET_SMSVERIFICATION_FORM_ERROR: 'SET_SMSVERIFICATION_FORM_ERROR',

    /*
     * Onboarding New
     */

    SET_WORKFLOW_DATA: 'SET_WORKFLOW_DATA',
    SET_ONBOARDING_WORKFLOW: 'SET_ONBOARDING_WORKFLOW',
    SET_ONBOARDING_REPORT_DATA: 'SET_ONBOARDING_REPORT_DATA',
    SET_ONBOARDING_INITIAL_LOAD: 'SET_ONBOARDING_INITIAL_LOAD',
    SET_ONBOARDING_SORT: 'SET_ONBOARDING_SORT',

    /*
     * Menu Design
     */
    UPDATE_MENU_ITEMS_COPY: 'UPDATE_MENU_ITEMS_COPY',
    UPDATE_MENU_ITEMS_CANCEL: 'UPDATE_MENU_ITEMS_CANCEL',
    UPDATE_MENU_ITEMS_NAME: 'UPDATE_MENU_ITEMS_NAME',
    CREATE_MENU_ITEMS_NAME: 'CREATE_MENU_ITEMS_NAME',
    UPDATE_MENU_ITEMS_SAVE_NAME: 'UPDATE_MENU_ITEMS_SAVE_NAME',
    UPDATE_MENU_ITEMS_DELETE: 'UPDATE_MENU_ITEMS_DELETE',
    GET_ALL_MENU_ITEMS: 'GET_ALL_MENU_ITEMS',
    RESET_MENU_ITEMS: 'RESET_MENU_ITEMS',
    DESKTOP_MENU_NAME_SAVING: 'DESKTOP_MENU_NAME_SAVING',

    /*
    Moneris
    */
    SET_MONERIS_CHECKOUT: 'SET_MONERIS_CHECKOUT',
    SET_MONERIS_CHECKOUT_QUOTE_ID: 'SET_MONERIS_CHECKOUT_QUOTE_ID',
    TOOGLE_MONERIS_CHECKOUT: 'TOOGLE_MONERIS_CHECKOUT',
};
