import React from 'react';
import {connect} from 'react-redux';
import {InputLabel, MenuItem, Select} from '@mui/material';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import moment from 'moment';
import ShowCircularProgress from '../components/circularProgress';
import {
    getArrayParam,
    getBooleanParam,
    getIntParam,
    getStringParam,
    isEmail,
    isFloat,
    isInteger,
    isUrl,
    isValidParam
} from '../../../services/helper/parameterVerifier';
import {styles} from '../../../services/constants/styles';
import {constants} from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import {endPoints} from '../../../services/constants/endPoints';
import {getParentDecision, removeError} from '../../../services/helper/automationUtils';
import {
    dateFormat,
    dateTimeFormat,
    dateTimeFormatFlatPicker,
    phoneFormat,
    sortArrayObjectByProperty
} from '../../../services/helper/utils';
// import { confirm, alert } from '../components/sfDialogs';
import {addNode} from '../../../services/actions/automationCampaignActions';
import {getLocalizedStrings} from '../../../services/constants/MultiLingual';
import Flatpickr from 'react-flatpickr';
import {
    addSourceOnRedux,
    checkDuplicateSourceName,
    saveListValues,
    saveSourceName
} from '../../../services/actions/sfFormActions';
import {getObjectLabelByObject, hasAccessPermission} from '../../../services/helper/common';
import * as sfDialogs from '../components/sfDialogs';

function mapStateToProps(state) {
    return {
        automationCampaign: state.automationCampaign,
        app: state.app,
        sfForm: state.sfForm
    }
}

function mapDispatchToProps(dispatch) {
    return {
        addNode: (arrayNodes) => {
            dispatch(addNode(arrayNodes));
        },
    }
}

class AutomationFieldUpdate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            objectId: 9,
            objectname: constants.CONTACTS_OBJECT,
            value: "zip_code",
            isDisableSelect: false,
            arrayFields: [],
            clickCount: 0,
            isShowChooseBtn: true,
            isShowLoader: true,
            isShowAddAnotherField: false,
            fieldList: [],
            parentActionName: "",
            fieldProps: {},
            qualifier: "",
        }
    }

    componentDidMount() {
        this.populateFieldData();
    }

    selectObject = (event) => {
        let tableId = event.target.value;
        this.setState({
            objectId: tableId,
            isShowChooseBtn: false,
            isShowLoader: true
        });
        let objectname = null;
        if (tableId === 10) {
            objectname = constants.ACCOUNTS_OBJECT;
        } else if (tableId === 9) {
            objectname = constants.CONTACTS_OBJECT;
        }
        // this.generateFieldsList(objectname);
        if (objectname !== null && objectname !== '') {
            this.loadFields(objectname);
        }
    }

    loadFields = (objectname) => {
        let data = Object.assign({}, this.props.sfForm.data[objectname]);
        if (Object.keys(data).length === 0) {
            this.generateFieldsList(objectname);
        } else {
            let fields = data.fields;
            let fieldList = [];
            fields.forEach((objField, i) => {
                if (objectname === constants.CONTACTS_OBJECT) {
                    if (objField.name !== "" && objField.field_type !== 'header' && !objField.is_hidden_field && !objField.is_readonly && objField.name !== "workflow_id" &&
                        objField.name !== "workflow_name" && objField.name !== "opportunity_amount" &&
                        objField.name !== "email_status" && objField.name !== "sales_rep" && objField.name !== "t_status" &&
                        objField.name !== "type" && objField.name !== "visible_to") {
                        fieldList.push(objField);
                    }
                }
                if (objectname === constants.ACCOUNTS_OBJECT) {
                    if (objField.name !== "" && objField.field_type !== 'header' && !objField.is_hidden_field && !objField.is_readonly && objField.name !== "workflow_id" &&
                        objField.name !== "workflow_name" && objField.name !== "opportunity_amount" &&
                        objField.name !== "sales_rep" && objField.name !== "t_type" && objField.name !== "lead_score") {
                        fieldList.push(objField);
                    }
                }
            });
            fieldList = this.sortByKey(fieldList, 'label');
            this.setState({
                fieldList: fieldList,
                objectname: objectname,
                isShowChooseBtn: !this.state.isShowAddAnotherField,
                isShowLoader: false,
            })
        }
    }


    generateFieldsList = (objectName) => {
        let url = null;
        if (objectName === constants.ACCOUNTS_OBJECT) {
            url = endPoints.ACCOUNTS.FIELDS;
        } else if (objectName === constants.CONTACTS_OBJECT) {
            url = endPoints.CONTACTS.FIELDS;
        }
        const promise = Promise.resolve(HTTPClient.get(url, null));
        promise.then((response) => {
            if (response.hasOwnProperty('fields')) {
                let fields = response.fields;
                let fieldList = [];
                fields.forEach((objField, i) => {
                    if (objectName === constants.CONTACTS_OBJECT) {
                        if (objField.name !== "" && objField.field_type !== 'header' && !objField.is_hidden_field && !objField.is_readonly && objField.name !== "workflow_id" &&
                            objField.name !== "workflow_name" && objField.name !== "opportunity_amount" &&
                            objField.name !== "email_status" && objField.name !== "sales_rep" && objField.name !== "t_status" &&
                            objField.name !== "type" && objField.name !== "visible_to") {
                            fieldList.push(objField);
                        }
                    }
                    if (objectName === constants.ACCOUNTS_OBJECT) {
                        if (objField.name !== "" && objField.field_type !== 'header' && !objField.is_hidden_field && !objField.is_readonly && objField.name !== "workflow_id" &&
                            objField.name !== "workflow_name" && objField.name !== "opportunity_amount" &&
                            objField.name !== "sales_rep" && objField.name !== "t_type" && objField.name !== "lead_score") {
                            fieldList.push(objField);
                        }
                    }
                });
                fieldList = this.sortByKey(fieldList, 'label');
                this.setState({
                    fieldList: fieldList,
                    objectname: objectName,
                    isShowChooseBtn: !this.state.isShowAddAnotherField,
                    isShowLoader: false,
                })
            }
        });
    }

    sortByKey = (array, key) => {
        return array.sort(function (a, b) {
            const x = a[key];
            const y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    fetchArrayIndex = (divId) => {
        let index = 0;
        let arrayFields = this.state.arrayFields;
        arrayFields.forEach((objField, i) => {
            if (objField.key === divId) {
                index = i;
            }
        });
        return index;
    }

    removeField = (fieldName) => {
        try {
            let fieldProps = this.state.fieldProps;
            let isShowChooseBtn = this.state.isShowChooseBtn;
            let isShowAddAnotherField = this.state.isShowAddAnotherField;
            let isDisableSelect = this.state.isDisableSelect;
            let fieldList = this.state.fieldList;
            if (fieldProps.hasOwnProperty(fieldName)) {
                delete fieldProps[fieldName];
            }
            let fieldObject = fieldList.filter((el) => el.name === fieldName);
            for (let key in fieldProps) {
                if (fieldProps[key].hasOwnProperty('fieldList')) {
                    let isAddFieldObject = true;
                    fieldProps[key].fieldList.filter((el) => el.name === fieldObject[0].name);
                    if (isAddFieldObject) {
                        fieldProps[key].fieldList.push(fieldObject[0]);
                        fieldProps[key].fieldList = this.sortByKey(fieldProps[key].fieldList, 'label');
                    }
                }
            }
            let fieldPropsLength = Object.keys(fieldProps).length;
            if (fieldPropsLength === 0) {
                isShowChooseBtn = true;
                isShowAddAnotherField = false;
                isDisableSelect = false;
            }
            this.setState({
                fieldProps: fieldProps,
                isShowChooseBtn: isShowChooseBtn,
                isShowAddAnotherField: isShowAddAnotherField,
                isDisableSelect: isDisableSelect
            })
        } catch (error) {
            console.error("Error in 'automationFieldUpdate.js -> removeField()': " + error);
        }
    }

    populateFieldList = (arrayFields, keyCount) => {
        let fieldList = null;
        if (isValidParam(arrayFields)) {
            fieldList = arrayFields.map((objField, i) => {
                return (
                    <MenuItem
                        onClick={() => this.changeField(objField.name, keyCount)}
                        value={objField.name}
                        style={{...styles.popoverMenuItem}}
                        className='menuItemUpdateFields'
                        title={objField.label}
                    >{objField.label}</MenuItem>
                );
            });
        }
        return fieldList;
    }

    changeField = (fieldName, keyCount) => {
        try {
            let fieldProps = this.state.fieldProps;
            if (fieldProps.hasOwnProperty(fieldName)) {
                if (fieldProps[fieldName].hasOwnProperty('fieldList')) {
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.MULTIPLE_TIME_SELECTED_MSG_1 + ' ' + fieldProps[fieldName].t_fieldLabel + ' ' + getLocalizedStrings().message.AUTOMATION_DESIGNER.MULTIPLE_TIME_SELECTED_MSG_2);
                }
            } else {
                let hiddenFieldValue = document.getElementById('field_name_' + keyCount).value;
                if (fieldProps.hasOwnProperty(hiddenFieldValue)) {
                    delete fieldProps[hiddenFieldValue];
                }
                let fieldList = this.state.fieldList;
                let restOfFieldList = this.state.fieldList;
                fieldList = fieldList.filter(function (el) {
                    return el.name === fieldName;
                });
                let objectName = this.state.objectname;
                objectName = objectName.charAt(0).toUpperCase() + objectName.slice(1);
                let objectId = this.state.objectId;
                for (const key in fieldProps) {
                    restOfFieldList = restOfFieldList.filter((el) => el.name !== key);
                }
                let fieldValue = "";
                if (fieldList[0].hasOwnProperty('list_values')) {
                    let listValues = fieldList[0].list_values;
                    listValues = this.sortByKey(listValues, 'value');
                    if (listValues.length > 0) {
                        let listValueLastObject = listValues[listValues.length - 1];
                        if (listValueLastObject.hasOwnProperty('value')) {
                            fieldValue = listValueLastObject.value;
                        }
                    }
                }
                fieldProps[fieldName] = {
                    action_type: "update_fields",
                    id: 0,
                    qualifier_1: this.state.qualifier,
                    t_fieldId: fieldList[0].t_tablefldid,
                    t_fieldLabel: fieldList[0].label,
                    t_fieldName: fieldList[0].name,
                    t_fieldType: fieldList[0].is_email_field ? constants.EMAIL_FORM_FIELD : fieldList[0].field_type_id,
                    t_fieldValue: fieldList[0].name === 'state' ? "" : fieldList[0].list_type === "multi-select" ? [] : fieldValue,
                    t_flag: "",
                    t_hiddenValue: "",
                    t_listType: fieldList[0].list_type_id,
                    t_queryId: fieldList[0].query_id,
                    t_tableId: objectId,
                    tableText: objectName,
                    fieldList: restOfFieldList,
                    isModifyList: fieldList[0].is_modify_list
                }
                this.setState({
                    fieldProps: fieldProps
                });
            }
        } catch (error) {
            console.error("Error in 'automationFieldUpdate.js -> changeField()': " + error);
        }
    }

    changeFieldValue = (value, fieldName) => {
        try {
            let fieldProps = this.state.fieldProps;
            if (fieldProps.hasOwnProperty(fieldName)) {
                let fieldItem = fieldProps[fieldName];
                let isModifyList = getBooleanParam(fieldItem.isModifyList);
                if (isModifyList && (value === '---- ' + getLocalizedStrings().label.COMMON.ADD_NEW_SOURCE + ' ----' ||
                    value === '---- ' + getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST + ' ----')) {
                    fieldItem.isAddToThisList = true;
                    fieldItem.t_fieldValue = '';
                } else if (fieldItem.hasOwnProperty('t_fieldValue')) {
                    fieldItem.t_fieldValue = getArrayParam(value).join(',');
                }

                this.setState({fieldProps: fieldProps});
            }
        } catch (error) {
            console.error("Error in 'automationFieldUpdate.js -> changeFieldValue()': " + error);
        }
    }

    populateFieldData = () => {
        let nodeObject = this.props.data;
        let isDisableSelect = this.state.isDisableSelect;
        let isShowChooseBtn = this.state.isShowChooseBtn;
        let isShowAddAnotherField = this.state.isShowAddAnotherField;
        let objectId = this.state.objectId;
        let parentActionName = this.state.parentActionName;
        let fieldProps = this.state.fieldProps;
        let qualifier = this.state.qualifier;
        if (isValidParam(nodeObject)) {
            if (nodeObject.hasOwnProperty('details')) {
                let detailsArray = nodeObject.details;
                if (nodeObject.hasOwnProperty('childId') && nodeObject.hasOwnProperty('parentId')) {
                    let parentnodeDetails = getParentDecision(this.props.automationCampaign.data.nodes, nodeObject.childId);
                    if (isValidParam(parentnodeDetails)) {
                        if (parentnodeDetails.hasOwnProperty('actionName')) {
                            parentActionName = parentnodeDetails.actionName;
                            if (parentActionName === constants.AUTOMATION_DESIGNER_WEB_FORM_SUBMIT ||
                                parentActionName === constants.AUTOMATION_DESIGNER_WEB_FORM) {
                                objectId = 10;
                            } else {
                                objectId = 9;
                            }
                        }
                    }
                }
                if (nodeObject.hasOwnProperty('parentId') && nodeObject.hasOwnProperty("childId")) {
                    let parentDecission = getParentDecision(this.props.automationCampaign.data.nodes, nodeObject.childId);
                    if (isValidParam(parentDecission)) {
                        if (parentDecission.hasOwnProperty("actionName")) {
                            let actionName = parentDecission.actionName;
                            if (actionName !== null && actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
                                qualifier = constants.AUTOMATION_DESIGNER_DECISION_LINK_CLICKED;
                            } else if (actionName !== null && actionName === constants.AUTOMATION_DESIGNER_EMAIL_OPEN) {
                                qualifier = constants.AUTOMATION_DESIGNER_DECISION_EMAIL_OPENED;
                            } else if (actionName !== null && actionName === constants.AUTOMATION_DESIGNER_UNSUBSCRIBE) {
                                qualifier = constants.AUTOMATION_DESIGNER_DECISION_UNSUBSCRIBED;
                            } else if (actionName !== null && actionName === constants.AUTOMATION_DESIGNER_WEB_FORM) {
                                qualifier = constants.AUTOMATION_DESIGNER_DECISION_WEBFORM_SUBMITTED;
                            }
                        }
                    }
                }
                if (isValidParam(detailsArray)) {
                    detailsArray.forEach((objDetail, i) => {
                        if (i === 0) {
                            objectId = objDetail.t_tableId;
                        }
                        fieldProps[objDetail.t_fieldName] = objDetail;
                    });
                    let fieldPropsLength = Object.keys(fieldProps).length;
                    if (fieldPropsLength > 0) {
                        isShowChooseBtn = false;
                        isShowAddAnotherField = true;
                        isDisableSelect = true;
                    }
                    this.setState({
                        isShowChooseBtn: isShowChooseBtn,
                        isShowAddAnotherField: isShowAddAnotherField,
                        isDisableSelect: isDisableSelect,
                        objectId: objectId,
                        parentActionName: parentActionName,
                        fieldProps: fieldProps,
                        qualifier: qualifier
                    });
                }
                let objectName = null;
                if (objectId === 9) {
                    objectName = constants.CONTACTS_OBJECT;
                } else if (objectId === 10) {
                    objectName = constants.ACCOUNTS_OBJECT;
                }
                if (parentActionName === constants.AUTOMATION_DESIGNER_WEB_FORM ||
                    parentActionName === constants.AUTOMATION_DESIGNER_WEB_FORM_SUBMIT) {
                    objectName = constants.ACCOUNTS_OBJECT;
                }
                this.loadFields(objectName);
            }
        }
    }

    addTextField = (e) => {
        e.stopPropagation();
        try {
            let fieldProps = this.state.fieldProps;
            if (Object.keys(fieldProps).length >= 4) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.ALLOWED_UPTO_FOUR_FIELDS, null);
            } else {
                let fieldList = this.state.fieldList;
                for (const key in fieldProps) {
                    fieldList = fieldList.filter((el) => el.name !== key);
                }
                let lastIndexCount = fieldList.length - 1;
                let lastIndexObject = fieldList[lastIndexCount];
                let objectName = this.state.objectname;
                objectName = objectName.charAt(0).toUpperCase() + objectName.slice(1);
                let objectId = this.state.objectId;
                let fieldValue = "";
                if (lastIndexObject.hasOwnProperty('list_values')) {
                    let listValues = lastIndexObject.list_values;
                    listValues = this.sortByKey(listValues, 'value');
                    if (listValues.length > 0) {
                        let listValueLastObject = listValues[listValues.length - 1];
                        if (listValueLastObject.hasOwnProperty('value')) {
                            fieldValue = listValueLastObject.value;
                        }
                    }
                }
                fieldProps[lastIndexObject.name] = {
                    action_type: "update_fields",
                    id: 0,
                    qualifier_1: this.state.qualifier,
                    t_fieldId: lastIndexObject.t_tablefldid,
                    t_fieldLabel: lastIndexObject.label,
                    t_fieldName: lastIndexObject.name,
                    t_fieldType: lastIndexObject.is_email_field ? constants.EMAIL_FORM_FIELD : lastIndexObject.field_type_id,
                    t_fieldValue: lastIndexObject.name === 'state' ? "" : fieldValue,
                    t_flag: "",
                    t_hiddenValue: "",
                    t_listType: lastIndexObject.list_type_id,
                    t_queryId: lastIndexObject.query_id,
                    t_tableId: objectId,
                    tableText: objectName,
                    fieldList: fieldList,
                }

                let isShowChooseBtn = this.state.isShowChooseBtn;
                let isShowAddAnotherField = this.state.isShowAddAnotherField;
                let isDisableSelect = this.state.isDisableSelect;
                let fieldPropsLength = Object.keys(fieldProps).length;
                if (fieldPropsLength > 0) {
                    isShowChooseBtn = false;
                    isShowAddAnotherField = true;
                    isDisableSelect = true;
                } else if (fieldPropsLength === 0) {
                    isShowChooseBtn = true;
                    isShowAddAnotherField = false;
                    isDisableSelect = false;
                }

                this.setState({
                    isShowChooseBtn: isShowChooseBtn,
                    isShowAddAnotherField: isShowAddAnotherField,
                    isDisableSelect: isDisableSelect,
                });
            }
        } catch (error) {
            console.error("Error in 'automationFieldUpdate.js -> addTextField()': " + error);
        }
    }

    saveUpdateField = () => {
        try {
            let nodeObject = this.props.data;
            let fieldProps = this.state.fieldProps;
            let fieldPropsLength = Object.keys(fieldProps).length;
            if (fieldPropsLength === 0) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.ADD_ONE_FIELD);
            } else {
                let error_msg = null;
                let is_error = false;
                let fieldId = "";
                let fieldList = this.state.fieldList;
                for (let key in fieldProps) {
                    let fieldObject = fieldProps[key];
                    fieldList = fieldList.filter((el) => el.name === key);
                    if (Array.isArray(fieldObject.t_fieldValue)) {
                        fieldObject.t_fieldValue = fieldObject.t_fieldValue.join(", ");
                    }

                    if (fieldObject.hasOwnProperty('t_fieldValue')) {
                        let fieldValue = fieldObject.t_fieldValue;
                        let fieldType = fieldObject.t_fieldType;
                        if (fieldValue === null || fieldValue === undefined || fieldValue === "") {
                            error_msg = getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_FIELD_VALUE;
                            is_error = true;
                            fieldId = key + "##edit";
                            break;
                        } else if (fieldType === constants.URL_FORM_FIELD && !isUrl(fieldValue)) {
                            error_msg = fieldValue + " " + getLocalizedStrings().message.AUTOMATION_DESIGNER.INVALID_URL;
                            is_error = true;
                            fieldId = key + "##edit";
                            break;
                        } else if (fieldType === constants.INTEGER_FORM_FIELD && !isInteger(fieldValue)) {
                            error_msg = fieldValue + " " + getLocalizedStrings().message.AUTOMATION_DESIGNER.INVALID_INTEGER;
                            is_error = true;
                            fieldId = key + "##edit";
                            break;
                        } else if (fieldType === constants.FLOAT_FORM_FIELD && !isFloat(fieldValue)) {
                            error_msg = fieldValue + " " + getLocalizedStrings().message.AUTOMATION_DESIGNER.INVALID_INTEGER;
                            is_error = true;
                            fieldId = key + "##edit";
                            break;
                        } else if (fieldType === constants.EMAIL_FORM_FIELD && !isEmail(fieldValue)) {
                            error_msg = fieldValue + " " + getLocalizedStrings().message.AUTOMATION_DESIGNER.INVALID_EMAIL;
                            is_error = true;
                            fieldId = key + "##edit";
                            break;
                        } else {
                            is_error = false;
                        }
                    }
                }
                if (is_error) {

                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, error_msg, () => this.onconfirmAlert(fieldId));

                } else {
                    let fieldLabels = "";
                    for (let key in fieldProps) {
                        if (fieldLabels === "") {
                            fieldLabels = fieldProps[key].t_fieldLabel;
                        } else {
                            fieldLabels += ", " + fieldProps[key].t_fieldLabel;
                        }
                    }
                    let confirmMsg = fieldLabels + " " + getLocalizedStrings().message.AUTOMATION_DESIGNER.WILL_BE_UPDATED;

                    sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, confirmMsg, () => this.onConfirmFieldUpdate(nodeObject, fieldProps), null);


                }
            }
        } catch (error) {
            console.error("Error in 'automationFieldUpdate.js -> saveUpdateField()': " + error);
        }
    }

    onconfirmAlert = (fieldId) => {
        document.getElementById(fieldId).focus();
    }
    onConfirmFieldUpdate = (nodeObject, fieldProps) => {
        let detailsArray = [];
        for (var key in fieldProps) {
            if (fieldProps[key].hasOwnProperty('fieldList')) {
                delete fieldProps[key]['fieldList'];
            }
            detailsArray.push(fieldProps[key]);
        }
        nodeObject.details = detailsArray;
        nodeObject = removeError(nodeObject);
        this.props.addNode(this.props.automationCampaign.data);
        this.props.closeDrawer();
    }
    generateFieldValueRows = () => {
        let fieldProps = this.state.fieldProps;
        let fieldRows = [];
        let keyCount = 0;
        try {
            if (isValidParam(fieldProps)) {
                for (let key in fieldProps) {
                    ++keyCount;
                    let component = this.generateTypeWiseFields(key, fieldProps);
                    let fieldList = null;
                    let listComponent = null;
                    let fieldName = key;
                    if (fieldProps[key].hasOwnProperty('fieldList')) {
                        fieldList = fieldProps[key].fieldList;
                    }
                    if (fieldList === null) {
                        listComponent = <MenuItem
                            onClick={() => this.changeField(fieldName, keyCount)}
                            value={fieldProps[key].t_fieldName}
                            style={{...styles.popoverMenuItem}}
                            className='menuItemUpdateFields'
                            title={fieldProps[key].t_fieldLabel}>{fieldProps[key].t_fieldLabel}</MenuItem>;
                    } else {
                        listComponent = this.populateFieldList(fieldProps[key].fieldList, keyCount);
                    }

                    fieldRows.push(
                        <div key={"1_" + key} className='row' style={{marginTop: '10px', padding: '1px 10px'}}>
                            <div key={"2_" + key} className='col-sm-4' style={{padding: 0}}>
                                <FormControl variant="outlined" style={{
                                    width: '100%',
                                    marginTop: fieldProps[key].t_fieldName === 'zip_code' ? '2px' : ''
                                }}>
                                    <InputLabel
                                        id="sf-automation-fields-update-from-automationfieldupdate-simple-select-outlined-label"
                                        className="sf-automation-fields-update-from-automationfieldupdates"> </InputLabel>
                                    <Select
                                        key={"3_" + key}
                                        value={fieldProps[key].t_fieldName}
                                        labelId='sf-label-field'
                                        id='sf-field'
                                        style={{height: '42px'}}
                                        className={"sf-fields-bg"}
                                    >
                                        {listComponent}
                                    </Select>
                                </FormControl>
                                <input key={'input_hidden_' + keyCount} type="hidden" id={"field_name_" + keyCount}
                                       value={fieldName}/>
                            </div>

                            <div className='col-sm-6' key={'component_' + keyCount} style={{marginTop: ''}}>
                                {component}
                            </div>

                            <div className='col-sm-1' key={'cross_icon_div_' + keyCount} style={{paddingTop: '14px'}}>
                                <i id={key}
                                   key={'cross_icon_' + keyCount}
                                   className="fa fa-times"
                                   aria-hidden="true"
                                   style={{cursor: 'pointer'}}
                                   onClick={() => this.removeField(fieldName)}
                                />
                            </div>
                        </div>
                    );
                }
            }
        } catch (error) {
            console.error("Error in 'automationFieldUpdate.js -> generateFieldValueRows()': " + error);
        }
        return fieldRows;
    }

    generateTypeWiseFields = (fieldName, fieldProps) => {
        let component = null;
        try {
            let HHFormat = new RegExp("HH");
            let hasPermission = false;
            let datePickerOptions = {
                enableTime: true,
                dateFormat: dateTimeFormatFlatPicker[this.props.app.me.date_format],
                minuteIncrement: 1,
                time_24hr: HHFormat.test(this.props.app.me.date_format)
            };
            hasPermission = hasAccessPermission("", constants.SECURITY_LEVEL_TYPE_PERMISSION, constants.PERMISSION_NAME_ADD_TO_LIST);
            let fieldObject = fieldProps[fieldName];
            if ((fieldObject.t_fieldType === constants.LIST_FORM_FIELD ||
                fieldObject.t_fieldType === constants.LIST_FORM_FIELD_INTEGER_TYPE) && fieldObject.t_fieldName !== 'state') {
                let fieldList = this.state.fieldList;
                let listComponent = null;
                let fieldValue = fieldObject.t_fieldValue;
                let isAddToThisList = getBooleanParam(fieldObject.isAddToThisList);
                if (isAddToThisList) {
                    component =
                        <FormControl style={{width: '100%', marginTop: '-5px'}} className="test" noValidate
                                     autoComplete="off">
                            <TextField
                                variant="outlined"
                                fullWidth={true}
                                key={'text_field_' + fieldName}
                                id={fieldName + "##edit"}
                                value={fieldValue}
                                onChange={(e) => this.setFieldValue(this, fieldName, e.target.value)}
                                onBlur={this.addToThisList.bind(this, fieldName)}
                                autoFocus={true}
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                } else {       
                    const filteredObject = fieldList.find(item => item.label === fieldObject.t_fieldLabel);
                    let listValues = filteredObject.list_values;
                        listComponent = listValues.map((listValueObject, i) => {
                            return (
                                <MenuItem
                                    key={i}
                                    value={listValueObject.value}
                                    onClick={() => this.changeFieldValue(listValueObject.value, fieldName)}
                                    style={{...styles.popoverMenuItem}}
                                    title={listValueObject.value}>{listValueObject.value}</MenuItem>
                            );
                        });

                        let userId = getIntParam(this.props.app.me.id);
                        let isModifyList = getBooleanParam(fieldObject.isModifyList);
                        if (isModifyList && (userId === 1 || hasPermission)) {
                            let primaryText = fieldName === 'source' ? '---- ' + getLocalizedStrings().label.COMMON.ADD_NEW_SOURCE + ' ----' : '---- ' + getLocalizedStrings().label.COMMON.ADD_TO_THIS_LIST + ' ----';
                            listComponent.push(
                                <MenuItem
                                    key={'addto_' + fieldName}
                                    id={'addto_' + fieldName}
                                    value={primaryText}
                                    onClick={() => this.changeFieldValue(primaryText, fieldName)}
                                    style={{...styles.popoverMenuItem}}
                                    title={primaryText}>{primaryText}</MenuItem>
                            );
                        }

                    if (listComponent === null) {
                        listComponent = <MenuItem key={fieldValue} value={fieldValue} title={fieldValue}
                                                  style={{...styles.popoverMenuItem}}>{fieldValue}</MenuItem>
                    }
                    fieldValue = Array.isArray(fieldValue) ? fieldValue : fieldValue.split(",")
                    component = <FormControl variant="outlined" style={{width: '100%'}}>
                        <InputLabel
                            id="sf-automation-fieldupdate-from-automationfieldupdates-simple-select-outlined-label"
                            className="sf-automation-fieldupdate-from-automationfieldupdates-simple"> </InputLabel>
                        <Select
                            key={'select_field_' + fieldName}
                            id={fieldName + "##edit"}
                            multiple
                            title={fieldValue}
                            value={fieldValue}
                            onChange={(event) => this.changeFieldValue(event.target.value, fieldName)}
                            style={{ height: '42px' }}
                            className={"sf-fields-bg"}
                        >
                            {listComponent}
                        </Select>
                    </FormControl>
                }
            } else if (fieldObject.t_fieldType === constants.DATETIME_FORM_FIELD ||
                fieldObject.t_fieldType === constants.DATE_AND_TIME_FORM_FIELD) {
                component = (
                    <div>
                        <Flatpickr
                            style={{
                                borderRadius: '4px',
                                height: '41px',
                                width: '100%',
                                marginBottom: '2px',
                                border: '1px solid rgb(221, 221, 221)',
                                padding: '8.5px 14px'
                            }}
                            id={fieldName + "##edit"}
                            key={'datetime_field_' + fieldName}
                            onChange={this.handleDateChange.bind(this, fieldName)}
                            value={fieldObject.t_fieldValue === 'Invalid date' ? '' : Date.parse(fieldObject.t_fieldValue)}
                            options={datePickerOptions}
                            className='flatpickr'
                        />
                    </div>
                )
            } else {
                let fieldValue = fieldObject.t_fieldValue;
                let fieldList = fieldObject.fieldList;
                if (!isValidParam(fieldList)) {
                    fieldList = this.state.fieldList;
                }
                if (isValidParam(fieldList) && fieldList.length > 0) {
                    fieldList = fieldList.filter((el) => el.name === fieldName);
                    if (fieldList.length > 0 && fieldList[0].hasOwnProperty('is_phone_field')) {
                        if (fieldList[0].is_phone_field) {
                            fieldValue = phoneFormat(fieldValue);
                        }
                    }
                }
                component =
                    <FormControl style={{width: '100%', marginTop: '-5px'}} className="test" noValidate
                                 autoComplete="off">
                        <TextField
                            variant="outlined"
                            fullWidth={true}
                            key={'text_field_' + fieldName}
                            id={fieldName + "##edit"}
                            value={fieldValue}
                            onChange={(e) => this.setFieldValue(this, fieldName, e.target.value)}
                            autoComplete="new-password"
                            margin='dense'
                            size='small'
                            className={"sf-fields-bg"}
                        />
                    </FormControl>
            }
        } catch (error) {
            console.error("Error in 'automationFieldUpdate.js -> generateTypeWiseFields()': " + error);
        }
        return component;
    }

    handleDateChange = (fieldName, date) => {
        try {
            let dateVal = new Date(date);
            let selectedDate = new Date(dateVal.getFullYear(), dateVal.getMonth(), dateVal.getDate(), dateVal.getHours(), dateVal.getMinutes());
            const momentDate = moment(selectedDate);
            if (momentDate !== null && momentDate !== "") {
                let fieldProps = this.state.fieldProps;
                let fieldObject = fieldProps[fieldName];
                if (fieldObject.t_fieldType === constants.DATETIME_FORM_FIELD) {
                    fieldObject.t_fieldValue = momentDate.format(dateFormat[this.props.app.me.date_format]);
                    fieldObject.t_hiddenValue = momentDate.format("YYYY-MM-DD");
                } else if (fieldObject.t_fieldType === constants.DATE_AND_TIME_FORM_FIELD) {
                    fieldObject.t_fieldValue = momentDate.format(dateTimeFormat[this.props.app.me.date_format]);
                    fieldObject.t_hiddenValue = momentDate.format("YYYY-MM-DD HH:mm:ss");
                }
                this.setState({fieldProps: fieldProps,});
            }
        } catch (error) {
            console.error("Error in 'automationFieldUpdate.js -> handleDateChange()': " + error);
        }
    }

    setFieldValue = (event, fieldName, value) => {
        try {
            let fieldProps = this.state.fieldProps;
            if (fieldProps.hasOwnProperty(fieldName)) {
                let fieldItem = fieldProps[fieldName];
                if (fieldItem.hasOwnProperty('t_fieldValue')) {
                    fieldItem.t_fieldValue = value;
                    this.setState({fieldProps: fieldProps});
                }
            }
        } catch (error) {
            console.error("Error in 'automationFieldUpdate.js -> setFieldValue()': " + error);
        }
    }

    addToThisList = (fieldName, event) => {
        try {
            let value = this.state.fieldProps[fieldName].t_fieldValue;
            value = getStringParam(value).trim();
            if (value !== '') {
                if (fieldName === 'source') {
                    let promise = checkDuplicateSourceName(value);
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            this.saveNewListValue(fieldName, value, getBooleanParam(response));
                        });
                    }
                } else {
                    let field = this.state.fieldList.filter(f => {
                        return f.name === fieldName
                    });
                    if (field.length > 0) {
                        let listValues = getArrayParam(field[0].list_values);
                        let tempValues = listValues.filter(f => {
                            return f.value.toLowerCase() === value.toLowerCase()
                        });
                        this.saveNewListValue(fieldName, value, tempValues.length > 0);
                    }
                }
            } else {
                let fieldValue = getStringParam(this.state.fieldProps[fieldName].t_fieldValue).trim();
                if (fieldValue === '') {
                    let field = this.state.fieldList.filter(f => {
                        return f.name === fieldName
                    });
                    let listValues = field.length > 0 ? getArrayParam(field[0].list_values) : [];
                    fieldValue = listValues.length > 1 ? listValues[listValues.length - 1].value : '';
                }
                this.state.fieldProps[fieldName].isAddToThisList = false;
                this.state.fieldProps[fieldName].t_fieldValue = fieldValue;
                this.setState(this.state);
            }
        } catch (error) {
            console.error("Error in 'automationFieldUpdate.js -> addToThisList()':" + error);
        }
    }

    saveNewListValue = (fieldName, value, isExist) => {
        try {
            if (getBooleanParam(isExist)) {
                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, 'Value already exists in List');
            } else if (fieldName === 'source') {
                let promise = saveSourceName(value);
                if (isValidParam(promise)) {
                    promise.then(response => {
                        if (getBooleanParam(response.data)) {
                            addSourceOnRedux(constants.CONTACTS_OBJECT, fieldName, value);
                            this.state.fieldProps[fieldName].isAddToThisList = false;
                            this.state.fieldProps[fieldName].t_fieldValue = value;

                            this.state.fieldList.forEach(field => {
                                if (field.name === fieldName) {
                                    field.list_values.push({id: value, value: value});
                                    sortArrayObjectByProperty(field.list_values, 'value');
                                }
                            });
                            this.setState(this.state);
                        }
                    });
                }
            } else {
                let field = this.state.fieldList.filter(f => {
                    return f.name === fieldName
                });
                if (field.length > 0) {
                    let values = getArrayParam(field[0].list_values);
                    let listValues = [];
                    values.forEach(field => {
                        if (field.value !== '') {
                            listValues.push(field.value);
                        }
                    });
                    listValues.push(value);
                    let promise = saveListValues(this.props.object, field[0].id, listValues);
                    if (isValidParam(promise)) {
                        promise.then(response => {
                            this.state.fieldProps[fieldName].isAddToThisList = false;
                            this.state.fieldProps[fieldName].t_fieldValue = value;

                            this.state.fieldList.forEach(field => {
                                if (field.name === fieldName) {
                                    field.list_values.push({id: value, value: value});
                                    sortArrayObjectByProperty(field.list_values, 'value');
                                }
                            });
                            this.setState(this.state);
                        });
                    }
                }

            }
        } catch (error) {
            console.error("Error in 'automationFieldUpdate.js -> saveNewListValue()':" + error);
        }
    }

    render() {
        let objectLabel = null;
        if (this.state.objectId === 9) {
            objectLabel = getObjectLabelByObject(constants.CONTACTS_OBJECT);
        } else if (this.state.objectId === 10) {
            objectLabel = getObjectLabelByObject(constants.ACCOUNTS_OBJECT);
        }
        let campaignStatus = this.props.automationCampaign.data.status;

        let isShowChooseBtn = this.state.isShowChooseBtn;
        let isShowAddAnotherField = this.state.isShowAddAnotherField;

        if (this.state.fieldList.length <= 0) {
            isShowChooseBtn = false;
        }
        let fieldValueRows = this.generateFieldValueRows();
        return (
            <div style={{paddingTop: '10px', paddingLeft: "20px", paddingRight: "20px"}}>
                <div className='row' style={{padding: '1px 10px'}}>
                    <div className='col-sm-4' style={{padding: 0}}>
                        <FormControl variant="outlined" style={{width: '100%'}}>
                            <InputLabel
                                id="sf-automationfields-updates-fromautomationupdates-result-simple-select-outlined-label"
                                className="sf-automationfields-updates-fromautomationupdates-results"> </InputLabel>
                            <Select
                                value={this.state.objectId}
                                onChange={this.selectObject}
                                fullWidth={true}
                                style={{height: '42px'}}
                                className={"sf-fields-bg"}
                            >
                                <MenuItem
                                    value={10}
                                    title=""
                                    style={{...styles.popoverMenuItem}}>{getObjectLabelByObject(constants.ACCOUNTS_OBJECT)}
                                </MenuItem>
                                <MenuItem
                                    value={9}
                                    title=""
                                    style={{...styles.popoverMenuItem}}>{getObjectLabelByObject(constants.CONTACTS_OBJECT)}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    {
                        (isShowChooseBtn) &&
                        <div className='col-sm-6'>
                            <div id="btnChoose" style={{paddingTop: '10px'}}>
                                <a href style={{cursor: 'pointer'}}
                                   onClick={(e) => this.addTextField(e)}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.CHOOSE}
                                </a> {getLocalizedStrings().label.AUTOMATION_DESIGNER.FIELD_FOR}
                                <span>{` ${objectLabel}`}</span>
                            </div>
                        </div>
                    }
                    {
                        (this.state.isShowLoader) &&
                        <div className='col-sm-6'>
                            <div id="btnChoose" style={{paddingTop: '10px'}}>
                                <div style={{...styles.assetLoaderContainer, height: 40, width: 40, padding: 7}}>
                                    <ShowCircularProgress size={20} style={{marginTop: '3', marginLeft: '3'}}/>
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div id="fieldList" style={{marginTop: '10px'}}>
                    {fieldValueRows}
                    {
                        (isShowAddAnotherField) &&
                        <div style={{marginTop: '10px'}}>
                            <span style={{cursor: 'pointer'}} onClick={(e) => this.addTextField(e)}>
                                <i className="fa fa-plus"
                                   aria-hidden="true"/> {getLocalizedStrings().label.AUTOMATION_DESIGNER.CHOOSE_ANOTHER_FIELD}
                            </span>
                        </div>
                    }
                </div>

                {
                    (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                    <div style={{paddingTop: '12px', paddingLeft: '10px'}}>
                        <Button
                            style={styles.listViewPrimaryButton}
                            onClick={this.saveUpdateField}
                        >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                    </div>
                }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutomationFieldUpdate);


