import React, {useEffect, useRef, useState} from "react";
import {
	getBooleanParam,
	getIntParam,
	getObjectParam,
	getStringParam,
	isValidParam,
	getArrayParam,
} from "../../../services/helper/parameterVerifier";
import {getLocalizedStrings} from "../../../services/constants/MultiLingual";
import {constants} from "../../../services/constants/constants";
import {hasAccessPermission, retriveEscapeHtml,} from "../../../services/helper/common";
import * as HTTPClient from "../../../services/helper/httpClient";
import {getActiveTabInfo, updateTabInfo,} from "../../../services/helper/sfTabManager";
import {endPoints} from "../../../services/constants/endPoints";
import * as sfDialogs from "../components/sfDialogs";
import {styles} from "../../../services/constants/styles";
import { useDispatch, useSelector } from "react-redux";
import { updateChildNoteFunc,updatePinnedNotesSelectedField,updateisAllSelected } from "../../../services/actions/noteActions";
import SFCheckbox from '../../sfPlugins/sfCheckBox';
import { Icon } from "@mui/material";
const NoteBody = (
	{
		id,
		data,
		starredNote,
		isPinned,
		hasPinnedNote,
		style,
		headerStyle,
		noteIndex,
		openEmail,
		sendEmail,
		handleEdit,
		parentRecordId,
		parentObject,
		handleNoteAttachemtUpload,
		handelDelete,
		handleNoteAttachmentDelete,
		getListView,
		handleArchive,
		selectedNoteAuthor,
		getLocalListViewData
	}) => {
	const dispatch = useDispatch();
	const reduxNote = useSelector((state) => state.note);
	const reduxSelectedModuleName = useSelector((state) => state.app.selectedModuleName);
	const reduxMe = useSelector((state) => state.app.me);
	const listView = useSelector((state)=> state.childListView);
	const detailView = useSelector((state)=> state.detailView);

	const [isMouseHovered, setIsMouseHovered] = useState(false);
	const [stateStarredNote, setStateStarredNote] = useState("Empty");
	const [dummyData,setdummyData] = useState(false);

	useEffect(() => {
		let tempStarredNote = isValidParam(starredNote) ? starredNote : "Empty";
		setStateStarredNote(tempStarredNote);
	}, [starredNote]);

	useEffect(()=>{

	},[data,dummyData])

	function showIcons(index) {
		let tempIsPinned = getBooleanParam(isPinned);
		let idPrefix = tempIsPinned ? "pinned-iconList_" : "iconList_";
		const objectIcons = document.getElementById(idPrefix + index);
		setIsMouseHovered(true);
		objectIcons.style.display = "block";
	}

	function hideIcons(index) {
		let tempIsPinned = getBooleanParam(isPinned);
		let idPrefix = tempIsPinned ? "pinned-iconList_" : "iconList_";
		const objectIcons = document.getElementById(idPrefix + index);
		setIsMouseHovered(false);
		objectIcons.style.display = "none";
	}

	function getProperties() {
		let props = {};
		try {
			let tempStyle = getObjectParam(style);
			props.style = {
				lineHeight: "30px",
				paddingTop: "7px",
				paddingBottom: "10px",
				...tempStyle,
			};
			props.onMouseEnter = () => {
				showIcons(noteIndex);
			};
			props.onMouseLeave = () => {
				hideIcons(noteIndex);
			};
		} catch (error) {
			console.error("Error in 'noteBody.js -> getProperty()':" + error);
		}
		return props;
	}

	function getHeaderText() {
		let headerText = "";

		let {author, note_date, note_type} = getObjectParam(data);
		try {
			author = getStringParam(author).trim();
			let date = getStringParam(note_date).trim();
			let type = getStringParam(note_type).trim();
			if (author !== "") {
				headerText = author;
			}

			if (date !== "") {
				headerText += headerText !== "" ? ", " + date : date;
			}

			if (type !== "") {
				headerText += headerText !== "" ? ", " + type : type;
			}

		} catch (error) {
			console.error("Error in 'noteBody.js -> getHeaderText()':" + error);
		}
		return headerText;
	}

	function getHeader() {
		let header = null;
		let bgColor = "#fafafa";
		if (isMouseHovered) {
			bgColor = "#edebeb";
		}

		let tempStyle = {backgroundColor: bgColor, color: "#535353", padding: "0"};
		try {
			let tempIsPinned = getBooleanParam(isPinned);
			let tempHeaderStyle = getObjectParam(headerStyle);
			let tempHasAttachment = hasAttachment();
			let tempIndex = getIntParam(noteIndex);
			let idPrefix = tempIsPinned ? "pinned-iconList_" : "iconList_";
			let iconListId = idPrefix + tempIndex;
			let headerText = getHeaderText();
			if (tempIsPinned || stateStarredNote === "Yes") {
				tempStyle.backgroundColor = "#f7eceb";  // star note header backGround color - #fce8e6

			}

			tempStyle = {...tempStyle, ...tempHeaderStyle};
			header = (
				<div style={tempStyle}>
					<div
						style={{
							paddingLeft: "10px",
							fontWeight: "bold",
							color: "#070100",
						}}
					>
						{headerText} &nbsp;
						{tempHasAttachment && (
							<i
								className="fa fa-paperclip"
								aria-hidden="true"
								style={{paddingRight: "5px"}}
							/>
						)}
					</div>
					<div
						style={{
							float: "right",
							marginTop: "-24px",
							paddingRight: "10px",
							display: "none",
						}}
						id={iconListId}
					>
						{getToolBarActions()}
					</div>
				</div>
			);
		} catch (error) {
			console.error("Error in 'noteBody.js -> getNoteHeader()':" + error);
		}
		return header;
	}

	function getNoteBody() {
		let noteRecord = getObjectParam(data);
		let tempIsPinned = getBooleanParam(isPinned);
		let noteBody = null;
		let tempStyle = {
			color: "#717171",
			paddingLeft: "10px",
			paddingTop: "6px",
			paddingBottom: "6px",
		};
		if (tempIsPinned) {
			tempStyle.backgroundColor = "#f7eceb";

		}
		try {
			let noteText = getNoteText();
			if (noteText != '' && noteText.indexOf("has signed")>=0 && noteText.indexOf("https://s3.amazonaws.com")>=0 && noteText.indexOf('<a') >= 0 && noteText.indexOf('a>') > 0 && noteText.indexOf('http') >= 0) {
                let startIndex = noteText.indexOf('<a');
                let endIndex = noteText.indexOf('a>');
                let lenkTag = startIndex >= 0 && endIndex > 0 ? noteText.slice(startIndex, endIndex + 2) : ''; 
                noteText = noteText.replace(lenkTag, '').trim();

                lenkTag = lenkTag.slice(lenkTag.indexOf('http'));
                let docName = lenkTag.slice(lenkTag.indexOf('>') + 1, lenkTag.indexOf('<'));
                noteText = noteText +" "+ docName;
            }
			noteBody = (
				<div style={tempStyle}>
						{noteRecord.email_process === 3 ? (
							<div style={{ whiteSpace:"pre-wrap",lineHeight: "16px",wordWrap: 'break-word'}}>
								{/* style={{display: "inline-block"}}
								dangerouslySetInnerHTML={{
									__html: retriveEscapeHtml(noteText),
								}} */}
								<span>{retriveEscapeHtml(noteText)}{getOpenEmailElement()}</span>
							</div>
						) : (
							<div style={{ whiteSpace: "pre-wrap",lineHeight: "16px",wordWrap: 'break-word'}}>{unescapeHTML(retriveEscapeHtml(noteText))}{getOpenEmailElement()}</div>
						)}
				</div>
			);
		} catch (error) {
			console.error("Error in 'noteBody.js -> getNoteBody()':" + error);
		}
		return noteBody;
	}

	function hasAttachment() {
		let tempData = data;
		if (Number(data.has_attachments)) {

			if (data.has_attachments != "0") {
				return true;
			} else {
				return false;
			}

		} else {
			let { note_text } = getObjectParam(data);
			try {
				let noteText = getStringParam(note_text).trim();
				return noteText.indexOf("Attachment Info:") > -1;
			} catch (error) {
				console.error("Error in 'noteBody.js -> getAttachmentIcon()':" + error);
			}

		}
	}

	function hasRecordings() {
		let {note_text} = getObjectParam(data);
		try {
			let noteText = getStringParam(note_text).trim();
			return noteText.indexOf("Call Recording:") > -1;
		} catch (error) {
			console.error("Error in 'noteBody.js -> getAttachmentIcon()':" + error);
		}
	}

	function getCheckBoxForNotes (tempData){
		let noteRecord = getObjectParam(tempData);
		let isChecked = false;
		let _value = 0;
		if(noteRecord.isChecked){
			isChecked = true
			_value = 1;
		}else{
			isChecked = false
			_value = 0;
		}
		let temp;
		temp = <SFCheckbox checked={isChecked} value={_value} onChange={(event)=>selectnoteText(event,noteRecord)}/>
		return temp;
	}

	function getToolBarActions() {
		let actionElements = [];
		try {
			const hasEditPermission = hasAccessPermission(
				constants.NOTES,
				constants.SECURITY_LEVEL_TYPE_ACCESS,
				constants.ACCESS_TYPE_EDIT
			);
			const hasDeletePermission = hasAccessPermission(
				constants.NOTES,
				constants.SECURITY_LEVEL_TYPE_ACCESS,
				constants.ACCESS_TYPE_DELETE
			);
			let tempParentObject = getStringParam(parentObject);
			let isFranchise = getStringParam(reduxSelectedModuleName) === constants.MODULE_HUB;
			let noteRecord = getObjectParam(data);
			let tempIsPinned = getBooleanParam(isPinned);
			if (isValidParam(noteRecord) && selectedNoteAuthor.value !=='Archived' ) {
				let isSystemType = reduxNote.noteNameTypeMap[noteRecord.note_type] === 1;
				let tempHasEditPermission = getBooleanParam(hasEditPermission);
				let tempHasDeletePermission = getBooleanParam(hasDeletePermission);
				if (
					tempParentObject === constants.CASES_OBJECT &&
					isFranchise &&
					getStringParam(noteRecord.author) !==
					getStringParam(reduxMe.name)
				) {
					tempHasDeletePermission = false;
				}

				if (
					tempParentObject !== constants.CASES_OBJECT ||
					(tempParentObject === constants.CASES_OBJECT && !isFranchise)
				) {
					if (!isSystemType) {
						let tempStyle = {
							fontSize: 13,
							paddingRight: "0px",
							cursor: "pointer",
						};
						let title = getLocalizedStrings().label.COMMON.UNPIN;

						if (!tempIsPinned) {
							title = getLocalizedStrings().label.COMMON.PIN;
						}else{
							tempStyle.transform = "rotate(-46deg)";
						}
						actionElements.push(
							<span title={title} key="unPinNote">
								<i
									className="fa-solid fa-thumbtack"
									aria-hidden="true"
									style={tempStyle}
									onClick={() => {
										tempIsPinned ? unPinNote(noteRecord.id) : pinNote(noteRecord.id)
									}}
								/>
							</span>
						);

						if (!stateStarredNote || stateStarredNote === "Empty" || stateStarredNote === "") {
							actionElements.push(
								<span title={"Star"} key="star">
									<i
										className="fa fa-star-o"
										aria-hidden="true"
										style={{
											paddingRight: "7px",
											cursor: "pointer",
											marginLeft: "6px",
										}}
										onClick={() => setStarredNote(noteRecord.id, "Yes")}
									/>
								</span>
							);
						} else {
							actionElements.push(
								<span title={"Remove Star"} key="removestar">
									<i
										className="fa fa-star"
										aria-hidden="true"
										style={{
											paddingRight: "7px",
											cursor: "pointer",
											marginLeft: "6px",
										}}
										onClick={() => setStarredNote(noteRecord.id, "Empty")}
									/>
								</span>
							);
						}
					}
				}

				if (
					noteRecord.note_type === "Email" &&
					(noteRecord.email_process.toString() === "2" || noteRecord.email_process.toString() === "3")
				) {
					actionElements.push(
						<span title={getLocalizedStrings().label.COMMON.REPLY} key="reply">
							<i
								className="fa fa-reply"
								aria-hidden="true"
								style={{paddingRight: "5px", cursor: "pointer"}}
								onClick={() => sendEmail("REPLY", noteRecord.id)}
							/>
						</span>
					);
					actionElements.push(
						<span
							title={getLocalizedStrings().label.COMMON.REPLY_ALL}
							key="replyall"
						>
							<i
								className="fa fa-reply-all"
								aria-hidden="true"
								style={{paddingRight: "5px", cursor: "pointer"}}
								onClick={() => sendEmail("REPLY-ALL", noteRecord.id)}
							/>
						</span>
					);
					actionElements.push(
						<span
							title={getLocalizedStrings().label.COMMON.FORWARD}
							key="forward"
						>
							<i
								className="fa fa-share"
								aria-hidden="true"
								style={{paddingRight: "5px", cursor: "pointer"}}
								onClick={() => sendEmail("FORWARD", noteRecord.id)}
							/>
						</span>
					);
				}

				if (
					tempParentObject !== constants.CASES_OBJECT ||
					(tempParentObject === constants.CASES_OBJECT && !isFranchise)
				) {
					if (!isSystemType) {
						actionElements.push(
							<span
								title={getLocalizedStrings().label.ATTACHMENT.ATTACHMENT}
								key="attachment"
							>
								<i
									className="fa fa-paperclip"
									aria-hidden="true"
									style={{paddingRight: "5px", cursor: "pointer"}}
									onClick={() => handleNoteAttachemtUpload(noteRecord.id, tempIsPinned)}
								/>
							</span>
						);
					}

					if (!isSystemType && tempHasEditPermission) {
						actionElements.push(
							<span title={getLocalizedStrings().label.COMMON.EDIT} key="edit">
								<i
									className="fa fa-pencil"
									aria-hidden="true"
									style={{
										// paddingRight: "5px",
										cursor: "pointer"}}
									onClick={() => handleEdit(noteRecord.id, tempIsPinned, stateStarredNote)}
								/>
							</span>
						);
					}
				}

				// if (tempHasDeletePermission) {
				// 	actionElements.push(
				// 		<span
				// 			title={getLocalizedStrings().label.COMMON.REMOVE}
				// 			key="remove"
				// 		>
				// 			<i
				// 				className="fa fa-times"
				// 				aria-hidden="true"
				// 				style={{cursor: "pointer", fontSize: "15px"}}
				// 				onClick={() => handelDelete(noteRecord.id, tempIsPinned)}
				// 			/>
				// 		</span>
				// 	);
				// }
				
				if (!tempIsPinned){
					actionElements.push(
						<span
							title={getLocalizedStrings().label.COMMON.ARCHIVE}
							key="archive"
							onClick={()=>handleArchive(noteRecord)} 
						>
							<Icon style={{
								fontSize: '15px',
								color: '#717171',
								cursor: 'pointer',
								paddingTop: '3px',
								// paddingLeft: '3px',
								opacity: 1,
								width:'25px'
							}}>archive</Icon>
						</span>
					);
				}

			} else if (isValidParam(noteRecord) && !tempIsPinned ) {
				actionElements.push(
					<span
						title={getLocalizedStrings().label.COMMON.UNARCHIVE}
						key="unarchive"
						onClick={()=>handleArchive(noteRecord)} 
					><Icon style={{
							fontSize: '15px',
							color: '#717171',
							cursor: 'pointer',
							paddingTop: '3px',
							paddingLeft: '3px',
							opacity: 1,
							width:'25px'
						}}>unarchive</Icon>
						
					</span>
				);
			}
		} catch (error) {
			console.error("Error in 'noteBody.js -> getToolBarActions()':" + error);
		}
		return actionElements;
	}

	function unescapeHTML(html) {
		const escapeEl = document.createElement("textarea");
		escapeEl.innerHTML = html;
		return escapeEl.textContent;
	}

	function getOpenEmailElement() {
		let {note_type, email_process} = getObjectParam(data);
		let element = null;
		try {
			let noteType = getStringParam(note_type).trim();
			if (noteType === "Email" && (email_process == 2 || email_process == 3)) {
				element = <span style={{ paddingLeft: '10px' }}>
				<a href="javascript:void(0)" onClick={() => openEmail(data, isPinned)}>
					{getLocalizedStrings().label.COMMON.MORE + "..."}
				</a>
			</span>;
			}
		} catch (error) {
			console.error("Error in 'noteBody.js -> getAttachmentIcon()':" + error);
		}
		return element;
	}

	function getAllNoteAttachments() {
		let arrAttachment = [];
		let {note_text} = getObjectParam(data);
		try {
			let noteText = getStringParam(note_text).trim();
			if (hasAttachment()) {
				let tempArr = noteText.split("Attachment Info:");
				tempArr = tempArr.length > 1 ? tempArr.slice(1) : [];

				arrAttachment = tempArr.map(sliceSplitAttachmentObject => {
					let strAttachment = sliceSplitAttachmentObject.trim();
					let attachmentInfoArray = [];
					while (
						strAttachment !== "" &&
						strAttachment.indexOf("<a") >= 0 &&
						strAttachment.indexOf("a>") > 0 &&
						strAttachment.indexOf("http") >= 0
						) {
						let startIndex = strAttachment.indexOf("<a");
						let endIndex = strAttachment.indexOf("a>");
						let lenkTag =
							startIndex >= 0 && endIndex > 0
								? strAttachment.slice(startIndex, endIndex + 2)
								: "";
						strAttachment = strAttachment.replace(lenkTag, "").trim();

						lenkTag = lenkTag.slice(lenkTag.indexOf("http"));
						let httpUrl = lenkTag.slice(0, lenkTag.indexOf("'"));
						let docName = lenkTag.slice(
							lenkTag.indexOf(">") + 1,
							lenkTag.indexOf("<")
						);
						attachmentInfoArray.push({
							name: docName,
							url: httpUrl,
							isMailAttach: httpUrl.indexOf("/mail/"),
						});
					}
					let totalAttachmentCount = attachmentInfoArray.length;
					let newAttachmentHtml = attachmentInfoArray.map(
						(attachInfoObject, k) => {
							return (
								<NoteAttachment
									key={"attachment-" + k}
									data={attachInfoObject}
									noteId={id}
									totalAttachmentCount={totalAttachmentCount}
									handleNoteAttachmentDelete={handleNoteAttachmentDelete}
								/>
							);
						}
					);
					return (
						<div
							style={{
								color: "#717171",
								paddingLeft: "10px",
								lineHeight: "25px",
							}}
						>
							{
								getLocalizedStrings().label.DETAIL_VIEW_CHILD_NOTES
									.ATTACHMENT_INFO
							}
							:<br/>
							{newAttachmentHtml}
						</div>
					);
				});
			}
		} catch (error) {
			console.error(
				"Error in 'noteBody.js -> getAllNoteAttachments()':" + error
			);
		}
		return arrAttachment;
	}


	function getAllCallRecordings() {
		let arrRecordings = [];
		let {note_text} = getObjectParam(data);
		try {
			let noteText = getStringParam(note_text).trim();
			if (hasRecordings()) {
				let tempArr = noteText.split("Attachment Info:");
				let tempBody =
					tempArr.length > 0 ? getStringParam(tempArr[0]).trim() : "";

				if (tempBody.indexOf("Call Recording:") > 0) {
					let splitRecording = tempBody
						.replace(/\n/g, " ")
						.split("Call Recording:");
					let recordingList = splitRecording[1].split("<a href=");
					arrRecordings = recordingList.slice(1).map((recordingAnchor, k) => {
						return (
							<CallRecordingsForNote
								key={"recordings-" + k}
								data={recordingAnchor}
							/>
						);
					});
				}
			}
		} catch (error) {
			console.error(
				"Error in 'noteBody.js -> getAllCallRecordings()':" + error
			);
		}
		return arrRecordings;
	}

	function getNoteText() {
		let {note_text} = getObjectParam(data);
		let noteText = getStringParam(note_text);
		try {
			noteText = noteText.split("Attachment Info:")[0];
			noteText = noteText.split("Call Recording:")[0];
		} catch (error) {
			noteText = "";
			console.error("Error in 'noteBody.js -> getNoteText()':" + error);
		}
		return noteText;
	}

	function pinNote(id) {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let tempParentRecordId = getIntParam(parentRecordId);
		let tempParentObject = getStringParam(parentObject);
		let tempHasPinnedNote = getBooleanParam(hasPinnedNote);

		let isUnitsAccount = false;
        if (isValidParam(tabInfo) && tabInfo.isUnitsAccount) {
            isUnitsAccount = tabInfo.isUnitsAccount;
        }
        if (!isInXpress && !isUnitsAccount) {
			if (tempHasPinnedNote) {
				let msg = getLocalizedStrings().message.PINNED_NOTES.CONFIRM_MESSAGE;
				// let promiseConfirmation = new Promise(function (resolve, reject) {
				sfDialogs.confirm(
					getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE,
					msg,
					() => processPinNote(id, tempParentRecordId, tempParentObject),
					null
				);
			} else {
				processPinNote(id, tempParentRecordId, tempParentObject);
			}
		}
		else{
            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.COMMON.ACCESS_DENIED, null);
        }
		return
	}

	function processPinNote(id, parentRecordId, parentObject) {
		let params = {};
		params["id"] = id;
		params["parent_record_id"] = parentRecordId;
		params["parent_object"] = parentObject;
		let promise = Promise.resolve(HTTPClient.post(endPoints.NOTES.PIN, params));
		promise.then(() => {
			updateTabInformation();
			getListView();
		});
	}

	function unPinNote(id) {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		let tempParentRecordId = getIntParam(parentRecordId);
		let tempParentObject = getStringParam(parentObject);
		if (!isInXpress) {
			let params = {};
			params["id"] = id;
			params["parent_record_id"] = tempParentRecordId;
			params["parent_object"] = tempParentObject;
			let promise = Promise.resolve(
				HTTPClient.post(endPoints.NOTES.UNPIN, params)
			);
			promise.then(() => {
				updateTabInformation();
				getListView();
			});
		}
	}

	function setStarredNote(id, type) {
		let tabInfo = getActiveTabInfo();
		let isInXpress = getBooleanParam(tabInfo.isInXpress);
		if (!isInXpress) {
			let params = {};
			params["id"] = id;
			params["t_important_note"] = type;
			let promise = Promise.resolve(
				HTTPClient.post(endPoints.NOTES.STARRED, params)
			);
			promise.then(() => {
				setStateStarredNote(type);
				let listViewParams = {
					start_index: tabInfo.startIndex,
					page_size: tabInfo.pageSize,
					is_list_view_update: false
				};
				getLocalListViewData(constants.NOTES, listViewParams)
			});
		}
	}

	function updateTabInformation() {
		let tabInfo = getActiveTabInfo();
		delete tabInfo.pageSize;
		delete tabInfo.selectedPageNo;
		delete tabInfo.startIndex;
		delete tabInfo.startPageNo;
		updateTabInfo(tabInfo);
	}

	const selectnoteText = (event,noteRecord) =>{
		let selectedId = noteRecord.id;
		let isChecked = event.target.checked;
		let notesData = isValidParam(listView) && isValidParam(listView.data) ?
            getArrayParam(listView.data.records) : [];
		let PinnedNotesData = isValidParam(detailView) && isValidParam(detailView.pinnedNote) ?
			getArrayParam(detailView.pinnedNote.pinnedNotes) : [];
		let isAvaialble = notesData.find((ele)=>ele.id === selectedId );
		if(isAvaialble){
			notesData = notesData?.map((ele)=>{
				if(ele.id === selectedId){
					ele.isChecked = isChecked;
				}
				return ele;
			})
			dispatch(updateChildNoteFunc(notesData));
		}else{
			PinnedNotesData = PinnedNotesData?.map((ele)=>{
				if(ele.id === selectedId){
					ele.isChecked = isChecked;
				}
				return ele;
			})
			dispatch(updatePinnedNotesSelectedField(PinnedNotesData));
		}
		if(!isChecked){
			dispatch(updateisAllSelected(false));
		}else if(isChecked){
			let _isAllSelected = true;
			notesData.map((ele)=>{
				if(!ele.isChecked){
					_isAllSelected =  false;
				}
			})
			PinnedNotesData.map((ele)=>{
				if(!ele.isChecked){
					_isAllSelected =  false;
				}
			})
			if(_isAllSelected){
				dispatch(updateisAllSelected(true));
			}
		}

	}

	let props = getProperties();
	let noteHeader = getHeader();
	let noteBody = getNoteBody();
	let allNoteAttachments = getAllNoteAttachments();
	let allCallRecordings = getAllCallRecordings();
	let tempIsPinned = getBooleanParam(isPinned);

	return (
		<div {...props} >
			<div style={{width:'100%'}}>
				<div style={{display:'flex',width:'100%'}}>
					<div style={{width:'33px'}}>
						{getCheckBoxForNotes(data)}
					</div>
					<div style={{width:'100%'}}>
						{noteHeader}
					</div>
				</div>
				<div style={{width:'calc(100% - 32px)',marginLeft:'32px',marginTop:tempIsPinned ? '-4px' :''}}>
					{noteBody}
					<div style={{minHeight:'1px',overflow:'hidden'}}>
						{allNoteAttachments}
					</div>
					{allCallRecordings}
				</div>
			</div>
		</div>
	);
}

const NoteAttachment = ({noteId, data, totalAttachmentCount, handleNoteAttachmentDelete}) => {
	let tempNoteId = getIntParam(noteId);
	let attachment = getObjectParam(data);
	let totalAttachment = getIntParam(totalAttachmentCount);

	const dowload =(attachment)=>{
        let fileparams = {}
        var formData = new FormData();
        let url = endPoints.ATTACHMENT.DOWNLOAD_ATTACHMENT;
        let documentName = getStringParam(attachment.name);
        fileparams = {'url': attachment.url,'name':documentName};
        formData.append('input_param', JSON.stringify(fileparams));
        if (documentName != '') {
                HTTPClient.postFormDataDownloadFile(url, formData, documentName);
           
        }
            
    }
	return (
		<div style={{width: "100%"}}>
			<i
				className="fa fa-paperclip"
				aria-hidden="true"
				style={{paddingRight: "5px", cursor: "pointer"}}
			/>
			<a href='javascript:void(0);' onClick={()=>dowload(attachment)} > {attachment.name} </a>
			{attachment.url.indexOf("/mail/") < 0 && (
				<i
					className="fa fa-times"
					aria-hidden="true"
					style={{paddingRight: "5px", cursor: "pointer"}}
					onClick={() => handleNoteAttachmentDelete(tempNoteId, attachment.name, totalAttachment)}
				/>
			)}
		</div>
	);
}

const CallRecordingsForNote = ({data}) => {
	let dataStr = getStringParam(data);
	let postDownloadStr = dataStr.substring(dataStr.indexOf("downloadRecording("));
	let recordingId = postDownloadStr.substring(
		postDownloadStr.indexOf("('") + 2,
		postDownloadStr.indexOf("')")
	);
	return (
		<div style={{width: "100%"}} >
			<i
				className="fa fa-paperclip"
				aria-hidden="true"
				style={{paddingRight: "5px", cursor: "pointer"}}
			/>
			<span
				style={{...styles.downloadableLink}}
				title={getLocalizedStrings().label.ATTACHMENT.DOWNLOAD_RECORDING}
				onClick={() => {
					let documentName = recordingId + ".mp3";
					const formData = new FormData();
					formData.append("input_param", JSON.stringify({id: recordingId}));

					HTTPClient.postFormDataDownloadFile(
						endPoints.ATTACHMENT.DOWNLOAD_RECORDING,
						formData,
						documentName
					);
				}}
			>
				{" "}
				{getLocalizedStrings().label.ATTACHMENT.DOWNLOAD_RECORDING}{" "}
			</span>
		</div>
	);
}

export const NotesFilterPromptMessage = ({notesFilterPromptMsg}) => {
	const timerRef = useRef(null);
	useEffect(() => {
		if (document.getElementById("notifnMsg") !== null) {
			timerRef.current = setTimeout(function () {
				document.getElementById("notifnMsg").style.display = "none";
			}, 4000);
		}
		return () => {
			if (timerRef.current !== null) {
				clearTimeout(timerRef.current);
			}
		}
	}, []);

	return (
		<div style={{paddingTop: "10px"}} id="notifnMsg">
			<div
				className="mui--align-middle"
				style={{
					paddingLeft: "10px",
					visibility: "visible",
					paddingTop: "2px",
					backgroundColor: "#d1ecf1",
					fontSize: "15px",
					height: "25px",
				}}
			>
				{notesFilterPromptMsg}
			</div>
		</div>
	);
}

export default NoteBody;