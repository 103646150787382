import { endPoints } from '..//constants/endPoints';
import { isValidParam, getStringParam, getObjectParam, getIntParam } from '..//helper/parameterVerifier';
import { constants } from '..//constants/constants';
import { getActiveTab, TYPE_COLUMN_VIEW, TYPE_HOME } from '..//helper/sfTabManager';
import * as HTTPClient from '..//helper/httpClient';
import store from '../../../src/services/store';
import { actions } from '..//constants/actions';
import { getCookie } from '../helper/sfCookies';

export const DEFAULT_PAGE_SIZE = 10;

function moveList(lastX, nextX) {
  return (dispatch) => {
    dispatch({ type: actions.MOVE_LIST, lastX, nextX });
  };
}

function moveCard(lastX, lastY, nextX, nextY) {
  return (dispatch) => {
    dispatch({ type: actions.MOVE_CARD, lastX, lastY, nextX, nextY });
  };
}

function moveTaskList(lastX, nextX) {
  return (dispatch) => {
    dispatch({ type: actions.MOVE_TASK_LIST, lastX, nextX });
  };
}

function moveTaskCard(lastX, lastY, nextX, nextY) {
  return (dispatch) => {
    dispatch({ type: actions.MOVE_TASK_CARD, lastX, lastY, nextX, nextY });
  };
}

function toggleDragging(isDragging) {
  return (dispatch) => {
    dispatch({ type: actions.TOGGLE_DRAGGING, isDragging });
  };
}

function getLists(object, params) {
  return dispatch => {
    let state = store.getState()
    dispatch({ type: actions.GET_LISTS_START, lists: [], isFetching: false, object, mounted: false });

    var promise = Promise.resolve(getListsData(object, params));
    if (isValidParam(promise)) {
      promise.then((response) => {
        let data = response;
        let communicationFields = null;
        let sales_rep = "";
        let activeTab = getActiveTab();
        activeTab = getObjectParam(activeTab);
        let tabType = getStringParam(activeTab.type);
        if (TYPE_COLUMN_VIEW === tabType || (tabType === TYPE_HOME && activeTab.object === constants.TASKS) || params.call_from === "columnview") {
          if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT || object === constants.TASKS) {
            let state = store.getState();
            let sfForm = state.sfForm.data;
            communicationFields = {};
            let objects = Object.keys(sfForm);
            let phoneSetup = data.phoneSetup;
            objects.forEach(function (object) {
              let communication_field = sfForm[object].fields.filter(f => f.is_email_field === true || f.is_phone_field === true);
              let communication_temp_field = [];
              communication_field.forEach((field, i) => {
                if (field.is_phone_field) {
                  field = { ...field, ...phoneSetup };
                }
                communication_temp_field.push(field);
              })
              communicationFields[object] = communication_temp_field;
            });
            if (object === constants.TASKS) {
              sales_rep = data.salesRep;
            }

          }

        }
        dispatch({
          type: actions.GET_LISTS,
          payload: {
            lists: data?.list,
            taskLists: state.lists.taskLists,
            sort_field_name: data?.sort_field_name,
            sort_type: data?.sort_type,
            isFetching: true,
            fields: data?.fields,
            communicationFields: communicationFields,
            selectedFields: data?.selected_fields,
            workflowName: params.workflowName,
            queryName: params.query_name,
            queryType: params.query_type,
            groupByFieldName: params.group_by_field_name,
            groupByFieldNameSort: params.group_by_field_name_sort,
            workFlowId: data?.workflow_id !== null && data?.workflow_id > 0 ? data?.workflow_id : -9999,
            totalCount: data?.total_count,
            mounted: true,
            sales_rep: sales_rep,
            object: object,
            view: 'ColumnView',
            isFilterChanged: true,
            actions: data?.actions,
            totalLeadScore: data?.total_lead_score,
            queryInfo: data?.queryInfo,
            state
          }
        });
      });
    }
  };
}

function setListsObject(object) {
  return (dispatch) => {
    dispatch({ type: actions.SET_LIST_OBJECT, payload: object });
  };
}
function getTaskTypes(params) {
  try {
      let url = endPoints.NOTE_TYPE.LIST_VIEW;;
      return HTTPClient.get(url, params);   
  }
  catch (error) {
      console.error("Error in 'auditActions.js --> getAuditCategory()':" + error);
  }
}
function getTaskLists(object, params) {
  return dispatch => {
    let state = store.getState()
    dispatch({ type: actions.GET_TASK_LISTS_START, lists: [], isFetching: false, object });

    var promise = Promise.resolve(getListsData(object, params));
    if (isValidParam(promise)) {
      promise.then((response) => {
        let data = response;
        let communicationFields = null;
        let sales_rep = "";
        let activeTab = getActiveTab();
        activeTab = getObjectParam(activeTab);
        let tabType = getStringParam(activeTab.type);
        if (TYPE_COLUMN_VIEW === tabType || (tabType === TYPE_HOME && activeTab.object === constants.TASKS) || params.call_from === "columnview") {
          if (object === constants.ACCOUNTS_OBJECT || object === constants.CONTACTS_OBJECT || object === constants.OPPORTUNITIES_OBJECT || object === constants.TASKS) {
            let state = store.getState();
            let sfForm = state.sfForm.data;
            communicationFields = {};
            let objects = Object.keys(sfForm);
            let phoneSetup = data.phoneSetup;
            objects.forEach(function (object) {
              let communication_field = sfForm[object].fields.filter(f => f.is_email_field === true || f.is_phone_field === true);
              let communication_temp_field = [];
              communication_field.forEach((field, i) => {
                if (field.is_phone_field) {
                  field = { ...field, ...phoneSetup };
                }
                communication_temp_field.push(field);
              })
              communicationFields[object] = communication_temp_field;
            });
            if (object === constants.TASKS) {
              sales_rep = data.salesRep;
            }

          }

        }
        dispatch({
          type: actions.GET_TASK_LISTS,
          payload: {
            taskLists: data?.list,
            sort_field_name: data?.sort_field_name,
            sort_type: data?.sort_type,
            isFetching: true,
            taskFields: data?.fields,
            communicationFields: communicationFields,
            selectedFields: data?.selected_fields,
            workflowName: params.workflowName,
            queryName: params.query_name,
            queryType: params.query_type,
            groupByFieldName: params.group_by_field_name,
            groupByFieldNameSort: params.group_by_field_name_sort,
            workFlowId: data?.workflow_id !== null && data?.workflow_id > 0 ? data?.workflow_id : -9999,
            totalCount: data?.total_count,
            mounted: true,
            sales_rep: sales_rep,
            object: object,
            view: 'ColumnView',
            isFilterChanged: true,
            actions: data?.actions,
            totalLeadScore: data?.total_lead_score,
            queryInfo: data?.queryInfo,
            state
          }
        });
      });
    }
  };
}

function getListsData(object, params) {
  let url = null;
  let callFrom = null;
  callFrom = params.call_from;
  try {
    if (object === constants.ACCOUNTS_OBJECT) {
      url = endPoints.ACCOUNTS.COLUMN_VIEW;
    } else if (object === constants.OPPORTUNITIES_OBJECT) {
      if (callFrom !== null && callFrom === 'columnview') {
        url = endPoints.OPPORTUNITIES.COLUMN_VIEW;
      } else {
        url = endPoints.OPPORTUNITIES.FORECAST_REPORT_GET;
      }
    } else if (object === constants.CONTACTS_OBJECT) {
      url = endPoints.CONTACTS.COLUMN_VIEW;
    } else if (object === constants.CASES_OBJECT) {
      url = endPoints.CASES.COLUMN_VIEW;
    } else if (object === constants.PROJECTS_OBJECT) {
      url = endPoints.PROJECTS.COLUMN_VIEW;
    } else if (object === constants.ISSUES_OBJECT) {
      url = endPoints.ISSUES.COLUMN_VIEW;
    } else if (object === constants.CUSTOM_TABLE1_OBJECT) {
      url = endPoints.CUSTOM_TABLE1.COLUMN_VIEW;
    } else if (object === constants.CUSTOM_TABLE2_OBJECT) {
      url = endPoints.CUSTOM_TABLE2.COLUMN_VIEW;
    } else if (object === constants.CUSTOM_TABLE3_OBJECT) {
      url = endPoints.CUSTOM_TABLE3.COLUMN_VIEW;
    } else if (object === constants.CUSTOM_TABLE4_OBJECT) {
      url = endPoints.CUSTOM_TABLE4.COLUMN_VIEW;
    } else if (object === constants.TASKS) {
      url = endPoints.TASK.TASK_PAGE_GET;
    } else if (object === constants.JOBS_OBJECT) {
      url = endPoints.JOBS.COLUMN_VIEW;
    }
    return HTTPClient.get(url, params);
  } catch (e) { console.error("Error in 'lists.js -> getListsData()':" + e); }
}

function getWorkflowList(object) {
  let url = null;
  try {
    url = endPoints.WORKFLOWS.LIST_GET;
    url = url + "/" + object;
    return HTTPClient.get(url, null);
  } catch (e) { console.error("Error in 'lists.js -> getWorkflowList()':" + e); }
}

function getSalesRepList() {
  let url = null;
  try {
    url = endPoints.OPPORTUNITIES.SALESREP_LIST_GET;
    return HTTPClient.get(url, null);
  } catch (e) { console.error("Error in 'lists.js -> getSalesRepList()':" + e); }
}

function storeWorkFlowList(data) {
  return (dispatch) => {
    try {
      dispatch({
        type: actions.WORKFLOW_LIST,
        workflowList: data

      });

    } catch (e) { console.error("Error in 'lists.js -> storeWorkFlowList()':" + e); }
  }
}

function storeSaleRepList(data) {
  return (dispatch) => {
    try {
      dispatch({
        type: actions.USER_LIST,
        userList: data

      });

    } catch (e) { console.error("Error in 'lists.js -> storeSaleRepList()':" + e); }
  }
}

function getForeCastReportData(object, params) {
  return dispatch => {
    dispatch({ type: actions.GET_LISTS_START, lists: [], isFetching: false, object });

    var promise = Promise.resolve(getListsData(object, params));
    if (isValidParam(promise)) {
      promise.then((response) => {
        let data = response;
        let communicationFields = null;
        if (object === constants.OPPORTUNITIES_OBJECT) {
          let state = store.getState();
          let sfForm = state.sfForm.data;
          communicationFields = {};
          let objects = Object.keys(sfForm);
          let phoneSetup = data.phoneSetup;
          objects.forEach(function (object) {
            let communication_field = sfForm[object].fields.filter(f => f.is_email_field === true || f.is_phone_field === true);
            let communication_temp_field = [];
            communication_field.forEach((field, i) => {
              if (field.is_phone_field) {
                field = { ...field, ...phoneSetup };
              }
              communication_temp_field.push(field);
            })
            communicationFields[object] = communication_temp_field;
          });


        }
        dispatch({
          type: actions.GET_LISTS,
          payload: {
            lists: data.lists,
            communicationFields: communicationFields,
            isFetching: true,
            object: object,
            view: 'KanbanView',
            mounted: false
          }
        });
      });
    }
  };
}

function updateList(data) {
  return (dispatch) => {
    dispatch({
      type: actions.GET_LISTS,
      payload: {
        lists: data,
        isFetching: true,
        view: 'KanbanView',
        mounted: false
      }

    });
  };
}

function getOpportunityDetailsById(params) {
  let url = null;
  try {
    url = endPoints.OPPORTUNITIES.OPPORTUNITIES_DETAILS_GET;
    return HTTPClient.get(url, params);
  } catch (e) { console.error("Error in 'lists.js -> getSalesRepList()':" + e); }
}

function setKanbanFetch(isFetching) {
  store.dispatch({ type: actions.FETCHED, payload: { isFetching: isFetching } });
}

function setKanbanMounted(isMounted) {
  store.dispatch({ type: actions.MOUNTED, payload: { mounted: isMounted } });
}

function setKanbanFilter(filter) {
  store.dispatch({ type: actions.COLUMNVIEW_FILTER_CHANGE, payload: { isFilterChanged: filter } });
}

function refreshColumnView(object) {
  try {
    const state = store.getState();
    object = getStringParam(object);
    let activeTab = getActiveTab();
    activeTab = getObjectParam(activeTab);
    let tabType = getStringParam(activeTab.type);
    let params = {};
    let info = getObjectParam(activeTab.info);
    if (TYPE_COLUMN_VIEW === tabType || (tabType === TYPE_HOME && activeTab.object === constants.TASKS)) {

      let filter = getObjectParam(info.filter);
      
      params = {
        query_id: getIntParam(filter.id),
        query_name: getStringParam(filter.name),
        query_type: getStringParam(filter.type)
      };

      const cookieName = 'select-' + object + '-workflow-' + state.app.me.projectId + '-' + state.app.me.id;
			const workflowIdFromCookie = parseInt(getCookie(cookieName));

      if(info.workflowId !== -9999){
        if (info.hasOwnProperty('input_param_sql') || (workflowIdFromCookie && workflowIdFromCookie !== -9999 && workflowIdFromCookie !== 0)) {
          params.input_param_sql = activeTab.info.input_param_sql ? activeTab.info.input_param_sql :  "workflow_id = " + workflowIdFromCookie; 
        }
      }
      if (info.hasOwnProperty('group_by_field_name')) {
        params.group_by_field_name = activeTab.info.group_by_field_name;
      }
      if (info.hasOwnProperty('group_by_field_name_sort')) {
        params.group_by_field_name_sort = activeTab.info.group_by_field_name_sort;
      }
      if (info.hasOwnProperty('searchFieldName') && info.hasOwnProperty('searchText')) {
        params.search_field_name = getObjectParam(info.searchFieldName);
        params.search_type = constants.SEARCH_TYPE_ANYWHERE;
        params.search_text = getObjectParam(info.searchText);
      }
      store.dispatch(removeColumnViewScrollPosition(object));
      store.dispatch(removeColumnViewHorizontalScrollPosition(object));
      if (info.workflowId <= 0 && info.isWorkflowSetup && info.workflowId !== -9999) {
        params.isWorkflowSetup = true;

        if (info.hasOwnProperty('is_workflow')) {
          params.is_workflow = info.is_workflow;
        }
      }

    }

    params.group_by_condition = '';
    params.start_index = 0;
    params.is_group_by_info = true;
    params.call_from = 'columnview';
    params.is_column_view = true;
    params.page_size = DEFAULT_PAGE_SIZE;

    if (info.workflowId === -9999) {
      params.is_call_for_all = true;
    }

    if (object === constants.TASKS || object === constants.CALENDAR_TASK) {
      let status = "";
      info.status.forEach((e) => { status += status === "" ? e : ',' + e })
      params.sales_rep = info.userName;
      params.status = status;
      params.sort_order = info.sortOrder;
      object = constants.TASKS;
    }
    if (object !== '') {
      object = object.toLowerCase();
      if(object === constants.TASKS) {
        store.dispatch(getTaskLists(object, params));
      } else {
        store.dispatch(getLists(object, params));
      }
    }
  } catch (error) {
    console.error("Error in 'listViewActions.js -> refreshColumnView()':" + error);
  }
}
function columnViewTaskUpdate(data) {
  store.dispatch({
    type: actions.COLUMN_VIEW_TASK_UPDATE,
    list: data
  })
}

function columnViewTaskListUpdate(data) {
  store.dispatch({
    type: actions.COLUMN_VIEW_TASK_LIST_UPDATE,
    tasklist: data
  })
}

function columnViewOpportunitiesUpdate(data) {
  store.dispatch({
    type: actions.COLUMN_VIEW_OPPORTUNITIES_UPDATE,
    payload: data
  })
}

function columnViewTaskOpportunitiesUpdate(data) {
  store.dispatch({
    type: actions.COLUMN_VIEW_TASK_OPPORTUNITIES_UPDATE,
    payload: data
  })
}

function columnViewContactUpdate(data) {
  store.dispatch({
    type: actions.COLUMN_VIEW_CONTACT_UPDATE,
    list: data
  })
}

function updateColumnViewScrollPosition(pos, obj) {
  return {
    type: actions.UPDATE_COLUMN_VIEW_SCROLL_POSITION,
    payload: { pos, obj }
  }
}

function removeColumnViewScrollPosition(obj) {
  return {
    type: actions.REMOVE_COLUMN_VIEW_SCROLL_POSITION,
    payload: { obj }
  }
}
function updateColumnViewHorizontalScrollPosition(pos, obj) {
  return {
    type: actions.UPDATE_COLUMN_VIEW_HORIZONTAL_SCROLL_POSITION,
    payload: { pos, obj }
  }
}

function removeColumnViewHorizontalScrollPosition(obj) {
  return {
    type: actions.REMOVE_COLUMN_VIEW_HORIZONTAL_SCROLL_POSITION,
    payload: { obj }
  }
}
export {
  moveList,
  moveCard,
  toggleDragging,
  getLists,
  getListsData,
  getWorkflowList,
  getSalesRepList,
  storeWorkFlowList,
  storeSaleRepList,
  getForeCastReportData,
  updateList,
  getOpportunityDetailsById,
  setKanbanFetch,
  setKanbanMounted,
  setKanbanFilter,
  refreshColumnView,
  columnViewTaskUpdate,
  columnViewOpportunitiesUpdate,
  columnViewContactUpdate,
  updateColumnViewScrollPosition,
  removeColumnViewScrollPosition,
  updateColumnViewHorizontalScrollPosition,
  removeColumnViewHorizontalScrollPosition,
  getTaskTypes,
  getTaskLists,
  setListsObject,
  moveTaskList,
  moveTaskCard,
  columnViewTaskListUpdate,
  columnViewTaskOpportunitiesUpdate
}